import firebase from "firebase/app";

// Components
import { Button, Stack } from "@mui/material";

// Assets
import { ReactComponent as GoogleIcon } from "../../images/icons/GoogleIcon.svg";
import { ReactComponent as MicrosoftIcon } from "../../images/icons/MicrosoftIcon.svg";

// Utils
import { AuthButton } from "./AuthButton";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { AuthAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { AuthProvider } from "lib-fullstack/utils/enums";
import {
  AuthProviderConfig,
  googleProviderConfig,
  microsoftProviderConfig,
} from "../providers/AuthProviderConfig";

type AuthButtonGroupProps = {
  isSignUp?: boolean;
  customSso: AuthProviderConfig | null;
  buttonHandler: (
    e: React.MouseEvent<HTMLButtonElement>,
    config: AuthProviderConfig
  ) => Promise<void>;
  error: boolean;
  handleRedirect: () => void;
  signInOptions: AuthProvider[];
  disableButtons: boolean;
};
export default function AuthButtonGroup({
  isSignUp,
  customSso,
  buttonHandler,
  error,
  handleRedirect,
  signInOptions,
  disableButtons,
}: AuthButtonGroupProps): JSX.Element {
  const handleCustomSsoSignin = async (e) => {
    Instrumentation.logAmplitudeEvent(
      isSignUp
        ? AuthAnalyticsEvents.SIGNUP_CUSTOM_SSO_OPTION_CLICKED
        : AuthAnalyticsEvents.SIGNIN_CUSTOM_SSO_OPTION_CLICKED
    );
    await buttonHandler(e, customSso);
  };

  const renderOrgSsoButton = () => {
    const signedInNoMembership = error && firebase.auth().currentUser;
    return (
      <Button
        variant="contained"
        onClick={signedInNoMembership ? handleRedirect : handleCustomSsoSignin}
        sx={{
          width: "100%",
          fontWeight: 600,
          fontSize: 14,
          color: getDynamicColor("light1"),
          height: 48,
        }}
      >
        {signedInNoMembership
          ? "Continue"
          : `${isSignUp ? "Continue" : "Sign in"} with ${customSso.displayName ?? "SSO"}`}
      </Button>
    );
  };

  const renderNormalOauthButtonOptions = () => (
    <>
      {signInOptions.includes(AuthProvider.GOOGLE) && (
        <AuthButton
          title={googleProviderConfig.displayName}
          disabled={disableButtons}
          logo={<GoogleIcon width="28px" />}
          onClick={async (e) => {
            Instrumentation.logAmplitudeEvent(
              isSignUp
                ? AuthAnalyticsEvents.SIGNUP_GOOGLE_OPTION_CLICKED
                : AuthAnalyticsEvents.SIGNIN_GOOGLE_OPTION_CLICKED
            );
            await buttonHandler(e, googleProviderConfig);
          }}
          isSignUp={isSignUp}
        />
      )}
      {signInOptions.includes(AuthProvider.MICROSOFT) && (
        <AuthButton
          title={microsoftProviderConfig.displayName}
          disabled={disableButtons}
          logo={<MicrosoftIcon width="28px" />}
          onClick={async (e) => {
            Instrumentation.logAmplitudeEvent(
              isSignUp
                ? AuthAnalyticsEvents.SIGNUP_OUTLOOK_OPTION_CLICKED
                : AuthAnalyticsEvents.SIGNIN_OUTLOOK_OPTION_CLICKED
            );
            await buttonHandler(e, microsoftProviderConfig);
          }}
          isSignUp={isSignUp}
        />
      )}
    </>
  );

  return (
    // If custom SSO is available and only that should be shown, render it here.
    // Otherwise, render default provider buttons (Google, Microsoft),
    // and SSO button is rendered by the parent component.
    <Stack direction="column" width="100%" gap={2}>
      {!customSso?.showOnlyThis ? renderNormalOauthButtonOptions() : renderOrgSsoButton()}
    </Stack>
  );
}
