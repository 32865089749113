/**
 * Definition of data schema used by Usage Plan API
 * These types are shared by database and API surface.
 */

import { Optional, Record, Static, String, Number, Boolean, Null, Union } from "runtypes";
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";
import {
  UsagePlanType,
  UsagePlanDuration,
  PricingExperiment,
} from "lib-fullstack/utils/pricingTypes";

export enum PlanCheckoutResult {
  REDIRECT = "redirect",
  NOT_SCHEDULED = "not_scheduled",
  SCHEDULED = "scheduled",
  FAIL_ENTERPRISE_PLAN = "fail_enterprise_plan",
  FAIL_SCHEDULE_EXIST = "fail_schedule_exist",
  FAIL_NOT_ACCEPTABLE = "fail_not_acceptable",
  FAIL_OTHER = "fail_other",
}

export enum PlanSettingsAction {
  UPDATE_PAYMENT_METHOD = "update_payment_method",
}

export enum PlanSettingsActionResult {
  REDIRECT = "redirect",
  FAIL_OTHER = "fail_other",
}

export enum PlanPortalType {
  CANCEL_SUBSCRIPTION = "cancel_subscription",
  PAYMENT_METHOD = "payment_method",
}

export const PlanCheckoutRequest = Record({
  plan_type: RTStringEnum(UsagePlanType),
  plan_duration: RTStringEnum(UsagePlanDuration),
  finalize_schedule: Optional(Boolean),
  p_exp_version: RTStringEnum(PricingExperiment),
  success_url: Optional(Union(String, Null)),
  cancel_url: Optional(Union(String, Null)),
  coupon_id: Optional(String),
  promotion_code: Optional(String),
});
export type PlanCheckoutRequest = Static<typeof PlanCheckoutRequest>;

export const Billing = Record({
  date: String,
  amount: Number,
  currency: String,
});
export type Billing = Static<typeof Billing>;

export const PlanCheckoutResponse = Record({
  result: RTStringEnum(PlanCheckoutResult),
  redirect_url: Union(String, Null),
  pending_billing: Union(Billing, Null),
  error_info: Union(String, Null),
});
export type PlanCheckoutResponse = Static<typeof PlanCheckoutResponse>;

export const PlanSettingsActionRequest = Record({
  action: RTStringEnum(PlanSettingsAction),
  success_url: Optional(String),
  cancel_url: Optional(String),
});
export type PlanSettingsActionRequest = Static<typeof PlanSettingsActionRequest>;

export const PlanSettingsActionResponse = Record({
  result: RTStringEnum(PlanSettingsActionResult),
  redirect_url: Union(String, Null),
});
export type PlanSettingsActionResponse = Static<typeof PlanSettingsActionResponse>;

export const PlanDataRedactionConfigResponse = Record({
  available: Boolean,
  org_config_days: Number,
  user_config_days: Number,
  effective_days: Number,
});
export type PlanDataRedactionConfigResponse = Static<typeof PlanDataRedactionConfigResponse>;

export const PlanDataRedactionConfigRequest = Record({
  user_config_days: Optional(Number),
});
export type PlanDataRedactionConfigRequest = Static<typeof PlanDataRedactionConfigRequest>;
