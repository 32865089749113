import React from "react";
import { db } from "lib-fullstack";

import { FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";

import { IOSSwitch } from "../Settings";
import { updateThisUserDocMain, useUserDocsState } from "lib-frontend/utils/LiveUserDocs";

export const NotifyMeWhen = (): JSX.Element => {
  const userDoc = useUserDocsState()[db.UserDocTypes.MAIN] as db.UserDocMain;

  const handleSpeechMilestonesEmailPreference = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateThisUserDocMain({
      notifPrefsSpeechMilestonesEmail: e.target.checked,
    }).catch(console.error);

  const handleDefaultCommentEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateThisUserDocMain({
      notifPrefsNewCommentEmail: e.target.checked,
    }).catch(console.error);

  const handleDefaultSendCommentEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateThisUserDocMain({
      notifPrefsSendCommentEmail: e.target.checked,
    }).catch(console.error);

  const handleDefaultShareEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateThisUserDocMain({
      notifPrefsNewShareEmail: e.target.checked,
    }).catch(console.error);

  return (
    <div style={{ zIndex: 2 }}>
      <Typography variant="h2" sx={{ fontWeight: 700, fontFamily: "Poppins" }}>
        Notify Me When...
      </Typography>
      <Grid container>
        <Grid
          container
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ mt: { xs: 3, md: 2 } }}
        >
          <Grid item flexWrap={"wrap"} xs={10} lg={6}>
            <Typography>I receive a comment</Typography>
          </Grid>
          <Grid item xs={1} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={userDoc.notifPrefsNewCommentEmail}
                    onChange={handleDefaultCommentEmail}
                  />
                }
                label=""
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ mt: { xs: 4, md: 2 } }}
        >
          <Grid item flexWrap={"wrap"} xs={10} lg={6}>
            <Typography>Someone shares a recording with me</Typography>
          </Grid>
          <Grid item xs={1} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={userDoc.notifPrefsNewShareEmail}
                    onChange={handleDefaultShareEmail}
                  />
                }
                label=""
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ mt: { xs: 4, md: 2 } }}
        >
          <Grid item flexWrap={"wrap"} xs={10} lg={6}>
            <Typography>I reach speech milestones</Typography>
          </Grid>
          <Grid item xs={1} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={userDoc.notifPrefsSpeechMilestonesEmail}
                    onChange={handleSpeechMilestonesEmailPreference}
                  />
                }
                label=""
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ mt: { xs: 4, md: 2 } }}
        >
          <Grid item flexWrap={"wrap"} xs={10} lg={6}>
            <Typography>Notify others when I comment on their recordings</Typography>
          </Grid>
          <Grid item xs={1} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={userDoc.notifPrefsSendCommentEmail}
                    onChange={handleDefaultSendCommentEmail}
                  />
                }
                label=""
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
