// Components
import { Stack, Typography } from "@mui/material";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";

/** You can set the global banner by enabling feature flag
 *  called "app-banner-message" at:
 *   https://us.app.unleash-hosted.com/uscc1011/projects/default/features/app-banner-message
 *  and updating the text in the variant's string payload. Please be sure to
 *  disable the feature flag when not in use.
 *
 *  Use this for, e.g., site maintenance notices.
 */
export const GLOBAL_BANNER_HEIGHT = 48;
export const GlobalBanner = (props: { message: string }): JSX.Element => {
  const { navDrawerOffset } = useNavDrawerOffset();
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        zIndex: 1101,
        position: "relative",
        width: "100%",
        p: 2,
        px: { xs: 2, md: "unset" },
        pl: { xs: 2, md: `calc(${navDrawerOffset}px + 32px)` },
        transition: "all 0.3s ease-out",
        backgroundColor: getDynamicColor("primary"),
        color: getDynamicColor("light1"),
        height: GLOBAL_BANNER_HEIGHT,
      }}
    >
      <Typography
        sx={{
          fontFamily: "poppins",
          fontSize: { xs: 14, md: 16 },
          lineHeight: 1.3,
          fontWeight: 600,
          textAlign: { xs: "center", md: "left" },
          width: { xs: "100%", md: "unset" },
        }}
      >
        {props.message}
      </Typography>
    </Stack>
  );
};
