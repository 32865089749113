// Components
import { LinearProgress, Stack, SxProps, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ProgramRecordState } from "lib-fullstack/utils/enums";

type ProgressRowProps = {
  state: ProgramRecordState;
  pct: number;
};

const ProgressRow = ({ state, pct }: ProgressRowProps): JSX.Element => {
  const getStateText = () => {
    switch (state) {
      case ProgramRecordState.NotStarted:
        return "Not started";
      case ProgramRecordState.InProgress:
        return "In progress";
      case ProgramRecordState.Completed:
        return "Completed";
    }
  };

  const getStateColor = () => {
    switch (state) {
      case ProgramRecordState.NotStarted:
        return getDynamicColor("chartBlue");
      case ProgramRecordState.InProgress:
        return getDynamicColor("chartPurple");
      case ProgramRecordState.Completed:
        return getDynamicColor("chartGreen");
    }
  };

  return (
    <Stack direction="row" gap={1.5} sx={{ alignItems: "center", width: "100%" }}>
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 500,
          whiteSpace: "nowrap",
        }}
      >
        {getStateText()}
      </Typography>
      <Stack direction={"row"} gap={0.5} sx={{ alignItems: "center", width: "100%" }}>
        <LinearProgress
          variant="determinate"
          value={pct}
          color="inherit"
          sx={{
            width: "100%",
            color: getStateColor(),
            borderRadius: "100px",
            "&::before": {
              backgroundColor: getDynamicColor("dark2"),
            },
          }}
        />
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "12px",
            fontWeight: 500,
            width: "30px",
          }}
        >
          {pct}%
        </Typography>
      </Stack>
    </Stack>
  );
};

type UserActivityProps = {
  userActivity: { state: ProgramRecordState; num_users: number }[];
  label?: string;
  labelSx?: SxProps;
};

export const UserActivity = ({ userActivity, label, labelSx }: UserActivityProps): JSX.Element => {
  const numUsersTotal = userActivity.reduce((acc, { num_users }) => acc + num_users, 0);
  return (
    <Stack gap={2} sx={{ width: "100%" }}>
      {label && (
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "12px",
            fontWeight: 600,
            ...labelSx,
          }}
        >
          {label}
        </Typography>
      )}
      {Object.values(ProgramRecordState).map((state) => (
        <ProgressRow
          key={state}
          state={state as ProgramRecordState}
          pct={
            userActivity.length > 0 && numUsersTotal > 0
              ? Math.round(
                  ((userActivity.find((ua) => ua.state === state)?.num_users ?? 0) /
                    numUsersTotal) *
                    100
                )
              : 0
          }
        />
      ))}
    </Stack>
  );
};
