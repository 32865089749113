import React from "react";

// Components
import {
  MoreHoriz as MoreHorizIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  ExpandLessRounded as ExpandLessRoundedIcon,
} from "@mui/icons-material";

import {
  Menu,
  MenuItem,
  IconButton,
  Stack,
  SxProps,
  Button,
  ButtonProps,
  CircularProgress,
} from "@mui/material";

// Utils
import YoodliTooltip from "./YoodliTooltip";
import { DynamicColorType, getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";

export enum YoodliMenuItemType {
  Default = "default",
  Warning = "warning",
  Primary = "primary",
}

export enum YoodliMenuButtonType {
  Text = "text",
  Icon = "icon",
}

const YoodliMenuItemTypeColorMap: Record<YoodliMenuItemType, DynamicColorType> = {
  [YoodliMenuItemType.Default]: "purple3",
  [YoodliMenuItemType.Warning]: "redError",
  [YoodliMenuItemType.Primary]: "primary",
};

type YoodliMenuProps = {
  type: YoodliMenuButtonType;
  menuItems: {
    title: string | JSX.Element;
    onClick: () => void;
    disabled?: boolean;
    type: YoodliMenuItemType;
    disabledTooltip?: string;
    keepOpen?: boolean;
    loading?: boolean;
  }[];
  icon?: JSX.Element;
  buttonSx?: SxProps;
  buttonText?: string;
  buttonProps?: ButtonProps;
  showDropdownArrow?: boolean;
  fitWidth?: boolean;
  menuContainerSx?: SxProps;
  menuDisabled?: boolean;
};

export const YoodliMenu = ({
  type,
  menuItems,
  icon,
  buttonSx,
  buttonProps,
  buttonText,
  showDropdownArrow,
  fitWidth,
  menuContainerSx,
  menuDisabled,
}: YoodliMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuLoadingIndex, setMenuLoadingIndex] = React.useState<number>(undefined);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const renderDropdownArrow = () => {
    if (anchorEl) {
      return <ExpandLessRoundedIcon />;
    }
    return <ExpandMoreRoundedIcon />;
  };

  const handleMenuItemClick = async (e, item, index) => {
    e.stopPropagation();
    setMenuLoadingIndex(index);
    await item.onClick();
    setMenuLoadingIndex(undefined);
    if (!item.keepOpen) setAnchorEl(null);
  };

  const renderMenuButton = () => {
    switch (type) {
      case YoodliMenuButtonType.Icon:
        return (
          <IconButton
            disabled={menuDisabled}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            sx={{
              mx: 2,
              width: "28px",
              height: "28px",
              color: getDynamicColor("primary"),
              border: `2px solid ${getDynamicColor("primary")}`,
              borderRadius: "32px",
              position: "relative",
              "&.Mui-disabled": {
                borderColor: getDynamicColor("dark3"),
              },
              ...buttonSx,
            }}
          >
            {icon ?? <MoreHorizIcon />}
          </IconButton>
        );
      case YoodliMenuButtonType.Text:
        return (
          <Button
            disabled={menuDisabled}
            ref={buttonRef}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            endIcon={showDropdownArrow ? renderDropdownArrow() : null}
            {...buttonProps}
            sx={{
              ...buttonSx,
            }}
          >
            {buttonText ?? "More"}
          </Button>
        );
    }
  };
  return (
    <>
      {renderMenuButton()}
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(e: Event) => {
          e.stopPropagation();
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{
          sx: { width: buttonRef.current && fitWidth && buttonRef.current.offsetWidth },
        }}
        slotProps={{
          paper: {
            sx: {
              background: "transparent",
              border: "hidden",
              boxShadow: "none",
              justifyContent: "center",
              zIndex: 1000,
            },
          },
        }}
      >
        <Stack
          width={fitWidth ? "100%" : "fit-content"}
          minWidth="150px"
          mt={1.5}
          py={2}
          gap={1}
          borderRadius="12px"
          border={`1px solid ${getDynamicColor("dark3")}`}
          boxShadow={Y_SHADOWS.box_shadow_1}
          sx={{ ...menuContainerSx, background: getDynamicColor("light1") }}
        >
          {menuItems.map((item, index) => (
            <YoodliTooltip
              key={index}
              title={item.disabled && item.disabledTooltip}
              sx={{ width: "100%" }}
            >
              <MenuItem
                onClick={async (e) => handleMenuItemClick(e, item, index)}
                disabled={item.disabled || menuLoadingIndex !== undefined}
                sx={{
                  fontFamily: "Poppins",
                  width: "100%",
                  gap: 1,
                  color: getDynamicColor(YoodliMenuItemTypeColorMap[item.type]),
                }}
              >
                {menuLoadingIndex === index && <CircularProgress size={12} />}
                {item.title}
              </MenuItem>
            </YoodliTooltip>
          ))}
        </Stack>
      </Menu>
    </>
  );
};
