import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

export enum ReferralProgram {
  MICROSOFT_FOR_STARTUPS = "microsoft-for-startups",
  COURSERA = "coursera",
  INDEED = "indeed",
  SPENCER_STUART = "spencer-stuart",
  KORN_FERRY_ADVANCE = "korn-ferry-advance",
  PROTOCOMMS = "protocomms",
}
export const RTReferralProgram = RTStringEnum(ReferralProgram);

export const REFERRAL_PROGRAM_AUTH_IMAGES = {
  [ReferralProgram.SPENCER_STUART]:
    "https://storage.googleapis.com/yoodli-public/landing-page-assets/brand-images/SpencerSturart_auth_screen_image.webp",
};
