import firebase from "firebase/app";

// Utils
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";

export function resolveAuthProvider(email: string): firebase.auth.AuthProvider {
  const domain = email.split("@")[1];
  switch (domain) {
    case "gmail.com":
      return new firebase.auth.GoogleAuthProvider();
    case "microsoft.com":
      return new firebase.auth.OAuthProvider("microsoft.com");
    default:
      return new firebase.auth.EmailAuthProvider();
  }
}

// Email Auth Provider
export async function signInWithEmail(email: string, password: string): Promise<firebase.User> {
  const credsOrError = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((user) => {
      if (user?.additionalUserInfo?.isNewUser) {
        Instrumentation.logUserSignUpCheckpoint();
      } else {
        Instrumentation.logUserSignInCheckpoint();
      }
      return user;
    })
    .catch((error) => error);

  // Firebase errors have a code property
  if (credsOrError?.code) {
    throw { code: credsOrError.code, message: credsOrError.message };
  } else {
    return credsOrError.user;
  }
}

export async function signInWithCustomToken(token: string): Promise<firebase.User> {
  try {
    const credsOrError = await firebase
      .auth()
      .signInWithCustomToken(token)
      .then((user) => {
        if (user?.additionalUserInfo?.isNewUser) {
          Instrumentation.logUserSignUpCheckpoint();
        } else {
          Instrumentation.logUserSignInCheckpoint();
        }
        return user;
      })
      .catch((error) => error);

    // Firebase errors have a code property
    if (credsOrError?.code) {
      throw { code: credsOrError.code, message: credsOrError.message };
    } else {
      return credsOrError.user;
    }
  } catch (error) {
    console.log("Failed to signin with custom token", error);
  }
}

export async function signUpWithEmail(
  email: string,
  password: string,
  displayName: string
): Promise<firebase.User> {
  const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
  await user.updateProfile({ displayName });
  Instrumentation.logUserSignUpCheckpoint();
  return user;
}
