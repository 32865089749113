// Components
import { Box } from "@mui/material";
import DownloadPoodli from "components/DownloadPoodli/DownloadPoodli";

// Utils
import TmiSso from "../auth/TmiSso";
import authUrls from "./config/authUrls";
import ForgotPassword from "./layouts/ForgotPassword";
import ResetPassword from "./layouts/ResetPassword";
import SignUpSignIn from "./layouts/SignUpSignIn";
import VerifyEmail from "./layouts/VerifyEmail/VerifyEmail";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";

const layouts = {
  [authUrls.signin]: <SignUpSignIn isSignUp={false} />,
  [authUrls.download]: <DownloadPoodli />,
  [authUrls.signup]: <SignUpSignIn isSignUp={true} />,
  [authUrls.forgot_password]: <ForgotPassword />,
  [authUrls.reset_password]: <ResetPassword />,
  [authUrls.verify_email]: <VerifyEmail />,
} as const;

function renderLayout(flow: string): JSX.Element {
  if (getStaticFullSiteConf()?.featureDiffs?.enableTmiSso) {
    // for Toastmasters "SSO" login, render that component instead of
    // the usual login component. The typical component is provided
    // as an additional 'admin' option (adminComponent).
    // If we end up implementing other custom
    // SSO solutions, we should do this routing more cleanly.
    return <TmiSso adminComponent={layouts[flow]} />;
  }

  if (!flow || !layouts[flow]) {
    return layouts[authUrls.signin];
  }
  return layouts[flow];
}

// Fullscreen auth page
export default function AuthPage(flow: string): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        minWidth: "100vw",
        zIndex: 5001,
        backgroundColor: getDynamicColor("dark1"),
      }}
    >
      {renderLayout(flow)}
    </Box>
  );
}
