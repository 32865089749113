import { db } from "lib-fullstack";

// Components
import { Stack, Box, Typography, Alert, SxProps } from "@mui/material";

// Assets
import { ReactComponent as CalImage } from "images/graphics/cal-item.svg";

// Utils
import ConnectGoogleCalendar from "./ConnectGoogleCalendar";
import ConnectOutlookCalendar from "./ConnectOutlookCalendar";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { EventsCalHows, EventsCalWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

type CalendarAuthProps = {
  error: boolean;
  setError: (val: boolean) => void;
  setLoading: (val: boolean) => void;
  mode: db.CalendarMode;
  connectCalendarTitle?: string;
  topTitle?: string;
  showImage?: boolean;
  rowButtons?: boolean;
  shouldDisplayOutlook?: boolean;
  sx?: SxProps;
  calConnectedWhereProperty: EventsCalWheres;
};

export default function CalendarAuth({
  error,
  setError,
  setLoading,
  mode,
  connectCalendarTitle,
  topTitle,
  showImage,
  rowButtons,
  shouldDisplayOutlook = true,
  sx = {},
  calConnectedWhereProperty,
}: CalendarAuthProps): JSX.Element {
  const isChecklist = ([WebServerExternalPath.HOME_LOGGED_IN] as string[]).includes(
    window.location.pathname
  );
  return (
    <Stack
      justifyContent="flex-start"
      alignItems="center"
      gap={3}
      sx={{
        color: getDynamicColor("purple3"),
        p: { xs: 3, md: 4 },
        pt: isChecklist ? 0 : { xs: 8, md: 12 },
        pb: isChecklist ? 0 : 12,
        width: "100%",
        height: "100%",
        ...sx,
      }}
    >
      {topTitle && (
        <Typography
          variant="poppins"
          fontWeight={500}
          fontSize={{ xs: 14, md: 20 }}
          px={{ xs: 0, sm: 2 }}
        >
          {topTitle}
        </Typography>
      )}
      {showImage && (
        <Box>
          <CalImage />
        </Box>
      )}
      {!topTitle && (
        <>
          <Typography fontWeight={700} fontSize="24px" textAlign="center">
            {connectCalendarTitle ?? "Connect your calendar"}
          </Typography>
          <Typography
            fontSize="16px"
            sx={{
              mb: 2,
              textAlign: "center",
            }}
          >
            Connect your calendar and Yoodli will automatically provide you speech coaching, a
            transcript, and a recording for your calls.
          </Typography>
        </>
      )}

      <Stack
        direction={rowButtons ? "row" : "column"}
        gap={2}
        sx={{
          button: {
            width: "100%",
          },
        }}
      >
        <ConnectGoogleCalendar
          onSuccess={() => {
            Instrumentation.logCalConnectedCheckpoint(
              EventsCalHows.GOOGLE,
              calConnectedWhereProperty
            );
          }}
          setError={setError}
          setLoading={setLoading}
          mode={mode}
        />
        {shouldDisplayOutlook && (
          <ConnectOutlookCalendar redirectPathname={window.location.pathname} />
        )}
      </Stack>
      {error && <Alert severity="error">Error: Could not connect calendar</Alert>}
    </Stack>
  );
}
