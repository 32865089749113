import React from "react";

// Components
import { Card, Stack, Typography, Button, SxProps, Box } from "@mui/material";
import BgBalls from "components/Onboarding/BgBalls";

// Assets
import YoodliLaptop from "lib-frontend/assets/graphic_yoodli-laptop";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { submitDownloadPoodliMobileNotificationJob } from "lib-frontend/utils/DbUtils";

type MeetingsOnboardingMobileProps = {
  setUpLater: () => void;
  sendMobileDownloadEmail?: boolean;
  sxOverrides?: SxProps;
};

export default function MeetingsOnboardingMobile({
  setUpLater,
  sendMobileDownloadEmail,
  sxOverrides,
}: MeetingsOnboardingMobileProps): JSX.Element {
  // Effects
  React.useEffect(() => {
    // Note: This component mounts as soon as user clicks OBQ1 = Meetings (due to being in the swiper)
    if (sendMobileDownloadEmail) {
      submitDownloadPoodliMobileNotificationJob().catch((er) => {
        console.error(`Error submitting download poodli mobile notification job: ${er}`);
      });
    }
  }, []);

  return (
    <Stack
      width="100%"
      maxHeight="100vh"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      gap={2}
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
        p: 2.5,
        ...sxOverrides,
      }}
    >
      <BgBalls visible />

      <Card
        sx={{
          border: `1px solid ${getDynamicColor("purple1")}`,
          boxShadow: "1px 2px 5px rgba(33, 37, 41, 0.16);",
          borderRadius: "20px",
          py: "30%",
        }}
      >
        <Stack width="min(100%, 270px)" p="10px" alignItems="center" gap={3} mx={"auto"}>
          <Box style={{ height: "60px" }}>
            <YoodliLaptop />
          </Box>
          {sendMobileDownloadEmail && (
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 700,
                color: getDynamicColor("purple3"),
              }}
            >
              Check your email...
            </Typography>
          )}
          <Stack gap={2}>
            {sendMobileDownloadEmail && (
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: getDynamicColor("purple3"),
                  lineHeight: "140%",
                }}
              >
                We sent you a download link for the Yoodli desktop app.
              </Typography>
            )}

            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 500,
                color: getDynamicColor("purple3"),
                lineHeight: "140%",
                px: 3,
              }}
            >
              Get private and live coaching during your online meetings for free!
            </Typography>
          </Stack>
          <Button
            onClick={setUpLater}
            sx={{ color: getDynamicColor("primary"), fontWeight: 500, fontSize: "14px" }}
          >
            Continue to Yoodli on mobile
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
}
