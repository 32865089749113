import React from "react";

// Components
import { Box, SxProps, TooltipProps } from "@mui/material";

// Utils
import { useObserveElementDimensions } from "../../hooks";
import YoodliTooltip from "./YoodliTooltip";

type OverflowTipProps = {
  title: string;
  textValue: string;
  sx?: SxProps;
  tooltipProps?: Partial<TooltipProps>;
};
export const TextOverflowTooltip = ({
  title,
  textValue,
  sx,
  tooltipProps,
}: OverflowTipProps): JSX.Element => {
  // Create Ref
  const textElementRef = React.useRef<HTMLDivElement>(null);

  const dims = useObserveElementDimensions(textElementRef);

  const compareSize = () => {
    if (!textElementRef.current) return;
    const compare = textElementRef.current.scrollWidth > textElementRef.current?.clientWidth;
    setHover(compare);
  };

  // compare whenever the size of the wrapper changes
  React.useEffect(() => {
    compareSize();
  }, [dims?.width, textValue]);

  React.useEffect(() => {
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  // Define state and function to update the value
  const [hoverStatus, setHover] = React.useState(false);

  return (
    <YoodliTooltip title={title} disableHoverListener={!hoverStatus} {...(tooltipProps ?? {})}>
      <Box
        ref={textElementRef}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...(sx ?? {}),
        }}
      >
        {textValue}
      </Box>
    </YoodliTooltip>
  );
};
