import React from "react";

// Components
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography, Button, Stack, CircularProgress } from "@mui/material";

// Utils
import { usePricingUsage } from "hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { handlePlanCheckout } from "utils/PricingUtils";
import { UsagePlanDuration } from "lib-fullstack/utils/pricingTypes";

type GoAnnualModalContentProps = {
  open: boolean;
  handleCloseModal: () => void;
  annualSavingsAmt: number;
  handleGoAnnual: () => void;
};

export default function GoAnnualModalContent({
  handleCloseModal,
  annualSavingsAmt,
  handleGoAnnual,
}: GoAnnualModalContentProps): JSX.Element {
  const { usagePlanType } = usePricingUsage();

  const [proratedPrice, setProratedPrice] = React.useState<number | null>(null);

  React.useEffect(() => {
    const getProratedPrice = async () => {
      const res = await handlePlanCheckout(usagePlanType, UsagePlanDuration.YEARLY, false);
      if (res?.amount) {
        setProratedPrice(res.amount / 100);
      }
    };
    getProratedPrice().catch((er) => {
      console.error(`Error getting prorated price from GoAnnualBanner.tsx: ${er}`);
    });
  }, []);

  return (
    <>
      <IconButton
        onClick={handleCloseModal}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        sx={{
          width: "100%",
          p: 2.5,
          borderBottom: `1px solid ${getDynamicColor("dark3")}`,
          textAlign: "center",
          fontFamily: "poppins",
          fontWeight: 600,
          color: getDynamicColor("purple3"),
        }}
      >
        Go Annual and save
      </Typography>
      <Stack
        sx={{
          height: { xs: 150, md: 250 },
          width: "100%",
          p: 2,
          mb: 4,
          color: getDynamicColor("purple3"),
        }}
        alignItems="center"
        justifyContent="center"
        gap={2}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontFamily: "poppins",
            textAlign: "center",
            maxWidth: 360,
          }}
        >
          You will be charged{" "}
          {!proratedPrice ? (
            <CircularProgress
              size={18}
              sx={{
                mx: 1,
              }}
            />
          ) : (
            <span style={{ fontWeight: 700 }}>${proratedPrice}</span>
          )}{" "}
          on your current payment method, for a total annual savings of{" "}
          <span style={{ fontWeight: 700 }}>${annualSavingsAmt}</span>.
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          pb: 3,
          px: 4,
          width: "100%",
        }}
      >
        <Button variant="text" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!proratedPrice}
          onClick={handleGoAnnual}
          sx={{
            backgroundColor: getDynamicColor("redError"),
            color: getDynamicColor("light1"),
            minWidth: { xs: 140, sm: 200 },
            "&:hover": {
              backgroundColor: getDynamicColor("redError"),
            },
          }}
        >
          Confirm
        </Button>
      </Stack>
    </>
  );
}
