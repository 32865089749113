// takes a Record<string, number> and returns a boolean indicating whether the distribution
// of the values is equal (other than one value which contains the remainder).
// returns false if total sum is not 100 or if distribution is not equal
export const isEqualDistribution = (weights?: Record<string, number>): boolean => {
  if (!weights) {
    return true;
  }
  const entries = Object.entries(weights);
  const totalSum = entries.reduce((sum, [, value]) => sum + value, 0);
  // this is an error case, backend will equalize the weights if this occurs so we return equal distribution
  if (totalSum !== 100) {
    return true;
  }
  const expectedProportion = Math.floor(100 / entries.length);
  const expectedProportionCount = entries.filter(
    ([, value]) => value === expectedProportion
  ).length;
  return (
    expectedProportionCount ===
    (Number.isInteger(100 / entries.length) ? entries.length : entries.length - 1)
  );
};
