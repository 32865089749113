// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

export const CARD_STYLES = {
  position: "relative",
  backgroundColor: getDynamicColor("dark1"),
  p: 2.5,
  borderRadius: 3,
  flexGrow: 1,
  height: "min(50vh, 500px)",
  minWidth: "min(100%, 400px)",
};

export const ROW_STYLES = {
  display: "flex",
  flexDirection: "row",
  gap: 1,
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 2,
  mb: 1,
  padding: 1,
  background: "white",
  svg: {
    p: 0.1,
    height: "16px",
    width: "16px",
  },
  "> p": {
    width: "100%",
  },
};
