// Components
import { Stack, Typography } from "@mui/material";

// Assets
import { ReactComponent as BotContentIcon } from "images/icons/icon-bot-content.svg";
import { ReactComponent as MailIcon } from "images/icons/icon-coachbot-mail.svg";
import { ReactComponent as PaperIcon } from "images/icons/icon-coachbot-paper.svg";
import { ReactComponent as OrgMembersIcon } from "images/icons/icon-org-members.svg";
import { ReactComponent as TtsIcon } from "images/icons/icon-tts-sparkle.svg";

export const COACHBOT_INFO = {
  getStarted: [
    {
      icon: (
        <TtsIcon
          style={{
            height: 40,
            minWidth: 40,
          }}
        />
      ),
      copy: "Generate responses and feedback on members’ speech summaries in your style and tone",
    },
    {
      icon: (
        <BotContentIcon
          style={{
            height: 40,
            minWidth: 40,
          }}
        />
      ),
      copy: "You’re always in control of what the AI will learn from. Just upload your content to build your bot",
    },
    {
      icon: (
        <OrgMembersIcon
          style={{
            height: 40,
            minWidth: 40,
          }}
        />
      ),
      copy: "Every member of your organization can access the power of your Coach Bot! It’s enabled by default for all users",
    },
  ],
  done: [
    {
      icon: (
        <MailIcon
          style={{
            height: 40,
            minWidth: 40,
          }}
        />
      ),
      copy: "We'll send you an email to let you know when it's ready",
    },
    {
      icon: (
        <PaperIcon
          style={{
            height: 40,
            minWidth: 40,
          }}
        />
      ),
      copy: "You'll be able to make changes to content and details on your bot's page",
    },
  ],
};

export const CoachBotInfoRow = ({
  copy,
  icon,
}: {
  copy: string;
  icon: React.ReactNode;
}): JSX.Element => {
  return (
    <Stack direction="row" gap={3}>
      {icon}
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          fontFamily: "poppins",
          lineHeight: 1.4,
          maxWidth: { xs: 360, md: 330 },
        }}
      >
        {copy}
      </Typography>
    </Stack>
  );
};
