import React from "react";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton, Dialog, SxProps } from "@mui/material";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { HubModalStatus } from "utils/Enums";

export type Modals = {
  [key in HubModalStatus]?: {
    element: JSX.Element;
    wrapperStyles?: SxProps;
    showCloseButton?: boolean;
  };
};

type ModalWrapperProps = {
  modalStatus: HubModalStatus;
  modals: Modals;
  closeModal: () => void;
};

const ModalWrapper = ({ modalStatus, modals, closeModal }: ModalWrapperProps): JSX.Element => {
  const { modalStyles } = useNavDrawerOffset();

  if (!modals[modalStatus]) return null;
  return (
    <Dialog
      open={modalStatus !== HubModalStatus.CLOSED}
      onClose={() => {
        if (modals[modalStatus].showCloseButton) {
          closeModal();
        }
      }}
      sx={{
        cursor: "default",
      }}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          width: "min(600px, 100%)",
          maxHeight: "min(600px, 100%)",
          maxWidth: "unset",
          boxShadow: Y_SHADOWS.box_shadow_1,
          alignItems: "center",
          textAlign: "center",
          position: "relative",
          backgroundColor: getDynamicColor("light1"),
          border: `2px solid ${getDynamicColor("dark2")}`,
          overflowY: "auto",
          pt: { xs: 2, md: 3 },
          pb: { xs: 2, md: 4 },
          px: { xs: 3, md: 5 },
          gap: 2,
          ...modals[modalStatus].wrapperStyles,
          ...modalStyles,
        },
      }}
    >
      {modals[modalStatus].showCloseButton && (
        <IconButton
          onClick={closeModal}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
          }}
        >
          <CloseIcon sx={{ width: "24px", height: "24px" }} />
        </IconButton>
      )}
      {modals[modalStatus].element}
    </Dialog>
  );
};

export default ModalWrapper;
