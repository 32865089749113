import React from "react";

/**
 * Conditionally wrap a component with another component
 * @param condition The condition to check
 * @param Wrapper The component to wrap with
 * @param wrapperProps The props to pass to the wrapper
 * @param children The children to wrap
 * @returns The wrapped component or the children
 * @example
 * // returns <Box p={2}><div>hello</div></Box>
 * <ConditionalWrapper condition={true} Wrapper={Box} wrapperProps={{ p: 2 }}>
 *   <div>hello</div>
 * </ConditionalWrapper>
 *
 * @example
 * // returns <div>hello</div>
 * <ConditionalWrapper condition={false} Wrapper={Box} wrapperProps={{ p: 2 }}>
 *   <div>hello</div>
 * </ConditionalWrapper>
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default function ConditionalWrapper({
  condition,
  Wrapper,
  wrapperProps = {},
  children,
}): JSX.Element {
  return condition ? <Wrapper {...wrapperProps}>{children}</Wrapper> : children;
}
