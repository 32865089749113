import React from "react";

// Components
import { Box, Stack, Typography } from "@mui/material";

// Utils
import {
  GOAL_CHART_LEGEND_BOX_SIZE,
  GoalImprovementChartType,
  MIN_BAR_CHART_HEIGHT,
  MIN_RADAR_CHART_HEIGHT,
} from "./GoalImprovementCard";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsLargeScreen, useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import { ProgramImprovementReportDataItem } from "lib-fullstack/api/reportApiTypes";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
} from "recharts";

type GoalImprovementChartProps = {
  goals: string[];
  data: ProgramImprovementReportDataItem[];
  chartType: GoalImprovementChartType;
};

enum ScoreType {
  Max = "max",
  First = "first",
}

export const GoalImprovementChart = ({
  goals,
  data,
  chartType,
}: GoalImprovementChartProps): JSX.Element => {
  const isLargeScreen = useIsLargeScreen();
  // isMediumScreen is used to break from row to column
  const isMediumScreen = useIsMediumScreen();

  const [chartHeight, setChartHeight] = React.useState(
    chartType === GoalImprovementChartType.Bar ? MIN_BAR_CHART_HEIGHT : MIN_RADAR_CHART_HEIGHT
  );
  const chartData = React.useMemo(
    () =>
      goals.map((goal) => {
        const goalWithData = data.find((d) => d.goalId === goal);
        return {
          subject: goalWithData?.goalName,
          [ScoreType.First]: goalWithData?.firstScorePercentage,
          [ScoreType.Max]: goalWithData?.maxScorePercentage,
        };
      }),
    [goals, data]
  );

  // never go below 200, but allow growing when adding more goals
  React.useEffect(() => {
    setChartHeight(
      chartType === GoalImprovementChartType.Bar
        ? Math.max(MIN_BAR_CHART_HEIGHT, goals.length * 80)
        : MIN_RADAR_CHART_HEIGHT
    );
  }, [goals]);
  let tickWidth = 120;
  let radarOuterRadius = "90%";
  if (isMediumScreen) {
    tickWidth = 80;
    radarOuterRadius = "85%";
  } else if (isLargeScreen) {
    tickWidth = 100;
    radarOuterRadius = "80%";
  }
  const renderRadarChart = () => {
    return (
      <RadarChart cy="55%" outerRadius={radarOuterRadius} data={chartData}>
        <PolarGrid stroke={getDynamicColor("dark2")} />
        {/* tick font color for polar angle axis controlled by css override below */}
        <PolarAngleAxis
          dataKey="subject"
          stroke={getDynamicColor("dark2")}
          fontSize={"10px"}
          // don't expand tick widths until xl screen size and above)
          tick={{ width: tickWidth }}
        />
        <Tooltip content={CustomTooltip} cursor={{ fill: "transparent" }} />
        <Radar
          name="First score"
          dataKey={ScoreType.First}
          stroke={getDynamicColor("programChart.purpleDark")}
          fill={getDynamicColor("programChart.purple")}
          fillOpacity={0.75}
        />
        <Radar
          name="Max score"
          dataKey={ScoreType.Max}
          stroke={getDynamicColor("programChart.blueDark")}
          fill={getDynamicColor("programChart.blue")}
          fillOpacity={0.75}
        />
      </RadarChart>
    );
  };
  const renderBarChart = () => {
    return (
      <BarChart layout="vertical" data={chartData}>
        <CartesianGrid stroke={getDynamicColor("dark3")} />
        <XAxis type="number" fontSize="10px" stroke={getDynamicColor("dark4")} />
        <YAxis
          dataKey="subject"
          type="category"
          fontSize="10px"
          stroke={getDynamicColor("dark4")}
          tick={{ width: tickWidth }}
        />
        <Tooltip content={CustomTooltip} cursor={{ fill: "transparent" }} />
        <Bar
          name="First score"
          dataKey={ScoreType.First}
          stroke={getDynamicColor("programChart.purpleDark")}
          fill={getDynamicColor("programChart.purple")}
          fillOpacity={0.75}
        />
        <Bar
          name="Max score"
          dataKey={ScoreType.Max}
          stroke={getDynamicColor("programChart.blueDark")}
          fill={getDynamicColor("programChart.blue")}
          fillOpacity={0.75}
        />
      </BarChart>
    );
  };

  return (
    <Box
      sx={{
        height: "100%",

        position: "relative",
        width: "100%",
        ".recharts-surface": {
          overflow: "visible !important",
        },
        ".recharts-polar-angle-axis-tick-value": {
          fill: getDynamicColor("dark4"),
        },
        ".recharts-legend-item-text": {
          color: `${getDynamicColor("purple3")} !important`,
          fontSize: "12px",
        },
      }}
    >
      <ResponsiveContainer
        width="100%"
        height="100%"
        // set max AND min here, to act as a statically set height instead (Responsive container has no height attribute)
        minHeight={chartHeight}
        maxHeight={isMediumScreen ? chartHeight : undefined}
      >
        {chartType === GoalImprovementChartType.Radar ? renderRadarChart() : renderBarChart()}
      </ResponsiveContainer>
    </Box>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Stack
        sx={{
          backgroundColor: getDynamicColor("light1"),
          p: 2,
          borderRadius: "12px",
          border: `1px solid ${getDynamicColor("dark3")}`,
          fontFamily: "poppins",
          fontSize: 14,
        }}
        gap={1}
      >
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          {label}
        </Typography>
        <Stack gap={1}>
          {payload.map((p) => (
            <Stack key={p.dataKey} direction="row" gap={1} alignItems="center">
              <Box
                sx={{
                  height: GOAL_CHART_LEGEND_BOX_SIZE,
                  width: GOAL_CHART_LEGEND_BOX_SIZE,
                  backgroundColor: p.fill,
                  border: `1px solid ${p.stroke}`,
                }}
              ></Box>
              <Typography>
                {p.name}: {p.value}%
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    );
  }

  return null;
};
