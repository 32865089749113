export const SIGN_IN_CUSTOM_TOKEN_QUERY_PARAM = "authToken" as const;
export const ACCEPT_TOS_QUERY_PARAM = "acceptTOS" as const;
export const DEFAULT_ONBOARDING_QUERY_PARAM = "defaultOnboarding" as const;

// Korn Ferry Advance firebase auth SSO provider id
export const KFA_PROVIDER = "saml.kornferryadvance" as const;

export const KFA_SIGN_IN_REDIRECT = "https://client.kfadvance.com/login" as const;

// SAML related constants
export const SAML20_FULLNAME_CLAIM_ATTRIBUTE = "urn:oid:2.16.840.1.113730.3.1.241"; // SAML 2.0 attribute claim identifier for fullname
