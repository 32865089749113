import React from "react";

// Components
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box } from "@mui/material";

// Utils
// Utilities/Functions
import { getDynamicColor } from "lib-frontend/utils/Colors";

export enum SortStatus {
  NONE = "none",
  ASC = "asc",
  DESC = "desc",
}

type TableSortBaseProps = {
  sortCreated: SortStatus;
  setSortCreated: (status: SortStatus) => void;
  handleSortCreated: (e: unknown) => void;
};

export const TableSortBase = ({
  handleSortCreated,
  sortCreated,
}: TableSortBaseProps): JSX.Element => {
  return (
    <Box
      sx={{
        height: "18px",
        width: "18px",
        marginRight: "12px",
      }}
    >
      <ArrowDownwardIcon
        sx={{
          cursor: "pointer",
          transform: sortCreated === SortStatus.DESC ? "" : "rotate(180deg)",
          transitionDuration: "0.5s",
          height: "100%",
          width: "100%",
          "&:hover": {
            color: getDynamicColor("dark4"),
          },
          color: getDynamicColor(sortCreated === SortStatus.NONE ? "dark3" : "secondary"),
        }}
        fontSize="medium"
        onClick={(e) => handleSortCreated(e)}
      />
    </Box>
  );
};
