import React from "react";

// Components
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import YoodliLoading from "lib-frontend/components/Animations/yoodli_loading.json";

// Utils
import Lottie from "lottie-react";

type LoadingAnimationProps = {
  showPrompt: boolean;
  prompts?: string[];
  bgColor?: string;
};

export function LoadingAnimation(props: LoadingAnimationProps): JSX.Element {
  const prompts = props.prompts ?? [
    "Setting up stage...",
    "Testing mic...",
    "Raising curtain...",
    "Finishing dress rehearsal...",
    "Fine-tuning AV setup...",
    "Ushering in audience...",
    "Rolling out red carpet...",
    "Preparing for standing ovation...",
    "Building up suspense...",
  ];

  const getRandomPrompt = (arr) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };
  // Set initial value to random prompt
  const [prompt, setPrompt] = React.useState(getRandomPrompt(prompts));

  // Rotate through random prompts every 10s (or prop from parent)
  React.useEffect(() => {
    let interval = null;

    interval = setInterval(function () {
      setPrompt(getRandomPrompt(prompts));
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Lottie animationData={YoodliLoading} className="animation" loop autoplay />

      {props.showPrompt && <Typography fontSize="1.5rem">{prompt}</Typography>}
    </div>
  );
}

const ContainedWrapper = styled("div")((props: { bgColor: string }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  backgroundColor: props.bgColor ?? "#fff",
  margin: "0 auto",
  textAlign: "center",
  ".inner": {
    width: "100%",
    alignSelf: "center",
  },
  ".animation": {
    width: "50%",
    margin: "0 auto",
  },
}));

// Loading animation for a container.
export function ContainedLoadingAnimation(props: LoadingAnimationProps): JSX.Element {
  return (
    <ContainedWrapper bgColor={props.bgColor}>
      <div className="inner">
        <LoadingAnimation showPrompt={props.showPrompt} prompts={props.prompts} />
      </div>
    </ContainedWrapper>
  );
}

// Loading animation that covers entire screen
export function FullscreenLoadingAnimation(props: LoadingAnimationProps): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: props.bgColor ?? "#fff",
        position: "fixed",
        zIndex: 14000,
        ".inner": {
          margin: "0 auto",
          textAlign: "center",
        },
        ".animation": {
          height: "25vh",
          maxWidth: 500,
        },
      }}
    >
      <div className="inner">
        <LoadingAnimation showPrompt={props.showPrompt} />
      </div>
    </Box>
  );
}
