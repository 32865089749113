import React from "react";
import { useDropzone } from "react-dropzone";

// Components
import { Button, Stack, SxProps, Typography } from "@mui/material";
import { WizardSubTitle, WizardTitle } from "components/Wizard/WizardTitles";

// Assets
import { ReactComponent as PDFIcon } from "images/icons/icon-coachbot-upload-pdf.svg";
import { ReactComponent as VideoIcon } from "images/icons/icon-coachbot-upload-video.svg";

// Utils
import { CoachBotUploadedFile } from "../../CoachBotTypes";
import { UploadedFiles } from "./UploadedFiles";
import {
  createCoachBotContent,
  deleteCoachBotContent,
  patchCoachBotContent,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { uploadBlobToResumableUploadUrl, UploadProgress } from "lib-frontend/utils/resumableUpload";
import { GetAllCoachBotContentResponse } from "lib-fullstack/api/hubApiTypes";
import { AnalyticProcessingState } from "lib-fullstack/utils/enums";
import prettyBytes from "pretty-bytes";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { useQueryClient } from "@tanstack/react-query";
import { getEnabledFlag } from "lib-frontend/utils/unleash";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgCoachbotType } from "lib-fullstack/utils/productAnalyticEvents";

type CoachBotUploadContentProps = {
  coachBotName: string;
  setLoading: (loading: boolean) => void;
  uploadedFiles: CoachBotUploadedFile[];
  setUploadedFiles: (files: CoachBotUploadedFile[]) => void;
  orgId: string;
  botId: string;
  // the below function is used inside of the onProgress callback, so need to be ref getter functions rathe than actual references
  // that way the onProgress callback always has the correct value. If we were to just use the ref, it wouldnt trigger a re render when it changes
  getCurrentUploadedFiles: () => CoachBotUploadedFile[];
  overrideSx?: SxProps;
  hideTitle?: boolean;
  hideUploadedFiles?: boolean;
  maxFilesReached?: boolean;
  filesLeftToUpload?: number;
  handleUpdateCoachBotContentFilename: (contentId: string, newFileName: string) => Promise<void>;
  coachBotContent?: GetAllCoachBotContentResponse["botContent"];
  coachBotType?: OrgCoachbotType;
};

export const TEXTFIELD_WIDTH = 540;
export const UPLOAD_WIDTH = {
  xs: "unset",
  md: TEXTFIELD_WIDTH,
  lg: TEXTFIELD_WIDTH + 100,
  xl: TEXTFIELD_WIDTH + 200,
};
export const MAX_FILES = 15;

export const CoachBotUploadContent = ({
  coachBotName,
  setLoading,
  uploadedFiles,
  setUploadedFiles,
  orgId,
  botId,
  getCurrentUploadedFiles,
  overrideSx,
  hideTitle,
  hideUploadedFiles,
  coachBotContent,
  maxFilesReached: maxFilesReachedProp,
  filesLeftToUpload,
  handleUpdateCoachBotContentFilename,
  coachBotType,
}: CoachBotUploadContentProps): JSX.Element => {
  const queryClient = useQueryClient();
  const [error, setError] = React.useState<string>(undefined);
  const [invalidFiles, setInvalidFiles] = React.useState<File[]>([]);
  const [editingFileId, setEditingFileId] = React.useState<string | null>(null);
  const [_loadingFileId, setLoadingFileId] = React.useState<string | null>(null);
  const [newFileName, setNewFileName] = React.useState<string>("");

  const maxFilesReached = maxFilesReachedProp ?? uploadedFiles?.length >= MAX_FILES;
  const fileInputRef = React.useRef(null);
  const amtOfFilesLeft = filesLeftToUpload
    ? filesLeftToUpload > 0
      ? `${filesLeftToUpload} more`
      : MAX_FILES
    : uploadedFiles?.length > 0
    ? `${MAX_FILES - uploadedFiles?.length} more`
    : MAX_FILES;
  let subTitleCopy = `Choose up to ${amtOfFilesLeft} files. PDF and video filetypes only. 50 MB max size per PDF, 5 GB max size per video.`;
  if (maxFilesReached) {
    subTitleCopy = `You've reached the max upload limit (${MAX_FILES}).`;
  }

  const fileTypes: { mime: string; regexp: RegExp | null; maxSize: number }[] = [
    { mime: "application/pdf", regexp: null, maxSize: 50 * 1024 * 1024 /* 50MB */ },
    { mime: "video/*", regexp: new RegExp("^video/.*"), maxSize: 5 * 1024 * 1024 * 1024 /* 5GB */ },
  ];
  if (getEnabledFlag("autogen")) {
    fileTypes.push({
      mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation" /* PPTX */,
      regexp: null,
      maxSize: 200 * 1024 * 1024 /* 200 MB */,
    });
    fileTypes.push({
      mime: "application/vnd.ms-powerpoint" /* PPT */,
      regexp: null,
      maxSize: 200 * 1024 * 1024 /* 200 MB */,
    });
    fileTypes.push({
      mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" /* DOCX */,
      regexp: null,
      maxSize: 200 * 1024 * 1024 /* 200 MB */,
    });
    fileTypes.push({
      mime: "application/msword" /* DOC */,
      regexp: null,
      maxSize: 200 * 1024 * 1024 /* 200 MB */,
    });
    fileTypes.push({
      mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /* XLSX */,
      regexp: null,
      maxSize: 200 * 1024 * 1024 /* 200 MB */,
    });
    fileTypes.push({
      mime: "application/vnd.ms-excel" /* XLS */,
      regexp: null,
      maxSize: 200 * 1024 * 1024 /* 200 MB */,
    });
  }
  const acceptsString = fileTypes.map((x) => x.mime).join(",");

  const handleSetUploadedFiles = (files: CoachBotUploadedFile[]) => {
    setUploadedFiles(files);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles?.length) {
        setLoading(true);
        try {
          await doUploadFiles(acceptedFiles);
        } catch (err) {
          console.log(`Error uploading files: ${err}`);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    },
    accept: acceptsString,
    maxFiles: 10 - (uploadedFiles?.length ?? 0),
  });

  const mapFilesToCoachBotContentResponse = (botContent, files: File[]): CoachBotUploadedFile[] => {
    return files
      .map((file) => {
        const fileMatch = botContent.find((content) => content.filename === file.name);
        if (!fileMatch) {
          return null;
        }
        return {
          data: file,
          filename: file.name,
          id: fileMatch.id,
          uploadUrl: fileMatch.uploadUrl,
          uploadStatus: "readyToUpload",
          uploadProgress: 0,
        } as CoachBotUploadedFile;
      })
      .filter(Boolean);
  };

  const onProgress = (fileId: string, progress: UploadProgress) => {
    const progressPct = Math.floor((progress.uploadedBytes / progress.totalBytes) * 100);
    handleUpdateFileProgress(fileId, progressPct);
    return true;
  };

  // now attempt to begin the uploads
  const uploadToGCS = async (file: CoachBotUploadedFile) => {
    // kick off the upload only if it's ready to upload
    if (file.uploadStatus === "readyToUpload") {
      await uploadBlobToResumableUploadUrl(
        file.data,
        file.uploadUrl,
        // TODO @dwiegand: this progress callback doesnt have access to the current value of uploadedFiles so its not updating correctly
        // need to swithc to using refs or something to give this the correct value
        // for now, just ignoring so i can complete hooking things up
        (progress: UploadProgress) => onProgress(file.id, progress),
        `upload (${file.data.name})`,
        4194304 // 4MB
      );
    }
  };

  // Return how much the file is over the limit in bytes
  // Returning 0 if it's not over the limit
  const getBytesOver = (file: File): number => {
    for (const { mime, regexp, maxSize } of fileTypes) {
      if ((regexp && regexp.test(file.type)) || (!regexp && file.type === mime)) {
        return Math.max(file.size - maxSize, 0);
      }
    }
    // It is unexpected to reach here. Return 1 to block this file anyway.
    console.warn(`Unexpected file type: ${file.type}`);
    return 1;
  };

  const isValidFile = (file: File): boolean => {
    return getBytesOver(file) === 0;
  };

  const doUploadFiles = async (files: File[]) => {
    try {
      const invalidFiles = files.filter((file) => !isValidFile(file));
      const validFiles = files.filter((file) => isValidFile(file));

      setInvalidFiles(invalidFiles?.length ? invalidFiles : []);
      if ((coachBotContent?.length ?? 0) + validFiles.length > MAX_FILES) {
        setError(`You can only upload up to ${MAX_FILES} files.`);
      } else {
        setLoading(true);
        // create the content in the backend, which returns the uploadUrls for us to use
        const data = await createCoachBotContent(
          orgId,
          botId,
          validFiles.map((x) => ({
            filename: x.name,
            fileType: x.type,
          }))
        );
        void queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.Coachbots, orgId],
        });
        const newFiles = mapFilesToCoachBotContentResponse(data.botContent, validFiles);
        Instrumentation.logOrgCoachbotUpdated(orgId, coachBotType);
        // update the state with the new files
        handleSetUploadedFiles([...(uploadedFiles ?? []), ...(newFiles ?? [])]);
        // kick off the uploads here, but don't wait for them to finish
        newFiles.forEach(uploadToGCS);
      }
    } catch (err) {
      setError(err.message);
      console.log(`Error uploading files: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCoachBotContentFilenameLocal = async (fileId: string, newFileName: string) => {
    // optimistically update the filename in the UI before refetching bot content
    const optimisticallyUpdatedUploadedFiles = uploadedFiles.map((file) => {
      if (file.id === fileId) {
        return { ...file, filename: newFileName };
      }
      return file;
    });
    if (newFileName.length > 0) {
      await handleUpdateCoachBotContentFilename(editingFileId, newFileName);
      setUploadedFiles(optimisticallyUpdatedUploadedFiles);
    }
  };

  const handleSetEditingFileId = async (fileId: string) => {
    // if this is called while already editing, save the current file name
    if (editingFileId) {
      setEditingFileId(editingFileId === fileId ? null : fileId);
      setLoadingFileId(editingFileId);
      await handleUpdateCoachBotContentFilenameLocal(editingFileId, newFileName);
      setLoadingFileId(null);
    } else {
      setEditingFileId(fileId);
      setNewFileName(uploadedFiles.find((x) => x.id === fileId)?.filename ?? "");
    }
  };

  const handleRemoveFile = async (fileId: string) => {
    const newFiles = uploadedFiles.filter((file) => file.id !== fileId);
    await deleteCoachBotContent(orgId, botId, fileId);
    Instrumentation.logOrgCoachbotUpdated(orgId, coachBotType);
    handleSetUploadedFiles(newFiles);
    // optimistically remove the file from the UI before refetching bot content
  };

  const handleUpdateFileProgress = (fileId: string, progress: number) => {
    const currFiles = getCurrentUploadedFiles();
    const isComplete = progress >= 100;
    const newFiles = currFiles.map((file) => {
      if (file.id === fileId) {
        return {
          ...file,
          uploadProgress: progress,
          uploadStatus: isComplete ? "done" : "uploading",
        };
      }
      return file;
    });

    handleSetUploadedFiles(newFiles as CoachBotUploadedFile[]);
    // when upload completes, manually change the status to kick off processing, and set active
    // but only if the uploaded file is not already at 100% (this is because progress is called twice on finish, once at 100% and once to "complete")
    if (isComplete && currFiles.find((x) => x.id === fileId)?.uploadProgress !== 100) {
      void patchCoachBotContent(orgId, botId, fileId, {
        status: AnalyticProcessingState.PROCESSING,
        isActive: true,
      });
      Instrumentation.logOrgCoachbotUpdated(orgId, coachBotType);
    }
  };

  const renderUploadDropzone = () => {
    let mdPy = uploadedFiles?.length || hideTitle ? 4 : 6;
    if (maxFilesReached) {
      mdPy = 0;
    }
    return (
      <Stack
        gap={{ xs: 2, md: 4 }}
        alignItems="center"
        {...getRootProps()}
        onClick={undefined}
        sx={{
          borderRadius: "20px",
          border: {
            xs: "none",
            md: `2px dashed ${maxFilesReached ? "transparent" : getDynamicColor("dark3")}`,
          },
          py: { xs: 2, md: mdPy },
          width: "100%",
          maxWidth: UPLOAD_WIDTH,
          transition: "all .5s ease",
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            transition: "all .7s ease",
          }}
        >
          <Stack
            direction="row"
            gap={4}
            sx={{
              color: maxFilesReached ? getDynamicColor("dark4") : getDynamicColor("primary"),
              svg: {
                height: maxFilesReached ? 50 : 60,
                width: maxFilesReached ? 50 : 60,
                transition: "all .7s ease",
              },
            }}
          >
            <PDFIcon />
            <VideoIcon />
          </Stack>
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Typography
              color={getDynamicColor("dark4")}
              fontSize="20px"
              fontWeight={500}
              sx={{
                display: { xs: "none", md: "block" },
                height: maxFilesReached ? 0 : 46,
                opacity: maxFilesReached ? 0 : 1,
                transition:
                  "margin .5s ease 0.2s, padding .5s ease 0.2s, height .5s ease 0.2s, opacity 0.4s ease",
                overflow: "visible",
                pt: maxFilesReached ? 0 : 2,
              }}
            >
              Drag PDF/video files here
            </Typography>
            {error && (
              <Typography color={getDynamicColor("redError")} fontSize="14px" fontWeight={700}>
                {error}
              </Typography>
            )}
            <Button
              onClick={(e) => {
                if (!maxFilesReached) {
                  getRootProps()?.onClick(e);
                }
              }}
              disabled={maxFilesReached}
              variant="outlined"
              sx={{
                height: maxFilesReached ? 0 : 44,
                px: 2.5,
                py: maxFilesReached ? 0 : 1,
                mt: maxFilesReached ? 0 : 3,
                opacity: maxFilesReached ? 0 : 1,
                border: maxFilesReached
                  ? "0px solid transparent"
                  : `2px solid ${getDynamicColor("primary")}`,
                borderRadius: "50px",
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
                width: { xs: "100%", md: "max-content" },
                transition:
                  "margin .5s ease 0.2s, padding .5s ease 0.2s, height .5s ease 0.2s, opacity 0.4s ease, border .5s ease 0.2s",
              }}
            >
              Upload from device
            </Button>
          </Stack>
        </Stack>
        <input
          type="file"
          accept={acceptsString}
          ref={fileInputRef}
          style={{ display: "none" }}
          multiple
          {...getInputProps()}
        />
      </Stack>
    );
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        position: "relative",
        px: { xs: 2, md: 0 },
        py: { xs: 3, md: 6 },
        ...overrideSx,
      }}
      gap={{ xs: 1, md: 2.5 }}
    >
      <Stack
        sx={{
          width: UPLOAD_WIDTH,
        }}
        gap={1}
      >
        {!hideTitle && (
          <WizardTitle
            overrideSx={{
              px: { xs: 1, md: 2 },
            }}
          >
            Upload content for {coachBotName}
          </WizardTitle>
        )}
        <WizardSubTitle
          overrideSx={{
            color: maxFilesReached ? getDynamicColor("redError") : getDynamicColor("dark5"),
            px: { xs: 1, md: 2 },
          }}
        >
          {subTitleCopy}
        </WizardSubTitle>
      </Stack>
      {renderUploadDropzone()}
      {invalidFiles?.length > 0 && (
        <Stack
          direction="column"
          gap={1}
          sx={{
            border: `1px solid ${getDynamicColor("dark3")}`,
            borderRadius: "6px",
            width: "min(100%, 540px)",
          }}
        >
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontSize: 14,
              fontWeight: 600,
              fontFamily: "poppins",
              px: 2,
              pt: 1.5,
              pb: 1,
              borderBottom: `1px solid ${getDynamicColor("dark3")}`,
            }}
          >
            Unable to upload the following files
          </Typography>
          <Stack
            gap={0.5}
            direction="column"
            sx={{
              width: "100%",
            }}
          >
            {invalidFiles.map((file, i) => {
              return (
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", md: "center" }}
                  sx={{
                    backgroundColor:
                      i % 2 === 0
                        ? getDynamicColor("light1")
                        : getDynamicColor("redErrorLight", 0.5),
                    borderRadius: "6px",
                    px: 2,
                    py: 0.5,
                  }}
                >
                  <Typography
                    key={file.name}
                    sx={{
                      width: "100%",
                      flexGrow: 1,
                      color: getDynamicColor("dark4"),
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "poppins",

                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {file.name}
                  </Typography>
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontWeight: 700,
                      color: getDynamicColor("redError"),
                      minWidth: 150,
                      width: "fit-content",
                      textAlign: "right",
                      fontSize: 14,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {prettyBytes(getBytesOver(file))} over the limit
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}
      {!hideUploadedFiles && (
        <UploadedFiles
          files={getCurrentUploadedFiles() ?? []}
          handleRemoveFile={handleRemoveFile}
          editingFileId={editingFileId}
          handleSetEditingFileId={handleSetEditingFileId}
          newFileName={newFileName}
          setNewFileName={setNewFileName}
          handleUpdateCoachBotContentFilename={handleUpdateCoachBotContentFilenameLocal}
        />
      )}
    </Stack>
  );
};
