import React from "react";

// Components
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Button, Stack, Typography, Box } from "@mui/material";
import PricingModal from "components/Pricing/PricingModal";

// Assets
import { ReactComponent as UpgradeIcon } from "images/icons/icon-upgrade.svg";

// Utils
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { PricingModalCtaLocations } from "lib-fullstack/utils/productAnalyticEvents";

export default function OutOfRecordingsModal(): JSX.Element {
  const [showPricingModal, setShowPricingModal] = React.useState(false);
  const handleTogglePricingModal = (open?: boolean) => {
    setShowPricingModal(open);
    if (open) {
      Instrumentation.logPricingModalViewed(
        PricingModalCtaLocations.ZOODLI,
        "UPGRADE",
        window.location.href
      );
    }
  };

  return (
    <Stack
      sx={{
        width: "min(350px, 100%)",
        height: "200px",
        px: { xs: 4, sm: 11.5 },
        py: { xs: 4, sm: 5 },
        borderRadius: "12px",
        boxShadow: Y_SHADOWS.box_shadow_1,
        backgroundColor: getDynamicColor("light1"),
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
        zIndex: 1,
      }}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{ color: getDynamicColor("redError") }}
        alignItems="center"
      >
        <RadioButtonCheckedIcon sx={{ width: "16px", height: "16px" }} />
        <Typography
          sx={{
            fontSize: "11px",
            fontStyle: "italic",
            fontWeight: 400,
          }}
        >
          You’re out of Yoodlis
        </Typography>
      </Stack>
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontSize: "16px",
          fontWeight: 700,
        }}
      >
        Upgrade to use Yoodli for your team!
      </Typography>
      <Button
        onClick={() => handleTogglePricingModal(true)}
        variant="gradient"
        startIcon={
          <Box sx={{ width: "19px", height: "16px" }}>
            <UpgradeIcon />
          </Box>
        }
        sx={{
          width: "105px",
          height: "24px",
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 700,
          letterSpacing: "0.6px",
        }}
      >
        UPGRADE
      </Button>
      <PricingModal open={showPricingModal} onClose={() => setShowPricingModal(false)} />
    </Stack>
  );
}
