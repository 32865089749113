// Components
import { Stack, Typography } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type MinMaxScoreSelectorProps = {
  min: ScoreSelector;
  max: ScoreSelector;
};

type ScoreSelector = {
  value: number;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  onChange?: (val: string) => void;
};

export const MinMaxScoreSelector = ({ min, max }: MinMaxScoreSelectorProps): JSX.Element => {
  const restrictValueToRange = (value: string, min: number, max: number) => {
    if (value) {
      if (Number(value) < min) {
        return min;
      }
      if (Number(value) > max) {
        return max;
      }
    }
    return value;
  };
  return (
    <Stack direction="row" gap={1.5} sx={{ alignItems: "center" }}>
      <YoodliTextfield
        sx={{
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
        }}
        inputProps={{
          sx: {
            width: "50px",
            textAlign: "center",
            fontWeight: 700,
            fontSize: 24,
          },
        }}
        InputProps={{
          sx: {
            mr: 0,
          },
        }}
        disabled={min.disabled}
        type="number"
        value={min.value}
        onChange={(e) =>
          min.onChange &&
          max.onChange(restrictValueToRange(e.target.value, max.minValue, max.maxValue).toString())
        }
        error={max.value <= min.value}
      />
      <Typography
        component="div"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          fontSize: 24,
        }}
      >
        -
      </Typography>
      <YoodliTextfield
        sx={{
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: getDynamicColor("purple3"),
          },
        }}
        inputProps={{
          sx: {
            width: "50px",
            textAlign: "center",
            fontWeight: 700,
            fontSize: 24,
          },
        }}
        InputProps={{
          sx: {
            mr: 0,
          },
        }}
        type="number"
        value={restrictValueToRange(max.value?.toString(), max.minValue, max.maxValue) ?? ""}
        onChange={(e) =>
          max.onChange &&
          max.onChange(restrictValueToRange(e.target.value, max.minValue, max.maxValue).toString())
        }
        error={max.value <= min.value}
      />
    </Stack>
  );
};
