// Utils
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

/**
 * Enum indicating the type of the context association. Used for casting and type checking at runtime.
 */
export enum IntegrationContextAssociationTypeEnum {
  /**
   * The context is associatead with a scenario.
   */
  Scenario = "scenario",
  /**
   * The context is associated with a program.
   */
  Program = "program",
}

/**
 * Enum indicating the type of the context association. Used for casting and type checking at runtime.
 */
export const IntegrationContextAssociationType = RTStringEnum(
  IntegrationContextAssociationTypeEnum
);
