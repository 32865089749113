// Utils
/**
 * Organization and Hub V2 API types
 * Eventually, remaining V1 API types will be moved here
 */
import {
  RTEffectiveRole,
  RTOrgRole,
  RTHubRole,
  RTOrgCustomerType,
  RTOBQ1Option,
  RTOrgSubscriptionType,
  RTOrgCreationMethod,
  RTAuthProvider,
} from "lib-fullstack/utils/enums";
import { BooleanStringType } from "lib-fullstack/utils/enums";
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";
import {
  Array as RTArray,
  Union,
  Optional,
  Null,
  Boolean,
  Number,
  Record,
  Static,
  String,
} from "runtypes";

export const CreateOrgV2Request = Record({
  name: String,
  customer_type: RTOrgCustomerType,
  subscription_type: RTOrgSubscriptionType,
  data_redaction_days: Number,
  default_onboarding: Optional(RTOBQ1Option),
  owner_id: Optional(String),
  org_creation_method: Optional(RTOrgCreationMethod),
  include_example_goals: Optional(Boolean),
});
export type CreateOrgV2Request = Static<typeof CreateOrgV2Request>;

export const UpdateOrgV2Request = Record({
  name: Optional(String),
  data_redaction_days: Optional(Number),
  default_onboarding: Optional(RTOBQ1Option),
  onboarding_checklist_enabled: Optional(Boolean),
  presentation_enabled: Optional(Boolean),
  roleplay_enabled: Optional(Boolean),
  interview_enabled: Optional(Boolean),
  zoodli_for_users_enabled: Optional(Boolean),
  desktop_app_enabled: Optional(Boolean),
  presentation_ftux_prompt_override: Optional(Union(String, Null)),
  sign_in_options: Optional(RTArray(RTAuthProvider)),
  member_builder_enabled: Optional(Boolean),
  support_access_enabled: Optional(Boolean),
});
export type UpdateOrgV2Request = Static<typeof UpdateOrgV2Request>;

export const HubV2Response = Record({
  id: String,
  name: String,
  org_default: Boolean,
  hub_role: Optional(Union(RTHubRole, Null)), // #8634: short term for v1 UI
  admin_emails: Optional(RTArray(String)),
  creation_date: String,
});
export type HubV2Response = Static<typeof HubV2Response>;

export const OrgV2Response = Record({
  id: String,
  name: String,
  effective_role: Union(RTEffectiveRole, Null), // Some API do not provide this
  caller_default: Union(Boolean, Null), // Some API do not provide this
  email_domains: RTArray(String),
  default_hub_id: String,
  data_redaction_days: Number,
  license_count: Number,
  /**
   * License count from base contract of flexible plan.
   * For prepaid plan it's as same as total. For PAYG, it's 0.
   */
  license_count_base: Number,
  /** Per seat price for flexible and PAYG plans. 0 for prepaid. */
  per_seat_dollars: Number,
  license_used: Number,
  /** Stripe subscription renewal date */
  renewal_date: Union(String, Null),
  /** Stripe subscription cancellation date (end date) */
  cancellation_date: Union(String, Null),
  is_trial: Boolean,
  hub_quota: Number,
  subscription_type: RTOrgSubscriptionType,
  customer_type: RTOrgCustomerType,
  payment_method_added: Boolean,
  presentation_ftux_prompt_override: Union(String, Null),
  default_onboarding: Union(RTOBQ1Option, Null),
  onboarding_checklist_enabled: Boolean,
  self_removal_allowed: Boolean,
  hubs: Union(RTArray(HubV2Response), Null), // Some API do not provide this
  coach_bot_enabled: Boolean,
  custom_goal_enabled: Boolean,
  sso_options: RTArray(String),
  sign_in_options: RTArray(RTAuthProvider),
  num_seat_inactive_days: Optional(Number),
  support_access_enabled: Optional(Boolean),
});
export type OrgV2Response = Static<typeof OrgV2Response>;

export const OrgV2ListItemResponse = Record({
  id: String,
  name: String,
  effective_role: RTEffectiveRole,
  caller_default: Boolean,
});
export type OrgV2ListItemResponse = Static<typeof OrgV2ListItemResponse>;

export const OrgV2ListResponse = Record({
  orgs: RTArray(OrgV2ListItemResponse),
});
export type OrgV2ListResponse = Static<typeof OrgV2ListResponse>;

export enum GetOrgMemberListSortOption {
  NAME_ASC = "name",
  NAME_DESC = "-name",
  EMAIL_ASC = "email",
  EMAIL_DESC = "-email",
  DATE_LAST_ACTIVITY_ASC = "date_last_activity",
  DATE_LAST_ACTIVITY_DESC = "-date_last_activity",
  DATE_JOINED_ASC = "date_joined",
  DATE_JOINED_DESC = "-date_joined",
  NUM_STARTED_SPEECHES_ASC = "num_started_speeches",
  NUM_STARTED_SPEECHES_DESC = "-num_started_speeches",
}
export const GetOrgMemberListSortOptionType = RTStringEnum(GetOrgMemberListSortOption);

export enum GetOrgMemberListField {
  HUBS = "hubs",
}
export const GetOrgMemberListFieldType = RTStringEnum(GetOrgMemberListField);

export const GetOrgMemberListQueryParams = Record({
  effective_roles: Optional(String), // comma separated list of EffectiveRole
  sort: Optional(GetOrgMemberListSortOptionType),
  start: Optional(String),
  limit: Optional(String),
  prefix: Optional(String),
  fields: Optional(String), // comma separated list of GetOrgMemberListFieldType
});
export type GetOrgMemberListQueryParams = Static<typeof GetOrgMemberListQueryParams>;

export const HubMemberResponse = Record({
  hub_id: String,
  name: String,
  role: RTHubRole,
  date_joined: String,
});
export type HubMemberResponse = Static<typeof HubMemberResponse>;

export const OrgMemberResponse = Record({
  user_id: String,
  name: String,
  email: String,
  role: Union(RTOrgRole, Null), // not emit for hub member list
  effective_role: Union(RTEffectiveRole, Null), // not emit for hub member list
  date_last_activity: Union(String, Null),
  date_joined: String,
  num_started_speeches: Number,
  hubs: RTArray(HubMemberResponse),
  seat_inactive: Boolean,
});
export type OrgMemberResponse = Static<typeof OrgMemberResponse>;

export const OrgMemberListResponse = Record({
  users: RTArray(OrgMemberResponse),
  next: Boolean,
  total: Number,
});
export type OrgMemberListResponse = Static<typeof OrgMemberListResponse>;

export const GetHubMemberListQueryParams = Record({
  roles: Optional(String), // comma separated list of Role
  sort: Optional(RTStringEnum(GetOrgMemberListSortOption)),
  start: Optional(String),
  limit: Optional(String),
  prefix: Optional(String),
});
export type GetHubMemberListQueryParams = Static<typeof GetHubMemberListQueryParams>;

export const DeleteHubQueryParams = Record({
  transfer: Optional(BooleanStringType),
});
export type DeleteHubQueryParams = Static<typeof DeleteHubQueryParams>;

export const AddOrgUsersRequest = Record({
  emails: RTArray(String),
  org_role: Union(RTOrgRole, Null),
  hub_role: Union(RTHubRole, Null),
  hub_ids: Union(RTArray(String), Null),
  send_invite_email: Boolean,
  welcome_message: Optional(String),
});
export type AddOrgUsersRequest = Static<typeof AddOrgUsersRequest>;

export enum AddOrgUserResult {
  ADDED = "added",
  INVITE_WITHOUT_EMAIL = "invite_without_email",
  NO_CHANGE = "no_change",
  REJECTED = "rejected",
  INTERNAL_ERROR = "internal_error",
  NO_MORE_LICENSE = "no_more_license",
  INVITE_WITH_EMAIL = "invite_with_email",
}

export const AddOrgUserResponse = Record({
  email: String,
  result: RTStringEnum(AddOrgUserResult),
});
export type AddOrgUserResponse = Static<typeof AddOrgUserResponse>;

export const AddOrgUsersResponse = Record({
  results: RTArray(AddOrgUserResponse),
});
export type AddOrgUsersResponse = Static<typeof AddOrgUsersResponse>;

export enum GetOrgInviteListSortOption {
  EMAIL_ASC = "email",
  EMAIL_DESC = "-email",
  DATE_INVITED_ASC = "date_invited",
  DATE_INVITED_DESC = "-date_invited",
}
export const GetOrgInviteListSortOptionType = RTStringEnum(GetOrgInviteListSortOption);

export const GetOrgInviteListQueryParams = Record({
  sort: Optional(GetOrgInviteListSortOptionType),
  start: Optional(String),
  limit: Optional(String),
  hub_id: Optional(String),
  prefix: Optional(String),
});
export type GetOrgInviteListQueryParams = Static<typeof GetOrgInviteListQueryParams>;

export const OrgInviteResponse = Record({
  email: String,
  name: Union(String, Null),
  role: Union(RTOrgRole, Null),
  effective_role: RTEffectiveRole,
  date_invited: String,
  hubs: RTArray(Record({ hub_id: String, role: RTHubRole })),
});
export type OrgInviteResponse = Static<typeof OrgInviteResponse>;

export const MigrateOrgInviteV1ToV2Response = OrgInviteResponse.extend({
  id: String,
});
export type MigrateOrgInviteV1ToV2Response = Static<typeof MigrateOrgInviteV1ToV2Response>;

export const OrgInviteListResponse = Record({
  invites: RTArray(OrgInviteResponse),
  next: Boolean,
  total: Number,
});
export type OrgInviteListResponse = Static<typeof OrgInviteListResponse>;

export enum OrgInviteAction {
  ACCEPT = "accept",
  RESEND = "resend",
}

export const ActOrgInviteRequest = Record({
  action: RTStringEnum(OrgInviteAction),
});
export type ActOrgInviteRequest = Static<typeof ActOrgInviteRequest>;

export const UpsetOrgSubscriptionRequest = Record({
  num_seats: Number,
  success_url: Optional(Union(String, Null)),
  cancel_url: Optional(Union(String, Null)),
});
export type UpsetOrgSubscriptionRequest = Static<typeof UpsetOrgSubscriptionRequest>;

export enum UpsetOrgSubscriptionResult {
  REDIRECT = "redirect",
  SUBSCRIPTION_UPDATED = "subscription_updated",
  FAIL_NOT_ACCEPTABLE = "fail_not_acceptable",
  FAIL_OTHER = "fail_other",
}

export const UpsetOrgSubscriptionResponse = Record({
  result: RTStringEnum(UpsetOrgSubscriptionResult),
  redirect_url: Union(String, Null),
  error_info: Union(String, Null),
});
export type UpsetOrgSubscriptionResponse = Static<typeof UpsetOrgSubscriptionResponse>;

export const UpdateOrgPaymentMethodRequest = Record({
  success_url: Optional(Union(String, Null)),
  cancel_url: Optional(Union(String, Null)),
});
export type UpdateOrgPaymentMethodRequest = Static<typeof UpdateOrgPaymentMethodRequest>;

export const UpdateOrgPaymentMethodResponse = Record({
  redirect_url: String,
});
export type UpdateOrgPaymentMethodResponse = Static<typeof UpdateOrgPaymentMethodResponse>;
