import { db } from "lib-fullstack";
import React from "react";

// Components
import { Button, Stack, Typography, Dialog } from "@mui/material";

// Utils
import MeetingLinkInput from "./MeetingLinkInput";
import OutOfRecordingsModal from "./OutOfRecordingsModal";
import { usePricingUsage } from "hooks";
import { useShouldRenderPricingGate } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { RecordingSupportedLanguage } from "lib-fullstack/utils/enums";

const HOW_TO_ZOODLI_LINK =
  "https://storage.googleapis.com/yoodli-public/misc-product-videos/how_to_team_mode.mp4";
const ZOOM_PERMISSION_TROUBLESHOOT_LINK =
  "https://docs.google.com/document/d/17SW_ckbG8IuZdfJZgNn9v2X8HjvFVTkr5DexHD7HT7U/edit#heading=h.rctp3o5mnsk5";

type AddMeetingLinkProps = {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  setError: (val: string) => void;
  error?: string;
  joinLastZoomCall: () => void;
  lastDbBot: db.Doc<db.RecallZoomBot>;
  modalContainerRef: React.RefObject<HTMLDivElement>;
  language: RecordingSupportedLanguage;
  setLanguage: React.Dispatch<React.SetStateAction<RecordingSupportedLanguage>>;
};

// Layout for adding a new meeting link
const AddMeetingLink = ({
  onSubmit,
  setError,
  error,
  joinLastZoomCall,
  lastDbBot,
  modalContainerRef,
  language,
  setLanguage,
}: AddMeetingLinkProps): JSX.Element => {
  const { thresholdHit } = usePricingUsage();
  const shouldRenderPricingGate = useShouldRenderPricingGate();

  return (
    <form style={{ width: "100%" }} onSubmit={onSubmit}>
      <Stack
        gap={2}
        sx={{
          position: "relative",
          color: getDynamicColor("purple3"),
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "poppins",
        }}
      >
        <Typography>
          Invite us to join your meeting as a participant and receive feedback and communications
          coaching for everyone on the call - best for speech coaches and corporate teams.
        </Typography>
        <Typography>Paste your meeting link to try recording an ongoing call.</Typography>
        <MeetingLinkInput
          resetError={() => {
            if (error) {
              setError("");
            }
          }}
          tutorialVideoUrl={HOW_TO_ZOODLI_LINK}
          language={language}
          setLanguage={setLanguage}
        />

        {lastDbBot && (
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={joinLastZoomCall}
              sx={{
                borderRadius: "50px",
                py: 1,
                px: 3,
                fontWeight: 700,
              }}
            >
              Join last call
            </Button>
            {lastDbBot.data.fullBotStatus.status_changes.find(
              (status) => status.code === "recording_permission_denied"
            ) && (
              <Typography
                sx={{
                  width: "50%",
                  fontSize: "14px",
                  color: getDynamicColor("redError"),
                  fontWeight: 500,
                  textAlign: "end",
                }}
              >
                We were unable to record due to a permissions issue. Click{" "}
                <a
                  href={ZOOM_PERMISSION_TROUBLESHOOT_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: getDynamicColor("redError") }}
                >
                  here
                </a>{" "}
                for help!
              </Typography>
            )}
          </Stack>
        )}
        <Dialog
          container={modalContainerRef.current}
          open={shouldRenderPricingGate && thresholdHit}
          slotProps={{ backdrop: { style: { position: "absolute" } } }}
          PaperComponent={OutOfRecordingsModal}
          sx={{ position: "absolute", zIndex: 1 }}
        />
      </Stack>
    </form>
  );
};

export default AddMeetingLink;
