// Components
import { Stack, Button, CircularProgress } from "@mui/material";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { PersonaResponse } from "lib-fullstack/api/scenarioApiTypes";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";

type PersonaFormFooterProps = {
  newPersona: PersonaResponse;
  saving: boolean;
  handleSavePersona: () => void;
};

export const PersonaFormFooter = ({
  newPersona,
  saving,
  handleSavePersona,
}: PersonaFormFooterProps): JSX.Element => {
  const { navDrawerOffset, modalStyles } = useNavDrawerOffset();

  const savingDisabled =
    saving ||
    !newPersona.name ||
    !newPersona.job_title ||
    !newPersona.demeanor ||
    !newPersona.voice_id ||
    !newPersona.profile_picture_id;

  return (
    <Stack
      justifyContent="center"
      sx={{
        height: WIZARD_FOOTER_HEIGHT,
        px: 3,
        position: "fixed",
        bottom: 0,
        right: 0,
        backgroundColor: getDynamicColor("light1"),
        boxShadow: Y_SHADOWS.upShadowLine,
        left: navDrawerOffset,
        transition: modalStyles.transition,
      }}
    >
      <Button
        variant="contained"
        size="xlarge"
        disabled={savingDisabled}
        sx={{
          ml: "auto",
          minWidth: 100,
        }}
        onClick={handleSavePersona}
      >
        {saving ? <CircularProgress size={20} /> : "Save"}
      </Button>
    </Stack>
  );
};
