// Components
import { Stack } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type OrgBannerProps = {
  title: JSX.Element;
  cta: JSX.Element;
};

export const OrgCtaBanner = ({ title, cta }: OrgBannerProps): JSX.Element => {
  return (
    <Stack
      direction="row"
      rowGap={1}
      columnGap={3}
      sx={{
        backgroundColor: getDynamicColor("purple3"),
        color: getDynamicColor("light1"),
        width: "100%",
        minHeight: "55px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        p: 2,
        flexWrap: "wrap",
        textAlign: "center",
      }}
    >
      {title}
      {cta}
    </Stack>
  );
};
