// Components
import { Box, Button, Stack, Typography } from "@mui/material";

// Assets
import { ReactComponent as CalendarIcon } from "images/icons/icon-calendar.svg";
import { ReactComponent as PeopleIcon } from "images/icons/icon-people.svg";
import { ReactComponent as ScenarioIcon } from "images/icons/icon-scenario.svg";

// Utils
import { ProgramDetails } from "./ProgramDetails";
import { ProgramStatus } from "./ProgramStatus";
import { UserActivity } from "./UserActivity";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { ProgramState } from "lib-fullstack/utils/enums";

type ProgramLibraryItemProps = {
  program: ProgramResponse;
  handleSelectProgram: (programId: string) => void;
};

export const ProgramLibraryItem = ({
  program,
  handleSelectProgram,
}: ProgramLibraryItemProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();

  const programDetails = [
    {
      icon: <CalendarIcon />,
      text: `Created ${getHumanReadableDate(program.created_date)}`,
    },
    {
      icon: <ScenarioIcon fill={getDynamicColor("purple3")} />,
      text: `${program.plan_steps.length} scenarios`,
    },
    {
      icon: <PeopleIcon />,
      text: `${program.hub_ids.length} groups`,
    },
  ];

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        alignItems: "center",
        justifyContent: "space-between",
        px: { xs: 2, md: 3 },
      }}
    >
      <Stack gap={2} sx={{ py: 3 }}>
        <Stack gap={1}>
          <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              {program.name}
            </Typography>
            <ProgramStatus state={program.state as ProgramState} />
          </Stack>
          <ProgramDetails programDetails={programDetails} />
        </Stack>
        <Typography
          sx={{
            color: getDynamicColor(program.description.length ? "purple3" : "dark4"),
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          {program.description.length ? program.description : "No description"}
        </Typography>
        <Button
          onClick={() => handleSelectProgram(program.id)}
          variant="outlined"
          sx={{ mt: { xs: 0, md: 2 }, fontSize: "12px" }}
        >
          View program
        </Button>
      </Stack>
      {!isSmallScreen && (
        <Box
          sx={{
            borderLeft: `1px solid ${getDynamicColor("dark3")}`,
            alignSelf: "stretch",
            display: "flex",
            alignItems: "center",
            pl: 3,
            width: "250px",
          }}
        >
          <UserActivity userActivity={program.user_activity} label="User activity" />
        </Box>
      )}
    </Stack>
  );
};
