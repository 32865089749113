import firebase from "firebase/app";

// Utils
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { isLocal } from "lib-fullstack/client_env";
import { AuthProviderConfig } from "./AuthProviderConfig";
import {
  addQueryParamToCurrentUrl,
  getQueryParamFromCurrentUrl,
  removeQueryParamFromCurrentUrl,
} from "utils/queryParamUtils";

/**
 * List of user facing custom error messages for some of known auth errors:
 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithpopup
 */
const ERROR_MESSAGES = {
  "auth/account-exists-with-different-credential":
    "Email already exists as a different sign-in method.",
  "auth/cancelled-popup-request":
    "You may have closed the login window before completing the login process.",
  "auth/operation-not-allowed": "You are not authorized to complete that operation.",
  "auth/popup-blocked":
    "Your browser may be blocking popup windows. Please allow popups in your browser settings to sign in with this provider.",
  "auth/popup-closed-by-user":
    "You may have closed the login window before completing the login process.",
};

/**
 * Default user facing error message for an error not listed in ERROR_MESSAGES.
 * Details are shown in browser console.
 */
const DEFAULT_ERROR_MESSAGE =
  "Something went wrong. Please try again or contact support if the issue persists";

/**
 * query param key in order to identify a redirecting sign-in.
 * The value is an opaque context which is relayed across the redirection.
 */
const REDIRECT_QUERY_PARAM_KEY = "redirect_sign_in";

/** Data returned by signInWithAuthProvider (for pop-up) and postRedirectSignIn */
export type SignInResult = {
  /** The user info if the sign-in has succeeded. null if failed */
  user: firebase.User | null;
  /** true if the signed in user is a new user */
  isNewUser: boolean;
  /** The context object that is passed to signInWithOAuthProvider() */
  context: object;
  /** The error message which is shown to the user, if the sign-in has failed */
  errorMessage: string | null;
};

/**
 * Initiates the sign in process either redirection (preferred) or pop-up.
 * If it is pop-up, this returns the result and context is returned as-is.
 * If it is redirection, this function never returns due to the redirection.
 *
 * @param providerParamSet Information of the target auth provider
 * @param context An object which is recovered by postRedirectSignIn() after the redirection
 * or returned as-is for pop-up sign-in. (This feature is not currently used)
 * @return The result of the sign-in effort unless the page navigates away for redirection sign-in.
 * @throws never
 */
export async function signInWithAuthProvider(
  config: AuthProviderConfig,
  context: object
): Promise<SignInResult> {
  if (!context) {
    throw new Error("Unexpected: context is required");
  }

  let useRedirect = config.redirectSignIn;
  // Force using pop-up if it's local and HTTPS is not configured or PR site
  if (useRedirect) {
    if (
      (isLocal() && !process.env.LOCAL_HTTPS) ||
      window.location.hostname.endsWith("_app.pr.yoodli.ai")
    ) {
      useRedirect = false;
    }
  }

  let provider: firebase.auth.AuthProvider;
  const firebaseId = config.firebaseId;
  if (firebaseId === "google") {
    provider = new firebase.auth.GoogleAuthProvider();
  } else if (firebaseId === "microsoft") {
    provider = new firebase.auth.OAuthProvider("microsoft.com");
  } else if (firebaseId.startsWith("saml")) {
    provider = new firebase.auth.SAMLAuthProvider(firebaseId);
  } else if (firebaseId.startsWith("oidc")) {
    provider = new firebase.auth.OAuthProvider(firebaseId);
  } else {
    console.error(`signInWithAuthProvider: unsupported provider ${firebaseId}`);
    return {
      user: null,
      isNewUser: false,
      context: context,
      errorMessage: `SSO is not configured correctly`,
    } as SignInResult;
  }

  if (useRedirect) {
    try {
      addQueryParamToCurrentUrl(REDIRECT_QUERY_PARAM_KEY, JSON.stringify(context), true);

      await firebase.auth().signInWithRedirect(provider);

      console.error("Unexpected: signInWithRedirect returned, did not navigate away");
    } catch (error) {
      console.error(`signInWithRedirect error: ${error}`);
    } finally {
      removeQueryParamFromCurrentUrl(REDIRECT_QUERY_PARAM_KEY);
    }
    return {
      user: null,
      isNewUser: false,
      context: context,
      errorMessage: "Redirect for SSO failed",
    } as SignInResult;
  } else {
    return processSignInResultCommon(async () => {
      return {
        userInfo: await firebase.auth().signInWithPopup(provider),
        context: context,
      };
    });
  }
}

/**
 * A helper function.
 * This runs the inner function, which is effectively either getRedirectResult() or signInWithPopup().
 * The common contract of these two functions is to return firebase.auth.UserCredential
 * if sign-in has succeeded and to throw an Error if sign-in has failed.
 * This function catches an Error from inner function and coverts it to return value as a form of SignInResult.
 * @return The result of the sign-in effort
 * @throws never
 */
async function processSignInResultCommon(
  innerFunction: () => Promise<{
    userInfo: firebase.auth.UserCredential;
    context: object;
  }>
): Promise<SignInResult> {
  try {
    const result = await innerFunction();
    if (!result) {
      console.error(`processSignInResultCommon: inner function did not return a result`);
      return {
        user: null,
        isNewUser: false,
        context: {},
        errorMessage: DEFAULT_ERROR_MESSAGE,
      } as SignInResult;
    }

    if (result.userInfo.additionalUserInfo.isNewUser) {
      Instrumentation.logUserSignUpCheckpoint();
    } else {
      Instrumentation.logUserSignInCheckpoint();
    }

    return {
      user: result.userInfo.user,
      isNewUser: result.userInfo.additionalUserInfo.isNewUser,
      context: result.context,
      errorMessage: null,
    };
  } catch (error) {
    let errorMessage = DEFAULT_ERROR_MESSAGE;
    if (error.code && ERROR_MESSAGES[error.code]) {
      errorMessage = ERROR_MESSAGES[error.code];
    }

    if (error.code?.startsWith?.("auth/")) {
      console.warn(`processSignInResultCommon error: ${JSON.stringify(error)}`);
    } else {
      console.error(`processSignInResultCommon error: ${JSON.stringify(error)}`);
    }

    return {
      user: null,
      isNewUser: false,
      context: {},
      errorMessage: errorMessage,
    };
  }
}

/**
 * Return if the page is loaded as post sign-in redirection synchronously.
 * This function works only until postRedirectSignIn() is called
 * because that removes the query param that indicates redirection.
 */
export function isPostRedirectSignIn(): boolean {
  const redirectContextBlob = getQueryParamFromCurrentUrl(REDIRECT_QUERY_PARAM_KEY);
  return !!redirectContextBlob;
}

/**
 * This function returns the result of redirect sign-in.
 * This may be called only once after a redirection because
 * this removes the query param that indicates redirection.
 * @throws when this is called not after the redirection.
 */
export async function postRedirectSignIn(): Promise<SignInResult | null> {
  const redirectContextBlob = removeQueryParamFromCurrentUrl(REDIRECT_QUERY_PARAM_KEY);
  if (!redirectContextBlob) {
    throw new Error("postRedirectSignIn: called not after the redirection");
  }

  return processSignInResultCommon(async () => {
    const userInfo = await firebase.auth().getRedirectResult();

    if (!userInfo?.user) {
      throw new Error("User info is unavailable even after successful redirection");
    } else {
      return {
        userInfo: userInfo,
        context: JSON.parse(redirectContextBlob),
      };
    }
  });
}

/**
 * Last resort fallback message in case any functions in this file throws unexpectedly
 * and the caller needs to catch and show user facing error message.
 * @returns DEFAULT_ERROR_MESSAGE
 */
export function getAuthSignInDefaultErrorMessage(): string {
  return DEFAULT_ERROR_MESSAGE;
}
