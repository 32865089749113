import { db } from "lib-fullstack";
import _ from "lodash";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";

// Components
import { Button, CircularProgress, Stack, Typography } from "@mui/material";

// Assets
import PlaceholderThumbnail from "images/graphics/video-placeholder.svg";

// Utils
import { FeaturedVideo, FeaturedSpeech, FeaturedCourse } from "./FeaturedContent";
import { CarouselContent, CarouselContentType } from "./Home";
import { ContentCarouselWrapper } from "./HomeStyles";
import { useIsExtraSmallScreen, useIsSmallScreen } from "lib-frontend/utils/themeUtils";

type ItemChunkProps = {
  chunk: [string, CarouselContent][];
  type: string;
  setLightboxURL?: (url: string, title: string) => void;
  showCoachChecklist?: boolean;
  coachWelcomeSlug?: string;
  selectedSlide?: number;
  itemOnClick?: (courseId: string) => void;
};

function ItemChunk({
  itemOnClick,
  chunk,
  type,
  setLightboxURL,
  showCoachChecklist,
  coachWelcomeSlug,
  selectedSlide,
}: ItemChunkProps): JSX.Element {
  switch (type) {
    case CarouselContentType.VIDEO:
      return (
        <>
          {chunk.map(([_, _video]) => {
            const video = _video as db.Doc<db.SampleVideo>;
            return (
              <FeaturedVideo
                key={`featured-video-${video.data.id}`}
                videoURL={video.data.videoURL}
                videoName={video.data.title}
                thumbnailPath={video.data.thumbnailPath}
                openInLightbox={() => setLightboxURL(video.data.videoURL, video.data.title)}
              />
            );
          })}
        </>
      );
    case CarouselContentType.SPEECH:
      return (
        <>
          {chunk.map(([_, _sampleSpeechItem], j) => {
            const sampleSpeechItem = _sampleSpeechItem as db.Doc<db.Speech>;
            const isHighlighted =
              showCoachChecklist && sampleSpeechItem.data.slug === coachWelcomeSlug;

            return (
              <FeaturedSpeech
                key={`featured-speech-${sampleSpeechItem.ref.id}`}
                speechItem={sampleSpeechItem}
                isSelected={selectedSlide === j}
                isHighlighted={isHighlighted}
              />
            );
          })}
        </>
      );
    case CarouselContentType.FEATURED_COURSE:
    case CarouselContentType.HUB_COURSE:
      return (
        <>
          {chunk.map(([courseId, _course]) => {
            const course = _course as db.Doc<db.CourseInfo>;
            return (
              <FeaturedCourse
                fallbackThumbnail={PlaceholderThumbnail}
                courseId={courseId}
                key={`course-highlight-${courseId}`}
                onClick={
                  itemOnClick
                    ? () => itemOnClick(course?.data?.id ?? course?.data?.slug)
                    : undefined
                }
                {...course.data}
              />
            );
          })}
        </>
      );
  }
}

type ItemCarouselProps = {
  items: CarouselContent[];
  itemOnClick?: (courseId: string) => void;
  title: string;
  titleCta?: {
    copy: string;
    onClick: () => void;
  };
  type: CarouselContentType;
  setLightboxURL?: (url: string, title: string) => void;
  showCoachChecklist?: boolean;
  coachWelcomeSlug?: string;
  loadingContent?: boolean;
};

export default function ItemCarousel({
  items,
  itemOnClick,
  title,
  titleCta,
  type,
  setLightboxURL,
  showCoachChecklist,
  coachWelcomeSlug,
  loadingContent,
}: ItemCarouselProps): JSX.Element {
  const isExtraSmallScreen = useIsExtraSmallScreen();
  const isSmallScreen = useIsSmallScreen();
  const CAROUSEL_SIZE = isExtraSmallScreen ? 1 : isSmallScreen ? 2 : 3;
  const [selectedSlide, setSelectedSlide] = useState(0);
  return (
    (items?.length > 0 || loadingContent) && (
      <Stack width="100%" gap={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography
            component="h2"
            variant="poppins"
            display="inline-block"
            fontWeight={600}
            fontSize={{ xs: 18, sm: 20 }}
          >
            {title}
          </Typography>
          {!!titleCta && (
            <Button
              variant="text"
              onClick={titleCta.onClick}
              sx={{
                fontSize: 14,
                whiteSpace: "nowrap",
              }}
            >
              {titleCta.copy}
            </Button>
          )}
        </Stack>
        <ContentCarouselWrapper>
          {loadingContent ? (
            <Stack
              sx={{
                width: "100%",
                minHeight: 200,
                pl: 4,
              }}
              alignItems="flex-start"
              justifyContent="center"
            >
              <CircularProgress size={64} />
            </Stack>
          ) : (
            <Carousel
              showArrows
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
              renderArrowNext={items.length > CAROUSEL_SIZE ? undefined : () => <></>}
              renderArrowPrev={items.length > CAROUSEL_SIZE ? undefined : () => <></>}
              onChange={(idx) => setSelectedSlide(idx)}
              infiniteLoop
            >
              {_.chunk(Object.entries(items), CAROUSEL_SIZE).map((chunk, i) => {
                return (
                  <Stack
                    sx={{
                      ".MuiCard-root": {
                        width: { xs: "100%", md: "33.33333%" },
                      },
                    }}
                    gap={1}
                    key={`content-chunk-${i}`}
                    direction="row"
                    mb="10px"
                  >
                    <ItemChunk
                      chunk={chunk}
                      type={type}
                      setLightboxURL={setLightboxURL}
                      showCoachChecklist={showCoachChecklist}
                      coachWelcomeSlug={coachWelcomeSlug}
                      selectedSlide={selectedSlide}
                      itemOnClick={itemOnClick}
                    />
                  </Stack>
                );
              })}
            </Carousel>
          )}
        </ContentCarouselWrapper>
      </Stack>
    )
  );
}
