import firebase from "firebase/app";
import { db } from "lib-fullstack";
import _ from "lodash";
import React from "react";

// Components
import { Stack, Typography } from "@mui/material";

// Utils
import AddMeetingLink from "./AddMeetingLink";
import JoiningMeeting from "./JoiningMeeting";
import RecordingComplete from "./RecordingComplete";
import RecordingInProgress from "./RecordingInProgress/RecordingInProgress";
import { useOnQuery, useOnGet } from "@typesaurus/react";
import { usePricingUsage } from "hooks";
import { addRecallZoomBot, removeRecallZoomBot } from "lib-frontend/modules/AxiosInstance";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { ReliableTimer } from "lib-frontend/utils/ReliableTimer";
import { ZoodliAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { Language, RecordingSupportedLanguage } from "lib-fullstack/utils/enums";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";

const getSecondsElapsed = (startTime: string): number => {
  const startDate = new Date(startTime);
  const currDate = new Date();
  return (currDate.getTime() - startDate.getTime()) / 1000;
};

enum BotErrors {
  DEFAULT = "Yoodlibot was unable to join the call. Please try again.",
  NO_BOT = "Yoodlibot is spinning up — please try again in a few seconds!",
}

type ZoomControlPanelProps = {
  modalContainerRef: React.RefObject<HTMLDivElement>;
};
// TODO: Change font-family to Poppins in respective places
const ZoomControlPanel = ({ modalContainerRef }: ZoomControlPanelProps): JSX.Element => {
  const { thresholdHit } = usePricingUsage();

  const [pending, setPending] = React.useState<boolean>(false);
  const [zoomUrl, setZoomUrl] = React.useState<string>(undefined);
  const [recordingComplete, setRecordingComplete] = React.useState<boolean>(false);
  const [secondsElapsed, setSecondsElapsed] = React.useState<number>(0);
  const [language, setLanguage] = React.useState<RecordingSupportedLanguage>(
    getLiveUserDocMain()?.recordingLanguage ?? Language.English
  );
  const [error, setError] = React.useState<string>("");
  const lastS = React.useRef<number>(0);
  const [speechTimer] = React.useState<ReliableTimer>(
    () =>
      new ReliableTimer(-1, {
        onTimeElapsedSChanged: (time: number) => {
          const timeRounded = Math.floor(time);
          // every second, check RTA stuff
          if (lastS.current < timeRounded) {
            lastS.current = timeRounded;
          }
          setSecondsElapsed(time);
        },
      })
  );

  const [dbBots, dbBotsMeta] = useOnQuery(
    db.recallZoomBots([getSiteId(), firebase.auth().currentUser?.uid]),
    [db.where("active", "==", true)]
  );

  // set loading optimistically so there isnt a lag after clicking the button, but before the request is complete/the dbBot exists
  const [optimisticLoading, setOptimisticLoading] = React.useState<boolean>(false);

  /* Next few lines find the most recent finished/inactive call (if any)
   * so we can display it to the user, for convenience.
   */
  const [lastDbBotList, _lastDbBotListMeta] = useOnQuery(
    db.recallZoomBots([getSiteId(), firebase.auth().currentUser?.uid]),
    [
      // db.where("active", "==", false),
      db.order("updatedAt", "desc"),
      db.limit(5),
    ]
  );
  const lastDbBot = _.chain(lastDbBotList ?? [])
    .filter(
      (e) =>
        !e.data?.active &&
        e.data.updatedAt &&
        e.data.status === "done" &&
        (new Date().getTime() - new Date(e.data.updatedAt).getTime()) / 1000 / 60 / 60 < 1.0
    )
    .sortBy((e) => e.data?.updatedAt)
    .reverse()
    .first()
    .value();
  // might be a ref, or might be undefined:
  const ref = lastDbBot && db.pathToRef<db.Speech>(lastDbBot?.data?.speechRefPath);
  const [lastDbSpeech, _lastDbSpeechMeta] = useOnGet(ref);
  // lastDbBot lastDbSpeech now contain the most recent legit bot/speech, or
  //  are both undefined if they don't exist.

  const dbBot = _.first(dbBots);

  const currRef = dbBot && db.pathToRef<db.Speech>(dbBot?.data?.speechRefPath);
  const [_currDbSpeech, _currDbSpeechMeta] = useOnGet(currRef);

  React.useEffect(() => {
    if (dbBot) {
      setZoomUrl(dbBot.data.inviteFullUrl);
      const recordingStatus = dbBot.data?.fullBotStatus?.status_changes.find(
        (status) => status.code === "in_call_recording"
      );
      if (recordingStatus) {
        const recordingStartTime = recordingStatus.created_at;
        const newSecondsElapsed = getSecondsElapsed(recordingStartTime);
        setSecondsElapsed(newSecondsElapsed);
        lastS.current = newSecondsElapsed;
        speechTimer.setTimeElapsedS(newSecondsElapsed);
      }
    }
  }, [dbBot]);

  React.useEffect(() => {
    updateThisUserDocMain({ recordingLanguage: language }).catch((er) => {
      console.error(`Error updating user doc main: ${er}`);
    });
  }, [language]);

  if (dbBotsMeta.loading) {
    return <div style={{ minHeight: "180px" }}>Loading...</div>;
  }
  if (dbBots?.length > 1) {
    console.error("Too many Bots! Showing first.");
  }

  const handleStart = async (e) => {
    e.preventDefault();
    try {
      await setOptimisticLoading(true);
      const meetingUrl = e.target.meetingURL.value;
      await inviteBotToMeeting(meetingUrl);
      await setOptimisticLoading(false);
    } catch (er) {
      throw new Error("Error starting zoom bot! Error: " + er);
    }
  };

  const inviteBotToMeeting = async (meetingUrl) => {
    if (!pending && !thresholdHit) {
      try {
        setPending(true);
        const dbUser = await db.get(db.users(getSiteId()), firebase.auth().currentUser.uid);

        Instrumentation.logAmplitudeEvent(ZoodliAnalyticsEvents.MANUAL_JOIN);

        await addRecallZoomBot(dbUser.ref, meetingUrl, "web_ui_link_paste", language);

        setZoomUrl(meetingUrl);
        setPending(false);
        setError("");
      } catch (e) {
        setPending(false);
        console.error(e);
        setError(BotErrors.DEFAULT);
      }
    }
  };

  const handleStop = async () => {
    try {
      if (!dbBot?.data) throw new Error(BotErrors.NO_BOT);
      await removeRecallZoomBot(dbBot?.data?.botId);
      setRecordingComplete(true);
      lastS.current = 0;
      setSecondsElapsed(0);
      speechTimer.stop();
      setError("");
      Instrumentation.logAmplitudeEvent(ZoodliAnalyticsEvents.STOP);
    } catch (e) {
      console.error(e);
      setError(e.message === BotErrors.NO_BOT ? BotErrors.NO_BOT : BotErrors.DEFAULT);
    }
  };

  const joinLastZoomCall = async () => {
    try {
      await setOptimisticLoading(true);
      const previousZoomURL = lastDbBot.data.inviteFullUrl;
      await inviteBotToMeeting(previousZoomURL);
      await setOptimisticLoading(false);
      setError("");
    } catch (er) {
      throw new Error("Unable to join last call! Error: " + er);
    }
  };

  // default view is the "Add Meeting Link" input screen
  let zoodliCurrentView = (
    <AddMeetingLink
      onSubmit={handleStart}
      setError={setError}
      error={error}
      joinLastZoomCall={joinLastZoomCall}
      lastDbBot={lastDbBot}
      modalContainerRef={modalContainerRef}
      language={language}
      setLanguage={setLanguage}
    />
  );

  // If bot exists currently
  if (dbBot || optimisticLoading) {
    // If bot is actively in call recording
    if (dbBot?.data?.status === "in_call_recording") {
      zoodliCurrentView = (
        <RecordingInProgress
          handleStop={handleStop}
          speechTimer={speechTimer}
          secondsElapsed={secondsElapsed}
        />
      );
    }
    // If bot exists but is inactive i.e. loading/
    else {
      zoodliCurrentView = (
        <JoiningMeeting
          handleStop={handleStop}
          setRecordingComplete={setRecordingComplete}
          status={dbBot?.data?.status ?? "ready"}
        />
      );
    }
  } else if (recordingComplete) {
    zoodliCurrentView = (
      <RecordingComplete
        setRecordingComplete={setRecordingComplete}
        lastDbBot={lastDbBot}
        lastDbSpeech={lastDbSpeech}
        zoomUrl={zoomUrl}
        setZoomUrl={setZoomUrl}
        inviteBotToMeeting={inviteBotToMeeting}
        setOptimisticLoading={setOptimisticLoading}
        modalContainerRef={modalContainerRef}
      />
    );
  }

  return (
    <Stack gap={2} alignItems="center">
      {zoodliCurrentView}
      {error && <Typography color="error">{error}</Typography>}
    </Stack>
  );
};

export default ZoomControlPanel;
