// Utils
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { WebServerInternalPath } from "utils/paths";

export default {
  signin: WebServerExternalPath.SIGN_IN,
  download: WebServerExternalPath.DOWNLOAD_POODLI_DIRECT,
  signup: WebServerExternalPath.SIGN_UP,
  forgot_password: WebServerInternalPath.FORGOT_PASSWORD,
  reset_password: WebServerExternalPath.RESET_PASSWORD,
  verify_email: WebServerInternalPath.VERIFY_EMAIL,
};
