import React from "react";

// Components
import { Button, Card, CircularProgress, Stack, Typography } from "@mui/material";

// Utils
import { revertScheduledPlanChange } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { getIntFromString } from "lib-frontend/utils/Utilities";
import { formatDate } from "lib-fullstack/utils/dateUtils";
import { getPlanData, handleGetBillingSettingsUrl } from "utils/PricingUtils";
import { UsagePlanDuration, UsagePlanType } from "lib-fullstack/utils/pricingTypes";

type CurrentPaymentProps = {
  hasPendingPlan?: boolean;
  usagePlanRenewalDate: string;
  usagePlanType: UsagePlanType;
  isAnnual?: boolean;
  nextUsagePlanStart?: string;
  nextUsagePlanType?: UsagePlanType;
  nextUsagePlanDuration: UsagePlanDuration;
  handleToggleResetOnCancel: () => void;
};

const CurrentPayment = ({
  hasPendingPlan,
  usagePlanRenewalDate,
  usagePlanType,
  isAnnual,
  nextUsagePlanStart,
  nextUsagePlanType,
  nextUsagePlanDuration,
  handleToggleResetOnCancel,
}: CurrentPaymentProps): JSX.Element => {
  const nextUsagePlanData = React.useMemo(
    () => getPlanData(nextUsagePlanType, isAnnual),
    [hasPendingPlan]
  );
  const [loadingUrl, setLoadingUrl] = React.useState(false);
  const [loadingCancel, setLoadingCancel] = React.useState(false);
  const renderCta = () => {
    return (
      <Button
        variant="text"
        onClick={async () => {
          try {
            setLoadingUrl(true);
            await handleGetBillingSettingsUrl();
          } catch (er) {
            console.log("error getting stripe billing settings url", er);
          } finally {
            setLoadingUrl(false);
          }
        }}
        sx={{
          width: 240,
          whiteSpace: "nowrap",
          alignSelf: { xs: "center", sm: "auto" },
        }}
      >
        {loadingUrl ? <CircularProgress size={20} /> : "Update payment method"}
      </Button>
    );
  };
  const renderCancelChange = () => {
    if (!hasPendingPlan || !nextUsagePlanData) {
      return null;
    }
    return (
      <Button
        variant="text"
        onClick={async () => {
          try {
            const nextIsAnnual = nextUsagePlanDuration === UsagePlanDuration.YEARLY;
            const nextDurationInt = getIntFromString(
              nextUsagePlanData.cost[
                nextUsagePlanDuration === UsagePlanDuration.YEARLY ? "annually" : "monthly"
              ]
            );
            Instrumentation.logSettingsPricingCancelScheduledChangeClicked(
              nextUsagePlanType,
              nextDurationInt,
              nextIsAnnual
            );
            setLoadingCancel(true);
            await revertScheduledPlanChange();
            Instrumentation.logPricingCancelScheduledChangeComplete(
              nextUsagePlanType,
              nextDurationInt,
              nextIsAnnual
            );
            handleToggleResetOnCancel();
          } catch (er) {
            console.log("error reverting scheduled plan change", er);
          } finally {
            setLoadingCancel(false);
          }
        }}
        sx={{
          width: 190,
          whiteSpace: "nowrap",
          alignSelf: { xs: "center", sm: "auto" },
          color: getDynamicColor("redError"),
          "&:hover": {
            color: getDynamicColor("redError"),
            backgroundColor: getDynamicColor("redErrorLight"),
          },
        }}
      >
        {loadingCancel ? (
          <CircularProgress
            size={20}
            sx={{
              color: getDynamicColor("redError"),
            }}
          />
        ) : (
          "Cancel this change"
        )}
      </Button>
    );
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "space-between",
        alignItems: { xs: "flex-start", sm: "center" },
        gap: { xs: 1, sm: 2 },
        px: { xs: 2, md: 5 },
        py: { xs: 2, md: 2.5 },
        minHeight: 84,
        borderRadius: "8px",
        boxShadow: Y_SHADOWS.box_shadow_1,
        color: getDynamicColor("purple3"),
      }}
    >
      <Stack
        direction="column"
        alignItems={{ xs: "center", sm: "flex-start" }}
        gap={{ xs: 1, lg: 0 }}
        sx={{
          width: { xs: "100%", sm: "auto" },
          flexGrow: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: "poppins",
            fontWeight: 600,
            lineHeight: "normal",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          {nextUsagePlanData
            ? `Scheduled change to ${nextUsagePlanData.title} on ${formatDate(
                nextUsagePlanStart,
                false
              )}`
            : `Renews ${
                usagePlanRenewalDate
                  ? formatDate(usagePlanRenewalDate, false)
                  : `next ${isAnnual ? "year" : "month"}`
              }`}
        </Typography>
        {(nextUsagePlanType ?? usagePlanType) !== UsagePlanType.FREE && (
          <Typography
            sx={{
              fontSize: 14,
              color: getDynamicColor("dark4"),
              fontFamily: "poppins",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            Paid {isAnnual ? "yearly" : "monthly"}
          </Typography>
        )}
      </Stack>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        gap={1}
        alignItems="center"
        sx={{
          width: { xs: "100%", sm: "auto" },
        }}
      >
        {renderCancelChange()}
        {renderCta()}
      </Stack>
    </Card>
  );
};

export default CurrentPayment;
