import React from "react";

// Components
import CircleIcon from "@mui/icons-material/Circle";
import { Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

type ProgramDetailProps = {
  icon: JSX.Element;
  text: string;
};

const ProgramDetail = ({ icon, text }: ProgramDetailProps): JSX.Element => {
  return (
    <Stack gap={1} direction="row" sx={{ alignItems: "center" }}>
      {icon}
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontFamily: "poppins",
          fontSize: "10px",
          fontWeight: 500,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

type ProgramDetailsProps = {
  programDetails: ProgramDetailProps[];
};

export const ProgramDetails = ({ programDetails }: ProgramDetailsProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <Stack direction="row" gap={2} sx={{ alignItems: "center", flexWrap: "wrap" }}>
      {programDetails.map((detail, index) => (
        <React.Fragment key={index}>
          <ProgramDetail {...detail} />
          {index !== programDetails.length - 1 && !isSmallScreen && (
            <CircleIcon sx={{ color: getDynamicColor("purple3"), width: 4, height: 4 }} />
          )}
        </React.Fragment>
      ))}
    </Stack>
  );
};
