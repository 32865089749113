import { isMobile } from "react-device-detect";
import { useNavigate, useLocation } from "react-router";

// Components
import { Circle as CircleIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Button, Dialog, IconButton, SxProps, Typography, Box } from "@mui/material";
import UsageItem from "lib-frontend/components/UsageItem";

// Assets
import { ReactComponent as UpgradeIcon } from "images/icons/icon-upgrade.svg";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getLiveUserDocReadonly } from "lib-frontend/utils/LiveUserDocs";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { PricingModalCtaLocations } from "lib-fullstack/utils/productAnalyticEvents";

type ThresholdReachedModalProps = {
  location: PricingModalCtaLocations;
  open: boolean;
  onUpgradeClick: (location?: PricingModalCtaLocations) => void;
  isWithinModal?: boolean;
  handleClose?: () => void;
  overrideSx?: SxProps;
};

const ThresholdReachedModal = ({
  location,
  open,
  onUpgradeClick,
  isWithinModal,
  handleClose,
  overrideSx,
}: ThresholdReachedModalProps): JSX.Element => {
  const userDocReadonly = getLiveUserDocReadonly();
  const { modalStyles } = useNavDrawerOffset();
  const navigate = useNavigate();
  const routerLocation = useLocation();

  function handleGoBack() {
    const previousUrl = routerLocation.state?.prevPath;
    if (previousUrl && previousUrl.includes("yoodli.ai/")) {
      navigate(-1); // go back
    } else {
      navigate(WebServerExternalPath.HOME_LOGGED_IN);
    }
  }

  const handleUpgradeClick = () => {
    if (location === PricingModalCtaLocations.SPEECH_RECORDER) {
      onUpgradeClick(PricingModalCtaLocations.SPEECH_RECORDER_THRESHOLD_REACHED_MODAL);
    } else if (location === PricingModalCtaLocations.UPLOAD) {
      onUpgradeClick(PricingModalCtaLocations.UPLOAD_THRESHOLD_REACHED_MODAL);
    }
  };
  return (
    <Dialog
      open={open}
      hideBackdrop={isWithinModal}
      onClose={() => {
        if (handleClose) {
          handleClose();
        }
      }}
      sx={{
        zIndex: 1200,
      }}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "min(100%, 500px)", md: "min(100%, 600px)" },
          height: "min(100%, 400px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 5,
          borderRadius: "12px",
          p: 6,
          m: 2,
          zIndex: 1200,
          backgroundColor: getDynamicColor("light2"),
          ...modalStyles,
          ...overrideSx,
        },
      }}
    >
      {isMobile && location === PricingModalCtaLocations.SPEECH_RECORDER && (
        <IconButton
          onClick={handleGoBack}
          sx={{
            color: getDynamicColor("dark4"),
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <UsageItem
        open
        numUsed={userDocReadonly.usedSpeeches}
        quota={userDocReadonly.usageQuota}
        progressSize={22}
        icon={
          <CircleIcon sx={{ width: "12px", height: "12px", color: getDynamicColor("redError") }} />
        }
        progressColor={getDynamicColor("redError")}
        textSx={{
          fontSize: { xs: 12, md: 14 },
          color: getDynamicColor("redError"),
        }}
      />
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: { xs: 14, md: 16 },
          textAlign: "center",
        }}
      >
        Upgrade to practice more with Yoodli!
      </Typography>
      <Button
        variant="gradient"
        onClick={handleUpgradeClick}
        startIcon={
          <Box>
            <UpgradeIcon />
          </Box>
        }
        sx={{
          fontWeight: 700,
          width: "100%",
          maxWidth: 180,
          svg: {
            width: "18px",
            height: "18px",
          },
        }}
      >
        UPGRADE
      </Button>
    </Dialog>
  );
};

export default ThresholdReachedModal;
