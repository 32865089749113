import React from "react";

// Components
import { CheckCircleRounded as CheckCircleRoundedIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { BulkInviteRoleOptionEnum } from "components/Orgs/BulkInviteByCSVWizard/BulkInviteByCSVWizard";
import { EmailBank } from "components/Orgs/BulkInviteByCSVWizard/EmailBank";
import { WizardSubTitle, WizardTitle } from "components/Wizard/WizardTitles";
import { YoodliMultiSelect } from "lib-frontend/components/YoodliComponents/YoodliMultiSelect";
import {
  YoodliSelect,
  YoodliSelectOption,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { BULK_INVITE_ROLE_OPTIONS, BulkInviteRoleOption } from "./BulkInviteByCSVWizard";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { isOrgOwnerAdmin } from "lib-frontend/utils/orgUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

type ImportCompleteProps = {
  emails: string[];
  handleRemoveEmail: (email: string) => void;

  selectedRole: BulkInviteRoleOption;
  setSelectedRole: (role: BulkInviteRoleOption) => void;

  selectedHubIds: string[];
  setSelectedHubIds: (hubIds: string[]) => void;
};

export const ImportComplete = ({
  emails,
  handleRemoveEmail,

  selectedRole,
  setSelectedRole,

  selectedHubIds,
  setSelectedHubIds,
}: ImportCompleteProps): JSX.Element => {
  const { defaultOrg } = React.useContext(UserOrgContext);
  const isSmallScreen = useIsSmallScreen();
  const subTitle = `We found ${emails?.length} emails. Any invalid or existing/duplicate emails were ignored`;
  return (
    <Stack
      gap={{ xs: 2, md: 5 }}
      sx={{
        fontFamily: "poppins",
      }}
    >
      <Stack gap={1}>
        <WizardTitle
          icon={
            <CheckCircleRoundedIcon
              sx={{
                color: getDynamicColor("greenSuccess"),
              }}
            />
          }
        >
          Import Complete
        </WizardTitle>
        <WizardSubTitle
          overrideSx={{
            color: getDynamicColor("dark5"),
          }}
        >
          {subTitle}
        </WizardSubTitle>
      </Stack>
      <EmailBank emails={emails} title="Email addresses" handleRemoveEmail={handleRemoveEmail} />
      {isOrgOwnerAdmin(defaultOrg) && (
        <Stack
          direction={{ xs: "column", lg: "row" }}
          gap={2}
          sx={{
            width: "100%",
          }}
        >
          <Stack gap={0.5} sx={{ width: { xs: "100%", lg: "40%" } }}>
            <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Role
              </Typography>
            </Stack>
            <YoodliSelect
              value={selectedRole.value}
              fullWidth={isSmallScreen}
              onChange={(e) => {
                setSelectedRole(BULK_INVITE_ROLE_OPTIONS.find((o) => o.value === e.target.value));
              }}
              options={[
                { label: "Group Member", value: BulkInviteRoleOptionEnum.HUB_MEMBER.toString() },
                { label: "Group Admin", value: BulkInviteRoleOptionEnum.HUB_ADMIN.toString() },
              ]}
            />
          </Stack>
          <Stack gap={0.5} sx={{ width: { xs: "100%", lg: "60%" }, flexGrow: 1 }}>
            <Stack direction="row" gap={0.5} sx={{ alignItems: "center", flexGrow: 1 }}>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Groups(s)
              </Typography>
            </Stack>
            <YoodliMultiSelect
              isCheckboxSelect
              value={
                selectedHubIds?.map((id) => ({
                  label: defaultOrg.hubs.find((h) => h.id === id)?.name ?? "",
                  value: id,
                })) ?? []
              }
              onChange={(e, vals: YoodliSelectOption[], _reason) => {
                if (vals.length === 0) {
                  setSelectedHubIds([]);
                  return;
                }
                if (selectedHubIds?.includes(vals[vals.length - 1]?.value)) {
                  setSelectedHubIds(
                    selectedHubIds.filter((id) => id !== vals[vals.length - 1]?.value)
                  );
                } else {
                  setSelectedHubIds([...(selectedHubIds ?? []), vals[vals.length - 1]?.value]);
                }
              }}
              placeholder="Select group(s)"
              options={defaultOrg?.hubs.map((h) => ({ label: h.name, value: h.id }))}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
