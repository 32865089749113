/* eslint @typescript-eslint/no-explicit-any: 0, @typescript-eslint/explicit-module-boundary-types: 0 */
import React from "react";

/**
 * Hook that returns the previous value of a property.
 * @param value The value to track.
 * @returns The previous value (undefined on the initial render).
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @see https://usehooks.com/usePrevious/
 *
 * @example
 * const prevProps = usePrevious(props);
 * if (props.value !== prevProps.value) {
 *  // do something
 * }
 */
export function usePrevious(value: any, deps?: any[]): any {
  const ref = React.useRef();
  const dependencyArr = deps ? [...deps] : [value];
  React.useEffect(() => {
    ref.current = value;
  }, dependencyArr);
  return ref.current;
}
