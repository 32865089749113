import React from "react";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Box } from "@mui/material";

export default function HamburgerButton(props: {
  isOpen: boolean;
  onClick: () => void;
}): JSX.Element {
  const expandedWidth = 32;
  const collapsedWidth = 20;

  return (
    <div
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        margin: "8px",
      }}
    >
      <Box
        sx={{
          width: "32px",
          height: "2px",
          background: getDynamicColor("gradient.reversed"),
          borderRadius: "3px",
          mb: 2,
        }}
      />
      <Box
        sx={{
          width: `${props.isOpen ? `${expandedWidth}px` : `${collapsedWidth}px`};`,
          height: "2px",
          background: getDynamicColor("gradient.reversed"),
          borderRadius: "3px",
          transition: "width 0.3s",
        }}
      />
    </div>
  );
}
