// Utils
import ysFixWebmDuration from "fix-webm-duration";

/**
 * Injecting seek metadata (duration) into the blob if it is WebM
 * Using the fix-webm-duration NPM package: https://www.npmjs.com/package/fix-webm-duration
 * If it fails internally, it returns the original blob as a fallback.
 * i.e. this does not throw.
 *
 * FFMPEG on the server handles seekability (duration) for all file types.
 * For .webm, it could handle that too but we're choosing fix-webm-duration because
 * it adds duration much quicker than FFMPEG to transcode from .webm without duration
 * to .webm with duration
 */
export const injectSeekMetadata = async (blob: Blob, durationMs: number): Promise<Blob> => {
  if (blob.type.toLowerCase().includes("webm")) {
    try {
      console.log(`injectSeekMetadata: duration of .webm: ${durationMs}`);
      return await ysFixWebmDuration(blob, durationMs);
    } catch (error) {
      console.warn(`injectSeekMetadata: Error. Returning original blob. ${error}.`);
      return blob;
    }
  } else {
    return blob;
  }
};

/**
 * @param {*} blob
 * @param {*} duration
 * @returns url of blob with seek metadata
 * @description
 * This function takes a blob and adds seek metadata to it.
 * This is necessary for Safari to be able to seek in the video.
 */
export const blobToSeekableURL = async (blob: Blob, duration: number): Promise<string> => {
  const URL = window.URL || window.webkitURL;
  return URL.createObjectURL(await injectSeekMetadata(blob, duration));
};
