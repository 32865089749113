import { db } from "lib-fullstack";

// Components
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import { Box, Stack, Typography, Tooltip } from "@mui/material";

// Utils
import { changeJoinOverride } from "../../modules/AxiosInstance";
import { getDynamicColor } from "../../utils/Colors";
import { Instrumentation } from "../../utils/ProductAnalyticsUtils";
import { YoodliSwitch } from "../YoodliSwitch";
import { shouldDefaultJoin } from "lib-fullstack/utils/calendar";
import { EventsCalWheres, RecurringEventSettings } from "lib-fullstack/utils/productAnalyticEvents";

type CalendarEventProps = {
  event: db.Doc<db.CalendarEvent>;
  defaultJoin: db.BotAutoJoinPreference;
  mode: db.CalendarMode;
  setLoading?: (val: boolean) => void;
  isPoodli?: boolean;
};

export default function CalendarEvent({
  event,
  defaultJoin,
  mode,
  setLoading,
  isPoodli,
}: CalendarEventProps): JSX.Element {
  const { data, ref } = event;
  const shouldJoin =
    (data.joinOverride ??
      shouldDefaultJoin(defaultJoin, data.isOrganizer, data.attendees, data.startTime)) &&
    !!data.meetingURL;

  const handleChange = async () => {
    // for Zoodli we need to show a loading indicator as we need to do bot scheduling
    if (mode === db.CalendarMode.ZOODLI) {
      setLoading?.(true);
    }

    try {
      Instrumentation.logCalAutostartToggledCheckpoint(
        !shouldJoin,
        data.recurringEventId
          ? RecurringEventSettings.THIS_AND_FOLLOWING_EVENTS
          : RecurringEventSettings.NA,
        // eslint-disable-next-line no-prototype-builtins
        globalThis.hasOwnProperty("inElectronRenderer")
          ? EventsCalWheres.POODLI
          : EventsCalWheres.MEETINGS
      );
      await changeJoinOverride(ref, !shouldJoin);
    } finally {
      if (mode === db.CalendarMode.ZOODLI) {
        setLoading?.(false);
      }
    }
  };

  const formatTime = (date) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    };
    return date.toLocaleTimeString([], options);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      sx={{
        backgroundColor: getDynamicColor("light1"),
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark2")}`,
        pr: 0.5,
        py: "6px",
        pl: 1.5,
        minHeight: "52px",
        display: "flex",
        width: "100%",
      }}
    >
      <Stack
        direction={{ xs: "column", xl: "row" }}
        alignItems={{ xs: "auto", xl: "center" }}
        gap={{ xs: 0.5, xl: 2 }}
      >
        <Typography
          style={{
            fontWeight: 400,
            fontSize: 14,
            fontFamily: "poppins",
            color: getDynamicColor(shouldJoin ? "purple3" : "dark4"),
            whiteSpace: "nowrap",
          }}
        >
          {formatTime(new Date(data.startTime))}
        </Typography>
        <Typography
          sx={
            isPoodli
              ? {
                  maxWidth: "260px",
                  fontWeight: shouldJoin ? 700 : 500,
                  fontSize: 12,
                  hyphens: "auto",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  color: getDynamicColor(shouldJoin ? "purple3" : "dark5"),
                }
              : {
                  fontWeight: shouldJoin ? 700 : 600,
                  fontSize: 14,
                  hyphens: "auto",
                  textOverflow: "break-word",
                  wordBreak: "break-word",
                  lineHeight: 1.2,
                  color: getDynamicColor(shouldJoin ? "purple3" : "dark5"),
                }
          }
        >
          {data.name}
        </Typography>
      </Stack>

      {data.meetingURL || isPoodli ? (
        <YoodliSwitch checked={shouldJoin} onClick={handleChange} />
      ) : (
        <Tooltip title="No meeting link found">
          <Box
            position="relative"
            width="24px"
            height="24px"
            sx={{ color: getDynamicColor("dark4"), mr: 2.5 }}
          >
            <VideocamOffOutlinedIcon />
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
}
