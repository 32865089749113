import React from "react";

// Components
import { Stack, Typography, Button, Box, CircularProgress } from "@mui/material";
import {
  CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS,
  CUSTOM_GOAL_NAME_MAX_CHARS,
  CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS,
  CUSTOM_GOAL_TEXTFIELD_MIN_ROWS,
  CUSTOM_GOAL_USER_DESC_MAX_CHARS,
  CreateConvoScenarioStepId,
  GoalHooks,
} from "components/ConvoScenarios/convoScenarioUtils";
import { WizardStep } from "components/Wizard/WizardTypes";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  CreateBinaryGoalRequest,
  CreateCustomGoalRequest,
} from "lib-fullstack/api/scenarioApiTypes";
import { getGoalRephrase } from "utils/GPTUtils";

export const CreateBinaryGoalSteps = (
  binaryGoal: CreateBinaryGoalRequest,
  setBinaryGoal: React.Dispatch<React.SetStateAction<CreateBinaryGoalRequest>>,
  createCustomGoal: (customGoal: CreateCustomGoalRequest) => Promise<string>,
  goalEditId: string,
  setDiscardGoalModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  goalHooks: GoalHooks,
  closeWizard: () => void,
  inScenarioCreation?: boolean
): WizardStep[] => {
  const handleUpdateBinaryGoal = (key: keyof CreateBinaryGoalRequest, val: string) => {
    setBinaryGoal((prev) => ({ ...prev, [key]: val }));
  };
  const descStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: inScenarioCreation ? "Customize" : "Start",
    component: (
      <ConvoScenarioStepWrapper
        title={goalEditId ? "Update Binary Goal" : "Create a Binary Goal"}
        subTitle="Name this binary goal and add a short description"
      >
        <Stack
          gap={6}
          sx={{
            position: "relative",
          }}
        >
          <YoodliLabeledInput
            label="Name"
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <YoodliTextfield
                autoFocus
                inputProps={{
                  className: "blockEnterToNavigate",
                }}
                InputLabelProps={{ shrink: true }}
                maxChars={CUSTOM_GOAL_NAME_MAX_CHARS}
                placeholder="e.g. Set a deadline"
                value={binaryGoal.name}
                onChange={(e) => handleUpdateBinaryGoal("name", e.target.value)}
              />
            }
          />
          <YoodliLabeledInput
            label="Add a member-facing description (optional)"
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <YoodliTextfield
                multiline
                minRows={2}
                inputProps={{
                  className: "blockEnterToNavigate",
                }}
                maxChars={CUSTOM_GOAL_USER_DESC_MAX_CHARS}
                placeholder="e.g. Choose a completion date for the project"
                InputLabelProps={{ shrink: true }}
                value={binaryGoal.userDescription ?? ""}
                onChange={(e) => handleUpdateBinaryGoal("userDescription", e.target.value)}
              />
            }
          />
        </Stack>
      </ConvoScenarioStepWrapper>
    ),
    validate: () => binaryGoal.name.length > 0,
    prev: () => {
      setDiscardGoalModalOpen(true);
    },
    skipPrevIndexUpdate: inScenarioCreation,
    prevCopy: "Discard",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;

  const evaluationStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: "Customize",
    component: (
      <ConvoScenarioStepWrapper
        title={goalEditId ? "Update Binary Goal" : "Create a Binary Goal"}
        subTitle={
          <>
            <Typography component="div">
              Explain this goal to the AI, then define success and failure.
            </Typography>
            <Typography component="div">All fields are required.</Typography>
          </>
        }
      >
        <Stack gap={4}>
          <YoodliLabeledInput
            label={
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    color: getDynamicColor("dark5"),
                    mb: 0.5,
                  }}
                >
                  Explanation
                </Typography>
                <Button
                  sx={{ fontSize: 12, color: getDynamicColor("dark4"), ml: "auto" }}
                  onClick={async () => {
                    goalHooks.setGoalDescriptionLoading(true);
                    const rephrase = await getGoalRephrase(
                      binaryGoal.aiDescription,
                      "description",
                      binaryGoal.name,
                      ""
                    );
                    handleUpdateBinaryGoal("aiDescription", rephrase);
                    goalHooks.setGoalDescriptionLoading(false);
                  }}
                >
                  {binaryGoal.aiDescription.length > 0 ? "Rephrase" : "Generate"}
                </Button>
              </Stack>
            }
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <Box sx={{ position: "relative" }}>
                {goalHooks.goalDescriptionLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <YoodliTextfield
                  multiline
                  minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                  inputProps={{
                    className: "blockEnterToNavigate",
                  }}
                  maxChars={CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS}
                  placeholder="e.g. The main goal for this call is to set a date for a project deadline. "
                  InputLabelProps={{ shrink: true }}
                  value={binaryGoal.aiDescription}
                  onChange={(e) => handleUpdateBinaryGoal("aiDescription", e.target.value)}
                  disabled={goalHooks.goalDescriptionLoading}
                />
              </Box>
            }
          />

          <Stack sx={{ fontFamily: "Poppins", fontSize: 14, fontWeight: 600 }}>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
              }}
            >
              Score
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("secondary"),
              }}
            >
              Missed / Achieved
            </Typography>
          </Stack>
          <YoodliLabeledInput
            label={
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    color: getDynamicColor("dark5"),
                    mb: 0.5,
                  }}
                >
                  Define "Missed"
                </Typography>
                <Button
                  sx={{ fontSize: 12, color: getDynamicColor("dark4"), ml: "auto" }}
                  onClick={async () => {
                    goalHooks.setGoalLowScoreLoading(true);
                    const rephrase = await getGoalRephrase(
                      binaryGoal.lowScoreDescription,
                      "low",
                      binaryGoal.name,
                      binaryGoal.aiDescription
                    );
                    handleUpdateBinaryGoal("lowScoreDescription", rephrase);
                    goalHooks.setGoalLowScoreLoading(false);
                  }}
                >
                  {binaryGoal.lowScoreDescription.length > 0 ? "Rephrase" : "Generate"}
                </Button>
              </Stack>
            }
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <Box sx={{ position: "relative" }}>
                {goalHooks.goalLowScoreLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <YoodliTextfield
                  multiline
                  minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                  inputProps={{
                    className: "blockEnterToNavigate",
                  }}
                  maxChars={CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS}
                  placeholder="e.g. The member did not set a clear date during the call."
                  InputLabelProps={{ shrink: true }}
                  value={binaryGoal.lowScoreDescription}
                  onChange={(e) => handleUpdateBinaryGoal("lowScoreDescription", e.target.value)}
                  disabled={goalHooks.goalLowScoreLoading}
                />
              </Box>
            }
          />
          <YoodliLabeledInput
            label={
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    color: getDynamicColor("dark5"),
                    mb: 0.5,
                  }}
                >
                  Define "Achieved"
                </Typography>
                <Button
                  sx={{ fontSize: 12, color: getDynamicColor("dark4"), ml: "auto" }}
                  onClick={async () => {
                    goalHooks.setGoalHighScoreLoading(true);
                    const rephrase = await getGoalRephrase(
                      binaryGoal.highScoreDescription,
                      "high",
                      binaryGoal.name,
                      binaryGoal.aiDescription
                    );
                    handleUpdateBinaryGoal("highScoreDescription", rephrase);
                    goalHooks.setGoalHighScoreLoading(false);
                  }}
                >
                  {binaryGoal.highScoreDescription.length > 0 ? "Rephrase" : "Generate"}
                </Button>
              </Stack>
            }
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <Box sx={{ position: "relative" }}>
                {goalHooks.goalHighScoreLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <YoodliTextfield
                  multiline
                  minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                  maxChars={CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS}
                  inputProps={{
                    className: "blockEnterToNavigate",
                  }}
                  placeholder="e.g. The member set a clear date at some point during the call."
                  InputLabelProps={{ shrink: true }}
                  value={binaryGoal.highScoreDescription}
                  onChange={(e) => handleUpdateBinaryGoal("highScoreDescription", e.target.value)}
                  sx={{
                    backgroundColor: getDynamicColor("light1"),
                  }}
                  disabled={goalHooks.goalHighScoreLoading}
                />
              </Box>
            }
          />
        </Stack>
      </ConvoScenarioStepWrapper>
    ),
    validate: () =>
      binaryGoal.aiDescription.length > 0 &&
      binaryGoal.highScoreDescription.length > 0 &&
      binaryGoal.lowScoreDescription.length > 0,
    skipNextIndexUpdate: true,
    next: async () => {
      await createCustomGoal(binaryGoal);
      if (!inScenarioCreation) {
        closeWizard();
      }
    },
    nextCopy: "Save",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;

  return [descStep, evaluationStep];
};
