import { db } from "lib-fullstack";
import { noop } from "lodash";

// Components
import { Button } from "@mui/material";

// Assets
import GoogleIcon from "images/icons/GoogleCalendarIcon.svg";

// Utils
import { envConfig } from "../../utils/Constants";
import { GOOGLE_SCOPE } from "./calendarUtils";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useGoogleLogin, hasGrantedAllScopesGoogle } from "@react-oauth/google";
import {
  addGoogleCalendar,
  setCredentials,
  syncCalendarEvents,
} from "lib-frontend/modules/AxiosInstance";

type Props = {
  setError: (val: boolean) => void;
  setLoading: (val: boolean) => void;
  mode: db.CalendarMode;
  onSuccess?: () => Promise<void> | void;
  onFailure?: (err: unknown) => Promise<void>;
};

function ConnectGoogleCalendarWithProvider(props: Props): JSX.Element {
  const handleSuccess = async (authCode: string) => {
    // Do NOT await the async cal methods; the UI should "optimistically" continue.
    props.setLoading(false);
    props.setError(false);
    void props.onSuccess?.();

    addGoogleCalendar(authCode, props.mode)
      .then(syncCalendarEvents, props.onFailure ?? noop)
      .catch(props.onFailure ?? noop);
  };

  const auth = async (codeResponse) => {
    if (!hasGrantedAllScopesGoogle(codeResponse, GOOGLE_SCOPE)) {
      await setCredentials(codeResponse.code);
      login();
    } else {
      void handleSuccess(codeResponse);
    }
  };

  const handleError = async (errorResponse) => {
    console.error(errorResponse);
    props.setError(true);
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => auth(codeResponse),
    onError: (errorResponse) => handleError(errorResponse),
    flow: "auth-code",
    scope: GOOGLE_SCOPE,
  });

  return (
    <Button
      variant="outlined"
      onClick={() => login()}
      startIcon={<img src={GoogleIcon} alt="Google" height="20px" width="20px" />}
      sx={{
        width: { xs: "100%", xl: "min(100%, 150px)" },
        height: "35px",
        fontSize: "12px",
      }}
    >
      Google Calendar
    </Button>
  );
}

export default function ConnectGoogleCalendar(props: Props): JSX.Element {
  return (
    <GoogleOAuthProvider clientId={envConfig.gCloudClientId}>
      <ConnectGoogleCalendarWithProvider {...props} />
    </GoogleOAuthProvider>
  );
}
