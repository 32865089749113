import { db } from "lib-fullstack";
import { useNavigate } from "react-router";

// Components
import { CardContent, CardMedia, styled, Typography } from "@mui/material";

// Assets
import PlaceholderThumbnail from "images/graphics/video-placeholder.svg";

// Utils
import { generateSpeechSharePath } from "../../utils/Utilities";
import { VideoContainerHighlightWrapper } from "./HomeStyles";
import { getDynamicColor } from "lib-frontend/utils/Colors";

type FeaturedSpeechProps = {
  speechItem: db.Doc<db.Speech>;
  isSelected: boolean;
  isHighlighted: boolean;
};

type FeaturedVideoProps = {
  videoURL: string;
  videoName: string;
  thumbnailPath: string;
  openInLightbox: () => void;
};

type FeaturedCourseProps = db.CourseInfo & {
  fallbackThumbnail?: string;
  courseId: string;
  onClick?: () => void;
};

const CARD_MEDIA_HEIGHT = {
  xs: "140px",
  md: "130px",
  lg: `180px`,
};

const CenteredDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

// Featuring a dbSpeech
export function FeaturedSpeech(props: FeaturedSpeechProps): React.ReactElement {
  const navigate = useNavigate();

  const speech = props.speechItem.data;

  const handleOpenSharedSpeech = () => {
    navigate(generateSpeechSharePath(speech));
  };

  const otherProps = props.isHighlighted ? { className: "highlighted-video" } : {};

  return (
    <VideoContainerHighlightWrapper
      {...otherProps}
      sx={{
        "&.highlighted-video": {
          borderColor: getDynamicColor("primary"),
          borderWidth: "3px",
        },
      }}
      onClick={handleOpenSharedSpeech}
    >
      <CenteredDiv>
        <SpeechThumbnail speech={speech} />
        <VideoName name={speech.name} />
      </CenteredDiv>
    </VideoContainerHighlightWrapper>
  );
}

// Featuring a plain old video. Metadata and image
export function FeaturedVideo(props: FeaturedVideoProps): React.ReactElement {
  return (
    <VideoContainerHighlightWrapper onClick={props.openInLightbox}>
      <CenteredDiv>
        <VideoThumbnail videoName={props.videoName} thumbnailImage={props.thumbnailPath} />
        <VideoName name={props.videoName} />
      </CenteredDiv>
    </VideoContainerHighlightWrapper>
  );
}

// Featuring course image and metadata
export function FeaturedCourse(props: FeaturedCourseProps): JSX.Element {
  const navigate = useNavigate();

  const handleOpenCourse = () => {
    navigate(`/course/${props.slug}`);
  };

  return (
    <VideoContainerHighlightWrapper onClick={props.onClick ?? handleOpenCourse}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <VideoThumbnail
          videoName={props.title}
          thumbnailImage={props.thumbnail?.length ? props.thumbnail : props.fallbackThumbnail}
        />
      </div>
      <CardContent>
        <Typography
          fontWeight={600}
          component="h5"
          fontSize={16}
          sx={{
            mb: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            // for single word name with no space, overflow ellipsis didnt work since you cant
            // clamp to 2 lines and do overflow ellipsis on a single line
            // this happens a lot with filenames, so worth the check here
            display: props.title?.includes(" ") ? "-webkit-box" : "block",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            lineHeight: 1.3,
          }}
        >
          {props.title}
        </Typography>
        <Typography
          className="description"
          variant="body2"
          color="text.secondary"
          mt="auto"
          fontSize={14}
        >
          {props.description}
        </Typography>
      </CardContent>
    </VideoContainerHighlightWrapper>
  );
}

function VideoThumbnail({
  videoName,
  thumbnailImage,
}: {
  videoName: string;
  thumbnailImage: string;
}): React.ReactElement {
  return (
    <CardMedia
      component="img"
      image={thumbnailImage}
      title={videoName}
      alt={videoName}
      sx={{ height: CARD_MEDIA_HEIGHT }}
      onError={(e) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = PlaceholderThumbnail;
        e.currentTarget.style.transform = "none";
      }}
    />
  );
}

function VideoName({ name }: { name: string }): React.ReactElement {
  return (
    <Typography
      fontWeight={600}
      sx={{
        my: 2,
        px: 1,
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // for single word name with no space, overflow ellipsis didnt work since you cant
        // clamp to 2 lines and do overflow ellipsis on a single line
        // this happens a lot with filenames, so worth the check here
        display: name?.includes(" ") ? "-webkit-box" : "block",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        lineHeight: 1.3,
      }}
    >
      {name}
    </Typography>
  );
}

function SpeechThumbnail({ speech }: { speech: db.Speech }): React.ReactElement {
  if (!speech.featuredSpeechThumbnail) {
    return (
      <CardMedia
        component="img"
        image={PlaceholderThumbnail}
        alt={speech.name}
        title={speech.name}
      />
    );
  }

  return (
    <CardMedia
      component="img"
      image={speech.featuredSpeechThumbnail}
      title={speech.name}
      alt={speech.name}
      sx={{
        height: CARD_MEDIA_HEIGHT,
        width: "auto",
      }}
      style={
        speech.noCanvasRecording || speech.mirrored // if the speech was recorded post canvas off, we need to mirror the thumbnail to match what was/is displayed
          ? { transform: "scaleX(-1)" }
          : {}
      }
      onError={(e) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = PlaceholderThumbnail;
        e.currentTarget.style.transform = "none";
      }}
    />
  );
}
