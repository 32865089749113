import React from "react";

// Components
import { Button, CircularProgress, Grid, InputLabel, Stack, Typography } from "@mui/material";
import { IOSSwitch } from "components/Settings/Settings";

// Utils
import { OrgDetailCardItemLabel } from "./AdminConfigOrgDetails";
import { UpdateOrgDetailCardProps } from "./UpdateOrgModal";
import { adminUpdateOrgData } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { AdminUpdateOrgRequest } from "lib-fullstack/api/adminApiTypes";

export const UpdateFeatureFlagsForm = ({
  orgId,
  card,
  setRerenderKey,
  close,
}: UpdateOrgDetailCardProps): React.ReactElement => {
  // Hooks
  const [interviewEnabled, setInterviewEnabled] = React.useState<boolean>(
    card.items.find((item) => item.label === OrgDetailCardItemLabel.INTERVIEW_ENABLED)
      .data as boolean
  );

  const [loadingText, setLoadingText] = React.useState<string>("");
  const [errorText, setErrorText] = React.useState<string>("");

  const handleUpdateOrg = async (e) => {
    e.preventDefault();

    setErrorText("");
    try {
      setLoadingText(`Updating ${card.label}`);
      const params: AdminUpdateOrgRequest = {
        features: {
          interviewEnabled: interviewEnabled,
        },
      };
      await adminUpdateOrgData(orgId, params);
      setRerenderKey((prevKey: number) => prevKey + 1);
      close();
    } catch (e) {
      console.log(`error updating ${card.label}: ${e}`);
      setErrorText(`Error Updating ${card.label}: ${e}`);
    }
    setLoadingText("");
  };

  return (
    <Stack width="100%" gap={2} p={3}>
      {errorText && (
        <Typography p={1} color="error" fontWeight={600} fontSize="12px">
          {errorText}
        </Typography>
      )}
      <form style={{ width: "100%" }} onSubmit={async (e) => await handleUpdateOrg(e)}>
        <Stack gap={3}>
          <Stack>
            <Grid container>
              <Grid item xs={10} lg={6}>
                <InputLabel
                  htmlFor="interview-enabled-toggle"
                  sx={{
                    pb: 0.5,
                    ml: 2,
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Interview enabled
                </InputLabel>
              </Grid>
              <Grid item xs={1} lg={6}>
                <IOSSwitch
                  id="interview-enabled-toggle"
                  checked={interviewEnabled}
                  onChange={(e) => setInterviewEnabled(e.target.checked)}
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>

        <input type="submit" style={{ display: "none" }} />
      </form>
      <Button
        startIcon={
          loadingText.length > 0 ? (
            <CircularProgress
              size={20}
              sx={{
                color: getDynamicColor("dark1"),
              }}
            />
          ) : (
            <></>
          )
        }
        onClick={async (e) => await handleUpdateOrg(e)}
        variant="gradient"
        disabled={!!loadingText}
        sx={{
          fontFamily: "poppins",
          fontSize: "16px",
          marginTop: 3,
          fontWeight: 700,
          "&:disabled": {
            color: getDynamicColor("light1"),
            background: getDynamicColor("dark4"),
          },
        }}
      >
        {loadingText.length == 0 ? `Update ${card.label}` : loadingText}
      </Button>
    </Stack>
  );
};
