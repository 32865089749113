import firebase from "firebase/app";
import React from "react";

// Components
import { Replay } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Button, Divider, Link, Stack, Typography } from "@mui/material";
import { ScenarioSortMenu, ScenarioSortOption } from "components/Builder/ScenarioSortMenu";
import {
  CustomizePracticeQueryKey,
  WizardState,
} from "components/ConvoScenarios/convoScenarioUtils";
import { CustomScenarioCard } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CustomScenarioCard";
import { TableFilter } from "components/VideoJournal/TableComponents/TableFilter";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";

// Utils
import { TEMPLATE_SUB_TYPE_DATA } from "../../../../ConvoScenarios/convoScenarioUtils";
import { OrgScenarioTemplateGrid } from "./OrgScenarioTemplateGrid";
import { useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createScenario, listScenarios } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { SupportPages } from "lib-frontend/utils/paths";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { PersonaResponse } from "lib-fullstack/api/scenarioApiTypes";
import { OrgScenario } from "lib-fullstack/db";
import { OrgIntegrationQueryKeys } from "lib-frontend/utils/orgUtils";
import { listOrgIntegrations } from "lib-frontend/modules/axiosOrgIntegration";
import { getEnabledFlag } from "lib-frontend/utils/unleash";

type ManageConvoScenariosProps = {
  handleTemplateSelected: (scenario: GetScenarioResponse, scenarioId?: string) => void;
  selectedOrg: OrgV2Response;
  wizardState: WizardState;
  setWizardState: (state: WizardState) => void;
  renderLoader: () => JSX.Element;
  showScenarioTemplates: boolean;
  handleCreateScenarioClicked: () => void;
};

export const ManageConvoScenarios = ({
  handleTemplateSelected,
  selectedOrg,
  wizardState,
  setWizardState,
  renderLoader,
  showScenarioTemplates,
  handleCreateScenarioClicked,
}: ManageConvoScenariosProps): JSX.Element => {
  const queryClient = useQueryClient();
  const isSmallScreen = useIsSmallScreen();

  const [sortBy, setSortBy] = React.useState<ScenarioSortOption>(ScenarioSortOption.Name);
  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [groupFilter, setGroupFilter] = React.useState<string>(undefined);

  const { defaultOrg, defaultOrgLoading } = React.useContext(UserOrgContext);

  const scenariosQuery = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrg?.id],
    queryFn: async () => await listScenarios(defaultOrg?.id),
    enabled: !!firebase.auth().currentUser?.uid && !!defaultOrg && !defaultOrgLoading,
    refetchOnWindowFocus: false,
  });

  const integrationsQuery = useApiQuery({
    queryKey: [OrgIntegrationQueryKeys.ORG_INTEGRATIONS, defaultOrg?.id],
    queryFn: () => listOrgIntegrations(defaultOrg.id),
    enabled: getEnabledFlag("lti-1.1"),
    refetchOnWindowFocus: false,
  });
  const integrations = React.useMemo(() => {
    return integrationsQuery.data?.integrations.filter((integration) => !!integration.name) ?? [];
  }, [integrationsQuery?.data]);

  const personasQueryState = queryClient.getQueryState([
    CustomizePracticeQueryKey.Personas,
    defaultOrg?.id,
  ]);

  const customScenarios =
    scenariosQuery.data?.contentArray?.filter(
      (scenario) => scenario.enabled && !scenario.isTemplate
    ) ?? [];
  const templates =
    scenariosQuery.data?.contentArray?.filter((scenario) => scenario.isTemplate) ?? [];

  const getFilteredAndSortedScenarios = React.useCallback(
    (filterTemplates) => {
      const filteredScenarios = (filterTemplates ? customScenarios : templates).filter(
        (scenario) => {
          const groupFilterSet = new Set(groupFilter?.split(","));
          return (
            (!searchFilter || scenario.title.toLowerCase().includes(searchFilter.toLowerCase())) &&
            (!groupFilter || scenario.activeHubs?.some((hub) => groupFilterSet.has(hub)))
          );
        }
      );

      switch (sortBy) {
        case ScenarioSortOption.Name:
          return filteredScenarios.sort((a, b) => a.title.localeCompare(b.title));
        case ScenarioSortOption.CreatedAt:
          return filteredScenarios
            .sort((a, b) =>
              (a as OrgScenario).createdAt.localeCompare((b as OrgScenario).createdAt)
            )
            .reverse();
        case ScenarioSortOption.UpdatedAt:
          return filteredScenarios.sort((a, b) =>
            (a as OrgScenario).modifiedAt?.localeCompare((b as OrgScenario).modifiedAt)
          );
      }
    },
    [customScenarios, templates, searchFilter, groupFilter, sortBy]
  );

  const loadingScenarios =
    (scenariosQuery.isLoading || scenariosQuery.isFetching) && !scenariosQuery.isRefetching;

  const hasScenarioIdTypeChecker = (res: unknown): res is { scenarioId: string } => {
    return Object.prototype.hasOwnProperty.call(res, "scenarioId");
  };

  const handleSelectAndCreateTemplate = async (scenario: GetScenarioResponse) => {
    let title = `My ${TEMPLATE_SUB_TYPE_DATA[scenario.templateSubType]?.label}`;
    if (wizardState === "duplicate") {
      title = `${scenario.title} (copy)`;
    }
    const res = await createScenario(
      selectedOrg?.id,
      firebase.auth().currentUser?.email,
      title,
      scenario.id
    ).catch((er) => {
      console.error(`Error creating convo scenario: ${er}`);
    });

    if (!hasScenarioIdTypeChecker(res)) {
      console.error("Error creating convo scenario: no scenarioId was returned");
      return;
    }
    handleTemplateSelected(scenario, res?.scenarioId);
    setWizardState("create");
    // dont wait here since we want the UI to update to the wizard immediately
    void scenariosQuery.refetch();
  };
  const handleSelectScenario = async (scenario: GetScenarioResponse, action: WizardState) => {
    if (action === "edit") {
      handleTemplateSelected(scenario);
    } else if (action === "duplicate") {
      await handleSelectAndCreateTemplate(scenario);
    }
    setWizardState(action);
  };

  const renderCustomScenarios = () => {
    if (scenariosQuery.isError) {
      return (
        <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
          <Typography
            sx={{
              color: getDynamicColor("redError"),
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            There was a problem loading your scenarios.
          </Typography>
          <Button
            variant="contained"
            sx={{ fontSize: 12, "& .MuiButton-endIcon": { ml: 0.5 } }}
            endIcon={<Replay sx={{ width: 12, height: 12 }} />}
            onClick={() => scenariosQuery.refetch()}
          >
            Retry
          </Button>
        </Stack>
      );
    }
    if (!getFilteredAndSortedScenarios(true)?.length && searchFilter) {
      return (
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          No scenarios found for "{searchFilter}"
        </Typography>
      );
    }
    return (
      <Stack direction="column" gap={2}>
        {getFilteredAndSortedScenarios(true)?.map((scenario) => {
          let defaultPersonaId = null;
          if (scenario.personaIds && scenario.personaIds.length) {
            defaultPersonaId = scenario.personaIds[0];
          } else {
            defaultPersonaId = scenario.defaultPersonaId;
          }
          const allHubIds = (selectedOrg?.hubs ?? []).map((hub) => hub.id);
          const cleanedActiveHubIds = (scenario?.activeHubs ?? []).filter((hubId) =>
            allHubIds.includes(hubId)
          );
          return (
            <CustomScenarioCard
              key={scenario.id}
              selectedOrgId={selectedOrg?.id}
              handleSelectScenario={handleSelectScenario}
              integrations={integrations ?? []}
              scenario={scenario}
              activeHubIds={cleanedActiveHubIds}
              allHubs={selectedOrg?.hubs.sort((a, b) => a.name.localeCompare(b.name))}
              persona={(personasQueryState?.data as PersonaResponse[])?.find(
                (p) => p.id === defaultPersonaId
              )}
              personaLoading={personasQueryState?.status === "pending"}
            />
          );
        })}
      </Stack>
    );
  };

  const renderSearchBar = () => {
    return (
      <YoodliSearchBar
        value={searchFilter}
        onChange={(e) => setSearchFilter(e.target.value)}
        clearSearch={() => setSearchFilter("")}
        placeholder="Search"
        InputProps={{
          sx: {
            width: { xs: 160, sm: 200 },
            height: "35px",
          },
        }}
        sx={{
          width: { xs: 160, sm: 200 },
          height: "35px",
        }}
      />
    );
  };

  const renderScenarioFilters = (templateFilters: boolean) => {
    return (
      <Stack
        direction="row"
        rowGap={1}
        justifyContent={!templateFilters ? "space-between" : "flex-end"}
        alignItems={{ xs: "flex-start", md: "center" }}
        sx={{
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {!templateFilters && (
          <Stack
            direction="row"
            sx={{
              width: isSmallScreen ? "100%" : "fit-content",
            }}
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: { xs: 16, md: 18 },
                fontWeight: 700,
              }}
            >
              Scenario Library
            </Typography>
            {isSmallScreen && renderSearchBar()}
          </Stack>
        )}
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={{ xs: 0, md: "20px" }}
          sx={{ alignItems: "center", justifyContent: "flex-end", flexGrow: 1 }}
        >
          <TableFilter
            searchable={true}
            setFilterState={setGroupFilter}
            names={defaultOrg.hubs.map((hub) => {
              return { label: hub.name, value: hub.id };
            })}
            buttonProps={{ startIcon: <FilterAltOutlinedIcon /> }}
            buttonSx={{ color: getDynamicColor("primary"), whiteSpace: "nowrap", fontSize: "14px" }}
            buttonText="Filter by"
          />
          {!templateFilters && (
            <>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: getDynamicColor("dark4"),
                  height: "20px",
                  my: "auto",
                }}
              />
              <ScenarioSortMenu sortBy={sortBy} setSortBy={setSortBy} />
            </>
          )}
          {(!isSmallScreen || templateFilters) && renderSearchBar()}
        </Stack>
      </Stack>
    );
  };
  if (showScenarioTemplates) {
    return (
      <Stack
        gap={1}
        sx={{
          p: { xs: 2, md: 0 },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 22, md: 24 },
            textAlign: "center",
            fontFamily: "poppins",
            color: getDynamicColor("purple3"),
            fontWeight: 700,
            mx: "auto",
          }}
        >
          Create a roleplay scenario
        </Typography>
        {renderScenarioFilters(true)}
        <Stack gap={3}>
          <OrgScenarioTemplateGrid
            scenarios={getFilteredAndSortedScenarios(false)}
            handleSelectTemplate={handleSelectAndCreateTemplate}
          />
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      sx={{
        fontFamily: "poppins",
        p: { xs: 3, md: 0 },
        pb: { xs: 3, md: 1 },
        maxWidth: "xxl",
        overflow: "hidden",
      }}
      gap={6}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        gap={{ xs: 2, md: 3 }}
        sx={{ justifyContent: "space-between", alignItems: { xs: "flex-start", md: "center" } }}
      >
        <Stack gap="6px">
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            Create a new scenario
          </Typography>
          <Typography
            sx={{
              color: getDynamicColor("dark5"),
              fontFamily: "poppins",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            Build customized roleplay scenarios for your members to practice with.
            <br />
            Select a template to get started!
          </Typography>
          <Typography
            sx={{
              mt: 1,
              color: getDynamicColor("dark5"),
              fontFamily: "poppins",
              fontSize: "10px",
              fontWeight: 600,
            }}
          >
            Want to learn more about scenarios? Check out this{" "}
            <Link href={SupportPages.SCENARIOS} target="_blank">
              support article
            </Link>
            .
          </Typography>
        </Stack>
        <Button
          onClick={handleCreateScenarioClicked}
          variant="contained"
          size="xlarge"
          endIcon={<AddIcon />}
          sx={{
            fontSize: 14,
            whiteSpace: "nowrap",
          }}
        >
          Create from template
        </Button>
      </Stack>
      {!!customScenarios?.length && (
        <>
          <Divider />
          <Stack direction="column" justifyContent="flex-start" gap={2}>
            {renderScenarioFilters(false)}

            {loadingScenarios ? renderLoader() : renderCustomScenarios()}
          </Stack>
        </>
      )}
    </Stack>
  );
};
