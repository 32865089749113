import { Typography } from "@mui/material";
import { getMMSS } from "lib-fullstack/utils/dateUtils";

const TimerDisplay = ({ timeS }: { timeS: number }): JSX.Element => {
  return (
    <Typography sx={{ fontWeight: 700, fontSize: "48px", lineHeight: 1, pb: 1.5 }}>
      {getMMSS(timeS)}
    </Typography>
  );
};

export default TimerDisplay;
