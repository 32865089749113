import { Button, ButtonProps } from "@mui/material";
import { getDynamicColor } from "lib-frontend/utils/Colors";

export function AuthButton(
  props: ButtonProps & {
    logo: JSX.Element;
    title: string;
    isSignUp?: boolean;
  }
): JSX.Element {
  const { logo, title, isSignUp, sx, ...restOfProps } = props;
  return (
    <Button
      {...restOfProps}
      variant="contained"
      startIcon={logo}
      sx={{
        backgroundColor: getDynamicColor("light1"),
        width: "100%",
        "&:hover": {
          backgroundColor: getDynamicColor("dark2"),
        },
        fontWeight: 600,
        fontSize: 14,
        color: getDynamicColor("dark6"),
        height: 48,
        ...sx,
      }}
    >
      {isSignUp ? "Continue" : "Sign in"} with {title}
    </Button>
  );
}
