// Components
import { Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

export type ProgramUserStatProps = {
  title: string;
  value: string | number;
  valueDescription?: string;
};

export const ProgramUserStat = ({
  title,
  value,
  valueDescription,
}: ProgramUserStatProps): JSX.Element => {
  return (
    <Stack
      gap={1}
      sx={{
        borderRadius: "4px",
        background: getDynamicColor("light2"),
        p: "20px",
        minHeight: "100px",
        height: "100px",
      }}
    >
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontFamily: "poppins",
          fontSize: "10px",
        }}
      >
        {title}
      </Typography>
      <Stack direction="row" gap={1} sx={{ alignItems: "baseline" }}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "25px",
            fontWeight: 600,
          }}
        >
          {value}
        </Typography>
        {valueDescription && (
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "10px",
              fontWeight: 500,
            }}
          >
            {valueDescription}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
