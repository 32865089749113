// Components
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

interface AnimatedTextButtonProps {
  gradient?: string;
  styles?: React.CSSProperties;
  animationspeeds?: number;
}

export const getShineAnimationStyles = (
  textClip: boolean,
  animationspeeds = 3
): {
  [key: string]: string | object | number | undefined;
} => {
  return {
    backgroundSize: "200% auto",
    WebkitAnimation: `shine ${animationspeeds}s linear infinite`,
    animation: `shine ${animationspeeds}s linear infinite`,
    "@-webkit-keyframes shine": {
      to: {
        backgroundPosition: "200% center",
      },
    },
    "@keyframes shine": {
      to: {
        backgroundPosition: "200% center",
      },
    },
    ...(textClip && {
      color: "#000",
      backgroundClip: "text",
      textFillColor: "transparent",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }),
  };
};

export const AnimatedGradientText = styled(Typography)((props: AnimatedTextButtonProps) => ({
  background: props.gradient ?? getDynamicColor("gradient.slantedLoop"),
  ...getShineAnimationStyles(true, props.animationspeeds),
  ...props.styles,
}));
