import React from "react";

// Components
import AddIcon from "@mui/icons-material/Add";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { TemplateSubTypeChip } from "components/ConvoScenarios/TemplateSubTypeChip";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { GoalImprovementCard } from "./GoalImprovement/GoalImprovementCard";
import { ProgramInfoTab } from "./ProgramInfo";
import { UserActivity } from "./UserActivity";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { HubData } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { ScenarioTemplateSubType } from "lib-fullstack/db";
import { ProgramState } from "lib-fullstack/utils/enums";
import { ProgramEvaluatorSelector } from "./ProgramEvaluatorSelector";

type OverviewSectionProps = {
  title: string | JSX.Element;
  subtitle?: string;
  content: JSX.Element;
  cta?: JSX.Element;
  tag?: JSX.Element;
  halfWidthTitle?: boolean;
};

export const OVERVIEW_SECTION_TITLE_SX = {
  color: getDynamicColor("purple3"),
  fontSize: "14px",
  fontFamily: "poppins",
  fontWeight: 700,
  width: { xs: "fit-content", lg: "100%" },
};

export const OVERVIEW_SECTION_SUBTITLE_SX = {
  color: getDynamicColor("purple3"),
  fontSize: "12px",
  fontFamily: "poppins",
};

const OverviewSection = ({
  title,
  subtitle,
  content,
  cta,
  halfWidthTitle,
  tag,
}: OverviewSectionProps): JSX.Element => {
  return (
    <Stack
      gap={{ xs: 3, md: 5 }}
      direction={halfWidthTitle ? "row" : "column"}
      sx={{
        p: 3,
        flex: 1,
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        justifyContent: "space-between",
        minHeight: { xs: "120px", md: "175px" },
      }}
    >
      <Stack
        sx={{
          width: halfWidthTitle ? "50%" : "100%",
        }}
      >
        <Stack
          direction="row"
          gap={1}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Typography component="div" sx={OVERVIEW_SECTION_TITLE_SX}>
            {title}
          </Typography>
          {cta}
        </Stack>
        <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
          {subtitle && <Typography sx={OVERVIEW_SECTION_SUBTITLE_SX}>{subtitle}</Typography>}
          {tag}
        </Stack>
      </Stack>
      {content}
    </Stack>
  );
};

type ProgramOverviewProps = {
  setActiveTab: (tab: ProgramInfoTab) => void;
  program: ProgramResponse;
  hubs: HubData[];
  scenarios: GetScenarioResponse[];
};

export const ProgramOverview = ({
  setActiveTab,
  program,
  hubs,
  scenarios,
}: ProgramOverviewProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const { defaultOrg } = React.useContext(UserOrgContext);
  const [addingEvaluators, setAddingEvaluators] = React.useState(false);

  React.useLayoutEffect(() => {
    Instrumentation.logProgramAdminOverviewViewed(defaultOrg.id, program.id);
  }, []);

  const renderGroupsContent = () => {
    if (program.hub_ids.length) {
      const hubsToShow = program.hub_ids
        .map((hubId) => hubs.find((hub) => hub.id === hubId))
        .slice(0, 3)
        // be sure to filter out any undefined values, i.e. if a hub was deleted, or less than 3 exist
        .filter(Boolean);
      return (
        <Stack gap={2}>
          {hubsToShow.map((hub) => {
            return (
              <React.Fragment key={hub.id}>
                <Divider />
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {hub.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: getDynamicColor("dark5"),
                      fontFamily: "poppins",
                      fontSize: "12px",
                    }}
                  >
                    {hub.numMembers} member{hub.numMembers !== 1 ? "s" : ""}
                  </Typography>
                </Stack>
              </React.Fragment>
            );
          })}
          {program.hub_ids.length > 3 && (
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
                fontSize: "12px",
                fontFamily: "poppins",
                fontWeight: 700,
              }}
            >
              + {program.hub_ids.length - 3} more
            </Typography>
          )}
        </Stack>
      );
    } else {
      return (
        <Button
          onClick={() => setActiveTab(ProgramInfoTab.Groups)}
          startIcon={<AddIcon />}
          sx={{ m: "auto", fontSize: "14px" }}
        >
          Enroll groups
        </Button>
      );
    }
  };

  const renderScenariosContent = () => {
    if (program.plan_steps.length) {
      const scenariosToShow = program.plan_steps
        .slice(0, 3)
        .map((step) => scenarios.find((s) => s.id === step.scenario_id));

      return (
        <Stack gap={2}>
          {scenariosToShow.map((scenario, index) => (
            <React.Fragment key={`${scenario.id}-${index}`}>
              <Divider />
              <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {scenario.title}
                </Typography>
                <TemplateSubTypeChip
                  templateSubType={scenario.templateSubType as ScenarioTemplateSubType}
                />
              </Stack>
            </React.Fragment>
          ))}
        </Stack>
      );
    }
    return (
      <Button
        onClick={() => setActiveTab(ProgramInfoTab.Scenarios)}
        startIcon={<AddIcon />}
        sx={{ m: "auto", fontSize: "14px" }}
      >
        Add scenarios
      </Button>
    );
  };

  const renderUserStatsContent = () => {
    return program.user_activity.length ? (
      <Box sx={{ my: "auto" }}>
        <UserActivity userActivity={program.user_activity} />
      </Box>
    ) : (
      <Typography
        sx={{
          color: getDynamicColor("dark4"),
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 600,
          textAlign: "center",
          m: "auto",
        }}
      >
        You will see user stats here once enrolled group members start their programs
      </Typography>
    );
  };

  return (
    <Stack gap={{ xs: 2, md: 4 }}>
      <Stack direction="row" gap={4}>
        <OverviewSection
          title="Groups"
          subtitle={program.hub_ids.length ? "Enrolled in this program" : undefined}
          content={renderGroupsContent()}
          cta={
            program.hub_ids.length ? (
              <Button
                onClick={() => setActiveTab(ProgramInfoTab.Groups)}
                sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
              >
                View all
              </Button>
            ) : undefined
          }
        />
        {!isSmallScreen && (
          <OverviewSection
            title="User stats"
            subtitle={program.user_activity.length ? "Overview of overall usage" : undefined}
            content={renderUserStatsContent()}
            cta={
              program.hub_ids.length ? (
                <Button
                  onClick={() => setActiveTab(ProgramInfoTab.Groups)}
                  sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                >
                  View all
                </Button>
              ) : undefined
            }
          />
        )}
      </Stack>
      {program?.state !== ProgramState.Draft && <GoalImprovementCard programId={program.id} />}
      <OverviewSection
        title="Scenarios"
        subtitle={program.plan_steps.length ? "Included in this program" : undefined}
        content={renderScenariosContent()}
        cta={
          program.plan_steps.length ? (
            <Button
              onClick={() => setActiveTab(ProgramInfoTab.Scenarios)}
              sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
            >
              View all
            </Button>
          ) : undefined
        }
        tag={
          program.state !== ProgramState.Draft && (
            <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
              <DoNotDisturbIcon sx={{ width: 12, height: 12 }} />
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontSize: "10px",
                  fontFamily: "poppins",
                  fontWeight: 600,
                }}
              >
                Programs steps are no longer editable
              </Typography>
            </Stack>
          )
        }
      />
      {isSmallScreen && (
        <OverviewSection
          title="User stats"
          subtitle={program.user_activity.length ? "Overview of overall usage" : undefined}
          content={renderUserStatsContent()}
        />
      )}
      <OverviewSection
        title={
          <Stack
            direction="row"
            sx={{ gap: 2, alignItems: "center", justifyContent: "space-between" }}
          >
            <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
              <Typography>Evaluators</Typography>
              <YoodliTooltip title="These are human reviewers who can adjust AI scores on member attempts.">
                <InfoOutlined sx={{ width: 18, height: 18, cursor: "pointer" }} />
              </YoodliTooltip>
            </Stack>
            {!addingEvaluators && program?.leaders.length > 0 && (
              <Button variant="contained" onClick={() => setAddingEvaluators(true)}>
                Add new
              </Button>
            )}
          </Stack>
        }
        content={
          <ProgramEvaluatorSelector
            program={program}
            addingEvaluators={addingEvaluators}
            setAddingEvaluators={setAddingEvaluators}
          />
        }
      />
    </Stack>
  );
};
