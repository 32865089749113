// Components
import { Box, SxProps, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

export const YoodliLabeledInput = ({
  label,
  wrapperSx,
  labelSx,
  inputEl,
}: {
  inputEl: JSX.Element;
  wrapperSx?: SxProps;
  label?: string | JSX.Element;
  labelSx?: SxProps;
}): JSX.Element => {
  return (
    <Box
      sx={{
        ".MuiTextField-root, .MuiInputBase-root": {
          width: "100%",
        },
        ...wrapperSx,
      }}
    >
      {label &&
        (typeof label === "string" ? (
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              fontFamily: "poppins",
              color: getDynamicColor("dark5"),
              mb: 1,
              ...labelSx,
            }}
          >
            {label}
          </Typography>
        ) : (
          label
        ))}
      {inputEl}
    </Box>
  );
};
