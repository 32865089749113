import React from "react";

// Components
import { Grow, List, Stack, Typography } from "@mui/material";

// Utils
import { CoachBotUploadedFile } from "../../CoachBotTypes";
import { TEXTFIELD_WIDTH } from "./CoachBotUploadContent";
import { UploadFileRow } from "./UploadFileRow";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";

type UploadedFilesProps = {
  files: CoachBotUploadedFile[];
  handleRemoveFile: (fileId: string) => Promise<void>;
  editingFileId: string;
  handleSetEditingFileId: (fileId: string) => void;

  newFileName: string;
  setNewFileName: (newFileName: string) => void;

  handleUpdateCoachBotContentFilename: (contentId: string, newFileName: string) => Promise<void>;
};

export const UploadedFiles = ({
  files,
  handleRemoveFile,
  editingFileId,
  handleSetEditingFileId,
  newFileName,
  setNewFileName,
  handleUpdateCoachBotContentFilename,
}: UploadedFilesProps): JSX.Element => {
  return (
    <Grow in={files?.length > 0} unmountOnExit timeout={300}>
      <Stack
        sx={{
          mt: 2,
          pb: { xs: 3, md: 6 },
          width: "100%",
          maxWidth: {
            xs: "unset",
            md: TEXTFIELD_WIDTH,
            lg: TEXTFIELD_WIDTH + 100,
            xl: TEXTFIELD_WIDTH + 200,
          },
        }}
        gap={0.5}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "poppins",
          }}
        >
          Your files:
        </Typography>
        <List
          sx={{
            width: "100%",
            border: `1px solid ${getDynamicColor("dark2")}`,
            borderRadius: "8px",
            boxShadow: Y_SHADOWS.dark_elevation,
            py: 0,
          }}
        >
          {files.map((file, index) => {
            return (
              <UploadFileRow
                index={index}
                key={index}
                file={file}
                editingFileId={editingFileId}
                handleSetEditingFileId={handleSetEditingFileId}
                handleRemoveFile={handleRemoveFile}
                newFileName={newFileName}
                setNewFileName={setNewFileName}
                handleUpdateCoachBotContentFilename={handleUpdateCoachBotContentFilename}
              />
            );
          })}
        </List>
      </Stack>
    </Grow>
  );
};
