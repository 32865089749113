import React from "react";
import AnimateHeight from "react-animate-height";

// Components
import { ExpandMoreRounded as ExpandMoreRoundedIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Stack,
  SxProps,
  Typography,
  Link,
} from "@mui/material";

// Assets
import { ReactComponent as ReportIcon } from "images/icons/icon-download-report.svg";
import { ReactComponent as DownloadIcon } from "images/icons/icon-download.svg";

// Utils
import {
  getDownloadableReport,
  getNoDataReport,
  getReportProcessing,
  REPORT_FIELDS,
  ReportsTabType,
  ReportSubType,
} from "./reportsUtils";
import { useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createReport, listReports } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { ListReportsResponse } from "lib-fullstack/api/reportApiTypes";
import { SUPPORT_ARTICLES } from "lib-fullstack/utils/constants";
import { getHoursToDate, getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { ReportParameter, ReportState, ReportType } from "lib-fullstack/utils/enums";
import { usePrevious } from "lib-frontend/hooks";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgReportWheres } from "lib-fullstack/utils/productAnalyticEvents";

enum ReportQueryKey {
  FeatureUse = "featureUseQueryKey",
  GoalImprovement = "goalImprovementQueryKey",
  UserEngagement = "userEngagementQueryKey",
}

type ReportsTabProps = {
  type: ReportsTabType;
  wrapperSx?: SxProps;
  // only for groups type
  hubName?: string;
  hubId?: string;
};

const ReportSubTypeCopyMap = {
  [ReportSubType.FeatureUse]: {
    title: "Feature Use",
    description:
      "See a breakdown of Presentation, Interview, and Roleplay practice sessions completed, as well as number of Uploads and Video calls for [NAME]members.",
  },
  [ReportSubType.GoalImprovement]: {
    title: "Goal Improvement",
    description:
      "See how [NAME]members have improved on all goals from their first score to their max score.",
  },
  [ReportSubType.UserEngagement]: {
    title: "User Engagement",
    description:
      "See aggregate data for [NAME]members on practice sessions, comments, sharing, and more.",
  },
};

export const ReportsTab = ({ type, wrapperSx, hubName, hubId }: ReportsTabProps): JSX.Element => {
  const { defaultOrg } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const isSmallScreen = useIsSmallScreen();

  const reportTypes = React.useMemo(
    () => ({
      [ReportSubType.FeatureUse]:
        type === ReportsTabType.Group ? ReportType.GroupFeatureUse : ReportType.OrgFeatureUse,
      [ReportSubType.GoalImprovement]:
        type === ReportsTabType.Group
          ? ReportType.GroupUserGoalImprovement
          : ReportType.OrgUserGoalImprovement,
      [ReportSubType.UserEngagement]:
        type === ReportsTabType.Group
          ? ReportType.GroupUserEngagement
          : ReportType.OrgUserEngagement,
    }),
    [type]
  );

  const [openAccordions, setOpenAccordions] = React.useState<ReportSubType[]>([]);
  const [processingReports, setProcessingReports] = React.useState<ReportSubType[]>([]);

  const featureUseQuery = useApiQuery({
    queryKey: [ReportQueryKey.FeatureUse, defaultOrg?.id, hubId],
    queryFn: () =>
      listReports(defaultOrg?.id, {
        reportType: reportTypes[ReportSubType.FeatureUse],
        states: [ReportState.Created, ReportState.Rendered, ReportState.Expired],
        parameters: JSON.stringify({ [ReportParameter.HubId]: hubId }),
      }),
    enabled: !!defaultOrg?.id,
    refetchInterval: () => (processingReports.includes(ReportSubType.FeatureUse) ? 2000 : 0),
    refetchOnWindowFocus: false,
  });

  const goalImprovementQuery = useApiQuery({
    queryKey: [ReportQueryKey.GoalImprovement, defaultOrg?.id, hubId],
    queryFn: () =>
      listReports(defaultOrg?.id, {
        reportType: reportTypes[ReportSubType.GoalImprovement],
        states: [ReportState.Created, ReportState.Rendered, ReportState.Expired],
        parameters: JSON.stringify({ [ReportParameter.HubId]: hubId }),
      }),
    enabled: !!defaultOrg?.id,
    refetchInterval: () => (processingReports.includes(ReportSubType.GoalImprovement) ? 2000 : 0),
    refetchOnWindowFocus: false,
  });

  const userEngagementQuery = useApiQuery({
    queryKey: [ReportQueryKey.UserEngagement, defaultOrg?.id, hubId],
    queryFn: () =>
      listReports(defaultOrg?.id, {
        reportType: reportTypes[ReportSubType.UserEngagement],
        states: [ReportState.Created, ReportState.Rendered, ReportState.Expired],
        parameters: JSON.stringify({ [ReportParameter.HubId]: hubId }),
      }),
    enabled: !!defaultOrg?.id,
    refetchInterval: () => (processingReports.includes(ReportSubType.UserEngagement) ? 2000 : 0),
    refetchOnWindowFocus: false,
  });

  const prevProcessingReports = usePrevious(processingReports);
  React.useEffect(() => {
    if (
      prevProcessingReports?.includes(ReportSubType.FeatureUse) &&
      !processingReports?.includes(ReportSubType.FeatureUse)
    ) {
      void handleReportCtaClick(
        ReportSubType.FeatureUse,
        getDownloadableReport(featureUseQuery?.data)?.downloadUrl
      );
    }
    if (
      prevProcessingReports?.includes(ReportSubType.GoalImprovement) &&
      !processingReports?.includes(ReportSubType.GoalImprovement)
    ) {
      void handleReportCtaClick(
        ReportSubType.GoalImprovement,
        getDownloadableReport(goalImprovementQuery?.data)?.downloadUrl
      );
    }
    if (
      prevProcessingReports?.includes(ReportSubType.UserEngagement) &&
      !processingReports?.includes(ReportSubType.UserEngagement)
    ) {
      void handleReportCtaClick(
        ReportSubType.UserEngagement,
        getDownloadableReport(userEngagementQuery?.data)?.downloadUrl
      );
    }
  }, [processingReports]);

  React.useEffect(() => {
    // find any reports that are currently processing i.e. created but no download url , and not expired, and add the id's to the processingReports state
    // check if r.expirationDate is in the future
    const featureUseReport = featureUseQuery.data?.reports.find(
      (r) =>
        r.state === ReportState.Created && !r.downloadUrl && new Date(r.expirationDate) > new Date()
    );

    const goalImprovementReport = goalImprovementQuery.data?.reports.find(
      (r) =>
        r.state === ReportState.Created && !r.downloadUrl && new Date(r.expirationDate) > new Date()
    );

    const userEngagementReport = userEngagementQuery.data?.reports.find(
      (r) =>
        r.state === ReportState.Created && !r.downloadUrl && new Date(r.expirationDate) > new Date()
    );

    setProcessingReports(
      [
        featureUseReport && ReportSubType.FeatureUse,
        goalImprovementReport && ReportSubType.GoalImprovement,
        userEngagementReport && ReportSubType.UserEngagement,
      ].filter(Boolean)
    );
  }, [featureUseQuery.data, goalImprovementQuery.data, userEngagementQuery.data]);

  const handleReportCtaClick = async (subType: ReportSubType, downloadUrl?: string) => {
    if (downloadUrl) {
      // download report
      window.location.assign(downloadUrl);
    } else {
      const params = {
        [ReportParameter.OrgId]: defaultOrg?.id,
      };
      if (type === ReportsTabType.Group) {
        params[ReportParameter.HubId] = hubId;
      }
      await createReport(defaultOrg?.id, {
        type: reportTypes[subType],
        parameters: JSON.stringify(params),
      });
      Instrumentation.logOrgReportGenerated(
        defaultOrg?.id,
        type === ReportsTabType.Group ? OrgReportWheres.GROUP : OrgReportWheres.ORG
      );
      switch (subType) {
        case ReportSubType.FeatureUse:
          await queryClient.invalidateQueries({ queryKey: [ReportQueryKey.FeatureUse] });
          break;
        case ReportSubType.GoalImprovement:
          await queryClient.invalidateQueries({ queryKey: [ReportQueryKey.GoalImprovement] });
          break;
        case ReportSubType.UserEngagement:
          await queryClient.invalidateQueries({ queryKey: [ReportQueryKey.UserEngagement] });
          break;
      }
    }
  };

  const getDesc = (subType: ReportSubType) => {
    return ReportSubTypeCopyMap[subType].description.replace(
      /\[NAME\]/g,
      type === ReportsTabType.Group ? `${hubName} ` : ""
    );
  };

  const renderAccordionSubHeader = (subType: ReportSubType, data: ListReportsResponse) => {
    const mostRecentReportRendered =
      data?.reports[0]?.state === ReportState.Rendered && !!data?.reports[0]?.renderDate;
    const mostRecentReportRequested =
      data?.reports[0]?.state === ReportState.Created && !data?.reports[0]?.renderDate;

    if (!mostRecentReportRendered && !mostRecentReportRequested) {
      // replace [NAME] in the following string with group name if its group type
      return (
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontSize: 12,
            fontWeight: 600,
            textAlign: "left",
          }}
        >
          {getDesc(subType)}
        </Typography>
      );
    }

    return (
      <Stack
        sx={{
          p: {
            color: getDynamicColor("dark5"),
            fontSize: 12,
            fontWeight: 600,
          },
        }}
      >
        <Typography>
          Requested on{" "}
          {getHumanReadableDate(data.reports[0]?.creationDate ?? new Date().toISOString(), {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
          })}{" "}
          by {data.reports[0]?.requestorDisplayName}
        </Typography>
      </Stack>
    );
  };

  const renderAccordionCta = (subType: ReportSubType, data: ListReportsResponse) => {
    let buttonText = "Download report";
    let buttonIcon = <DownloadIcon />;
    let color = getDynamicColor("primary");
    const downloadableReport = getDownloadableReport(data);
    const reportProcessing = getReportProcessing(data);
    if (reportProcessing) {
      buttonText = "Report processing";
      buttonIcon = (
        <Box
          sx={{
            position: "relative",
            height: 16,
            width: 16,
          }}
        >
          <CircularProgress
            size={14}
            thickness={6}
            sx={{
              position: "absolute",
              zIndex: 1,
              color: getDynamicColor("primary"),
              top: 0,
              left: 0,
            }}
          />
          <CircularProgress
            size={14}
            thickness={6}
            variant="determinate"
            value={100}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 0,
              color: getDynamicColor("purple1"),
            }}
          />
        </Box>
      );
      color = getDynamicColor("dark5");
    }
    return (
      <Button
        sx={{
          p: 0,
          fontSize: 12,
          whiteSpace: "nowrap",
          color,
          pointerEvents: reportProcessing ? "none" : "auto",
        }}
        disableRipple
        variant="text"
        startIcon={buttonIcon}
        onClick={() => handleReportCtaClick(subType, downloadableReport?.downloadUrl)}
      >
        {buttonText}
      </Button>
    );
  };

  const renderReportNotificationCopy = (subType: ReportSubType, data: ListReportsResponse) => {
    const downloadableReport = getDownloadableReport(data);
    const noDataReport = getNoDataReport(data);
    if (downloadableReport) {
      return (
        <Typography
          sx={{
            color: getDynamicColor("greenSuccess"),
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Ready to download, expires in {getHoursToDate(downloadableReport.expirationDate)} hour
          {getHoursToDate(downloadableReport.expirationDate) === 1 ? "" : "s"}
        </Typography>
      );
    } else if (noDataReport) {
      return (
        <Typography
          sx={{
            color: getDynamicColor("dark4"),
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          No data available on last report
        </Typography>
      );
    }
    return null;
  };

  const renderReportAccordion = (
    subType: ReportSubType,
    data: ListReportsResponse,
    isLoading: boolean
  ) => {
    if (isLoading) {
      return <Skeleton variant="rectangular" height={108} />;
    }

    const isExpanded = openAccordions.includes(subType);
    return (
      <Card
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          border: `1px solid ${getDynamicColor("dark3")}`,
          borderRadius: "8px",
          p: { xs: 2, md: 3 },
          fontFamily: "poppins",
          elevation: 0,
          boxShadow: "none",
        }}
      >
        <CardActions
          sx={{
            position: "absolute",
            right: { xs: 8, md: 16 },
            top: { xs: 8, md: 16 },
          }}
        >
          {!isSmallScreen && renderAccordionCta(subType, data)}
          <IconButton
            onClick={() => {
              if (isExpanded) {
                // remove from open accordions
                setOpenAccordions(openAccordions.filter((t) => t !== subType));
              } else {
                setOpenAccordions([...openAccordions, subType]);
              }
            }}
            sx={{
              height: { xs: 32, md: 40 },
              width: { xs: 32, md: 40 },
            }}
          >
            <ExpandMoreRoundedIcon
              sx={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease-out",
              }}
            />
          </IconButton>
        </CardActions>
        <Stack gap={{ xs: 0.5, md: 0 }}>
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={1}>
            <Stack
              alignItems="center"
              direction="row"
              sx={{
                minHeight: 40,
                pr: { xs: "40px", md: "200px" },
                width: "100%",
              }}
            >
              <Stack
                direction={{ xs: "column", md: "row" }}
                alignItems="baseline"
                columnGap={2}
                rowGap={{ xs: 1, md: 0 }}
                flexWrap="wrap"
              >
                <Stack direction="row" gap={1} alignItems="baseline">
                  <ReportIcon
                    style={{
                      display: "block",
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: getDynamicColor("purple3"),
                    }}
                  >
                    {ReportSubTypeCopyMap[subType].title}
                  </Typography>
                </Stack>
                {isSmallScreen && renderAccordionCta(subType, data)}
                {renderReportNotificationCopy(subType, data)}
              </Stack>
            </Stack>
          </Stack>
          {renderAccordionSubHeader(subType, data)}
        </Stack>

        <AnimateHeight height={isExpanded ? "auto" : 0}>
          <Stack
            gap={2}
            sx={{
              pt: 2,
              ...(data?.reports.length !== 0 && {
                mt: 2,
                borderTop: `1px solid ${getDynamicColor("dark2")}`,
              }),
            }}
          >
            {data?.reports.length !== 0 && (
              <Typography
                sx={{
                  color: getDynamicColor("dark5"),
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                {getDesc(subType)}
              </Typography>
            )}
            <Stack>
              <Typography sx={{ fontWeight: 600, fontSize: 12, color: getDynamicColor("dark5") }}>
                Includes the following data:
              </Typography>
              <List
                sx={{
                  color: getDynamicColor("purple3"),
                  listStyleType: "disc",
                  listStylePosition: "inside",
                  pl: 1,
                  py: 0,
                }}
              >
                {REPORT_FIELDS[subType].map((field) => (
                  <ListItem
                    key={field}
                    sx={{
                      display: "list-item",
                      fontSize: 12,
                      fontWeight: 400,
                      color: getDynamicColor("dark5"),
                      p: 0,
                      pt: 0.25,
                    }}
                  >
                    {field}
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Stack>
        </AnimateHeight>
      </Card>
    );
  };

  return (
    <Stack
      sx={{
        py: { xs: 4, md: 6 },
        px: { xs: 2, md: 6 },
        ...wrapperSx,
      }}
      gap={{ xs: 3, md: 4 }}
    >
      <Stack
        sx={{
          maxWidth: 650,
          fontFamily: "poppins",
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
          Reports for {type === ReportsTabType.Group ? hubName ?? "group" : "all members"}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: 12 }}>
          Reports are generated upon request and take a few moments to get ready. Data in reports
          may be up to 24 hours out of date, and reports will be available to download for 24 hours.{" "}
          <Link
            href={SUPPORT_ARTICLES.ORGANIZATION_REPORTS}
            target="_blank"
            sx={{
              fontWeight: 600,
            }}
          >
            Learn more about reports
          </Link>
        </Typography>
      </Stack>
      <Stack gap={3}>
        {renderReportAccordion(
          ReportSubType.FeatureUse,
          featureUseQuery?.data,
          featureUseQuery.isLoading
        )}
        {renderReportAccordion(
          ReportSubType.GoalImprovement,
          goalImprovementQuery?.data,
          goalImprovementQuery.isLoading
        )}
        {renderReportAccordion(
          ReportSubType.UserEngagement,
          userEngagementQuery?.data,
          userEngagementQuery.isLoading
        )}
      </Stack>
    </Stack>
  );
};
