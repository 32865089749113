import React from "react";

// Utils
import { VideoListingItem } from "../VideoJournalUtils";
import { SortStatus, TableSortBase } from "./TableSortBase";

export type SpeechTableRow = {
  // This type is comprised of fields and metadata
  // Each field is a table column, and is used for filtering/sorting (can be overridden though)
  // Each field must non-null because tan table will log a warning if it is null
  // Any sub-data of metaData can be null.
  thumbnail: string;
  title: string;
  created: string;
  sharedBy: string;
  isViewed: boolean;
  type: string;
  totalTime: number;
  fillerWordPercentage: number;
  pacing: number;
  metaData: {
    videoListingItem: VideoListingItem;
  };
};

export type TableSortServerProps = {
  columnId: string;
  setSortFilter?: (columnId: string) => void;
};

export const TableSortServer = ({ columnId, setSortFilter }: TableSortServerProps): JSX.Element => {
  const [sortCreated, setSortCreated] = React.useState<SortStatus>(SortStatus.NONE);
  const handleSortCreated = () => {
    if (sortCreated === SortStatus.NONE) {
      setSortCreated(SortStatus.ASC);
      setSortFilter(columnId);
    } else if (sortCreated === SortStatus.ASC) {
      setSortCreated(SortStatus.DESC);
      setSortFilter("-" + columnId);
    } else {
      setSortCreated(SortStatus.NONE);
      setSortFilter(undefined);
    }
  };

  return (
    <TableSortBase
      sortCreated={sortCreated}
      setSortCreated={setSortCreated}
      handleSortCreated={handleSortCreated}
    />
  );
};
