import { isMobile } from "react-device-detect";

// Components
import { Color, PaletteColor, SxProps } from "@mui/material";
import { grey as muiGrey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// Utils
import { Y_SHADOWS, getDynamicColor } from "../utils/Colors";

export const ADMIN_HEADER_HEIGHT = 80;

export type BreakpointStyles = {
  xs?: number;
  sm?: number;
  sm2?: number;
  md?: number;
  md2?: number;
  md3?: number;
  lg?: number;
  lg2?: number;
  xl?: number;
  xxl?: number;
  xxxl?: number;
};

export const WIGGLE_ANIMATION = {
  animation: "wiggle 1s ease-in-out infinite",
  "@keyframes wiggle": {
    "0%": { transform: "rotate(4deg)" },
    "25%": { transform: "rotate(-4deg)" },
    "50%": { transform: "rotate(8deg)" },
    "75%": { transform: "rotate(-2deg)" },
    "100%": { transform: "rotate(0deg)" },
  },
};

export const CONTINUOUS_WIGGLE = {
  animation: "wiggle 0.5s linear infinite",
  "@keyframes wiggle": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "25%": {
      transform: "rotate(-5deg)",
    },
    "50%": {
      transform: "rotate(0deg)",
    },
    "75%": {
      transform: "rotate(5deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
};

export const DEFAULT_MUI_DRAWER_TRANSITION = "300ms cubic-bezier(0, 0, 0.2, 1) 0ms";
/**
 * MUI theme palette mapping
 */
const MUI_PALETTE: Record<string, PaletteColor | Color> = {
  primary: {
    main: getDynamicColor("primary"),
    dark: getDynamicColor("purple3"),
    light: getDynamicColor("purple1"),
    contrastText: getDynamicColor("light1"),
  },
  secondary: {
    main: getDynamicColor("secondary"),
    light: getDynamicColor("secondary"),
    dark: getDynamicColor("secondaryHover"),
    contrastText: getDynamicColor("light1"),
  },
  success: {
    main: getDynamicColor("greenSuccess"),
    dark: getDynamicColor("greenSuccess"),
    light: getDynamicColor("greenSuccess"),
    contrastText: getDynamicColor("light1"),
  },
  error: {
    main: getDynamicColor("redError"),
    dark: getDynamicColor("redError"),
    light: getDynamicColor("redError"),
    contrastText: getDynamicColor("light1"),
  },
  info: {
    main: getDynamicColor("dark5"),
    dark: getDynamicColor("dark6"),
    light: getDynamicColor("dark4"),
    contrastText: "black",
  },
  grey: {
    ...muiGrey,
    600: getDynamicColor("dark6"),
    500: getDynamicColor("dark5"),
    400: getDynamicColor("dark4"),
    300: getDynamicColor("dark3"),
    200: getDynamicColor("dark2"),
    100: getDynamicColor("dark1"),
    50: getDynamicColor("light1"),
  },
};

// View the default MUI theme here https://mui.com/customization/default-theme/#main-content
// to see what values are available to change and what their default values are
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    sm2: true;
    md: true;
    md2: true;
    md3: true;
    lg: true;
    lg2: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    poppins: true;
  }
}

// Update the Button's variant prop options
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    text: true;
    text_small: true;
    custom: true;
    contained: true;
    contained_dark: true;
    contained_small: true;
    contained_small_dark: true;
    outlined: true;
    outlined_small: true;
    gradient: true;
    gradient_small: true;
  }
  interface ButtonPropsSizeOverrides {
    xlarge: true;
  }
}

export const defaultHeaderSx: SxProps = {
  position: "relative",
  zIndex: 20,
  minHeight: ADMIN_HEADER_HEIGHT,
  background: getDynamicColor("purple3"),
};

const defaultButtonStyles = {
  boxShadow: "none",
  width: "fit-content",
  minWidth: "unset",
  borderRadius: "100px",
  fontWeight: 700,
  lineHeight: 1.3,
  padding: "0.5em 1em",
  transition: "all 0.2s ease-in-out",
  fontFamily:
    '"poppins", "Inter", "Helvetica Neue", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontSize: 16,
  "&:hover": {
    filter: "none",
  },
};

const customButtonStyles = {
  ...defaultButtonStyles,
  padding: "unset",
  margin: "unset",
  fontWeight: "initial",
  lineHeight: "initial",
  borderRadius: 0,
  width: "unset",
};

const textButtonStyles = {
  ...defaultButtonStyles,
  color: getDynamicColor("primary"),
  backgroundColor: "transparent",
  "&:hover": {
    ...defaultButtonStyles["&:hover"],
    color: getDynamicColor("primaryHover"),
    backgroundColor: "transparent",
  },
  "&:disabled": {
    backgroundColor: "transparent",
    color: getDynamicColor("dark3"),
  },
};

const containedButtonStyles = {
  ...defaultButtonStyles,
  color: getDynamicColor("light1"),
  backgroundColor: getDynamicColor("primary"),
  "&:hover": {
    ...defaultButtonStyles["&:hover"],
    backgroundColor: getDynamicColor("primaryHover"),
  },
};

const outlinedButtonStyles = {
  ...defaultButtonStyles,
  backgroundColor: "transparent",
  border: `2px solid ${getDynamicColor("primary")}`,
  color: getDynamicColor("primary"),
  borderWidth: "2px",
  "&:hover": {
    ...defaultButtonStyles["&:hover"],
    borderWidth: "2px",
    backgroundColor: getDynamicColor("light2"),
  },
  "&:disabled": {
    borderWidth: 2,
  },
};

const gradientButtonStyles = {
  ...defaultButtonStyles,
  ...containedButtonStyles,
  background: getDynamicColor("gradient.default"),
  zIndex: 1,
  "&::before": {
    borderRadius: "inherit",
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    fontSize: "inherit",
    width: "100%",
    height: "100%",
    background: `linear-gradient(
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0.25)
    ), ${getDynamicColor("gradient.default")}`,
    opacity: 0,
    zIndex: -1,
    transition: "all 0.2s ease-in-out",
  },
  "&:hover::before": {
    opacity: 1,
  },
  "&:disabled": {
    background: getDynamicColor("dark2"),
    color: getDynamicColor("dark4"),
  },
};

const smallButtonStyles = {
  ...defaultButtonStyles,
  padding: "4px 8px",
  fontWeight: 600,
};

const darkButtonStyles = {
  backgroundColor: getDynamicColor("purple3"),
  "&:hover": {
    backgroundColor: getDynamicColor("purple3"),
    filter: "brightness(1.75)",
  },
};

const theme = createTheme({
  typography: {
    fontFamily: `"Inter", sans-serif, "Helvetica Neue", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  palette: {
    ...MUI_PALETTE,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      sm2: 640,
      md: 768,
      md2: 850,
      md3: 920,
      lg: 992,
      lg2: 1024,
      xl: 1200,
      xxl: 1500,
      xxxl: 2000,
    },
  },
  components: {
    MuiCssBaseline: {
      // reset h1-h6 styles
      styleOverrides: `
        h1, h2, h3, h4, h5, h6 {
          font-size: unset;
          font-weight: unset;
        }
        .seo {
          font-weight: inherit
        },
        .tiptap {
          padding: 10px 20px;
          overflow-y: auto;
          height: 100%;
          border-radius: 8px;
          
          h1,
          h2,
          h3,
          h4 {
            line-height: 1.1;
            font-weight: revert;
            font-size: revert;
          }

          p.is-editor-empty:first-of-type::before {
            color: ${getDynamicColor("dark4")};
            content: attr(data-placeholder);
            pointer-events: none;
            height: 0;
            float: left;
          }
        }
      `,
    },
    MuiButton: {
      variants: [
        {
          props: { size: "xlarge" },
          style: {
            padding: "12px 24px !important",
          },
        },
        {
          props: { variant: "text", disableRipple: true },
          style: {
            ...textButtonStyles,
          },
        },
        {
          props: { variant: "text_small" },
          style: {
            ...textButtonStyles,
            ...smallButtonStyles,
          },
        },
        {
          props: { variant: "custom" },
          style: {
            ...customButtonStyles,
          },
        },
        {
          props: { variant: "contained" },
          style: {
            ...containedButtonStyles,
          },
        },
        {
          props: { variant: "contained_dark" },
          style: {
            ...containedButtonStyles,
            ...darkButtonStyles,
          },
        },
        {
          props: { variant: "contained_small_dark" },
          style: {
            ...containedButtonStyles,
            ...smallButtonStyles,
            ...darkButtonStyles,
          },
        },
        {
          props: { variant: "contained_small" },
          style: {
            ...containedButtonStyles,
            ...smallButtonStyles,
          },
        },
        {
          props: { variant: "gradient" },
          style: {
            ...gradientButtonStyles,
          },
        },
        {
          props: { variant: "gradient_small" },
          style: {
            ...gradientButtonStyles,
            ...smallButtonStyles,
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            ...outlinedButtonStyles,
          },
        },
        {
          props: { variant: "outlined_small" },
          style: {
            ...outlinedButtonStyles,
            ...smallButtonStyles,
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          ...defaultButtonStyles,
          textTransform: "none",
          ...(isMobile
            ? {
                "&:hover::before": {
                  all: "unset",
                },
              }
            : {}),
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          fieldset: {
            borderRadius: "4px",
          },
          "& ::placeholder": {
            fontSize: "inherit",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "poppins" },
          style: {
            fontFamily: `"Poppins", sans-serif, "Helvetica Neue", "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol"`,
          },
        },
      ],
      styleOverrides: {
        root: {
          letterSpacing: "0.17px",
          fontSize: "inherit",
          fontFamily: "inherit",
          fontWeight: "inherit",
          color: "inherit",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: Y_SHADOWS.box_shadow_1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
