// Components
import { Box, Stack, Typography } from "@mui/material";

// Assets
import { ReactComponent as OpenYoodliDownloadArrow } from "images/icons/open-yoodli-arrow.svg";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

const OpenYoodliNotifier = (props: {
  color?: string;
  top?: number;
  right?: number;
}): JSX.Element => {
  return (
    <Stack
      direction="row"
      sx={{
        transition: "opacity 0.5s ease-in-out",
        position: "absolute",
        top: props.top ?? 18,
        right: props.right ?? 128,
        alignItems: "end",
        zIndex: 1,
      }}
    >
      <Typography
        variant="poppins"
        sx={{
          fontWeight: 600,
          fontFamily: "poppins",
          fontSize: 20,
          color: props.color ?? getDynamicColor("purple3"),
          whiteSpace: "nowrap",
          mb: "9px",
        }}
      >
        Open Yoodli!
      </Typography>
      <Box
        component="span"
        sx={{
          svg: {
            fill: props.color ?? getDynamicColor("purple3"),
          },
          height: 90,
          width: 90,
          rotate: "180deg",
          animation: "growTopRight 3s ease-in-out infinite",
          "@keyframes growTopRight": {
            "0%": {
              transform: "scale(0.6) translate(0, 0)",
            },
            "50%": {
              transform: "scale(1) translate(-24px, 18px)",
            },
            "100%": {
              transform: "scale(0.6) translate(0, 0)",
            },
          },
        }}
      >
        <OpenYoodliDownloadArrow />
      </Box>
    </Stack>
  );
};

export default OpenYoodliNotifier;
