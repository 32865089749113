import { useState, useRef, useEffect } from "react";

export type ObserveElementDimensions<T extends HTMLElement> = {
  width: number;
  height: number;
  ref: React.RefObject<T>;
};

export const useObserveElementDimensions = <T extends HTMLElement>(
  refToObserve?: React.RefObject<T>
): ObserveElementDimensions<T> => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const observedElementRef = refToObserve ?? useRef(null);
  const resizingDelayTimer = useRef(null);
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      clearTimeout(resizingDelayTimer.current);
      resizingDelayTimer.current = setTimeout(() => {
        // check if the observed div is still mounted
        // else this will cause memory leak
        if (observedElementRef.current) {
          setWidth(entries[0].contentRect.width);
          setHeight(entries[0].contentRect.height);
        }
      }, 50);
    });

    if (observedElementRef.current) {
      observer.observe(observedElementRef.current);
    }

    return () => {
      observedElementRef.current && observer.unobserve(observedElementRef.current);
    };
  }, []);

  return {
    width,
    height,
    ref: observedElementRef,
  };
};
