import React from "react";
import { useLocation } from "react-router";

// Components
import { PracticeType, PracticeTypes } from "components/PracticeRecorder/PracticeRecorderTypes";

// Utils
import { currentUserFirstName } from "lib-frontend/utils/AccountUtils";
import { getLiveUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";

export const usePracticeType = (): PracticeType => {
  const location = useLocation();
  const userDocMain = getLiveUserDocMain();
  // determine if isFTUX based on # of speeches etc.
  const isFTUX =
    userDocMain.nka_upload === 0 &&
    userDocMain.nka_record === 0 &&
    userDocMain.nka_zoodli_record === 0 &&
    userDocMain.nka_poodli_record === 0 &&
    !isWhiteLabel();
  const type = PracticeTypes.find((x) => location.pathname === x.path);
  const title = type?.instructions?.title[isFTUX ? "ftux" : "default"];
  // map displayName to title
  return React.useMemo(
    () => ({
      ...type,
      instructions: {
        ...type?.instructions,
        title: {
          ftux: title.replace(/{(.*?)}/, currentUserFirstName()),
          default: title.replace(/{(.*?)}/, currentUserFirstName()),
        },
      },
    }),
    [type, title, location]
  );
};
