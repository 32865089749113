// Components
import { Stack, Box, Button, SxProps } from "@mui/material";

// Utils
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsScreenSmallerThanPx } from "lib-frontend/utils/themeUtils";

type YoodliTabSelectorProps = {
  tabs: {
    label: string;
    value: string;
    count?: number;
  }[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  containerSx?: SxProps;
};

export const YoodliTabSelector = ({
  tabs,
  activeTab,
  setActiveTab,
  containerSx,
}: YoodliTabSelectorProps): JSX.Element => {
  const isExtraSmallScreen = useIsScreenSmallerThanPx(375);
  return (
    <Stack
      direction="row"
      sx={{
        boxShadow: Y_SHADOWS.dark_elevation,
        borderRadius: "4px",
        overflow: "hidden",
        position: "relative",
        width: "min(570px, 100%)",
        mx: { xs: 0, sm: "auto", md: 0 },
        mt: { xs: 2, md: 0 },
        ...containerSx,
      }}
    >
      <Box
        sx={{
          width: `min(${100 / tabs.length}%, 190px)`,
          height: "100%",
          boxShadow: Y_SHADOWS.box_shadow_1,
          backgroundColor: getDynamicColor("light2"),
          borderRadius: "4px",
          position: "absolute",
          left: tabs.findIndex((tab) => tab.value === activeTab) * (100 / tabs.length) + "%",
          transition: "left 0.5s cubic-bezier(.47,1.64,.41,.8)",
        }}
      />
      {tabs.map((tab) => {
        return (
          <Button
            key={tab.value}
            onClick={() => setActiveTab(tab.value)}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            endIcon={
              tab.count !== undefined && !isExtraSmallScreen ? (
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: "100px",
                    background: getDynamicColor(activeTab === tab.value ? "primary" : "dark4"),
                    color: getDynamicColor("light1"),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "10px !important",
                    fontWeight: 500,
                  }}
                >
                  {tab.count}
                </Box>
              ) : undefined
            }
            sx={{
              backgroundColor: "transparent",
              borderRadius: "4px",
              width: 190,
              minHeight: 48,
              py: { xs: 1, sm: 2 },
              fontWeight: activeTab === tab.value ? 700 : 500,
              fontSize: 14,
              lineHeight: 1.2,
              color: getDynamicColor(activeTab === tab.value ? "primary" : "purple3"),
              transition: "color 0.3s, font-weight 0s",
              "&:hover": {
                color: getDynamicColor("primary"),
                backgroundColor: "transparent",
              },
            }}
          >
            {tab.label}
          </Button>
        );
      })}
    </Stack>
  );
};
