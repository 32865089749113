import React from "react";

// Components
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@mui/material";

// Assets
import { ReactComponent as SendMoneyGraphic } from "images/graphics/graphic-referafriend.svg";
import { ReactComponent as CollectCoinGraphic } from "images/graphics/graphic-referanent.svg";

// Utils
import { sendJoinAffiliateProgramEmail } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";

const VARUN_LI = "https://linkedin.com/in/varun-puri001";
const YOODLI = "https://yoodli.ai";
const ELON_SPEECH = "https://app.yoodli.ai/share/gfYhf3qU/elon-musk-commencement-address";
const EXPLAINER_VIDEO = "https://www.youtube.com/watch?v=kPg47lTlyhg";

const HTML_EMAIL = `I am excited to introduce you to <a href="${VARUN_LI}">Varun Puri</a>, founder at <a href="${YOODLI}">Yoodli AI communication coach</a> (think Grammarly for speech). For context, check out this <a href="${ELON_SPEECH}">Elon Musk speech</a> run through their system and a <a href="${EXPLAINER_VIDEO}">quick explainer video.</a>
<br /><br />
Yoodli is being rolled out by organizations such as Korn Ferry, SSI, Toastmasters, Indeed, and Coursera as part of their coaching offerings, and by internal Google L&D teams as part of their employee upskilling tech. Varun would love to partner with you on a bespoke version of Yoodli for your learners. I will let Varun follow up. Thank you!`;

const TEXT_EMAIL = `I am excited to introduce you to Varun Puri, founder at Yoodli AI communication coach (think Grammarly for speech). For context, check out this Elon Musk speech run through their system and a quick explainer video: ${EXPLAINER_VIDEO}

Yoodli is being rolled out by organizations such as Korn Ferry, SSI, Toastmasters, Indeed, and Coursera as part of their coaching offerings, and by internal Google L&D teams as part of their employee upskilling tech. Varun would love to partner with you on a bespoke version of Yoodli for your learners. I will let Varun follow up. Thank you!`;

export const AffiliateProgram = (): JSX.Element => {
  const [requestCodeClicked, setRequestCodeClicked] = React.useState<boolean>(false);
  const [copyLinkClicked, setCopyLinkClicked] = React.useState<boolean>(false);

  const sendAffiliateCodeEmail = async () => {
    const successful = await sendJoinAffiliateProgramEmail();
    if (successful) {
      await updateThisUserDocMain({ requestedAffiliateCodeTimestamp: new Date().toISOString() });
    }
    return successful;
  };

  const showRequestedCodeButton = () => {
    setRequestCodeClicked(true);
    sendAffiliateCodeEmail().catch((err) => {
      console.error(`Error sending affiliate code email: ${err}`);
    });
  };

  const showLinkCopiedButton = () => {
    setCopyLinkClicked(true);
    setTimeout(() => {
      setCopyLinkClicked(false);
    }, 3000);
  };

  const copyLink = () => {
    try {
      const type = "text/html";
      const blob = new Blob([HTML_EMAIL], { type });
      const data = [new ClipboardItem({ [type]: blob })];

      navigator.clipboard.write(data).catch((err) => {
        console.error(`Error copying to clipboard: ${err}`);
      });
      showLinkCopiedButton();
    } catch (err) {
      // Firefox does not allow copying HTML to clipboard: https://caniuse.com/?search=clipboard.write, so we fallback to text.
      if (err.name === "ReferenceError" && err.message === "ClipboardItem is not defined") {
        copyToMyClipboard(TEXT_EMAIL);
        showLinkCopiedButton();
      } else {
        console.error(`Error copying to clipboard: ${err}`);
      }
    }
  };

  const renderCheckItem = (text: string) => (
    <Stack direction={"row"} gap={0.5} mt={1}>
      <Box
        sx={{
          svg: {
            display: "block",
          },
        }}
      >
        <CheckRoundedIcon sx={{ color: getDynamicColor("purple3"), height: 20, width: 20 }} />
      </Box>
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontSize: 14,
          fontFamily: "poppins",
          fontWeight: 500,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );

  const renderLink = (text: string, href: string) => (
    <a style={{ textDecoration: "underline", color: getDynamicColor("purple3") }} href={href}>
      {text}
    </a>
  );

  const renderGraphic = (graphic: JSX.Element, caption: string) => {
    return (
      <Box
        sx={{
          borderRadius: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 135,
          mx: "auto",
        }}
      >
        <Box
          sx={{
            width: 110,
            height: 110,
            svg: {
              position: "relative",
              top: `6px`,
            },
          }}
        >
          {graphic}
        </Box>
        <Typography
          sx={{
            color: getDynamicColor("greenSuccess"),
            backgroundColor: getDynamicColor("light1"),
            borderRadius: "50px",
            border: `1px solid ${getDynamicColor("dark3")}`,
            fontSize: "12px",
            textAlign: "center",
            fontWeight: 700,
            textTransform: "uppercase",
            lineHeight: 1.3,
            position: "relative",
            bottom: 6,
            py: 0.5,
            px: 3,
          }}
        >
          {caption}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        background: getDynamicColor("gradient.slantedLight"),
        justifyContent: "center",
        display: "flex",
        minHeight: "100dvh",
        py: 6,
        px: 4,
      }}
    >
      <Stack sx={{ maxWidth: 950, textAlign: "center" }} gap={3}>
        <Typography sx={{ fontSize: 24, fontFamily: "poppins", fontWeight: 700 }}>
          Yoodli affiliate program
        </Typography>
        <Typography>Get rewarded when you refer people to Yoodli</Typography>
        <Box
          sx={{
            backgroundColor: getDynamicColor("light1"),
            textAlign: "start",
            p: 4,
            display: "flex",
            flexDirection: "row",
            gap: { xs: 3, lg: 6 },
            borderRadius: "12px",
            boxShadow: Y_SHADOWS.box_shadow_1,
            flexWrap: "wrap",
          }}
        >
          {renderGraphic(<SendMoneyGraphic />, "GET $20")}
          <Stack sx={{ textAlign: "start", maxWidth: 500 }} gap={1}>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              Refer a friend
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: 14,
                fontFamily: "poppins",
                fontWeight: 500,
              }}
            >
              We'll send you a special code - for anyone who redeems your code on the annual plan
              we'll send you $20 (and give them a 20% discount)
            </Typography>
            {requestCodeClicked || getLiveUserDocMain()?.requestedAffiliateCodeTimestamp ? (
              <Button
                variant="contained"
                endIcon={<CheckRoundedIcon />}
                disableRipple={true}
                sx={{
                  mt: 2,
                  ml: 2,
                  fontSize: 14,
                  backgroundColor: getDynamicColor("greenSuccessLight"),
                  color: getDynamicColor("greenSuccess"),
                  cursor: "default",
                  "&:hover": {
                    backgroundColor: getDynamicColor("greenSuccessLight"),
                    color: getDynamicColor("greenSuccess"),
                  },
                }}
              >
                Code requested - check your email!
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ mt: 2, fontSize: 14 }}
                onClick={showRequestedCodeButton}
              >
                Request an affiliate code
              </Button>
            )}
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: getDynamicColor("light1"),
            textAlign: "start",
            p: 4,
            display: "flex",
            flexDirection: "row",
            gap: { xs: 3, lg: 6 },
            borderRadius: "12px",
            boxShadow: Y_SHADOWS.box_shadow_1,
            flexWrap: "wrap",
          }}
        >
          {renderGraphic(<CollectCoinGraphic />, "Collect a finder's fee")}
          <Stack sx={{ textAlign: "start", maxWidth: 500 }} gap={1}>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              Refer an enterprise
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: 14,
                fontFamily: "poppins",
                fontWeight: 500,
                mb: 1,
              }}
            >
              For any enterprise client you introduce us to, we give you 10% of the first year fee
              as a finders fee. Enterprises can trust that:
            </Typography>
            {renderCheckItem("Recordings are private by default")}
            {renderCheckItem("Recording data is automatically excluded from Yoodli’s AI training")}
            {renderCheckItem(
              "Recording audio, video, transcription data can be set to automatically deleted"
            )}

            <Divider sx={{ color: getDynamicColor("dark3"), mt: 3, mb: 1 }} />

            <Accordion
              disableGutters
              sx={{
                boxShadow: 0,
                "&.MuiAccordion-root:before": { display: "none" },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontSize: 14,
                    fontFamily: "poppins",
                    fontWeight: 500,
                  }}
                >
                  Need a <a style={{ color: getDynamicColor("primary") }}>blurb to forward?</a> Copy
                  below, and be sure to cc varunpuri@yoodli.ai. Thanks!
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ border: 0, pt: 3, pl: 2 }}>
                <Typography
                  sx={{ fontWeight: 300, fontFamily: "poppins", fontSize: 14, fontStyle: "italic" }}
                >
                  I am excited to introduce you to{" "}
                  {renderLink("Varun Puri", "https://linkedin.com/in/varun-puri001")}, founder at{" "}
                  {renderLink("Yoodli AI communication coach", "https://yoodli.ai")} (think
                  Grammarly for speech). For context, check out this{" "}
                  {renderLink(
                    "Elon Musk speech",
                    "https://app.yoodli.ai/share/gfYhf3qU/elon-musk-commencement-address"
                  )}{" "}
                  run through their system and a{" "}
                  {renderLink(
                    "quick explainer video.",
                    "https://www.youtube.com/watch?v=kPg47lTlyhg"
                  )}
                  <br />
                  <br />
                  Yoodli is being rolled out by organizations such as Korn Ferry, SSI, Toastmasters,
                  Indeed, and Coursera as part of their coaching offerings, and by internal Google
                  L&D teams as part of their employee upskilling tech. Varun would love to partner
                  with you on a bespoke version of Yoodli for your learners. I will let Varun follow
                  up. Thank you!
                </Typography>
              </AccordionDetails>
            </Accordion>
            {copyLinkClicked ? (
              <Button
                variant="contained"
                endIcon={<CheckRoundedIcon />}
                disableRipple={true}
                sx={{
                  mt: 2,
                  ml: 2,
                  fontSize: 14,
                  backgroundColor: getDynamicColor("greenSuccessLight"),
                  color: getDynamicColor("greenSuccess"),
                  cursor: "default",
                  "&:hover": {
                    backgroundColor: getDynamicColor("greenSuccessLight"),
                    color: getDynamicColor("greenSuccess"),
                  },
                }}
              >
                Copied to clipboard
              </Button>
            ) : (
              <Button variant="contained" sx={{ mt: 2, ml: 2, fontSize: 14 }} onClick={copyLink}>
                Copy the email blurb
              </Button>
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
