import React from "react";

// Components
import { Divider, Stack, TablePagination, Typography } from "@mui/material";
import {
  CustomizePracticeQueryKey,
  binaryGoalBase,
  compoundGoalBase,
  ratedGoalBase,
} from "components/ConvoScenarios/convoScenarioUtils";

// Utils
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { deleteCustomGoal, getAllCustomGoals } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CustomGoalCard } from "./CustomGoalCard";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { GoalKind } from "lib-fullstack/db";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";
import { CustomGoalItemWithUsage, CustomGoalResponse } from "lib-fullstack/api/scenarioApiTypes";
import { CustomGoalWizard } from "./CustomGoalWizard";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  CustomGoalFilterOption,
  ScenarioSortOption,
  SortFilterMenu,
  SortFilterType,
  SortOption,
} from "components/Builder/SortFilterMenu";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";

type CustomGoalLibraryProps = {
  handleCustomGoalIsSelected: (selected: boolean) => void;
  showBackHeader: boolean;
};

export const renderCreateGoalMenuItem = (goalKind: GoalKind): JSX.Element => {
  const goalMenuItem = {
    [GoalKind.BinaryGoal]: {
      title: "Create binary goal",
      subtitle: "Create a goal that can be either achieved or failed",
    },
    [GoalKind.ScoreGoal]: {
      title: "Create rated goal",
      subtitle: "Create a goal that is rated on a scale",
    },
    [GoalKind.CompoundGoal]: {
      title: "Create compound goal",
      subtitle: "Create a goal made up of multiple sub-goals",
    },
  };
  return (
    <Stack
      direction="column"
      sx={{
        px: 0.5,
        color: getDynamicColor("purple3"),
        fontFamily: "Poppins",
        textWrap: "wrap",
      }}
    >
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>{goalMenuItem[goalKind].title}</Typography>
      <Typography sx={{ fontWeight: 400, fontSize: 12 }}>
        {goalMenuItem[goalKind].subtitle}
      </Typography>
    </Stack>
  );
};

export const CustomGoalLibrary = ({
  handleCustomGoalIsSelected,
  showBackHeader,
}: CustomGoalLibraryProps): JSX.Element => {
  const { defaultOrg } = React.useContext(UserOrgContext);

  const [searchText, setSearchText] = React.useState<string>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedGoal, setSelectedGoal] = React.useState<CustomGoalResponse>(undefined);

  const [goalToDelete, setGoalToDelete] = React.useState<CustomGoalItemWithUsage>(undefined);
  const [goalKindFilter, setGoalKindFilter] = React.useState<SortOption>(undefined);
  const [sortBy, setSortBy] = React.useState<SortOption>({
    value: ScenarioSortOption.Name,
    label: "Name",
  });
  const queryClient = useQueryClient();

  const { data: customGoals, isPending: customGoalsLoading } = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrg?.id],
    queryFn: () => getAllCustomGoals(defaultOrg?.id),
    enabled: !!defaultOrg?.id,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: handleCustomGoalDeletion, isPending: customGoalDeletionLoading } =
    useMutation({
      mutationFn: async (goalId: string) => {
        const deletedGoalId = await deleteCustomGoal(defaultOrg?.id, goalId);
        return deletedGoalId;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrg?.id],
        });
        Instrumentation.logOrgCustomGoalDeleted(defaultOrg?.id);
      },
      onError: (error) => {
        console.error("Error deleting custom goal: ", error);
      },
    });

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  React.useEffect(() => {
    handleCustomGoalIsSelected(!!selectedGoal);
  }, [selectedGoal]);

  React.useEffect(() => {
    if (!showBackHeader) {
      setSelectedGoal(undefined);
    }
  }, [showBackHeader]);

  React.useEffect(() => {
    setPage(0);
  }, [customGoals, searchText, rowsPerPage, sortBy, goalKindFilter]);

  const getGoalKind = (filterOpt: CustomGoalFilterOption): GoalKind => {
    switch (filterOpt) {
      case CustomGoalFilterOption.BinaryGoal:
        return GoalKind.BinaryGoal;
      case CustomGoalFilterOption.RatedGoal:
        return GoalKind.ScoreGoal;
      case CustomGoalFilterOption.CompoundGoal:
        return GoalKind.CompoundGoal;
      default:
        return undefined;
    }
  };

  const filteredGoals = React.useMemo(() => {
    const searchFiltered =
      searchText.length > 0
        ? customGoals?.goals?.filter((goal) => {
            return goal.name.toLowerCase().includes(searchText.toLowerCase());
          }) ?? []
        : customGoals?.goals ?? [];
    const goalKindFiltered = searchFiltered.filter(
      (goal) =>
        !goalKindFilter ||
        goal.goalKind === getGoalKind(goalKindFilter.label as CustomGoalFilterOption)
    );

    switch (sortBy.value) {
      case ScenarioSortOption.Name:
        goalKindFiltered.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case ScenarioSortOption.CreatedAt:
        goalKindFiltered.sort((a, b) => b.createdDate.localeCompare(a.createdDate));
        break;
      case ScenarioSortOption.UpdatedAt:
        goalKindFiltered.sort((a, b) => b.modifiedDate?.localeCompare(a.modifiedDate));
        break;
    }
    return goalKindFiltered;
  }, [customGoals, searchText, rowsPerPage, sortBy, goalKindFilter]);

  const paginatedGoals = React.useMemo(() => {
    return filteredGoals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [page, filteredGoals]);

  const renderCustomGoalList = () => {
    if (!customGoals?.goals?.length) {
      return (
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          You haven't created any custom goals yet. Choose a template to get started.
        </Typography>
      );
    }
    return (
      <Stack direction="column" gap={2}>
        {paginatedGoals.map((goal) => {
          return (
            <CustomGoalCard
              key={goal.id}
              goal={goal}
              setSelectedGoal={setSelectedGoal}
              setGoalToDelete={setGoalToDelete}
            />
          );
        })}
        <TablePagination
          component="div"
          sx={{ m: "auto", fontFamily: "poppins !important" }}
          count={filteredGoals.length ?? 0}
          rowsPerPageOptions={[5, 10, 25, 50]}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    );
  };

  if (selectedGoal && showBackHeader) {
    return (
      <CustomGoalWizard
        selectedGoal={selectedGoal}
        setSelectedGoal={setSelectedGoal}
        closeWizard={() => {
          setSelectedGoal(undefined);
        }}
      />
    );
  }

  return (
    <Stack
      sx={{
        fontFamily: "poppins",
        p: { xs: 3, md: 0 },
        pb: { xs: 3, md: 1 },
        maxWidth: "xxl",
      }}
      gap={6}
    >
      <Stack direction="row" sx={{ justifyContent: "space-between", flexWrap: "wrap", rowGap: 1 }}>
        <Stack direction="column" sx={{ justifyContent: "flex-start" }}>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontSize: { xs: 16, md: 18 },
              fontWeight: 700,
            }}
          >
            Create new custom goal
          </Typography>
          <Typography
            sx={{
              color: getDynamicColor("dark5"),
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Create custom goals that can be assigned to roleplay scenarios.
          </Typography>
        </Stack>
        <YoodliMenu
          type={YoodliMenuButtonType.Text}
          buttonText="Create a custom goal"
          showDropdownArrow
          buttonProps={{ variant: "contained" }}
          fitWidth
          menuContainerSx={{
            mt: 0,
          }}
          menuItems={[
            {
              title: renderCreateGoalMenuItem(GoalKind.BinaryGoal),
              onClick: () => {
                setSelectedGoal(binaryGoalBase as CustomGoalResponse);
              },
              type: YoodliMenuItemType.Default,
            },
            {
              title: renderCreateGoalMenuItem(GoalKind.ScoreGoal),
              onClick: () => {
                setSelectedGoal(ratedGoalBase as CustomGoalResponse);
              },
              type: YoodliMenuItemType.Default,
            },
            {
              title: renderCreateGoalMenuItem(GoalKind.CompoundGoal),
              onClick: () => {
                setSelectedGoal(compoundGoalBase as CustomGoalResponse);
              },
              type: YoodliMenuItemType.Default,
            },
          ]}
        />
      </Stack>
      <Stack direction="column" justifyContent="flex-start" gap={2}>
        <Stack
          direction="row"
          sx={{ gap: 2, justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}
        >
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontSize: { xs: 16, md: 18 },
              fontWeight: 700,
            }}
          >
            My custom goals
          </Typography>
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              gap: { xs: 0, md: "20px" },
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            <SortFilterMenu
              sortFilterType={SortFilterType.Filter}
              sortBy={goalKindFilter}
              setSortBy={setGoalKindFilter}
              sortOptions={[
                ...Object.keys(CustomGoalFilterOption).map((key: CustomGoalFilterOption) => ({
                  label: CustomGoalFilterOption[key],
                  value: key,
                })),
              ]}
            />
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: getDynamicColor("dark4"),
                height: "20px",
                my: "auto",
              }}
            />
            <SortFilterMenu
              sortFilterType={SortFilterType.Sort}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortOptions={[
                {
                  value: ScenarioSortOption.Name,
                  label: "Name",
                },
                {
                  value: ScenarioSortOption.CreatedAt,
                  label: "Created At",
                },
                {
                  value: ScenarioSortOption.UpdatedAt,
                  label: "Updated At",
                },
              ]}
            />
            <YoodliSearchBar
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              clearSearch={() => setSearchText("")}
              placeholder="Search"
              InputProps={{
                sx: {
                  height: "40px",
                },
              }}
              sx={{
                minWidth: { xs: "125px", md: "200px" },
                maxWidth: { xs: "unset", sm: "300px" },
                height: "40px",
              }}
            />
          </Stack>
        </Stack>

        {!customGoalsLoading && renderCustomGoalList()}
      </Stack>
      <YoodliCtaModal
        ctaBody={{
          title: (
            <>
              Are you sure you want to delete{" "}
              <strong style={{ color: getDynamicColor("primary") }}>{goalToDelete?.name}</strong>?
            </>
          ),
          subtitle:
            "You will no longer be able to use this goal in any scenario. This action cannot be undone.",
        }}
        open={!!goalToDelete}
        theme={YoodliCtaModalTheme.Danger}
        loading={customGoalDeletionLoading}
        hideCloseButton={true}
        close={() => setGoalToDelete(undefined)}
        buttons={
          {
            primary: {
              text: "Delete goal",
              handler: async () => {
                await handleCustomGoalDeletion(goalToDelete?.id).catch((e) => console.error(e));
                setGoalToDelete(undefined);
              },
            },
            secondary: { text: "Cancel", handler: () => setGoalToDelete(undefined) },
          } as CtaButtonHandlers
        }
      />
    </Stack>
  );
};
