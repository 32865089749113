import { UseMutateAsyncFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { updateCoachBot } from "lib-frontend/modules/AxiosInstance";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { GetCoachBotResponse } from "lib-fullstack/api/hubApiTypes";
import { OrgCoachbotType } from "lib-fullstack/utils/productAnalyticEvents";

export const useUpdateCoachBot = (): {
  handleUpdateCoachBot: UseMutateAsyncFunction<
    void,
    Error,
    {
      orgId: string;
      botId: string;
      updateOptions: Partial<GetCoachBotResponse>;
      isDefault?: boolean;
    }
  >;
  handleUpdateCoachBotLoading: boolean;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: handleUpdateCoachBot, isPending: handleUpdateCoachBotLoading } = useMutation(
    {
      mutationFn: async ({
        orgId,
        botId,
        updateOptions,
      }: {
        orgId: string;
        botId: string;
        updateOptions: Partial<GetCoachBotResponse>;
        isDefault?: boolean;
      }) => {
        await updateCoachBot(orgId, botId, updateOptions);
      },
      onSuccess: async (_, params) => {
        Instrumentation.logOrgCoachbotUpdated(
          params.orgId,
          params.isDefault ? OrgCoachbotType.DEFAULT : OrgCoachbotType.SCENARIO
        );
        if (params.updateOptions.scenarioIds) {
          await queryClient.invalidateQueries({
            queryKey: [CustomizePracticeQueryKey.Scenarios, params.orgId],
          });
        }
        return queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.Coachbots, params.orgId],
        });
      },
      onError: (error) => {
        console.error("Error updating coachbot: ", error);
      },
    }
  );

  return { handleUpdateCoachBot, handleUpdateCoachBotLoading };
};
