// Components
import { Button, Stack, Typography } from "@mui/material";

// Utils
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { WebServerInternalPath } from "utils/paths";

export const CREATE_ORG_BANNER_DISMISSED = "createOrgBannerDismissed";

type CreateOrgBannerProps = {
  showDismissButton: boolean;
  setCreateOrgBannerDismissed?: (dismissed: boolean) => void;
};

export default function CreateOrgBanner({
  showDismissButton,
  setCreateOrgBannerDismissed,
}: CreateOrgBannerProps): JSX.Element {
  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      rowGap={3}
      columnGap={1}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: Y_SHADOWS.box_shadow_1,
        p: { xs: 2, lg: 3.5 },
      }}
    >
      <Stack gap={1}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 700,
          }}
        >
          Invite clients and track their progress in a single place
        </Typography>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          Try out Yoodli’s Organization feature for free
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: "column-reverse", lg: "row" }}
        rowGap={1}
        columnGap={5}
        sx={{ alignItems: "center", flexWrap: "wrap-reverse" }}
      >
        {showDismissButton && (
          <Button
            onClick={() => {
              localStorage.setItem(CREATE_ORG_BANNER_DISMISSED, "true");
              setCreateOrgBannerDismissed(true);
            }}
            sx={{ height: 40 }}
          >
            Dismiss
          </Button>
        )}
        <Button
          href={WebServerInternalPath.CREATE_ORG}
          variant="gradient"
          sx={{ height: 40, whiteSpace: "nowrap" }}
        >
          Create Your Organization
        </Button>
      </Stack>
    </Stack>
  );
}
