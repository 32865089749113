import { Box } from "@mui/material";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import React from "react";

type HubsListSpanProps = {
  hubsList: string[];
};

export const HubsListSpan = ({ hubsList }: HubsListSpanProps): JSX.Element => {
  return (
    <Box component="span">
      {hubsList.map((hubName, i) => {
        if (hubsList?.length > 1 && i === hubsList.length - 1) {
          return (
            <React.Fragment key={i}>
              and{" "}
              <Box component="span" key={i} sx={{ color: getDynamicColor("primary") }}>
                {hubName}
              </Box>
            </React.Fragment>
          );
        } else {
          return (
            <Box component="span" key={hubName} sx={{ color: getDynamicColor("primary") }}>
              {hubName}
              {hubsList?.length === 1 ? "" : i !== hubsList?.length - 2 ? ", " : " "}
            </Box>
          );
        }
      })}
    </Box>
  );
};
