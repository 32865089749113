// Shared configs

/**
 * Hide a DOM element's text data from being recorded in a Logrocket session.
 * https://docs.logrocket.com/reference/dom#disable-recording-on-elements-with-specific-attributes
 * @example
 * <div data-logrocket-hidden>Hidden text</div>
 */

function sanitizeLogrocketUrl(url: string) {
  let sanitizedUrl = url;

  // hide signed url signatures
  sanitizedUrl = sanitizedUrl.replace(/x-goog-signature=([^&]*)/gi, "x-goog-signature=redacted");
  sanitizedUrl = sanitizedUrl.replace(/upload_id=([^&]*)/gi, "upload_id=redacted");

  return sanitizedUrl;
}

export const logrocketDomConfig = {
  dom: {
    textSanitizer: true,
    inputSanitizer: true,
    privateAttributeBlocklist: ["data-logrocket-hidden"],
  },
};

export const logrocketBrowserConfig = {
  browser: {
    urlSanitizer: (url: string): string => {
      return sanitizeLogrocketUrl(url);
    },
  },
};

/**
 * Configure LogRocket network capture.
 */
export const logRocketNetworkCaptureConfig = {
  // Do not capture client IP address.
  // https://docs.logrocket.com/reference/react-native-disable-ip-capture
  shouldCaptureIP: false,

  // Do not capture request or response body.
  // https://docs.logrocket.com/reference/react-native-network
  network: {
    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    requestSanitizer: (request) => {
      request.headers = null;
      request.body = null;
      request.url = sanitizeLogrocketUrl(request.url);
      return request;
    },
    responseSanitizer: (response) => {
      response.body = null;
      return response;
    },
  },
};
