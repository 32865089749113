/** Interview Prompt Questions: https://docs.google.com/document/d/1Ho2mC6qmYZB-nlxZvr4YxEQhq0GseJ1IIxgN6zUMY2E/edit */
import { db } from "lib-fullstack";

export type CategoryInformation = {
  title: string;
  identifier: string;
  subcategories?: Record<string, CategoryInformation>;
  emoji?: string;
};

export enum InterviewFlow {
  DEFAULT,
  AI,
}

export const corePrompt1 = "Tell me about yourself.";

export const productSubcategories: Record<string, CategoryInformation> = {
  behavioral: {
    title: "Behavioral",
    identifier: "BEHAVIORAL",
  },
  product: {
    title: "Product",
    identifier: "PRODUCT",
  },
  productDesign: {
    title: "Product Design",
    identifier: "PRODUCT_DESIGN",
  },
  analytical: {
    title: "Analytical",
    identifier: "ANALYTICAL",
  },
  technical: {
    title: "Technical",
    identifier: "TECHNICAL",
  },
};

export const marketingSubcategories: Record<string, CategoryInformation> = {
  general: {
    title: "General",
    identifier: "GENERAL",
  },
  growth: {
    title: "Growth",
    identifier: "GROWTH",
  },
  productMarketing: {
    title: "Product Marketing",
    identifier: "PRODUCT_MARKETING",
  },
};

export const consultingSubcategories: Record<string, CategoryInformation> = {
  behavioral: {
    title: "Behavioral",
    identifier: "BEHAVIORAL",
  },
  marketSizing: {
    title: "Market Sizing",
    identifier: "MARKET_SIZING",
  },
  caseQuestions: {
    title: "Case Questions",
    identifier: "CASE_QUESTIONS",
  },
};

export const customSubcategories: Record<string, CategoryInformation> = {};

/**
 * Interview categories
 */
export const INTERVIEW_CATEGORIES: Record<string, CategoryInformation> = {
  GENERAL: {
    title: "General",
    identifier: "GENERAL",
  },
  PRODUCT_MANAGEMENT: {
    title: "PM",
    identifier: "PRODUCT",
    subcategories: productSubcategories,
  },
  MARKETING: {
    title: "Marketing",
    identifier: "MARKETING",
    subcategories: marketingSubcategories,
  },
  FINANCE: {
    title: "Finance",
    identifier: "FINANCE",
  },
  CONSULTING: {
    title: "Consulting",
    identifier: "CONSULTING",
    subcategories: consultingSubcategories,
  },
  CUSTOM: {
    title: "Custom",
    identifier: "CUSTOM",
    subcategories: customSubcategories,
  },
};

export type AICategoryInformation = CategoryInformation & {
  identifier: db.InterviewTopic;
};

export const AI_INTERVIEW_TOPICS: Record<db.InterviewTopic, AICategoryInformation> = {
  general: {
    title: "General",
    identifier: "general",
  },
  "product manager": {
    title: "Product Management",
    identifier: "product manager",
  },
  marketing: {
    title: "Marketing",
    identifier: "marketing",
  },
  finance: {
    title: "Finance",
    identifier: "finance",
  },
  consulting: {
    title: "Consulting",
    identifier: "consulting",
  },
  "software engineering": {
    title: "Software Engineering",
    identifier: "software engineering",
  },
};

/**
 * Interview personas
 */
export type PersonaInformation = CategoryInformation & {
  emoji: string;
  identifier: db.AIPersona;
};
export const INTERVIEW_STYLES: Record<string, PersonaInformation> = {
  FRIENDLY: {
    emoji: "😊",
    title: "Friendly & supportive",
    identifier: "friendly",
  },
  PROFESSIONAL: {
    emoji: "🧑‍💼",
    title: "Professional & formal",
    identifier: "professional",
  },
  TECHNICAL: {
    emoji: "🧑‍💻",
    title: "Technical expert",
    identifier: "technical",
  },
  BEHAVIORAL: {
    emoji: "✍️",
    title: "Behavioral specialist",
    identifier: "behavioral",
  },
  STRESS: {
    emoji: "😅",
    title: "Stress interviewer",
    identifier: "stress",
  },
};
