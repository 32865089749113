import * as React from "react";

// Components
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

// Use like a normal textbyox, entirely, but show the limited max length
// All other props should pass through properly
// This maxLength setting on the parent will override properly if one
// is set on the child. Don't do that.
type IBoundTextFieldProps = TextFieldProps & {
  maxLength: number;
  location: "inside" | "below";
};

/**
 * Renders a MuiTextField as entirely normal, but with the helper text (if there)
 *  left justified and the character count of the value & the max character count
 *  shown in bottom right.
 */
const BoundTextField = React.forwardRef<HTMLInputElement, IBoundTextFieldProps>((props, ref) => {
  const { maxLength, location, InputProps, onFocus, onBlur, inputProps, helperText, ...other } =
    props;

  // Shows the counter of characters left
  const [visible, setVisible] = React.useState(false);

  // declaring it outside the return should potentially minimize rerendering
  //  perf impact
  const characterCountAdornment = <span>{`${(props.value as string).length} / ${maxLength}`}</span>;

  return (
    <MuiTextField
      ref={ref}
      {...other}
      onFocus={(event) => {
        setVisible(true);
        onFocus && onFocus(event);
      }}
      onBlur={(event) => {
        setVisible(false);
        onBlur && onBlur(event);
      }}
      InputProps={{
        ...InputProps,
        endAdornment:
          location === "inside" ? (
            <InputAdornment position="end">{characterCountAdornment}</InputAdornment>
          ) : undefined,
      }}
      inputProps={{
        ...inputProps,
        maxLength: maxLength,
      }}
      helperText={
        location === "below" ? (
          <Box component="span" sx={{ display: "flex", justifyContent: "space-between" }}>
            <span>{helperText}</span>
            {visible && characterCountAdornment}
          </Box>
        ) : (
          helperText
        )
      }
    />
  );
});

export default BoundTextField;
