import React from "react";

// Components
import { Stack, SxProps, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

export const PAGE_CONTENT_HEADER_HEIGHT = 60;
export const PageContentTitle = ({
  title,
  piiTitle,
  isOutsideContent,
}: {
  title: string;
  piiTitle?: boolean;
  isOutsideContent?: boolean;
}): JSX.Element => {
  return (
    <Typography
      {...(piiTitle ? { "data-logrocket-hidden": true } : {})}
      component="h1"
      sx={{
        position: "relative",
        fontWeight: 600,
        fontSize: 20,
        color: getDynamicColor("purple3"),
        px: isOutsideContent ? { xs: 3, sm: 4 } : 0,
        pt: 3,
        fontFamily: "poppins",
        zIndex: 2,
        maxWidth: { xs: "100%", md: "calc(100% - 140px)" },
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        height: PAGE_CONTENT_HEADER_HEIGHT,
      }}
    >
      {title}
    </Typography>
  );
};

export default function PageContent({
  title,
  piiTitle,
  wrapperSx,
  children,
}: React.PropsWithChildren<{
  title?: string;
  piiTitle?: boolean;
  wrapperSx?: SxProps;
}>): JSX.Element {
  return (
    <Stack width="100%" sx={wrapperSx}>
      {title && <PageContentTitle title={title} piiTitle={piiTitle} isOutsideContent />}
      {children}
    </Stack>
  );
}
