import { isMobile } from "react-device-detect";

// Components
import { Button, Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { markTipInteracted, tipToContentMap } from "lib-frontend/utils/productTipsUtils";
import { ProductTip } from "lib-fullstack/db";
import { ProductTipEventWheres } from "lib-fullstack/utils/productAnalyticEvents";

type TipTextProps = {
  tip: ProductTip;
  isInteracted: boolean;
  width: string;
  pl: number;
  pr: number;
  onCtaClick: (tip: ProductTip) => void;
  idx: number;
};

export const TipText = ({
  tip,
  isInteracted,
  width,
  pl,
  pr,
  onCtaClick,
  idx,
}: TipTextProps): JSX.Element => {
  const onButtonClick = () => {
    onCtaClick(tip);
    markTipInteracted(tip);
    Instrumentation.logUsageTipCtaClicked(
      tipToContentMap[tip].header,
      idx + 1,
      tipToContentMap[tip].buttonText,
      tip,
      ProductTipEventWheres.WEBCLIENT_HOME
    );
  };

  return (
    <Stack
      sx={{
        width: width,
        backgroundColor: getDynamicColor("light1"),
        pl: pl,
        pr: pr,
        pt: { xs: 0, lg: 2 },
        pb: { xs: 2, lg: tipToContentMap[tip].buttonText ? 1 : 6 },
        my: "auto",
      }}
      gap={1.5}
    >
      <Typography
        sx={{
          textTransform: "uppercase",
          color: getDynamicColor("greenSuccess"),
          backgroundColor: getDynamicColor("greenSuccessLight"),
          width: "fit-content",
          px: 1.5,
          py: 0.5,
          fontSize: 12,
          fontWeight: 600,
          opacity: isInteracted ? 0 : 1,
          height: isInteracted ? 0 : 26,
          transition: `opacity 1s ease-in-out, height 1s ease-in-out`,
        }}
      >
        NEW
      </Typography>
      <Typography sx={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 15 }}>
        {tipToContentMap[tip].header}
      </Typography>
      <Typography sx={{ fontFamily: "Poppins", fontSize: 13 }}>
        {tipToContentMap[tip].body}
      </Typography>
      {/* Show button if there is one, and if the button opens Poodli, only show it on desktop. */}
      {tipToContentMap[tip].buttonText &&
        (!tipToContentMap[tip].buttonOpensPoodli || !isMobile) && (
          <Button variant="text" sx={{ mt: 0.5, ml: "-14px" }} onClick={onButtonClick}>
            {tipToContentMap[tip].buttonText}
          </Button>
        )}
    </Stack>
  );
};
