import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Components
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import {
  Stack,
  Typography,
  Divider,
  Box,
  Button,
  CircularProgress,
  Checkbox,
  Chip,
} from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Assets
import PlaceholderThumbnail from "images/graphics/video-placeholder.svg";

// Utils
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  CourseVideoResponse,
  DemoVideoResponse,
  UpdateCourseVideoRequest,
  UpdateDemoVideoRequest,
} from "lib-fullstack/api/hubApiTypes";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { ContentVideoState } from "lib-fullstack/utils/enums";
import { VIDEO_RATIO } from "utils/Constants";

const THUMBAIL_WIDTH_PX = 96;

type EditVideosModalProps = {
  videos: DemoVideoResponse[] | CourseVideoResponse[];
  closeModal: () => void;
  handleDeleteVideo: UseMutateAsyncFunction<void, Error, string>;
  handleUpdateVideo: UseMutateAsyncFunction<
    void,
    Error,
    { videoId: string; updates: UpdateDemoVideoRequest | UpdateCourseVideoRequest }
  >;
  handleUpdateVideos?: UseMutateAsyncFunction<void, Error, string[]>;
  allHubs: HubV2Response[];
  isUpload?: boolean;
  uploading?: boolean;
  clearUploadingVideos?: () => void;
  isCourse?: boolean;
};

export default function EditVideosModal({
  videos,
  closeModal,
  handleDeleteVideo,
  handleUpdateVideo,
  handleUpdateVideos,
  allHubs,
  isUpload,
  uploading,
  clearUploadingVideos,
  isCourse,
}: EditVideosModalProps): JSX.Element {
  // State
  const [inEditModeIndex, setInEditModeIndex] = React.useState<number>(-1);
  const [dragDisabled, setDragDisabled] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [availableHubs, setAvailableHubs] = React.useState<string[]>([]);

  // Constants
  const allHubsAvailable = availableHubs.length === allHubs.length;
  const disableSave = !title.length || (!availableHubs.length && !isCourse);
  const isDraggable = !!handleUpdateVideos; // if there is no function to update the videos (i.e. demo video) hide drag handle
  const [orderedVideos, setOrderedVideos] = React.useState<
    DemoVideoResponse[] | CourseVideoResponse[]
  >(videos);
  React.useEffect(() => {
    setOrderedVideos(videos);
  }, [videos]);

  const handleSetInEditModeIndex = (
    index: number,
    title?: string,
    description?: string,
    availableHubs?: string[]
  ) => {
    if (inEditModeIndex !== index) {
      setInEditModeIndex(index);
      setTitle(title ?? "");
      setDescription(description ?? "");
      setAvailableHubs(availableHubs ?? allHubs?.map((hub) => hub.id));
    }
  };

  const handleUploadComplete = async () => {
    setLoading(true);
    await Promise.all(
      videos.map(async (upload) =>
        handleUpdateVideo({ videoId: upload.id, updates: { state: ContentVideoState.UPLOADED } })
      )
    );
    clearUploadingVideos();
    closeModal();
    setLoading(false);
  };

  const deleteVideo = async (videoId: string) => {
    setLoading(true);
    await handleDeleteVideo(videoId);
    if (!videos.length) {
      closeModal();
    }
    setLoading(false);
  };

  const cancelUpload = async () => {
    setLoading(true);
    await Promise.all(videos.map(async (upload) => handleDeleteVideo(upload.id)));
    clearUploadingVideos();
    closeModal();
    setLoading(false);
  };

  const updateVideo = async (videoId: string) => {
    setLoading(true);
    handleSetInEditModeIndex(-1);
    await handleUpdateVideo({
      videoId,
      updates: {
        title,
        description: description ?? "",
        available_hubs: availableHubs,
      },
    });
    setLoading(false);
  };

  const handleSelectAll = () => {
    if (allHubsAvailable) {
      setAvailableHubs([]);
    } else {
      setAvailableHubs(allHubs.map((hub) => hub.id));
    }
  };

  const handleSetAvailableHubs = (hubId) => {
    const newAvailableHubs = [...availableHubs];
    if (newAvailableHubs.includes(hubId)) {
      const indexToRemove = newAvailableHubs.indexOf(hubId);
      newAvailableHubs.splice(indexToRemove, 1);
    } else {
      newAvailableHubs.push(hubId);
    }

    setAvailableHubs(newAvailableHubs);
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const oldVideos = [...videos] as DemoVideoResponse[] | CourseVideoResponse[];
    const newVideos = reorder(videos, result.source.index, result.destination.index) as
      | DemoVideoResponse[]
      | CourseVideoResponse[];

    // optimistically set the reordered videos, so when the user releases their mouse, they see the new list
    // but block dragging until the update call returns, and if it fails, re order them ot how they were before
    setOrderedVideos(newVideos);
    setDragDisabled(true);
    handleUpdateVideos(newVideos.map((x) => x.id))
      .catch((er) => {
        console.error("Failed to reorder the couse videos", er);
        setOrderedVideos(oldVideos);
      })
      .finally(() => {
        setDragDisabled(false);
      });
  };

  const renderVideo = (video, index, provided) => {
    return (
      <Stack
        sx={{
          backgroundColor: getDynamicColor("light1"),
          borderBottom: index !== videos.length - 1 && `1px solid ${getDynamicColor("dark2")}`,
          py: { xs: 2, sm: 3 },
          gap: 1.5,
        }}
      >
        <Stack direction="row" alignItems="start" width="100%" gap={1.5}>
          {uploading ? (
            <Box width="max-content">
              <CircularProgress
                size={20}
                sx={{
                  mr: 2,
                }}
              />
            </Box>
          ) : (
            <img
              src={video.thumbnail_url || PlaceholderThumbnail}
              width={THUMBAIL_WIDTH_PX}
              height={THUMBAIL_WIDTH_PX / VIDEO_RATIO}
              style={{
                borderRadius: "4px",
                border: `1px solid ${getDynamicColor("dark3")}`,
              }}
            />
          )}
          <Stack
            gap={1}
            sx={{
              flexGrow: 1,
              position: "relative",
            }}
          >
            {isDraggable && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: dragDisabled ? "not-allowed" : "auto",
                }}
                {...provided.dragHandleProps}
              >
                <>
                  <CircularProgress
                    size={20}
                    sx={{
                      position: "absolute",
                      top: 2,
                      left: 2,
                      backgroundColor: "white",
                      opacity: dragDisabled ? 1 : 0,
                      transition: "opacity 0.3s ease-out",
                    }}
                  />
                  <DragIndicatorIcon />
                </>
              </Box>
            )}
            {inEditModeIndex === index ? (
              <YoodliTextfield
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Video title"
                InputProps={{
                  sx: {
                    fontSize: "16px",
                    fontWeight: 500,
                    height: "40px",
                  },
                }}
                sx={{
                  height: "40px",
                  borderRadius: "4px",
                  pt: 0.5,
                }}
              />
            ) : (
              <Typography
                sx={{
                  textAlign: "start",
                  lineHeight: 1.2,
                  width: "fit-content",
                  maxWidth: "calc(100% - 24px)",
                  display: "-webkit-box",
                  lineClamp: 2,
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                {video.title}
              </Typography>
            )}

            <Stack
              direction={{ xs: "column", sm: "row" }}
              gap={{ xs: 1, sm: 2.5 }}
              alignItems={{ xs: "flex-start", sm: "center" }}
            >
              <Button
                onClick={() => deleteVideo(video.id)}
                disabled={loading}
                disableRipple
                sx={{ fontSize: "12px", fontWeight: 700, p: 0 }}
              >
                Remove file
              </Button>
              {inEditModeIndex === -1 && (
                <Button
                  disabled={loading}
                  onClick={() =>
                    handleSetInEditModeIndex(
                      index,
                      video.title,
                      video.description,
                      video.available_hubs
                    )
                  }
                  sx={{ fontSize: "12px", fontWeight: 700, p: 0 }}
                >
                  Edit details
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
        {inEditModeIndex === index ? (
          <Stack
            gap={1}
            sx={{
              textAlign: "start",
              pt: 1,
            }}
          >
            <Typography color={getDynamicColor("purple3")} fontSize="14px" fontWeight={700}>
              Description
            </Typography>
            <YoodliTextfield
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Add a description"
              multiline
              rows={2}
              InputProps={{
                sx: {
                  fontSize: "16px",
                },
              }}
            />

            {!isCourse && (
              <Stack gap={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography
                    color={getDynamicColor("purple3")}
                    fontFamily="poppins"
                    fontSize="14px"
                    fontWeight={700}
                  >
                    Available in<span style={{ color: getDynamicColor("redError") }}>*</span>
                  </Typography>
                  <Button
                    onClick={handleSelectAll}
                    sx={{
                      color: getDynamicColor("primary"),
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    {allHubsAvailable ? "Deselect all" : "Select all"}
                  </Button>
                </Stack>
                <Stack
                  py={1}
                  px={2.5}
                  maxHeight="210px"
                  borderRadius="4px"
                  border={`1px solid ${getDynamicColor("dark3")}`}
                  sx={{ overflowY: "scroll", background: getDynamicColor("light1") }}
                >
                  {allHubs.map((hub, index) => (
                    <Stack
                      key={hub.id}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderBottom={
                        index === allHubs.length - 1
                          ? "hidden"
                          : `1px solid ${getDynamicColor("dark3")}`
                      }
                      py={2}
                    >
                      <Typography>{hub.name}</Typography>
                      <Checkbox
                        onClick={() => {
                          handleSetAvailableHubs(hub.id);
                        }}
                        checked={availableHubs.includes(hub.id)}
                        sx={{
                          color: getDynamicColor("purple3"),
                        }}
                      />
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
            <Button
              onClick={() => updateVideo(video.id)}
              disabled={disableSave}
              sx={{ ml: "auto", borderRadius: "50px", mt: 0.5 }}
              variant="contained"
            >
              Save changes
            </Button>
          </Stack>
        ) : (
          <>
            {video.description && (
              <Typography
                sx={{
                  width: "fit-content",
                  textAlign: "start",
                  color: getDynamicColor("purple3"),
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {video.description}
              </Typography>
            )}
            {!isCourse &&
              video.available_hubs?.some((hubId) => allHubs.some((hub) => hub.id === hubId)) && (
                <Box
                  sx={{
                    mt: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: getDynamicColor("dark4"),
                      fontSize: 14,
                      fontWeight: 500,
                      mb: 0.5,
                      textAlign: "start",
                    }}
                  >
                    Available In:
                  </Typography>
                  <Stack direction="row" flexWrap="wrap" gap={1}>
                    {video.available_hubs.map((hubId) => {
                      const hub = allHubs
                        .map((hub) => ({ id: hub.id, name: hub.name }))
                        .find((hub) => hub.id === hubId);
                      return (
                        <Chip
                          key={hubId}
                          label={hub.name}
                          sx={{
                            height: 28,
                            width: "fit-content",
                          }}
                        />
                      );
                    })}
                  </Stack>
                </Box>
              )}
          </>
        )}
      </Stack>
    );
  };

  return (
    <>
      <Stack py={3}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          Manage {isCourse ? "course" : "demo"} videos
        </Typography>
        <Typography
          sx={{
            maxWidth: "300px",
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          Edit the name/description and select which groups have access to your content
        </Typography>
      </Stack>
      <Divider sx={{ width: "100%", background: getDynamicColor("dark3") }} />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppable"
          style={{
            width: "100%",
          }}
        >
          {(provided) => (
            <Stack
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                py: { xs: 2, sm: 4 },
                px: { xs: 4, sm: 6.5 },
                width: "100%",
                maxHeight: 600,
                overflowY: "auto",
              }}
            >
              {orderedVideos?.map((video, index) => {
                return (
                  <Draggable
                    key={video.id}
                    draggableId={video.id}
                    index={index}
                    isDragDisabled={dragDisabled || !isDraggable || loading}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        {renderVideo(video, index, provided)}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <Box
        sx={{
          background: getDynamicColor("light2"),
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          height: "88px",
          alignItems: "center",
          px: 4.5,
          py: 2,
          borderRadius: "0 0 12px 12px",
        }}
      >
        {isUpload && (
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <Button
              onClick={cancelUpload}
              sx={{
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 700,
                textDecorationLine: "underline",
              }}
            >
              Cancel upload
            </Button>
            <Button
              onClick={handleUploadComplete}
              disabled={uploading}
              variant="contained"
              sx={{
                height: "44px",
                borderRadius: "50px",
                px: 8,
                py: 2,
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 700,
                "&:disabled": {
                  backgroundColor: getDynamicColor("dark4"),
                  color: getDynamicColor("light1"),
                },
              }}
            >
              {uploading ? "Uploading" : "Save"}
            </Button>
          </Stack>
        )}
      </Box>
    </>
  );
}
