import React from "react";

// Components
import { Button, Stack, Typography, Dialog } from "@mui/material";
import GoAnnualModalContent from "components/Pricing/GoAnnualModalContent";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { PlanCheckoutResult } from "lib-fullstack/api/planApiTypes";

const GoAnnualBanner = ({
  annualSavingsAmt,
  handleGoAnnual,
  goAnnualError,
  isCard,
  hasPendingPlan,
  handleTogglePricingModal,
}: {
  annualSavingsAmt: number;
  handleGoAnnual: () => void;
  goAnnualError: PlanCheckoutResult | null;
  isCard?: boolean;
  hasPendingPlan?: boolean;
  handleTogglePricingModal?: (open?: boolean, copy?: string, hasPendingPlan?: boolean) => void;
}): JSX.Element => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const { modalStyles } = useNavDrawerOffset();

  const handleGoAnnualClick = () => {
    if (!showConfirmModal) {
      if (hasPendingPlan) {
        handleTogglePricingModal?.(true, "Go Annual", true);
      } else {
        setShowConfirmModal(true);
      }
    } else {
      handleGoAnnual();
    }
  };
  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };

  const renderGoAnnualModal = () => {
    return (
      <Dialog
        open={showConfirmModal}
        onClose={handleCloseModal}
        sx={{
          ...modalStyles,
        }}
        PaperProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            width: "min(600px, 100%)",
            maxWidth: "calc(100% - 32px) !important",
            p: "0 !important",
          },
        }}
      >
        <GoAnnualModalContent
          open={showConfirmModal}
          handleCloseModal={handleCloseModal}
          annualSavingsAmt={annualSavingsAmt}
          handleGoAnnual={handleGoAnnual}
        />
      </Dialog>
    );
  };
  return (
    <>
      {renderGoAnnualModal()}
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: getDynamicColor("light2"),
          borderRadius: "8px",
          ...(isCard
            ? {
                width: "calc(100% + 32px)",
                position: "relative",
                left: -16,
                top: 16,
                p: 2,
                gap: 2,
              }
            : {
                justifyContent: "space-between",
                minHeight: 60,
                px: 3,
              }),
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            color: getDynamicColor("purple3"),
            fontWeight: 400,
            ...(isCard
              ? {
                  flexGrow: 1,
                  lineHeight: 1.3,
                  ...(goAnnualError && {
                    color: getDynamicColor("redError"),
                  }),
                }
              : {
                  ml: 1,
                }),
          }}
        >
          {goAnnualError ? (
            `${goAnnualError}. Please try again.`
          ) : (
            <>
              <span
                style={{
                  fontWeight: 900,
                }}
              >
                You’re billing monthly.
              </span>{" "}
              Save ${annualSavingsAmt} a year when you pay annually
            </>
          )}
        </Typography>
        <Button
          variant="contained"
          sx={{
            fontSize: 12,
            ...(isCard
              ? {
                  height: 32,
                  whiteSpace: "nowrap",
                }
              : {
                  lineHeight: 1.3,
                }),
          }}
          onClick={handleGoAnnualClick}
        >
          GO ANNUAL
        </Button>
      </Stack>
    </>
  );
};

export default GoAnnualBanner;
