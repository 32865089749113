import React from "react";
import { useLocation } from "react-router-dom";
import { CUSTOM_TITLE_PAGES, WebServerInternalPath } from "./paths";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { getNavigationItems } from "./Enums";

export const PAGE_TITLES: Record<string, string> = {
  [WebServerExternalPath.SIGN_IN]: "Sign In",
  [WebServerExternalPath.SIGN_UP]: "Sign Up",
  [WebServerExternalPath.RESET_PASSWORD]: "Reset Password",
  [WebServerExternalPath.SUPPORT]: "Support",
  [WebServerExternalPath.ACCOUNT]: "Account",
  [WebServerInternalPath.AFFILIATE_PROGRAM]: "Refer Clients",
  [WebServerExternalPath.PRACTICE_CONVERSATION]: "Practice Conversation",
  [WebServerExternalPath.PRACTICE_SPEECH]: "Practice Presentation",
  [WebServerExternalPath.PRACTICE_INTERVIEW]: "Practice Interview",
  [WebServerInternalPath.EXERCISES_SPIN_A_YARN]: "Spin a Yarn",
  [WebServerInternalPath.EXERCISES_METAPHOR_MANIA]: "Metaphor Mania",
  [WebServerInternalPath.EXERCISES_NO_FILLER]: "No Fillers",
  [WebServerInternalPath.EXERCISES_STORYTELLER]: "Storyteller",
  [WebServerExternalPath.DOWNLOAD_POODLI_DIRECT]: "Download the Desktop App",
  [WebServerExternalPath.POODLI_CALENDAR_CONNECT]: "Open Yoodli App",
  [WebServerInternalPath.SETUP_DESKTOP_APP]: "Setup Yoodli App",
  [WebServerInternalPath.CHECKOUT_COMPLETE]: "Checkout Complete",
  [WebServerInternalPath.CHECKOUT]: "Checkout",
  [WebServerInternalPath.ADMIN_CONFIG]: "Yoodli Product Administration: Org Management",
  [WebServerInternalPath.ADMIN_CONFIG_ORG_DETAILS]: "Yoodli Product Administration Org Details",
  [WebServerInternalPath.ACCEPT_INVITE]: "Accept Invite",
  [WebServerInternalPath.ORG_OVERVIEW]: "Organization Overview",
  [WebServerInternalPath.ORG_SETTINGS]: "Organization Settings",
  [WebServerInternalPath.ORG_MEMBERS]: "Organization Members",
  [WebServerInternalPath.ORG_CONTENT]: "Organization Content",
  [WebServerExternalPath.MY_LEARNING]: "Organization Resources",
  [WebServerInternalPath.ORG_GROUPS]: "Organization Groups",
  [WebServerInternalPath.CREATE_ORG]: "Create Organization",
  [WebServerInternalPath.CREATE_GROUP]: "Create Group",
  [WebServerInternalPath.ORG_PROGRAMS]: "Organization Programs",
  [WebServerInternalPath.BUILDER]: "Builder",
};

export const PageTitleProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const location = useLocation();

  const navigationItems = getNavigationItems();
  React.useEffect(() => {
    Object.keys(navigationItems).forEach((path) => {
      PAGE_TITLES[path] = navigationItems[path].pageTitle ?? navigationItems[path].text;
    });
  }, [navigationItems]);

  const pageTitle = PAGE_TITLES[location.pathname];

  let shouldSetTitle = true;
  // loop through the custom_titles so i can check partial matches of path into location.pathname
  // rather than location.pathname into path, which fails due to "/:slug" vs "/5dfsb3g"
  CUSTOM_TITLE_PAGES.forEach((path) => {
    if (location.pathname.includes(path)) {
      shouldSetTitle = false;
    }
  });
  // dont set the title here on pages with custom titles
  if (shouldSetTitle) {
    const _pageTitle =
      (pageTitle ? `${pageTitle} - ` : "") + (getStaticFullSiteConf().title ?? "Yoodli");
    document.title = _pageTitle;
  }
  return <>{children}</>;
};
