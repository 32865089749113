import { getAxiosInstance, AuthorizationAPIHeaders } from "./AxiosInstance";
import * as api from "lib-fullstack/api/integrationContextAssociationApiTypes";

/**
 * Create a new org integration context association.
 * @param orgId Org ID.
 * @param integrationId Integration ID.
 * @param body The request body.
 * @returns The response containing the ID of the created integration context association.
 * @throws Error if the request fails.
 */
export async function createOrgIntegrationContextAssociation(
  orgId: string,
  integrationId: string,
  body: api.CreateOrgIntegrationContextAssociationRequestBase
): Promise<api.CreateOrgIntegrationContextAssociationResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().post(
      `/v2/orgs/${orgId}/integrations/${integrationId}/contexts`,
      body,
      {
        headers: headers,
      }
    );
    return api.CreateOrgIntegrationContextAssociationResponse.check(ret.data);
  } catch (err) {
    throw Error(
      `Bad createOrgIntegrationContextAssociation request, status ${err.response?.status}`
    );
  }
}

/**
 * List the context associations for a single org integration.
 * @param orgId Org ID.
 * @param integrationId Integration ID.
 * @returns Context associations for this org integration.
 * @throws Error if the request fails.
 */
export async function listOrgIntegrationContextAssociations(
  orgId: string,
  integrationId: string
): Promise<api.ListOrgIntegrationContextAssociationsResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().get(
      `/v2/orgs/${orgId}/integrations/${integrationId}/contexts`,
      {
        headers: headers,
      }
    );
    return api.ListOrgIntegrationContextAssociationsResponse.check(ret.data);
  } catch (err) {
    throw Error(
      `Bad listOrgIntegrationContextAssociations request, status ${err.response?.status}`
    );
  }
}

/**
 * Update an organization integration context association.
 * @param orgId Org ID.
 * @param integrationId Integration ID.
 * @param contextId External context ID.
 * @param body The request body.
 * @throws Error if the request fails.
 */
export async function updateOrgIntegrationContextAssociation(
  orgId: string,
  integrationId: string,
  contextId: string,
  body: api.UpdateOrgIntegrationContextAssociationRequestBase
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await getAxiosInstance().patch(
      `/v2/orgs/${orgId}/integrations/${integrationId}/contexts/${encodeURIComponent(contextId)}`,
      body,
      {
        headers: headers,
      }
    );
  } catch (err) {
    throw Error(
      `Bad updateOrgIntegrationContextAssociation request, status ${err.response?.status}`
    );
  }
}

/**
 * Delete an organization integration context association.
 * @param orgId Org ID.
 * @param integrationId Integration ID.
 * @param contextId External context ID.
 * @throws Error if the request fails.
 */
export async function deleteOrgIntegrationContextAssociation(
  orgId: string,
  integrationId: string,
  contextId: string
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await getAxiosInstance().delete(
      `/v2/orgs/${orgId}/integrations/${integrationId}/contexts/${encodeURIComponent(contextId)}`,
      {
        headers: headers,
      }
    );
  } catch (err) {
    throw Error(
      `Bad deleteOrgIntegrationContextAssociation request, status ${err.response?.status}`
    );
  }
}
