import React from "react";

// Components
import { Box } from "@mui/material";

const BG_BALL_COLORS = ["#CBE6FF", "#EDFDFF"];
const BgBalls = ({ visible }: { visible: boolean }): JSX.Element => {
  const sharedStyles = {
    position: "absolute",
    borderRadius: "50%",
  };
  return (
    <>
      <Box
        component="span"
        sx={{
          ...sharedStyles,
          top: "80%",
          left: "70%",
          filter: "blur(22px)",
          transform: "translate(-15%, -25%)",
          width: "max(150%, 1200px)",
          height: "150%",
          backgroundColor: BG_BALL_COLORS[0],
          zIndex: -1,
          opacity: visible ? 1 : 0,
          transition: "opacity 0.75s ease-in-out",
        }}
      />
      <Box
        component="span"
        sx={{
          ...sharedStyles,
          top: { xs: "40%", md: "80%", lg: "90%" },
          left: { xs: 0, md: "30%", lg: "40%" },
          transform: "rotate(25deg) translate(-15%, -25%)",
          width: "150%",
          height: "120%",
          filter: "blur(44px)",
          backgroundColor: BG_BALL_COLORS[1],
          zIndex: -2,
          opacity: visible ? 1 : 0,
          transition: "opacity 1.5s ease-in-out",
        }}
      />
    </>
  );
};

export default BgBalls;
