// Components
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, TextFieldProps } from "@mui/material";

// Utils
import YoodliTextfield from "./YoodliTextfield";
import { getDynamicColor } from "lib-frontend/utils/Colors";

type YoodliSearchBarProps = {
  clearSearch: () => void;
};

export const YoodliSearchBar = (props: TextFieldProps & YoodliSearchBarProps): JSX.Element => {
  const searchbarProps = { ...props };
  delete searchbarProps.clearSearch;
  return (
    <YoodliTextfield
      {...searchbarProps}
      variant="outlined"
      value={props?.value}
      sx={{ maxWidth: "400px", minWidth: "200px", ...props?.sx }}
      InputProps={{
        endAdornment: (
          <IconButton aria-label="Search" onClick={props?.clearSearch} edge="end">
            {props?.value.toString().length > 0 ? (
              <ClearIcon />
            ) : (
              <SearchIcon sx={{ color: getDynamicColor("dark4") }} />
            )}
          </IconButton>
        ),
        sx: searchbarProps?.InputProps?.sx,
      }}
    />
  );
};
