/**
 * Admin V1 API types
 */

import { OrgSubscriptionType } from "lib-fullstack/utils/enums";
import {
  Number,
  Optional,
  Array as RTArray,
  Record,
  Static,
  String,
  Boolean,
  Union,
  Null,
} from "runtypes";
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

/**
 * Sort order for {@link AdminGetOrgListResponse} items.
 */
export enum AdminGetOrgListSortOption {
  NAME_ASC = "name",
  NAME_DESC = "-name",
}

/**
 * Query parameters for {@link getOrgList}.
 */
export const AdminGetOrgListQueryParams = Record({
  sort: Optional(RTStringEnum(AdminGetOrgListSortOption)),
  start: Optional(Number),
  limit: Optional(Number),
});
export type AdminGetOrgListQueryParams = Static<typeof AdminGetOrgListQueryParams>;

/**
 * Response item for {@link getOrgList}.
 */
export const AdminGetOrgListResponseItem = Record({
  id: String,
  name: String,
  owner_emails: RTArray(String),
  admin_emails: RTArray(String),
});
export type AdminGetOrgListResponseItem = Static<typeof AdminGetOrgListResponseItem>;

/**
 * Response for {@link getOrgList}.
 */
export const AdminGetOrgListResponse = Record({
  orgs: RTArray(AdminGetOrgListResponseItem),
});
export type AdminGetOrgListResponse = Static<typeof AdminGetOrgListResponse>;

/**
 * HubSpot data which can be read for an org.
 */
export const AdminGetOrgHubSpotData = Record({
  instanceId: Union(String, Null),
});
export type AdminGetOrgHubSpotData = Static<typeof AdminGetOrgHubSpotData>;

/**
 * Subscription data which can be read for an org.
 */
export const AdminGetOrgSubscriptionData = Record({
  /**
   * Subscription type for the org.
   */
  subscriptionType: RTStringEnum(OrgSubscriptionType),
  /**
   * Stripe customer ID for the org.
   */
  stripeId: Union(String, Null),
  /**
   * Number of licensed seats by the contract.
   * Prepaid: the whole number
   * Flexible: the number of base seats
   * PAYG: null
   */
  seatsLicensed: Union(Number, Null),
  /**
   * Number of days of inactivity before a user is considered inactive and no longer occupies an org seat. If < 1, seats do not become inactive.
   */
  numSeatInactiveDays: Optional(Number),
  /**
   * End date of the license. ISO8601 date string.
   */
  licenseEndDate: Union(String, Null),
  /**
   * Subscription status. Null if not provided. May be "active" or "trialing".
   */
  subscriptionStatus: Union(String, Null),
  /**
   * True if a payment method has been added to the subscription.
   */
  paymentMethodAdded: Union(Boolean, Null),
  /**
   * Per seat price for Flexible and PAYG
   */
  perSeatDollars: Union(Number, Null),
});
export type AdminGetOrgSubscriptionData = Static<typeof AdminGetOrgSubscriptionData>;

/**
 * Quota data which can be read for an org.
 */
export const AdminGetOrgQuotaData = Record({
  courseQuota: Number,
  courseVideoQuota: Number,
  demoVideoQuota: Number,
  hubQuota: Number,
});
export type AdminGetOrgQuotaData = Static<typeof AdminGetOrgQuotaData>;

/**
 * Feature flags which can be read for an org.
 */
export const AdminGetOrgFeatureData = Record({
  interviewEnabled: Boolean,
  supportAccessEnabled: Boolean,
});
export type AdminGetOrgFeatureData = Static<typeof AdminGetOrgFeatureData>;

/**
 * Owners and admins data which can be read for an org.
 */
export const AdminGetOrgOwnersAndAdminsData = Record({
  ownerEmails: RTArray(String),
  adminEmails: RTArray(String),
});
export type AdminGetOrgOwnersAndAdminsData = Static<typeof AdminGetOrgOwnersAndAdminsData>;

/**
 * Response type for {@link getOrgById}.
 */
export const AdminGetOrgResponse = Record({
  hubSpot: Optional(AdminGetOrgHubSpotData),
  subscription: Optional(AdminGetOrgSubscriptionData),
  quotas: Optional(AdminGetOrgQuotaData),
  features: Optional(AdminGetOrgFeatureData),
  ownersAndAdmins: Optional(AdminGetOrgOwnersAndAdminsData),
});
export type AdminGetOrgResponse = Static<typeof AdminGetOrgResponse>;

/**
 * The enum values must match the field names in {@link AdminGetOrgResponse}.
 */
export enum AdminGetOrgFieldType {
  HUBSPOT = "hubSpot",
  SUBSCRIPTION = "subscription",
  QUOTAS = "quotas",
  FEATURES = "features",
  OWNERS_AND_ADMINS = "ownersAndAdmins",
}

/**
 * Query parameters for {@link getOrgById}.
 */
export const AdminGetOrgQueryParams = Record({
  /**
   * Comma separated list of {@link AdminGetOrgFieldType}.
   */
  fields: String,
});
export type AdminGetOrgQueryParams = Static<typeof AdminGetOrgQueryParams>;

/**
 * Request type of update an org subscription.
 * See {@link upsetSubscriptionForOrg}.
 */
export enum AdminUpdateOrgSubscriptionRequestType {
  /**
   * Switch from PAYG or Flexible to Prepaid.
   * Must include the license end date and number of seats.
   */
  SWITCH_TO_PREPAID = "switch_to_prepaid",
  /**
   * Switch from PAYG or Prepaid to Flexible.
   * Must include the license end date, number of seats, and per seat price.
   */
  SWITCH_TO_FLEXIBLE = "switch_to_flexible",
  /**
   * Assign a free trial to PAYG customer whose contract has expired
   * or switch from Prepaid or Flexible to PAYG.
   * Must include the license end date and number of seats.
   */
  SWITCH_TO_OR_RECREATE_PAYG_FREE_TRIAL = "switch_to_or_recreate_payg_free_trial",
  /**
   * Update extant license for Prepaid or Flexible customer.
   * Must include the license end date and number of seats.
   * Also must include per seat price for Flexible.
   */
  UPDATE_LICENSE = "update_license",
}

/**
 * Subscription data which can be updated for an org.
 */
export const AdminUpdateOrgSubscriptionRequest = Record({
  /**
   * Desired subscription type.
   */
  requestType: RTStringEnum(AdminUpdateOrgSubscriptionRequestType),
  /**
   * Desired number of licensed seats.
   */
  seatsLicensed: Optional(Number),
  /**
   * End date of the license. ISO8601 date string.
   */
  licenseEndDate: Optional(String),
  /**
   * Number of days of inactivity before a user is considered inactive and no longer occupies an org seat. If < 1, seats do not become inactive.
   */
  numSeatInactiveDays: Optional(Number),
  /**
   * Price per seat in dollars. Only for flexible plan.
   */
  perSeatDollars: Optional(Number),
});
export type AdminUpdateOrgSubscriptionRequest = Static<typeof AdminUpdateOrgSubscriptionRequest>;

/**
 * Quotas which can be updated for an org.
 */
export const AdminUpdateOrgQuotasRequest = Record({
  courseQuota: Optional(Number),
  courseVideoQuota: Optional(Number),
  demoVideoQuota: Optional(Number),
  hubQuota: Optional(Number),
});
export type AdminUpdateOrgQuotasRequest = Static<typeof AdminUpdateOrgQuotasRequest>;

/**
 * Feature flags which can be updated for an org.
 */
export const AdminUpdateOrgFeaturesRequest = Record({
  interviewEnabled: Optional(Boolean),
});
export type AdminUpdateOrgFeaturesRequest = Static<typeof AdminUpdateOrgFeaturesRequest>;

/**
 * Request type for {@link updateOrg}.
 */
export const AdminUpdateOrgRequest = Record({
  subscription: Optional(AdminUpdateOrgSubscriptionRequest),
  quotas: Optional(AdminUpdateOrgQuotasRequest),
  features: Optional(AdminUpdateOrgFeaturesRequest),
});
export type AdminUpdateOrgRequest = Static<typeof AdminUpdateOrgRequest>;

export const AdminUpdateOrgResponse = Record({
  success: Boolean,
});
export type AdminUpdateOrgResponse = Static<typeof AdminUpdateOrgResponse>;

export const AdminGetUserListQueryParams = Record({
  email: Optional(String),
});
export type AdminGetUserListQueryParams = Static<typeof AdminGetUserListQueryParams>;

export const AdminGetUserListResponseItem = Record({
  id: String,
  email: String,
});
export type AdminGetUserListResponseItem = Static<typeof AdminGetUserListResponseItem>;

export const AdminGetUserListResponse = Record({
  users: RTArray(AdminGetUserListResponseItem),
});
export type AdminGetUserListResponse = Static<typeof AdminGetUserListResponse>;

/**
 * Response for {@link getDealById}.
 */
export const AdminGetHubSpotDealByIdResponse = Record({
  /**
   * HubSpot deal ID.
   */
  dealId: Union(String, Null),
  /**
   * Name of the deal.
   */
  dealName: Union(String, Null),
  /**
   * Organization ID associated with the deal.
   */
  orgId: Union(String, Null),
  /**
   * HubSpot company ID associated with the deal.
   */
  companyId: Union(String, Null),
});
export type AdminGetHubSpotDealByIdResponse = Static<typeof AdminGetHubSpotDealByIdResponse>;

/**
 * Response for {@link getCompanyById}.
 */
export const AdminGetHubSpotCompanyByIdResponse = Record({
  /**
   * HubSpot company ID.
   */
  companyId: Union(String, Null),
  /**
   * Name of the company.
   */
  companyName: Union(String, Null),
});
export type AdminGetHubSpotCompanyByIdResponse = Static<typeof AdminGetHubSpotCompanyByIdResponse>;

export const AdminCreateHubSpotYoodliInstanceRequest = Record({
  /**
   * Organization ID.
   */
  orgId: String,
  /**
   * HubSpot company ID.
   */
  companyId: String,
  /**
   * HubSpot deal ID.
   */
  dealId: String,
});
export type AdminCreateHubSpotYoodliInstanceRequest = Static<
  typeof AdminCreateHubSpotYoodliInstanceRequest
>;

export const AdminCreateHubSpotYoodliInstanceResponse = Record({
  /**
   * HubSpot instance ID.
   */
  instanceId: String,
});
export type AdminCreateHubSpotYoodliInstanceResponse = Static<
  typeof AdminCreateHubSpotYoodliInstanceResponse
>;
