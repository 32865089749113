// Components
import { WizardState } from "components/ConvoScenarios/convoScenarioUtils";
import {
  CreateConvoScenarioWizard,
  WizardType,
} from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreateConvoScenarioWizard";
import { ManageConvoScenarios } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/ManageConvoScenarios";

// Utils
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";
import { HubV2Response, OrgV2Response } from "lib-fullstack/api/orgApiTypes";

type ScenariosTabProps = {
  selectedOrg: OrgV2Response;
  templateSelected: GetScenarioResponse;
  wizardState: WizardState;
  setWizardState: React.Dispatch<React.SetStateAction<WizardState>>;
  handleTemplateSelected: (scenario: GetScenarioResponse, scenarioId?: string) => void;
  allHubs: HubV2Response[];
  renderLoader: () => JSX.Element;
  showBackHeader: boolean;
  setShowBackHeader: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldBlockNav: React.Dispatch<React.SetStateAction<boolean>>;
  showScenarioTemplates: boolean;
  setShowScenarioTemplates: React.Dispatch<React.SetStateAction<boolean>>;
  setHideTabsState: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ScenariosTab = ({
  selectedOrg,
  templateSelected,
  wizardState,
  setWizardState,
  handleTemplateSelected,
  renderLoader,
  showBackHeader,
  setShowBackHeader,
  setShouldBlockNav,
  showScenarioTemplates,
  setShowScenarioTemplates,
  setHideTabsState,
}: ScenariosTabProps): JSX.Element => {
  const handleCreateScenarioClicked = () => {
    setShowScenarioTemplates(true);
    setShowBackHeader(true);
    setHideTabsState(true);
  };

  const handleCloseWizard = () => {
    setShowScenarioTemplates(false);
    setShowBackHeader(false);
    setHideTabsState(false);
    handleTemplateSelected(null);
  };

  if (templateSelected && showBackHeader) {
    return (
      <CreateConvoScenarioWizard
        closeWizard={handleCloseWizard}
        wizardState={wizardState}
        templateSelected={templateSelected}
        handleTemplateSelected={handleTemplateSelected}
        setShouldBlockNav={setShouldBlockNav}
        wizardType={WizardType.Org}
      />
    );
  }
  return (
    <ManageConvoScenarios
      handleTemplateSelected={handleTemplateSelected}
      selectedOrg={selectedOrg}
      setWizardState={setWizardState}
      wizardState={wizardState}
      renderLoader={renderLoader}
      showScenarioTemplates={showScenarioTemplates}
      handleCreateScenarioClicked={handleCreateScenarioClicked}
    />
  );
};
