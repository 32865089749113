// Components
import { Button } from "@mui/material";

// Assets
import OutlookIcon from "../../images/icons/OutlookCalendarIcon.svg";

// Utils
import { initAuthFlow } from "./calendarUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";

export default function ConnectOutlookCalendar(props: {
  redirectPathname?: string;
  logActionPanelAmplitudeEvent?: () => void;
}): JSX.Element {
  return (
    <Button
      aria-label="Connect Outlook calendar"
      onClick={() => {
        props.logActionPanelAmplitudeEvent?.();
        initAuthFlow(props.redirectPathname);
      }}
      variant="outlined"
      startIcon={<img src={OutlookIcon} alt="Microsoft" height="20px" width="20px" />}
      sx={{
        width: { xs: "100%", xl: "min(100%, 150px)" },
        height: "35px",
        fontSize: "12px",
        "&:hover": {
          backgroundColor: getDynamicColor("light2"),
          color: getDynamicColor("primary"),
        },
      }}
    >
      Outlook Calendar
    </Button>
  );
}
