import * as db from "../db";
import isEmail from "validator/lib/isEmail";
import normalizeEmail from "validator/lib/normalizeEmail";

/**
 * This wraps functions of validator packages.
 * It is recommended to use this function instead of directly using
 * validator functions for uniformed operations across the codebase.
 *
 * Normalization is to make all characters as lower cases
 * and strip off leading/trailing spaces.
 *
 * Lower case is essential especially when storing it into Firestore
 * because Firestore does not support case-insensitive queries
 * while email address is practically case-insensitive.
 * Longer explanation: https://stackoverflow.com/a/9808332
 *
 * @param input a string that is supposed to be an email address.
 * This wrapper may accept non-string types or null and handle it as invalid.
 * @returns normalized email address, or null if the input is invalid.
 */
export function validateAndNormalizeEmail(input: string): string | null {
  // Type check to check null or non-string types (e.g. an object)
  if (!input || typeof input !== "string") {
    console.warn(`validateAndNormalizeEmail: invalid type`);
    return null;
  }

  const trimmedInput = input.trim();

  if (
    !isEmail(
      trimmedInput,
      // Note: these options are all default, but explicitly set for clarity
      {
        allow_display_name: false,
        require_display_name: false,
        allow_utf8_local_part: true,
        require_tld: true,
        allow_ip_domain: false,
        domain_specific_validation: false,
        blacklisted_chars: "",
        host_blacklist: [],
      }
    )
  ) {
    console.warn(`validateAndNormalizeEmail: invalid email address: ${trimmedInput}`);
    return null;
  }

  const normalizedEmail = normalizeEmail(
    trimmedInput,
    // Keep all lower case = true (default),
    // but disable removing dots or subaddresses (i.e. non default)
    // because we have taken those without modification up to now.
    {
      all_lowercase: true,
      gmail_remove_dots: false,
      gmail_remove_subaddress: false,
      gmail_convert_googlemaildotcom: false,
      outlookdotcom_remove_subaddress: false,
      yahoo_remove_subaddress: false,
      icloud_remove_subaddress: false,
    }
  );
  if (!normalizedEmail) {
    console.warn(`validateAndNormalizeEmail: normalization failed (unexpected): ${trimmedInput}`);
    return null;
  }

  return normalizedEmail;
}

/**
 * Throw version of validateAndNormalizeEmail()
 */
export function validateAndNormalizeEmailAndThrowOnFailure(input: string): string {
  // Detailed error message is spewed to the log
  const normalized = validateAndNormalizeEmail(input);
  if (!normalized) {
    throw new Error(`Invalid email address: ${input}`);
  }
  return normalized;
}

/**
 * Normalize incoming email domains to lower cases and remove empty entries.
 */
export function normalizeEmailDomains(input: string[] | null): string[] {
  const output: string[] = [];
  if (input) {
    for (const domain of input) {
      const normalized = domain.trim().toLowerCase();
      if (normalized.length > 0) {
        output.push(normalized);
      }
    }
  }
  return output;
}

/**
 * Get email domain as lower case string from email address
 * @param email email address
 * @returns email domain. undefined if it's not valid email address.
 */
export function getEmailDomain(email: string): string | undefined {
  const normalizedEmail = validateAndNormalizeEmail(email);
  if (!normalizedEmail) {
    return undefined;
  }
  const match = normalizedEmail.match(/@(.*)$/);
  if (!match) {
    return undefined;
  }
  return match[1];
}

/**
 * Determine if the user is a Yoodli employee or persona. This excludes
 * Yoodli user names which include "+".
 * @param email User email address.
 * @returns True if the user is a Yoodli employee or persona.
 */
export function isYoodliEmployeeOrPersona(email: string): boolean {
  const normalized = validateAndNormalizeEmail(email);
  if (!normalized) {
    return false;
  }
  const parts = normalized.split("@");
  if (parts.length !== 2) {
    return false;
  }
  const username = parts[0];
  const domain = parts[1];
  return domain === "yoodli.ai" && !username.includes("+");
}

export async function sendFreeformEmail(
  toEmail: string,
  toName: string,
  subject: string,
  headerText: string,
  mainText: string,
  siteId: string
): Promise<void> {
  const notif: db.FreeformEmailNotificationJob = {
    type: db.NotificationTypes.FREEFORM_EMAIL,
    toEmail,
    toName,
    subject,
    headerText,
    mainText,
    createdAt: new Date().toISOString(),
  };
  const ref = await db.add(db.notificationJobs(siteId), notif);
  console.log(`Created new free-form email notification job. Ref path: ${db.getRefPath(ref)}`);
}
