// Utils
import { getLiveUserDocMain, getLiveUserDocReadonly, updateThisUserDocMain } from "./LiveUserDocs";
import { setExistingAmplitudeUserProperties } from "./ProductAnalyticsUtils";
import { genExperimentGroup } from "./unleash";
import Cookies from "js-cookie";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import {
  PRICING_EXPERIMENT_COOKIE,
  ON_PAID_PLAN_COOKIE,
  LEGACY_PRICING,
} from "lib-fullstack/utils/constants";
import { PricingExperiment, UsagePlanType } from "lib-fullstack/utils/pricingTypes";

const PRICING_EXPERIMENT_AMPLITUDE_PROPERTY_NAME = "pricing_v1.1_cohort";

const PRICING_EXPERIMENT_AMPLITUDE_COHORTS = {
  [PricingExperiment.PRO_ADV]: "pricing_v1.1_utility",
  [PricingExperiment.CRUNCH_GROWTH]: "pricing_v1.1_crunchimprovement",
};

export const syncPricingExperimentWebclient = async (): Promise<PricingExperiment> => {
  const clientEnv = getClientEnvConfig();
  let pricingExperiment: PricingExperiment;
  pricingExperiment =
    getLiveUserDocMain().pExpVersion ??
    Cookies.get(PRICING_EXPERIMENT_COOKIE, { domain: clientEnv.domain });

  const onPaidPlan = getLiveUserDocReadonly().usagePlanType !== UsagePlanType.FREE;

  if (
    pricingExperiment === undefined ||
    (LEGACY_PRICING.includes(pricingExperiment) && !onPaidPlan)
  ) {
    pricingExperiment = (await genExperimentGroup("p-exp-version")) as PricingExperiment;
  }

  // If pricing experiment is not applicable, default to PRO_ADV
  pricingExperiment = Object.values(PricingExperiment).includes(pricingExperiment)
    ? pricingExperiment
    : PricingExperiment.PRO_ADV;

  Cookies.set(PRICING_EXPERIMENT_COOKIE, pricingExperiment, { domain: clientEnv.domain });

  Cookies.set(ON_PAID_PLAN_COOKIE, getLiveUserDocReadonly().usagePlanType !== UsagePlanType.FREE, {
    domain: clientEnv.domain,
  });

  await setExistingAmplitudeUserProperties({
    [PRICING_EXPERIMENT_AMPLITUDE_PROPERTY_NAME]:
      PRICING_EXPERIMENT_AMPLITUDE_COHORTS[pricingExperiment],
  });

  if (getLiveUserDocMain()?.pExpVersion !== pricingExperiment) {
    await updateThisUserDocMain({
      pExpVersion: pricingExperiment,
    });
  }

  return pricingExperiment;
};
