// Components
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";

// Utils
import { CoachBotCardScenarioTable } from "./CoachBotCardScenarioTable";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";

type CoachBotCardProps = {
  botName: string;
  dateBuilt: string;
  isProcessing: boolean;
  isDefault?: boolean;
  scenarios?: GetScenarioResponse[];
  handleDelete?: () => void;
  handleEdit?: () => void;
};

export const CoachBotCard = ({
  botName,
  isProcessing,
  dateBuilt,
  scenarios,
  isDefault,
  handleDelete,
  handleEdit,
}: CoachBotCardProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const renderIsProcessing = () => {
    if (!isProcessing) {
      return null;
    }
    return (
      <Stack direction="row" sx={{ gap: 1.5, alignItems: "center" }}>
        <Box sx={{ height: "fit-content" }}>
          <CircularProgress size={16} thickness={6} />
        </Box>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            fontFamily: "poppins",
            letterSpacing: "0.17px",
          }}
        >
          PROCESSING: <strong>10 minutes</strong> (est. remaining)
        </Typography>
      </Stack>
    );
  };
  return (
    <Stack
      direction="column"
      gap={{ xs: 1.5, md: 0 }}
      alignItems={{ xs: "flex-start", md: "center" }}
      justifyContent="flex-start"
      sx={{
        fontFamily: "poppins",
        boxShadow: Y_SHADOWS.box_shadow_1,
        p: 2,
        border: `1px solid ${getDynamicColor("dark2")}`,
        backgroundColor: isDefault ? getDynamicColor("light2") : getDynamicColor("light1"),
      }}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          width: "100%",
          gap: isDefault ? 1 : 0,
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Stack direction="row" gap={{ xs: 2, md: 4 }}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                fontFamily: "poppins",
                lineHeight: 1.3,
                cursor: handleEdit ? "pointer" : "default",
              }}
              onClick={handleEdit}
            >
              {botName}
            </Typography>
            {!isSmallScreen && renderIsProcessing()}
          </Stack>
          {!isSmallScreen ? (
            <Stack direction="row" sx={{ gap: 1 }}>
              {handleEdit && (
                <Button variant="text" onClick={handleEdit}>
                  Edit
                </Button>
              )}
              {handleDelete && (
                <Button variant="text" onClick={handleDelete}>
                  Delete
                </Button>
              )}
            </Stack>
          ) : (
            <YoodliMenu
              type={YoodliMenuButtonType.Icon}
              buttonSx={{
                border: "none",
                mx: 0,
                p: 0,
              }}
              menuItems={[
                handleEdit && {
                  title: "Edit",
                  onClick: handleEdit,
                  type: YoodliMenuItemType.Default,
                },
                handleDelete && {
                  title: "Delete",
                  onClick: handleDelete,
                  type: YoodliMenuItemType.Warning,
                },
              ].filter(Boolean)}
            />
          )}
        </Stack>
        <Typography
          sx={{
            color: getDynamicColor("dark4"),
            fontSize: 12,
            fontWeight: 400,
            fontFamily: "poppins",
            minWidth: { xs: 100, md: "unset" },
          }}
        >
          Built: {dateBuilt}
        </Typography>
      </Stack>
      {isSmallScreen && renderIsProcessing()}
      <Stack
        sx={{
          width: "100%",
          mt: 1.5,
        }}
      >
        {!isDefault && (
          <Accordion
            sx={{
              boxShadow: "none",
              fontSize: 12,
              mb: "0px !important",
              color: getDynamicColor("purple3"),
            }}
          >
            <AccordionSummary
              sx={{
                my: 0,
                px: 0,
                width: "fit-content",
                minHeight: "unset !important",
                ".MuiAccordionSummary-content, .Mui-expanded": {
                  my: "0px !important",
                  mr: 0.5,
                  cursor: !scenarios || scenarios?.length === 0 ? "default" : "pointer",
                },
              }}
              expandIcon={
                scenarios?.length > 0 ? (
                  <ExpandMoreIcon
                    sx={{
                      color: getDynamicColor("primary"),
                      height: 20,
                      width: 20,
                    }}
                  />
                ) : (
                  <></>
                )
              }
            >
              ASSIGNED TO {scenarios?.length ?? 0} SCENARIO
              {(scenarios?.length > 1 || !scenarios || scenarios?.length === 0) && "S"}
            </AccordionSummary>
            {!isDefault && scenarios?.length > 0 && (
              <AccordionDetails
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: 1.4,
                  px: 0,
                  pt: 1.5,
                  pb: "8px !important",
                }}
              >
                <CoachBotCardScenarioTable scenarios={scenarios} />
              </AccordionDetails>
            )}
          </Accordion>
        )}
      </Stack>
    </Stack>
  );
};
