// Components
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { Select, SelectProps } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type CreateOrgSectionSelectProps = {
  value: SelectProps["value"];
  onChange: SelectProps["onChange"];
  renderValue?: SelectProps["renderValue"];
  disabled?: SelectProps["disabled"];
  allowEmpty?: boolean;
};

export default function CreateOrgSectionSelect({
  children,
  value,
  onChange,
  renderValue,
  disabled,
}: React.PropsWithChildren<CreateOrgSectionSelectProps>): JSX.Element {
  return (
    <Select
      fullWidth
      value={value ?? ""}
      onChange={onChange}
      disabled={disabled}
      displayEmpty
      renderValue={renderValue}
      IconComponent={(params) => (
        <ExpandCircleDownIcon
          {...params}
          sx={{
            color: `${getDynamicColor("dark6")} !important`,
            width: 28,
            height: 28,
            right: "16px !important",
            transition: "transform 0.2s ease-out",
          }}
        />
      )}
      MenuProps={{
        sx: {
          ".MuiPaper-root": {
            borderRadius: "16px",
            py: 1,
            maxWidth: "260px",
          },
        },
      }}
      sx={{
        borderRadius: "100px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        height: "64px",
        px: 2,
        color: getDynamicColor(value !== undefined ? "purple3" : "dark4"),
        fontFamily: "poppins",
        fontSize: "16px",
        fontWeight: 500,
      }}
    >
      {children}
    </Select>
  );
}
