import { IntegrationData } from "lib-fullstack/db/db_runtypes_integration";
import { IntegrationType } from "lib-fullstack/utils/enums/integrationType";
import { Record, Static, String, Array as RTArray, Optional, Boolean } from "runtypes";

/**
 * Request to create a new integration for an organization.
 */
export const CreateOrgIntegrationRequest = Record({
  /**
   * Integration type.
   */
  type: IntegrationType,
  /**
   * Integration name.
   */
  name: String,
});
/**
 * Request to create a new integration for an organization.
 */
export type CreateOrgIntegrationRequest = Static<typeof CreateOrgIntegrationRequest>;

/**
 * Response to creating a new integration for an organization.
 */
export const CreateOrgIntegrationResponse = Record({
  /**
   * Integration ID.
   */
  id: String,
});
/**
 * Response to creating a new integration for an organization.
 */
export type CreateOrgIntegrationResponse = Static<typeof CreateOrgIntegrationResponse>;

/**
 * Record representing an item in the list of integrations for an organization.
 */
export const ListOrgIntegrationResponseItem = Record({
  /**
   * Integration ID.
   */
  id: String,
  /**
   * Integration type enum value.
   */
  type: IntegrationType,
  /**
   * Integration name.
   */
  name: String,
});
/**
 * Record representing an item in the list of integrations for an organization.
 */
export type ListOrgIntegrationResponseItem = Static<typeof ListOrgIntegrationResponseItem>;

/**
 * Response to getting a list of integrations for an organization.
 */
export const ListOrgIntegrationsResponse = Record({
  /**
   * List of integrations.
   */
  integrations: RTArray(ListOrgIntegrationResponseItem),
});
/**
 * Response to getting a list of integrations for an organization.
 */
export type ListOrgIntegrationsResponse = Static<typeof ListOrgIntegrationsResponse>;

/**
 * Response to getting an organization's integration.
 * For now, this is a pass-through of the database type.
 */
export const GetOrgIntegrationResponse = IntegrationData;
/**
 * Response to getting an organization's integration.
 * For now, this is a pass-through of the database type.
 */
export type GetOrgIntegrationResponse = Static<typeof GetOrgIntegrationResponse>;

/**
 * Request to update an organization's integration.
 */
export const UpdateOrgIntegrationRequest = Record({
  /**
   * Integration name.
   */
  name: Optional(String),
  /**
   * If true, enable debug-level logging for the integration. This can be used to build
   * the mock test data or enable troubleshooting live operations.
   */
  debugLogging: Optional(Boolean),
  /**
   * Key value used for indexing in the "orgIntegrations" collection group.
   */
  externalId: Optional(String),
});
/**
 * Request to update an organization's integration.
 */
export type UpdateOrgIntegrationRequest = Static<typeof UpdateOrgIntegrationRequest>;

/**
 * Request to update an organization's test integration.
 */
export const UpdateOrgTestIntegrationRequest = UpdateOrgIntegrationRequest.extend({
  /**
   * Test integration-specific field.
   */
  testField: Optional(String),
});
/**
 * Request to update an organization's test integration.
 */
export type UpdateOrgTestIntegrationRequest = Static<typeof UpdateOrgTestIntegrationRequest>;

/**
 * Request to update an organization's LTI 1.1 integration.
 */
export const UpdateOrgLti11IntegrationRequest = UpdateOrgIntegrationRequest.extend({
  /**
   * Consumer instance GUID generated by the LMS.
   * Provided by the Yoodli admin configuring the integration.
   */
  consumerInstanceGuid: Optional(String),
  /**
   * Host name for the LMS server which will be calling into the Yoodli
   * integration. This is required so we can validate URLs provided by the LMS
   * server before calling them. This protects against SSRF.
   */
  consumerHostName: Optional(String),
});

/**
 * Request to update an organization's LTI 1.1 integration.
 */
export type UpdateOrgLti11IntegrationRequest = Static<typeof UpdateOrgLti11IntegrationRequest>;

// Add LTI 1.3 update request
// Add SCORM 1.2 update request
