import React from "react";

// Components
import { Chip, Box, Typography, Stack } from "@mui/material";
import { EmojiEvents as TrophyIcon } from "@mui/icons-material";
import { LogDashboardAmplitudeEventType } from "components/Dashboard/Dashboard";

// Utils
import { FocusArea as FocusAreaType } from "../../DashboardTypes";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { DashboardAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";

type FocusAreaProps = {
  focusArea: FocusAreaType;
  isGoal?: boolean;
  logDashboardAmplitudeEvent: LogDashboardAmplitudeEventType;
};
const FocusArea = ({
  focusArea,
  isGoal,
  logDashboardAmplitudeEvent,
}: FocusAreaProps): JSX.Element => {
  const [isHoveringLabel, setIsHoveringLabel] = React.useState(false);
  const goalData = {
    identifier: "goal",
    label: "Goal",
    definition: "A goal is a target that you set for yourself to achieve.",
    icon: <TrophyIcon />,
    color: getDynamicColor("purple1"),
  };
  const data = isGoal ? goalData : focusArea;
  let setIsHoveringTimeout: NodeJS.Timeout;
  let setNotHoveringTimeout: NodeJS.Timeout;
  const handleSetIsHoveringLabel = () => {
    setIsHoveringTimeout = setTimeout(() => {
      logDashboardAmplitudeEvent(
        DashboardAnalyticsEvents.FOCUS_AREA_TOOLTIP_DISPLAYED,
        {
          focus_area_name: data.identifier,
        },
        true
      );
      setIsHoveringLabel(true);
    }, 150);
    if (setNotHoveringTimeout) clearTimeout(setNotHoveringTimeout);
    return () => clearTimeout(setIsHoveringTimeout);
  };

  const handleSetNotHoveringLabel = () => {
    setNotHoveringTimeout = setTimeout(() => {
      setIsHoveringLabel(false);
    }, 300);
    if (setIsHoveringTimeout) clearTimeout(setIsHoveringTimeout);
    return () => clearTimeout(setNotHoveringTimeout);
  };

  return (
    <Box
      onMouseEnter={handleSetIsHoveringLabel}
      onMouseLeave={handleSetNotHoveringLabel}
      sx={{
        position: "relative",
        width: "fit-content",
        zIndex: 2,
      }}
    >
      <Chip
        label={data.label}
        icon={data.icon}
        sx={{
          transition: "none",
          backgroundColor: data.color,
          color: getDynamicColor("purple3"),
          width: "fit-content",
          px: 1,
          fontSize: 10,
          textTransform: "uppercase",
          fontWeight: 400,
          height: 24,
          svg: {
            height: 14,
            width: 14,
          },
        }}
      />
      <Stack
        gap={1}
        sx={{
          transition: "opacity 0.3s ease-in-out",
          position: "absolute",
          left: "calc(100% + 16px)",
          top: 0,
          zIndex: 1900,
          backgroundColor: data.color,
          p: 2.5,
          borderRadius: 3,
          boxShadow: Y_SHADOWS.box_shadow_1,
          width: 250,
          border: `1px solid ${getDynamicColor("dark3")}`,
          ...(isHoveringLabel
            ? {
                opacity: 1,
              }
            : {
                pointerEvents: "none",
                opacity: 0,
              }),
        }}
      >
        <Typography
          variant="poppins"
          sx={{
            fontWeight: 700,
            fontSize: 14,
          }}
        >
          {data.label}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 12,
          }}
        >
          {data.definition}
        </Typography>
      </Stack>
    </Box>
  );
};

export default FocusArea;
