import React from "react";

// Components
import ModalWrapper, { Modals } from "components/ModalWrapper";

// Utils
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { HubsCourseAndDemoVideosTypeV2 } from "hooks/useCourseContentAndDemoVideosV2";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { DeleteModalType } from "utils/Enums";
import { HubModalStatus } from "utils/Enums";
import { InterviewBankNameModal } from "./CustomizePractice/InterviewBanks/InterviewBankNameModal";
import { InterviewBankQuestionsModal } from "./CustomizePractice/InterviewBanks/InterviewBankQuestionsModal";
import EditInterviewBankAvailableHubs from "./CustomizePractice/InterviewBanks/EditInterviewBankAvailableHubs";
import DeleteModal from "./VideosAndCourses/DeleteModal";
import EditVideosModal from "./VideosAndCourses/EditVideosModal";
import UploadVideoModal from "./VideosAndCourses/UploadVideoModal";
import CreateOrEditCourseModal from "./VideosAndCourses/CreateOrEditCourseModal";

type OrgSettingsModalProps = Partial<HubsCourseAndDemoVideosTypeV2> & {
  modalStatus: HubModalStatus;
  setModalStatus: (status: HubModalStatus) => void;
  allHubs: HubV2Response[];
  selectedInterviewBank: InterviewBankResponse;
  handleCreateInterviewBank: UseMutateAsyncFunction<
    void,
    Error,
    { name: string; interviewQs: string[] }
  >;
  handleDeleteInterviewBank: UseMutateAsyncFunction<void, Error, void>;
  handleUpdateInterviewBankName: UseMutateAsyncFunction<void, Error, { name: string }>;
  handleUpdateInterviewBankQuestions: UseMutateAsyncFunction<
    void,
    Error,
    { interviewQuestions: string[] }
  >;
  handleUpdateInterviewBankAvailableHubs: UseMutateAsyncFunction<void, Error, { hubs: string[] }>;
};

export function OrgManageContentModal({
  modalStatus,
  setModalStatus,
  allHubs,
  contentView,
  uploading,
  selectedCourse,
  setSelectedCourse,

  //Courses
  handleCreateCourse,
  handleUpdateCourse,
  handleDeleteCourse,
  // Demo Video Videos
  handleCreateDemoVideos,
  handleUpdateDemoVideo,
  handleDeleteDemoVideo,
  // Course Videos
  handleDeleteCourseVideo,
  handleUpdateCourseVideo,
  handleCreateCourseVideos,
  handleUpdateCourseVideos,

  // General Videos
  uploadingVideos,
  clearUploadingVideos,

  // Interview Banks
  selectedInterviewBank,
  handleCreateInterviewBank,
  handleDeleteInterviewBank,
  handleUpdateInterviewBankName,
  handleUpdateInterviewBankQuestions,
  handleUpdateInterviewBankAvailableHubs,
}: OrgSettingsModalProps): JSX.Element {
  const [bankName, setBankName] = React.useState<string>(undefined);

  const closeModal = (skipConfirmation?: boolean) => {
    if (
      ![
        HubModalStatus.CREATE_INTERVIEW_BANK,
        HubModalStatus.EDIT_INTERVIEW_BANK_QUESTIONS,
      ].includes(modalStatus) ||
      skipConfirmation ||
      window.confirm(
        "Are you sure you want to cancel creating an interview bank? your questions will not be saved"
      )
    ) {
      setModalStatus(HubModalStatus.CLOSED);
      setSelectedCourse(undefined);
      setBankName(undefined);
    }
  };

  const modals: Modals = {
    [HubModalStatus.UPLOAD_DEMO_VIDEO]: {
      element: uploadingVideos.length ? (
        <EditVideosModal
          videos={uploadingVideos}
          closeModal={closeModal}
          handleDeleteVideo={handleDeleteDemoVideo}
          handleUpdateVideo={handleUpdateDemoVideo}
          allHubs={allHubs}
          isUpload
          uploading={uploading}
          clearUploadingVideos={clearUploadingVideos}
        />
      ) : (
        <UploadVideoModal handleCreateVideos={handleCreateDemoVideos} />
      ),
      wrapperStyles: uploadingVideos.length
        ? {
            width: "min(650px, calc(100% - 16px))",
            maxHeight: "min(750px, calc(100% - 32px))",
            p: "0 !important",
            gap: "0 !important",
          }
        : {
            gap: 8,
            pb: 12,
          },
      showCloseButton: true,
    },
    [HubModalStatus.CREATE_COURSE]: {
      element: (
        <CreateOrEditCourseModal
          closeModal={closeModal}
          allHubs={allHubs}
          handleCreateCourse={handleCreateCourse}
        />
      ),
      wrapperStyles: {
        width: "min(650px, calc(100% - 16px))",
        maxHeight: "min(750px, calc(100% - 32px))",
      },
      showCloseButton: true,
    },
    [HubModalStatus.EDIT_COURSE_DETAILS]: {
      element: (
        <CreateOrEditCourseModal
          closeModal={closeModal}
          allHubs={allHubs}
          handleUpdateCourse={handleUpdateCourse}
          course={selectedCourse}
          isEditing
        />
      ),
      wrapperStyles: {
        width: "min(650px, calc(100% - 16px))",
        maxHeight: "min(750px, calc(100% - 32px))",
        backgroundColor: getDynamicColor("light2"),
      },
      showCloseButton: true,
    },
    [HubModalStatus.EDIT_DEMO_VIDEOS]: {
      element: (
        <EditVideosModal
          videos={contentView?.demo_videos}
          closeModal={closeModal}
          handleDeleteVideo={handleDeleteDemoVideo}
          handleUpdateVideo={handleUpdateDemoVideo}
          allHubs={allHubs}
        />
      ),
      wrapperStyles: {
        width: "min(650px, calc(100% - 16px))",
        maxHeight: "min(750px, calc(100% - 32px))",
        p: "0 !important",
        gap: "0 !important",
      },
      showCloseButton: true,
    },
    [HubModalStatus.UPLOAD_COURSE_VIDEO]: {
      element: uploadingVideos.length ? (
        <EditVideosModal
          videos={uploadingVideos}
          closeModal={closeModal}
          handleDeleteVideo={handleDeleteCourseVideo}
          handleUpdateVideo={handleUpdateCourseVideo}
          handleUpdateVideos={handleUpdateCourseVideos}
          allHubs={allHubs}
          isUpload
          uploading={uploading}
          clearUploadingVideos={clearUploadingVideos}
          isCourse
        />
      ) : (
        <UploadVideoModal handleCreateVideos={handleCreateCourseVideos} isCourse />
      ),
      wrapperStyles: uploadingVideos.length
        ? {
            width: "min(650px, calc(100% - 16px))",
            maxHeight: "min(750px, calc(100% - 32px))",
            p: "0 !important",
            gap: "0 !important",
          }
        : {
            gap: 8,
            pb: 12,
          },
      showCloseButton: true,
    },
    [HubModalStatus.EDIT_COURSE_VIDEOS]: {
      element: (
        <EditVideosModal
          videos={contentView?.courses.find((course) => course.id === selectedCourse?.id)?.videos}
          closeModal={closeModal}
          handleDeleteVideo={handleDeleteCourseVideo}
          handleUpdateVideo={handleUpdateCourseVideo}
          handleUpdateVideos={handleUpdateCourseVideos}
          allHubs={allHubs}
          isCourse
        />
      ),
      wrapperStyles: {
        p: "0 !important",
        gap: "0 !important",
        width: "min(650px, calc(100% - 16px))",
        maxHeight: "min(750px, calc(100% - 32px))",
      },
      showCloseButton: true,
    },
    [HubModalStatus.DELETE_COURSE]: {
      element: (
        <DeleteModal
          type={DeleteModalType.COURSE}
          currentName={selectedCourse?.title}
          closeModal={closeModal}
          handleDelete={handleDeleteCourse}
        />
      ),
      showCloseButton: true,
    },
    [HubModalStatus.CREATE_INTERVIEW_BANK]: {
      element: bankName ? (
        <InterviewBankQuestionsModal
          initQuestions={[]}
          bankName={bankName}
          onSubmit={async (interviewQuestions) =>
            await handleCreateInterviewBank({
              name: bankName,
              interviewQs: interviewQuestions.interviewQuestions,
            })
          }
          closeModal={closeModal}
        />
      ) : (
        <InterviewBankNameModal
          initName=""
          onSubmit={async (name) => setBankName(name)}
          ctaText="Next: Add questions"
        />
      ),
      wrapperStyles: {
        width: `min(${bankName ? 900 : 620}px, 100%)`,
        maxHeight: "100%",
        pt: bankName ? 3 : 10.5,
        pb: 4,
        px: 4.5,
        backgroundColor: getDynamicColor("light2"),
        boxShadow: Y_SHADOWS.box_shadow_1,
      },
      showCloseButton: true,
    },
    [HubModalStatus.DELETE_INTERVIEW_BANK]: {
      element: (
        <DeleteModal
          type={DeleteModalType.INTERVIEW_BANK}
          currentName={selectedInterviewBank?.name}
          closeModal={closeModal}
          handleDelete={handleDeleteInterviewBank}
        />
      ),
      showCloseButton: true,
    },
    [HubModalStatus.EDIT_INTERVIEW_BANK_NAME]: {
      element: (
        <InterviewBankNameModal
          initName={selectedInterviewBank?.name}
          onSubmit={async (name) => handleUpdateInterviewBankName({ name })}
          ctaText="Submit"
        />
      ),
      wrapperStyles: {
        width: `min(620px, 100%)`,
        maxHeight: "100%",
        pt: 10.5,
        pb: 4,
        px: 4.5,
        backgroundColor: getDynamicColor("light2"),
        boxShadow: Y_SHADOWS.box_shadow_1,
      },
      showCloseButton: true,
    },
    [HubModalStatus.EDIT_INTERVIEW_BANK_QUESTIONS]: {
      element: (
        <InterviewBankQuestionsModal
          initQuestions={selectedInterviewBank?.interview_questions}
          bankName={selectedInterviewBank?.name}
          onSubmit={handleUpdateInterviewBankQuestions}
          closeModal={closeModal}
        />
      ),
      wrapperStyles: {
        width: `min(900px, 100%)`,
        maxHeight: "100%",
        pt: 3,
        pb: 4,
        px: 4.5,
        backgroundColor: getDynamicColor("light2"),
        boxShadow: Y_SHADOWS.box_shadow_1,
      },
      showCloseButton: true,
    },
    [HubModalStatus.EDIT_INTERVIEW_BANK_AVAILABLE_HUBS]: {
      element: (
        <EditInterviewBankAvailableHubs
          selectedInterviewBank={selectedInterviewBank}
          allHubs={allHubs}
          onSubmit={handleUpdateInterviewBankAvailableHubs}
          closeModal={closeModal}
        />
      ),
      wrapperStyles: {
        width: "min(650px, calc(100% - 16px))",
        maxHeight: "100%",
        backgroundColor: getDynamicColor("light2"),
      },
      showCloseButton: true,
    },
  };
  return <ModalWrapper modalStatus={modalStatus} modals={modals} closeModal={closeModal} />;
}
