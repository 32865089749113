import React from "react";

import { shouldRenderPricingGate } from "lib-frontend/utils/DbUtils";
import { userDocsService, useUserDocsState } from "lib-frontend/utils/LiveUserDocs";
import { unleashTogglesResolvedPromise } from "lib-frontend/utils/unleash";

export const useShouldRenderPricingGate = (deps: Array<unknown> = []): boolean => {
  const [renderPricing, setRenderPricing] = React.useState<boolean>(false);
  const userDocsReadonly = useUserDocsState()?.readonly;
  React.useEffect(() => {
    const getShouldRenderPricing = async () => {
      await unleashTogglesResolvedPromise;
      await userDocsService.awaitUserDocsResolved();
      const shouldRender = shouldRenderPricingGate();
      setRenderPricing(shouldRender);
    };
    getShouldRenderPricing().catch((er) => {
      console.error(`Error checking if pricing usage should be rendered: ${er}`);
    });
  }, [
    unleashTogglesResolvedPromise,
    userDocsService.awaitUserDocsResolved(),
    userDocsReadonly,
    ...deps,
  ]);
  return renderPricing === null ? false : renderPricing;
};
