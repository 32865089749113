import React from "react";

// UI Utilities/Functions
import { Stack, Typography } from "@mui/material";
import { getDynamicColor } from "lib-frontend/utils/Colors";

function GameLabels({ labels }: { labels: string[] }): React.ReactElement {
  return (
    <Stack
      gap={1}
      direction="row"
      justifyContent="center"
      sx={{
        flexWrap: "wrap",
        mb: 2,
        width: "100%",
      }}
    >
      {labels.map((label) => {
        return (
          <Typography
            variant="h6"
            key={label}
            sx={{
              width: "fit-content",
              color: getDynamicColor("light1"),
              fontWeight: 600,
              fontSize: 14,
              borderRadius: "6px",
              backgroundColor: getDynamicColor("secondary"),
              p: 1,
            }}
          >
            {label}
          </Typography>
        );
      })}
    </Stack>
  );
}

export default GameLabels;
