import React from "react";

// Components
import { Stack, useTheme, useMediaQuery, Typography, Box } from "@mui/material";

// Assets
import { ReactComponent as PoweredByYoodliGraphic } from "../../images/graphics/graphic-powered-by-yoodli.svg";
import YoodliLogoText from "../../images/logos/yoodli_logo.svg";
import { ReactComponent as HowYoodliWorksGraphic } from "../images/graphic-howyoodliworks.svg";

// Utils
import AuthImage from "./AuthImage";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { GetOrgAccessInfoResponse } from "lib-fullstack/api/authApiTypes";
import {
  REFERRAL_PROGRAM_AUTH_IMAGES,
  ReferralProgram,
} from "lib-fullstack/utils/referralProgramUtils";

type AuthWrapperProps = {
  showUsernamePasswordSignUp?: boolean;
  orgAccessInfo: GetOrgAccessInfoResponse;
  isSignUp?: boolean;
};

export default function AuthWrapper({
  children,
  showUsernamePasswordSignUp,
  orgAccessInfo,
  isSignUp,
}: React.PropsWithChildren & AuthWrapperProps): JSX.Element {
  const siteConf = getStaticFullSiteConf();
  const theme = useTheme();
  const isSmallScreen = !useMediaQuery(theme.breakpoints.up(1000));

  const decodedOrgName = decodeURI(orgAccessInfo?.org_name);

  const renderImage = () => {
    if (isSmallScreen) {
      return null;
    } else if (orgAccessInfo?.org_name.includes("Spencer Stuart")) {
      <AuthImage
        image={
          <img src={REFERRAL_PROGRAM_AUTH_IMAGES[ReferralProgram.SPENCER_STUART]} width="468px" />
        }
        lightBackground={false}
      />;
    }
    return (
      <AuthImage
        image={
          <Box sx={{ position: "relative" }}>
            {orgAccessInfo?.org_secondary_logo_url && (
              <Box
                sx={{
                  position: "absolute",
                  width: 180,
                  height: 180,
                  left: 160,
                  top: 120,
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: getDynamicColor("light1"),
                }}
              >
                <img
                  src={orgAccessInfo?.org_secondary_logo_url}
                  style={{ width: 150, height: 150 }}
                />
              </Box>
            )}
            <HowYoodliWorksGraphic />
          </Box>
        }
        lightBackground={false}
      />
    );
  };
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      minHeight="100dvh"
      sx={{
        backgroundColor: getDynamicColor("light1"),
      }}
    >
      <Stack
        textAlign="center"
        gap={3}
        alignItems="center"
        justifyContent="center"
        width="100%"
        sx={{
          py: { xs: showUsernamePasswordSignUp ? 4 : 2, sm: 2 },
          mb: orgAccessInfo?.org_logo_url || siteConf?.logo?.url ? "76px" : 0,
        }}
      >
        <Stack display="flex" justifyContent="center" alignItems="center" gap={3} width="100%">
          <img
            src={orgAccessInfo?.org_logo_url ?? siteConf?.logo?.url ?? YoodliLogoText}
            width={100}
            alt="Yoodli logo"
          />
          {orgAccessInfo?.org_name && (
            <Stack
              sx={{ fontFamily: "poppins", color: getDynamicColor("purple3"), textAlign: "center" }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
                {isSignUp ? "Join" : "Sign in"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                }}
              >
                {decodedOrgName}
              </Typography>
            </Stack>
          )}
          {children}
        </Stack>
        {(orgAccessInfo?.org_logo_url || siteConf?.logo?.url) && (
          <Stack position="absolute" bottom={0} mb={2}>
            <PoweredByYoodliGraphic />
          </Stack>
        )}
      </Stack>
      {renderImage()}
    </Stack>
  );
}
