/**
 * RUNTYPES definitions for integrations.
 */

import {
  Record,
  Static,
  String,
  Boolean,
  Optional,
  Array as RTArray,
  Literal,
  Union,
} from "runtypes";
import { IntegrationTypeEnum } from "lib-fullstack/utils/enums/integrationType";

/**
 * Common base type for all integration instances.
 */
const IntegrationBase = Record({
  /**
   * Integration ID.
   */
  id: String,
  /**
   * ISO8601 creation timestamp.
   */
  createdDate: String,
  /**
   * User ID of the creator.
   */
  createdBy: String,
  /**
   * ISO8601 modification timestamp.
   */
  modifiedDate: String,
  /**
   * User ID of the last modifier.
   */
  modifiedBy: String,
  /**
   * Descriptive user-supplied integration name.
   */
  name: String,
  /**
   * If true, enable debug-level logging for the integration. This can be used to build the mock test data or enable troubleshooting live operations.
   */
  debugLogging: Boolean,
  /**
   * Key value used for indexing in the "orgIntegrations" collection group.
   */
  externalId: Optional(String),
});

/**
 * Common base type for all integration instances.
 */
type IntegrationBase = Static<typeof IntegrationBase>;

/**
 * Integration type for use in test code.
 */
export const TestIntegration = IntegrationBase.extend({
  /**
   * Integration type enum value.
   */
  type: Literal(IntegrationTypeEnum.Test),
  /**
   * Test integration-specific field.
   */
  testField: Optional(String),
});

/**
 * Integration type for use in test code.
 */
export type TestIntegration = Static<typeof TestIntegration>;

/**
 * Integration type for LTI 1.1.
 */
export const Lti11Integration = IntegrationBase.extend({
  /**
   * Integration type enum value.
   */
  type: Literal(IntegrationTypeEnum.LTI_1_1),
  /**
   * ISO8601 timestamp of the last time the OAuth 1.0 key and secret were modified.
   */
  oauth10KeyAndSecretModifiedDate: Optional(String),
  /**
   * Consumer instance GUID generated by the LMS.
   * Provided by the Yoodli admin configuring the integration.
   */
  consumerInstanceGuid: Optional(String),
  /**
   * Host name for the LMS server which will be calling into the Yoodli
   * integration. This is required so we can validate URLs provided by the LMS
   * server before calling them. This protects against SSRF.
   */
  consumerHostName: Optional(String),
});

/**
 * Integration type for LTI 1.1.
 */
export type Lti11Integration = Static<typeof Lti11Integration>;

/**
 * Integration type for SCORM 1.2.
 */
export const Scorm12Integration = IntegrationBase.extend({
  /**
   * Integration type enum value.
   */
  type: Literal(IntegrationTypeEnum.SCORM_1_2),
  /**
   * Host name allow-list for CSP frame-ancestors policy.
   */
  hostNames: Optional(RTArray(String)),
});

/**
 * Integration type for SCORM 1.2.
 */
export type Scorm12Integration = Static<typeof Scorm12Integration>;

/**
 * Union of all integration types.
 */
export const IntegrationData = Union(TestIntegration, Lti11Integration, Scorm12Integration);

/**
 * Union of all integration types.
 */
export type IntegrationData = Static<typeof IntegrationData>;
