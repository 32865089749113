import React from "react";

// Components
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  ExpandCircleDownRounded as ExpandCircleDownRoundedIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  TextField,
  Stack,
  Typography,
} from "@mui/material";

// Utils
import { YoodliSelectOption, setBorderColor } from "./YoodliSelect";
import { getDynamicColor } from "lib-frontend/utils/Colors";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type YoodliMultiSelectProps = {
  options: YoodliSelectOption[];
  placeholder?: string;
  isCheckboxSelect?: boolean;
};

export const YoodliMultiSelect = (
  props: Partial<AutocompleteProps<YoodliSelectOption, boolean, boolean, boolean>> &
    YoodliMultiSelectProps
): JSX.Element => {
  const { options, placeholder, isCheckboxSelect, onChange } = props;
  // remove invalid dom attributes before spreading props on Autocomplete
  const autocompleteProps = { ...props };
  delete autocompleteProps.isCheckboxSelect;

  const [isOpen, setIsOpen] = React.useState<boolean>(props.open ?? false);

  const value = (props.value as YoodliSelectOption[]) ?? [];

  const renderOption = (optionProps, option: YoodliSelectOption) => {
    return (
      <li {...optionProps} key={option.value}>
        <Stack
          direction="row"
          sx={{ alignItems: "center", justifyContent: "space-between", width: "100%" }}
        >
          <Stack
            gap={1}
            direction="row"
            sx={{
              alignItems: "center",
              width: "100%",
            }}
          >
            {isCheckboxSelect && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={value.map((v) => v.value).indexOf(option.value) > -1}
              />
            )}
            {option.JSXOverride
              ? (option as YoodliSelectOption).JSXOverride
              : (option as YoodliSelectOption).label}
          </Stack>
          {option.labelTag && (
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "12px",
                color: getDynamicColor("dark5"),
                whiteSpace: "nowrap",
                ...option.labelTagSx,
              }}
            >
              {option.labelTag}
            </Typography>
          )}
        </Stack>
      </li>
    );
  };

  return (
    <Autocomplete
      {...autocompleteProps}
      multiple={props.multiple ?? true}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      value={value}
      open={isOpen}
      onChange={onChange}
      isOptionEqualToValue={(option: YoodliSelectOption, value: YoodliSelectOption) =>
        option.value === value.value
      }
      options={options}
      disableCloseOnSelect
      disablePortal={props.disablePortal ?? true}
      renderOption={autocompleteProps.renderOption ?? renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={value?.length === 0 ? placeholder : undefined}
          sx={{
            fontSize: 16,
            fontWeight: 500,
            border: "none",
            fieldset: {
              border: "none",
            },
          }}
        />
      )}
      popupIcon={
        <ExpandCircleDownRoundedIcon
          sx={{
            color: `${props.disabled ? getDynamicColor("dark4") : getDynamicColor("primary")}`,
            transform: `${props.open ? "rotate(180deg)" : "rotate(0)"}`,
          }}
        />
      }
      componentsProps={{
        ...props.componentsProps,
        paper: {
          sx: {
            borderRadius: "8px !important",
            boxShadow: "1px 2px 5px 0px rgba(33, 37, 41, 0.16)",
            p: 1,
            mt: 1,
            fontFize: 16,
            fontFamily: "poppins",
            fontWeight: 500,
          },
        },
      }}
      sx={{
        fontFamily: "poppins",
        minHeight: 57,
        minWidth: 250,
        width: "100%",
        flexGrow: 1,
        borderRadius: "4px",

        border: setBorderColor(props.disabled, isOpen),
        ul: {
          py: 0,
        },

        ".MuiChip-root": {
          borderRadius: "4px",
          backgroundColor: getDynamicColor("dark2"),
          color: getDynamicColor("dark5"),
          fontWeight: 500,
          fontSize: 14,
          ".MuiChip-deleteIcon": {
            color: getDynamicColor("dark5"),
          },
        },
        ...props.sx,
      }}
    />
  );
};
