import React from "react";

// Components
import { Stack, Typography } from "@mui/material";
import { IOSSwitch } from "components/Settings/Settings";

// Utils
import { WizardSubTitle, WizardTitle } from "../../../../../Wizard/WizardTitles";
import { TEXTFIELD_WIDTH } from "../CoachBotUploadContent/CoachBotUploadContent";
import { getDynamicColor } from "lib-frontend/utils/Colors";

type CoachBotSetDefaultProps = {
  setMakeDefault: React.Dispatch<React.SetStateAction<boolean>>;
  makeDefault: boolean;
};

export const CoachBotSetDefault = ({
  makeDefault,
  setMakeDefault,
}: CoachBotSetDefaultProps): JSX.Element => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        position: "relative",
        px: { xs: 2, md: 0 },
        pt: { xs: 3, md: 6 },
        pb: { xs: 6, md: 10 },
      }}
      gap={2}
    >
      <Stack
        sx={{
          width: { xs: "unset", md: TEXTFIELD_WIDTH },
        }}
        gap={0.5}
      >
        <WizardTitle
          overrideSx={{
            px: { xs: 1, md: 2 },
          }}
        >
          Assign Coach Bot as default
        </WizardTitle>
        <WizardSubTitle
          overrideSx={{
            px: { xs: 1, md: 2 },
          }}
        >
          This Coach Bot will be the default for your organization and apply to every recording. You
          can turn this option off if you would prefer to set a different one later.
        </WizardSubTitle>
        <Stack
          direction="row"
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 2,
            px: { xs: 1, md: 2 },
            pt: 3,
          }}
        >
          <Typography component="span" sx={{ color: getDynamicColor("purple3"), fontWeight: 600 }}>
            Make it the default Coach Bot
          </Typography>
          <IOSSwitch
            sizeVariant="small"
            checked={makeDefault}
            onChange={(e) => {
              setMakeDefault(e.target.checked);
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
