import React from "react";

// Components
import { Box, Stack } from "@mui/material";

// Utils
import { WizardStep } from "../WizardTypes";
import { ProgressMarker } from "./ProgressMarker";
import { DynamicColorType, getDynamicColor } from "lib-frontend/utils/Colors";
import { intersperseSpacer } from "utils/Utilities";

export type ProgressMarkerColorsProps = {
  active?: DynamicColorType;
  inactive?: DynamicColorType;
  success?: DynamicColorType;
};

type ProgressTrackerProps = {
  currStepId: string;
  currStepIndex: number;
  steps: WizardStep[];
  colors?: ProgressMarkerColorsProps;
};

const DEFAULT_PROGRESS_TRACKER_COLORS: ProgressMarkerColorsProps = {
  active: "primary",
  inactive: "dark3",
  success: "greenSuccess",
};

type DisplayStep = {
  label: string;
  stepsIncluded: WizardStep[];
};

export const ProgressTracker = ({
  currStepId,
  currStepIndex,
  steps,
  colors = DEFAULT_PROGRESS_TRACKER_COLORS,
}: ProgressTrackerProps): JSX.Element => {
  const displaySteps = steps
    .reduce<DisplayStep[]>((acc, step) => {
      const prevStep = acc[acc.length - 1];
      // if this step has the same label as the previous step
      if (prevStep && prevStep.label === step.label) {
        // just add the step to the stepsIncluded array
        prevStep.stepsIncluded.push(step);
      }
      // otherwise create a new DisplayStep
      else if (step.label !== undefined) {
        acc.push({ label: step.label, stepsIncluded: [step] });
      }

      return acc;
    }, [])
    .filter((step) => !step.stepsIncluded.every((x) => x.hideProgressMarker));

  const stepsWithSpacers = intersperseSpacer(displaySteps, (key) => (
    <Box
      key={key}
      sx={{
        height: "1px",
        flexGrow: 1,
        backgroundColor: getDynamicColor("dark4"),
        borderRadius: "1px",
      }}
    />
  ));
  const handleCheckStepComplete = (step: DisplayStep) => {
    if (currStepId)
      return step.stepsIncluded.every((stepInc) => {
        return (
          stepInc.validate() &&
          (step.stepsIncluded[step.stepsIncluded.length - 1].stepIdx < currStepIndex ||
            !step.stepsIncluded.find((step) => step.stepIdx === currStepIndex))
        );
      });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={1}
      maxWidth="md"
      sx={{
        width: "100%",
        mx: "auto",
        px: 3.5,
        pb: 3,
        pt: 2,
      }}
    >
      {stepsWithSpacers.map((step, i) => {
        // render the spacers
        if (i % 2 !== 0) {
          return step(i);
        }
        const currStepWithSpacersIdx = stepsWithSpacers.findIndex(
          (step) =>
            step?.stepsIncluded &&
            !!step?.stepsIncluded.find((innerStep) => innerStep?.id === currStepId)
        );
        if (step.stepsIncluded.some((x) => x.hideProgressMarker)) {
          return null;
        }
        return (
          <ProgressMarker
            key={step.label}
            label={step.label}
            isComplete={
              steps[currStepIndex]?.progressTrackerComplete ||
              (currStepWithSpacersIdx >= i && handleCheckStepComplete(step))
            }
            isActive={step.id === currStepId || step.stepsIncluded.find((x) => x.id === currStepId)}
            currStepId={currStepId}
            stepsIncluded={step.stepsIncluded}
            colors={colors}
            hideMultiStepCount={step.stepsIncluded.some((s) => s.initialStep)}
          />
        );
      })}
    </Stack>
  );
};
