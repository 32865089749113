// Components
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Stack, Typography, Box, Button, Link } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Assets
import { ReactComponent as StarIcon } from "images/icons/icon-star.svg";
import { ReactComponent as UsersIcon } from "images/icons/icon-users.svg";

// Utils
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { HubData } from "lib-frontend/utils/orgUtils";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

type HubDetailCardProps = {
  hub?: HubData;
  onClick?: () => void;
  disableCreate?: boolean;
};

export const HubDetailCard = ({ hub, onClick, disableCreate }: HubDetailCardProps): JSX.Element => {
  const disableTooltip = !!hub || !disableCreate;
  return (
    <YoodliTooltip
      title={
        <>
          You've reached the max amount of groups. If you'd like to add more, please{" "}
          <Link
            href={WebServerExternalPath.SUPPORT}
            target="_blank"
            sx={{ textDecorationLine: "underline", cursor: "pointer" }}
          >
            contact support
          </Link>
          .
        </>
      }
      disableHoverListener={disableTooltip}
      disableTouchListener={disableTooltip}
      disableFocusListener={disableTooltip}
    >
      <Button
        onClick={onClick}
        disabled={!hub && disableCreate}
        sx={{
          textAlign: hub ? "left" : "center",
          alignItems: hub ? "flex-start" : "center",
          justifyContent: hub ? "flex-start" : "center",
          p: 2,
          width: { xs: "165px", md: "215px" },
          minWidth: { xs: "165px", md: "215px" },
          minHeight: { xs: "145px", md: "125px" },
          borderRadius: "8px",
          boxShadow: Y_SHADOWS.box_shadow_1,
          border: `1px solid ${getDynamicColor("dark2")}`,
          ":hover": {
            border: `1px solid ${getDynamicColor("purple2")}`,
            backgroundColor: getDynamicColor("light2"),
          },
          ":disabled": {
            backgroundColor: getDynamicColor("dark2"),
            boxShadow: Y_SHADOWS.box_shadow_1,
          },
        }}
      >
        <Stack
          gap={1}
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
          }}
        >
          {hub ? (
            <>
              <Stack
                direction="row"
                gap={1}
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <Stack direction="row" gap={1} sx={{ alignItems: "center", width: "100%" }}>
                  <Box sx={{ width: "20px", height: "20px" }}>
                    <UsersIcon />
                  </Box>
                  <Typography
                    sx={{
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {hub.name}
                  </Typography>
                </Stack>
                {hub.isDefault && (
                  <YoodliTooltip title="If members are not assigned to a group upon joining the organization, they will automatically be placed in this default group. All members must be part of at least one group. This default group cannot be deleted.">
                    <StarIcon style={{ fill: getDynamicColor("dark4") }} />
                  </YoodliTooltip>
                )}
              </Stack>
              <Stack
                gap={0.5}
                sx={{
                  width: "100%",
                  color: getDynamicColor("dark4"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                <Typography>
                  Created{" "}
                  {getHumanReadableDate(hub.creationDate, {
                    year: "2-digit",
                    month: "numeric",
                    day: "numeric",
                  })}
                </Typography>
                <Typography>
                  {hub.numMembers} member{hub.numMembers === 1 ? "" : "s"}
                </Typography>
                <Typography>
                  {hub.numCourses} course{hub.numCourses === 1 ? "" : "s"}
                </Typography>
              </Stack>
            </>
          ) : (
            <>
              <AddCircleOutlineIcon
                sx={{
                  color: disableCreate ? getDynamicColor("dark2Text") : getDynamicColor("primary"),
                }}
              />
              <Typography
                sx={{
                  color: disableCreate ? getDynamicColor("dark2Text") : getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Create a new group
              </Typography>
            </>
          )}
        </Stack>
      </Button>
    </YoodliTooltip>
  );
};
