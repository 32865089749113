// Components
/* eslint-disable */
import { Typography, SxProps } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type Save40Props = {
  isAnnual?: boolean;
  sx?: SxProps;
};

export const Save40 = ({ isAnnual, sx }: Save40Props): any => {
  return (
    <Typography
      sx={{
        fontSize: 16,
        fontWeight: 700,
        fontFamily: "poppins",
        p: 1,
        px: 1.5,
        height: "fit-content",
        width: "fit-content",
        lineHeight: 1,
        borderRadius: "4px",
        backgroundColor: getDynamicColor(isAnnual ? "greenSuccessLight" : "dark2"),
        color: getDynamicColor(isAnnual ? "greenWarning" : "dark4"),
        ...(sx ?? {}),
      }}
    >
      SAVE 40%
    </Typography>
  );
};
