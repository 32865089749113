// Components
import { Box, SxProps, TextField, TextFieldProps, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

export const YOODLI_TEXTFIELD_MAX_ROWS = 10;
export interface YoodliTextfieldProps {
  maxChars?: number;
  hideCharCount?: boolean;
  fadeInCharCountPct?: number;
}

enum MaxCharDisplayPosition {
  Right = "right",
  BottomLeft = "bottomLeft",
}

type MaxCharDisplayComponentProps = {
  value: string;
  maxChars: number;
  position: MaxCharDisplayPosition;
  isShowing: boolean;
};

const MaxCharDisplayComponent = ({
  value,
  maxChars,
  position,
  isShowing,
}: MaxCharDisplayComponentProps) => {
  const positionStyles = {
    [MaxCharDisplayPosition.Right]: {
      right: 16,
      bottom: "50%",
      transform: "translateY(50%)",
    },
    [MaxCharDisplayPosition.BottomLeft]: {
      left: 16,
      bottom: 8,
    },
  };

  return (
    <Typography
      sx={{
        opacity: isShowing ? 1 : 0,
        transition: "opacity 0.2s ease-in-out",
        fontSize: 12,
        color: getDynamicColor("dark4"),
        position: "absolute",
        zIndex: 2,
        fontFamily: "poppins",
        fontWeight: 600,
        ...positionStyles[position],
      }}
    >
      {value?.slice(0, maxChars)?.length ?? 0}/{maxChars}
    </Typography>
  );
};

export default function YoodliTextfield(props: TextFieldProps & YoodliTextfieldProps): JSX.Element {
  let counterCurrentlyShowing = true;
  if (props?.fadeInCharCountPct) {
    counterCurrentlyShowing =
      (props?.value as string).length > props.maxChars * (props.fadeInCharCountPct / 100);
  }
  const showCharCounter = props.maxChars && !props.hideCharCount;
  const textfieldProps = { ...props };
  delete textfieldProps.maxChars;
  delete textfieldProps.hideCharCount;
  delete textfieldProps.fadeInCharCountPct;

  let rowCount = props?.minRows ? Number(props.minRows) : 1;
  rowCount = props?.rows ? Number(props.rows) : rowCount;
  let fontSize = null;
  if (props?.sx) {
    ({ fontSize } = props.sx as { fontSize?: string });
  }
  const maxCharDisplayPosition =
    props?.multiline || rowCount > 1
      ? MaxCharDisplayPosition.BottomLeft
      : MaxCharDisplayPosition.Right;

  const positionSx: SxProps = {
    pr:
      props?.maxChars &&
      maxCharDisplayPosition === MaxCharDisplayPosition.Right &&
      counterCurrentlyShowing
        ? "64px !important"
        : undefined,
    pb:
      props?.maxChars &&
      maxCharDisplayPosition === MaxCharDisplayPosition.BottomLeft &&
      counterCurrentlyShowing
        ? "32px !important"
        : undefined,
  };
  const renderTextField = () => {
    return (
      <TextField
        {...textfieldProps}
        variant={props.variant ?? "outlined"}
        maxRows={props.maxRows ?? YOODLI_TEXTFIELD_MAX_ROWS}
        fullWidth={props.fullWidth ?? true}
        helperText={""}
        inputProps={{
          maxLength: props?.maxChars,
          sx: {
            pr: props?.maxChars && 0,
            "::placeholder": {
              color: getDynamicColor("dark4"),
              opacity: 1,
            },
          },
          ...props?.inputProps,
        }}
        InputProps={{
          ...props?.InputProps,
          sx: {
            borderRadius: "4px",
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontWeight: 400,
            mr: 1,
            transition: "padding-bottom 0.2s ease-in-out",
            "::placeholder": {
              color: getDynamicColor("dark4"),
              fontWeight: 500,
            },
            fontSize: fontSize ?? "14px",
            ...positionSx,
            ...props?.InputProps?.sx,
          },
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline legend": {
            fontFamily: "Poppins",
            fontSize: "0.7rem",
          },
          borderRadius: "4px",
          backgroundColor: getDynamicColor("light1"),
          ...props?.sx,
        }}
        {...(props.rows
          ? { rows: props.rows }
          : { minRows: props.minRows, maxRows: props.maxRows ?? YOODLI_TEXTFIELD_MAX_ROWS })}
      />
    );
  };

  return (
    <Box sx={{ width: props.fullWidth === false ? "auto" : "100%", ...props?.sx }}>
      <Box
        sx={{
          width: props.fullWidth === false ? "auto" : "100%",
          position: "relative",
        }}
      >
        {showCharCounter && (
          <MaxCharDisplayComponent
            value={props?.value?.toString() || ""}
            maxChars={props?.maxChars}
            position={maxCharDisplayPosition}
            isShowing={counterCurrentlyShowing}
          />
        )}

        {renderTextField()}
      </Box>
      {props?.helperText && (
        <Box
          sx={{
            color: getDynamicColor("redError"),
            fontFamily: "Poppins",
            fontSize: "12px",
            mx: 2,
            mt: 0.5,
          }}
        >
          {props?.helperText}
        </Box>
      )}
    </Box>
  );
}
