import React from "react";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { Dialog, IconButton, Stack, Typography } from "@mui/material";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { OrgDetailCard, OrgDetailCardLabel } from "./AdminConfigOrgDetails";
import { UpdateQuotasForm } from "./UpdateQuotaForm";
import { UpdateFeatureFlagsForm } from "./UpdateFeatureFlagsForm";
import { UpdateOwnerForm } from "./UpdateOwnerForm";
import { UpdateSubscriptionForm } from "./UpdateSubscriptionForm";

type UpdateModalProps = {
  orgId: string;
  card: OrgDetailCard;
  open: boolean;
  close: () => void;
  setRerenderKey: React.Dispatch<React.SetStateAction<number>>;
};

export type UpdateOrgDetailCardProps = {
  orgId: string;
  card: OrgDetailCard;
  close: () => void;
  setRerenderKey: React.Dispatch<React.SetStateAction<number>>;
};

export const UpdateOrgModal = ({
  orgId,
  card,
  close,
  open,
  setRerenderKey,
}: UpdateModalProps): React.ReactElement => {
  // Hooks
  const { modalStyles } = useNavDrawerOffset();

  // TODO: add other editable forms here
  const renderForm = () => {
    switch (card?.label) {
      case OrgDetailCardLabel.SUBSCRIPTION:
        return (
          <UpdateSubscriptionForm
            orgId={orgId}
            card={card}
            setRerenderKey={setRerenderKey}
            close={close}
          />
        );
      case OrgDetailCardLabel.OWNER_ADMINS:
        return (
          <UpdateOwnerForm
            orgId={orgId}
            card={card}
            setRerenderKey={setRerenderKey}
            close={close}
          />
        );
      case OrgDetailCardLabel.QUOTAS:
        return (
          <UpdateQuotasForm
            orgId={orgId}
            card={card}
            setRerenderKey={setRerenderKey}
            close={close}
          />
        );
      case OrgDetailCardLabel.FEATURE_FLAGS:
        return (
          <UpdateFeatureFlagsForm
            orgId={orgId}
            card={card}
            setRerenderKey={setRerenderKey}
            close={close}
          />
        );
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          p: 3,
          borderRadius: "12px",
          width: { xs: "100%", sm: "min(100%, 500px)", lg: "min(100%, 600px)" },
          maxWidth: { xs: "calc(100% - 24px) !important", md: "calc(100% - 48px) !important" },
          maxHeight: { xs: "calc(100% - 24px) !important", md: "calc(100% - 48px) !important" },
          m: { xs: 1.5, md: 3 },
          ...modalStyles,
        },
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          zIndex: 2,
          top: 12,
          right: 12,
          p: 0.5,
        }}
        onClick={close}
      >
        <CloseIcon
          sx={{
            width: 18,
            height: 18,
          }}
        />
      </IconButton>
      <Stack
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          component="h2"
          sx={{
            fontFamily: "poppins",
            fontWeight: 600,
            fontSize: { xs: 16, md: 18 },
            width: "100%",
            textAlign: "center",
          }}
        >
          Update {card?.label}
        </Typography>

        <Stack width="100%" gap={2} p={3}>
          {renderForm()}
        </Stack>
      </Stack>
    </Dialog>
  );
};
