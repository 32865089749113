import React from "react";

// Components
import { Button, Grid, Typography } from "@mui/material";
import { downloadPoodli } from "components/Onboarding/OnboardingData";
import PoodliDownloadModal from "components/PoodliDownload/PoodliDownloadModal";

// Utils
import { PoodliDownloadedWheres } from "lib-fullstack/utils/productAnalyticEvents";

export const DownloadDesktopApp = (): JSX.Element => {
  const [showPoodliDownloadInstructionModal, setShowPoodliDownloadInstructionModal] =
    React.useState<boolean>(false);

  const handleDownloadPoodli = () => {
    setShowPoodliDownloadInstructionModal(true);
    downloadPoodli(PoodliDownloadedWheres.SETTINGS);
  };

  return (
    <div style={{ zIndex: 2 }}>
      <Typography variant="h2" sx={{ fontWeight: 700, fontFamily: "Poppins" }}>
        Download Desktop App
      </Typography>
      <Grid container alignItems="center" justifyContent="space-between" zIndex={2}>
        <Grid item sx={{ mb: { xs: 4, md: 2 } }} flexWrap={"wrap"} xs={10} md={6} lg={6}>
          <Typography
            className="font-size-label"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: 2,
              mt: "16px",
              maxWidth: { md: "min(80%, 750px)" },
            }}
          >
            Get private, real-time suggestions in your online meetings, interviews, presentations
            and more. The best part? Yoodli only analyzes your voice, so no one else will know
            you're using it.
          </Typography>
        </Grid>
        <Grid item lg={6} sx={{ mx: { xs: "auto" }, mb: 4 }}>
          <Button
            onClick={handleDownloadPoodli}
            color="primary"
            variant="contained"
            sx={{
              alignContent: "left",
              whiteSpace: "nowrap",
              width: "fit-content",
              px: 2,
            }}
          >
            Download desktop app
          </Button>
        </Grid>
      </Grid>
      <PoodliDownloadModal
        show={showPoodliDownloadInstructionModal}
        handleClose={() => setShowPoodliDownloadInstructionModal(false)}
        downloadAgain={downloadPoodli}
        where={PoodliDownloadedWheres.SETTINGS}
      />
    </div>
  );
};
