import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import { Button, Stack, Typography } from "@mui/material";

// Utils
import EmptyVideoView from "./EmptyVideoView";
import VideoCollection from "../VideoCollection";
import SectionOptionsMenu from "../SectionOptionsMenu";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { DemoVideoResponse } from "lib-fullstack/api/hubApiTypes";
import { HubModalStatus } from "utils/Enums";
import { WebServerInternalPath } from "utils/paths";
import { OrgContentQueryParams } from "lib-fullstack/utils/queryParams";
import { OrgSettingsTabs } from "lib-frontend/utils/orgUtils";

type DemoVideosSectionProps = {
  setModalStatus?: (modalStatus: HubModalStatus) => void;
  demoVideos: DemoVideoResponse[];
  selectedOrgId: string;
  isHubAdmin?: boolean;
  showManageContentButton?: boolean;
};

export default function DemoVideosSection({
  setModalStatus,
  demoVideos,
  selectedOrgId,
  isHubAdmin,
  showManageContentButton,
}: DemoVideosSectionProps): JSX.Element {
  const navigate = useNavigate();
  const [dropdownAnchorEl, setDropdownAnchorEl] = React.useState<HTMLElement | null>(null);
  const menuItems = React.useMemo(
    () => [
      {
        title: "Upload videos",
        onClick: () => {
          setDropdownAnchorEl(null);
          setModalStatus(HubModalStatus.UPLOAD_DEMO_VIDEO);
        },
      },
      {
        title: "Manage videos",
        onClick: () => {
          setDropdownAnchorEl(null);
          setModalStatus(HubModalStatus.EDIT_DEMO_VIDEOS);
        },
      },
    ],

    []
  );

  const videoCount = `${demoVideos?.length} video${demoVideos?.length > 1 ? "s" : ""}`;
  return (
    <Stack gap={3}>
      <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "baseline" }}>
        <Stack
          direction="row"
          sx={{ flexWrap: "wrap", alignItems: "baseline", fontFamily: "poppins", fontWeight: 700 }}
        >
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              mr: 2,
            }}
          >
            Welcome videos
          </Typography>
          {demoVideos?.length > 0 && (
            <Typography
              sx={{
                fontSize: { xs: 14, md: 16 },
                color: getDynamicColor("dark4"),
              }}
            >
              {videoCount}
            </Typography>
          )}
        </Stack>
        <Stack>
          {isHubAdmin && (
            <SectionOptionsMenu
              menuItems={menuItems}
              anchorEl={dropdownAnchorEl}
              setAnchorEl={setDropdownAnchorEl}
            />
          )}
          {showManageContentButton && (
            <Button
              variant="text"
              onClick={() => {
                const qp = new URLSearchParams({
                  [OrgContentQueryParams.TAB]: OrgSettingsTabs.VIDEOS_COURSES,
                });
                navigate({ pathname: WebServerInternalPath.ORG_CONTENT, search: qp.toString() });
              }}
            >
              Manage
            </Button>
          )}
        </Stack>
      </Stack>
      {demoVideos?.length ? (
        <VideoCollection videos={demoVideos} selectedOrgId={selectedOrgId} courseId={undefined} />
      ) : (
        <EmptyVideoView
          isHubAdmin={isHubAdmin}
          handleAddVideo={() => setModalStatus(HubModalStatus.UPLOAD_DEMO_VIDEO)}
        />
      )}
    </Stack>
  );
}
