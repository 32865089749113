/**
 * RUNTYPES definitions for DB documents.
 * This is our primary definition of the names/fields/types of the documents
 *   in our DB. The tighter we can get this (especially, fewer 'optional'
 *   fields), the better.
 */

// #region Imports

import { ChecklistKey } from "lib-fullstack/checklistTypes";
import {
  oneOf,
  RTOBQ1Option,
  RTAnalyticProcessingState,
  RTMaterialType,
  RTAutoGenerationState,
  RTLanguage,
  RecordingSupportedLanguage,
} from "lib-fullstack/utils/enums";
import { RTReferralProgram } from "lib-fullstack/utils/referralProgramUtils";
import {
  UsagePlanType,
  UsagePlanDuration,
  PricingExperiment,
} from "lib-fullstack/utils/pricingTypes";
import { NullableOptional, RTStringEnum, RTNumericEnum } from "lib-fullstack/utils/runtypesHelpers";
import {
  Boolean,
  Dictionary,
  Literal,
  Null,
  Number,
  Optional,
  Array as RTArray,
  Record,
  Static,
  String,
  Union,
  Unknown,
} from "runtypes";

// #endregion Imports

// #region Default region
// Default region is to cover not categorized types and hide them when working
// on a specific region of this big file. It is recommended to move out related
// types into a dedicated region opportunistically.
/**
 * 'ID' types. These are all basic types, but it's helpful
 * to alias them for clarity.
 */
export const SiteID = String; //String.withBrand("SiteID");
export type SiteID = Static<typeof SiteID>;
// Would be great to make informative, e.g., annotation.type + N,
//   instead of some random UUID.
//   ^ They are the above but I'm not sure how to enforce that type-wise
export const AnnotationID = String; //.Or(Number).withBrand("AnnotationID"); // SHOULD ONLY BE ONE TYPE!!!
export type AnnotationID = Static<typeof AnnotationID>;
export const SpeechCommentID = String; //.withBrand("SpeechCommentID");
export type SpeechCommentID = Static<typeof SpeechCommentID>;
export const SpeakerID = Number; //.withBrand("SpeakerID");
export const UserID = String; //.withBrand("UserID");
export type UserID = Static<typeof UserID>;
export const SpeechID = String; //.withBrand("SpeechID");
export type SpeechID = Static<typeof SpeechID>;

/**
 * Abstract DB object with any bookkeeping or universal fields.
 */
export const AbstractDoc = Record({
  // `extra` is a catch-all field for experimentation/db maintenance/etc.
  // Do not use this in frontend code or really depend on it in any
  //  way; is meant as a 'wild-west' field. Real data should always go
  //  in an official part of the schema.
  extra: Optional(Dictionary(Unknown)),
});

export const CalendarAuthMethod = oneOf("google_calendar", "outlook");
export type CalendarAuthMethod = Static<typeof CalendarAuthMethod>;

export const CalendarResponseStatus = oneOf(
  "needsAction",
  "notResponded",
  "none",
  "declined",
  "tentative",
  "tentativelyAccepted",
  "accepted",
  "organizer"
);
export type CalendarResponseStatus = Static<typeof CalendarResponseStatus>;

export const CalendarAttendee = Record({
  name: Optional(Union(String, Null)),
  emailAddress: Optional(Union(String, Null)),
  organizer: Optional(Union(Boolean, Null)),
  responseStatus: Optional(CalendarResponseStatus),
  calendarUserId: Optional(Union(String, Null)), // the external service's user ID
  authMethod: Optional(CalendarAuthMethod),
});
export type CalendarAttendee = Static<typeof CalendarAttendee>;

export const RecallBotStatus = oneOf(
  "ready",
  "joining_call",
  "in_waiting_room",
  "in_call_not_recording",
  "in_call_recording",
  "done",
  "fatal",
  "recording_permission_denied"
);
export type RecallBotStatus = Static<typeof RecallBotStatus>;

export const RecallBotJoinMethod = oneOf(
  "web_ui_link_paste",
  "google_calendar",
  "outlook_calendar",
  "zoom_app_click"
);
export type RecallBotJoinMethod = Static<typeof RecallBotJoinMethod>;

export const RecallGetBotResponseBody = Record({
  id: String,
  video_url: Optional(String),
  media_retention_end: Optional(String),
  status_changes: RTArray(
    Record({
      code: RecallBotStatus,
      message: Optional(String),
      created_at: String,
    })
  ),
  meeting_metadata: Optional(Record({ title: String })),
  meeting_participants: Optional(RTArray(Unknown)),
  speaker_timeline: Optional(
    Record({
      timeline: RTArray(
        Record({
          users: RTArray(
            Record({
              zoom_userid: Optional(Number),
              email_address: Optional(String),
              username: Optional(String),
              avatar_url: Optional(String),
              user_id: Optional(Number),
              multiple_people: Optional(Boolean),
              client_type: Optional(Number),
            })
          ),
          ts: Number,
        })
      ),
    })
  ),
  detail: Optional(String),
});
export type RecallGetBotResponseBody = Static<typeof RecallGetBotResponseBody>;

export enum MeetingPlatform {
  ZOOM = "zoom",
  GOOGLE_MEET = "google_meet",
  MICROSOFT_TEAMS = "microsoft_teams",
  OTHER = "other",
}

export const RecallZoomBot = AbstractDoc.extend({
  botId: String,
  userId: UserID,
  status: RecallBotStatus,
  active: Boolean,
  statusMessage: Optional(String),
  createdAt: String,
  updatedAt: Optional(String),
  speechRefPath: String,
  meetingTitle: Optional(String),
  fullBotStatus: Optional(RecallGetBotResponseBody),
  inviteFullUrl: Optional(String),
  meetingPlatform: Optional(RTStringEnum(MeetingPlatform)),
  meetingId: Optional(String),
  emailNotificationJob: Optional(String),
  joinMethod: Optional(RecallBotJoinMethod),
  // We could expand this field depending on what kind of analytics we'd like to store in db from
  // Gcal meetings (https://developers.google.com/calendar/api/v3/reference/events)
  eventDetails: Optional(
    Record({
      eventId: String,
      eventName: String,
      startTime: String,
      joinOverride: Union(Boolean, Null),
      attendees: Optional(Union(RTArray(CalendarAttendee), Null)),
    })
  ),
});

export type RecallZoomBot = Static<typeof RecallZoomBot>;

export const BotAutoJoinPreference = oneOf("none", "organizer_only", "all", "smart");

export type BotAutoJoinPreference = Static<typeof BotAutoJoinPreference>;

export enum CalendarMode {
  ZOODLI = "zoodli",
  POODLI = "poodli",
}

export const Calendar = AbstractDoc.extend({
  service: oneOf("gcal", "outlook"),
  calendarId: String,
  createDate: String,
  defaultJoin: BotAutoJoinPreference,
  refreshTokenEncrypted: String,
  webhookResourceId: Optional(String),
  webhookExpirationDate: Optional(String),
  lastRefresh: Optional(String),
  inactive: Optional(Boolean),
  email: Optional(String), // Outlook calendarId is different from email
  lockAcqUntil: Optional(String),
  mode: RTStringEnum(CalendarMode),
});

export type Calendar = Static<typeof Calendar>;

export const CalendarEvent = AbstractDoc.extend({
  eventId: String,
  isOrganizer: Union(Boolean, Null),
  joinOverride: Optional(Union(Boolean, Null)),
  meetingURL: Optional(String),
  name: Optional(Union(String, Null)),
  startTime: Optional(String),
  endTime: Optional(String),
  lockAcqUntil: Optional(String), // lock acquired by a process until this time.
  joined: Optional(Boolean),
  skipped: Optional(Boolean),
  taskName: Optional(String),
  scheduledBotId: Optional(String),
  scheduledBotTime: Optional(String),
  lastUpdated: Optional(String),
  attendees: Optional(Union(RTArray(CalendarAttendee), Null)),
  meetingPlatform: Optional(RTStringEnum(MeetingPlatform)),
  recurringEventId: Optional(Union(String, Null)),
  precalcContextType: Optional(String),
  talkingPointTemplate: Optional(String),

  // DEPRECATED
  allDay: Optional(Boolean),
});

export type CalendarEvent = Static<typeof CalendarEvent>;

export const ICalendarEvent = AbstractDoc.extend({
  id: String,
  name: Union(String, Null),
  status: CalendarResponseStatus,
  created: String,
  updated: String,
  description: String,
  location: String,
  isOrganizer: Union(Boolean, Null),
  start: Record({
    date: Optional(String),
    dateTime: Optional(String),
    timeZone: Optional(String),
  }),
  end: Record({
    date: Optional(String),
    dateTime: Optional(String),
    timeZone: Optional(String),
  }),
  attendees: Union(RTArray(CalendarAttendee), Null),
  entryPoints: Optional(
    RTArray(
      Record({
        entryPointType: String,
        uri: String,
      })
    )
  ),
  recurringEventId: Optional(String),

  // DEPRECATED
  isAllDay: Optional(Boolean),
});

export type ICalendarEvent = Static<typeof ICalendarEvent>;

export const CalendarRecurrences = AbstractDoc.extend({
  joinOverride: Union(Boolean, Null),
  lastUpdated: String,
});

export type CalendarRecurrences = Static<typeof CalendarRecurrences>;

export const OffloadedField = Record({
  hash: String,
  offloadedTo: String,
  offloadPath: String,
});
export type OffloadedField = Static<typeof OffloadedField>;

/** Used to record user feedback given via the UI. Details
 * on this class are TBD.
 */
export const UserFeedback = AbstractDoc.extend({
  labeledById: String,
  labeledByDate: String,
  // Feedback fields TBD.
});

/** Output from Stt transcription (e.g., Rev.ai). Immutable. */
export const SttTranscriptElement = AbstractDoc.extend({
  startS: Number, // ts?
  endS: Number, // end_ts?
  value: String,
  confidence: Optional(Number),
});
export type SttTranscriptElement = Static<typeof SttTranscriptElement>;

// this and above _should_ be the same type (need migration)
// TODO #12836 cleanup
export const RevTranscriptElement = AbstractDoc.extend({
  ts: Optional(Number),
  end_ts: Optional(Number),
  value: String,
  confidence: Optional(Number),
  type: String,
});
export type RevTranscriptElement = Static<typeof RevTranscriptElement>;

export const RevMonologue = Record({
  elements: RTArray(RevTranscriptElement),
  speaker: Number,
});
export type RevMonologue = Static<typeof RevMonologue>;

export const RevTranscript = Record({
  monologues: RTArray(RevMonologue),
});
export type RevTranscript = Static<typeof RevTranscript>;
/** Base const for annotations, which are suggestions or notes that
 *  are produced algorithmically and which concern specific times,
 *  time intervals, or words in a speech. */
export const AbstractAnnotation = AbstractDoc.extend({
  type: String, // distinguishes between different annotations types
  firstWordIndex: Number,
  lastWordIndex: Number,

  message: Optional(String), // reference?
  userFeedback: Optional(RTArray(UserFeedback)),
});
export type AbstractAnnotation = Static<typeof AbstractAnnotation>;

export const AbstractTimeRangeAnnotation = AbstractDoc.extend({
  startS: Optional(Number), // SHOULD BE REQUIRED
  endS: Optional(Number), // SHOULD BE REQUIRED
});

export enum PhraseAnnotationTypes {
  filler = "DISFLUENCY",
  wc_filler = "WORDCLASS_FILLER",
  wc_hedging = "WORDCLASS_HEDGING",
  hedging = "FILLER",
  nonInclusive = "SENSITIVE",
  double = "DOUBLE",
}

export const PhraseAnnotationType = RTStringEnum(PhraseAnnotationTypes);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PhraseAnnotation = AbstractAnnotation.extend({
  type: RTStringEnum(PhraseAnnotationTypes),
  // Any other fields specific to PhraseAnnotations could go here.
  phrase: Optional(String),
  regex: Optional(String),
  repeatLength: Optional(Number),
  confidence: Optional(Number),
  // Will also want to add a convenience method to return any words
  //  that this phrase applies to, either as:
  //    Words[] | { firstWordIndex: number, lastWordIndex: number }
  //    | number[] // indexes of zl affected words.

  // TODO: Delete this by creating a new RepeatAnnotation type?
  // See also: https://github.com/Yoodli/yoodli-web-server/pull/787#discussion_r984825217
  id: Optional(String),
});
export type PhraseAnnotation = Static<typeof PhraseAnnotation>;

export const UptalkAnnotation = AbstractTimeRangeAnnotation.extend({
  type: Literal("UPTALK"),
  metadata: Record({
    max_up: Number,
    med_diff: Number,
    up_diff: Number,
  }),
});

export const EmphasizedWordsAnnotation = AbstractTimeRangeAnnotation.extend({
  type: Optional(Literal("EM_WORDS")), // TODO: SHOULD NOT BE OPTIONAL
  loudness: Number,
  hasLongPause: Boolean,
  speakerId: Number.optional(),
  prePauseDuration: Number,
  postPauseDuration: Number,
  annotationIds: RTArray(AnnotationID).optional(),
  isLoud: Boolean,
  firstWordIndex: Optional(Number), // TODO: We should remove one of these
  wordIndex: Optional(Number), // TODO: We should remove one of these
  value: Optional(String),
  normalizedDuration: Number,
});

export const FillerWordsAnnotation = AbstractAnnotation.extend({
  type: Literal("FILLER"),
  regex: String.optional(),
  phrase: String.optional(),
  confidence: Optional(Number),
});

export const Annotation = Union(
  PhraseAnnotation,
  // UptalkAnnotation, // TODO when we have time series annotations they should have their own collection as we want to update the words array when we get new word annos
  // EmphasizedWordsAnnotation,
  FillerWordsAnnotation
);
export type Annotation = Static<typeof Annotation>;

export const Word = AbstractDoc.extend({
  speakerId: SpeakerID,
  startS: Number,
  endS: Number,
  value: String,
  confidence: Optional(Union(Number, Null)),
  srcTranscriptIndex: Optional(Number),
  userModifications: Optional(
    Record({
      isRemoved: Optional(Boolean),
      isUserAdded: Optional(Boolean),
      isUserEdited: Optional(Boolean),
    })
  ),
  annotationIds: RTArray(AnnotationID),
});

export type Word = Static<typeof Word>;

export const SpeakerLog = Record({
  speakerId: Number,
  startS: Number,
});

export type SpeakerLog = Static<typeof SpeakerLog>;

export const AIConversationMessage = Record({
  role: oneOf("system", "assistant", "user"),
  content: String,
  timeS: Number, // note that this timeS will be the end time for user messages. Since AI messages come in while we are paused, it's both start and end time there.
});
export type AIConversationMessage = Static<typeof AIConversationMessage>;

export enum SpeechAnalyticsTypes {
  GAZE = "gaze",
  CV_FAIL = "cvFailure",
  SMILE = "smile",
  CENTERING = "centering",
  AGGREGATE = "aggregate",
  RAW = "rawTranscripts",
  DISPLAYABLE = "displayableTranscript",
  WORD_REMOVAL = "wordRemoval",
  LAST_UPDATED = "lastUpdated",
  CV_PROGESS = "cvProgress",
  RTA_HISTORY = "rtaHistory",
  TALKING_POINTS = "talkingPoints",
  AI_CONVERSATION_HISTORY = "aiConversationHistory",
  CONCISENESS = "conciseness",
  SENTENCE_STARTERS = "sentenceStarters",
  STATES = "states",
  FILLER = "filler",
  WEAK = "weak",
  NON_INCLUSIVE = "nonInclusive",
  REPETITION = "repetition",
  MONOLOGUES = "monologues",
  QUESTIONS = "questions",
  TALK_TIME = "talkTime",
  SCREENSHOT_HISTORY = "screenshotHistory",
}

// IDs for individual speech analytics used by the frontend
export enum SpeechAnalyticIDs {
  // Word Choice
  FILLER = "filler",
  HEDGING = "hedging",
  NON_INCLUSIVE_LANGUAGE = "nonInclusive",
  REPETITION = "double",
  CONCISENESS = "conciseness",
  SENTENCE_STARTERS = "sentenceStarters",

  // Delivery
  TIMING = "timing",
  EYE_CONTACT = "gaze", //Computed values are not permitted in an enum with string valued members. If we update to a TS version where this isn't the case we might should use SpeechAnalyticsTypes above for this value
  CENTERING = "centering",
  PACING = "pacing",
  PAUSES = "pauses",
  SMILE = "smile",
  TONE_SYNC = "toneSync",
  TONE_ASYNC = "toneAsync",

  // Listening
  TALK_TIME = "talkTime",
  QUESTIONS = "questions",
  MONOLOGUES = "monologues",

  // Poodli
  RTA_HISTORY = "rtaHistory",
  TALKING_POINTS = "talkingPoints",

  // AI
  AI_CONVERSATION_HISTORY = "aiConversationHistory",
  SCREENSHOT_HISTORY = "screenshotHistory",
}

export const SpeechAnalyticIDsType = RTStringEnum(SpeechAnalyticIDs);

// when speech analytics were last updated. not exhaustive; only covers those the frontend wants to sub to updates on
// but does not need the entire doc for currently (9/22/22)

export const Update = Record({
  version: Number,
  time: String,
});
export type Update = Static<typeof Update>;
export const LastUpdated = AbstractDoc.extend({
  type: Literal(SpeechAnalyticsTypes.LAST_UPDATED),
  gaze: Optional(Update),
  smile: Optional(Update),
  centering: Optional(Update),
  wordRemoval: Optional(Update),
  displayableTranscript: Optional(Update),
  aggregate: Optional(Update),
  remarks: Optional(Update),
  loudness: Optional(Update),
});
export type LastUpdated = Static<typeof LastUpdated>;

export const RTAEndReason = oneOf("correction", "timeout", "pause", "stop", "disabled");
export type RTAEndReason = Static<typeof RTAEndReason>;

export const RTA = Record({
  startS: Number,
  endS: Optional(Number),
  endReason: Optional(RTAEndReason),
});
export type RTA = Static<typeof RTA>;

export enum PoodliAlertStatuses {
  MONOLOGUING = "MONOLOGUING",
  PACING_FAST = "PACING_FAST",
  PACING_SLOW = "PACING_SLOW",
  SENTENCE_STARTER = "SENTENCE_STARTER",
  HALFWAY = "HALFWAY",
}

export const CVProgressString = oneOf("started", "done", "error");
export type CVProgressString = Static<typeof CVProgressString>;

// string spritesheet name : boolean, plus a string for the progress of the cv
export const CVProgress = AbstractDoc.extend({
  status: CVProgressString,
});
// Dictionary(Union(Boolean, CVProgressString), String);

export type CVProgress = Static<typeof CVProgress>;

export const AbstractAnalyticsDoc = AbstractDoc.extend({
  type: RTStringEnum(SpeechAnalyticsTypes),
  version: Union(Number, String),
  lastRunDate: String,
});
export type AbstractAnalyticsDoc = Static<typeof AbstractAnalyticsDoc>;

// map each history status value to an array of RTAs
// this sorcery makes fromEntries have correctly typed keys, not just values
type FromEntries = <Key extends string | number, Value>(
  entries: [Key, Value][]
) => { [K in Key as `${K}`]: Value };
const fromEntries = Object.fromEntries.bind(Object) as FromEntries;

const historyValues = Object.values(PoodliAlertStatuses) as PoodliAlertStatuses[];
export const RTAHistory = AbstractAnalyticsDoc.extend({
  ...fromEntries(historyValues.map((k) => [k, RTArray(RTA)])),
  type: Literal(SpeechAnalyticsTypes.RTA_HISTORY),
});
export type RTAHistory = Static<typeof RTAHistory>;

export const TalkingPoint = Record({
  text: String,
  checked: Boolean,
  lastAnalyzedTime: Optional(String), // timestamp of when it was last toggled, by the user or the backend
  checkHistory: RTArray(
    // a talking point might be toggled multiple times. this lets us replay that
    Record({
      timestamp: String, // absolute utc timestamp
      recordingTimeS: Number, // recording time in seconds the action was taken at (for correlating to transcript)
      checked: Boolean,
      wasUser: Boolean, // as opposed to the backend doing it
    })
  ),
});
export type TalkingPoint = Static<typeof TalkingPoint>;

export const TalkingPoints = AbstractAnalyticsDoc.extend({
  talkingPoints: RTArray(TalkingPoint),
  type: Literal(SpeechAnalyticsTypes.TALKING_POINTS),
  templateId: String,
});
export type TalkingPoints = Static<typeof TalkingPoints>;

export const AggAnalytics = AbstractAnalyticsDoc.extend({
  totalTimeS: Optional(Number),
  wordFrequencies: Optional(Dictionary(Number, String)),
  fillerWords: Optional(Dictionary(Number, String)),
  sensitiveWords: Optional(Dictionary(Number, String)),
  disfluencies: Optional(Dictionary(Number, String)),
  wcFillerWords: Optional(Dictionary(Number, String)),
  wcHedgingWords: Optional(Dictionary(Number, String)),
  baseWordInfo: Optional(RTArray(Unknown)),
  doubleList: Optional(RTArray(String)),
  fillerList: Optional(RTArray(String)),
  sensitiveList: Optional(RTArray(String)),
  type: Literal(SpeechAnalyticsTypes.AGGREGATE),
});
export type AggAnalytics = Static<typeof AggAnalytics>;

export const GazeAnalytics = AbstractAnalyticsDoc.extend({
  rawSpriteReturns: Dictionary(OffloadedField, String),
  rawData: OffloadedField,
  eyeContactPct: Number,
  spritesheetFPS: Number,
  type: Literal(SpeechAnalyticsTypes.GAZE),
});
export type GazeAnalytics = Static<typeof GazeAnalytics>;
// will also include additional fields for which spritesheet failed; not sure how to type that as their keys are not known prior
export const CVFailure = AbstractAnalyticsDoc.extend({
  type: Literal(SpeechAnalyticsTypes.CV_FAIL),
  version: String,
});
export type CVFailure = Static<typeof CVFailure>;

export const SmileAnalytics = AbstractAnalyticsDoc.extend({
  rawSpriteReturns: Dictionary(OffloadedField, String),
  rawData: OffloadedField,
  smileRatio: Number,
  spritesheetFPS: Number,
  type: Literal(SpeechAnalyticsTypes.SMILE),
});
export type SmileAnalytics = Static<typeof SmileAnalytics>;

export const CenteringAnalytics = AbstractAnalyticsDoc.extend({
  rawSpriteReturns: Dictionary(OffloadedField, String),
  rawData: OffloadedField,
  spritesheetFPS: Number,
  width: Number,
  height: Number,
  centeringPct: Number,
  type: Literal(SpeechAnalyticsTypes.CENTERING),
});
export type CenteringAnalytics = Static<typeof CenteringAnalytics>;

export const RawTranscripts = AbstractAnalyticsDoc.extend({
  syncSttTranscript: Optional(RevTranscript),
  asyncSttTranscript: Optional(RevTranscript),
  rawActiveSpeakerLog: Optional(Union(RTArray(SpeakerLog), Null)),
  transcriptType: Optional(Union(Literal("rev"), Literal("deepgram"))),
  type: Literal(SpeechAnalyticsTypes.RAW),
});
export type RawTranscripts = Static<typeof RawTranscripts>;

export const DisplayableTranscript = AbstractAnalyticsDoc.extend({
  words: RTArray(Word),
  annotations: Dictionary(PhraseAnnotation, AnnotationID),
  segmentBoundaries: Optional(RTArray(Number)), // index of sentence boundaries where segment boundaries exist. Eg, if there is a segment boundary at the end of the 3rd sentence, this array will contain [2]
  poodliBoundaries: Optional(RTArray(Number)), // index of sentence boundaries where poodli will say other people were speaking. Same as above.
  interviewBoundaries: Optional(RTArray(Number)), // index of sentence boundaries demarcating interview questions. Same as above.
  type: Literal(SpeechAnalyticsTypes.DISPLAYABLE),
});
export type DisplayableTranscript = Static<typeof DisplayableTranscript>;

export const Conciseness = AbstractAnalyticsDoc.extend({
  chunkRephrasedNWords: RTArray(Number), // -1 for chunks below threshold size
  chunkNWords: RTArray(Number),
  score: Number, // any positive number, but the API should coerce max to 1
  sampleRephrasing: Optional(String),
  sampleChunk: Optional(String),
  sampleChunkIndex: Optional(Number),
  sampleChunkStartTimeS: Optional(Number),
  type: Literal(SpeechAnalyticsTypes.CONCISENESS),
});
export type Conciseness = Static<typeof Conciseness>;

export const ConcisenessDiarized = AbstractAnalyticsDoc.extend({
  score: Number,
  speakerId: Number,
  type: Literal(SpeechAnalyticsTypes.CONCISENESS),
});
export type ConcisenessDiarized = Static<typeof ConcisenessDiarized>;

export const SentenceStarters = AbstractAnalyticsDoc.extend({
  sentenceStarters: Dictionary(Number, String),
  nSentences: Number,
  sentenceStarterStartTimes: Dictionary(RTArray(String), String),
  type: Literal(SpeechAnalyticsTypes.SENTENCE_STARTERS),
});
export type SentenceStarters = Static<typeof SentenceStarters>;

export const SentenceStartersDiarized = SentenceStarters.extend({
  speakerId: Number,
});
export type SentenceStartersDiarized = Static<typeof SentenceStartersDiarized>;

export const InterviewAIPersona = oneOf(
  "professional",
  "friendly",
  "technical",
  "behavioral",
  "stress",
  "ftux" // only for FTUX flow
);
export type InterviewAIPersona = Static<typeof InterviewAIPersona>;

export const ConversationAIPersona = oneOf(
  "professional",
  "friendly",
  "stressed",
  "frustrated",
  "skeptical",
  "reserved",
  "poetic"
);
export type ConversationAIPersona = Static<typeof ConversationAIPersona>;

// "what does the audience care about?"
export const SpeechAIPersona = oneOf(
  "peers",
  "business",
  "technical",
  "children",
  "college students",
  "skeptical"
);
export type SpeechAIPersona = Static<typeof SpeechAIPersona>;

// note that these are demeanors for the AI, and should not be confused with the personas below which are full-on AI characters
// These should eventually be renamed
export const AIPersona = Union(InterviewAIPersona, ConversationAIPersona, SpeechAIPersona);
export type AIPersona = Static<typeof AIPersona>;

// default interview topics, can also be user input
export const InterviewTopic = oneOf(
  "general",
  "product manager",
  "marketing",
  "finance",
  "consulting",
  "software engineering"
);
export type InterviewTopic = Static<typeof InterviewTopic>;

export const ConversationTopic = oneOf(
  "giving colleague constructive feedback",
  "asking manager for a raise",
  "small talk",
  "giving a sales pitch"
);
export type ConversationTopic = Static<typeof ConversationTopic>;

export enum ScenarioTypeIdEnum {
  SALES_CALL = "sales_call",
  COLD_CALL = "cold_call",
  MANAGER_SKILLS_TRAINING = "manager_skills_training",
  MANAGER_PERF_REVIEW = "manager_perf_review",
  CUSTOMER_ENABLEMENT = "customer_enablement", // customer support & success & etc
  MEDIA_TRAINING = "media_training",
  GENERIC = "generic",
  PITCH = "pitch",
  SMALL_TALK = "small_talk",
  PATIENT_COUNSELING = "patient_counseling",
  NETWORKING = "networking",
  BEHAVIORAL_INTERVIEW = "behavioral_interview",
  TECHNICAL_INTERVIEW = "technical_interview",
}
export const ScenarioTypeId = RTStringEnum(ScenarioTypeIdEnum);

export const AIHistoryBase = AbstractAnalyticsDoc.extend({
  type: Literal(SpeechAnalyticsTypes.AI_CONVERSATION_HISTORY),
  history: RTArray(AIConversationMessage),
});
export type AIHistoryBase = Static<typeof AIHistoryBase>;
export const AIHistoryFPR = AIHistoryBase.extend({
  persona: AIPersona,
  topic: Union(InterviewTopic, String, Null),
  company: Optional(String),
  role: Optional(String),
});
export type AIHistoryFPR = Static<typeof AIHistoryFPR>;
export const AIHistoryScenario = AIHistoryBase.extend({
  scenarioPath: String,
  scenarioTypeId: ScenarioTypeId,
  templateSubType: String,
  personaId: String,
});
export type AIHistoryScenario = Static<typeof AIHistoryScenario>;
export const AIConversationHistory = Union(AIHistoryFPR, AIHistoryScenario);
export type AIConversationHistory = Static<typeof AIConversationHistory>;

export const ScreenshotHistory = AbstractAnalyticsDoc.extend({
  type: Literal(SpeechAnalyticsTypes.SCREENSHOT_HISTORY),
  historyGcsUrl: String,
});
export type ScreenshotHistory = Static<typeof ScreenshotHistory>;

export const CommentLike = Record({
  userId: UserID,
  userDisplayName: String,
  emoji: String,
});
export const CommentUpdateAction = oneOf(
  "COMMENT_CREATED",
  "COMMENT_DELETED",
  "COMMENT_LIKED",
  "COMMENT_READ",
  "COMMENT_UNLIKED",
  "COMMENT_UPDATED",
  "NESTED_COMMENT_CREATED",
  "NESTED_COMMENT_DELETED",
  "NESTED_COMMENT_UPDATED",
  "BOOKMARK_CREATED",
  "BOOKMARK_DELETED"
);
export type CommentUpdateAction = Static<typeof CommentUpdateAction>;

export const CommentType = oneOf("text", "bookmark");
export type CommentType = Static<typeof CommentType>;

export const SpeechComment = Record({
  value: Optional(String),
  videoTimestamp: Optional(Union(Number, Null)),
  commentedBy: Optional(String), // 'undefined' if commenter not logged in
  commentedDate: String,
  commenterName: Optional(String),
  commenterPhotoUrl: Optional(Union(String, Null)),
  lastUpdated: Optional(
    Record({
      date: String,
      type: CommentUpdateAction,
    })
  ),
  isRead: Boolean, // For notifs. If comment is left by the speaker, var set to true. Once speaker reads notif, set to false.
  isQuickActionComment: Optional(Boolean), // Quick action comment may include PAUSE, UPTALK, etc.. (for labeling purposes)
  parentIds: RTArray(SpeechCommentID),
  likes: RTArray(CommentLike),
  type: CommentType,
});
export type SpeechComment = Static<typeof SpeechComment>;

export type CommentLike = Static<typeof CommentLike>;

export enum RemarkID {
  Conciseness = "conciseness",
  FollowUpQuestions = "follow_up_questions",
  Summary = "summary",
  InterviewEvaluation = "interview_evaluation",
  Demeanor = "demeanor",
  Pronunciation = "pronunciation",

  // CoachBot
  CoachBotPositive = "coach_bot_positive",
  CoachBotConstructive = "coach_bot_constructive",
}
export const RemarkIDType = RTStringEnum(RemarkID);

export const Remark = AbstractDoc.extend({
  remarkId: RemarkIDType,
  shortText: String,
  showMoreText: Union(String, Null),
  createdAt: String,
  wasGeneratedOnSyncTranscript: Union(Boolean, Null),
  wasRegeneratedOnAsyncTranscript: Union(Boolean, Null), // above and this can be false if, eg, speech was uploaded so no sync
  isRead: Boolean,
});
export type Remark = Static<typeof Remark>;

export const ConcisenessRemark = Remark.extend({
  remarkId: Literal(RemarkID.Conciseness),
  startTimeS: Number,
  endTimeS: Number,
});
export type ConcisenessRemark = Static<typeof ConcisenessRemark>;

export const CoachBotRemark = Remark.extend({
  remarkId: oneOf(RemarkID.CoachBotPositive, RemarkID.CoachBotConstructive),
  coachBotPath: String,
});
export type CoachBotRemark = Static<typeof CoachBotRemark>;

export const FollowUpQuestionsRemark = Remark.extend({
  remarkId: Literal(RemarkID.FollowUpQuestions),
  questions: RTArray(String),
});
export type FollowUpQuestionsRemark = Static<typeof FollowUpQuestionsRemark>;

export const InterviewEvaluationRemark = Remark.extend({
  remarkId: Literal(RemarkID.InterviewEvaluation),
  feedback: RTArray(
    Record({
      questionIndex: Number,
      questionText: Union(String, Null),
      questionTimestamp: Union(Number, Null),
      feedback: String,
    })
  ),
});
export type InterviewEvaluationRemark = Static<typeof InterviewEvaluationRemark>;

export const DemeanorItem = Record({
  demeanor: String,
  level: String,
  level_key: String,
});
export type DemeanorItem = Static<typeof DemeanorItem>;
export const DemeanorRemark = Remark.extend({
  demeanorList: RTArray(DemeanorItem),
});
export type DemeanorRemark = Static<typeof DemeanorRemark>;

export const PronunciationRemark = Remark.extend({
  remarkId: Literal(RemarkID.Pronunciation),
  language: RTLanguage,
  /**
   * This array stores raw feedback items.
   * shortText and showMoreText in base class are also filled for
   * CommentCard's standard rendering pattern.
   * Mark it optional because a few early versions did not have it.
   * We may drop Optional in the near future.
   **/
  feedback: Optional(RTArray(String)),
});
export type PronunciationRemark = Static<typeof PronunciationRemark>;

export type AnyRemark =
  | ConcisenessRemark
  | CoachBotRemark
  | FollowUpQuestionsRemark
  | InterviewEvaluationRemark
  | DemeanorRemark
  | PronunciationRemark
  | Remark;

export enum GoalKind {
  ScoreGoal = "score",
  BinaryGoal = "binary",
  CompoundGoal = "compound",
  TPGoal = "talking_points",
}
export const GoalKindType = RTStringEnum(GoalKind);

export const CustomGoal = AbstractDoc.extend({
  goalKind: GoalKindType,
  name: String,
  userDescription: Union(String, Null), // user facing
  createdBy: Union(String, Null),
  createdDate: String,
  modifiedBy: Optional(String),
  modifiedDate: Optional(Union(String, Null)),
  sourceMetadata: Optional(Dictionary(Unknown)),
});
export type CustomGoal = Static<typeof CustomGoal>;

export const ScoreGoal = CustomGoal.extend({
  goalKind: Literal(GoalKind.ScoreGoal),
  maxScore: Number,
  aiDescription: String,
  lowScoreDescription: String,
  highScoreDescription: String,
});
export type ScoreGoal = Static<typeof ScoreGoal>;

export const BinaryGoal = CustomGoal.extend({
  goalKind: Literal(GoalKind.BinaryGoal),
  aiDescription: String,
  lowScoreDescription: String,
  highScoreDescription: String,
});
export type BinaryGoal = Static<typeof BinaryGoal>;

export const CompoundGoal = CustomGoal.extend({
  goalKind: Literal(GoalKind.CompoundGoal),
  maxScore: Number,
  subGoals: RTArray(
    Record({
      name: String,
      aiDescription: String,
      lowScoreDescription: String,
      highScoreDescription: String,
    })
  ),
});
export type CompoundGoal = Static<typeof CompoundGoal>;

/** This is currently used only by processing logic, but not saved to DB */
export const TPGoal = CustomGoal.extend({
  goalKind: Literal(GoalKind.TPGoal),
  talkingPoints: RTArray(String),
});
export type TPGoal = Static<typeof TPGoal>;

export const AnyGoal = Union(CustomGoal, ScoreGoal, BinaryGoal, CompoundGoal, TPGoal);
export type AnyGoal = Static<typeof AnyGoal>;

export enum DefaultGoal {
  HitTalkingPoints = "hit_talking_points",
  HitTimeTarget = "hit_time_target",
  ActiveListening = "active_listening",
  BookFollowUp = "book_follow_up",
  UseGROW = "use_grow",
  UseSTAR = "use_star",
  IdentifyPainPoints = "identify_pain_points",
  EngageSmallTalk = "engage_small_talk",
}
export const DefaultGoalType = RTStringEnum(DefaultGoal);
export type DefaultGoalType = Static<typeof DefaultGoalType>;

export const CompoundGoalSubScore = Record({
  scoreNumerator: Number,
  name: String,
});
export type CompoundGoalSubScore = Static<typeof CompoundGoalSubScore>;

export const GoalResultHistory = Record({
  updatedAt: String,
  updatedBy: String,
  oldHumanEvaluationText: NullableOptional(String),
  newHumanEvaluationText: NullableOptional(String),
  oldScore: Optional(Number),
  newScore: Optional(Number),
  oldScoreNumerator: Optional(Number),
  newScoreNumerator: Optional(Number),
  oldTalkingPointsHit: Optional(RTArray(String)),
  newTalkingPointsHit: Optional(RTArray(String)),
  oldTalkingPointsPartial: Optional(RTArray(String)),
  newTalkingPointsPartial: Optional(RTArray(String)),
  oldTalkingPointsMiss: Optional(RTArray(String)),
  newTalkingPointsMiss: Optional(RTArray(String)),
  oldSubScores: Optional(RTArray(CompoundGoalSubScore)),
  newSubScores: Optional(RTArray(CompoundGoalSubScore)),
});
export type GoalResultHistory = Static<typeof GoalResultHistory>;

export const GoalResult = AbstractDoc.extend({
  goalId: Union(DefaultGoalType, String), // if string, custom goal which lives on the org
  goalKind: GoalKindType,
  goalName: String,
  shortText: Union(String, Null),
  showMoreText: Union(String, Null),
  createdAt: String,
  wasGeneratedOnSyncTranscript: Union(Boolean, Null),
  wasRegeneratedOnAsyncTranscript: Union(Boolean, Null),
  isRead: Boolean,
  goalWeight: NullableOptional(Number), // 0-100
  humanEvaluationText: NullableOptional(String),
  history: Optional(RTArray(GoalResultHistory)),
});
export type GoalResult = Static<typeof GoalResult>;

export const ScoreGoalResult = GoalResult.extend({
  goalKind: Literal(GoalKind.ScoreGoal),
  scoreNumerator: Number,
  scoreDenominator: Number,
});
export type ScoreGoalResult = Static<typeof ScoreGoalResult>;

export const CompoundGoalResult = GoalResult.extend({
  goalKind: Literal(GoalKind.CompoundGoal),
  subScoresDenominator: Number,
  scoreNumerator: Number,
  scoreDenominator: Number,
  subScores: RTArray(CompoundGoalSubScore),
});
export type CompoundGoalResult = Static<typeof CompoundGoalResult>;

export const BinaryGoalResult = GoalResult.extend({
  goalKind: Literal(GoalKind.BinaryGoal),
  score: Union(Literal(0), Literal(1)),
});
export type BinaryGoalResult = Static<typeof BinaryGoalResult>;

export const TPGoalResult = GoalResult.extend({
  goalKind: Literal(GoalKind.TPGoal),
  talkingPointsHit: RTArray(String),
  talkingPointsMiss: RTArray(String),
  talkingPointsPartial: RTArray(String),
  scoreNumerator: Number,
  scoreDenominator: Number,
});
export type TPGoalResult = Static<typeof TPGoalResult>;

export const AnyGoalResult = Union(GoalResult, BinaryGoalResult, CompoundGoalResult, TPGoalResult);
export type AnyGoalResult = Static<typeof AnyGoalResult>;

export const AnalyticStates = Record({
  remarkState: Optional(RTAnalyticProcessingState),
  syncRemarkState: Optional(RTAnalyticProcessingState),
  contextTagState: Optional(RTAnalyticProcessingState),
  concisenessState: Optional(RTAnalyticProcessingState),
  coachRemarkState: Optional(RTAnalyticProcessingState),
  goalsState: Optional(RTAnalyticProcessingState),
  /** used for sensitive analytic time tracking. Not on the speech document to avoid contention */
  syncAnalyticsKickoffDate: Optional(String),
  /** Python stack does not set this */
  type: Optional(Literal(SpeechAnalyticsTypes.STATES)),
});
export type AnalyticStates = Static<typeof AnalyticStates>;

export const RepeatedPhrase = Record({
  phrase: String,
  startS: Number,
});
export type RepeatedPhrase = Static<typeof RepeatedPhrase>;

export const RepetitionAnalytics = AbstractAnalyticsDoc.extend({
  repeatPercentage: Number,
  repeatPercentile: Number,
  repeats: RTArray(RepeatedPhrase),
  type: Literal(SpeechAnalyticsTypes.REPETITION),
});
export type RepetitionAnalytics = Static<typeof RepetitionAnalytics>;

export const RepetitionDiarized = RepetitionAnalytics.extend({
  speakerId: Number,
});
export type RepetitionDiarized = Static<typeof RepetitionDiarized>;

export const NonInclusiveAnalytics = AbstractAnalyticsDoc.extend({
  wordCounts: Dictionary(Number, String),
  type: Literal(SpeechAnalyticsTypes.NON_INCLUSIVE),
});
export type NonInclusiveAnalytics = Static<typeof NonInclusiveAnalytics>;

export const NonInclusiveDiarized = NonInclusiveAnalytics.extend({
  speakerId: Number,
});
export type NonInclusiveDiarized = Static<typeof NonInclusiveDiarized>;

export const FillerAnalytics = AbstractAnalyticsDoc.extend({
  wordCounts: Dictionary(Number, String),
  fillerPercentage: Number,
  fillerPercentile: Number,
  type: Literal(SpeechAnalyticsTypes.FILLER),
});
export type FillerAnalytics = Static<typeof FillerAnalytics>;

export const FillerDiarized = FillerAnalytics.extend({
  speakerId: Number,
});
export type FillerDiarized = Static<typeof FillerDiarized>;

export const WeakAnalytics = AbstractAnalyticsDoc.extend({
  wordCounts: Dictionary(Number, String),
  weakPercentage: Number,
  weakPercentile: Number,
  type: Literal(SpeechAnalyticsTypes.WEAK),
});
export type WeakAnalytics = Static<typeof WeakAnalytics>;

export const WeakDiarized = WeakAnalytics.extend({
  speakerId: Number,
});
export type WeakDiarized = Static<typeof WeakDiarized>;

export const SpeechSegment = Record({
  startS: Number,
  endS: Number,
  speakerId: Number,
  text: String,
});
export type SpeechSegment = Static<typeof SpeechSegment>;

// note no diarized version of the following 3, as the schema means diarized versions are trivially generated
export const MonologueAnalytics = AbstractAnalyticsDoc.extend({
  monologues: RTArray(SpeechSegment),
  type: Literal(SpeechAnalyticsTypes.MONOLOGUES),
});
export type MonologueAnalytics = Static<typeof MonologueAnalytics>;

export const TalkTime = Record({
  speakerId: Number,
  talkTime: Number,
  percentage: Number,
});
export type TalkTime = Static<typeof TalkTime>;

export const TalkTimeAnalytics = AbstractAnalyticsDoc.extend({
  talkTimeBySpeaker: RTArray(TalkTime),
  totalTalkTime: Number,
  type: Literal(SpeechAnalyticsTypes.TALK_TIME),
});
export type TalkTimeAnalytics = Static<typeof TalkTimeAnalytics>;

export const QuestionAnalytics = AbstractAnalyticsDoc.extend({
  questions: RTArray(SpeechSegment),
  type: Literal(SpeechAnalyticsTypes.QUESTIONS),
});
export type QuestionAnalytics = Static<typeof QuestionAnalytics>;

export const AnalyticsDocV2 = Union(
  GazeAnalytics,
  CVFailure,
  RawTranscripts,
  DisplayableTranscript,
  SmileAnalytics,
  CenteringAnalytics,
  LastUpdated,
  RTAHistory,
  TalkingPoints,
  Conciseness,
  SentenceStarters,
  AIConversationHistory,
  ScreenshotHistory,
  AnalyticStates,
  RepetitionAnalytics,
  FillerAnalytics,
  WeakAnalytics,
  NonInclusiveAnalytics,
  MonologueAnalytics,
  TalkTimeAnalytics,
  QuestionAnalytics,
  AggAnalytics
);
export type AnalyticsDocV2 = Static<typeof AnalyticsDocV2>;

export const SpeechAnalyticsOffloadableFields = [
  "loudness",
  "sttTranscript",
  "syncSttTranscript",
  "asyncSttTranscript",
  "gazeCoordsX",
  "gazeCoordsY",
  "gaussianFilterECPct",
  "gaussianFilterTimeS",

  "gazeDistances",
  "loudnessX",
  "loudnessY",
  "words",
  "wordRemovalTimestamps",
];

// must tighten these up!!
export const SpeechSource = oneOf("LIVE", "CHROME_EXTENSION", "UPLOADED", "ZOOM_RECALL", "POODLI");
export type SpeechSource = Static<typeof SpeechSource>;

// prettier-ignore
export const SpeechLayout = oneOf(
    "JOB_INTERVIEW",
    "SPEECH",
    "CONVERSATION",
    "IMPROMPTU_PROMPT",
    "SPEAKER_NOTES", // retired, but old speech docs have this value
  );
export type SpeechLayout = Static<typeof SpeechLayout>;

export const AbstractLabel = Record({
  startS: Number,
  endS: Number,
  labelingUser: UserID,
});
export type AbstractLabel = Static<typeof AbstractLabel>;
export const LabelSelection = oneOf("AGREE", "DISAGREE");
export type LabelSelection = Static<typeof LabelSelection>;
export const AnnotationLabel = AbstractLabel.extend({
  labeledPhrase: String,
  annotationType: PhraseAnnotationType,
  labelSelection: LabelSelection,
});
export type AnnotationLabel = Static<typeof AnnotationLabel>;

export const PromptType = oneOf(
  "CUSTOM",
  "YOODLI_QUESTION_BANK",
  "YOODLI_IMPROMPTU_PROMPT",
  "HUB_QUESTION_BANK",
  "AI_STARTER",
  "AI_RESPONSE",
  "AI_PAIRED_FOLLOW_UP", // AI follow-up question, paired with the immediately prior starter question or question bank / custom question.
  "UNKNOWN"
);
export type PromptType = Static<typeof PromptType>;

// a SpeechLabel could be any of the above Abstractlabel subtypes.
export const SpeechLabel = Union(AnnotationLabel);
export type SpeechLabel = Static<typeof SpeechLabel>;

export enum TagType {
  CONTEXT_TYPE = "context_type",
  RECORDING_TYPE = "recording_type",
  RECORDING_SUBTYPE = "recording_subtype",
  INTERNAL_EXTERNAL = "internal_external",
  ROLE = "role",
}

export const ContextTypeValues = oneOf("interview", "1:1", "meeting", "presentation");
export type ContextTypeValues = Static<typeof ContextTypeValues>;
export const InternalExternalValues = oneOf("internal", "external");
export type InternalExternalValues = Static<typeof InternalExternalValues>;
export const RoleValues = oneOf(
  "interviewer",
  "interviewee",
  "presenter",
  "audience",
  "leader",
  "participant"
);
export type RoleValues = Static<typeof RoleValues>;

export const AllTagValues = Union(
  ContextTypeValues,
  SpeechSource,
  SpeechLayout,
  InternalExternalValues,
  RoleValues
);
export type AllTagValues = Static<typeof AllTagValues>;

export enum PossibleUserChangeLocations {
  DASHBOARD = "dashboard",
  SPEECH_SUMMARY = "speech_summary",
  PRE_POODLI_NOTIF = "pre_poodli_notif",
  POST_POODLI_NOTIF = "post_poodli_notif",
  LIBRARY = "library",
}

export const Tag = Record({
  key: RTStringEnum(TagType),
  value: AllTagValues,
  version: Number,
  lastUpdated: String,
  userSet: Optional(Boolean),
  userChangedFrom: Optional(AllTagValues),
  userChangeLocation: Optional(RTStringEnum(PossibleUserChangeLocations)),
  timeFirstSet: oneOf("event_creation", "speech_completion", "user_set"),
});
export type Tag = Static<typeof Tag>;

export const SpeechTags = AbstractDoc.extend({
  tags: RTArray(Tag),
  version: Number,
  lastUpdated: String,
});
export type SpeechTags = Static<typeof SpeechTags>;

export const AggregateAnalyticTypes = oneOf(
  "countAnalytics",
  "repetition",
  "filler",
  "weak",
  "sensitive",
  "pacing",
  "monologues",
  "questions",
  "talkTime",
  "eyeContact",
  "centering",
  "sentenceStarters",
  "questionPauses",
  "poodliAlerts",
  "tags",
  "talkingPoints",
  "conciseness"
);
export type AggregateAnalyticTypes = Static<typeof AggregateAnalyticTypes>;

export type DashboardAnalyticId = AggregateAnalyticTypes | UnitCountAnalyticEnum;

export const speechAnalyticIDsToDashboardAnalyticIdMap = new Map<
  SpeechAnalyticIDs,
  DashboardAnalyticId
>([
  [SpeechAnalyticIDs.FILLER, "filler"],
  [SpeechAnalyticIDs.HEDGING, "weak"],
  [SpeechAnalyticIDs.NON_INCLUSIVE_LANGUAGE, "sensitive"],
  [SpeechAnalyticIDs.REPETITION, "repetition"],
  [SpeechAnalyticIDs.EYE_CONTACT, "eyeContact"],
  [SpeechAnalyticIDs.CENTERING, "centering"],
  [SpeechAnalyticIDs.PACING, "pacing"],
  [SpeechAnalyticIDs.PAUSES, "questionPauses"],
  [SpeechAnalyticIDs.TALK_TIME, "talkTime"],
  [SpeechAnalyticIDs.QUESTIONS, "questions"],
  [SpeechAnalyticIDs.MONOLOGUES, "monologues"],
  [SpeechAnalyticIDs.RTA_HISTORY, "poodliAlerts"],
  [SpeechAnalyticIDs.TALKING_POINTS, "talkingPoints"],
  [SpeechAnalyticIDs.CONCISENESS, "conciseness"],
]);

export enum AggregateAnalyticEnum {
  COUNT_ANALYTICS = "countAnalytics",
  REPETITION = "repetition",
  FILLER = "filler",
  WEAK = "weak",
  PACING = "pacing",
  MONOLOGUES = "monologues",
  QUESTIONS = "questions",
  TALK_TIME = "talkTime",
  EYE_CONTACT = "eyeContact",
  CENTERING = "centering",
  SENTENCE_STARTERS = "sentenceStarters",
  QUESTION_PAUSES = "questionPauses",
  POODLI_ALERTS = "poodliAlerts",
  TAGS = "tags",
  CONCISENESS = "conciseness",
  SENSITIVE = "sensitive",
}

export enum UnitCountAnalyticEnum {
  NUM_YOODLIS = "nYoodlis",
  NUM_COMMENTS_GIVEN = "nCommentsGiven",
  NUM_COMMENTS_RECEIVED = "nCommentsReceived",
  NUM_SHARED_WITH_ME = "nSharedWithMe",
}
export const UnitCountAnalyticTypes = RTStringEnum(UnitCountAnalyticEnum);

// dashboard analytics, also will be used for other aggregation such as emails
export const AbstractAggregateAnalytic = AbstractDoc.extend({
  version: Number,
  lastUpdated: String,
  cutoffTimestamp: Optional(String), // for historical data, the cutoff timestamp for the data
  isLatest: Boolean, // if this is the latest data. If this is true, the cutoffTimestamp shouldn't be set
  type: AggregateAnalyticTypes,
  isGoal: NullableOptional(Boolean), // if this is a goal analytic
  goalName: NullableOptional(String), // only set if isGoal is true
});
export type AbstractAggregateAnalytic = Static<typeof AbstractAggregateAnalytic>;

export const AnalyticCount = Record({
  hourUTC: String,
  count: Number,
});
export type AnalyticCount = Static<typeof AnalyticCount>;

// dashboard items which are pure action counts
export const CountAnalytics = AbstractAggregateAnalytic.extend({
  nYoodlis: RTArray(AnalyticCount),
  nCommentsGiven: RTArray(AnalyticCount),
  nCommentsReceived: RTArray(AnalyticCount),
  nSharedWithMe: RTArray(AnalyticCount),
});
export type CountAnalytics = Static<typeof CountAnalytics>;

export const NonCountAggAnalyticValues = AbstractDoc.extend({
  timestamp: String, // when the analytic was last updated (utc timestamp)
  speechRecordedDate: String, // when the speech was recorded (utc timestamp)
  slug: String,
  speechTimeS: Number,
  version: Number,
});
export type NonCountAggAnalyticValues = Static<typeof NonCountAggAnalyticValues>;

export const PctAggAnalyticValues = NonCountAggAnalyticValues.extend({
  pct: Optional(Number),
  count: Optional(Number),
});
export type PctAggAnalyticValues = Static<typeof PctAggAnalyticValues>;

export const PctAggAnalytic = AbstractAggregateAnalytic.extend({
  values: RTArray(PctAggAnalyticValues),
});
export type PctAggAnalytic = Static<typeof PctAggAnalytic>;

export const GoalAggAnalyticValues = NonCountAggAnalyticValues.extend({
  scoreNumerator: Number,
  scoreDenominator: Number, // note that binary goals will be out of 1 here.
  scenarioPath: String,
  personaId: String,
  isGoal: Literal(true),
  goalName: String,
});
export type GoalAggAnalyticValues = Static<typeof GoalAggAnalyticValues>;

export const GoalAggAnalytic = AbstractDoc.extend({
  values: RTArray(GoalAggAnalyticValues),
  version: Number,
  isLatest: Boolean,
  lastUpdated: String,
  isGoal: Literal(true),
  goalName: String,
  type: String, // goal ID
});
export type GoalAggAnalytic = Static<typeof GoalAggAnalytic>;

// reptition, talk time, n questions, eye contact, talking points, and centering are all identical schemas
export const AggRepetition = PctAggAnalytic.extend({
  type: Literal("repetition"),
});
export type AggRepetition = Static<typeof AggRepetition>;

export const AggTalkTime = PctAggAnalytic.extend({
  type: Literal("talkTime"),
});
export type AggTalkTime = Static<typeof AggTalkTime>;
export const AggQuestions = PctAggAnalytic.extend({
  type: Literal("questions"),
});
export type AggQuestions = Static<typeof AggQuestions>;
export const AggEyeContact = PctAggAnalytic.extend({
  type: Literal("eyeContact"),
});
export type AggEyeContact = Static<typeof AggEyeContact>;
export const AggCentering = PctAggAnalytic.extend({
  type: Literal("centering"),
});
export type AggCentering = Static<typeof AggCentering>;

export const AggTalkingPoints = PctAggAnalytic.extend({
  type: Literal("talkingPoints"),
});
export type AggTalkingPoints = Static<typeof AggTalkingPoints>;

export const AggConciseness = PctAggAnalytic.extend({
  type: Literal("conciseness"),
});
export type AggConciseness = Static<typeof AggConciseness>;

export const AggFillerWeakSensValues = NonCountAggAnalyticValues.extend({
  pct: Number,
  count: Number,
  wordCounts: Dictionary(Number, String), // word: count
});
export type AggFillerWeakSensValues = Static<typeof AggFillerWeakSensValues>;
export const AggFiller = AbstractAggregateAnalytic.extend({
  type: Literal("filler"),
  values: RTArray(AggFillerWeakSensValues),
});
export type AggFiller = Static<typeof AggFiller>;
export const AggWeak = AbstractAggregateAnalytic.extend({
  type: Literal("weak"),
  values: RTArray(AggFillerWeakSensValues),
});
export type AggWeak = Static<typeof AggWeak>;

export const AggMonologueValues = NonCountAggAnalyticValues.extend({
  avgDuration: Number,
  longestMonologueS: Number,
  longestStartS: Number,
  nMonologues: Number,
  monologueThresholdS: Number, // seconds of uninterrupted speech counted as a monologue
});
export type AggMonologueValues = Static<typeof AggMonologueValues>;

export const AggMonologues = AbstractAggregateAnalytic.extend({
  type: Literal("monologues"),
  values: RTArray(AggMonologueValues),
});
export type AggMonologues = Static<typeof AggMonologues>;

export const AggPacingValues = NonCountAggAnalyticValues.extend({
  wpm: Number,
});
export type AggPacingValues = Static<typeof AggPacingValues>;

export const AggPacing = AbstractAggregateAnalytic.extend({
  type: Literal("pacing"),
  values: RTArray(AggPacingValues),
});
export type AggPacing = Static<typeof AggPacing>;

export const AggSentenceStartersValues = NonCountAggAnalyticValues.extend({
  sentenceStarters: Dictionary(Number, String), // word: count
  speechNSentences: Number,
});
export type AggSentenceStartersValues = Static<typeof AggSentenceStartersValues>;
export const AggSentenceStarters = AbstractAggregateAnalytic.extend({
  type: Literal("sentenceStarters"),
  values: RTArray(AggSentenceStartersValues),
});
export type AggSentenceStarters = Static<typeof AggSentenceStarters>;

export const AggPoodliAlertsValues = NonCountAggAnalyticValues.extend({
  alertCounts: Dictionary(Number, String), // alert: count
});
export type AggPoodliAlertsValues = Static<typeof AggPoodliAlertsValues>;
export const AggPoodliAlerts = AbstractAggregateAnalytic.extend({
  type: Literal("poodliAlerts"),
  values: RTArray(AggPoodliAlertsValues),
});
export type AggPoodliAlerts = Static<typeof AggPoodliAlerts>;

export const tagValues = Object.values(TagType) as TagType[];
export const AggTagsValues = NonCountAggAnalyticValues.extend({
  ...fromEntries(tagValues.map((k) => [k, Optional(String)])),
});
export type AggTagsValues = Static<typeof AggTagsValues>;

export const TagKeyValueMap = Record({
  ...fromEntries(tagValues.map((k) => [k, Optional(String)])),
});
export type TagKeyValueMap = Static<typeof TagKeyValueMap>;

export const AggTags = AbstractAggregateAnalytic.extend({
  type: Literal("tags"),
  values: RTArray(AggTagsValues),
  lastUpdated: String,
});
export type AggTags = Static<typeof AggTags>;

export const AggregateAnalytics = Union(
  CountAnalytics,
  AggRepetition,
  AggFiller,
  AggWeak,
  AggTalkTime,
  AggQuestions,
  AggEyeContact,
  AggCentering,
  AggMonologues,
  AggPacing,
  AggSentenceStarters,
  AggPoodliAlerts
);
export type AggregateAnalytics = Static<typeof AggregateAnalytics>;

export const TalkingPointTemplate = Record({
  talkingPoints: RTArray(String),
  name: String,
  version: Number,
  lastModifiedDate: String,
  createdDate: String,
});
export type TalkingPointTemplate = Static<typeof TalkingPointTemplate>;

export enum ScenarioTemplateSubType {
  // sales
  COLD_CALL = "cold_call",
  INBOUND_DISCOVERY_CALL = "inbound_discovery_call",
  OUTBOUND_DISCOVERY_CALL = "outbound_discovery_call",

  // manager training
  MANAGER_PERF_REVIEW = "manager_perf_review",
  MANAGER_SKILLS_TRAINING = "manager_skills_training",

  // other
  CUSTOMER_SUPPORT = "customer_support",
  MEDIA_TRAINING = "media_training",
  PITCH = "pitch",
  SMALL_TALK = "small_talk",
  PATIENT_COUNSELING = "patient_counseling",
  NETWORKING = "networking",
  BEHAVIORAL_INTERVIEW = "behavioral_interview",
  TECHNICAL_INTERVIEW = "technical_interview",

  GENERIC = "generic",
}
export const RTScenarioTemplateSubType = RTStringEnum(ScenarioTemplateSubType);
export type RTScenarioTemplateSubType = Static<typeof RTScenarioTemplateSubType>;

export const User = AbstractDoc.extend({
  email: String,
  displayName: String,
  photoURL: NullableOptional(String),
  emailNotificationsEnabled: Optional(Boolean), //default False. Field only gets added when user unsubscribes from notifications
  dateAdded: String, // Field only gets set once (when the new user is added)
  ephemeralUser: Optional(Boolean),
  vtags: Optional(RTArray(String)), // 'varun tags' for psychographic analysis
  referralSlug: Optional(String), // Unique referral link to bring new users to platform
  stripeId: Optional(String),
  stripeNextSync: Optional(String),
  syncUserPlanLockId: Optional(String),
  syncUserPlanLockTime: Optional(Number),
  authProvider: Union(String, Null),
  dateTestResource: Optional(String), // This is set only when the user is created for unit tests
  dateOrgV2Migration: Optional(String),
  defaultOrgId: Optional(Union(String, Null)),

  // Subcollection
  // speeches: subCollection(SpeechID, Speech),
  // games: subCollection(GameID, Game),
  // shares: subCollection(ShareID, Share)
});
export type User = Static<typeof User>;

// Contact for contact book. Email is used as key in contacts dictionary,
//  so a contact without name  would just be
//  `contacts={..., "email@example.com": {}}`
export const Contact = Record({
  email: String,
  name: Optional(String),
  userId: Optional(String),
});

export type Contact = Static<typeof Contact>;

// <Object created by Firebase’s Trigger Email Extension - object schema not controlled by us, only the contents of the body and recipients, etc>
export const EmailNotification = Unknown;
export type EmailNotification = Static<typeof EmailNotification>;

export const AbstractSiteDoc = AbstractDoc.extend({
  type: String, // distinguishes between different annotations types
});

export enum SiteDocTypes {
  FEATURE_DIFFS = "featureDiffs",
  ADMIN = "admin",
  LANDING_PAGE = "landingPage",
  IMAGE_DIFFS = "imageDiffs",
}

export const DropdownButtonOption = Record({
  value: String,
  hidden: Boolean,
  path: String,
});
export type DropdownButtonOption = Static<typeof DropdownButtonOption>;

export const FeatureDiffs = AbstractSiteDoc.extend({
  type: Optional(Literal(SiteDocTypes.FEATURE_DIFFS)), // needs to be optional for now because of static files...
  hideBeta: Optional(Boolean),
  funTopicWebsitePath: Optional(String),
  defaultDropdownButtonOptions: Optional(RTArray(DropdownButtonOption)),
  closedDrawerLogoWidth: Optional(Number),
  openDrawerLogoWidth: Optional(Number),
  openDrawerLogoMarginTop: Optional(Number),
  showCoachFeedbackBanner: Optional(Boolean),
  enableTmiSso: Optional(Boolean),
  enableYoodlibotRecording: Optional(Boolean),
  showNavBarPrimaryCTA: Optional(Boolean),
  showGameLabels: Optional(Boolean),
  underlineSelectedNavBarItem: Optional(Boolean),
  journalSnackbar: Optional(
    Record({
      content: String,
      actionPath: Optional(String),
    })
  ),
  faqDocumentation: Optional(String),
  privacyPolicyConsentRequired: Optional(Boolean),
  referralData: Optional(
    Record({
      header: String,
      rewards: RTArray(
        Record({
          imagePath: String,
          signUpCount: Number,
          text: String,
        })
      ),
    })
  ),
});
export type FeatureDiffs = Static<typeof FeatureDiffs>;

export const ImageDiffs = AbstractSiteDoc.extend({
  type: Optional(Literal(SiteDocTypes.IMAGE_DIFFS)),
  navBarHome: Optional(String),
  navBarFeedbackForm: Optional(String),
  navBarLibrary: Optional(String),
  navBarGames: Optional(String),
  navBarReferrals: Optional(String),
  gamesPageBackground: Optional(String),
  videoJournalEmptyState: Optional(String),
  accountAvatar: Optional(String),
  landingPageAboutTabsRTAnalytics: Optional(String),
});
export type ImageDiffs = Static<typeof ImageDiffs>;

export const Admin = Record({
  email: String,
  name: Optional(String),
});
export type Admin = Static<typeof Admin>;

export const SiteAdminInfo = AbstractSiteDoc.extend({
  type: Literal(SiteDocTypes.ADMIN),
  adminInfo: RTArray(Admin),
  productAdmins: RTArray(Admin),
});
export type SiteAdminInfo = Static<typeof SiteAdminInfo>;

export const Logo = Record({
  url: String,
  closedDrawerUrl: Optional(String),
  height: Optional(String),
});
export type Logo = Static<typeof Logo>;

export const SignInOptions = Record({
  google: Optional(Boolean),
  facebook: Optional(Boolean),
  microsoft: Optional(Boolean),
  email: Optional(Boolean),
});
export type SignInOptions = Static<typeof SignInOptions>;

export const EnvironmentStringMap = Record({
  production: Optional(String),
  staging: Optional(String),
  development: Optional(String),
  local: Optional(String),
});
export type EnvironmentStringMap = Static<typeof EnvironmentStringMap>;

export const ColorPalette = Record({
  primary: Optional(String),
  primaryTransparent: Optional(String),
  primaryDark: Optional(String),
  primaryLight: Optional(String),
  secondary: Optional(String),
  gradient: Optional(String),
  hoverGradient: Optional(String),
  specialGradient: Optional(String),
  specialPrimary: Optional(String),
  specialAccessibility: Optional(String),
  specialAccessibility2: Optional(String),
});
export type ColorPalette = Static<typeof ColorPalette>;

export const HeroVideo = Record({
  sourceUrl: String,
  shouldAutoplay: Optional(Boolean),
  muted: Optional(Boolean),
  showControls: Optional(Boolean),
  shouldLoop: Optional(Boolean),
});
export type HeroVideo = Static<typeof HeroVideo>;

export const LandingPage = AbstractSiteDoc.extend({
  type: Optional(Literal(SiteDocTypes.LANDING_PAGE)), // needs to be optional for now because of static files...
  heroVideo: Optional(HeroVideo),
  heroText: Optional(String),
  biography: Optional(String),
  biographyThumbnail: Optional(String),
  aboutUsString: Optional(String),
  signUpCTAString: Optional(String),
  omitFeatures: Optional(Dictionary(Boolean, String)),
  logo: Optional(Record({ height: String })), // @ronak / @esha why is logo defined twice
  rollingTextWords: Optional(RTArray(String)),
});
export type LandingPage = Static<typeof LandingPage>;

export const Site = AbstractDoc.extend({
  allowedEmails: Optional(RTArray(String)), // if both exist, allow inclusive
  allowedEmailDomains: Optional(RTArray(String)),
  restrictAccess: Optional(Boolean), // if true, respect above. makes this toggleable without losing data
  landingPageUrl: Optional(String),
  subdomain: String,
  logo: Optional(Logo),
  secondaryLogo: Optional(Logo),
  title: Optional(String),
  signInOptions: Optional(SignInOptions),
  demoSpeechUser: Optional(String),
  defaultContacts: Optional(RTArray(Contact)), // not sure what all this is used for
  endUserWelcomeSlugs: Optional(EnvironmentStringMap),
  demoSlugs: Optional(EnvironmentStringMap),
  coachWelcomeSlugs: Optional(EnvironmentStringMap),
  coachDemoSlugs: Optional(EnvironmentStringMap),
  checklistTutorialVideoPercentage: Optional(Number),
  stringSubs: Optional(Dictionary(String, String)),
  colors: Optional(ColorPalette),
  disableRemarks: Optional(Boolean),
  unaffiliatedOrgId: Optional(String),
  /**
   * Flag indicating whether or not debug logging is enabled for the Accenture 1.0 integration.
   */
  accenture10DebugLoggingEnabled: Optional(Boolean),
});
export type Site = Static<typeof Site>;

export const SiteDoc = Union(FeatureDiffs, SiteAdminInfo, LandingPage, ImageDiffs);
export type SiteDoc = Static<typeof SiteDoc>;

export const AnalyticNorm = Record({
  avg: Number,
  stddev: Number,
  median: Number,
  min: Number, // min counted = 5th percentile
  max: Number, // max counted = 95th percentile
  count: Number,
  measure: String, // eg pct, wpm
});
export type AnalyticNorm = Static<typeof AnalyticNorm>;

export enum GameTypes {
  SPIN_A_YARN = "SPIN_A_YARN",
  METAPHOR_MANIA = "METAPHOR_MANIA",
  NO_FILLER = "NO_FILLER",
  STORYTELLER = "STORYTELLER",
}

export type SubPrompt = Static<typeof SubPrompt>;
export const SubPrompt = AbstractDoc.extend({
  word: String,
  skipped: Optional(Boolean), // deprecating; should i just delete this?
  presentedTime: Number,
  spoken: Optional(Boolean),
  spokenTime: Optional(Number),
  spokenQuantity: Optional(Number),
});

export type AbstractGame = Static<typeof AbstractGame>;
export const AbstractGame = AbstractDoc.extend({
  slug: String, // Unique string used to lookup this document. Eg, DocumentId
  type: RTStringEnum(GameTypes),
  // name: String,
  // gcsPath: Optional(String), // retired
  prompt: Optional(String),
  targetTimeS: Optional(Number),
  totalTimeS: Optional(Number),
  totalScore: Optional(Number),
  recordedDate: String,
  archivedByUser: Optional(Boolean), // default: false. Not deleting speech right now, setting this to true removes visibility from user
  visible: Boolean,
  commentsVisibility: Boolean, // Default: true
});

export type SpinAYarnGame = Static<typeof SpinAYarnGame>;
export const SpinAYarnGame = AbstractGame.extend({
  type: Literal(GameTypes.SPIN_A_YARN),
  totalScore: Number,
  prompt: String,
  subPrompts: RTArray(SubPrompt),
  numberOfSkips: Optional(Number), // here again with deprecating
  numberOfHits: Optional(Number),
});
export type MetaphorManiaGame = Static<typeof MetaphorManiaGame>;
export const MetaphorManiaGame = AbstractGame.extend({
  type: Literal(GameTypes.METAPHOR_MANIA),
  prompts: RTArray(RTArray(String)),
  numberOfPrompts: Number,
  timeSetBetweenPrompts: Number,
  targetTimeS: Number,
  totalTimeS: Number,
  promptTimeS: Number,
});

// a Game could be any of the above AbstractGame subtypes.
export const Game = Union(SpinAYarnGame, MetaphorManiaGame);
export type Game = Static<typeof Game>;

// USER DOCS
// this value should be the key of the doc, and should go in 'type' as well.
// So, 'public' doc might be like:
//    '/users/:userId/docs/public' --> {type:'public', blah:'blah'}
export enum UserDocTypes {
  PUBLIC = "public",
  MAIN = "main",
  READONLY = "readonly",
  PLAN = "plan",
  REMARKS = "remarks",
}

export const AbstractUserDoc = AbstractDoc.extend({
  // Except for 'type', all fields in user docs should be optional to
  //  allow for bootstrapping.
  type: RTStringEnum(UserDocTypes),
});
export type AbstractUserDoc = Static<typeof AbstractUserDoc>;

export const Share = AbstractDoc.extend({
  path: String,
  isRead: Optional(Boolean), // Has user seen this share yet?
  sharedByUid: Optional(String),
  sharedByName: Optional(String),
  sharedByEmail: Optional(String),
  shareNote: Optional(String),
  shareTimestamp: Optional(String),
  collabStatusRequired: Optional(Boolean), // if True, share is filtered out if viewer removed from 'collabs'
  hidden: Optional(Boolean), // Hide in share library? default: false
  lastHiddenTimestamp: Optional(String), // may be used to purge old shares, eventually
  forEvaluation: Optional(Boolean),
});
export type Share = Static<typeof Share>;

// Immediate type coming out of the InterviewInput flow, gets converted to SpeechPrompt before entering db.Speech
export const InterviewQuestion = Record({
  text: Union(String, Null),
  category: String,
  subcategory: Optional(Union(String, Null)),
  type: Optional(PromptType),
});
export type InterviewQuestion = Static<typeof InterviewQuestion>;

// This Boolean is from runtypes, but ESlint thinks this is a boolean primitive
// eslint-disable-next-line @typescript-eslint/ban-types
type ChecklistItemsCompleted = { [key in ChecklistKey]: Optional<Boolean> };

export const Referee = Record({
  email: String,
  userPath: Optional(String), // gets populated once the referee creates an account
  referTimestamp: Optional(String), // Optional because added after the first pass of referral flow
});
export type Referee = Static<typeof Referee>;

export const KfaReferralProgramExtraData = Record({
  workspace: String,
  companyName: String,
  companyCategory: String,
  environment: String,
});
export type KfaReferralProgramExtraData = Static<typeof KfaReferralProgramExtraData>;

export const IndeedReferralProgramExtraData = Record({
  hashedUserId: String,
});
export type IndeedReferralProgramExtraData = Static<typeof IndeedReferralProgramExtraData>;

export const ReferralProgramExtraData = Union(
  KfaReferralProgramExtraData,
  IndeedReferralProgramExtraData
);
export type ReferralProgramExtraData = Static<typeof ReferralProgramExtraData>;

export const KfaSsoInterviewData = Record({
  recent_industry: String,
  recent_level: String,
  recent_function: String,
  recent_sub_function: String,
  immediate_needs: String,
  career_aspirations: String,
  resume: String,
});
export type KfaSsoInterviewData = Static<typeof KfaSsoInterviewData>;

export enum DashboardRange {
  WEEK = "7 days",
  MONTH = "30 days",
}

export enum OnboardingChecklist {
  INTERVIEW_CHECKLIST_V1 = "interview-checklist-v1",
  SPEECH_CHECKLIST_V1 = "speech-checklist-v1",
  MEETINGS_CHECKLIST_V1 = "meetings-checklist-v1",
  COACH_CHECKLIST_V1 = "coach-checklist-v1",
}

export enum OnboardingChecklistTask {
  DEMO_VIDEO = "demo-video",
  FPR_SPEECH = "fpr-speech",
  FPR_CONVERSATION = "fpr-conversation",
  PERSONALIZE_AUDIENCE_AND_PRACTICE = "personalize-audience-and-practice",
  SELF_CONFIDENCE_EVAL = "self-confidence-eval",
  FPR_INTERVIEW = "fpr-interview",
  CUSTOMIZE_QUESTIONS_AND_PRACTICE = "customize-questions-and-practice",
  UPLOAD_SPEECH = "upload-speech",
  SHARE_SPEECH = "share-speech",
  VIEW_SPEECH_SUMMARY = "view-speech-summary",
  // obsolete but must be kept until migrated out of the DB
  DOWNLOAD_AND_SET_UP_POODLI = "download-and-set-up-poodli",
  RECORD_POODLI = "record-poodli",
  CREATE_ORG = "create-org",
  INVITE_CLIENT_TO_HUB = "invite-client-to-hub",
  VIEW_SAMPLE_SPEECH = "view-sample-speech",

  // DEPRECATED
  CREATE_HUB = "create-hub",
}

export type OnboardingChecklistItem = {
  taskDescription: string;
  task: OnboardingChecklistTask;
};

export enum ProductTip {
  CUSTOMIZE_DASHBOARD_ANALYTICS = "customize_dashboard_analytics",
  ADD_CONCISENESS_TO_DASHBOARD = "add_conciseness_to_dashboard",
  CONCISENESS_MAGIC_WAND = "conciseness_magic_wand",
  COACHING_KEY_POINTS = "coaching_key_points",
  WEEKLY_REVIEW_EMAIL = "weekly_review_email",
  REVIEW_SENTENCE_STARTERS = "review_sentence_starters",
  JOIN_COMMUNITY = "join_community",
  FPR_WARM_UP = "fpr_warm_up",
  CUSTOMIZE_FPR_PERSONA = "customize_fpr_persona",
  TRY_FPR_CONVERSATION = "try_fpr_conversation",
  UPLOAD_SPEECH = "upload_speech",
  DASHBOARD_FILLER_TARGET = "dashboard_filler_target",
  DASHBOARD_PACING_TARGET = "dashboard_pacing_target",
  SHARE_WITH_COACH = "share_with_coach",
  CAL_CONNECT = "cal_connect",
  CUSTOMIZE_LIVE_ANALYTICS = "customize_live_analytics",
  HIDE_POODLI_SCREENSHARE = "hide_poodli_screenshare",
  POODLI_START_MODE_SELECTOR = "poodli_start_mode_selector",
  POODLI_TALKING_POINTS = "poodli_talking_points",
  SHARE_REPORT_WITH_COACH = "share_report_with_coach",
  DASHBOARD_TALK_TIME_TARGET = "dashboard_talk_time_target",
}

export enum TipGroup {
  FPR_CUSTOMIZATION = "fpr_customization",
  FPR_REVIEW = "fpr_review",
  FPR_COMMUNITY = "fpr_community",
  FPR_SPEECHES = "fpr_speeches",
  FPR_DASHBOARD_TARGETS = "fpr_dashboard_targets",
  POODLI_SETUP = "poodli_setup",
  POODLI_CUSTOMIZATION = "poodli_customization",
  POODLI_COMMUNITY = "poodli_community",
  POODLI_ADVANCED_FEATURES = "poodli_advanced_features",
  POODLI_DASHBOARD_TARGETS = "poodli_dashboard_targets",
}

export enum TipCategory {
  FPR = "fpr",
  POODLI = "poodli",
  COACH = "coach",
}

export enum SelfConfidenceLevel {
  NOT_CONFIDENT = 1,
  COULD_IMPROVE = 2,
  NEUTRAL = 3,
  SOMEWHAT_CONFIDENT = 4,
  VERY_CONFIDENT = 5,
}
export enum SelfConfidenceStruggle {
  MAKING_EYE_CONTACT = "MAKING_EYE_CONTACT",
  TOO_MANY_WORDS = "TOO_MANY_WORDS",
  TALK_TOO_FAST = "TALK_TOO_FAST",
  REPEATING_MYSELF = "REPEATING_MYSELF",
  EVERYTHING = "EVERYTHING",
}
export enum SelfConfidenceAnalytic {
  REPETITION = "REPETITION",
  WEAK = "WEAK",
  PACING = "PACING",
  CONCISENESS = "CONCISENESS",
  EVERYTHING = "EVERYTHING",
}
const SelfConfidenceEval = Record({
  confidenceLevel: RTNumericEnum(SelfConfidenceLevel),
  // strugglesWith DEPRECATED 2024-03-01
  strugglesWith: Optional(RTArray(RTStringEnum(SelfConfidenceStruggle))),
  analytics: Optional(
    Record({
      good: Optional(RTArray(RTStringEnum(SelfConfidenceAnalytic))),
      bad: Optional(RTArray(RTStringEnum(SelfConfidenceAnalytic))),
    })
  ),
  createdAt: String, // ISO Date String
  version: Number,
});
export type SelfConfidenceEval = Static<typeof SelfConfidenceEval>;

// UserDocMain is read/writeable by the user *and* the server, and
//  inaccessible to other users.
// Most UserDoc values probably belong here, or on some future doc with
//  similar permissions.
export const UserDocMain = AbstractUserDoc.extend({
  type: Literal(UserDocTypes.MAIN),

  contacts: Dictionary(Contact, String),

  referees: Optional(RTArray(Referee)),

  referredBy: Optional(
    Record({
      userPath: String,
    })
  ),

  dashboardOptions: Optional(
    Record({
      dayRange: Optional(RTStringEnum(DashboardRange)),
      hoistedAnalytics: Optional(RTArray(String)),
      hoistedGoals: Optional(RTArray(String)),
      hoistOrder: Optional(RTArray(String)),
      ftuxDismissed: Optional(Boolean),
    })
  ),
  referralProgram: Optional(RTReferralProgram),
  referralProgramExtraData: Optional(ReferralProgramExtraData),

  onboardingAnswers: Optional(
    Record({
      speakingFocus: Optional(String),
      eventsFocus: RTOBQ1Option,
      calendarAction: Optional(Union(String, Null)),
      emails: Optional(RTArray(String)),
    })
  ),

  actionPanel: Optional(
    Record({
      // DEPRECATED
      onboardingChecklistCompleted: Optional(Boolean),
    })
  ),

  // TODO: get rid of this?
  checklistItemsCompleted: Optional(
    Record<ChecklistItemsCompleted>({
      coachLeaveFeedback: Optional(Boolean),
      coachUploadSpeech: Optional(Boolean),
      coachWatchTutorial: Optional(Boolean),
      endUserPracticeInterview: Optional(Boolean),
      endUserPracticeSpeech: Optional(Boolean),
      endUserZoom: Optional(Boolean),
      endUserWatchTutorial: Optional(Boolean),
    })
  ),

  notifPrefsNewCommentEmail: Boolean,
  notifPrefsNewShareEmail: Boolean,
  notifPrefsSpeechMilestonesEmail: Boolean,
  notifPrefsSendCommentEmail: Boolean,
  hasCal: Optional(Boolean),
  sampleSpeechesVisited: Optional(Boolean),

  videoPlayerOnMobile: Record({
    // we used to also track interview and conversation defaults, but collapsed to just speech
    speech: Boolean,
    /**
     * @deprecated, use speech instead
     */
    interview: Optional(Boolean),
    /**
     * @deprecated, use speech instead
     */
    conversation: Optional(Boolean),
  }),
  videoPlayerOnDesktop: Optional(
    Record({
      // we used to also track interview and conversation defaults, but collapsed to just speech
      speech: Optional(Boolean),
      /**
       * @deprecated, use speech instead
       */
      interview: Optional(Boolean),
      /**
       * @deprecated, use speech instead
       */
      conversation: Optional(Boolean),
    })
  ),
  speechRecorderVideoOn: Boolean,
  mirrorVideo: Boolean,
  countdownOn: Boolean,
  backgroundBlurOn: Boolean,
  realTimeAlertsOn: Boolean,
  defaultLinkSharingPublic: Boolean,
  navigationBarDefaultExpanded: Boolean,

  practiceTopic: Optional(
    Record({
      interview: Optional(String),
      speech: Optional(String),
      conversation: Optional(String),
    })
  ),
  practiceConvoScenarioId: Optional(String),
  practiceConvoPersonaId: Optional(String), // DEPRECATED
  practiceConvoScenarioActive: Optional(Boolean),
  practicePresentationScenarioId: Optional(String), // DEPRECATED
  // company is only used for interview so no need for speech and conversation values
  practiceCompany: Optional(String),
  // role is only used for conversation so no need for interview and speech values
  practiceRole: Optional(String),
  practiceRoleOptions: Optional(RTArray(String)),
  personas: Record({
    interview: Optional(InterviewAIPersona),
    speech: Optional(SpeechAIPersona),
    conversation: Optional(ConversationAIPersona),
  }),
  fprSpeakForNSecondsHintDismissed: Optional(Boolean), // DEPRECATED IN FAVOR OF THE fpr OBJECT
  fpr: Optional(
    Record({
      speakForNSecondsHintDismissed: Optional(Boolean),
      presentationScenarioHintDismissed: Optional(Boolean),
      autoRespondModeEnabled: Optional(Boolean),
      aiInterruptible: Optional(Boolean),
      ttsEnabled: Optional(Boolean),
      showCaptions: Optional(Boolean),
      ttsFTUXDismissed: Optional(Boolean),
    })
  ),

  builder: Optional(
    Record({
      ftuxCompleted: Optional(Boolean),
    })
  ),

  selfConfidenceEval: Optional(
    Record({
      ftuxEvalCompleted: Optional(Boolean),
      // this is really the last time a user interactice with the eval UI, not actually the last time they completed an eval
      // this is because we dont want to continually show them it untilk they coplete it, if they close it, they dont wanna do it, so dont make them
      // lastEvalDate should be called: lastEvalInteractionDate
      lastEvalDate: Optional(String),
      // this actually tracks nSpeeches since the last time they interacted with the eval ui, in any capacity. It not only works for follow up evaqls now,
      // but also for FTUX evals, to avoid showing then another eval on speech summary if they had just completed/interacted with one
      // nSpeechesAtLastFollowUpEval should be called: nSpeechesAtLastEvalInteraction
      nSpeechesAtLastFollowUpEval: Optional(Number),
    })
  ),
  poodliStatuses: Optional(
    Record({
      poodliOnboardingStarted: Optional(Boolean),
      poodliOnboardingComplete: Optional(Boolean),
      poodliDownloadDate: Optional(String),
      realTimeAlertsEnabled: Optional(Boolean),
      talkingPointsEnabled: Optional(Boolean),
      talkingPointsLastUsedDate: Optional(String),
      autoRecordEnabled: Optional(Boolean),
      notificationsEnabled: Optional(Boolean),
      preferredMeetingPlatform: Optional(RTStringEnum(MeetingPlatform)),
      hideFromScreenShare: Optional(Boolean),
      meetingDetectionEnabled: Optional(Boolean),
      coachRequested: Optional(Boolean),
      calendarActionItemDismissed: Optional(Boolean),
      screenShareActionItemDismissedTimestamp: Optional(String),
      activeLiveAnalytics: Optional(RTArray(String)),

      // DEPRECATED
      poodliNotificationContextFtuxTimestamp: Optional(String),
    })
  ),

  broadcastEmailStatuses: Optional(
    Record({
      lastWeeklyPreviewEmailBroadcast: Optional(String),
      lastWeeklyReviewEmailBroadcast: Optional(String),
    })
  ),

  defaultTargetTimeS: Number,
  visitedDemoTourpoints: RTArray(String),
  visitedRecorderTourpoints: RTArray(Union(String, Null)),
  visitedSummaryTourpoints: RTArray(Union(String, Null)),
  visitedPracticeRecorderTourpoints: RTArray(Union(String, Null)),
  visitedTtsTourpoints: RTArray(Union(String, Null)),
  defaultInterviewPlanV2: Optional(RTArray(InterviewQuestion)),

  nka_record: Number, // emitted when user is about to view speech summary (on Speech Recorder, it's when user clicks "View Analysis")
  nka_poodli_record: Number, // emitted when, from Poodli app, user records a Poodli and the upload completes
  nka_zoodli_record: Number, // emitted when, from Zoodli page, user clicks on "View last recording" to view Speech Summary right after Zoodli recording
  nka_upload: Number, // upload is initiated (regardless of outcome)
  nka_game: Number, // game is successfully played
  nka_comment: Number, // a human comment, comment reply, etc on speech summary
  nka_autoremark: Number, // an autoremark click, interaction, expand, read
  nka_share_game: Number, // share a game
  nka_share_rec: Number, // share a speech/video
  nka_invite: Number, // invite a user to the platform or to view a speech, by email
  nka_engage_passive: Number, // play video on speech summary or engaging with Courses
  nka_tour_click: Number, // click on a tour point on speech summmary

  highScoreYarn: Number,
  highScoreNoFiller: Number,

  timesGpt3RephraseUsed: Optional(Number),

  dataCollectionConsent: Boolean,

  homeTimezone: Optional(String),
  dateLastActivity: String,

  sentFirstSpeechEmail: Optional(Boolean), // Default: false, only sent for live speeches

  clearedSpeechSummaryContextCallout: Optional(Boolean),

  brandingLogoUrl: Optional(Union(String, Null)),
  brandingSecondaryLogoUrl: Optional(Union(String, Null)),

  pExpVersion: Optional(RTStringEnum(PricingExperiment)), // Used to which pricing experiment the user is a part of

  onboardingChecklist: Optional(
    Record({
      checklistId: Optional(RTStringEnum(OnboardingChecklist)),
      completedTimestamp: Optional(String),
      viewedCompletedAnimationTimestamp: Optional(String),
      completedTasks: Optional(
        RTArray(
          Record({
            task: Optional(RTStringEnum(OnboardingChecklistTask)),
            completedTimestamp: Optional(String),
          })
        )
      ),
    })
  ),

  productTips: Optional(
    Record({
      currentWebclientTipGroup: Optional(RTStringEnum(TipGroup)),
      currentPoodliTipGroup: Optional(RTStringEnum(TipGroup)),
      nextCycle: Optional(String),
      tipsInteracted: Optional(
        RTArray(
          Record({
            tip: RTStringEnum(ProductTip),
            interactionTimestamp: Optional(String),
          })
        )
      ),
    })
  ),

  requestedAffiliateCodeTimestamp: Optional(String),

  convoScenarioOnboardingCompleted: Optional(Boolean),

  hasCreatedScenarioPersona: Optional(Boolean),

  lastUserOrgProfile: Optional(String),
  lastUserOrgRole: Optional(String),

  /** list of persona IDs which were copied from default personas. This is used to track which default personas shouldn't be shown in the persona list for the user */
  copiedDefaultPersonas: Optional(RTArray(String)),

  /** if true, generate new interview questions on load of the practice page, and then set to false */
  generateQuestionsOnLoad: Optional(Boolean),

  /** If true, don't send CIO marketing emails to user */
  marketingOptOut: Optional(Boolean),

  /**
   * Flag whether providing feedback for a language learner, like pronunciation analysis.
   * We may need to change this to per-language setting in the near future, but for now,
   * it represents the feature regardless of the language and practically English only.
   * The default (undefined) is "neutral" and the initial pronunciation analysis sets
   * false or true depending on its evaluation whether a native speaker or not.
   */
  languageLearnerFeedback: Optional(Boolean),

  recordingLanguage: Optional(RecordingSupportedLanguage),

  b2bBannerDismissed: Optional(Boolean),
});
export type UserDocMain = Static<typeof UserDocMain>;

// UserDocPublic is read/writeable by the user *and* the server, and
//  readable by everyone (including unauth'd users)
export const UserDocPublic = AbstractUserDoc.extend({
  type: Literal(UserDocTypes.PUBLIC),

  dailyStreak: Number, // may be displayed on profile view
  lastStreakActionDate: Optional(String), // "last active" (also needed for streak calc)
});
export type UserDocPublic = Static<typeof UserDocPublic>;

// UserDocReadonly is read/writeable by the server, is readonly to
// the user themselves, and is inaccessible to anyone else.
// Example usage would be permissions, whether user is employee, etc.
export const UserDocReadonly = AbstractUserDoc.extend({
  type: Literal(UserDocTypes.READONLY),

  // Now deprecated and unused, to remove in a migration in the future
  admin: Optional(Boolean),

  // pricing information
  usagePlanType: RTStringEnum(UsagePlanType),
  usagePlanDuration: RTStringEnum(UsagePlanDuration),
  usagePlanRenewalDate: Union(String, Null),
  nextUsagePlanStart: Union(String, Null),
  nextUsagePlanType: Union(RTStringEnum(UsagePlanType), Null),
  nextUsagePlanDuration: Union(RTStringEnum(UsagePlanDuration), Null),
  usageWindowStart: Union(String, Null),
  usageWindowEnd: Union(String, Null),
  // This number is reset at various timings. Don't use it for analytics (BQ).
  usedSpeeches: Number,
  // Obsoleted field. Old speeches may still have the values.
  //usedComments: Number,
  usageQuota: Union(Number, Null),
  freeUsageBonusForPoodli: Boolean,

  // 1-30: config days, 0 (or non-existence): not applying the feature
  // Effective value needs to consider license state and org configuration.
  // The client should not read/write this value directly, but use API.
  dataRedactionDays: Number,

  nextSpeechRedaction: Optional(String),
});
export type UserDocReadonly = Static<typeof UserDocReadonly>;

export const UserDocRemarks = AbstractUserDoc.extend({
  type: Literal(UserDocTypes.REMARKS),
  remarkCounts: Dictionary(Record({ count: Number, lastUpdated: String }), String), // key is full remark dupKey
});
export type UserDocRemarks = Static<typeof UserDocRemarks>;

export const PlanItem = Record({
  type: Literal("ITEM"),
  entryId: String,
  args: Optional(Dictionary(Unknown, String)),
  done: Optional(Boolean),
  optional: Optional(Boolean),
});
export type PlanItem = Static<typeof PlanItem>;

export const PlanUnit = Record({
  type: Literal("UNIT"),
  entryId: String,
  args: Optional(Dictionary(Unknown, String)),
  progressPct: Optional(Number),
  done: Optional(Boolean),
  expanded: Optional(Boolean),
  children: RTArray(PlanItem),
});
export type PlanUnit = Static<typeof PlanUnit>;

export const PlanCategory = Record({
  type: Literal("CATEGORY"),
  entryId: oneOf("GETTING_STARTED", "LEARN", "PRACTICE", "ENGAGE", "IMPROVE"),
  progressPct: Optional(Number),
  done: Optional(Boolean),
  expanded: Optional(Boolean),
  children: RTArray(Union(PlanUnit, PlanItem)),
});
export type PlanCategory = Static<typeof PlanCategory>;

// UserDocPlan is for 'my plan'. It has permissions like UserDocMain ; is separated out just to
// not bloat UserDocMain.
export const UserDocPlan = AbstractUserDoc.extend({
  type: Literal(UserDocTypes.PLAN),

  plan: Optional(RTArray(PlanCategory)),

  eventSet: Optional(RTArray(String)),
});
export type UserDocPlan = Static<typeof UserDocPlan>;

// a UserDoc could be any of the above UserDoc subtypes.
export const UserDoc = Union(
  UserDocMain,
  UserDocPublic,
  UserDocReadonly,
  UserDocPlan,
  UserDocRemarks
);
export type UserDoc = Static<typeof UserDoc>;

// NOTIFICATION JOBS
export enum NotificationTypes {
  SPEECH_INVITE = "SPEECH_INVITE",
  NEW_COMMENT = "NEW_COMMENT",
  FREEFORM_EMAIL = "FREEFORM_EMAIL",
  DISCONNECT_RECONNECT_CALENDAR = "DISCONNECT_RECONNECT_CALENDAR",
  DOWNLOAD_POODLI_MOBILE = "DOWNLOAD_POODLI_MOBILE",
  // Deprecated, to be removed in the future
  REQUEST_COACHING = "REQUEST_COACHING",
  ZOODLI_QUOTA_REACHED = "ZOODLI_QUOTA_REACHED",
}

export const ActionTaken = Dictionary(Unknown, String);
export type ActionTaken = Static<typeof ActionTaken>;

export type AbstractNotificationJob = Static<typeof AbstractNotificationJob>;
export const AbstractNotificationJob = AbstractDoc.extend({
  type: RTStringEnum(NotificationTypes),
  processed: Optional(Boolean),
  actionsTaken: Optional(RTArray(ActionTaken)),
  createdAt: String,
});

export type SpeechInviteNotificationJob = Static<typeof SpeechInviteNotificationJob>;
export const SpeechInviteNotificationJob = AbstractNotificationJob.extend({
  type: Literal(NotificationTypes.SPEECH_INVITE),
  documentPath: String,
  toEmail: String,
  note: Optional(String),
  fromUserId: String, // UID of person who initiated the share.
  forEvaluation: Optional(Boolean), // TODO: #11931 make this mandatory after initial release
});

export type NewCommentNotificationJob = Static<typeof NewCommentNotificationJob>;
export const NewCommentNotificationJob = AbstractNotificationJob.extend({
  type: Literal(NotificationTypes.NEW_COMMENT),
  documentPath: String,
});

export type DownloadPoodliMobileNotificationJob = Static<
  typeof DownloadPoodliMobileNotificationJob
>;
export const DownloadPoodliMobileNotificationJob = AbstractNotificationJob.extend({
  type: Literal(NotificationTypes.DOWNLOAD_POODLI_MOBILE),
  toEmail: String,
});

export type FreeformEmailNotificationJob = Static<typeof FreeformEmailNotificationJob>;
export const FreeformEmailNotificationJob = AbstractNotificationJob.extend({
  type: Literal(NotificationTypes.FREEFORM_EMAIL),
  toEmail: String,
  toName: Optional(String),
  subject: String,
  headerText: Optional(String),
  mainText: String,
});

export type DisconnectReconnectCalendarNotificationJob = Static<
  typeof DisconnectReconnectCalendarNotificationJob
>;

export const DisconnectReconnectCalendarNotificationJob = AbstractNotificationJob.extend({
  type: Literal(NotificationTypes.DISCONNECT_RECONNECT_CALENDAR),
  calendarEmail: String, // Specific email that was used for calendar integration
  documentPath: String,
});

export type RequestCoachingNotificationJob = Static<typeof RequestCoachingNotificationJob>;
export const RequestCoachingNotificationJob = AbstractNotificationJob.extend({
  type: Literal(NotificationTypes.REQUEST_COACHING),
  toEmail: String,
  subject: String,
  mainText: String,
});

export type ZoodliQuotaReachedNotificationJob = Static<typeof ZoodliQuotaReachedNotificationJob>;
export const ZoodliQuotaReachedNotificationJob = AbstractNotificationJob.extend({
  type: Literal(NotificationTypes.ZOODLI_QUOTA_REACHED),
  userId: String,
  toEmail: String,
});

// a UserDoc could be any of the above NotificationJob subtypes.
export const NotificationJob = Union(
  NewCommentNotificationJob,
  SpeechInviteNotificationJob,
  FreeformEmailNotificationJob,
  DisconnectReconnectCalendarNotificationJob,
  DownloadPoodliMobileNotificationJob,
  RequestCoachingNotificationJob,
  ZoodliQuotaReachedNotificationJob
);
export type NotificationJob = Static<typeof NotificationJob>;

// a BQRow is a generic container we use to queue up events to stream
//  to bigquery.
export const BQRow = Record({
  ts: String, // timestamp, ISO format UTC
  userId: String,
  siteId: String,
  rowType: oneOf("EXPOSURE", "GA_EVENT", "USER_PROPERTIES"),
  jsonPayload: String, // JSON-compatible data.
});
export type BQRow = Static<typeof BQRow>;

export enum GenInterviewQuestionCategory {
  GENERAL = "Generalist",
  PRODUCT_MANAGER = "Product Manager",
  EDUCATION = "Education",
  LEARNING_AND_DEVELOPMENT = "Learning & Development",
  SOFTWARE_ENGINEER = "Software Engineer",
  TECHNICAL_PROGRAM_MANAGER = "Technical Program Manager",
  ENGINEERING_MANAGER = "Engineering Manager",
  PRODUCT_DESIGNER = "Product Designer",
  DATA_SCIENTIST = "Data Scientist",
  SOLUTIONS_ARCHITECT = "Solutions Architect",
  BIZOPS_STRATEGY = "BizOps & Strategy",
  MARKETING = "Marketing",
  FINANCE = "Finance",
  CONSULTING = "Consulting",
}
export const GenInterviewQuestionCategoryType = RTStringEnum(GenInterviewQuestionCategory);

export enum GenInterviewQuestionSubCategory {
  BEHAVIORAL = "Behavioral",
  TECHNICAL = "Technical",
  GENERAL = "General",
}
export const GenInterviewQuestionSubCategoryType = RTStringEnum(GenInterviewQuestionSubCategory);

export enum GenInterviewCompany {
  GOOGLE = "Google",
  GENERIC = "Generic",
  ACCENTURE = "Accenture",
  ADOBE = "Adobe",
  AMAZON = "Amazon",
  APPLE = "Apple",
  AT_AND_T = "AT&T",
  BANK_OF_AMERICA = "Bank of America",
  CHEVRON = "Chevron",
  CISCO = "Cisco",
  COSTCO = "Costco",
  CVS_HEALTH = "CVS Health",
  EBAY = "eBay",
  FORD_MOTORS = "Ford Motors",
  GENERAL_MOTORS = "General Motors",
  HEWLETT_PACKARD = "Hewlett-Packard",
  IBM = "IBM",
  INTEL = "Intel",
  INTUIT = "Intuit",
  KROGER = "Kroger",
  META = "Meta",
  MICROSOFT = "Microsoft",
  MORGAN_STANLEY = "Morgan Stanley",
  NVIDIA = "NVIDIA",
  ORACLE = "Oracle",
  SALESFORCE = "Salesforce",
  SAMSUNG = "Samsung",
  SAP = "SAP",
  SONY = "Sony",
  UBER = "Uber",
  UNITEDHEALTH = "UnitedHealth",
  VERIZON = "Verizon",
  WALMART = "Walmart",
  YAHOO = "Yahoo",
}

// interview questions we generated with GPT4
export const GenerativeInterviewQuestion = Record({
  question: String,
  subCategory: GenInterviewQuestionSubCategoryType,
  category: GenInterviewQuestionCategoryType,
});
export type GenerativeInterviewQuestion = Static<typeof GenerativeInterviewQuestion>;

/** DB Structure (for reference only; not used programmatically)
 * /sites/{siteId}
 * /sites/{siteId}/users/{userId}
 * /sites/{siteId}/users/{userId}/docs/{userDocType}
 * /sites/{siteId}/users/{userId}/aggregateAnalytics/{analyticsId}
 * /sites/{siteId}/users/{userId}/talkingPointTemplates/{templateId}
 * /sites/{siteId}/users/{userId}/selfConfidenceEvals/{evalId}
 * /sites/{siteId}/users/{userId}/userSkillFocuses/{skillFocusId}
 * /sites/{siteId}/users/{userId}/speeches/{speechId}
 * /sites/{siteId}/users/{userId}/speeches/{speechId}/comments/{commentId}
 * /sites/{siteId}/users/{userId}/speeches/{speechId}/remarks/{remarkId}
 * /sites/{siteId}/users/{userId}/speeches/{speechId}/analytics/{analyticsId}
 * /sites/{siteId}/users/{userId}/speeches/{speechId}/analyticsV2/{analyticsDocType}
 * /sites/{siteId}/users/{userId}/speeches/{speechId}/analyticsV2/cvProgress/cvProgressMarkers/{cvProgressId} <-- does not need to be retained, except for debugging purposes
 * /sites/{siteId}/users/{userId}/speeches/{speechId}/analyticsV2/toneAsync/toneAsyncProgressMarkers/{toneAsyncProgressId} <-- does not need to be retained, except for debugging purposes
 * /sites/{siteId}/users/{userId}/speeches/{speechId}/tags/tags
 * /sites/{siteId}/notificationJobs/{jobId}
 * /sites/{siteId}/bqRows/{insertId}
 * /sites/{siteId}/skillFocuses/{skillFocusSetId}/skillFocus/{skillFocusId}
 *
 * /interviewPrompts/{promptId}
 * /generativeInterviewQuestions/{questionId}
 * /emailNotifications   <-- deprecated?
 */

export const ZoomAccessToken = Record({
  access_token: String,
  token_type: String,
  refresh_token: String,
  expires_in: String,
  scope: String,
});
export type ZoomAccessToken = Static<typeof ZoomAccessToken>;

export const ZoomUser = AbstractDoc.extend({
  userRef: String,
  siteId: String,
  createdAt: String,
  accessTokenEncrypted: String,
  lastInstalled: Optional(String),
  nInstalls: Optional(Number),
  accountNumber: Optional(Number),
});
export type ZoomUser = Static<typeof ZoomUser>;

export const OneTimeCode = AbstractDoc.extend({
  authToken: String,
  createdAt: String,
});

export type OneTimeCode = Static<typeof OneTimeCode>;

// it's a bit of overkill to have skillFocuses as a nested subcollection right now, but we suspect it will be more useful in the future.
export const SkillFocusSet = Record({
  name: String,
});
export type SkillFocusSet = Static<typeof SkillFocusSet>;

export const SkillFocus = Record({
  analytic: RTStringEnum(SpeechAnalyticIDs),
  lessThan: Optional(Number),
  greaterThan: Optional(Number),
  equalTo: Optional(Number),
  nSpeeches: Number,
  skillFocusOrdinal: Number,
});
export type SkillFocus = Static<typeof SkillFocus>;

// skillFocus progress
export const UserSkillFocus = Record({
  skillFocusPath: String,
  startDate: String, // iso string
  endDate: String, // iso string
  completed: Boolean,
  acknowledged: Boolean,

  // for progress tracking
  relevantSpeeches: RTArray(
    Record({
      speechPath: String,
      completed: Boolean,
    })
  ),
});

export type UserSkillFocus = Static<typeof UserSkillFocus>;

// bot style guide; if this exists and is active, it's included with every bot prompt
export const StyleGuide = Record({
  createdAt: String,
  botId: String,
  tokenLength: Optional(Number), // if set, this is the token length of the content
  isActive: Boolean,
  content: String,
});
export type StyleGuide = Static<typeof StyleGuide>;

export const CoachBot = Record({
  isActive: Boolean,
  name: String,
  creatorEmail: String,
  createdAt: String,
  botId: String,
  isDefault: Optional(Boolean),
  scenarioIds: Optional(RTArray(String)),
  styleGuide: Optional(StyleGuide),
  activeOrgWide: Optional(Boolean),
  botSubType: Optional(SpeechLayout),
  completionEmailSent: Optional(Boolean),
});
export type CoachBot = Static<typeof CoachBot>;

export const BotContent = Record({
  filename: String,
  fileType: String,
  totalTokenLength: Optional(Number),
  rawContentPath: Optional(String),
  createdAt: String,
  isActive: Boolean,
  status: RTAnalyticProcessingState,
  errorReason: Optional(String),

  // Fields for auto generation
  // Most fields exist in all newer contents, but keep them optional
  // for older contents until we run the new process for them.
  preprocessState: Optional(RTAnalyticProcessingState),
  preprocessedPath: Optional(String),
  preprocessErrorReason: Optional(String),
  materialType: Optional(RTMaterialType),
  scenarioGenerationState: Optional(RTAutoGenerationState),
  goalGenerationState: Optional(RTAutoGenerationState),
});
export type BotContent = Static<typeof BotContent>;

export const BotContentExtract = Record({
  title: Optional(String), // if this doesn't exist we're still processing
  tokenLength: Number,
  contentPath: String, // google storage path, should be 1:1 with object path
  createdAt: String,
  botId: String, // for queryability, can be infered by path normally
  ignorable: Optional(Boolean), // various types of text we want to ignore, including index files
});
export type BotContentExtract = Static<typeof BotContentExtract>;

// unclear if we will have others (eg interview may be conversation)
export enum ScenarioUX {
  Conversation = "conversation",
  Presentation = "presentation",
}
export const ScenarioUXType = RTStringEnum(ScenarioUX);

export const ScenarioBase = Record({
  id: String,
  scenarioTypeId: ScenarioTypeId,
  templateSubType: RTScenarioTemplateSubType,
  description: String,
  title: String,
  templateDefiningPromptDetails: String,
  userProvidedContext: String,
  enabled: Boolean, // default false
  aiConcerns: Optional(RTArray(String)),
  autoEndEnabled: Optional(Boolean), // if set to true, the AI will be able to end the conversation
  scenarioUX: ScenarioUXType,
  botId: Optional(String),
  sourceMetadata: Optional(Dictionary(Unknown)),

  // postprocessed values
  emailDomain: Optional(String), // if set, this is the email domain that the persona will pick up to use in the scenario

  // goals
  goalIds: Optional(RTArray(Union(DefaultGoalType, String))),
  talkingPoints: Optional(RTArray(String)), // ignored if there is no talking points goal in goalIds
  targetTimeS: Optional(Number), // ignored if there is no time target goal in goalIds
  goalWeights: Optional(Dictionary(Number, String)), // goalId to weight. weights should be integers which sum to 100, 08/28/24: There is potential for this field to be stale transiently, i.e. have goalId's that no longer exist
  lockedWeightGoalIds: Optional(RTArray(Union(DefaultGoalType, String))), // goalIds that should be locked in the UI, 08/28/24: There is potential for this field to be stale transiently, i.e. have goalId's that no longer exist
  goalRenderOrder: Optional(RTArray(Union(DefaultGoalType, String))), // goalIds in the order they should be rendered, 08/28/24: There is potential for this field to be stale transiently, i.e. have goalId's that no longer exist

  // manager training
  relationshipWithUser: Optional(String), // these should be pretty static, if set. eg direct report, peer, etc. Defines the conversation partner's relationship with the user
  pregeneratedProblemStatements: Optional(RTArray(String)), // for now, we randomly select one of these to use as the problem statement

  activeHubs: Optional(RTArray(String)), // while this should never be defined for a template, it is better to include here as we will be using the unified type and want to access it without a type check
  userId: Optional(String), // if set, this is the user who owns the scenario

  /**
   * If set, this is the AI's time until it has a "hard stop" and will end the conversation.
   */
  aiTimeLimitS: Optional(Number),

  defaultPersonaId: String,
  personaIds: Optional(RTArray(String)), // TODO this shouldn't be optional, but leaving as such for now to avoid breaking changes,

  /** interview scenario specific; the role the user is interviewing for */
  defaultRole: Optional(String),
  /** interview scenario specific; the company the user is interviewing with */
  defaultCompany: Optional(String),
});

export type ScenarioBase = Static<typeof ScenarioBase>;

export const ScenarioTemplate = ScenarioBase.extend({
  isTemplate: Literal(true),
});
export type ScenarioTemplate = Static<typeof ScenarioTemplate>;

// note that this is not amazingly named now since it also describes an end user scenario
export const OrgScenario = ScenarioBase.extend({
  isTemplate: Literal(false),
  createdAt: String,
  createdByEmail: Union(String, Null),
  modifiedAt: Optional(String),
  modifiedByEmail: Optional(Union(String, Null)),
  copiedFromOrg: Optional(Boolean), // is the user scenario copied from an org?
});
export type OrgScenario = Static<typeof OrgScenario>;

export const Scenario = Union(ScenarioTemplate, OrgScenario);
export type Scenario = Static<typeof Scenario>;

export enum DemeanorEnum {
  Friendly = "friendly",
  Critical = "critical",
  Empathetic = "empathetic",
  Enthusiastic = "enthusiastic",
  Analytical = "analytical",
  Curious = "curious",
  Assertive = "assertive",
  Blunt = "blunt",
  Skeptical = "skeptical",
  Neutral = "neutral",
  Casual = "casual",
}

export const Demeanor = RTStringEnum(DemeanorEnum);

export const Persona = Record({
  id: String,
  name: String,
  jobTitle: String,
  demeanor: Demeanor,
  voiceId: String,
  additionalBackground: RTArray(String),
  isActive: Boolean,
  isTemplate: Boolean,
  createdAt: String,
  updatedAt: String,
  profilePictureId: Optional(String),
  userId: Optional(String), // if set, this is the user who owns the persona
  copiedFromId: NullableOptional(String), // if set, this is the (org) persona that this persona was copied from
  sourceMetadata: Optional(Dictionary(Unknown)),
});
export type Persona = Static<typeof Persona>;

export const PersonaProfilePicture = Record({
  id: String,
  extension: String,
  filepath: String,
  associatedPersonaIds: RTArray(String),
  createdAt: String,
});
export type PersonaProfilePicture = Static<typeof PersonaProfilePicture>;

export enum VocalTone {
  // Positive tones
  Confident = "confident",
  Warm = "warm",
  Enthusiastic = "enthusiastic",
  Assertive = "assertive",
  Calm = "calm",
  Friendly = "friendly",
  Empathetic = "empathetic",
  Persuasive = "persuasive",
  Passionate = "passionate",
  Relaxed = "relaxed",
  Sincere = "sincere",
  Authoritative = "authoritative",
  Encouraging = "encouraging",
  Engaging = "engaging",
  Humorous = "humorous",
  Respectful = "respectful",
  Serious = "serious",
  Inspirational = "inspirational",
  Clear = "clear",
  Professional = "professional",

  // Negative tones
  Monotonous = "monotonous",
  Harsh = "harsh",
  Unenthusiastic = "unenthusiastic",
  Condescending = "condescending",
  Bored = "bored",
  Nervous = "nervous",
  Abrasive = "abrasive",
  Overbearing = "overbearing",
  Unsteady = "unsteady",
  Insincere = "insincere",
  Forceful = "forceful",
  Disengaged = "disengaged",
  Rushed = "rushed",
  Sarcastic = "sarcastic",
  Unclear = "unclear",
  Tense = "tense",
  Dismissive = "dismissive",
  Timid = "timid",
}
export const VocalToneType = RTStringEnum(VocalTone);

// #endregion Default region
// #endregion Default region
