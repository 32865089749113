// Components
import { Card } from "@mui/material";
import { styled } from "@mui/system";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

export const ContentCarouselWrapper = styled("div")`
  width: 100%;
  & .control-dots .dot {
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity=30);
    background: var(--white);
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
  }
  .carousel-root {
    outline: none;
  }
  .carousel {
    position: relative;
    width: 100%;
    & * {
      box-sizing: border-box;
    }

    &.carousel-slider {
      position: relative;
      margin: 0;
      overflow: hidden;
      display: flex;
    }
    & .slider-wrapper {
      overflow: hidden;
      margin: auto;
      width: 100%;
      transition: height 0.15s ease-in;
      &.axis-horizontal .slider {
        display: flex;
      }
      &.axis-horizontal .slider .slide {
        flex-direction: column;
        flex-flow: column;
      }
      &.axis-vertical {
        display: flex;
      }
      &.axis-vertical .slider {
        flex-direction: column;
      }
    }
    & .slider {
      margin: 0;
      padding: 0;
      position: relative;
      list-style: none;
      width: 100%;
    }
    & .slider.animated {
      transition: all 0.35s ease-in-out;
    }
    & .slide {
      min-width: 100%;
      margin: 0;
      position: relative;
      text-align: center;
      padding: 2px;
    }
    & .slide iframe {
      display: inline-block;
      width: calc(100% - 80px);
      margin: 0 40px 40px;
      border: 0;
    }
    & .slide .legend {
      transition: all 0.5s ease-in-out;
      position: absolute;
      bottom: 40px;
      left: 50%;
      margin-left: -45%;
      width: 90%;
      border-radius: 10px;
      background: #000;
      color: var(--white);
      padding: 10px;
      font-size: 12px;
      text-align: center;
      opacity: 0.25;
      transition: opacity 0.35s ease-in-out;
    }
    & .control-dots {
      margin: 10px 0;
      padding: 0;
      text-align: center;
      width: 100%;
      z-index: 1;
    }
    @media (min-width: 960px) {
      & .control-dots {
        bottom: 0;
      }
    }
    & .control-dots .dot {
      transition: opacity 0.25s ease-in;
      opacity: 0.3;
      filter: alpha(opacity=30);
      background: var(--white);
      border-radius: 50%;
      width: 8px;
      height: 8px;
      cursor: pointer;
      display: inline-block;
      margin: 0 8px;
    }
    & .control-dots .dot.selected,
    & .control-dots .dot:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
    &:hover .slide .legend {
      opacity: 1;
    }
    & button.control-arrow {
      border: none;
      cursor: pointer;
      background: none;
    }
    & .control-arrow {
      :before {
        border: 1px solid ${getDynamicColor("dark3")};
      }
      :hover {
        :before {
          border: 1px solid ${getDynamicColor("primary")};
        }
      }
    }
    & .control-next.control-arrow:before {
      content: "";
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 8px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    & .control-prev.control-arrow:before {
      content: "";
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 8px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
`;

export const VideoContainerHighlightWrapper = styled(Card)`
  border: 1px solid var(--border-light-gray);
  border-radius: 12px;
  box-shadow: none;
  position: relative;
  display: flex;
  flex-direction: column;
  &:hover {
    outline: 2px solid ${getDynamicColor("secondary")};
    cursor: pointer;
  }
  & .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
