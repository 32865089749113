// show confirm modal on reload
export const initBeforeUnLoad = (shouldBeforeUnload: boolean): string | void => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (shouldBeforeUnload) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
};
