// Utils
import { supportEmailTag } from "./pricingConstants";
import { FaqType } from "lib-frontend/types/FAQTypes";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { AFFILIATE_EMAIL, SALES_EMAIL } from "lib-fullstack/utils/constants";
import {
  getLandingPageExternalUrl,
  getWebServerExternalUrl,
  LandingPageExternalPath,
  WebServerExternalPath,
} from "lib-fullstack/utils/paths";
import { UsagePlanType, PricingExperiment } from "lib-fullstack/utils/pricingTypes";

const clientEnv = getClientEnvConfig();

export type PricingCardType = {
  title?: string;
  pricingSubtext?: string;
  isRecommended?: boolean;
  cost: {
    monthly?: string;
    annually?: string;
    totalSavings?: string;
  };
  description: string;
  bulletPoints: string[];
  sectionHeader?: string;
  showSavings?: boolean;
  cta: {
    copy: string;
    url: string;
    onClick?: () => void;
  };
  id: number;
  type: UsagePlanType;
};

export enum PlanChangeType {
  UPGRADE = "upgrade",
  DOWNGRADE = "downgrade",
}

export enum SettingsTab {
  GENERAL = "general",
  BILLING = "billing",
}

export enum PricingDurationOption {
  MONTHLY = "monthly",
  ANNUALLY = "annually",
}

export const pdfFileURL =
  "https://storage.googleapis.com/yoodli-public/webclient-assets/Yoodli%20Corporate%20Reimbursement.pdf";

const salesEmailTag = `<a href="mailto:${SALES_EMAIL}">${SALES_EMAIL}</a>`;
const affiliateEmailTag = `<a href="mailto:${AFFILIATE_EMAIL}?subject=Affiliate%20Program%20Inquiry">${AFFILIATE_EMAIL}</a>`;

export const PRICING_FAQ_DATA: FaqType[] = [
  {
    question: "What counts as a Yoodli?",
    answer:
      "A Yoodli is a speech or interview over 30 seconds. You'll receive AI powered insights on your content, monologues, filler words, pacing, and more.",
    id: 0,
  },
  {
    question: "How can I reimburse Yoodli with my employer?",
    answer: `Click <a target="_blank" href=${pdfFileURL}>here</a> to download a reimbursement letter. Submit this along with your invoice (downloadable from your account) to your employer. Over 90% of companies cover the cost of Yoodli as part of professional development benefits.`,
    id: 1,
  },
  {
    question: "How can I buy Yoodli for my company?",
    answer: `Reach out to ${salesEmailTag} for enterprise pricing. Our enterprise package comes with bulk discounts, org-wide privacy, and single sign on functionality.`,
    id: 2,
  },
  {
    question: "How can I change my Yoodli subscription?",
    answer:
      "In order to change your subscription, sign in to Yoodli and click on the billing tab within settings. We don't offer refunds in the event of a cancellation. You will continue to have access to your Yoodli plan until the end of the billing period.",
    id: 3,
  },
  {
    question: "Do you have an affiliate program?",
    answer: `Yes! Our affiliate program pays you a commission each time someone redeems your coupon code. Learn more at <a href="/ambassadors">www.yoodli.ai/ambassadors</a>. Reach out at ${affiliateEmailTag} to get started.`,
    id: 4,
  },
  {
    question: "Do you offer discounts for NGOs or universities?",
    answer: `Yes! Email us at ${supportEmailTag} to get setup with our NGO or academia plan.`,
    id: 5,
  },
];

const PRO_ADV_PRICING_DATA: PricingCardType[] = [
  {
    type: UsagePlanType.FREE,
    title: "Free",
    cost: {
      monthly: "$0",
      annually: "$0",
    },
    pricingSubtext: "forever free",
    bulletPoints: [
      "Up to 5 Yoodlis",
      "Practice, upload, and live AI coaching",
      "No credit card required",
    ],
    description: "I'm looking around",
    cta: {
      copy: "Get started",
      url: getWebServerExternalUrl(clientEnv, WebServerExternalPath.SIGN_UP),
    },
    id: 0,
  },
  {
    type: UsagePlanType.PRO_V1,
    title: "Pro",
    pricingSubtext: "/ month",
    bulletPoints: ["10 Yoodlis / week", "Early access to new features"],
    showSavings: true,
    cost: {
      monthly: "$11",
      annually: "$8",
      totalSavings: "40%",
    },
    description: "I'm ready to improve",
    cta: {
      copy: "Subscribe now",
      url:
        getWebServerExternalUrl(clientEnv, WebServerExternalPath.ACCOUNT) +
        `?tab=${SettingsTab.BILLING}`,
    },
    id: 1,
  },
  {
    type: UsagePlanType.UNLIMITED,
    title: "Advanced",
    isRecommended: true,
    pricingSubtext: "/ month",
    description: "For corporate professionals",
    bulletPoints: [
      "Unlimited Yoodlis",
      "Your data is automatically excluded from AI training models",
      `<a target="_blank" href=${pdfFileURL}>Reimbursable</a> by employer as a learning benefit`,
    ],
    showSavings: true,
    cost: {
      monthly: "$28",
      annually: "$20",
      totalSavings: "30%",
    },
    cta: {
      copy: "Subscribe now",
      url:
        getWebServerExternalUrl(clientEnv, WebServerExternalPath.ACCOUNT) +
        `?tab=${SettingsTab.BILLING}`,
    },
    id: 2,
  },
  {
    type: UsagePlanType.ENTERPRISE,
    title: "Enterprise",
    pricingSubtext: "",
    bulletPoints: [
      "Custom branding/logo, custom content",
      "SSO (SAML), Advanced privacy, data retention",
      "Dedicated account manager",
    ],
    cost: {
      monthly: "Let's talk",
      annually: "Let's talk",
    },
    description: "For coaches and businesses",
    cta: {
      copy: "Contact sales",
      url: getLandingPageExternalUrl(clientEnv, LandingPageExternalPath.CONTACT_SALES),
    },
    id: 3,
  },
];

const CRUNCH_MODE_PRICING_DATA: PricingCardType[] = [
  PRO_ADV_PRICING_DATA[0],
  {
    type: UsagePlanType.UNLIMITED,
    title: "Growth",
    isRecommended: true,
    pricingSubtext: "/ month",
    description: "I want to improve",
    bulletPoints: [
      "Unlimited Yoodlis",
      "Your data is automatically excluded from AI training models",
      `<a target="_blank" href=${pdfFileURL}>Reimbursable</a> by employer as a learning benefit`,
    ],
    showSavings: true,
    cost: {
      annually: "$10",
    },
    cta: {
      copy: "Subscribe now",
      url:
        getWebServerExternalUrl(clientEnv, WebServerExternalPath.ACCOUNT) +
        `?tab=${SettingsTab.BILLING}`,
    },
    id: 1,
  },
  {
    type: UsagePlanType.UNLIMITED,
    title: "Time Crunch",
    pricingSubtext: "/ month",
    bulletPoints: [
      "Unlimited Yoodlis",
      "Your data is automatically excluded from AI training models",
    ],
    showSavings: true,
    cost: {
      monthly: "$20",
    },
    description: "I need urgent help",
    cta: {
      copy: "Subscribe now",
      url:
        getWebServerExternalUrl(clientEnv, WebServerExternalPath.ACCOUNT) +
        `?tab=${SettingsTab.BILLING}`,
    },
    id: 2,
  },
  {
    type: UsagePlanType.ENTERPRISE,
    title: "Enterprise",
    pricingSubtext: "",
    bulletPoints: [
      "Customizable around your coaching method",
      "Custom branding/logo",
      "SSO (SAML), Advanced privacy, data retention",
      "Dedicated account manager",
    ],
    cost: {
      monthly: "Let's talk",
      annually: "Let's talk",
    },
    description: "For coaches and businesses",
    cta: {
      copy: "Contact sales",
      url: getLandingPageExternalUrl(clientEnv, LandingPageExternalPath.CONTACT_SALES),
    },
    id: 3,
  },
];

export const PRICING_EXPERIMENT_CONFIG: {
  [key: string]: { cards: PricingCardType[]; toggleAnnualMonthly: boolean; seeDetailsText: string };
} = {
  [PricingExperiment.PRO_ADV]: {
    cards: PRO_ADV_PRICING_DATA,
    toggleAnnualMonthly: true,
    seeDetailsText: "how to get reimbursed by your employer",
  },
  [PricingExperiment.CRUNCH_GROWTH]: {
    cards: CRUNCH_MODE_PRICING_DATA,
    toggleAnnualMonthly: false,
    seeDetailsText: "how to get growth mode reimbursed by your employer",
  },
};
