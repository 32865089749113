import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Components
import { EventAvailable as EventAvailableIcon, Warning as WarningIcon } from "@mui/icons-material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Box,
  Stack,
  Typography,
  Container,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { ContainedLoadingAnimation } from "lib-frontend/components/Animations/LoadingAnimation";
import CalendarEvents from "lib-frontend/components/Calendar/CalendarEvents";

// Utils
import CalendarAuth from "./CalendarAuth";
import { useOnAll } from "@typesaurus/react";
import {
  changeCalendarMode,
  disconnectCalendar,
  addOutlookCalendar,
  syncCalendarEvents,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { getLiveUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { animatedEllipsisStyles } from "lib-frontend/utils/StyleUtils";
import { isWhiteLabel, launchPoodliWithCalConnectError } from "lib-frontend/utils/Utilities";
import { OBQ1Option } from "lib-fullstack/utils/enums";
import {
  CalendarAnalyticsEvents,
  EventsCalHows,
  EventsCalWheres,
} from "lib-fullstack/utils/productAnalyticEvents";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { UITestId } from "lib-fullstack/utils/enums";

type Props = {
  dbCalendars?: db.Doc<db.Calendar>[];
  dbCalendarsMeta?: { loading: boolean; error: unknown };
  currCalendarMode?: db.CalendarMode;
  connectCalendarTitle?: string;
  shouldDisplayOutlook?: boolean;
  isHomePage?: boolean;
  googleAuthProviderReady?: boolean;
};

export default function Calendar({
  dbCalendars,
  dbCalendarsMeta,
  currCalendarMode,
  connectCalendarTitle,
  shouldDisplayOutlook,
  isHomePage,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const [scrolled, setScrolled] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean | "cal-switch">(false);
  const [error, setError] = React.useState<boolean>(false);
  const [showPoodliPrompt, setShowPoodliPrompt] = React.useState<boolean>(true);

  const [dbEvents, dbEventsMeta] = useOnAll(
    db.calendarEvents([getSiteId(), firebase.auth()?.currentUser?.uid, "calendar_default"])
  );

  const userDocMain = getLiveUserDocMain();

  if (dbEventsMeta.error) {
    console.error(dbEventsMeta.error);
    throw new Error(dbEventsMeta.error.toString());
  }

  React.useEffect(() => {
    if (location.search.includes("code")) {
      const code = new URLSearchParams(location.search).get("code");
      const state = new URLSearchParams(location.search).get("state");
      if (state !== "google") {
        setLoading(true);
        const calConnectLocation = state
          ? EventsCalWheres.POODLI
          : isHomePage
          ? EventsCalWheres.HOME
          : EventsCalWheres.MEETINGS;
        addOutlookCalendar(
          code,
          location.pathname,
          (state as db.CalendarMode) ?? currentCalendarMode
        )
          .then(async () => {
            setLoading(false);
            setError(false);
            await syncCalendarEvents();
            Instrumentation.logCalConnectedCheckpoint(EventsCalHows.OUTLOOK, calConnectLocation);
          })
          .catch((err) => {
            setLoading(false);
            setError(true);
            console.error(err);
            if (calConnectLocation === EventsCalWheres.POODLI) {
              launchPoodliWithCalConnectError(err);
            }
          });
      }
    }
  }, []);

  const currentCalendarMode: db.CalendarMode =
    currCalendarMode ?? dbCalendars?.length
      ? (dbCalendars[0].data.mode as db.CalendarMode)
      : db.CalendarMode.POODLI;

  const defaultJoin = dbCalendars?.length ? dbCalendars[0].data.defaultJoin : "none";

  const handleDisconnect = async () => {
    setLoading(true);
    await disconnectCalendar(dbCalendars[0].ref);
    Instrumentation.logCalDisconnectedCheckpoint(EventsCalWheres.MEETINGS);
    setLoading(false);
  };

  const handleSwitchToPrivateYoodli = async () => {
    try {
      setLoading("cal-switch");
      // fire this first, as the calendar swap can take a while
      Instrumentation.logAmplitudeEvent(CalendarAnalyticsEvents.SWITCH_POODLI);
      await changeCalendarMode(dbCalendars[0].ref, db.CalendarMode.POODLI);

      setShowPoodliPrompt(false);
      setLoading(false);
    } catch (er) {
      console.warn("Error switching calendar to the desktop app: ", er);
      // make sure the prompt stays visible so that the user can try again
      setShowPoodliPrompt(true);
      throw new Error(er);
    }
  };

  if (loading || dbCalendarsMeta.loading || dbEventsMeta.loading) {
    return (
      <Stack
        width="100%"
        height="auto"
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={4}
        sx={{
          height: "100%",
          color: getDynamicColor("dark4"),
          minHeight: 560,
        }}
        data-testid={UITestId.LoadingAnimation}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 700,
            px: 4,
            pt: { xs: 6, md: 0 },
            textAlign: "center",
            ...animatedEllipsisStyles,
          }}
        >
          {loading === "cal-switch" ? "Switching to the desktop app" : "Loading calendar"}
        </Typography>
        {isHomePage ? <CircularProgress /> : <ContainedLoadingAnimation showPrompt={false} />}
      </Stack>
    );
  }

  const showSwitchToPoodliPopup =
    currentCalendarMode === db.CalendarMode.ZOODLI &&
    userDocMain?.poodliStatuses?.poodliOnboardingComplete &&
    userDocMain?.onboardingAnswers?.eventsFocus !== OBQ1Option.COACH &&
    !isWhiteLabel() &&
    showPoodliPrompt;

  return dbCalendars && dbCalendars.length ? (
    <Stack
      direction="column"
      spacing={3}
      width="100%"
      sx={{
        color: getDynamicColor("purple3"),
        height: "100%",
      }}
    >
      <Box
        justifyContent="center"
        display="flex"
        width="100%"
        position="relative"
        overflow="hidden"
        sx={{
          backgroundColor: "transparent",
          height: "100vh",
        }}
      >
        {dbCalendars[0].data.inactive && (
          <Box
            position="absolute"
            top={0}
            right={0}
            left={0}
            bottom={0}
            zIndex={10}
            sx={{
              backgroundColor: getDynamicColor("dark6", 0.5),
            }}
          >
            <Stack
              direction="column"
              spacing={3}
              justifyContent="center"
              height="100%"
              alignItems="center"
              sx={{
                backgroundColor: getDynamicColor("dark2", 0.75),
                p: 1,
              }}
            >
              <WarningIcon
                color="warning"
                sx={{
                  height: 60,
                  width: 60,
                }}
              />
              <Typography
                fontWeight={700}
                fontSize={{ xs: "1em", sm: "1.5em" }}
                textAlign="center"
                maxWidth={270}
                sx={{
                  mt: "8px !important",
                }}
              >
                Oops, your calendar is out of sync!
              </Typography>
              <Typography
                textAlign="center"
                maxWidth={300}
                fontWeight={600}
                fontSize={{ xs: "0.8em", sm: "1em" }}
                sx={{
                  mt: "8px !important",
                }}
              >
                We recommend disconnecting and reconnecting it to Yoodli :)
              </Typography>
              <Button onClick={handleDisconnect} variant="contained">
                Let's do it
              </Button>
            </Stack>
          </Box>
        )}

        {showSwitchToPoodliPopup && (
          <Box
            position="absolute"
            top={0}
            right={0}
            left={0}
            bottom={0}
            zIndex={10}
            sx={{
              backgroundColor: getDynamicColor("dark6", 0.5),
            }}
            display="flex"
            alignItems="center"
          >
            <Box
              mx={{ xs: 4, md: 6 }}
              zIndex={10}
              sx={{
                backgroundColor: getDynamicColor("light1"),
                borderRadius: "12px",
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                p={{ xs: 4, md: 6 }}
                sx={{
                  borderRadius: "12px",
                }}
              >
                <Typography fontWeight={700} fontSize={{ xs: 16, md: 18 }}>
                  Looks like you downloaded <br /> the desktop app!
                </Typography>
                <Typography fontWeight={500} fontSize={{ xs: 14, md: 16 }}>
                  Connect your calendar to the desktop app to automatically record yourself and get
                  analytics.
                </Typography>
                <Button onClick={handleSwitchToPrivateYoodli} variant="contained">
                  Connect to Desktop App
                </Button>
                <Button
                  onClick={() => {
                    setShowPoodliPrompt(false);
                    Instrumentation.logAmplitudeEvent(CalendarAnalyticsEvents.STAY_ZOODLI);
                  }}
                >
                  Stay connected to Team Yoodli
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
        <Stack direction="column" width="100%" minHeight="400px">
          <Container
            maxWidth="lg"
            sx={{
              position: "relative",
              zIndex: 2,
              pb: {
                xs: 0,
                md: 0,
              },
              pt: {
                xs: isHomePage ? 2.5 : 3,
                md: isHomePage ? 2.5 : 4,
              },
              boxShadow: scrolled ? Y_SHADOWS.box_shadow_1 : "none",
              transition: "box-shadow 0.2s ease-in-out",
            }}
          >
            {!isHomePage && (
              <Stack justifyContent="space-between" alignItems="flex-start" direction="column">
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={{
                    color: getDynamicColor("greenSuccess"),
                  }}
                >
                  <EventAvailableIcon
                    sx={{
                      height: 18,
                      width: 18,
                    }}
                  />
                  <Typography fontSize={13}>
                    Connected to{" "}
                    {currentCalendarMode === db.CalendarMode.ZOODLI ? "Team Yoodli" : "Desktop App"}
                  </Typography>
                </Stack>
                {currentCalendarMode === db.CalendarMode.ZOODLI && !isWhiteLabel() && (
                  <Button
                    onClick={handleSwitchToPrivateYoodli}
                    variant="contained"
                    sx={{
                      backgroundColor: getDynamicColor("light2"),
                      color: getDynamicColor("primary"),
                      alignSelf: "center",
                      fontSize: 12,
                      width: "min(100%, 400px)",
                      "&:hover": {
                        backgroundColor: getDynamicColor("purple1"),
                      },
                    }}
                  >
                    Switch your calendar to the desktop app
                  </Button>
                )}
              </Stack>
            )}
            <Stack direction="row" justifyContent="space-between" alignItems="center" pt={1}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: getDynamicColor("purple3"),
                }}
              >
                Yoodli auto join
              </Typography>
              <IconButton
                onClick={() => navigate(WebServerExternalPath.ACCOUNT)}
                sx={{
                  backgroundColor: getDynamicColor("primary"),
                  transition: "color 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: getDynamicColor("primary"),
                  },
                  mr: { xxl: 4 },
                  width: "36px",
                  height: "36px",
                }}
              >
                <SettingsOutlinedIcon sx={{ color: getDynamicColor("light1") }} />
              </IconButton>
            </Stack>
            <Typography
              sx={{
                pt: 1,
                pr: 1,
                pb: 3,
                fontSize: 12,
                fontWeight: 500,
                maxWidth: "min(400px, 100%)",
                color: getDynamicColor("purple3"),
              }}
            >
              You must have the Yoodli desktop app installed to enable autostart on calls you want
              feedback on. Recordings are audio only.
            </Typography>
          </Container>
          <CalendarEvents
            setScrolled={setScrolled}
            dbEvents={dbEvents}
            eventsLoading={dbEventsMeta?.loading}
            defaultJoin={defaultJoin}
            currentCalendarMode={currentCalendarMode}
            setLoading={setLoading}
          />
        </Stack>
      </Box>
    </Stack>
  ) : (
    <CalendarAuth
      error={error}
      setError={setError}
      setLoading={setLoading}
      mode={currentCalendarMode}
      connectCalendarTitle={connectCalendarTitle}
      shouldDisplayOutlook={shouldDisplayOutlook}
      calConnectedWhereProperty={isHomePage ? EventsCalWheres.HOME : EventsCalWheres.MEETINGS}
    />
  );
}
