import firebase from "firebase/app";
import React from "react";

// Components
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { YoodliMultiSelect } from "lib-frontend/components/YoodliComponents/YoodliMultiSelect";
import {
  YoodliSelect,
  YoodliSelectOption,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { useUpdateCoachBot } from "./useUpdateCoachBot";
import { useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import { listScenarios } from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import {
  GetCoachBotResponse,
  GetScenarioResponse,
  ListCoachBotsResponse,
} from "lib-fullstack/api/hubApiTypes";

type CoachBotSettingsProps = {
  coachBot: GetCoachBotResponse;
  orgId: string;
  setCoachBot: (coachBot: GetCoachBotResponse) => void;
  setShowStyleGuide: (showStyleGuide: boolean) => void;
  currentSelectedScenarios: GetScenarioResponse[];
  styleGuideContent?: string;
};

export type ScenarioInfo = {
  scenarioId: string;
  scenarioName: string;
  coachBotName?: string;
};
export const CoachBotSettings = ({
  coachBot,
  setCoachBot,
  setShowStyleGuide,
  orgId,
  currentSelectedScenarios,
  styleGuideContent,
}: CoachBotSettingsProps): JSX.Element => {
  const queryClient = useQueryClient();
  const isSmallScreen = useIsSmallScreen();
  const { handleUpdateCoachBot, handleUpdateCoachBotLoading } = useUpdateCoachBot();
  // name
  const [editingCoachBotName, setEditingCoachBotName] = React.useState(false);
  const [newCoachBotName, setNewCoachBotName] = React.useState(coachBot.name);
  const [savingCoachBotName, setSavingCoachBotName] = React.useState(false);
  // visiblity
  const [visibleToSelectOpen, setVisibleToSelectOpen] = React.useState(false);
  const [coachBotVisibleTo, setCoachBotVisibleTo] = React.useState("placeholder");
  // scenarios
  const [editingScenarios, setEditingScenarios] = React.useState<boolean>(false);
  const [selectedScenarioIds, setSelectedScenarioIds] = React.useState<string[]>(
    (currentSelectedScenarios ?? []).map((scen) => scen.id)
  );
  const coachbotsData: ListCoachBotsResponse = queryClient.getQueryData([
    CustomizePracticeQueryKey.Coachbots,
    orgId,
  ]);
  const { data: scenariosData, isPending: scenariosLoading } = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Scenarios, orgId],
    queryFn: async () => await listScenarios(orgId),
    enabled: !!firebase.auth().currentUser?.uid && !!orgId,
    refetchOnWindowFocus: false,
  });

  const enabledScenarios = React.useMemo(() => {
    return (scenariosData?.contentArray as GetScenarioResponse[])
      ?.filter((scenario) => {
        return !scenario.isTemplate && scenario.enabled;
      })
      .map((scenario) => {
        return {
          scenarioId: scenario.id,
          scenarioName: scenario.title,
          coachBotName:
            coachbotsData.coachBots.find((bot) => bot.botId === scenario.botId)?.name ??
            "None assigned",
        } as ScenarioInfo;
      });
  }, [scenariosData]);

  React.useEffect(() => {
    let newVisibleTo = "nobody";
    if (coachBot.isActive && coachBot.activeOrgWide) {
      newVisibleTo = "everybody";
    } else if (coachBot.isActive) {
      newVisibleTo = "adminsOnly";
    }
    setCoachBotVisibleTo(newVisibleTo);
  }, [coachBot]);

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (editingCoachBotName) {
        if (e.key === "Enter") {
          handleSaveCoachBotName().catch((er) => {
            console.error(`Error saving coachbot name: ${er}`);
          });
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [editingCoachBotName, newCoachBotName, coachBot, orgId]);
  const [savingCoachBotVisibility, setSavingCoachBotVisibility] = React.useState(false);

  const handleSaveCoachBotName = async () => {
    if (newCoachBotName !== coachBot.name) {
      if (newCoachBotName.length !== 0) {
        setSavingCoachBotName(true);
        await handleUpdateCoachBot({
          orgId,
          botId: coachBot.botId,
          updateOptions: { name: newCoachBotName },
          isDefault: coachBot.isDefault,
        }).catch((er) => {
          console.error(`Error updating coachbot name: ${er}`);
        });
        setCoachBot({
          ...coachBot,
          name: newCoachBotName,
        });
        setSavingCoachBotName(false);
      } else {
        setNewCoachBotName(coachBot.name);
      }
      setEditingCoachBotName(false);
    }
  };
  const renderEditNameButton = () => {
    return (
      <Button
        variant="text"
        onClick={async () => {
          if (editingCoachBotName) {
            handleSaveCoachBotName().catch((er) => {
              console.error(`Error saving coachbot name: ${er}`);
            });
          } else {
            setEditingCoachBotName(true);
          }
        }}
        sx={{
          fontSize: 14,
        }}
        endIcon={savingCoachBotName ? <CircularProgress size={12} /> : null}
      >
        {editingCoachBotName ? "Save" : "Edit"} name
      </Button>
    );
  };
  const renderName = () => {
    return (
      <Stack
        direction="column"
        sx={{
          pb: 2,
        }}
        gap={{ xs: 0.5, md: 0 }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{
            xs: "space-between",
            md: "flex-start",
          }}
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 600,
              fontFamily: "poppins",
              color: getDynamicColor("dark5"),
              lineHeight: 1.3,
            }}
          >
            Name
          </Typography>
          {isSmallScreen && renderEditNameButton()}
        </Stack>
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          {editingCoachBotName ? (
            <YoodliTextfield
              value={newCoachBotName}
              onChange={(e) => setNewCoachBotName(e.target.value)}
              sx={{
                mt: 0.5,
                fontSize: 16,
                fontWeight: 700,
                width: { xs: "100%", md: "initial" },
              }}
              autoFocus
              maxChars={30}
              InputProps={{
                sx: {
                  height: 39,
                },
              }}
              inputProps={{
                sx: {
                  padding: "4px 12px",
                  fontFamily: "poppins",
                  fontSize: 16,
                  fontWeight: 600,
                  color: getDynamicColor("purple3"),
                },
              }}
            />
          ) : (
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                fontFamily: "poppins",
                minHeight: 33,
                mt: 0.5,
              }}
            >
              {coachBot.name}
            </Typography>
          )}
          {!isSmallScreen && renderEditNameButton()}
        </Stack>
      </Stack>
    );
  };
  const renderVisibility = () => {
    return (
      <Stack
        direction="column"
        sx={{
          py: 2.5,
        }}
        gap={{ xs: 1, md: 0 }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "poppins",
            color: getDynamicColor("dark5"),
            lineHeight: 1.3,
          }}
        >
          Visibility
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              fontFamily: "poppins",
              display: { xs: "none", md: "initial" },
            }}
          >
            Coach Bot comments visible to:
          </Typography>
          <YoodliSelect
            open={visibleToSelectOpen}
            onOpen={() => setVisibleToSelectOpen(true)}
            onClose={() => setVisibleToSelectOpen(false)}
            value={coachBotVisibleTo}
            onChange={async (e) => {
              try {
                setSavingCoachBotVisibility(true);
                let updateOptions = {};
                if (e.target.value === "nobody") {
                  updateOptions = {
                    isActive: false,
                    activeOrgWide: false,
                  };
                } else if (e.target.value === "everybody") {
                  updateOptions = {
                    isActive: true,
                    activeOrgWide: true,
                  };
                } else if (e.target.value === "adminsOnly") {
                  updateOptions = {
                    isActive: true,
                    activeOrgWide: false,
                  };
                }
                await handleUpdateCoachBot({
                  orgId,
                  botId: coachBot.botId,
                  updateOptions,
                  isDefault: coachBot.isDefault,
                });
              } catch (er) {
                console.log(`Error updating coachbot visibility settings: ${er}`);
              } finally {
                setSavingCoachBotVisibility(false);
              }
              setCoachBotVisibleTo(e.target.value as string);
            }}
            options={[
              { value: "placeholder", label: "Select option", optionSx: { display: "none" } },
              { value: "nobody", label: "Nobody" },
              { value: "everybody", label: "Everybody" },
              { value: "adminsOnly", label: "Admins only" },
            ]}
            loading={savingCoachBotVisibility}
          />
        </Stack>
      </Stack>
    );
  };

  const renderScenarioSelector = () => {
    if (scenariosLoading) {
      return <CircularProgress size={20} />;
    }
    return (
      <YoodliMultiSelect
        isCheckboxSelect
        sx={{
          ".MuiChip-root": {
            borderRadius: "4px",
            backgroundColor: getDynamicColor("dark2"),
            color: getDynamicColor("dark5"),
            fontWeight: 500,
            fontSize: 14,
            maxWidth: 200,
            ".MuiChip-deleteIcon": {
              color: getDynamicColor("dark5"),
            },
          },
        }}
        value={selectedScenarioIds.map((id) => ({
          label: enabledScenarios.find((scen) => scen.scenarioId === id).scenarioName ?? "",
          value: id,
        }))}
        onChange={(e, vals: YoodliSelectOption[], _reason, detail) => {
          if (vals?.length === 0) {
            setSelectedScenarioIds([]);
            return;
          }
          if (selectedScenarioIds.includes(detail?.option?.value)) {
            setSelectedScenarioIds(
              selectedScenarioIds.filter((id) => id !== detail?.option?.value)
            );
          } else {
            setSelectedScenarioIds([...selectedScenarioIds, detail?.option?.value]);
          }
        }}
        placeholder="Select scenario(s)"
        options={(enabledScenarios ?? []).map((scen) => ({
          label: scen.scenarioName,
          value: scen.scenarioId,
          subLabel: scen.coachBotName,
          JSXOverride: (
            <Grid
              container
              sx={{
                color: selectedScenarioIds.includes(scen.scenarioId)
                  ? getDynamicColor("primary")
                  : getDynamicColor("purple3"),
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Poppins",
                alignItems: "center",
              }}
            >
              <Grid item xs={8}>
                {scen.scenarioName}
              </Grid>
              <Grid item xs={4} sx={{ fontSize: 12 }}>
                {scen.coachBotName ?? "None assigned"}
              </Grid>
            </Grid>
          ),
        }))}
      />
    );
  };

  const renderScenarioSectionControls = () => {
    if (!editingScenarios) {
      return (
        <Button
          variant="text"
          onClick={() => setEditingScenarios(true)}
          sx={{
            fontSize: 14,
          }}
        >
          Edit
        </Button>
      );
    }
    return (
      <Stack direction="row" sx={{ gap: 0.5 }}>
        <Button
          variant="text"
          onClick={() => {
            setSelectedScenarioIds((currentSelectedScenarios ?? []).map((scen) => scen.id));
            setEditingScenarios(false);
          }}
          sx={{
            fontSize: 14,
          }}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          onClick={async () => {
            await handleUpdateCoachBot({
              orgId,
              botId: coachBot.botId,
              updateOptions: { scenarioIds: selectedScenarioIds },
              isDefault: false,
            }).catch((er) => {
              console.error(`Error updating coachbot scenarios: ${er}`);
            });
            setEditingScenarios(false);
          }}
          sx={{
            fontSize: 14,
          }}
          endIcon={handleUpdateCoachBotLoading ? <CircularProgress size={12} /> : null}
        >
          Save
        </Button>
      </Stack>
    );
  };

  const renderScenarioSection = () => {
    return (
      <Stack
        direction="column"
        sx={{
          py: 2.5,
        }}
        gap={{ xs: 1, md: 0 }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "poppins",
            color: getDynamicColor("dark5"),
            lineHeight: 1.3,
          }}
        >
          Scenarios
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              fontFamily: "poppins",
              display: { xs: "none", md: "initial" },
            }}
          >
            Assigned to {selectedScenarioIds.length} scenarios
          </Typography>
          {renderScenarioSectionControls()}
        </Stack>
        {editingScenarios && <Box sx={{ pt: 2 }}>{renderScenarioSelector()}</Box>}
      </Stack>
    );
  };

  const renderStyleGuideControls = () => {
    return (
      <Button
        variant="text"
        onClick={async () => setShowStyleGuide(true)}
        sx={{
          fontSize: 14,
        }}
      >
        {styleGuideContent ? "Edit" : "Add"}
      </Button>
    );
  };

  const renderStyleGuide = () => {
    return (
      <Stack
        direction="column"
        sx={{
          py: 2.5,
        }}
        gap={{ xs: 1, md: 0 }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "poppins",
            color: getDynamicColor("dark5"),
            lineHeight: 1.3,
          }}
        >
          Guidance
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              fontFamily: "poppins",
              display: { xs: "none", md: "initial" },
            }}
          >
            Coaching notes
          </Typography>
          {renderStyleGuideControls()}
        </Stack>
      </Stack>
    );
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: 16, md: 18 },
          fontWeight: 700,
          fontFamily: "poppins",
          mb: 2,
        }}
      >
        Settings
      </Typography>
      <Stack
        direction="column"
        sx={{
          fontFamily: "poppins",
          boxShadow: Y_SHADOWS.dark_elevation,
          px: 3,
          py: 2.5,
          pb: 2,
          borderRadius: "6px",
        }}
      >
        {renderName()}
        <Divider
          sx={{
            width: `calc(100% + 48px)`,
            ml: -3,
          }}
        />
        {renderVisibility()}
        {!coachBot?.isDefault && (
          <>
            <Divider
              sx={{
                width: `calc(100% + 48px)`,
                ml: -3,
              }}
            />
            {renderScenarioSection()}
          </>
        )}
        <Divider
          sx={{
            width: `calc(100% + 48px)`,
            ml: -3,
          }}
        />
        {renderStyleGuide()}
      </Stack>
    </Box>
  );
};
