import React from "react";

// Components
import { Box, CircularProgress, ListItem, ListItemText, Stack, Tooltip } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { CoachBotUploadedFile } from "../../CoachBotTypes";
import { FileUploadProgress } from "./FileUploadProgress";
import { getDynamicColor } from "lib-frontend/utils/Colors";

type UploadFileRowProps = {
  file: CoachBotUploadedFile;
  index?: number;
  editingFileId: string;
  handleSetEditingFileId: (fileId: string) => void;
  handleRemoveFile: (fileId: string) => Promise<void>;
  newFileName: string;
  setNewFileName: (newFileName: string) => void;
  handleUpdateCoachBotContentFilename: (contentId: string, newFileName: string) => Promise<void>;
};

export const UploadFileRow = ({
  file,
  index,
  editingFileId,
  handleSetEditingFileId,
  handleRemoveFile,
  newFileName,
  setNewFileName,
  handleUpdateCoachBotContentFilename,
}: UploadFileRowProps): JSX.Element => {
  return (
    <ListItem
      sx={{
        position: "relative",
        "&:not(:last-child)": {
          borderBottom: `1px solid ${getDynamicColor("dark2")}`,
        },
        width: "100%",
        gap: { xs: 0.5, md: 2 },
        flexDirection: { xs: "column", md: "row" },
        alignItems: { xs: "flex-start", md: "center" },
        backgroundColor: getDynamicColor(index % 2 === 0 ? "light1" : "light2"),
      }}
    >
      <Tooltip title={file.data.name} enterDelay={500}>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          sx={{ width: { xs: "100%", md: "42%", lg: "50%" } }}
        >
          {file?.uploadStatus === "uploading" && !editingFileId && (
            <Box sx={{ height: "fit-content" }}>
              <CircularProgress size={14} />
            </Box>
          )}
          {editingFileId === file.id ? (
            <YoodliTextfield
              autoFocus
              value={newFileName}
              onChange={(e) => {
                setNewFileName(e.target.value);
              }}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  if (newFileName !== file.filename && newFileName.length > 0) {
                    await handleUpdateCoachBotContentFilename(file.id, newFileName);
                    handleSetEditingFileId(null);
                  }
                } else if (e.key === "Escape") {
                  handleSetEditingFileId(null);
                }
              }}
              sx={{
                width: "100%",
                fontWeight: 600,
              }}
              inputProps={{
                maxLength: 100,
                sx: {
                  fontWeight: 600,
                  py: 1,
                  px: 1.5,
                },
              }}
            />
          ) : (
            <ListItemText
              primary={file.filename}
              sx={{
                color: getDynamicColor("dark5"),
                fontFamily: "poppins",
                fontSize: 14,
                fontWeight: 400,
                width: "fit-content",
                position: "relative",
                span: {
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
            />
          )}
        </Stack>
      </Tooltip>
      <Box
        sx={{
          flexBasis: { xs: "100%", md: "58%", lg: "50%" },
          width: { xs: "100%", md: "58%", lg: "50%" },
          flexGrow: 1,
        }}
      >
        <FileUploadProgress
          progress={file.uploadProgress}
          status={file.uploadStatus}
          handleRemoveFile={handleRemoveFile}
          fileId={file.id}
          editingFileId={editingFileId}
          handleSetEditingFileId={handleSetEditingFileId}
        />
      </Box>
    </ListItem>
  );
};
