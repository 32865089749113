// Components
import { CheckCircleRounded } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { BreakpointStyles } from "lib-frontend/ui/Theme";

// Utils
import { WizardStep } from "../WizardTypes";
import { ProgressMarkerColorsProps } from "./ProgressTracker";
import useBreakpoint from "hooks/useBreakpoint";
import { DynamicColorType, getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

type ProgressMarkerProps = {
  isComplete: boolean;
  isActive: boolean;
  label: string;
  markerSize?: number | BreakpointStyles;
  colors: ProgressMarkerColorsProps;
  currStepId: string;
  stepsIncluded: WizardStep[];
  hideMultiStepCount?: boolean;
};
export const ProgressMarker = ({
  isComplete,
  isActive,
  label,
  markerSize,
  colors,
  currStepId,
  stepsIncluded,
  hideMultiStepCount,
}: ProgressMarkerProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const breakpoint = useBreakpoint();
  let MARKER_SIZE_SX: number | BreakpointStyles = isSmallScreen ? 20 : 28;
  if (markerSize) {
    MARKER_SIZE_SX = markerSize;
  }
  const size = typeof MARKER_SIZE_SX === "number" ? MARKER_SIZE_SX : MARKER_SIZE_SX[breakpoint];

  let color: DynamicColorType = colors?.inactive ?? "dark3";
  if (isActive) {
    color = colors?.active ?? "primary";
  }
  if (isComplete) {
    color = colors?.success ?? "greenSuccess";
  }

  let stepNumCopy = "0";
  if (isComplete) {
    stepNumCopy = stepsIncluded?.length.toString();
  } else if (stepsIncluded?.length > 1) {
    stepNumCopy = (stepsIncluded.findIndex((x) => x.id === currStepId) + 1).toString();
  }

  return (
    <Stack
      direction="column"
      gap={0.5}
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: size,
          height: size,
          borderRadius: "50%",
          border: `2px solid ${getDynamicColor(color)}`,
          backgroundColor: "transparent",
          transition: "all 0.3s ease-out",
          position: "relative",
        }}
      >
        <CheckCircleRounded
          sx={{
            color: getDynamicColor(color),
            transform: `scale(${isComplete ? 1 : 0})`,
            height: size + 3,
            width: size + 3,
            transition: "all 0.3s ease-out",
            position: "relative",
            top: -3,
            left: -3,
          }}
        />
      </Box>

      <Typography
        sx={{
          fontWeight: isComplete || isActive ? 600 : 500,
          fontSize: { xs: 12, md: 14 },
          fontFamily: "poppins",
          color: getDynamicColor(isActive || isComplete ? "purple3" : "dark3"),
          position: "absolute",
          top: size + 6,
          whiteSpace: "nowrap",
        }}
      >
        {`${label}${
          stepsIncluded?.length > 1 && !hideMultiStepCount
            ? ` ${stepNumCopy}/${
                stepsIncluded.filter((step) => !step.ignoreStepInProgressTracker).length
              }`
            : ""
        }`}
      </Typography>
    </Stack>
  );
};
