export const COACHBOT_CONTENT_POLLING_INTERVAL = 10000;

export type BotProcessingState = "readyToProcess" | "processing" | "done" | "error";

export type CoachBotStep = {
  label: string;
  id: CoachBotStepId;
  component: JSX.Element;
  validate: () => boolean;
  next: () => void | Promise<void>;
  prev: () => void | Promise<void>;
  enterToNavigate?: boolean;
  showProgressTracker?: boolean;
};

export type CoachBotContentUploadStatus = "readyToUpload" | "uploading" | "done" | "error";

export type CoachBotUploadedFile = {
  data: File; // includes things like name, size, etc
  filename: string;
  id: string;
  uploadUrl: string;
  uploadStatus: CoachBotContentUploadStatus;
  uploadProgress: number; // value between 0 and 100, signifying upload completion percentage
};

export enum CoachBotStepId {
  GET_STARTED = "GET_STARTED",
  NAMING = "NAMING",
  UPLOAD_CONTENT = "UPLOAD_CONTENT",
  SET_AS_DEFAULT = "SET_AS_DEFAULT",
  ASSIGN_SCENARIOS = "ASSIGN_SCENARIOS",
  DONE = "DONE",
}
