export const animatedEllipsisStyles = {
  "::after": {
    overflow: "hidden",
    display: "inline-block",
    verticalAlign: "bottom",
    WebkitAnimation: "ellipsis steps(4,end) 900ms infinite",
    animation: "ellipsis steps(4,end) 2s infinite",
    content: '"..."',
    width: 0,
    position: "absolute",
  },
  "@keyframes ellipsis": {
    to: {
      width: "1.25em",
    },
  },
  "@-webkit-keyframes ellipsis": {
    to: {
      width: "1.25em",
    },
  },
};
