import React from "react";

// Components
import { Box, Stack } from "@mui/material";

// Assets
import { ReactComponent as ExpandIcon } from "images/icons/expand.svg";

// Utils
import { TipGifDialog } from "./TipGifDialog";
import { DESKTOP_TIP_HEIGHT } from "./TipsCarousel";
import { TipText } from "./TipText";
import { usePreventSwipeClick } from "hooks/useSwipePrevention";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { markTipInteracted, tipToContentMap } from "lib-frontend/utils/productTipsUtils";
import { ProductTip } from "lib-fullstack/db";
import { ProductTipEventWheres } from "lib-fullstack/utils/productAnalyticEvents";

type DesktopTipProps = {
  tip: ProductTip;
  isInteracted: boolean;
  onCtaClick: (tip: ProductTip) => void;
  idx: number;
};

export const DesktopTip = ({
  tip,
  isInteracted,
  onCtaClick,
  idx,
}: DesktopTipProps): JSX.Element => {
  const [showGifDialog, setShowGifDialog] = React.useState(false);
  const [hovering, setHovering] = React.useState(false);

  const previewImgClick = () => {
    setShowGifDialog(true);
    markTipInteracted(tip);
    Instrumentation.logUsageTipGifPlayed(
      tipToContentMap[tip].header,
      idx + 1,
      tip,
      ProductTipEventWheres.WEBCLIENT_HOME
    );
  };
  const { handleMouseDown, handleMouseMove, handleMouseUp, handleClickIgnoreSwipe } =
    usePreventSwipeClick(previewImgClick);

  return (
    <Stack
      direction="row"
      sx={{
        boxShadow: Y_SHADOWS.box_shadow_9,
        mx: 1,
        height: `${DESKTOP_TIP_HEIGHT}px`,
        borderRadius: "6px",
      }}
    >
      <TipText
        tip={tip}
        isInteracted={isInteracted}
        width={"40%"}
        pl={4}
        pr={0}
        onCtaClick={onCtaClick}
        idx={idx}
      />
      {/* Trapezoid with left border diagonal */}
      <Stack
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={(e) => {
          if (showGifDialog) {
            setShowGifDialog(false);
          } else {
            handleClickIgnoreSwipe(e);
          }
        }}
        sx={{
          cursor: "pointer",
          width: "60%",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            color: getDynamicColor("light1"),
            position: "relative",
            pl: 14.5,
            pr: 2.5,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <video
            width="100%"
            height="100%"
            style={{
              maxHeight: `${DESKTOP_TIP_HEIGHT - 50}px`,
              cursor: "pointer",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={tipToContentMap[tip].gifUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
        <TipGifDialog tip={tip} isOpen={showGifDialog} onClose={() => setShowGifDialog(false)} />
        <div
          style={{
            cursor: "pointer",
            height: 0,
            width: "100%",
            borderBottom: `${DESKTOP_TIP_HEIGHT}px solid ${getDynamicColor("purple3")}`,
            borderLeft: `100px solid transparent`,
            position: "absolute",
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        ></div>
        <Box
          sx={{
            cursor: "pointer",
            position: "absolute",
            top: 16,
            right: 16,
            height: 40,
            width: 40,
            p: 0,
            zIndex: 2,
            backgroundColor: getDynamicColor("dark2", 0.75),
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "transform 0.2s",
            ...(hovering && {
              transform: "scale(1.1)",
            }),
          }}
        >
          <ExpandIcon />
        </Box>
      </Stack>
    </Stack>
  );
};
