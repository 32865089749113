import React from "react";

// Components
import { Circle as CircleIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

const RecordingStatus = (): JSX.Element => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      sx={{ backgroundColor: getDynamicColor("redErrorLight"), borderRadius: "8px", px: 2, py: 1 }}
    >
      <CircleIcon
        sx={{
          color: getDynamicColor("redError"),
          height: { xs: 14, md: 18 },
          width: { xs: 14, md: 18 },
        }}
      />
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontWeight: 600,
          fontSize: { xs: 10, md: 12 },
        }}
      >
        RECORDING
      </Typography>
    </Stack>
  );
};

export default RecordingStatus;
