import React from "react";

// Components
import { Box, SxProps, CircularProgress, Stack, Typography } from "@mui/material";

// Utils
import { usePricingUsage } from "../hooks";
import { getDynamicColor } from "../utils/Colors";
import { syncPlan } from "lib-frontend/modules/AxiosInstance";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";

type UsageItemProps = {
  numUsed: number;
  quota: number;
  icon: JSX.Element;
  open: boolean;
  thresholdJustHit?: boolean;
  usagePlanType?: UsagePlanType;
  showResetsInCopy?: boolean;
  progressColor?: string;
  progressSize?: number;
  progressThickness?: number;
  wrapperSx?: SxProps;
  textSx?: SxProps;
  resetsInCopySx?: SxProps;
};

export default function UsageItem({
  numUsed,
  quota,
  icon,
  open,
  thresholdJustHit,
  usagePlanType,
  showResetsInCopy = false,
  progressColor = getDynamicColor("light1"),
  progressSize = 20,
  progressThickness = 5,
  wrapperSx = {},
  textSx = {},
  resetsInCopySx = {},
}: UsageItemProps): JSX.Element {
  const pricingUsage = usePricingUsage();

  React.useEffect(() => {
    if (pricingUsage) {
      const { usageWindowEnd } = pricingUsage;
      if (usageWindowEnd < new Date().toISOString()) {
        syncPlan().catch((error) => {
          console.error(`Error syncing plan: ${error}`);
        });
        // No UI to update because one of external components detects
        // the update of numUsed and update the property.
      }
    }
  }, [pricingUsage]);

  const getCopy = () => {
    const showThisWeek = usagePlanType === UsagePlanType.PRO_V1;
    if (typeof quota === "number") {
      if (numUsed >= quota) {
        if (thresholdJustHit) {
          return `This is your last Yoodli${showThisWeek ? " this week" : ""}`;
        }
        return "You're out of Yoodlis";
      }
      return `${numUsed}/${quota} Yoodlis${showThisWeek ? " this week" : ""}`;
    } else {
      return "You're unlimited!";
    }
  };

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{ alignItems: "center", justifyContent: open ? "flex-start" : "center", ...wrapperSx }}
    >
      <Box
        sx={{
          position: "relative",
          width: progressSize,
          height: progressSize,
          display: "flex",
          alignItems: "center",
        }}
      >
        <CircularProgress
          variant="determinate"
          value={100}
          size={progressSize}
          thickness={progressThickness}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            color: progressColor,
            opacity: 0.3,
          }}
        />
        <CircularProgress
          variant="determinate"
          value={Math.min((numUsed / quota) * 100, 100)}
          size={progressSize}
          thickness={progressThickness}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            color: progressColor,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: progressSize,
            height: progressSize,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Box>
      </Box>
      {open && (
        <Stack>
          <Typography
            sx={{
              color: getDynamicColor("light1"),
              fontSize: 11,
              fontWeight: 500,
              fontStyle: numUsed >= quota ? "italic" : "normal",
              cursor: "default",
              lineHeight: 1.4,
              textAlign: "left",
              whiteSpace: "nowrap",
              ...textSx,
            }}
          >
            {getCopy()}
          </Typography>
          {showResetsInCopy && (
            <Typography
              sx={{
                fontSize: 10,
                color: getDynamicColor("dark4"),
                lineHeight: 1.4,
                textAlign: "left",
                ...resetsInCopySx,
              }}
            >
              Resets every Sunday
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
}
