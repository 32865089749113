// Components
import SortIcon from "@mui/icons-material/Sort";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";

export enum ScenarioSortOption {
  Name = "Name",
  CreatedAt = "CreatedAt",
  UpdatedAt = "UpdatedAt",
}

export enum CustomGoalFilterOption {
  All = "All",
  BinaryGoal = "Binary Goal",
  RatedGoal = "Rated Goal",
  CompoundGoal = "Compound Goal",
}

export enum ScenarioFilterOption {
  All = "All",
  Sales = "Sales",
  ManagerTraining = "Manager Training",
  Other = "Other",
}

export type SortOption = {
  value: ScenarioSortOption | ScenarioFilterOption | CustomGoalFilterOption;
  label: string;
};

export enum SortFilterType {
  Sort = "sort",
  Filter = "filter",
}

type SortFilterMenuProps = {
  sortFilterType: SortFilterType;
  sortOptions: SortOption[];
  sortBy: SortOption;
  setSortBy: (option: SortOption) => void;
};

export const SortFilterMenu = ({
  sortFilterType,
  sortOptions,
  sortBy,
  setSortBy,
}: SortFilterMenuProps): JSX.Element => {
  return (
    <YoodliMenu
      type={YoodliMenuButtonType.Text}
      menuItems={sortOptions.map((option) => {
        return {
          title: option.label,
          onClick: () => (option.value === "All" ? setSortBy(undefined) : setSortBy(option)),
          type:
            (!sortBy && option.value === "All") || sortBy?.value === option.value
              ? YoodliMenuItemType.Primary
              : YoodliMenuItemType.Default,
        };
      })}
      buttonText={sortFilterType === SortFilterType.Sort ? "Sort by" : "Filter by"}
      buttonProps={{
        startIcon:
          sortFilterType === SortFilterType.Sort ? <SortIcon /> : <FilterAltOutlinedIcon />,
      }}
      buttonSx={{ whiteSpace: "nowrap", fontSize: "14px" }}
    />
  );
};
