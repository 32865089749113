import { db } from "lib-fullstack";

export const getRandomItemInArray = (array: string[]): string => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

export const WELCOME_MESSAGES = [
  "Remember to smile",
  "Try to listen more than you speak",
  "Check your background lighting",
  "Look at the camera, not your screen",
  "Pause instead of using filler words",
  "Sit upright",
  "Vary your speaking volume",
  "Start with small talk",
];

export const HALFWAY_THERE_RTA_MESSAGES = ["Take a deep breath, you're halfway there!"];

export const RTA_MESSAGES = {
  [db.PoodliAlertStatuses.MONOLOGUING]: "2 min monologue, try pausing",
  [db.PoodliAlertStatuses.PACING_FAST]: "Slow down",
  [db.PoodliAlertStatuses.PACING_SLOW]: "Speed up",
  [db.PoodliAlertStatuses.SENTENCE_STARTER]: 'Avoid starting sentences w/ "{context}"',
  [db.PoodliAlertStatuses.HALFWAY]: getRandomItemInArray(HALFWAY_THERE_RTA_MESSAGES),
};
