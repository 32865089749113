import React from "react";
import styled from "@emotion/styled";

import Background from "../images/backgrounds/yoodli_gradient_background.svg";
import { Typography } from "@mui/material";

const Wrapper = styled("div")`
  background-image: url("${Background}");
  background-size: cover;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .logo {
    width: 70vw;
    max-width: 700px;
    height: auto;
    margin: 0 auto;
  }

  .container {
    margin-top: 5rem;
  }

  .copy {
    width: 80vw;
    font-size: 1.25rem;
    max-width: 800px;
    color: white;
    text-align: center;
    margin: 2rem auto 0 auto;

    b {
      font-size: 2rem;
    }
  }
`;

export default function IncompatibleBrowser(props: {
  copy: string;
  header: string | React.ReactNode;
}): React.ReactElement {
  return (
    <Wrapper>
      <div className="container">
        <div className="logo">
          <svg
            width="auto"
            height="auto"
            viewBox="0 0 1350 397"
            fill="white"
            stroke="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M258.381 149.111C253.396 133.313 236.78 124.721 220.994 129.71C212.132 132.482 205.209 139.133 202.163 147.725C219.056 158.257 237.057 166.572 255.611 173.224C260.042 166.018 260.873 157.149 258.381 149.111Z"
              fill="inherit"
            />
            <path
              d="M258.381 149.111C253.396 133.313 236.78 124.721 220.994 129.71C212.132 132.482 205.209 139.133 202.163 147.725C219.056 158.257 237.057 166.572 255.611 173.224C260.042 166.018 260.873 157.149 258.381 149.111Z"
              stroke="inherit"
              strokeWidth="1.15"
              strokeMiterlimit="10"
            />
            <path
              d="M199.947 171.284C200.224 172.393 200.778 173.501 201.332 174.61L198.009 182.648L220.164 194.288C223.487 195.951 226.533 197.614 229.579 199.277L234.841 191.239C235.672 190.962 236.503 190.962 237.057 190.685C242.318 189.022 246.749 185.973 250.35 182.093C232.349 175.441 215.179 167.127 198.84 157.149C198.009 161.583 198.286 166.572 199.947 171.284Z"
              fill="inherit"
            />
            <path
              d="M199.947 171.284C200.224 172.393 200.778 173.501 201.332 174.61L198.009 182.648L220.164 194.288C223.487 195.951 226.533 197.614 229.579 199.277L234.841 191.239C235.672 190.962 236.503 190.962 237.057 190.685C242.318 189.022 246.749 185.973 250.35 182.093C232.349 175.441 215.179 167.127 198.84 157.149C198.009 161.861 198.286 166.572 199.947 171.284Z"
              stroke="inherit"
              strokeWidth="1.15"
              strokeMiterlimit="10"
            />
            <path
              d="M193.024 190.962L178.346 227.27V227.547L111.328 362.523C109.389 368.621 103.02 371.947 96.9274 370.284L96.3735 370.007C91.1117 368.621 88.0654 363.355 89.4501 358.366C89.727 357.812 89.7271 357.257 90.004 356.703L216.01 96.451C224.595 78.7128 217.117 57.3716 199.393 48.7797C198.286 48.2254 196.901 47.6711 195.793 47.3939L194.962 47.1167C177.792 41.0192 158.961 48.7797 150.93 65.1321L82.8037 204.82C77.8188 215.075 65.6337 219.232 55.3871 214.243C45.9713 209.809 41.5403 198.723 45.1404 189.022C45.4174 188.191 45.6943 187.636 45.9713 186.805L109.666 57.6488C118.251 39.9106 111.051 18.5694 93.3272 9.97747C76.1572 1.6627 55.3871 8.31451 46.2482 25.2212L3.04627 106.983C-0.276953 113.358 2.21547 121.118 8.58498 124.444C14.9545 127.77 22.7087 125.276 26.0319 119.178L69.2338 37.4162C71.7262 32.7045 77.5419 31.0415 82.2498 33.536C86.6808 35.7532 88.6193 41.0192 86.6808 45.4538L86.4038 46.2853L22.7087 175.441C11.0774 198.168 19.9393 226.438 42.925 238.079C65.6337 249.72 93.8811 240.851 105.512 217.847C105.789 217.292 106.066 216.738 106.343 216.184L174.469 76.2184C176.685 71.7839 181.67 69.5666 186.378 71.2296L187.208 71.5067C192.193 73.1697 194.962 78.7128 193.024 83.7017C193.024 83.9789 192.747 84.256 192.47 84.5332L66.4645 344.508C61.7566 354.209 61.7566 365.295 66.1875 374.996C70.3416 384.419 78.6496 391.625 88.6193 394.397C92.2195 395.505 96.0966 396.337 100.251 396.337C115.482 396.614 129.052 387.468 134.868 373.333L201.609 238.911L222.656 206.483C212.686 201.771 202.994 196.505 193.024 190.962Z"
              fill="inherit"
            />
            <path
              d="M193.024 190.962L178.346 227.27V227.547L111.051 362.523C109.113 368.621 102.743 371.947 96.6504 370.007L96.0966 369.73C90.8348 368.344 87.7885 363.078 89.1732 358.089C89.4501 357.535 89.4501 356.98 89.7271 356.426L216.01 96.451C224.595 78.7128 217.117 57.3716 199.393 48.7797C198.286 48.2254 196.901 47.6711 195.793 47.3939L194.962 47.1167C177.792 41.0192 158.961 48.7797 150.93 65.1321L82.8037 204.82C77.8188 215.075 65.6337 219.232 55.3871 214.243C45.9713 209.809 41.5403 198.723 45.1404 189.022C45.4174 188.191 45.6943 187.636 45.9713 186.805L109.666 57.6488C118.251 39.9106 111.051 18.5694 93.3272 9.97747C76.1572 1.6627 55.3871 8.31451 46.2482 25.2212L3.04627 106.983C-0.276953 113.358 2.21547 121.118 8.58498 124.444C14.9545 127.77 22.7087 125.276 26.0319 119.178L69.2338 37.4162C71.7262 32.7045 77.5419 31.0415 82.2498 33.536C86.6808 35.7532 88.6193 41.0192 86.6808 45.4538C86.6808 45.7309 86.4038 46.0081 86.4038 46.2853L22.7087 175.441C11.0774 198.168 19.9393 226.438 42.925 238.079C65.6337 249.72 93.8811 240.851 105.512 217.847C105.789 217.292 106.066 216.738 106.343 216.184L174.469 76.2184C176.685 71.7839 181.67 69.5666 186.377 71.2296L187.208 71.5067C192.193 73.1697 194.962 78.7128 193.024 83.7017C193.024 83.9789 192.747 84.256 192.47 84.5332L66.4645 344.508C61.7566 354.209 61.7566 365.295 66.1875 374.996C70.3416 384.419 78.6496 391.625 88.6193 394.397C92.2195 395.505 96.0966 396.337 100.251 396.337C115.482 396.614 129.052 387.468 134.868 373.333L201.609 238.911L222.656 206.483C212.686 201.771 202.717 196.228 193.024 190.962Z"
              stroke="inherit"
              strokeWidth="1.15"
              strokeMiterlimit="10"
            />
            <path
              d="M1193.59 6.37455C1178.36 91.7394 1163.68 175.441 1148.73 259.421H1116.33C1116.05 252.214 1115.5 245.285 1114.94 237.248C1112.45 239.188 1110.79 240.019 1109.68 241.405C1084.2 264.409 1054.85 268.29 1024.38 255.54C994.475 243.068 981.459 217.847 980.351 186.251C978.413 137.748 1002.78 96.4511 1043.49 79.5445C1063.15 71.2297 1083.92 69.2896 1103.86 76.2186C1116.05 80.3759 1126.85 89.245 1138.48 96.174C1140.97 81.7617 1144.02 65.1322 1147.07 48.5027C1149.28 36.862 1150.94 24.9442 1153.44 13.3035C1153.99 10.8091 1156.76 7.20602 1158.97 7.20602C1170.05 6.09739 1181.13 6.37455 1193.59 6.37455ZM1128.51 159.643C1127.68 137.471 1123.8 118.347 1102.76 109.478C1081.71 100.609 1061.49 105.32 1044.32 120.287C1024.66 138.025 1018.01 160.752 1020.51 186.805C1024.66 229.487 1062.32 235.862 1088.63 223.667C1116.33 211.195 1126.3 186.528 1128.51 159.643Z"
              fill="inherit"
            />
            <path
              d="M448.912 215.907C451.405 211.195 453.066 208.146 454.728 205.374C476.052 164.909 497.653 124.444 518.7 83.979C522.577 76.4957 527.008 73.7241 535.039 74.5556C545.009 75.3871 554.702 74.8327 565.779 74.8327C563.84 78.713 562.733 81.2074 561.348 83.7018C522.3 152.992 482.975 222.558 443.927 291.848C443.374 292.68 442.82 293.511 442.543 294.342C429.25 322.335 408.48 337.025 376.078 333.145C367.216 332.036 358.077 332.868 348.385 332.868L353.923 298.223C363.062 298.223 371.924 297.946 380.786 298.223C391.033 299.054 400.726 293.788 405.987 284.919C414.572 271.893 420.111 259.42 415.957 242.237C404.049 190.408 394.356 138.025 383.555 85.9191C383.002 82.5932 382.448 78.9901 381.617 74.8327C394.079 74.8327 405.71 74.5556 417.065 75.1099C419.28 75.1099 422.326 79.8216 422.88 82.5932C430.911 124.444 438.389 166.295 446.143 208.146C446.974 210.086 447.528 212.026 448.912 215.907Z"
              fill="inherit"
            />
            <path
              d="M658.829 72.3382C715.324 72.6154 751.326 114.466 743.571 168.512C736.094 219.232 709.231 249.72 662.706 259.975C615.904 270.23 573.533 249.997 560.517 209.255C544.455 159.643 567.441 82.5931 647.198 73.1697C651.629 72.3382 656.614 72.3382 658.829 72.3382ZM595.411 171.284C594.857 219.232 630.582 241.405 668.245 222.281C692.061 210.086 707.847 179.044 704.8 150.497C701.2 113.635 668.245 94.7881 634.459 110.863C607.042 123.89 597.073 148.28 595.411 171.284Z"
              fill="inherit"
            />
            <path
              d="M767.942 179.322C769.326 97.5597 834.406 61.8062 895.886 74.8327C929.672 82.0388 952.104 107.537 955.981 144.4C960.412 185.696 943.242 228.933 903.64 249.443C879.27 262.469 850.745 265.795 823.883 258.312C789.82 249.166 767.665 217.569 767.942 179.322ZM917.21 156.04C917.487 116.129 882.593 93.4023 846.591 110.863C817.79 124.998 807.267 150.497 807.82 180.984C808.097 215.629 835.514 235.585 869.023 226.439C897.547 218.955 916.933 190.685 917.21 156.04Z"
              fill="inherit"
            />
            <path
              d="M1225.99 259.42H1187.22C1189.44 245.562 1191.65 232.259 1194.15 219.232C1206.05 151.328 1218.52 83.4244 1230.15 15.5205C1231.53 8.31442 1234.02 5.81999 1241.22 6.09715C1250.92 6.65147 1260.33 6.09715 1270.86 6.09715C1255.9 91.1849 1240.95 174.887 1225.99 259.42Z"
              fill="inherit"
            />
            <path
              d="M1264.49 259.42L1296.61 75.1097H1335.94C1324.86 136.639 1314.06 197.614 1303.26 259.42H1264.49Z"
              fill="inherit"
            />

            <path
              d="M1349.23 22.1726C1348.95 36.0305 1337.6 47.1168 1323.75 47.1168C1310.46 47.1168 1301.6 38.2478 1301.6 25.4985C1301.6 11.3634 1313.23 -0.277312 1327.35 -0.277312C1338.98 -0.554471 1348.95 8.59177 1349.23 20.5096C1349.23 21.0639 1349.23 21.6182 1349.23 22.1726Z"
              fill="inherit"
            />
          </svg>
        </div>

        <Typography className="copy">
          <b>{props.header}</b>
          <br />
          {props.copy}
        </Typography>
      </div>
    </Wrapper>
  );
}
