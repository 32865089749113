export enum BuilderSection {
  Default = "Default",
  CreateScenario = "CreateScenario",
  CreatePersona = "CreatePersona",
}

export enum BuilderTab {
  Scenarios = "Scenarios",
  Personas = "Personas",
}

export enum BuilderQueryKeys {
  UserScenarios = "UserScenarios",
  OrgScenarios = "OrgScenarios",
  UserPersonas = "UserPersonas",
}
