import { db } from "lib-fullstack";
import React from "react";

// Components
import { Add as AddQuestionIcon, DoneOutlined as QuestionAddedIcon } from "@mui/icons-material";
import { Button, Stack, Typography, Box, CircularProgress, IconButton } from "@mui/material";
import { MyQuestions } from "components/Interview/MyQuestions";
import { QuestionList } from "components/Interview/QuestionList";
import { ROW_STYLES } from "components/Interview/styles";

// Utils
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { useOnAll } from "@typesaurus/react";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ORG_INTERVIEW_BANK_MAX_QS } from "lib-fullstack/utils/constants";
import { INTERVIEW_CATEGORIES } from "utils/interviewUtils";
import { reorder } from "utils/Utilities";

type InterviewBankQuestionsModalProps = {
  initQuestions: string[];
  bankName: string;
  onSubmit: UseMutateAsyncFunction<void, Error, { interviewQuestions: string[] }>;
  closeModal: (skipConfirmation?: boolean) => void;
};

export function InterviewBankQuestionsModal({
  initQuestions,
  bankName,
  onSubmit,
  closeModal,
}: InterviewBankQuestionsModalProps): JSX.Element {
  const [interviewQs, setInterviewQs] = React.useState<string[]>(initQuestions);
  const [inputtedInterviewOption, setInputtedInterviewOption] =
    React.useState<db.InterviewQuestion>({
      text: "",
      category: INTERVIEW_CATEGORIES.CUSTOM.identifier,
    });
  const [autocompleteOpen, setAutocompleteOpen] = React.useState<boolean>(false); // for autocomplete
  const [allPrompts, setAllPrompts] = React.useState<db.InterviewQuestion[]>([]);
  const [error, setError] = React.useState<string>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [dbInterviewPrompts, dbInterviewPromptsMeta] = useOnAll<db.InterviewQuestion>( // general interview prompts
    db.interviewPrompts
  );

  React.useEffect(() => {
    if (dbInterviewPrompts) {
      setAllPrompts(
        dbInterviewPrompts
          .filter((p) => p.data.category === INTERVIEW_CATEGORIES.GENERAL.identifier)
          .sort((a) => (interviewQs.includes(a.data.text) ? -1 : 1))
          .map((p) => ({ text: p.data.text, category: p.data.category }))
      );
    }
  }, [dbInterviewPromptsMeta.loading]);
  const handleQuestionDrag = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedInterviewQs = reorder(
      interviewQs,
      result.source.index,
      result.destination.index
    );

    setInterviewQs(reorderedInterviewQs);
  };

  const onAddInterviewQ = (questionBankPrompt = undefined) => {
    if (interviewQs.length < ORG_INTERVIEW_BANK_MAX_QS) {
      if (questionBankPrompt) {
        setInterviewQs([...interviewQs, questionBankPrompt]);
      } else if (inputtedInterviewOption) {
        setInterviewQs([...interviewQs, inputtedInterviewOption.text]);
      }
      setAutocompleteOpen(false);
      setInputtedInterviewOption({ text: "", category: INTERVIEW_CATEGORIES.CUSTOM.identifier });
    } else {
      setError("Error adding question: Question bank is full.");
    }
  };

  const onDeleteInterviewQ = (i) => {
    const arr = [...interviewQs];
    arr.splice(i, 1);
    setInterviewQs([...arr]);
  };

  const renderQuestions = () => {
    if (dbInterviewPromptsMeta.loading) {
      return (
        <Box
          sx={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      );
    } else if (allPrompts?.length > 0) {
      return allPrompts.map((prompt, index) => (
        <Box
          key={index}
          sx={{
            ...ROW_STYLES,
            cursor:
              interviewQs.length >= ORG_INTERVIEW_BANK_MAX_QS || interviewQs.includes(prompt.text)
                ? "default"
                : "pointer",
            textAlign: "left",
          }}
          onClick={() => {
            if (!interviewQs.includes(prompt.text)) {
              onAddInterviewQ(prompt.text);
            }
          }}
        >
          <IconButton
            aria-label="Add question"
            disabled={
              interviewQs.length >= ORG_INTERVIEW_BANK_MAX_QS || interviewQs.includes(prompt.text)
            }
            size="medium"
          >
            {interviewQs.includes(prompt.text) ? (
              <QuestionAddedIcon style={{ color: getDynamicColor("greenSuccess") }} />
            ) : (
              <AddQuestionIcon sx={{ "&:hover": { color: getDynamicColor("primary") } }} />
            )}
          </IconButton>
          <Typography
            sx={{
              fontSize: { xs: 14, md: 16 },
              cursor: "inherit",
            }}
          >
            {prompt.text}
          </Typography>
        </Box>
      ));
    }
  };

  const submitQuestions = async () => {
    try {
      setIsLoading(true);
      await onSubmit({ interviewQuestions: interviewQs });
      closeModal(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Shuffle through prompts of the selected category or fallback to all prompts if there are issues
  const shuffleThroughPrompts = () => {
    const randomIndex = Math.round(Math.random() * (allPrompts.length - 1));
    const randomPrompt = allPrompts[randomIndex];
    setInputtedInterviewOption(randomPrompt);
  };

  return (
    <Stack gap={3} sx={{ width: "100%", alignItems: "center" }}>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontWeight: 700,
          fontSize: "19px",
          color: getDynamicColor("purple3"),
        }}
      >
        {bankName} Question Bank
      </Typography>
      {isLoading ? (
        <Box my={5}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && <Typography sx={{ color: getDynamicColor("redError") }}>{error}</Typography>}
          <Stack
            direction={{ xs: "column", lg: "row" }}
            gap={2}
            alignItems="stretch"
            sx={{
              width: "100%",
              height: { xs: "calc(100% - 56px)", md: "100%" },
              position: "relative",
              flexGrow: { xs: 1, lg: "auto" },
            }}
          >
            <QuestionList
              inputtedInterviewOption={inputtedInterviewOption}
              setInputtedInterviewOption={setInputtedInterviewOption}
              addInterviewQuestion={onAddInterviewQ}
              autocompleteOpen={autocompleteOpen}
              setAutocompleteOpen={setAutocompleteOpen}
              allPrompts={allPrompts}
              interviewQs={interviewQs}
              maxInterviewQs={ORG_INTERVIEW_BANK_MAX_QS}
              renderQuestions={renderQuestions}
              error={!!error}
              setError={(err) => setError(err ? "An unexpected error occured" : undefined)}
              shuffleThroughPrompts={shuffleThroughPrompts}
            />
            <MyQuestions
              interviewQs={interviewQs}
              maxInterviewQs={ORG_INTERVIEW_BANK_MAX_QS}
              handleClearAllQuestions={() => setInterviewQs([])}
              handleQuestionDrag={handleQuestionDrag}
              onDelete={onDeleteInterviewQ}
            />
          </Stack>
        </>
      )}
      {/* Consider in future: hide the button and add label to the loader instead of disabling */}
      <Button
        onClick={submitQuestions}
        disabled={isLoading || !(interviewQs.length > 0)}
        variant="contained"
      >
        Save Questions
      </Button>
    </Stack>
  );
}
