import React from "react";

// Components
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import videoLightbox from "ui/VideoLightbox";

// Assets
import { ReactComponent as SharedUsersIcon } from "../../../images/icons/users-connected-group.svg";
import PlaceholderThumbnail from "images/graphics/video-placeholder.svg";

// Utils
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getCourseVideo, getDemoVideo } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CourseVideoResponse, DemoVideoResponse } from "lib-fullstack/api/hubApiTypes";
import { ContentVideoState } from "lib-fullstack/utils/enums";
import { VIDEO_WIDTH, VIDEO_RATIO } from "utils/Constants";

type VideoCollectionProps = {
  videos: DemoVideoResponse[] | CourseVideoResponse[];
  selectedOrgId: string;
  courseId: string;
};

export default function VideoCollection({
  videos,
  selectedOrgId,
  courseId,
}: VideoCollectionProps): JSX.Element {
  const { lightbox, setLightboxURL } = videoLightbox("");
  const userOrgContext = React.useContext(UserOrgContext);

  const handleOpenVideo = async (video: DemoVideoResponse) => {
    if (video.state === ContentVideoState.READY) {
      setLightboxURL("loading", video.title, video.description);
      let fullVideo: DemoVideoResponse | CourseVideoResponse;
      if (courseId) {
        fullVideo = await getCourseVideo(selectedOrgId, courseId, video.id, true);
      } else {
        fullVideo = await getDemoVideo(selectedOrgId, video.id, true);
      }
      setLightboxURL(fullVideo.view_url, fullVideo.title, fullVideo.description);
    }
  };

  const getHubNameFromId = (hubId: string): string => {
    return userOrgContext.defaultOrg.hubs.find((hub) => hub.id === hubId)?.name || hubId;
  };

  const renderVideoThumbnail = (video: DemoVideoResponse | CourseVideoResponse): JSX.Element => {
    switch (video.state) {
      case ContentVideoState.READY:
        return (
          <Box
            sx={{
              aspectRatio: `${VIDEO_RATIO}`,
              borderRadius: "4px",
              border: `1px solid ${getDynamicColor("dark3")}`,
              overflow: "hidden",
              backgroundImage: `url(${video.thumbnail_url || PlaceholderThumbnail})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
          />
        );
      case ContentVideoState.PROCESSING:
        return (
          <Stack
            width="100%"
            borderRadius="4px"
            style={{
              background: getDynamicColor("light2"),
              borderRadius: "4px",
              aspectRatio: `${VIDEO_RATIO}`,
            }}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <CircularProgress />
          </Stack>
        );
      case ContentVideoState.ERROR:
        return (
          <Box
            width="100%"
            borderRadius="4px"
            sx={{
              background: getDynamicColor("redErrorLight"),
              mb: 1,
              aspectRatio: `${VIDEO_RATIO}`,
            }}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <Stack
              alignItems="center"
              gap={1}
              sx={{
                px: 2,
              }}
            >
              <ErrorOutlineIcon
                sx={{ width: "40px", height: "40px", color: getDynamicColor("redError") }}
              />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontFamily: "poppins",
                  color: getDynamicColor("redError"),
                  textAlign: "center",
                }}
              >
                Error uploading video. Please try again
              </Typography>
            </Stack>
          </Box>
        );
    }
  };

  return (
    <Stack gap={{ xs: 2, sm: 3, md: 4, xxl: 5 }} direction="row" flexWrap="wrap">
      {videos.map((video) => (
        <Stack
          gap={1.5}
          key={video.id}
          onClick={() => handleOpenVideo(video)}
          sx={{
            cursor: "pointer",
            width: `min(${VIDEO_WIDTH}px, 100%)`,
            maxWidth: 400,
            flexBasis: {
              xs: "100%",
              sm: "calc(100% / 2 - (24px * 1 / 2))",
              md: "calc(100% / 3 - (32px * 2 / 3))",
              xxl: "calc(100% / 4 - (40px * 3 / 4))",
            },
            position: "relative",
          }}
        >
          {renderVideoThumbnail(video)}
          <Stack>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 500,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                lineHeight: 1.3,
              }}
            >
              {video.title}
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 400,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                lineHeight: 1.3,
              }}
            >
              {video.description}
            </Typography>
          </Stack>
          {!courseId && video.available_hubs?.length > 0 && (
            <Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <YoodliTooltip
                  title={
                    <>
                      {video.available_hubs.map((hubId) => (
                        <Typography variant="body1" key={hubId}>
                          {getHubNameFromId(hubId)}
                        </Typography>
                      ))}
                    </>
                  }
                >
                  <>
                    <SharedUsersIcon />
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 500, fontSize: 12, color: getDynamicColor("dark4") }}
                    >
                      Available to {video.available_hubs?.length} group
                      {video.available_hubs?.length > 1 ? "s" : ""}
                    </Typography>
                  </>
                </YoodliTooltip>
              </Stack>
            </Stack>
          )}
        </Stack>
      ))}
      {lightbox}
    </Stack>
  );
}
