// Components
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack, Typography } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type OrgOverviewSectionProps = {
  title: string;
  content: JSX.Element;
  tooltip?: string;
  cta?: JSX.Element;
};

export const OrgOverviewSection = ({
  title,
  content,
  tooltip,
  cta,
}: OrgOverviewSectionProps): JSX.Element => {
  return (
    <Stack gap={1} sx={{ width: "100%" }}>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "40px",
        }}
      >
        <Stack direction="row" gap={1.5}>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
          {tooltip && (
            <YoodliTooltip title={tooltip}>
              <InfoOutlinedIcon sx={{ width: "16px" }} />
            </YoodliTooltip>
          )}
        </Stack>
        {cta && cta}
      </Stack>
      {content}
    </Stack>
  );
};
