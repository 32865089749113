/**
 * Apply async action to each item in a list and return a list of results.
 * If any of actions fail after max retry, the entire method throws an error.
 * @param maxConcurrency Maximum number of async operations that may run concurrently.
 * @param maxRetryCount Maximum number of total retires before giving up.
 * @returns An array of the results, corresponding to each item in the list.
 */
export async function asyncMap<S, T>(
  list: S[],
  action: (item: S) => Promise<T>,
  maxConcurrency: number,
  maxRetryCount: number
): Promise<T[]> {
  // Array to hold the result
  const results: (T | undefined)[] = Array(list.length).fill(undefined);

  // Queue to hold index of unprocessed items.
  const queue = Array.from(Array(list.length).keys());

  type TaskResult = {
    index: number;
    success: boolean;
    result: T | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorReason: any | undefined;
  };

  // Array to hold in-flight promises.
  const inFlights: Promise<TaskResult>[] = [];

  // Lookup table from item index to an inflight promise (task).
  const lookup = new Map<number, Promise<TaskResult>>();

  let retryCount = 0;
  while (inFlights.length > 0 || queue.length > 0) {
    while (inFlights.length < maxConcurrency && queue.length > 0) {
      const itemIndex = queue.shift();
      const item = list[itemIndex];
      // Create a new promise to run the action and resolve.
      // This promise resolves whether the action succeeds or fails,
      // and the returned TaskResult includes all the metadata.
      const promise: Promise<TaskResult> = action(item)
        .then((result) => {
          return {
            index: itemIndex,
            success: true,
            result: result,
            errorReason: undefined,
          };
        })
        .catch((reason) => {
          return {
            index: itemIndex,
            success: false,
            result: undefined,
            errorReason: reason,
          };
        });
      inFlights.push(promise);
      lookup.set(itemIndex, promise);
    }

    // Note: at least one item should be queued above.
    // And this should be resolved, not rejected. Thus using await.
    const settled = await Promise.race(inFlights);

    const settledPromise = lookup.get(settled.index);
    lookup.delete(settled.index);
    inFlights.splice(inFlights.indexOf(settledPromise), 1);

    if (settled.success) {
      results[settled.index] = settled.result;
    } else {
      // push back for retry
      queue.push(settled.index);
      retryCount++;
      if (retryCount > maxRetryCount) {
        throw new Error(
          `asyncMap: Failed after ${maxRetryCount} retries. The last error was ${settled.errorReason}.`
        );
      }
      console.debug(`will retry action ${settled.index} (${settled.errorReason})`);
    }
  }

  return results;
}
