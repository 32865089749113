import { Button, Drawer, IconButton, Typography } from "@mui/material";
import { DropdownButtonOptionArrayInfo, getDropDownButtonOptions } from "utils/Utilities";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { CurrentlyUploadingFilesContext } from "App";
import { EventsRecordWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import React from "react";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
// Utils
import { iconMap } from "./SelectPracticeTypeDropdown";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { useNavigate, useLocation } from "react-router";

type PracticeTypeButtonProps = {
  value: string;
  iconId: string;
  onClick: () => void;
};
const PracticeTypeButton = ({ value, iconId, onClick }: PracticeTypeButtonProps): JSX.Element => {
  return (
    <Button
      onClick={onClick}
      startIcon={iconMap[iconId]()}
      sx={{
        fontSize: { xs: 14, sm: 16, md: 18 },
        fontWeight: 600,
        minWidth: { xs: 240, sm: 260 },
        height: { xs: 48, sm: 52 },
        color: getDynamicColor("purple3"),
        backgroundColor: getDynamicColor("light1"),
        boxShadow: Y_SHADOWS.dark_elevation,
        "&:hover": {
          backgroundColor: getDynamicColor("primary"),
          color: getDynamicColor("light1"),
        },
      }}
    >
      {value}
    </Button>
  );
};

type SelectPracticeTypeDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  paddingTop: string;
};
const SelectPracticeTypeDrawer = ({
  open,
  setOpen,
  paddingTop,
}: SelectPracticeTypeDrawerProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgModuleAccess } = React.useContext(UserOrgContext);
  // close the drawer whenever the pathname changes
  React.useEffect(() => {
    setOpen(false);
  }, [location.pathname]);
  const { currentlyUploadingFiles } = React.useContext(CurrentlyUploadingFilesContext);

  const siteConf = getStaticFullSiteConf();
  const options = getDropDownButtonOptions(isToastmasters(siteConf), orgModuleAccess);

  const handleOptionClick = (option: DropdownButtonOptionArrayInfo) => {
    const pathToPush = option.path;
    switch (pathToPush) {
      case WebServerExternalPath.PRACTICE_INTERVIEW:
        Instrumentation.logRecordInterviewCheckpoint(EventsRecordWheres.APPBAR);
        break;
      case WebServerExternalPath.PRACTICE_CONVERSATION:
        Instrumentation.logRecordConversationCheckpoint(EventsRecordWheres.APPBAR);
        break;
      case WebServerExternalPath.PRACTICE_SPEECH:
        Instrumentation.logRecordSpeechCheckpoint(EventsRecordWheres.APPBAR);
        break;
    }

    if (currentlyUploadingFiles && currentlyUploadingFiles.length > 0) {
      if (
        window.confirm("Switching pages will cancel your upload. Are you sure you want to do this?")
      ) {
        navigate(pathToPush);
      }
    } else {
      if (pathToPush === location.pathname) {
        setOpen(false);
      } else {
        navigate(pathToPush);
      }
    }
  };
  return (
    <Drawer
      open={open}
      anchor="bottom"
      onClose={() => setOpen(false)}
      hideBackdrop
      sx={{
        // header is 1100, so this is above everything else, but still below header
        zIndex: 1099,
        width: "100%",
      }}
      PaperProps={{
        sx: {
          position: "relative",
          top: paddingTop,
          width: "100%",
          height: `calc(100% - ${paddingTop})`,
          background: getDynamicColor("gradient.slantedLight"),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2.5,
          pt: { xs: 10, md: 2.5 },
        },
      }}
    >
      <IconButton
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "poppins",
          fontWeight: 700,
          lineHeight: 1.3,
          width: 230,
          mb: 2,
          fontSize: { xs: 16, sm: 18, md: 20 },
        }}
      >
        What do you want to practice?
      </Typography>
      {options.map((option) => (
        <PracticeTypeButton
          key={option.value as string}
          iconId={option.iconId}
          value={option.value as string}
          onClick={() => handleOptionClick(option)}
        />
      ))}
    </Drawer>
  );
};

export default SelectPracticeTypeDrawer;
