import { NavigateFunction } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import { db } from "lib-fullstack";

// Utils
import { getScopedUserId, keyDbObjects } from "lib-frontend/utils/AccountUtils";
import { getSiteId, getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { ifFirebaseAnalytics } from "lib-frontend/utils/ProductAnalyticsUtils";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import LogRocket from "logrocket";
import { WebServerInternalPath } from "utils/paths";

/**
 * Auth and User--related business logic.
 * See "./components/Login.tsx" for UI.
 */
/**
 * If there is no user logged in, this fxn loads the "/log`in" route so the
 *   user can log in.
 * Afterwards, user is sent to specified URL.
 * @param navigate (from `useNavigate`)
 * @param returnPath where to send user after signin. Default: back to current page.
 */
export function requireAuth(
  navigate: NavigateFunction,
  isSignUp: boolean,
  returnPath: string
): void {
  if (!firebase.auth().currentUser) {
    navigate(isSignUp ? WebServerExternalPath.SIGN_UP : WebServerExternalPath.SIGN_IN, {
      state: { returnPath },
    });
  } else if (
    firebase.auth().currentUser.providerData &&
    firebase.auth().currentUser.providerData.find((profile) => profile.providerId === "password") &&
    !firebase.auth().currentUser.emailVerified
  ) {
    navigate(WebServerInternalPath.VERIFY_EMAIL, {
      state: { returnPath },
    });
  }
}

/** Update DB */
let authHandlerHasBeenSetup = false;

export async function hasSiteAccess(user: firebase.User): Promise<boolean> {
  const fullConf = getStaticFullSiteConf();
  const userFirebaseAuthEmail = user.email;
  // Toastmasters SSO users do not have unique emails, so they are set to 'null'
  //  in firebase auth. Anybody with Toastmasters SSO is an allowed user on that
  //  site.
  if (fullConf.featureDiffs?.enableTmiSso && user && !userFirebaseAuthEmail) {
    return true;
  }

  if (fullConf.restrictAccess) {
    if (!userFirebaseAuthEmail) {
      console.log("No user email; access not allowed.");
      return false;
    }
    if (
      fullConf.allowedEmails
        ?.map((email) => email.toLowerCase())
        .includes(userFirebaseAuthEmail.toLowerCase())
    ) {
      console.log(
        `User ${userFirebaseAuthEmail} allowed access to ${fullConf.siteId} by specific email allow list.`
      );
      return true;
    }
    if (
      [...(fullConf.allowedEmailDomains ?? []), "yoodli.ai"].includes(
        userFirebaseAuthEmail.split("@").pop()
      )
    ) {
      console.log(
        `User ${userFirebaseAuthEmail} allowed access to ${fullConf.siteId} by email domain match.`
      );
      return true;
    }
    return false;
  }
  return true;
}

export function setupAuthChangeHandler(navigate: NavigateFunction): void {
  if (authHandlerHasBeenSetup) return; // subsequent calls are no-ops.
  authHandlerHasBeenSetup = true;
  firebase.auth().onAuthStateChanged(async (user) => {
    // Check for restricted access first.
    if (user && !(await hasSiteAccess(user))) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          navigate(WebServerInternalPath.RESTRICTED, { replace: true });
          console.log("Logged user out due to restricted access.");
        })
        .catch(console.error);
      return;
    }

    ifFirebaseAnalytics((fb) => fb.setUserId(getScopedUserId()));

    if (firebase.auth().currentUser) {
      LogRocket.identify(getScopedUserId());
    }

    if (user) {
      const dbUser = await db.get(db.users(getSiteId()), user.uid);
      if (dbUser) {
        keyDbObjects.dbUser = dbUser;
        db.onGet<db.User>(dbUser.ref, (dbUser) => {
          if (dbUser?.ref?.id && dbUser?.ref?.id === user?.uid) {
            keyDbObjects.dbUser = dbUser;
          } else {
            console.error(
              "User ID mismatch in keyDbObjects listener. (no big deal, but shouldn't happen)"
            );
          }
        });
      }
    }
  });
}
