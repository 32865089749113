// Components
import { Stack, Typography, Box } from "@mui/material";

// Assets
import { ReactComponent as CalendarIcon } from "images/icons/icon-calendar.svg";
import { ReactComponent as ScenarioIcon } from "images/icons/icon-scenario.svg";

// Utils
import { ProgramDetails } from "./ProgramDetails";
import { ProgramStateIndicator } from "./ProgramStateIndicator";
import { StepProgressTracker } from "./StepProgressTracker";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import { ProgramMemberViewItem } from "lib-fullstack/api/programApiTypes";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { UITestId } from "lib-fullstack/utils/enums";

type MemberProgramItemProps = {
  program: ProgramMemberViewItem;
  cta: JSX.Element;
};

export const MemberProgramItem = ({ program, cta }: MemberProgramItemProps): JSX.Element => {
  const isMediumScreen = useIsMediumScreen();

  const programDetails = [
    {
      icon: <CalendarIcon />,
      text: program.completion_date
        ? `Completed ${getHumanReadableDate(program.completion_date)}`
        : program.last_progress_date
        ? `Last progress ${getHumanReadableDate(program.last_progress_date)}`
        : "No progress",
    },
    {
      icon: <ScenarioIcon fill={getDynamicColor("purple3")} />,
      text: `${program.plan_step_results.length} scenarios`,
    },
  ];

  return (
    <Stack
      data-testid={`${UITestId.ProgramCarouselCard}-${program.id}`}
      gap={1}
      sx={{
        p: { xs: 2, md: 4 },
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark3")}`,
      }}
    >
      <Stack
        direction="row"
        columnGap={2}
        rowGap={1}
        sx={{ alignItems: "center", flexWrap: "wrap" }}
      >
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: { xs: "16px", md: "18px" },
            fontWeight: 700,
          }}
        >
          {program.name}
        </Typography>
        <ProgramStateIndicator state={program.state} />
      </Stack>
      <Stack
        direction="row"
        gap={3}
        sx={{ justifyContent: "space-between", alignItems: "center", mb: 1 }}
      >
        <Stack gap={2}>
          <ProgramDetails programDetails={programDetails} />
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 500,
              textAlign: "start",
            }}
          >
            {program.description}
          </Typography>
        </Stack>
        {!isMediumScreen && (
          <Box sx={{ py: 1.5, borderLeft: `1px solid ${getDynamicColor("dark4")}`, pl: 3 }}>
            <StepProgressTracker planStepResults={program.plan_step_results} />
          </Box>
        )}
      </Stack>
      {cta}
    </Stack>
  );
};
