// Components
import { createTheme } from "@mui/material/styles";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

/**
 * Override @muiv5 component styles here
 */
let theme = createTheme({
  palette: {
    primary: {
      main: getDynamicColor("secondary"),
    },

    secondary: {
      main: "#333333",
      dark: "#333333",
      light: "#333333",
      contrastText: "#212121",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "16px",
          letterSpacing: "normal",
          fontFamily: `"Lato", sans-serif`,
          fontStyle: "normal",
          textTransform: "none",
        },
        gradient: {
          background: theme.palette.primary.main,
          color: getDynamicColor("light1"),
          "&:hover": {
            background: theme.palette.primary.main,
          },
        },
        text: {
          lineHeight: "32px",
        },
        contained: {
          background: getDynamicColor("gradient.default"),
          color: getDynamicColor("light1"),
          fontWeight: 600,
          "&:hover": {
            background: getDynamicColor("gradient.hover"),
          },
        },
        outlined: {
          fontWeight: "normal",
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: getDynamicColor("light1"),
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: "auto",
          height: "auto",
          color: theme.palette.primary.main,
          fontSize: "40px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: `"Lato", sans-serif`,
          textTransform: "none",
          letterSpacing: "normal",
        },
        h1: {
          fontWeight: 500,
          fontSize: "22px",
          color: theme.palette.secondary.main,
        },
        h2: {
          fontWeight: 600,
          fontSize: "22px",
          color: theme.palette.secondary.main,
        },
        h5: {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "16px",
        },
        h6: {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "13px",
          color: theme.palette.secondary.main,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          padding: "1.5rem 2rem 1.5rem 2rem",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.palette.primary.main,
          },
        },
        colorPrimary: {
          color: "blue",
        },
        colorSecondary: {
          color: "red",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          opacity: 0.1,
          border: "1px solid #000000",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: getDynamicColor("gradient.default"),
        },
      },
    },
  },
});

export default theme;
