import React from "react";
import { Editor } from "@tiptap/react";
import {
  FormatUnderlined,
  FormatListBulleted,
  FormatListNumbered,
  Link as LinkIcon,
  FormatBold,
  FormatItalic,
} from "@mui/icons-material";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { Level } from "@tiptap/extension-heading";
import { Stack, IconButton, Box, TextField, Button, Typography, Popover } from "@mui/material";
import { isValidUrl } from "utils/navUtils";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import { useNavDrawerOffset } from "lib-frontend/hooks";

type MenuBarProps = {
  editor: Editor;
  excludeHeaderStyles?: boolean;
  mini?: boolean;
};

export function WYSIWYGMenuBar({ editor, excludeHeaderStyles, mini }: MenuBarProps): JSX.Element {
  if (!editor) {
    return null;
  }

  const { modalStyles } = useNavDrawerOffset();

  const [tempLink, setTempLink] = React.useState("");
  const [linkDialogError, setLinkDialogError] = React.useState("");
  const [linkAnchorEl, setLinkAnchorEl] = React.useState<HTMLElement | null>(null);

  const showLinkDialog = Boolean(linkAnchorEl);

  const setLink = React.useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    // // cancelled or empty
    if (previousUrl === null) {
      // if cancelled, don't reset the link
      return;
    } else if (previousUrl === "") {
      editor.chain().focus().unsetLink().run();
      return;
    }
    let finalUrl = previousUrl;

    if (finalUrl && !finalUrl.startsWith("http")) {
      finalUrl = `http://${previousUrl}`;
    }
    setTempLink(finalUrl);
    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: finalUrl }).run();
    setLinkDialogError("");
  }, [editor]);

  const handleApplyLink = () => {
    if (!isValidUrl(tempLink)) {
      setLinkDialogError("Please enter a valid url");
      return;
    }
    editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({
        href: tempLink.startsWith("http") ? tempLink : `http://${tempLink}`,
      })
      .run();
    console.log("handle apply link, mother ffather");
    setTimeout(() => {
      setLinkAnchorEl(null);
      // blur any elements that are currently focused on enter
      const activeElement = document.activeElement;
      if (activeElement instanceof HTMLElement) {
        activeElement.blur();
      }
    }, 0);
  };

  // clear the error whenever the tempLink is updated
  React.useEffect(() => {
    setLinkDialogError("");
  }, [tempLink]);

  // saVE THE LINK ON ENTER PRESS
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleApplyLink();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showLinkDialog, tempLink]);

  const iconSize = mini ? 16 : 24;

  return (
    <>
      <Popover
        open={showLinkDialog}
        onClose={() => {
          setLinkAnchorEl(null);
          setLinkDialogError("");
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorEl={linkAnchorEl}
        sx={{
          ...modalStyles,
        }}
      >
        <Box sx={{ p: 2, minWidth: 320 }}>
          <YoodliLabeledInput
            // label="Link URL"
            inputEl={
              <Stack gap={1} alignItems="flex-start">
                <Stack
                  gap={1}
                  alignItems="center"
                  direction="row"
                  sx={{
                    width: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    onChange={(e) => {
                      const url = e.target.value;
                      setTempLink(url);
                    }}
                    value={tempLink ?? ""}
                    variant="outlined"
                    size="small"
                    autoFocus
                    placeholder="Paste a link"
                    inputProps={{
                      sx: {
                        fontSize: 14,
                      },
                    }}
                    sx={{
                      flexGrow: 1,
                    }}
                    InputProps={{
                      sx: {
                        pr: 0,
                      },
                      endAdornment: (
                        <Button
                          variant="text"
                          disabled={!tempLink}
                          onClick={handleApplyLink}
                          sx={{
                            fontSize: 12,
                            ml: "auto",
                          }}
                        >
                          Apply
                        </Button>
                      ),
                    }}
                  />
                </Stack>
                {linkDialogError && (
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: getDynamicColor("redError"),
                    }}
                  >
                    {linkDialogError}
                  </Typography>
                )}
              </Stack>
            }
          />
        </Box>
      </Popover>
      <Stack
        direction="row"
        columnGap={mini ? 1 : 2.5}
        rowGap={1}
        sx={{
          background: getDynamicColor("light1"),
          color: getDynamicColor("black"),
          borderRadius: "8px",
          boxShadow: Y_SHADOWS.box_shadow_1,
          px: mini ? 1 : 3,
          py: mini ? 0.5 : 1,
          alignItems: "center",
          flexWrap: "wrap",
          width: "fit-content",
        }}
      >
        <IconButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          sx={{
            color: getDynamicColor(editor.isActive("bold") ? "black" : "dark4"),
          }}
        >
          <FormatBold sx={{ width: iconSize, height: iconSize }} />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          sx={{
            color: getDynamicColor(editor.isActive("italic") ? "black" : "dark4"),
          }}
        >
          <FormatItalic sx={{ width: iconSize, height: iconSize }} />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          sx={{
            color: getDynamicColor(editor.isActive("underline") ? "black" : "dark4"),
          }}
        >
          <FormatUnderlined sx={{ width: iconSize, height: iconSize }} />
        </IconButton>
        {!excludeHeaderStyles &&
          [1, 2, 3, 4].map((level) => (
            <IconButton
              key={level}
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .toggleHeading({ level: level as Level })
                  .run()
              }
              disabled={
                !editor
                  .can()
                  .chain()
                  .focus()
                  .toggleHeading({ level: level as Level })
                  .run()
              }
              sx={{
                color: getDynamicColor(
                  editor.isActive("heading", { level: level }) ? "black" : "dark4"
                ),
                fontSize: "20px",
              }}
            >
              <Box
                sx={{ width: iconSize, height: iconSize, display: "flex", alignItems: "center" }}
              >
                H{level}
              </Box>
            </IconButton>
          ))}
        <IconButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          disabled={!editor.can().chain().focus().toggleBulletList().run()}
          sx={{
            color: getDynamicColor("black"),
          }}
        >
          <FormatListBulleted sx={{ width: iconSize, height: iconSize }} />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          disabled={!editor.can().chain().focus().toggleOrderedList().run()}
          sx={{
            color: getDynamicColor("black"),
          }}
        >
          <FormatListNumbered sx={{ width: iconSize, height: iconSize }} />
        </IconButton>
        <IconButton
          onClick={() => {
            console.log("bruh..... link icon on click handler");
            setLinkAnchorEl(document.activeElement as HTMLElement);
            setLink();
          }}
          disabled={!editor.can().chain().focus().setLink({ href: "#" }).run()}
          sx={{
            color: getDynamicColor("black"),
          }}
        >
          <LinkIcon sx={{ width: iconSize, height: iconSize }} />
        </IconButton>
      </Stack>
    </>
  );
}
