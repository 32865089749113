// Components
import { MenuItem, Select, Stack } from "@mui/material";

// Utils
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";

export enum DayRange {
  Today = "day",
  SevenDays = "week",
  ThirtyDays = "month",
  NinetyDays = "3 months",
}

type ReportCardFilterDropdownProps = {
  selectedValue: DayRange;
  handleSetSelectedValue: (newRange: DayRange) => void;
};

export const ReportCardFilterDropdown = ({
  selectedValue,
  handleSetSelectedValue,
}: ReportCardFilterDropdownProps): JSX.Element => {
  const onSelectedValueChanged = (dayRange: DayRange) => {
    handleSetSelectedValue(dayRange);
    Instrumentation.logReportCardDateRangeChanged(dayRange);
  };

  return (
    <Stack
      direction="row"
      gap={{ xs: 4, md: 2 }}
      sx={{ alignItems: "center", justifyContent: "center" }}
    >
      <Select
        value={selectedValue}
        onChange={(e) => onSelectedValueChanged(e.target.value as DayRange)}
        sx={{
          backgroundColor: getDynamicColor("light1"),
          borderRadius: "8px",
          boxShadow: Y_SHADOWS.box_shadow_3,
          fontWeight: 600,
          fontSize: 14,
          color: getDynamicColor("primary"),
          ".MuiSelect-select": { pr: "34px !important" },
          svg: { fill: getDynamicColor("primary") },
          fieldset: { borderWidth: "0", borderColor: "transparent" },
          "&:hover fieldset, &:focus fieldset": {
            borderWidth: "unset",
            borderColor: "unset !important",
            border: `2px solid ${getDynamicColor("primary")}`,
          },
          height: 37,
          width: 130,
        }}
      >
        {Object.values(DayRange).map((enumValue) => {
          return (
            <MenuItem key={enumValue} value={enumValue} sx={{ fontSize: 14 }}>
              {enumValue}
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};
