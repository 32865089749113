import ReactHtmlParser from "html-react-parser";
import React from "react";

// Components
import { Box, Stack, Typography, Button, Checkbox, FormControlLabel } from "@mui/material";

// Utils
import { Link } from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { UITestId } from "lib-fullstack/utils/enums";

type TermsAndConditionsProps = {
  onSuccess: () => void;
  signUpNotice: string;
};

export default function TermsAndConditions({
  onSuccess,
  signUpNotice,
}: TermsAndConditionsProps): JSX.Element {
  const { defaultOrgPrimaryLogo } = React.useContext(UserOrgContext);
  const editor = useEditor({
    extensions: [StarterKit, Link, Underline, TextStyle],
    content: JSON.parse(signUpNotice),
  });
  const [checked, setChecked] = React.useState(false);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        px: 2,
      }}
    >
      <Stack
        gap={2}
        sx={{
          borderRadius: "16px",
          width: "min(600px, 100%)",
          maxHeight: "min(600px, 100%)",
          boxShadow: { xs: "none", md: Y_SHADOWS.box_shadow_1 },
          position: "relative",
          backgroundColor: getDynamicColor("light1"),
          border: { xs: "none", md: `2px solid ${getDynamicColor("dark2")}` },
          px: 5,
          pt: 3,
          pb: 4,
          overflowY: "auto",
        }}
      >
        <Stack gap={1} sx={{ alignItems: "center" }}>
          {defaultOrgPrimaryLogo ? (
            <img src={defaultOrgPrimaryLogo} alt="yoodli logo" width="130px" />
          ) : (
            <Box sx={{ width: "130px", height: "35px" }} />
          )}
          <Typography
            sx={{
              color: getDynamicColor("dark5"),
              fontSize: "16px",
              fontWeight: 500,
              fontFamily: "poppins",
            }}
          >
            Sign up Notice
          </Typography>
        </Stack>
        <Box className="tiptap" data-testid={UITestId.AcceptToSContent}>
          {ReactHtmlParser(editor?.getHTML() ?? "")}
        </Box>
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={{ xs: 2.5, md: 0 }}
          sx={{ justifyContent: "space-between", width: "100%", pt: 1, alignItems: "center" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(_, checked) => setChecked(checked)}
                data-testid={UITestId.AcceptToSCheckbox}
              />
            }
            label={
              <Typography
                sx={{
                  color: getDynamicColor("dark5"),
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                I accept the terms of service
              </Typography>
            }
          />

          <Button
            variant="contained"
            disabled={!checked}
            onClick={onSuccess}
            sx={{
              fontSize: "14px",
              "&:disabled": {
                background: getDynamicColor("dark4"),
                color: getDynamicColor("light1"),
              },
            }}
            data-testid={UITestId.AcceptToSButton}
          >
            Accept
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
