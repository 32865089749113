import React from "react";
import { isPricingEnabled } from "lib-frontend/utils/DbUtils";
import { unleashTogglesResolvedPromise } from "lib-frontend/utils/unleash";
const useIsPricingEnabled = (): boolean => {
  const [pricingEnabled, setPricingEnabled] = React.useState(false);
  React.useEffect(() => {
    const getIsPricingEnabled = async () => {
      await unleashTogglesResolvedPromise;
      return isPricingEnabled();
    };
    getIsPricingEnabled()
      .then((isPricingEnabled) => {
        setPricingEnabled(isPricingEnabled);
      })
      .catch((err) => {
        console.error("Error getting pricing enabled", err);
      });
  }, []);
  return pricingEnabled;
};

export default useIsPricingEnabled;
