import { db } from "lib-fullstack";

// Components
import { Stack, Typography } from "@mui/material";
import CalendarEvent from "lib-frontend/components/Calendar/CalendarEvent";

// Utils
import { getDynamicColor } from "../../utils/Colors";

type CalendarDateProps = {
  date: string;
  isToday?: boolean;
  events: db.Doc<db.CalendarEvent>[];
  defaultJoin: db.BotAutoJoinPreference;
  mode: db.CalendarMode;
  setLoading?: (val: boolean) => void;
  isPoodli?: boolean;
};

export default function CalendarDate({
  date,
  isToday,
  events,
  defaultJoin,
  mode,
  setLoading,
  isPoodli,
}: CalendarDateProps): JSX.Element {
  return (
    <Stack gap={isPoodli ? 1 : 2} px={isPoodli ? "20px" : { xs: 3, md: 4 }} width="100%">
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: isPoodli ? 12 : 14,
          textAlign: "start",
          letterSpacing: "1px",
          textTransform: "uppercase",
          color: isToday ? getDynamicColor("purple3") : getDynamicColor("dark5"),
        }}
      >
        {date}
      </Typography>
      {events.map((event) => (
        <CalendarEvent
          key={event.ref.id}
          isPoodli={isPoodli}
          event={event}
          defaultJoin={defaultJoin}
          mode={mode}
          setLoading={setLoading}
        />
      ))}
    </Stack>
  );
}
