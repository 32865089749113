import { useState } from "react";
import firebase from "firebase/app";
import authUrls from "../../auth/config/authUrls";
import { useURLQueryString } from "../utils";
import { useValidateStrongPassword, useValidatePassword } from "../utils/validator";

import { Link } from "react-router-dom";
import { Box, Stack, Button, Typography, Grid } from "@mui/material";
import AuthInput from "../components/AuthInput";
import PasswordProgress from "./PasswordProgress";
import yoodli_logo from "../../images/logos/yoodli_logo.svg";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { ReactComponent as PasswordResetIcon } from "../images/password_reset.svg";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

function PasswordResetSuccess(): JSX.Element {
  return (
    <Stack gap={2} alignItems="center" textAlign="center">
      <PasswordResetIcon />
      <Typography fontSize="32px">Nice! Your password has been reset</Typography>

      <Button
        variant="contained"
        onClick={() => (window.location.href = WebServerExternalPath.SIGN_IN)}
        sx={{ width: "100%" }}
      >
        Sign In
      </Button>
    </Stack>
  );
}

// TODO: Implement this as the custom action handler for emails and whatnot
export default function ResetPassword(): JSX.Element {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [completed, setCompleted] = useState(false);

  const siteConf = getStaticFullSiteConf();

  const passwordStrength = useValidateStrongPassword(password);
  const confirmPasswordError =
    useValidatePassword(password) || (password !== confirmPassword ? "Passwords do not match" : "");

  const URLQueryString = useURLQueryString();
  const oobCode = URLQueryString.get("oobCode");

  const handleConfirmPasswordReset = async (e) => {
    e.preventDefault();
    if (confirmPasswordError) return;
    if (!oobCode) return;

    try {
      await firebase.auth().confirmPasswordReset(oobCode, password);
      setCompleted(true);
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  const handleChangePassword = (password) => {
    setPassword(password);
    setError(undefined);
  };

  const handleChangeConfirmPassword = (password) => {
    setConfirmPassword(password);
    setError(undefined);
  };

  return (
    <Stack width="100%" alignItems="center">
      <Box position="absolute" sx={{ top: 0, left: 0 }} p={2} m={2}>
        <img
          src={siteConf?.logo?.url ?? yoodli_logo}
          style={{
            width: 150,
          }}
          alt="Yoodli logo"
        />
      </Box>
      <Grid
        container
        sm={12}
        md={6}
        px={{ xs: "24px", md: "84px" }}
        py={10}
        border="1px solid #F5F5F5"
        borderRadius="12px"
        boxShadow="1px 2px 5px rgba(33, 37, 41, 0.16)"
        justifyContent="center"
      >
        {!completed ? (
          <form onSubmit={handleConfirmPasswordReset} style={{ width: "100%" }}>
            <Stack gap={2}>
              <Typography fontSize="32px">Reset password</Typography>
              <Typography fontSize="16px">Enter your new password </Typography>

              <AuthInput
                fullWidth
                value={password}
                autoComplete="type-password"
                placeholder="Type Password"
                label={password ? "Type Password" : undefined}
                type="password"
                onChange={(e) => handleChangePassword(e.target.value)}
              />
              {password.length > 0 && <PasswordProgress score={passwordStrength} />}
              <AuthInput
                fullWidth
                value={confirmPassword}
                autoComplete="retype-password"
                placeholder="Re-type Password"
                label={confirmPassword ? "Re-type Password" : undefined}
                type="password"
                errorText={confirmPasswordError}
                onChange={(e) => handleChangeConfirmPassword(e.target.value)}
              />
              <Button variant="contained" onClick={handleConfirmPasswordReset}>
                Reset Password
              </Button>
              <Link
                style={{
                  textDecoration: "none",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                to={authUrls.signin}
              >
                <Button variant="contained">Cancel</Button>
              </Link>
            </Stack>
          </form>
        ) : (
          <PasswordResetSuccess />
        )}
        {error && <Typography color="error">{error}</Typography>}
      </Grid>
    </Stack>
  );
}
