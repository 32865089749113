import { Record, Static, String, Array as RTArray, Optional } from "runtypes";
import { IntegrationContextAssociationType } from "lib-fullstack/utils/enums/integrationContextAssociationType";

/**
 * Request to create a new integration context association for an organization.
 */
export const CreateOrgIntegrationContextAssociationRequestBase = Record({
  /**
   * External context ID.
   */
  contextId: String,
  /**
   * Context association type enum value.
   */
  type: IntegrationContextAssociationType,
});

/**
 * Request to create a new integration context association for an organization.
 */
export type CreateOrgIntegrationContextAssociationRequestBase = Static<
  typeof CreateOrgIntegrationContextAssociationRequestBase
>;

/**
 * Request to create a new integration context association with a scenario.
 */
export const CreateOrgIntegrationContextToScenarioAssociationRequest =
  CreateOrgIntegrationContextAssociationRequestBase.extend({
    /**
     * Scenario ID.
     */
    scenarioId: String,
  });

/**
 * Request to create a new integration context association with a scenario.
 */
export type CreateOrgIntegrationContextToScenarioAssociationRequest = Static<
  typeof CreateOrgIntegrationContextToScenarioAssociationRequest
>;

/**
 * Response to creating a new integration context association for an organization.
 */
export const CreateOrgIntegrationContextAssociationResponse = Record({
  /**
   * Integration context association ID.
   */
  id: String,
});

/**
 * Response to creating a new integration context association for an organization.
 */
export type CreateOrgIntegrationContextAssociationResponse = Static<
  typeof CreateOrgIntegrationContextAssociationResponse
>;

/**
 * Record representing an item in the list of integration context associations for an organization.
 */
export const ListOrgIntegrationContextAssociationsResponseItem = Record({
  /**
   * External context ID.
   */
  contextId: String,
  /**
   * Integration context association type enum value.
   */
  type: IntegrationContextAssociationType,
});

/**
 * Record representing an item in the list of integration context associations for an organization.
 */
export type ListOrgIntegrationContextAssociationsResponseItem = Static<
  typeof ListOrgIntegrationContextAssociationsResponseItem
>;

/**
 * Response to getting a list of integration context associations for an organization.
 */
export const ListOrgIntegrationContextAssociationsResponse = Record({
  /**
   * List of integration context associations.
   */
  associations: RTArray(ListOrgIntegrationContextAssociationsResponseItem),
});

/**
 * Response to getting a list of integration context associations for an organization.
 */
export type ListOrgIntegrationContextAssociationsResponse = Static<
  typeof ListOrgIntegrationContextAssociationsResponse
>;

/**
 * Request to update an integration context association for an organization.
 */
export const UpdateOrgIntegrationContextAssociationRequestBase = Record({
  /**
   * External context ID.
   */
  externalId: Optional(String),
});

/**
 * Request to update an integration context association for an organization.
 */
export type UpdateOrgIntegrationContextAssociationRequestBase = Static<
  typeof UpdateOrgIntegrationContextAssociationRequestBase
>;

/**
 * Request to update an integration context association with a scenario.
 */
export const UpdateOrgIntegrationContextToScenarioAssociation =
  UpdateOrgIntegrationContextAssociationRequestBase.extend({
    /**
     * Scenario ID.
     */
    scenarioId: Optional(String),
  });

/**
 * Request to update an integration context association with a scenario.
 */
export type UpdateOrgIntegrationContextToScenarioAssociation = Static<
  typeof UpdateOrgIntegrationContextToScenarioAssociation
>;
