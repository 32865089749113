import { Number, Optional, Record, Static, String } from "runtypes";
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

// Audience Types, matches OBQ1_EVENT enum
export enum AudienceType {
  COACH = "COACH",
  USER = "USER",
  INTERVIEW = "INTERVIEW",
  SPEECH = "SPEECH",
  EXPLORING = "EXPLORING",
  ALL = "ALL",
  NONE = "NONE",
}

// Lives at the Site level. Sample videos for all users in the site
export const SampleVideo = Record({
  id: Number,
  title: String,
  videoURL: String,
  thumbnailPath: String,
  audience: RTStringEnum(AudienceType),
});
export type SampleVideo = Static<typeof SampleVideo>;

// Lives at the Site level. Sample speeches (w/ analytics) for all users in the site
export const SampleSpeech = Record({
  id: Number,
  path: String,
  audience: RTStringEnum(AudienceType),
  name: Optional(String),
  slug: Optional(String),
  indexData: Optional(
    Record({
      percentFillerWords: Optional(Number),
      percentWeakWords: Optional(Number),
      lastUpdate: Optional(String),
      wpm: Optional(Number),
      topKeyWord: Optional(String),
      topWeakWord: Optional(String),
    })
  ),
});
export type SampleSpeech = Static<typeof SampleSpeech>;

export type DisplayableSampleSpeechInfo = {
  name: string;
  thumbnailPath: string;
  wpm: number;
  percentFillerWords: number;
  topKeyWord: string;
  topWeakWord: string;
  slug: string;
};
