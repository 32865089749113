import React from "react";
import * as Sentry from "@sentry/react";

/**
 * Error boundary that isolates the error (allowing parent/sister components to render),
 * shows an error message, and optionally asks for additional feedback. Also reports
 * errors nicely to Sentry.
 * @param children
 * @param smallText - make text smaller (for error boundaries in potentially
 *   tiny components like insight cards)
 */
export default function YoodliErrorBoundary({
  children,
  smallText = false,
  text = "Oops! We ran into a problem. Our team has been alerted. You might want to try refreshing this page.",
}: {
  children: React.ReactNode;
  showDialog?: boolean;
  smallText?: boolean;
  text?: string;
}): React.ReactElement {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            color: "darkgray",
            fontSize: smallText ? "0.75rem" : "1.0rem",
          }}
        >
          <i>{text}</i>
        </div>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
