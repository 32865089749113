import chroma from "chroma-js";

// Utils
import { fromJS } from "immutable";

// Yoodli Design System Colors
let Y_COLORS: {
  [key: string]: string | { [key: string]: string };
} = {
  primary: "#524FFC",
  primaryLight: "#F5F8FE",
  primaryHover: "#4E4CBB",

  secondary: "#1DA9E6",
  secondaryLight: "#E8F6FC",
  secondaryLightest: "#F5FDFF",
  secondaryHover: "#0884BA",

  purple1: "#E6E5FF",
  purple2: "#9B99FF",
  purple3: "#040348",
  purple3Hover: "#07057E",

  // Chart colors for Dashboard
  chartPurple: "#886EFF",
  chartPurpleLight: "#bf92ff",
  chartGreen: "#71D481",
  chartGreenLight: "#9bd59e",
  chartBlue: "#66A4DE",

  programChart: {
    blue: "#E8F6FC",
    blueDark: "#bde5f6",
    purple: "#B7B7E7",
    purpleDark: "#7979d2",
  },

  teal: "#26A69A",

  lightBlue: "#e2edfb",
  gradient: {
    default: "linear-gradient(90deg, #1DA9E6 0%, #6966FE 82%)",
    defaultTransparent: "linear-gradient(90deg, #1DA9E690 0%, #6966FE90 82%)",

    reversed: "linear-gradient(90deg, #6966FE 0%, #1DA9E6 82%)",
    hover: "linear-gradient(90deg, #2A6DBD 1%, #4240B3 98%)",
    slanted: "linear-gradient(72deg, #1DA9E6 0%, #6966FE 82%)",
    slantedLoop: "linear-gradient(72deg, #1DA9E6 0%, #6966FE 50%, #1DA9E6 100%)",
    slantedLoopBorder:
      "linear-gradient(72deg, #1DA9E6 0%, #6966FE 20%, #bf92ff 50%, #6966FE 80%, #1DA9E6 100%)",
    slantedReversed: "linear-gradient(72deg, #6966FE 0%, #1DA9E6 82%)",
    slantedLight: "linear-gradient(72deg, #EFF5FD 0%, #EEEEFE 79%)",
    slantedMedium: "linear-gradient(72deg, #e2edfb 0%, #e0e0fd 79%)",
  },

  dark1: "#F5F5F5",
  dark2: "#E9ECEF",
  dark3: "#C4C9CD",
  dark4: "#8D99A3",
  dark5: "#495057",
  dark6: "#343A40",

  dark2Text: "#9B9A9A",

  light1: "#FFFFFF",
  light2: "#F6F8FF",
  light3: "#EFF5FD",

  microsoftTeams: "#4B59CD",
  focusArea: {
    authority: "#E1FCFF",
    listening: "#FFF0C9",
    presence: "#D0FBEC",
    usage: "#FBDAC2",
  },

  coldCall: "#C6DBFA",
  warmCall: "#FAD8B8",

  redNotification: "#ED3E33",

  // Error/Success/Warning
  redError: "#CD4631",
  redErrorDark: "#a43826",
  redErrorLight: "#FFEAE9",

  greenSuccess: "#0FA16D",
  greenSuccessDark: "#0b6e47",
  greenSuccessLight: "#E3F8E2",
  // Used for attention grabbers i.e. SAVE 40%
  greenWarning: "#47BF40",
  greenHighlightShadow: "#2ECC51",

  yellowWarning: "#FFCA42",
  yellowWarningDark: "#E99923",
  yellowWarningLight: "#fffaef",

  warningOrangeDark: "#B8520B",
  warningOrangeLight: "#FBDAC2",

  orangeLight: "#FFEFE1",
  orangeDark: "#A0530C",

  muiGrey: "#0000001f",
  muiGreyText: "#A2A2A2",

  poodli: {
    red: "#AD460B",
    redLight: "#FFF8F4",

    green: "#09593D",
    greenLight: "#F5FFFC",
    greenContrast: "#E5FEF3",

    yellow: "#FFF1AA",
    yellowContrast: "#FEF2D7",
    yellowLight: "#FFFFE9",

    gold: "#9C9557",
  },

  landingPageHeroLight: "#F7FDFF",
  black: "#000000",
  pink: "#FF70B3",
};

// Define specific toastmasters colors
const toastmasterGradient =
  "linear-gradient(160deg, #004165 0%, #004165 29%, #6966FE 74%, #1DA9E6 100%)";

const TOASTMASTERS_COLORS = {
  primary: "#016094",
  navTextColor: "#FFFFFF",
  bannerLinkColor: "#FFFFFF",
  // for TMI, there is no light and dark
  navBackgroundLight: toastmasterGradient,
  navBackgroundDark: toastmasterGradient,
};

// spread toastmasters color keys to default yoodli colors
// so i can access this key regardless of hte current site
Y_COLORS = {
  ...Y_COLORS,
  navTextColor: Y_COLORS.purple3,
  bannerLinkColor: Y_COLORS.purple2,
  navBackgroundLight: Y_COLORS.light1,
  navBackgroundDark: Y_COLORS.light2,
};

export const Y_SHADOWS = {
  box_shadow_1: "1px 2px 5px rgba(33, 37, 41, 0.16)",
  box_shadow_2: "1px 2px 5px #ffffff75",
  box_shadow_3: "0px 0.25px 1px rgba(0, 0, 0, 0.039), 0px 0.85px 3px rgba(0, 0, 0, 0.19)",
  box_shadow_4: "-2px -2px 3px rgba(33, 37, 41, 0.3)",
  box_shadow_5: "0px 8px 32px 0px rgba(0, 0, 0, 0.60)",
  box_shadow_6: "1px 2px 8px 2px rgba(33, 37, 41, 0.16)",
  box_shadow_7: "0px 4px 32px 0px rgba(0, 0, 0, 0.25)",
  // from jacobs ambassador page
  box_shadow_8: "0px 7px 16px 0px rgba(4, 3, 72, 0.30)",
  box_shadow_8_dark: "0px 7px 16px 0px rgba(4, 3, 72, 0.50)",

  box_shadow_9: "1px 0px 10px rgba(33, 37, 41, 0.16)",

  box_shadow_temp_hover:
    "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
  // 8 = upwards shadow for footers
  upShadow1: "0px -2px 8px 0px rgba(151, 151, 151, 0.25)",
  upShadow2: "0px -4px 8px 0px rgba(4, 3, 72, 0.25)",
  upShadowLine: "0px -1px 4px 0px rgba(0, 0, 0, 0.08)",
  // 9 = downwards shadow for headers
  downShadow1: "0px 2px 8px 0px rgba(151, 151, 151, 0.25)",
  box_shadow_inset: "inset 0px 0px 6px #D9D9D9",
  dark_elevation: "0px 1px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)",

  chart_type_selector: "0px 2.385px 2.385px 0px rgba(0, 0, 0, 0.25)",

  homepageBannerCard: "1px 2px 5px 0px rgba(33, 37, 41, 0.16)",
};

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export type DynamicColorType = NestedKeyOf<typeof Y_COLORS>;

const isToastmasters = () => {
  return (
    typeof window !== "undefined" && window?.location?.hostname.split(".")[0] === "toastmasters"
  );
};
export function getDynamicColor(color: DynamicColorType, opacity = 1): string {
  try {
    const colorArray = color.split(".");
    const colorMap = fromJS(Y_COLORS);
    let hexCode = colorMap.getIn(colorArray) as string;
    if (isToastmasters()) {
      if (TOASTMASTERS_COLORS[color]) {
        hexCode = TOASTMASTERS_COLORS[color];
      }
    }
    if (opacity !== 1) {
      hexCode = chroma(hexCode).alpha(opacity).hex();
    }
    if (!hexCode) {
      throw new Error(`Color "${color}" not found.`);
    }
    return hexCode;
  } catch (er) {
    if (!er.message.includes("window is not defined")) {
      console.log(`Error getting dynamic color: ${er}`);
    }
    return "#000000";
  }
}
