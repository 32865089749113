import React from "react";

// Components
import { Stack, SxProps, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

export const WizardTitle = ({
  children,
  icon,
  overrideSx,
}: React.PropsWithChildren & { overrideSx?: SxProps; icon?: JSX.Element }): JSX.Element => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      {icon}
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: { xs: 16, sm: 18 },
          fontFamily: "poppins",
          textAlign: "left",
          ...overrideSx,
        }}
      >
        {children}
      </Typography>
    </Stack>
  );
};

export const WizardSubTitle = ({
  children,
  overrideSx,
}: React.PropsWithChildren & { overrideSx?: SxProps }): JSX.Element => {
  return (
    <Typography
      sx={{
        fontSize: { xs: 12, md: 13 },
        fontWeight: 600,
        fontFamily: "poppins",
        lineHeight: 1.4,
        color: getDynamicColor("dark5"),
        textAlign: "left",
        ...overrideSx,
      }}
    >
      {children}
    </Typography>
  );
};

export const WizarcTitleCta = ({
  onClick,
  overrideSx,
  children,
}: React.PropsWithChildren & { onClick: () => void; overrideSx?: SxProps }): JSX.Element => {
  return (
    <Typography
      onClick={onClick}
      sx={{
        color: getDynamicColor("primary"),
        fontWeight: 700,
        fontSize: { xs: 12, md: 13 },
        cursor: "pointer",
        ...overrideSx,
      }}
    >
      {children}
    </Typography>
  );
};
