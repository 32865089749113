import { useState, useEffect } from "react";

export interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

/**
 * Hook to listen for window resize for values that need to be recalculated on
 * screen size change
 * https://usehooks.com/useWindowSize/
 * @returns width and height of screen.
 */

export function useWindowSize(callbackOnResize?: (windowSize?: WindowSize) => void): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      const newSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      callbackOnResize?.(newSize);
      setWindowSize(newSize);
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
