import React from "react";

// Components
import { Box, Stack, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { VIDEO_RATIO } from "utils/Constants";

type EmptyVideoViewProps = {
  handleAddVideo: () => void;
  isHubAdmin?: boolean;
};

export default function EmptyVideoView({
  handleAddVideo,
  isHubAdmin,
}: EmptyVideoViewProps): JSX.Element {
  return isHubAdmin ? (
    <Stack direction="row">
      <Box
        width="min(300px, 100%)"
        sx={{
          cursor: "pointer",
          maxWidth: 400,
          flexBasis: {
            xs: "100%",
            sm: "calc(100% / 2 - (24px * 1 / 2))",
            md: "calc(100% / 3 - (32px * 2 / 3))",
            xxl: "calc(100% / 4 - (40px * 3 / 4))",
          },
        }}
        onClick={handleAddVideo}
      >
        <Box
          width="100%"
          borderRadius="12px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={0.5}
          sx={{ background: getDynamicColor("dark2"), aspectRatio: `${VIDEO_RATIO}` }}
        >
          <AddCircleOutlineIcon
            sx={{ width: "40px", height: "40px", color: getDynamicColor("primary") }}
          />
        </Box>
        <Typography
          color={getDynamicColor("primary")}
          fontFamily="poppins"
          fontSize="12px"
          fontWeight={500}
        >
          Upload a video
        </Typography>
      </Box>
    </Stack>
  ) : (
    <Typography
      sx={{
        px: 2,
      }}
    >
      No videos found
    </Typography>
  );
}
