import React from "react";

// Components
import { Button, Checkbox, CircularProgress, Stack, Typography } from "@mui/material";

// Utils
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";

type EditInterviewBankAvailableHubsProps = {
  selectedInterviewBank: InterviewBankResponse;
  allHubs: HubV2Response[];
  onSubmit: UseMutateAsyncFunction<void, Error, { hubs: string[] }>;
  closeModal: () => void;
};

export default function EditInterviewBankAvailableHubs({
  selectedInterviewBank,
  allHubs,
  onSubmit,
  closeModal,
}: EditInterviewBankAvailableHubsProps): JSX.Element {
  const [availableHubs, setAvailableHubs] = React.useState<string[]>(
    selectedInterviewBank?.available_hubs ?? []
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const allHubsAvailable = availableHubs.length === allHubs.length;

  const handleSetSelectedHubs = (hubId) => {
    const newAvailableHubs = [...availableHubs];
    if (newAvailableHubs.includes(hubId)) {
      const indexToRemove = newAvailableHubs.indexOf(hubId);
      newAvailableHubs.splice(indexToRemove, 1);
    } else {
      newAvailableHubs.push(hubId);
    }

    setAvailableHubs(newAvailableHubs);
  };

  const handleSelectAll = () => {
    if (allHubsAvailable) {
      setAvailableHubs([]);
    } else {
      setAvailableHubs(allHubs.map((hub) => hub.id));
    }
  };

  const saveInterviewBank = async () => {
    try {
      setLoading(true);
      await onSubmit({ hubs: availableHubs });
      closeModal();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Stack sx={{ gap: 3, alignItems: "center", py: { xs: 6, md: 2 }, width: "100%" }}>
      <Stack sx={{ gap: 1, width: "100%" }}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center", pt: 1 }}
        >
          <Typography
            color={getDynamicColor("purple3")}
            fontFamily="poppins"
            fontSize="14px"
            fontWeight={700}
          >
            Available in<span style={{ color: getDynamicColor("redError") }}>*</span>
          </Typography>
          <Button
            onClick={handleSelectAll}
            sx={{ color: getDynamicColor("primary"), fontSize: "12px", fontWeight: 700 }}
          >
            {allHubsAvailable ? "Deselect all" : "Select all"}
          </Button>
        </Stack>
        <Stack
          sx={{
            overflowY: "auto",
            background: getDynamicColor("light1"),
            py: 1,
            px: 2.5,
            maxHeight: "350px",
            borderRadius: "4px",
            border: `1px solid ${getDynamicColor("dark3")}`,
          }}
        >
          {allHubs.map((hub, index) => (
            <Stack
              key={hub.id}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                borderBottom:
                  index === allHubs.length - 1 ? "hidden" : `1px solid ${getDynamicColor("dark3")}`,
                py: 2,
              }}
            >
              <Typography>{hub.name}</Typography>
              <Checkbox
                onClick={() => {
                  handleSetSelectedHubs(hub.id);
                }}
                checked={availableHubs.includes(hub.id)}
                sx={{
                  color: getDynamicColor("purple3"),
                }}
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
      {error && <Typography color={getDynamicColor("redError")}>{error}</Typography>}
      <Button onClick={saveInterviewBank} disabled={!availableHubs.length} variant="contained">
        Submit
      </Button>
    </Stack>
  );
}
