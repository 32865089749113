export enum UsagePlanType {
  FREE = "free",
  PRO_V1 = "pro_v1",
  UNLIMITED = "unlimited",
  ENTERPRISE = "enterprise",
}

export enum UsagePlanDuration {
  MONTHLY = "monthly",
  YEARLY = "yearly",
  NONE = "none",
}

export enum PricingExperiment {
  PRO_ADV = "pro_adv",
  CRUNCH_GROWTH = "crunch_growth",
}
