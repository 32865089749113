import React from "react";

// Components
import { Stack, Typography } from "@mui/material";
import { MobileTip } from "components/Home/ProductTips/MobileTip";
import { TipsCarousel } from "components/Home/ProductTips/TipsCarousel";
import UploadModal from "ui/UploadModal/UploadModal";

// Utils
import { DesktopTip } from "./DesktopTip";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import {
  ProductTipsDoc,
  TipGroupObj,
  setNewTipForWebclientUser,
  tipGroups,
  tipToContentMap,
} from "lib-frontend/utils/productTipsUtils";
import { ProductTip } from "lib-fullstack/db";
import { HomePageProductTipEvents } from "lib-fullstack/utils/productAnalyticEvents";

type ProductTipsProps = {
  productTipsDoc: ProductTipsDoc;
  showMobileView: boolean;
};

export const ProductTips = ({ productTipsDoc, showMobileView }: ProductTipsProps): JSX.Element => {
  const [showUploadModal, setShowUploadModal] = React.useState(false);

  const currentWebclientTipGroupId = productTipsDoc?.currentWebclientTipGroup;
  const currentPoodliTipGroupId = productTipsDoc?.currentPoodliTipGroup;
  const interactedTips = productTipsDoc?.tipsInteracted;

  const checkToResetCycle = () => {
    if (!productTipsDoc || !productTipsDoc.nextCycle) {
      return;
    }

    const today = new Date();
    const nextCycle = new Date(productTipsDoc.nextCycle);
    if (today > nextCycle) {
      setNewTipForWebclientUser(
        currentWebclientTipGroupId,
        currentPoodliTipGroupId,
        productTipsDoc.tipsInteracted
      );
    }
  };

  if (!productTipsDoc) {
    Instrumentation.logAmplitudeEvent(HomePageProductTipEvents.USAGE_TIPS_APPEARED);
    setNewTipForWebclientUser(currentWebclientTipGroupId, currentPoodliTipGroupId, []);
  }

  checkToResetCycle();

  // #region Render

  const onCtaClick = (tip: ProductTip) => {
    if (tip === ProductTip.UPLOAD_SPEECH) {
      setShowUploadModal(true);
    } else {
      tipToContentMap[tip]?.onButtonClick();
    }
  };

  const getCurrentTips = (): TipGroupObj => {
    const focusedTipGroup = currentWebclientTipGroupId ?? currentPoodliTipGroupId;
    if (!focusedTipGroup) {
      return;
    }

    const currentTipGroup = tipGroups.find((tipGroup) => tipGroup.id === focusedTipGroup);
    if (currentTipGroup === undefined) {
      throw new Error(`Could not find currentTipGroup with id ${focusedTipGroup}`);
    }

    return currentTipGroup;
  };

  const tips = getCurrentTips();
  if (!tips) {
    return;
  }

  let elements: JSX.Element[] = [];
  if (showMobileView) {
    elements = tips.tips
      .filter((tip) => Object.keys(tipToContentMap).includes(tip))
      .map((tip, idx) => (
        <MobileTip
          isInteracted={
            interactedTips.findIndex((interactedTip) => interactedTip.tip === tip) !== -1
          }
          tip={tip}
          onCtaClick={onCtaClick}
          idx={idx}
        />
      ));
  } else {
    elements = tips.tips
      .filter((tip) => Object.keys(tipToContentMap).includes(tip))
      .map((tip, idx) => (
        <DesktopTip
          isInteracted={
            interactedTips.findIndex((interactedTip) => interactedTip.tip === tip) !== -1
          }
          tip={tip}
          onCtaClick={onCtaClick}
          idx={idx}
        />
      ));
  }

  return (
    <Stack mb={{ xs: 3, md: 0 }} gap={2}>
      <Typography
        variant="poppins"
        component="h2"
        sx={{
          fontSize: { xs: 18, sm: 20 },
          fontWeight: 600,
        }}
      >
        Tips
      </Typography>
      <TipsCarousel
        carouselElements={elements}
        carouselControlsMarginTop={16}
        isSmallScreen={showMobileView}
      />
      <UploadModal open={showUploadModal} close={() => setShowUploadModal(false)} />
    </Stack>
  );

  // #endregion
};
