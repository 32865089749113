import striptags from "striptags";

/**
 * Remove HTML tags from the source
 * This is just a wrapper of NPM striptags package.
 *
 * Other declined options:
 * DOMPurify - it may not retain texts outside of tags
 *   ref. https://github.com/cure53/DOMPurify/issues/377)
 * sanitize-html - it has a problem with Poodli environment
 *   Crash as "Uncaught ReferenceError: require is not defined"
 *   This did not work: https://github.com/apostrophecms/sanitize-html?tab=readme-ov-file#requirements
 */
export const removeHtmlTags = (source: string | null | undefined): string | undefined => {
  if (!source) {
    // null or undefined or empty string. Return the source as-is.
    return source;
  }

  const result = striptags(source, [], "");

  return result;
};

/**
 * List of disallowed characters for our display-purpose data fields,
 * as a form of a pre-compiled regular expression.
 * Those are 7 characters --- *  . / : < > & "
 */
export const regexDisallowedCharsInDisplay = new RegExp('[./:<>&"]', "g");

/**
 * Strip disallowed characters for our display-purpose data fields.
 */
export const stripDisallowedCharsInDisplay = (str: string): string => {
  return str.replace(regexDisallowedCharsInDisplay, "");
};
