// Components
import { Button, Stack } from "@mui/material";

// Utils
import { InvitedEmailResponse, InvitesSentReview } from "../InviteMembers";

type InvitesSentProps = {
  onDone: () => void;
  emailResponses: InvitedEmailResponse;
  roleStr: string;
  hubNames: string[];
};

export const InvitesSent = ({
  onDone,
  emailResponses,
  roleStr,
  hubNames,
}: InvitesSentProps): JSX.Element => {
  return (
    <Stack
      sx={{
        height: "100%",
      }}
      alignItems="center"
      justifyContent="center"
      gap={1}
    >
      <InvitesSentReview emailResponses={emailResponses} roleStr={roleStr} hubNames={hubNames} />
      <Button
        variant="contained"
        onClick={onDone}
        sx={{
          mt: 1,
        }}
      >
        Done
      </Button>
    </Stack>
  );
};
