import React from "react";

// Components
import { Box, Button, Card, Typography } from "@mui/material";

// Utils
import GameLabels from "../GameLabels";
import { IMPROMPTU_GAME_OPTIONS } from "./GamesCatalog";
import HighScoreTooltip from "./HighScoreTooltip";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";

type GameCardProps = {
  handleGameStartPlayingClick: (
    game: (typeof IMPROMPTU_GAME_OPTIONS)[keyof typeof IMPROMPTU_GAME_OPTIONS]
  ) => void;
  highScore: number;
  game: (typeof IMPROMPTU_GAME_OPTIONS)[keyof typeof IMPROMPTU_GAME_OPTIONS];
};

const GameCard = ({ handleGameStartPlayingClick, highScore, game }: GameCardProps): JSX.Element => {
  return (
    <Box
      sx={{
        p: 1.5,
        position: "relative",
        flexBasis: { xs: "100%", md: "50%", xl: "25%" },
        maxWidth: 400,
      }}
    >
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          pb: 3,
          borderRadius: "12px",
          boxShadow: Y_SHADOWS.box_shadow_1,
        }}
      >
        <HighScoreTooltip score={highScore} game={game.name} />
        <img className="exercise-graphic" alt={`${game.name} exercise logo`} src={game.graphic} />
        <Typography
          mt={3}
          mb={2}
          component="h2"
          sx={{
            color: getDynamicColor("secondary"),
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 22,
            textAlign: "center",
            width: "100%",
          }}
        >
          {game.name}
        </Typography>

        <GameLabels labels={game.labels} />

        <Typography sx={{ fontSize: "16px" }} textAlign="center" mb={3} variant="body1">
          {game.description}
        </Typography>

        <Button
          aria-label={`Start practicing ${game.name}`}
          onClick={() => handleGameStartPlayingClick(game)}
          variant={isWhiteLabel() ? "contained" : "gradient"}
          sx={{
            width: "100%",
            backgroundColor: isWhiteLabel() ? getDynamicColor("primary") : "unset",
            "&:hover": {
              backgroundColor: isWhiteLabel() ? getDynamicColor("primary") : "unset",
            },
          }}
        >
          Start Practicing
        </Button>
      </Card>
    </Box>
  );
};

export default GameCard;
