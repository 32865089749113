import React from "react";

// Components
import { Typography, Button, Box, CircularProgress, Divider, Stack } from "@mui/material";
import BoundTextField from "lib-frontend/components/BoundTextField";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type InterviewBankNameModalProps = {
  initName: string;
  onSubmit: (name: string) => Promise<void>;
  ctaText: string;
};

// stolen using a constant here
const MAX_BANK_NAME_CHARACTERS = 50;

export function InterviewBankNameModal({
  initName,
  onSubmit,
  ctaText,
}: InterviewBankNameModalProps): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [bankName, setBankName] = React.useState<string>(initName);
  const [error, setError] = React.useState<string>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  const promptString =
    (initName ? (loading ? "Renaming" : "Rename") : loading ? "Naming" : "Name") +
    " your question bank";

  const submitName = async () => {
    try {
      setLoading(true);
      await onSubmit(bankName.trim());
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack gap={3} width="100%">
      <Typography
        sx={{
          fontFamily: "poppins",
          fontSize: { xs: 16, md: 19 },
          fontWeight: 700,
          color: getDynamicColor("purple3"),
        }}
      >
        {promptString}
      </Typography>
      {loading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <BoundTextField
            value={bankName}
            placeholder={"eg. General Interview Questions"}
            onChange={(e) => {
              setBankName(e.target.value);
            }}
            sx={{
              width: "100%",
              background: getDynamicColor("light1"),
            }}
            inputRef={inputRef}
            InputProps={{
              sx: {
                color: getDynamicColor("purple3"),
                background: getDynamicColor("light1"),
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 500,
                px: 1.5,
                py: 0.5,
              },
            }}
            maxLength={MAX_BANK_NAME_CHARACTERS}
            location="inside"
          />

          {error && (
            <Typography
              color={getDynamicColor("redError")}
              fontFamily="poppins"
              fontSize="14px"
              fontWeight={700}
            >
              {error}
            </Typography>
          )}
          <Divider sx={{ width: "100%", backgroundColor: getDynamicColor("light2") }} />
          <Button
            disabled={bankName.trim().length === 0}
            onClick={submitName}
            variant="contained"
            sx={{
              ml: "auto",
              borderRadius: "100px",
              fontSize: 16,
              fontWeight: 700,
              fontFamily: "poppins",
              px: 3,
              minWidth: "unset",
            }}
          >
            {ctaText}
          </Button>
        </>
      )}
    </Stack>
  );
}
