// Utils
/**
 * Customization for 'Bespoke' sites, which are detected by introspecting
 * the 'subdomain' part of the URL.
 */
import { FALLBACK_SUBDOMAIN } from "../utils/constants";
import { db } from "..";
import { sanitizeForLogging } from "../utils/logging";
import { SiteConfig } from "./SiteConfig";

// if you really need a script to run and window is being rude, uncomment below
// const window = undefined;
export interface SiteExports {
  siteConf: SiteConfig;
  siteId: string;
  siteSubdomain: string;
}

/**
 * Unfortunately, mapping subdomain -> siteId currently requires a DB lookup. So ,
 *   this fxn is async.
 * @param subdomain
 */
export async function getSiteIdBySubdomain(subdomain: string): Promise<string> {
  // Enable this line temporarily in order to ignore accessing host name and force the default site.
  // return "yoodli";

  let ret;

  while (!ret) {
    ret = await db.ts.query<db.Site>(db.sites, [db.ts.where("subdomain", "==", subdomain)]);

    // typesaurus has some nasty behaviors on network issues,
    // where it just lies and says no results in this case. To handle that
    // if we know the site does exist then just retry every 2 seconds
    if (subdomain === FALLBACK_SUBDOMAIN && (!ret || ret.length === 0)) {
      console.log("Could not find default site, retrying in 2 seconds");
      ret = null;
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  }

  if (ret.length === 1) {
    const [dbSite] = ret;
    return db.siteIdFor(dbSite);
  } else if (ret.length === 0) {
    throw new Error("No site found for subdomain: " + subdomain);
  } else {
    throw new Error("Too many sites found for subdomain: " + subdomain);
  }
}

export async function getSiteConfigBySubdomain(subdomain: string): Promise<SiteExports> {
  const siteId = await getSiteIdBySubdomain(subdomain);
  return getSiteConfigBySiteId(siteId);
}

export async function getSiteConfigBySiteId(siteId: string): Promise<SiteExports> {
  const [dbSite, dbSiteFeatureDiffs, dbSiteLandingPage, dbSiteImageDiffs] = await Promise.all([
    db.ts.get<db.Site>(db.sites, siteId),
    db.ts.get<db.FeatureDiffs>(db.siteDocs(siteId), db.SiteDocTypes.FEATURE_DIFFS),
    db.ts.get<db.LandingPage>(db.siteDocs(siteId), db.SiteDocTypes.LANDING_PAGE),
    db.ts.get<db.ImageDiffs>(db.siteDocs(siteId), db.SiteDocTypes.IMAGE_DIFFS),
  ]);

  if (!dbSite) {
    console.error("Warning: No site info found for " + sanitizeForLogging(siteId));
  }

  const siteConf: SiteConfig = {
    ...dbSite?.data,
    featureDiffs: dbSiteFeatureDiffs?.data ?? {},
    landingPage: dbSiteLandingPage?.data ?? {},
    imageDiffs: dbSiteImageDiffs?.data ?? {},
    siteId,
  };
  return {
    siteConf,
    siteId,
    siteSubdomain: dbSite?.data?.subdomain,
  };
}
