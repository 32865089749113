import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/analytics";

import { getFirebaseConfig, inBrowser, ReactAppEnvironment } from "lib-fullstack/client_env";

/** Firebase Module in lib-frontend. Other Firebase functions are in web-client's Firebase Module */

/**
 * Initializes Firebase
 */
export const initializeFirebase = (environment?: ReactAppEnvironment): typeof firebase => {
  if (!firebase.apps.length) {
    const config = getFirebaseConfig(environment);

    // short-term override to switch to the new auth domain (same as application server)
    if (inBrowser) {
      if (
        window.location.hostname === "localhost" ||
        window.location.hostname.endsWith("_app.pr.yoodli.ai")
      ) {
        // Fallback to dev's auth domain for http://localhost:3001
        // SSO redirection sign-in (including Google/Microsoft) fails with this on Safari
        config.authDomain = "app.dev.yoodli.ai";
      } else {
        config.authDomain = window.location.hostname;
      }
    }

    console.log(`Initializing Firebase with authDomain: ${config.authDomain}`);
    firebase.initializeApp(config);

    // setup any experimental settings overrides we want to apply
    // we allow query params to set these at first, then use local storage to persist them
    // as we often overwrite the urls
    try {
      if (inBrowser) {
        const longPollingOverride = new URLSearchParams(window.location.search).get(
          "enableLongPolling"
        );

        if (longPollingOverride?.toLowerCase() === "false") {
          localStorage["experimentalAutoDetectLongPolling"] = false;
        } else if (longPollingOverride?.toLowerCase() === "true") {
          localStorage.removeItem("experimentalAutoDetectLongPolling");
        }

        if (
          localStorage["experimentalAutoDetectLongPolling"] === undefined ||
          localStorage["experimentalAutoDetectLongPolling"] === true
        ) {
          console.log("Firestore long polling auto detect enabled");
          firebase.firestore().settings({ experimentalAutoDetectLongPolling: true, merge: true });
          localStorage.removeItem("experimentalAutoDetectLongPolling");
        }
      }
    } catch (error) {
      console.log("failed to setup firebase experimental features");
    }

    // attach a customer logger that quiets likely expected errors into warning messages
    // this is critical for sentry, as network errors or other transient errors are logged as errors
    firebase.onLog((log) => {
      if (log.level === "silent") {
        return;
      } else if (log.level === "debug") {
        console.debug(log.message, log.args);
      } else if (log.level === "verbose" || log.level === "info") {
        console.info(log.message, log.args);
      } else {
        // combine warn and error under warn
        console.warn(log.message, log.args);
      }
    });
  } else {
    firebase.app();
  }
  return firebase;
};
