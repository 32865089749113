import { isMacOs } from "react-device-detect";

// Components
import { Avatar, Box, Card, Stack, SxProps, Typography } from "@mui/material";

// Assets
import { ReactComponent as MacDownloadInstructionsGraphic } from "../../images/graphics/poodli_ob_download_mac.svg";
import { ReactComponent as WindowsDownloadInstructionsGraphic } from "../../images/graphics/poodli_ob_download_windows.svg";
import { ReactComponent as AppleIcon } from "lib-frontend/assets/AppleIcon.svg";
import { ReactComponent as WindowsIcon } from "lib-frontend/assets/WindowsIcon.svg";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { PoodliDownloadedWheres } from "lib-fullstack/utils/productAnalyticEvents";

const INSTRUCTION_TEXTS = [
  "Open the Yoodli installer (in your Downloads folder)",
  isMacOs ? "Drag and drop Yoodli into the Applications folder" : "Complete the installation ",
  isMacOs
    ? "Launch Yoodli from the Applications folder to get started!"
    : "Launch the Yoodli desktop app",
];

type PoodliDownloadInstructionsProps = {
  downloadAgain: (where: PoodliDownloadedWheres) => void;
  where: PoodliDownloadedWheres;
  onSetUpLaterClicked?: () => void; // if undefined, does not display the "set up later" button
  cardSxProps?: SxProps;
};

const PoodliDownloadInstructions = ({
  downloadAgain,
  where,
  onSetUpLaterClicked,
  cardSxProps,
}: PoodliDownloadInstructionsProps): JSX.Element => {
  const renderInstructions = () => (
    <Box
      sx={{
        fontWeight: 600,
        marginBottom: "24px",
        width: "fit-content",
      }}
    >
      {INSTRUCTION_TEXTS.map((instruction, index) => {
        return (
          <Stack
            key={instruction}
            direction="row"
            sx={{
              marginTop: index != 0 && "16px",
            }}
            alignItems={"center"}
            gap={3}
          >
            <Avatar
              sx={{
                backgroundColor: getDynamicColor("purple1"),
                height: "32px",
                width: "32px",
                color: getDynamicColor("purple3"),
                marginRight: "16px",
              }}
            >
              {index + 1}
            </Avatar>
            <Typography style={{ fontSize: "16px", color: getDynamicColor("purple3") }}>
              {instruction}
            </Typography>
          </Stack>
        );
      })}
    </Box>
  );

  const renderCompatibleDevices = () => (
    <Stack
      gap={2}
      style={{ color: getDynamicColor("dark4"), fontSize: "14px", alignItems: "center" }}
    >
      <Stack
        direction="row"
        sx={{
          fontWeight: 400,
        }}
        flexWrap="wrap"
        gap={2}
      >
        <Typography
          sx={{
            fontWeight: 700,
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          Compatible with
        </Typography>
        <Typography sx={{ display: "inline-flex", alignItems: "center" }}>
          <AppleIcon
            style={{
              height: "26px",
              width: "26px",
              marginRight: "6px",
            }}
          />
          macOS 12+ (Monterey)
        </Typography>
        <Typography sx={{ display: "inline-flex", alignItems: "center", ml: "2px" }}>
          <WindowsIcon
            style={{
              height: "22px",
              width: "22px",
              marginRight: "8px",
            }}
          />
          Windows 10+
        </Typography>
      </Stack>

      <Typography
        sx={{
          fontWeight: 700,
        }}
      >
        Not working?
        <Box
          onClick={() => downloadAgain(where)}
          component="span"
          sx={{
            color: getDynamicColor("primary"),
            cursor: "pointer",
            ml: 1,
            mt: "2px",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
            fontWeight: 600,
          }}
        >
          Download Again
        </Box>
      </Typography>
    </Stack>
  );

  return (
    <Card
      sx={{
        width: "min(90%, 702px)",
        margin: "auto",
        color: getDynamicColor("purple3"),
        backgroundColor: getDynamicColor("light1"),
        borderRadius: "16px",
        overflow: "auto",
        p: { xs: 2.5, sm: 3, md: 4 },
        position: "relative",
        ...cardSxProps,
      }}
    >
      <Stack gap={3} alignItems="center">
        <Box
          sx={{
            maxWidth: isMacOs ? "200px" : "65px",
            maxHeight: "58px",
            minWidth: "50px",
            minHeight: "50px",
            flexGrow: 1,
            mx: "auto",
            svg: {
              width: "100%",
              height: "100%",
            },
          }}
        >
          {isMacOs ? <MacDownloadInstructionsGraphic /> : <WindowsDownloadInstructionsGraphic />}
        </Box>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontSize: "24px",
            fontWeight: 700,
            fontFamily: "poppins",
            padding: "16px",
            width: "100%",
            textAlign: "center",
            mt: 1,
          }}
        >
          Let’s get talkin’
        </Typography>
        {renderInstructions()}
        {renderCompatibleDevices()}
        {onSetUpLaterClicked && (
          <Box
            onClick={onSetUpLaterClicked}
            component="span"
            sx={{
              color: getDynamicColor("dark4"),
              cursor: "pointer",
              mt: 1,
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
              fontWeight: 400,
              fontSize: "13px",
            }}
          >
            Set up later
          </Box>
        )}
      </Stack>
    </Card>
  );
};

export default PoodliDownloadInstructions;
