import { isProd } from "../client_env";
import * as db from "typesaurus";
const LOCAL_STORAGE_KEY = "debug-db" as const;

/* We wrap the typesaurus `db.query()` method for two reasons:

1. By throwing a new Error here, Javascript creates a new stacktrace here at
   this file, which will have a useful callstack of code we wrote ourselves.
   If we do not throw a new Error, any firebase error ends up throwing from deep
   within node_modules and (for some reason??) it does not actually show any of
   our own filenames or line numbers.

   See also: screenshots at https://github.com/Yoodli/yoodli/pull/1902

2. It also adds an optional ability to log every `db.query()` call, printing
  its args *and* results to the browser console for debugging purposes. Call
  `enableDebugDb()` from wherever you would like to start debugging, and
  `disableDebugDb()` to stop. Alternatively, you can enable it directly from
  the browser console by running `localStorage.setItem("debug-db", "true")`.

  Note that it will *not* print debug output in Production.

*/
export async function query<Model>(
  collection: db.Collection<Model> | db.CollectionGroup<Model>,
  queries: db.Query<Model, keyof Model>[]
): Promise<db.Doc<Model>[]> {
  try {
    debugDbPrologue(collection, queries);
    const result = await db.query(collection, queries);
    debugDbEpilogue(collection, queries, result);
    return result;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
}

function debugDbPrologue<Model>(
  collection: db.Collection<Model> | db.CollectionGroup<Model>,
  queries: db.Query<Model, keyof Model>[]
): void {
  try {
    if (isProd() || !getDebugDb()) return;

    console.log(`db.query("${collection.path}", ${queriesToString(queries)})`);
  } catch (err) {
    // This debug method should never cause the query method itself to fail, so catch & log any errors here.
    console.error(err);
  }
}

function debugDbEpilogue<Model>(
  collection: db.Collection<Model> | db.CollectionGroup<Model>,
  queries: db.Query<Model, keyof Model>[],
  result: db.Doc<Model>[]
): void {
  try {
    if (isProd() || !getDebugDb()) return;

    console.group(`db.query("${collection.path}", ${queriesToString(queries)})`);
    console.debug(`Returned ${result.length} objects:`);
    result.forEach((r) => {
      // Show each item in a skimmable way in the browser by spreading the "interesting" data.
      console.debug({ RAW_RESULT: { ...r }, ...r.data });
    });
    console.groupEnd();
  } catch (err) {
    // This debug method should never cause the query method itself to fail, so catch & log any errors here.
    console.error(err);
  }
}

function queriesToString<Model>(queries: db.Query<Model, keyof Model>[]): string {
  const terms = queries.map((q) => queryTermToString(q));
  const indent = terms.length <= 1 ? "" : "\n\t";
  const endOfTerms = terms.length <= 1 ? "" : "\n";
  return `[${indent}${terms.join(`,${indent}`)}${endOfTerms}]`;
}

function queryTermToString<Model>(term: db.Query<Model, keyof Model>): string {
  switch (term.type) {
    case "where":
      return `${term.type} ${term.field} ${term.filter} ${term.value}`;
    case "limit":
      return `${term.type} ${term.number}`;
    case "order": {
      let str = `${term.type} ${String(term.field)} ${term.method}`;
      if (term.cursors) str += " " + JSON.stringify(term.cursors);
      return str;
    }
    default:
      return JSON.stringify(term);
  }
}

let debugDbFlag = false;

function getDebugDb(): boolean {
  if (typeof localStorage === "undefined") {
    return debugDbFlag;
  } else {
    return localStorage?.getItem(LOCAL_STORAGE_KEY) === "true";
  }
}

export function enableDebugDb(): void {
  if (typeof localStorage === "undefined") {
    debugDbFlag = true;
  } else {
    localStorage?.setItem(LOCAL_STORAGE_KEY, "true");
  }
}

export function disableDebugDb(): void {
  if (typeof localStorage === "undefined") {
    debugDbFlag = false;
  } else {
    localStorage?.removeItem(LOCAL_STORAGE_KEY);
  }
}
