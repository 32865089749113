import { Stack, Typography, LinearProgress } from "@mui/material";
import { getDynamicColor } from "lib-frontend/utils/Colors";

export default function PasswordProgress({ score }: { score: number }): JSX.Element {
  let strength: { color: string; text: string } = {
    color: getDynamicColor("redError"),
    text: "Weak",
  };

  if (score >= 20 && score <= 50)
    strength = {
      color: "#ffa500",
      text: "Medium",
    };

  if (score > 50)
    strength = {
      color: getDynamicColor("greenSuccess"),
      text: "Strong",
    };

  return (
    <Stack direction="row" alignItems="center" gap={1} pl={1} width="100%">
      <LinearProgress
        variant="determinate"
        value={score}
        sx={{
          width: "80%",
          backgroundColor: "#cccccc88",
          "& .MuiLinearProgress-bar": {
            backgroundColor: strength.color,
          },
        }}
      />
      <Typography fontSize="14px">{strength.text}</Typography>
    </Stack>
  );
}
