// Components
import { Box } from "@mui/material";
import { ContainedLoadingAnimation } from "lib-frontend/components/Animations/LoadingAnimation";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { MOBILE_TOP_NAVBAR_HEIGHT } from "utils/Constants";

type OrgLoadingProps = {
  prompts?: string[];
  bgColor?: string;
};

export const OrgLoading = ({ prompts, bgColor }: OrgLoadingProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();

  return (
    <Box
      height={{ xs: `calc(100vh - ${MOBILE_TOP_NAVBAR_HEIGHT})`, md: "100vh" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          width: "min(100%, 500px)",
          position: "relative",
          pb: 5,
        }}
      >
        <ContainedLoadingAnimation
          showPrompt
          prompts={prompts}
          bgColor={bgColor ?? getDynamicColor(isSmallScreen ? "light1" : "dark1")}
        />
      </Box>
    </Box>
  );
};
