// Components
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";

// Utils
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { toTitleCase } from "lib-frontend/utils/Utilities";
import { MemberInfo } from "./Members/MembersTable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { asyncMap } from "lib-fullstack/utils/asyncMap";
import {
  actOrgInviteV2,
  deleteOrgInviteV2,
  deleteOrgUserV2,
} from "lib-frontend/modules/AxiosInstance";
import { OrgMembersQueryKeys, OrgMembersSectionStatus } from "./Members/OrgMembers";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";
import { OrgInviteAction } from "lib-fullstack/api/orgApiTypes";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { OrgHubsQueryKeys } from "./Hubs/OrgHubs";
import { TableTabLabel } from "lib-frontend/utils/orgUtils";

export type TableTab = {
  label: TableTabLabel;
  count?: number;
};

enum SearchFilter {
  Members = "member emails",
  Invites = "invites",
}

type TableActionBarProps = {
  tableTabs: TableTab[];
  selectedTabLabel: TableTabLabel;
  setSelectedTabLabel: (tabLabel: TableTabLabel) => void;
  searchText: string;
  setSearchText: (str: string) => void;
  bulkActionUserList: MemberInfo[];
  setBulkActionUserList: (members: MemberInfo[]) => void;
  setSectionStatus: (status: string) => void;
  origin: "org" | "group";
};

export const TableActionBar = ({
  tableTabs,
  selectedTabLabel,
  setSelectedTabLabel,
  searchText,
  setSearchText,
  bulkActionUserList,
  setBulkActionUserList,
  setSectionStatus,
  origin,
}: TableActionBarProps): JSX.Element => {
  const { invalidateDefaultOrgQuery, defaultOrg } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const { showNotificationBanner } = useNotification();

  const [openRemoveUsersModal, setOpenRemoveUsersModal] = React.useState<boolean>(false);

  const removeMembersMutation = useMutation({
    mutationFn: async () => {
      await asyncMap(
        bulkActionUserList,
        async (member) => {
          await deleteOrgUserV2(defaultOrg.id, member.id);
        },
        5,
        1
      );
    },
    onSuccess: () => {
      setBulkActionUserList([]);
      if (origin === "org") {
        void invalidateDefaultOrgQuery();
        void queryClient.invalidateQueries({
          queryKey: [OrgMembersQueryKeys.OrgMembers, defaultOrg.id],
        });
        void queryClient.invalidateQueries({
          queryKey: [OrgMembersQueryKeys.OrgUserCounts, defaultOrg.id],
        });
      } else {
        void invalidateDefaultOrgQuery();
        void queryClient.invalidateQueries({
          queryKey: [OrgHubsQueryKeys.HUB_USERS, defaultOrg.id],
        });
        void queryClient.invalidateQueries({
          queryKey: [OrgMembersQueryKeys.HubUserCounts, defaultOrg.id],
        });
      }
      Instrumentation.logOrgMemberRemoved(defaultOrg.id);
    },
    onError: (e: Error) => {
      console.log("Error removing org members: ", e);
    },
  });

  const resendInviteMutation = useMutation({
    mutationFn: async () => {
      await asyncMap(
        bulkActionUserList,
        async (member) => {
          await actOrgInviteV2(defaultOrg.id, member.email, OrgInviteAction.RESEND);
        },
        10,
        1
      );
    },
    onSuccess: () => {
      setBulkActionUserList([]);
      showNotificationBanner(<>Resent invite to {bulkActionUserList.length} members</>, {
        variant: YoodliNotificationBannerVariant.Success,
      });
    },
    onError: (e: Error) => {
      console.log("Error in resending invites: ", e);
      showNotificationBanner(<>Failed to resend invite to {bulkActionUserList.length} members</>, {
        variant: YoodliNotificationBannerVariant.Danger,
      });
    },
  });

  const cancelInviteMutation = useMutation({
    mutationFn: async () => {
      await asyncMap(
        bulkActionUserList,
        async (member) => {
          await deleteOrgInviteV2(defaultOrg.id, member.email);
        },
        10,
        1
      );
    },
    onSuccess: () => {
      setBulkActionUserList([]);
      void invalidateDefaultOrgQuery();
      if (origin === "org") {
        void queryClient.invalidateQueries({
          queryKey: [OrgMembersQueryKeys.OrgUserCounts, defaultOrg.id],
        });
        return queryClient
          .invalidateQueries({
            queryKey: [OrgMembersQueryKeys.OrgInvites, defaultOrg.id],
          })
          .then(() => {
            showNotificationBanner(<>Cancelled invite to {bulkActionUserList.length} members</>, {
              variant: YoodliNotificationBannerVariant.Success,
            });
          });
      } else {
        void queryClient.invalidateQueries({
          queryKey: [OrgMembersQueryKeys.HubUserCounts, defaultOrg.id],
        });
        return queryClient
          .invalidateQueries({
            queryKey: [OrgHubsQueryKeys.HUB_INVITES, defaultOrg.id],
          })
          .then(() => {
            showNotificationBanner(<>Cancelled invite to {bulkActionUserList.length} members</>, {
              variant: YoodliNotificationBannerVariant.Success,
            });
          });
      }
    },
    onError: (e: Error) => {
      console.log("Error in cancelling invites: ", e);
      showNotificationBanner(<>Failed to cancel invite to {bulkActionUserList.length} members</>, {
        variant: YoodliNotificationBannerVariant.Danger,
      });
    },
  });

  const renderTableTabs = () => (
    <Stack
      direction="row"
      sx={{ height: "55px", alignItems: "center", gap: { xs: 1, md: 3, lg: 6 } }}
    >
      {Object.values(tableTabs).map((tab) => (
        <Button
          key={tab.label}
          onClick={() => {
            setSelectedTabLabel(tab.label);
            setBulkActionUserList([]);
            setSearchText("");
          }}
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            color:
              selectedTabLabel === tab.label
                ? getDynamicColor("primary")
                : getDynamicColor("purple3"),
            px: 1,
            borderRadius: 0,
            borderBottom:
              selectedTabLabel === tab.label ? `2px solid ${getDynamicColor("primary")}` : "none",
            top: selectedTabLabel === tab.label ? 0 : -1,
            transition: "none",
            textTransform: "capitalize",
          }}
        >
          {tab.label} {typeof tab.count === "number" ? `(${tab.count})` : ""}
        </Button>
      ))}
    </Stack>
  );

  const renderBulkActionButtons = () => {
    if (selectedTabLabel === TableTabLabel.Invites) {
      return (
        <>
          <Button
            variant="text"
            onClick={() => {
              cancelInviteMutation.mutate();
            }}
            sx={{
              color: getDynamicColor("redError"),
              borderRight: `1px solid ${getDynamicColor("dark3")}`,
            }}
            endIcon={
              cancelInviteMutation.isPending && (
                <CircularProgress size={14} sx={{ color: getDynamicColor("redError") }} />
              )
            }
          >
            Cancel invites
          </Button>
          <Button
            variant="text"
            onClick={() => resendInviteMutation.mutate()}
            endIcon={
              resendInviteMutation.isPending ? <CircularProgress size={14} /> : <ArrowForwardIcon />
            }
            sx={{
              color: getDynamicColor("primary"),
            }}
          >
            Resend invites
          </Button>
        </>
      );
    }
    return (
      <>
        <Button
          variant="text"
          onClick={() => {
            setOpenRemoveUsersModal(true);
          }}
          sx={{
            color: getDynamicColor("redError"),
            borderRight: `1px solid ${getDynamicColor("dark3")}`,
          }}
        >
          Remove from org
        </Button>
        <Button
          variant="text"
          onClick={() => setSectionStatus(OrgMembersSectionStatus.ReassignGroups)}
          endIcon={<ArrowForwardIcon />}
          sx={{
            color: getDynamicColor("primary"),
          }}
        >
          Reassign groups
        </Button>
      </>
    );
  };

  const renderBulkActionGroup = () => (
    <Stack
      direction={{ xs: "column", md: "row" }}
      sx={{
        gap: 3,
        height: { xs: "unset", md: "55px" },
        alignItems: { xs: "flex-start", md: "center" },
        justifyContent: "space-between",
      }}
    >
      <Stack direction="column">
        <Typography
          sx={{
            fontFamily: "poppins",
            fontWeight: 600,
            fontSize: 12,
            color: getDynamicColor("dark5"),
            whiteSpace: "nowrap",
            width: "130px",
          }}
        >
          {bulkActionUserList.length}{" "}
          {selectedTabLabel === TableTabLabel.Members ? "members" : "invites"} selected
        </Typography>
        <Typography
          sx={{
            fontFamily: "poppins",
            fontWeight: 600,
            fontSize: 12,
            color: getDynamicColor("redError"),
            whiteSpace: "nowrap",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => setBulkActionUserList([])}
        >
          Clear all
        </Typography>
      </Stack>
      <Box
        sx={{
          display: "flex",
          borderRadius: "50px",
          border: `1px solid ${getDynamicColor("dark2")}`,
          boxShadow: Y_SHADOWS.box_shadow_1,
          fontWeight: 700,
          overflow: "hidden",
          whitespace: "nowrap",

          "& button": {
            borderRadius: 0,
            px: { xs: 2, md: 4 },
            py: { xs: 1, md: 2 },
            whiteSpace: "nowrap",
            flex: 1,
            fontSize: { xs: "12px", sm: "14px", md: "16px" },
          },
        }}
      >
        {renderBulkActionButtons()}
      </Box>
    </Stack>
  );

  const renderSearchFilter = (searchLabel: SearchFilter) => (
    <YoodliSearchBar
      value={searchText}
      sx={{ maxWidth: { xs: "100%", md: "300px" } }}
      onChange={(e) => setSearchText(e.target.value)}
      clearSearch={() => setSearchText("")}
      placeholder={`Search ${toTitleCase(searchLabel)}`}
    />
  );

  return (
    <Stack
      gap={2}
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      sx={{ flexWrap: "wrap" }}
    >
      {bulkActionUserList.length > 0 ? renderBulkActionGroup() : renderTableTabs()}
      {selectedTabLabel === TableTabLabel.Members
        ? renderSearchFilter(SearchFilter.Members)
        : renderSearchFilter(SearchFilter.Invites)}
      <YoodliCtaModal
        ctaBody={{
          title: (
            <>
              Are you sure you want to remove {bulkActionUserList.length} members from{" "}
              <strong style={{ color: getDynamicColor("primary") }}>{defaultOrg?.name}</strong>?
            </>
          ),
          subtitle: (
            <Stack sx={{ fontWeight: 400, position: "relative" }}>
              <Typography>
                The following members will be removed from all groups and will release an
                organization seat:
              </Typography>
              <Stack sx={{ overflow: "auto", maxHeight: 300, pl: 2, mt: 2 }}>
                {bulkActionUserList.map((member) => (
                  <Typography key={member.email}>{member.name}</Typography>
                ))}
              </Stack>
            </Stack>
          ),
        }}
        open={openRemoveUsersModal}
        theme={YoodliCtaModalTheme.Danger}
        loading={removeMembersMutation.isPending}
        hideCloseButton={true}
        close={() => setOpenRemoveUsersModal(false)}
        buttons={
          {
            primary: {
              text: `Remove ${bulkActionUserList.length} members`,
              handler: removeMembersMutation.mutateAsync,
            },
            secondary: { text: "Cancel", handler: () => setOpenRemoveUsersModal(false) },
          } as CtaButtonHandlers
        }
      />
    </Stack>
  );
};
