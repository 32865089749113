import { Stack, Box, Typography } from "@mui/material";
import { AnimatedGradientText } from "lib-frontend/components/AnimatedGradientText";
import { getDynamicColor } from "lib-frontend/utils/Colors";

type ListItemProps = {
  icon: JSX.Element;
  text: string;
};

const ListItem = ({ icon, text }: ListItemProps) => {
  return (
    <Stack direction="row" gap={3} sx={{ alignItems: "center" }}>
      <Box sx={{ width: 35, height: 35 }}>{icon}</Box>
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontFamily: "poppins",
          fontSize: "16px",
          fontWeight: 500,
          maxWidth: "350px",
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

type SplashInfoScreenProps = {
  title: string;
  items: ListItemProps[];
  cta: JSX.Element;
};

export const SplashInfoScreen = ({ title, items, cta }: SplashInfoScreenProps): JSX.Element => {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      gap={{ xs: 6, md: 5 }}
      sx={{ alignItems: "center" }}
    >
      <Stack gap={1.5}>
        <Box
          sx={{
            width: "40px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: getDynamicColor("greenSuccessLight"),
            color: getDynamicColor("greenSuccess"),
            fontFamily: "poppins",
            fontSize: "10px",
            fontWeight: 600,
          }}
        >
          NEW
        </Box>
        <AnimatedGradientText
          sx={{
            fontFamily: "poppins",
            fontSize: { xs: "27px", md: "30px" },
            fontWeight: 800,
            maxWidth: "400px",
          }}
        >
          {title}
        </AnimatedGradientText>
      </Stack>
      <Stack gap={{ xs: 4, md: 6 }}>
        {items.map((item, index) => (
          <ListItem key={index} icon={item.icon} text={item.text} />
        ))}
        {cta}
      </Stack>
    </Stack>
  );
};
