import { Record, Static, String } from "runtypes";

/**
 * Base type for responses to getting integration secrets for an organization.
 */
export const GetOrgIntegrationSecretsResponseBase = Record({});

/**
 * Base type for responses to getting integration secrets for an organization.
 */
export type GetOrgIntegrationSecretsResponseBase = Static<
  typeof GetOrgIntegrationSecretsResponseBase
>;

/**
 * Response to getting OAuth 1.0 secrets for an organization.
 */
export const GetOrgIntegrationOAuth10SecretsResponse = GetOrgIntegrationSecretsResponseBase.extend({
  /**
   * OAuth 1.0 key.
   */
  key: String,
  /**
   * OAuth 1.0 secret.
   */
  secret: String,
});

/**
 * Response to getting OAuth 1.0 secrets for an organization.
 */
export type GetOrgIntegrationOAuth10SecretsResponse = Static<
  typeof GetOrgIntegrationOAuth10SecretsResponse
>;
