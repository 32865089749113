import React from "react";
import { useLocation } from "react-router-dom";

// Components
import { CheckCircleRounded as SuccessIcon } from "@mui/icons-material";
import { HighlightOffRounded as DangerIcon } from "@mui/icons-material";
import { WarningRounded as WarningIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";

// Utils
import { useNotificationContext } from "lib-frontend/contexts/NotificationContext";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";

export enum YoodliNotificationBannerVariant {
  Success = "success",
  Warning = "warning",
  Danger = "danger",
  Neutral = "neutral",
}

const BannerThemeSx = {
  [YoodliNotificationBannerVariant.Success]: {
    color: getDynamicColor("greenSuccess"),
    backgroundColor: getDynamicColor("greenSuccessLight"),
  },
  [YoodliNotificationBannerVariant.Warning]: {
    color: getDynamicColor("warningOrangeDark"),
    backgroundColor: getDynamicColor("warningOrangeLight"),
  },
  [YoodliNotificationBannerVariant.Danger]: {
    color: getDynamicColor("redError"),
    backgroundColor: getDynamicColor("redErrorLight"),
  },
  [YoodliNotificationBannerVariant.Neutral]: {
    color: getDynamicColor("purple3"),
    backgroundColor: getDynamicColor("light2"),
  },
};

export default function YoodliNotificationBanner(): JSX.Element {
  const { notification, hideNotification, notifAnchorHeight } = useNotificationContext();
  const [isVisible, setIsVisible] = React.useState(false);
  const location = useLocation();
  const { navDrawerOffset } = useNavDrawerOffset();

  React.useEffect(() => {
    if (notification) {
      setIsVisible(true);
      // Set a timeout to hide the notification after 3 seconds
      const timer = setTimeout(() => {
        handleClose();
      }, notification.options?.duration || 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  React.useEffect(() => {
    // Hide notification instantly on route change
    hideNotification(true);
  }, [location, hideNotification]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => hideNotification(false), 300); // Match this with transition duration
  };

  const renderIcon = () => {
    switch (notification.options?.variant) {
      case YoodliNotificationBannerVariant.Success:
        return <SuccessIcon />;
      case YoodliNotificationBannerVariant.Danger:
        return <DangerIcon />;
      case YoodliNotificationBannerVariant.Warning:
        return <WarningIcon />;
      case YoodliNotificationBannerVariant.Neutral:
        return <></>;
      default:
        return <></>;
    }
  };

  if (!notification) {
    return null;
  }

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        width: `calc(100% - ${navDrawerOffset})`,
        position: "absolute",
        minHeight: "40px",
        p: "12px",
        fontSize: "12px",
        fontWeight: 600,
        fontFamily: "Poppins",
        left: navDrawerOffset,
        right: 0,
        zIndex: 10,
        transition: "top 0.3s ease-in-out, left 0.3s ease-in-out",
        top: isVisible ? notifAnchorHeight : "-60px",
        ...BannerThemeSx[notification.options?.variant],
        ...notification.options?.sx,
      }}
    >
      {notification.options?.showIcon && renderIcon()}
      <Typography
        sx={{
          "& strong": {
            fontWeight: 700,
            fontSize: "13px",
          },
          pr: notification.options?.showCloseButton && "32px",
        }}
      >
        {notification.message}
      </Typography>
      {notification.options?.showCloseButton && (
        <IconButton
          sx={{ position: "absolute", right: "12px", color: "inherit" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
}
