// Components
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Stack, Typography, Button } from "@mui/material";

// Utils
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { HubModalStatus } from "utils/Enums";

type EmptyCourseViewProps = {
  setModalStatus: (modalStatus: HubModalStatus) => void;
  isHubAdmin?: boolean;
};

export default function EmptyCourseView({
  setModalStatus,
  isHubAdmin,
}: EmptyCourseViewProps): JSX.Element {
  return isHubAdmin ? (
    <Stack
      gap={4}
      border={`1px solid ${getDynamicColor("dark2")}`}
      borderRadius="8px"
      boxShadow={Y_SHADOWS.box_shadow_1}
      p={3.5}
    >
      <Stack gap={1}>
        <Typography
          color={getDynamicColor("purple3")}
          fontFamily="poppins"
          fontSize="16px"
          fontWeight={600}
        >
          Create your own course
        </Typography>
        <Typography
          color={getDynamicColor("purple3")}
          fontFamily="poppins"
          fontSize="16px"
          fontWeight={500}
        >
          Upload your own content, directly accessible to every member in your Group
        </Typography>
      </Stack>
      <Button
        onClick={() => setModalStatus(HubModalStatus.CREATE_COURSE)}
        startIcon={<AddCircleOutlineIcon sx={{ width: "20px", height: "20px" }} />}
        variant="contained"
        sx={{
          py: 2,
          px: 3.5,
          width: "max-content",
          borderRadius: "100px",
          fontFamily: "poppins",
          fontSize: "16px",
          fontWeight: 700,
        }}
      >
        Create your first course
      </Button>
    </Stack>
  ) : (
    <Typography px={2}>No courses found</Typography>
  );
}
