// Components
import { Box } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

export const TrialEndedBanner = (): JSX.Element => {
  return (
    <Box
      sx={{
        background: getDynamicColor("redError"),
        fontFamily: "poppins",
        fontSize: "12px",
        fontWeight: 600,
        color: getDynamicColor("light1"),
        width: 117,
        height: 25,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Free trial ended
    </Box>
  );
};
