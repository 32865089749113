import firebase from "firebase/app";
import React from "react";

// Components
import { EmojiEventsOutlined as TrophyIcon } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type HighScoreTooltipProps = {
  score: number;
  game: string;
};

const HighScoreTooltip = ({ score, game }: HighScoreTooltipProps): JSX.Element => {
  const user = firebase.auth().currentUser;
  return (
    <div
      style={{
        width: "100%",
        height: "30px",
        display: "flex",
      }}
    >
      {user && (
        <React.Fragment>
          {score ? (
            <Tooltip
              title={`Your highest score on ${game}. Keep practicing to improve your off the cuff speaking (and your score too)!`}
              placement="top-end"
            >
              <div style={{ display: "flex", marginLeft: "auto" }}>
                <TrophyIcon style={{ width: "30px", height: "100%" }} />
                <Typography variant="h6" sx={{ marginLeft: "5px" }}>
                  {score}
                </Typography>
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={`No high score yet`} placement="top-end">
              <div style={{ display: "flex", marginLeft: "auto" }}>
                <TrophyIcon
                  style={{
                    width: "30px",
                    height: "100%",
                    color: getDynamicColor("dark4"),
                  }}
                />
              </div>
            </Tooltip>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default HighScoreTooltip;
