import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Components
import { DragIndicator as DragIndicatorIcon, Close as CloseIcon } from "@mui/icons-material";
import { Stack, Button, Divider, Box, Typography, IconButton } from "@mui/material";

// Utils
import { CARD_STYLES, ROW_STYLES } from "./styles";
import { getDynamicColor } from "lib-frontend/utils/Colors";

type MyQuestionsProps = {
  interviewQs: string[];
  maxInterviewQs: number;
  handleClearAllQuestions: () => void;
  handleQuestionDrag: (result) => void;
  onDelete: (index: number) => void;
};

export function MyQuestions({
  interviewQs,
  maxInterviewQs,
  handleClearAllQuestions,
  handleQuestionDrag,
  onDelete,
}: MyQuestionsProps): JSX.Element {
  return (
    <Stack sx={{ ...CARD_STYLES, flexGrow: 1 }} gap={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          component="label"
          className="section-headline"
          sx={{
            fontFamily: "poppins",
            fontWeight: 500,
            color: getDynamicColor("purple3"),
            fontSize: { xs: 14, md: 16 },
          }}
        >
          My Questions ({`${interviewQs.length} / ${maxInterviewQs}`})
        </Typography>
        <Button
          aria-label="Clear all questions"
          onClick={handleClearAllQuestions}
          sx={{
            fontWeight: 500,
            fontSize: 12,
            backgroundColor: "none",
            color: getDynamicColor("dark4"),
          }}
        >
          Clear All
        </Button>
      </Stack>
      <Divider sx={{ width: "100%" }} />
      <Box
        sx={{
          overflowY: "auto",
          display: "flex",
          flexGrow: 1,
          pb: 2,
          width: "100%",
          "> div": {
            width: "100%",
          },
        }}
      >
        <DragDropContext onDragEnd={handleQuestionDrag}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Stack {...provided.droppableProps} ref={provided.innerRef} direction="column">
                {interviewQs.map((interviewOption, index) => {
                  return (
                    <Draggable
                      key={index}
                      draggableId={index.toString()} // According to docs, this needs to be a string
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...ROW_STYLES,
                            // use mb not gap here so the placeholder while dragging renders correctly
                            mb: 1,
                          }}
                        >
                          {provided.placeholder}
                          <DragIndicatorIcon style={{ cursor: "grab" }} />
                          <Typography
                            sx={{
                              fontSize: { xs: 14, md: 16 },
                              textAlign: "left",
                            }}
                          >
                            {interviewOption}
                          </Typography>
                          <IconButton onClick={() => onDelete(index)} size="medium">
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Draggable>
                  );
                })}

                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Stack>
  );
}
