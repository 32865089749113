import React from "react";
// Components
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Stack,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import parse from "html-react-parser";
// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { GetScenarioLimitedResponse } from "lib-fullstack/api/hubApiTypes";
import { PersonaMemberViewResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ScenarioTemplateSubType } from "lib-fullstack/db";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { TemplateSubTypeChip } from "components/ConvoScenarios/TemplateSubTypeChip";

type CustomScenarioCardProps = {
  scenario: GetScenarioLimitedResponse;
  selectedOrgId: string;
  persona?: PersonaMemberViewResponse;
  personaLoading?: boolean;
};

export const ReadOnlyScenarioCard = ({
  scenario,
  persona,
  personaLoading,
}: CustomScenarioCardProps): JSX.Element => {
  const [descriptionOpen, setDescriptionOpen] = React.useState(false);

  const renderPersona = () => {
    if (personaLoading) {
      return <CircularProgress size={16} />;
    } else if (persona) {
      return (
        <Stack direction="row" gap={0.5} alignItems="center">
          <Avatar
            alt={persona?.name}
            src={persona?.profile_picture_signed_url}
            key={persona?.profile_picture_signed_url}
            sx={{
              height: 20,
              width: 20,
            }}
          />
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 500,
              fontFamily: "poppins",
            }}
          >
            {persona?.name}
          </Typography>
        </Stack>
      );
    }
    return null;
  };

  const renderDescriptionText = () => {
    return (
      <Stack
        direction="row"
        gap={1}
        alignItems="flex-end"
        sx={{
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 1.4,
        }}
      >
        <Box
          sx={{
            "> *": {
              margin: "0px !important",
              width: "fit-content",
              wordBreak: { xs: "break-word", md: "unset" },
            },
          }}
        >
          {parse(scenario.description)}
        </Box>
      </Stack>
    );
  };

  return (
    <Stack
      alignItems="flex-start"
      justifyContent="space-between"
      direction={{ xs: "column", sm: "row" }}
      gap={1}
      sx={{
        fontFamily: "poppins",
        p: { xs: 1.5, md: 2.5 },
        border: `1px solid ${getDynamicColor("dark3")}`,
        borderRadius: "8px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={1}
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <Stack
          gap={1}
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            width: "calc(100% - 36px)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
              maxWidth: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                lineHeight: 1.3,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              {scenario.title}
            </Typography>
          </Stack>
          {scenario.description?.length > 160 ? (
            <Accordion
              sx={{
                boxShadow: "none",
                fontSize: 12,
                my: "0px !important",
                color: getDynamicColor("purple3"),
                "::before": {
                  display: "none !important",
                  position: "relative",
                },
              }}
              onChange={(_, expanded) => {
                setDescriptionOpen(expanded);
              }}
            >
              <AccordionSummary
                sx={{
                  my: 0,
                  px: 0,
                  width: "fit-content",
                  minHeight: "unset !important",
                  ".MuiAccordionSummary-content, .Mui-expanded": {
                    my: "0px !important",
                    mr: 0.5,
                  },
                  "&::before": {
                    display: "none",
                  },
                }}
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: getDynamicColor("primary"),
                      height: 20,
                      width: 20,
                    }}
                  />
                }
              >
                {descriptionOpen ? "Hide" : "Show"} description
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: 1.4,
                  pb: "8px !important",
                }}
              >
                {renderDescriptionText()}
              </AccordionDetails>
            </Accordion>
          ) : scenario.description?.length > 0 ? (
            renderDescriptionText()
          ) : null}
          <Stack
            direction="row"
            columnGap={2}
            rowGap={1}
            alignItems="center"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <TemplateSubTypeChip
              templateSubType={scenario.templateSubType as ScenarioTemplateSubType}
            />
            <Typography
              sx={{
                fontSize: 12,
                color: getDynamicColor("dark4"),
              }}
            >
              Created by: {scenario.createdByEmail} ({getHumanReadableDate(scenario.createdAt)})
            </Typography>
            {renderPersona()}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
