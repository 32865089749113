import firebase from "firebase/app";
import { db } from "lib-fullstack";

// Utils
import { getSiteId } from "./LiveSiteDocs";
import { getLiveUserDocMain, updateThisUserDocMain } from "./LiveUserDocs";
import {
  DashboardQueryParams,
  PoodliToggleViews,
  SpeechSummaryQueryParams,
} from "lib-fullstack/utils/queryParams";
import { launchPoodliToSettingsOpen, launchPoodliToSpecificTab } from "./Utilities";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { LifecycleState, ProductTip, TipCategory, TipGroup } from "lib-fullstack/db";
import { SAMPLE_SPEECH_URL } from "lib-fullstack/utils/constants";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { WebServerInternalPath } from "webclient/src/utils/paths";

type TipInteraction = { tip: db.ProductTip; interactionTimestamp?: string };

export type ProductTipsDoc = {
  currentWebclientTipGroup?: db.TipGroup;
  currentPoodliTipGroup?: db.TipGroup;
  nextCycle?: string;
  tipsInteracted?: TipInteraction[];
};

export type TipGroupObj = {
  id: TipGroup;
  category: TipCategory;
  tips: ProductTip[];
};

// Note: The order of these groups is the priority/order that will be shown to the user over time!
// Poodli tips are placed after the FPR and COACH tips so that a FPR user who PDLs won't get them until after all FPR tip groups.
export const tipGroups: TipGroupObj[] = [
  {
    id: TipGroup.FPR_CUSTOMIZATION,
    category: TipCategory.FPR,
    tips: [
      ProductTip.CUSTOMIZE_DASHBOARD_ANALYTICS,
      ProductTip.ADD_CONCISENESS_TO_DASHBOARD,
      ProductTip.CONCISENESS_MAGIC_WAND,
    ],
  },
  {
    id: TipGroup.FPR_REVIEW,
    category: TipCategory.FPR,
    tips: [
      ProductTip.COACHING_KEY_POINTS,
      ProductTip.WEEKLY_REVIEW_EMAIL,
      ProductTip.REVIEW_SENTENCE_STARTERS,
    ],
  },
  {
    id: TipGroup.FPR_COMMUNITY,
    category: TipCategory.FPR,
    tips: [ProductTip.JOIN_COMMUNITY, ProductTip.FPR_WARM_UP, ProductTip.CUSTOMIZE_FPR_PERSONA],
  },
  {
    id: TipGroup.FPR_SPEECHES,
    category: TipCategory.FPR,
    tips: [ProductTip.TRY_FPR_CONVERSATION, ProductTip.UPLOAD_SPEECH],
  },
  {
    id: TipGroup.FPR_DASHBOARD_TARGETS,
    category: TipCategory.FPR,
    tips: [
      ProductTip.DASHBOARD_FILLER_TARGET,
      ProductTip.DASHBOARD_PACING_TARGET,
      ProductTip.SHARE_WITH_COACH,
    ],
  },
  {
    id: TipGroup.POODLI_SETUP,
    category: TipCategory.POODLI,
    tips: [
      ProductTip.CAL_CONNECT,
      ProductTip.CUSTOMIZE_LIVE_ANALYTICS,
      ProductTip.HIDE_POODLI_SCREENSHARE,
    ],
  },
  {
    id: TipGroup.POODLI_CUSTOMIZATION,
    category: TipCategory.POODLI,
    tips: [
      ProductTip.ADD_CONCISENESS_TO_DASHBOARD,
      ProductTip.CONCISENESS_MAGIC_WAND,
      ProductTip.WEEKLY_REVIEW_EMAIL,
    ],
  },
  {
    id: TipGroup.POODLI_COMMUNITY,
    category: TipCategory.POODLI,
    tips: [
      ProductTip.REVIEW_SENTENCE_STARTERS,
      ProductTip.JOIN_COMMUNITY,
      ProductTip.TRY_FPR_CONVERSATION,
    ],
  },
  {
    id: TipGroup.POODLI_ADVANCED_FEATURES,
    category: TipCategory.POODLI,
    tips: [
      ProductTip.POODLI_START_MODE_SELECTOR,
      ProductTip.POODLI_TALKING_POINTS,
      ProductTip.SHARE_REPORT_WITH_COACH,
    ],
  },
  {
    id: TipGroup.POODLI_DASHBOARD_TARGETS,
    category: TipCategory.POODLI,
    tips: [
      ProductTip.DASHBOARD_FILLER_TARGET,
      ProductTip.DASHBOARD_PACING_TARGET,
      ProductTip.DASHBOARD_TALK_TIME_TARGET,
    ],
  },
];

export const determineNextTipGroup = (
  currentTipGroupId: TipGroup,
  availableCategories: TipCategory[],
  tipsInteracted: TipInteraction[]
): TipGroup => {
  const getFirstTipGroup = () => {
    return tipGroups.find((tipGroup) => availableCategories.includes(tipGroup.category));
  };

  const allTipsInGroupInteractedWith = (tipGroup: TipGroupObj) => {
    return tipGroup.tips.every((tip) =>
      tipsInteracted.find((tipInteracted) => tipInteracted.tip === tip)
    );
  };

  if (!currentTipGroupId) {
    return getFirstTipGroup().id;
  }

  const currentTipGroupIdx = tipGroups.findIndex((tipGroup) => tipGroup.id === currentTipGroupId);

  // Find index of next tipGroup after currentTipGroupIdx where:
  // - the category matches the user's answer to OBQ1:
  // - the group has a remaining tip that the user hasn't interacted with
  let nextTipGroupIdx = tipGroups.findIndex(
    (tipGroup) =>
      availableCategories.includes(tipGroup.category) &&
      !allTipsInGroupInteractedWith(tipGroup) &&
      tipGroups.indexOf(tipGroup) > currentTipGroupIdx
  );
  if (nextTipGroupIdx !== -1) {
    return tipGroups[nextTipGroupIdx].id;
  }

  // Check to see if there are any un-interacted tip groups for the category before currentTipGroupIdx:
  nextTipGroupIdx = tipGroups.findIndex(
    (tipGroup) =>
      availableCategories.includes(tipGroup.category) &&
      !allTipsInGroupInteractedWith(tipGroup) &&
      tipGroups.indexOf(tipGroup) < currentTipGroupIdx
  );
  if (nextTipGroupIdx !== -1) {
    return tipGroups[nextTipGroupIdx].id;
  }

  // Return the next tipGroup for the category (ignore interactions):
  nextTipGroupIdx = tipGroups.findIndex(
    (tipGroup) =>
      availableCategories.includes(tipGroup.category) &&
      tipGroups.indexOf(tipGroup) > currentTipGroupIdx
  );
  if (nextTipGroupIdx !== -1) {
    return tipGroups[nextTipGroupIdx].id;
  }

  // If no tipGroup found, cycle back to the first tip group for that category.
  return getFirstTipGroup().id;
};

export const setNewTipForWebclientUser = (
  currentWebclientTipGroupId: TipGroup,
  currentPoodliTipGroupId: TipGroup,
  tipsInteracted: TipInteraction[]
): void => {
  const sevenDaysFromNow = new Date();
  sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

  // All users get FPR tips, + Poodli tips if they PDL:
  const categories = [TipCategory.FPR, ...(currentPoodliTipGroupId ? [TipCategory.POODLI] : [])];

  const nextTipGroup = determineNextTipGroup(
    currentWebclientTipGroupId,
    categories,
    tipsInteracted
  );

  updateThisUserDocMain({
    productTips: {
      currentWebclientTipGroup: nextTipGroup,
      nextCycle: sevenDaysFromNow.toISOString(),
      tipsInteracted: tipsInteracted,
    },
  }).catch((err) =>
    console.error(`Error setting currentWebclientTipGroup productTips in userDocMain: ${err}`)
  );
};

const openMostRecentYoodli = async (queyParamStr?: string) => {
  // Open a new tab immediately, otherwise safari will block it as a popup.
  const newTab = window.open();

  const latestYoodlis = await db.query<db.Speech>(
    db.userSpeeches([getSiteId(), firebase.auth().currentUser.uid]),
    [db.where("lifecycleState", "==", LifecycleState.CREATED), db.limit(1)]
  );

  // If the user does not have a yoodli, open a sample yoodli.
  if (!latestYoodlis || latestYoodlis.length === 0) {
    newTab.location.href = `${SAMPLE_SPEECH_URL}${queyParamStr ? queyParamStr : ""}`;
  } else {
    const latestYoodliSlug = latestYoodlis[0].data.slug;
    newTab.location.href = `/share/${latestYoodliSlug}${queyParamStr ? queyParamStr : ""}`;
  }
};

type TipContent = {
  header: string;
  body: string;
  buttonText?: string;
  onButtonClick?: () => void;
  buttonOpensPoodli?: boolean;
  gifUrl: string;
};
type TipToContentMap = {
  [key in ProductTip]: TipContent;
};

export const tipToContentMap: TipToContentMap = {
  [ProductTip.CUSTOMIZE_DASHBOARD_ANALYTICS]: {
    header: "Customize dashboard based on your needs",
    body: "Select the analytics you care about. You'll see trends, progress, and suggestions on how to improve and maintain your performance.",
    buttonText: "Customize dashboard",
    onButtonClick: () =>
      open(
        `${WebServerExternalPath.DASHBOARD}?${DashboardQueryParams.EDIT_FOCUS_ANALYTICS}=true`,
        "_blank"
      ),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/conciseness_analytic_dashboard_720.mp4",
  },
  [ProductTip.ADD_CONCISENESS_TO_DASHBOARD]: {
    header: "Analytic Deep Dive: Conciseness",
    body: "Review your conciseness metric for each practice session. Add the analytic to your Dashboard to track improvement.",
    buttonText: "Learn more",
    onButtonClick: () => open("https://www.youtube.com/watch?v=Ym3EJHXvq1o", "_blank"),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/conciseness_analytic_dashboard_720.mp4",
  },
  [ProductTip.CONCISENESS_MAGIC_WAND]: {
    header: "Rewrite or paraphrase your speech/interview with AI",
    body: "Find the Yoodli magic wand to rephrase, simplify, or rewrite your stories as limericks!",
    buttonText: "Try rephrasing",
    onButtonClick: () =>
      openMostRecentYoodli(`?${SpeechSummaryQueryParams.FOCUS_ANALYTIC}=conciseness`),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/speech_magic_wand_conciseness_720.mp4",
  },
  [ProductTip.COACHING_KEY_POINTS]: {
    header: "Coaching Deep Dive: Key Points",
    body: "Yoodli Coach's key points analysis ensures your message resonates with your audience or interviewer.",
    buttonText: "Review key points",
    onButtonClick: () => openMostRecentYoodli(`?${SpeechSummaryQueryParams.FOCUS_COMMENT}=fuq`),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/coaching_key_points_720.mp4",
  },
  [ProductTip.WEEKLY_REVIEW_EMAIL]: {
    header: "Weekly Summary Emails",
    body: "Every Friday, Yoodli will send you a summary of how you spoke during the week (e.g. this week you rambled more than your typical average!).",
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/weekly_review_email_720.mp4",
  },
  [ProductTip.REVIEW_SENTENCE_STARTERS]: {
    header: "Analytic Deep Dive: Sentence Starters",
    body: "Starting your sentences with the same word over and over again typically lacks structure/transition in your message.",
    buttonText: "Learn more",
    onButtonClick: () => open("https://www.youtube.com/watch?v=WV5ml6yTH58", "_blank"),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/sentence_starter_analytic_720.mp4",
  },
  [ProductTip.JOIN_COMMUNITY]: {
    header: "Join Our Free Community",
    body: "Meet thousands of Yoodlers who share how they use Yoodli to improve their communication skills. You can also get help from a Yoodli coach or ambassador.",
    buttonText: "Join now",
    onButtonClick: () => open("https://www.yoodli.ai/community", "_blank"),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/yoodli_slack_community_720.mp4",
  },
  [ProductTip.FPR_WARM_UP]: {
    header: "Warm up with an AI-powered improv drill",
    body: "Warm up for your big presentation/interview with a fun speaking drill. Yoodli will give you a random prompt. Score points by speaking without filler words!",
    buttonText: "Warm up",
    onButtonClick: () => open(WebServerInternalPath.EXERCISES_SPIN_A_YARN, "_blank"),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/spin_a_yarn_tip_720.mp4",
  },
  [ProductTip.CUSTOMIZE_FPR_PERSONA]: {
    header: "Practice with a custom audience/interviewer persona",
    body: "Be prepared for anything - peers, business, technical, and more",
    buttonText: "Practice",
    onButtonClick: () => open(WebServerExternalPath.PRACTICE_SPEECH, "_blank"),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/customize_interviewer_persona_720.mp4",
  },
  [ProductTip.TRY_FPR_CONVERSATION]: {
    header: "Practice a sales pitch, tough manager chat, and more",
    body: "Flex your conversational muscle - practice any topic you'd like and get AI powered follow up responses",
    buttonText: "Practice",
    onButtonClick: () => open(WebServerExternalPath.PRACTICE_CONVERSATION, "_blank"),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/fpr_conversation_720.mp4",
  },
  [ProductTip.UPLOAD_SPEECH]: {
    header: "Upload a speech to see your analysis",
    body: "See the magic of Yoodli's AI coaching feedback",
    buttonText: "Upload",
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/upload_speech_720.mp4",
  },
  [ProductTip.DASHBOARD_FILLER_TARGET]: {
    header: "Analytic Deep Dive: Filler Words",
    body: "Aim to have fewer than 3% filler words",
    buttonText: "Learn more",
    onButtonClick: () =>
      open(
        `${getClientEnvConfig().url.LANDING_PAGE}/blog/yoodli-skill-reduce-um-filler-words`,
        "_blank"
      ),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/filler_word_analytic_dashboard_720.mp4",
  },
  [ProductTip.DASHBOARD_PACING_TARGET]: {
    header: "Analytic Deep Dive: Pacing",
    body: "Aim to have average conversational pace between 115 - 180 words/minute",
    buttonText: "Learn more",
    onButtonClick: () =>
      open(`${getClientEnvConfig().url.LANDING_PAGE}/blog/yoodli-weekly-tip-slow-down`, "_blank"),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/pacing_analytic_dashboard_720.mp4",
  },
  [ProductTip.SHARE_WITH_COACH]: {
    header: "Share your latest practice session with a coach",
    body: "Get timestamped feedback from coaches and peers you trust. It's the #1 way to improve!",
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/speech_share_coach_720.mp4",
  },
  [ProductTip.CAL_CONNECT]: {
    header: "Meeting mode: Customize the meetings you want live training on",
    body: "Yoodli is set to provide feedback on meetings where you're the host. Customize which meetings to get coached on - calendar must be connected.",
    buttonText: "Customize meetings",
    onButtonClick: () => launchPoodliToSpecificTab(PoodliToggleViews.CALENDAR),
    buttonOpensPoodli: true,
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/customize_meetings_720.mp4",
  },
  [ProductTip.CUSTOMIZE_LIVE_ANALYTICS]: {
    header: "Meeting mode: Personalize communication coaching to your needs",
    body: "You can choose with analytics you want live coaching on during your meetings - available via the desktop app.",
    buttonText: "Personalize coaching",
    onButtonClick: () => launchPoodliToSpecificTab(PoodliToggleViews.COACHING),
    buttonOpensPoodli: true,
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/poodli_customizable_analytics_720.mp4",
  },
  [ProductTip.HIDE_POODLI_SCREENSHARE]: {
    header: "Meeting mode: Hide Yoodli from screenshare",
    body: "If you're in a meeting and share your screen, your colleague will not be able to see that you're using Yoodli.",
    buttonText: "Open meeting mode settings",
    onButtonClick: () => launchPoodliToSettingsOpen(),
    buttonOpensPoodli: true,
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/poodli_hide_from_screenshare_720.mp4",
  },
  [ProductTip.POODLI_START_MODE_SELECTOR]: {
    header: "Practice a speech, interview, or conversation",
    body: "Use the Start button selector to dive into practice mode",
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/poodli_start_mode_selector_720.mp4",
  },
  [ProductTip.POODLI_TALKING_POINTS]: {
    header: "Talking Points: Smart teleprompter",
    body: "Yoodli can give you real-time nudges to hit your key talking points on a meeting.",
    buttonText: "Set up talking points",
    onButtonClick: () => launchPoodliToSpecificTab(PoodliToggleViews.TALKING_POINTS),
    buttonOpensPoodli: true,
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/poodli_talking_points_720.mp4",
  },
  [ProductTip.SHARE_REPORT_WITH_COACH]: {
    header: "Share your latest meeting report with a coach",
    body: "Get timestamped feedback from coaches and peers you trust. It's the #1 way to improve!",
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/poodli_share_with_coach_720.mp4",
  },
  [ProductTip.DASHBOARD_TALK_TIME_TARGET]: {
    header: "Analytic Deep Dive: Talk Time",
    body: "The best leaders say less, but with impact. Try to keep your talk time less than 50%",
    buttonText: "Learn more",
    onButtonClick: () =>
      open(
        `${getClientEnvConfig().url.LANDING_PAGE}/blog/yoodli-weekly-tip-say-more-with-less`,
        "_blank"
      ),
    gifUrl:
      "https://storage.googleapis.com/yoodli-public/media-assets/emails/talk_time_analytic_dashboard_720.mp4",
  },
};

export const markTipInteracted = (tip: ProductTip): void => {
  const currProductTips = getLiveUserDocMain().productTips;
  if (
    currProductTips.tipsInteracted.findIndex((tipInteracted) => tipInteracted.tip === tip) === -1
  ) {
    currProductTips.tipsInteracted.push({
      tip: tip,
      interactionTimestamp: new Date().toISOString(),
    });
    updateThisUserDocMain({
      productTips: currProductTips,
    }).catch((err) => console.error(`Error updating user doc: ${err}`));
  }
};
