// Components
import { grey as muiGrey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import globalTheme from "lib-frontend/ui/Theme";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

// View the default MUI theme here https://mui.com/customization/default-theme/#main-content
// to see what values are available to change and what their default values are
declare module "@mui/material/styles" {
  interface ThemeOptions {
    overrides?: {
      MuiButton?: Record<string, unknown>;
      MuiTypography?: Record<string, unknown>;
      MuiAppBar?: Record<string, unknown>;
    };
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    sm2: true;
    md: true;
    md2: true;
    md3: true;
    lg: true;
    lg2: true;
    xl: true;
    xxl: true;
  }
}

const theme = createTheme(globalTheme, {
  typography: {
    fontFamily: `"Lato", sans-serif`,
  },
  palette: {
    primary: {
      main: getDynamicColor("secondary"),
    },
    secondary: {
      main: getDynamicColor("secondary"),
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      sm2: 640,
      md: 768,
      md2: 850,
      md3: 920,
      lg: 992,
      lg2: 1024,
      xl: 1200,
      xxl: 1400,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
          boxShadow: "none",
        },
        gradient: {
          background: "orange",
        },
        containedSecondary: {
          color: muiGrey[50],
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "inherit",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: `0px 0.5px 0px ${getDynamicColor("dark3")}`,
          backgroundColor: "white",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
