/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

// Components
import { useMediaQuery, useTheme, Breakpoint } from "@mui/material";

export const useIsBreakpointScreen = (breakpoint: Breakpoint): boolean => {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up(breakpoint));
};

export function useIsLargeScreen(): boolean {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up("xl"));
}

export function useIsMediumScreen(): boolean {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up("lg"));
}

export function useIsSmallScreen(): boolean {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up("md"));
}

export function useIsExtraSmallScreen(): boolean {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up("sm"));
}

export function useIsScreenSmallerThanPx(px: number): boolean {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up(px));
}

export function useIsBraveBrowser(): boolean {
  const [isBrave, setIsBrave] = useState(false);

  useEffect(() => {
    const checkBrave = async () => {
      if ((navigator as any).brave && (navigator as any).brave.isBrave) {
        const brave = await (navigator as any).brave.isBrave();
        setIsBrave(brave);
      }
    };

    checkBrave().catch(() => {
      setIsBrave(false);
    });
  }, []);

  return isBrave;
}
