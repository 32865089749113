import React from "react";

// Components
import { Chip, SxProps } from "@mui/material";

// Utils
import { TEMPLATE_SUB_TYPE_DATA } from "./convoScenarioUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ScenarioTemplateSubType } from "lib-fullstack/db";

export const TemplateSubTypeChip = ({
  templateSubType,
  sx,
}: {
  templateSubType: ScenarioTemplateSubType;
  sx?: SxProps;
}): JSX.Element => {
  return (
    <Chip
      label={
        TEMPLATE_SUB_TYPE_DATA[templateSubType]?.label.toLowerCase().replace("call", "").trim() ??
        "Scenario"
      }
      sx={{
        textTransform: "uppercase",
        width: "fit-content",
        backgroundColor:
          TEMPLATE_SUB_TYPE_DATA[templateSubType]?.color ?? getDynamicColor("focusArea.presence"),
        fontSize: 10,
        height: 24,
        letterSpacing: "1px",
        ...sx,
      }}
    />
  );
};
