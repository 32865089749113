import React from "react";
import { isMobile } from "react-device-detect";

// Components
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Typography, Box, Stack } from "@mui/material";

// Utils
import { DRAWER_MENU_TRANSITION_DURATION } from "lib-frontend/hooks/useNavDrawerOffset";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { PricingAnalyticEvents } from "lib-fullstack/utils/productAnalyticEvents";

type PricingUsageNavPromoCodeProps = {
  open: boolean;
  isMobileDrawer?: boolean;
  promoCode: string;
  thresholdAlmostHit: boolean;
};

export const PricingUsageNavPromoCode = ({
  open,
  isMobileDrawer,
  promoCode,
  thresholdAlmostHit,
}: PricingUsageNavPromoCodeProps): JSX.Element => {
  const [codeCopied, setCodeCopied] = React.useState(false);
  const [hovering, setHovering] = React.useState(false);

  const copyCode = () => {
    setCodeCopied(true);
    copyToMyClipboard(promoCode);
    Instrumentation.logAmplitudeEvent(PricingAnalyticEvents.NAV_PRICING_PROMO_CODE_CLICKED);
    setTimeout(() => {
      setCodeCopied(false);
    }, 3000);
  };

  const backgroundColor = () => {
    if (hovering && !codeCopied) {
      return getDynamicColor("primaryHover");
    } else if (codeCopied) {
      return null;
    } else {
      return getDynamicColor("purple3Hover");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: open || isMobileDrawer ? 34 : 0,
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        backgroundColor: backgroundColor,
        alignItems: "center",
        textAlign: "center",
        transition: `${!isMobileDrawer && `height ${DRAWER_MENU_TRANSITION_DURATION} ease-out`}`,
      }}
      onMouseEnter={() => !isMobile && setHovering(true)}
      onMouseLeave={() => !isMobile && setHovering(false)}
    >
      {open && (
        <>
          {!hovering && !codeCopied && (
            <Typography
              sx={{
                color: getDynamicColor("light1"),
                fontSize: "12px",
                p: 1,
                cursor: "pointer",
                fontFamily: "poppins",
              }}
              onClick={copyCode}
            >
              {isMobile && isMobileDrawer ? "Copy promo code" : "Use promo"} <b>{promoCode}</b>
            </Typography>
          )}
          {hovering && !codeCopied && (
            <Typography
              sx={{
                textDecoration: "underline",
                color: getDynamicColor("light1"),
                fontSize: "12px",
                fontWeight: 700,
                p: 1,
                cursor: "pointer",
                fontFamily: "poppins",
              }}
              onClick={copyCode}
            >
              Copy code
            </Typography>
          )}
          {codeCopied && (
            <Stack
              direction="row"
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  color: thresholdAlmostHit
                    ? getDynamicColor("purple3")
                    : getDynamicColor("light1"),
                  svg: {
                    display: "block",
                  },
                }}
              >
                <CheckRoundedIcon sx={{ width: 18, height: 18 }} />
              </Box>
              <Typography
                sx={{
                  color: thresholdAlmostHit
                    ? getDynamicColor("purple3")
                    : getDynamicColor("light1"),
                  fontSize: "12px",
                  p: 1,
                  cursor: "default",
                  fontFamily: "poppins",
                }}
                onClick={copyCode}
              >
                Copied to clipboard
              </Typography>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};
