import parse, { Element } from "html-react-parser";
import React from "react";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { getLiveUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { PRICING_EXPERIMENT_CONFIG } from "lib-frontend/utils/PricingData";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { getIntFromString } from "lib-frontend/utils/Utilities";
import { PlanCheckoutResult } from "lib-fullstack/api/planApiTypes";
import { formatDate } from "lib-fullstack/utils/dateUtils";
import { PricingReimbursementLetterLinkLocation } from "lib-fullstack/utils/productAnalyticEvents";
import { handlePlanCheckout } from "utils/PricingUtils";
import { UsagePlanDuration, UsagePlanType } from "lib-fullstack/utils/pricingTypes";

type DowngradeModalContentProps = {
  usagePlanType: UsagePlanType;
  usagePlanDuration: UsagePlanDuration;
  usagePlanRenewalDate: string;
  downgradeSelection: UsagePlanType;
  setDowngradeSelection: (type: UsagePlanType) => void;
};

const CycleItem = ({
  header,
  duration,
  selected,
  setNewUsagePlanDuration,
  subHeader,
  footer,
}: {
  header: string;
  duration: UsagePlanDuration;
  selected: boolean;
  setNewUsagePlanDuration: (duration: UsagePlanDuration) => void;
  subHeader: string;
  footer?: string;
}): JSX.Element => {
  return (
    <Stack
      onClick={() => setNewUsagePlanDuration(duration)}
      alignItems="center"
      justifyContent="center"
      sx={{
        cursor: "pointer",
        width: { xs: 220, sm: "auto" },
        maxWidth: 212,
        minHeight: 100,
        flexBasis: "50%",
        flexGrow: 1,
        border: `1px solid ${getDynamicColor("dark2")}`,
        backgroundColor: getDynamicColor(selected ? "light2" : "light1"),
        boxShadow: Y_SHADOWS.box_shadow_1,
        py: 2,
        px: 5,
        borderRadius: "4px",
        transition: "background-color 0.2s ease-out",
        "&:hover": {
          backgroundColor: getDynamicColor("light2"),
        },
      }}
    >
      <Typography
        sx={{
          fontFamily: "poppins",
          fontWeight: 700,
          lineHeight: "normal",
          whiteSpace: "nowrap",
          color: getDynamicColor(selected ? "primary" : "purple3"),
        }}
      >
        {header}
      </Typography>
      <Typography
        sx={{
          fontWeight: 600,
          fontFamily: "poppins",
          lineHeight: "normal",
          color: getDynamicColor("dark4"),
          whiteSpace: "nowrap",
        }}
      >
        {subHeader}
      </Typography>
      {footer && (
        <Typography
          sx={{
            background: getDynamicColor("gradient.default"),
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: 800,
            fontFamily: "poppins",
            fontSize: 12,
            whiteSpace: "nowrap",
          }}
        >
          {footer}
        </Typography>
      )}
    </Stack>
  );
};

const DowngradeModalContent = ({
  usagePlanType,
  usagePlanDuration,
  usagePlanRenewalDate,
  downgradeSelection,
  setDowngradeSelection,
}: DowngradeModalContentProps): JSX.Element => {
  const userDocMain = getLiveUserDocMain();
  const [loadingCheckout, setLoadingCheckout] = React.useState(false);
  const [newUsagePlanDuration, setNewUsagePlanDuration] =
    React.useState<UsagePlanDuration>(usagePlanDuration);
  const [newPrice, setNewPrice] = React.useState<number>(null);
  const [currIdx, setCurrIdx] = React.useState<number>(0);
  const [loadingProratedRate, setLoadingProratedRate] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>(null);
  const data = React.useMemo(
    () =>
      PRICING_EXPERIMENT_CONFIG[userDocMain.pExpVersion].cards.find(
        (x) => x.type === usagePlanType
      ),
    [usagePlanType]
  );
  const downgradeData = React.useMemo(
    () =>
      PRICING_EXPERIMENT_CONFIG[userDocMain.pExpVersion].cards.find(
        (x) => x.type === downgradeSelection
      ),
    [downgradeSelection]
  );
  const multiStepDowngrade = downgradeSelection === UsagePlanType.PRO_V1;
  const renderModalContent = () => {
    switch (currIdx) {
      default:
      case 0:
        return (
          <Stack
            alignItems="center"
            gap={2}
            sx={{
              p: 4,
              pt: 5,
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "poppins",
                fontWeight: 700,
                fontSize: 16,
                mb: 1,
              }}
            >
              Are you sure you want to downgrade to {downgradeData.title}?
            </Typography>
            <Stack
              alignItems="center"
              gap={1.5}
              sx={{
                mx: 2,
                px: 2,
                py: 3,
                backgroundColor: getDynamicColor("light2"),
                borderRadius: "12px",
                width: "calc(100% - 32px)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  textAlign: "center",
                  mb: 1,
                }}
              >
                You'll be missing out on:
              </Typography>
              {data?.bulletPoints.map((bp) => (
                <Typography
                  key={bp.toString()}
                  sx={{
                    textDecoration: "line-through",
                    textAlign: "center",
                    fontSize: 14,
                  }}
                >
                  {parse(bp, {
                    replace(domNode: Element) {
                      if (domNode.name === "a") {
                        return (
                          <a
                            {...domNode.attribs}
                            onClick={() =>
                              Instrumentation.logPricingReimbursementLetterClicked(
                                PricingReimbursementLetterLinkLocation.WEBCLIENT_PRICING_MODAL
                              )
                            }
                          >
                            Reimbursable
                          </a>
                        );
                      }
                    },
                  })}
                </Typography>
              ))}
            </Stack>
          </Stack>
        );
      case 1:
        return (
          <Stack
            alignItems="center"
            gap={2}
            sx={{
              p: 4,
              pt: 5,
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "poppins",
                fontWeight: 700,
                fontSize: 16,
                mb: 1,
              }}
            >
              Select new billing cycle
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              gap={{ xs: 2, md: 5 }}
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <CycleItem
                duration={UsagePlanDuration.MONTHLY}
                selected={newUsagePlanDuration === UsagePlanDuration.MONTHLY}
                setNewUsagePlanDuration={setNewUsagePlanDuration}
                header="Pay monthly"
                subHeader={`${downgradeData?.cost?.monthly} / month`}
              />
              <CycleItem
                duration={UsagePlanDuration.YEARLY}
                selected={newUsagePlanDuration === UsagePlanDuration.YEARLY}
                setNewUsagePlanDuration={setNewUsagePlanDuration}
                header="Pay annually"
                subHeader={`$${getIntFromString(downgradeData?.cost?.annually) * 12} / year`}
                footer="Save up to 40%"
              />
            </Stack>
          </Stack>
        );
      case 2:
        return (
          <Stack
            alignItems="center"
            gap={3}
            sx={{
              width: "100%",
              p: 4,
              pb: 2,
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontWeight: 400,
              }}
            >
              Your plan will switch to <strong>{downgradeData.title}</strong> after your current
              plan ends on {formatDate(usagePlanRenewalDate, false)}
            </Typography>
            <Box
              sx={{
                fontFamily: "poppins",
                fontWeight: 400,
              }}
            >
              You will be charged{" "}
              {loadingProratedRate ? (
                <Box
                  sx={{
                    mx: 1,
                    display: "inline-block",
                  }}
                >
                  <CircularProgress size={16} />
                </Box>
              ) : (
                <strong>${newPrice}</strong>
              )}{" "}
              (including tax if applicable){" "}
              {newUsagePlanDuration === UsagePlanDuration.YEARLY ? "annually" : "monthly"} on your
              current payment method. You can change the payment any time from the settings page.
            </Box>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontWeight: 400,
              }}
            >
              Do you want to proceed?
            </Typography>
          </Stack>
        );
    }
  };

  const DOWNGRADE_ERROR_STRING = "Error downgrading plan — please try again";

  const handleCtaClick = async () => {
    // multi step downgrade i.e. advanced -> pro: this shows prorated rate and allows duration change
    if (multiStepDowngrade) {
      if (currIdx < 2) {
        setCurrIdx(currIdx + 1);
      }
      // this is the Duration slide (monthly vs annually)
      if (currIdx === 1) {
        try {
          setLoadingProratedRate(true);
          const res = await handlePlanCheckout(downgradeSelection, newUsagePlanDuration, false);
          setNewPrice(res?.amount / 100);
        } catch (err) {
          setError(err === PlanCheckoutResult.FAIL_SCHEDULE_EXIST ? err : DOWNGRADE_ERROR_STRING);
          console.error(err);
        } finally {
          setLoadingProratedRate(false);
        }
        // this is the slide that shows the prorated rate, i.e. the last "confirm change" slide
      } else if (currIdx === 2) {
        // if on last slide, ACTUALLY apply the plan checkout change
        try {
          setLoadingCheckout(true);
          await handlePlanCheckout(downgradeSelection, newUsagePlanDuration, true);

          setDowngradeSelection(null);
        } catch (err) {
          setError(err === PlanCheckoutResult.FAIL_SCHEDULE_EXIST ? err : DOWNGRADE_ERROR_STRING);
          console.error(err);
        } finally {
          setLoadingCheckout(false);
        }
      }
      // single step downgrade i.e. to pro/advanced -> free
    } else {
      try {
        setLoadingCheckout(true);
        await handlePlanCheckout(downgradeSelection, newUsagePlanDuration, true);
        setDowngradeSelection(null);
      } catch (err) {
        setError(DOWNGRADE_ERROR_STRING);
        console.error(DOWNGRADE_ERROR_STRING, err);
      } finally {
        setLoadingCheckout(false);
      }
    }
  };
  return (
    <Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          pointerEvents: loadingCheckout ? "auto" : "none",
          opacity: loadingCheckout ? 1 : 0,
          transition: "opacity 0.2s ease-out",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 2,
          backgroundColor: getDynamicColor("dark4", 0.5),
        }}
      >
        <CircularProgress size={60} />
      </Stack>
      <IconButton
        onClick={() => setDowngradeSelection(null)}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        sx={{
          width: "100%",
          p: 2.5,
          borderBottom: `1px solid ${getDynamicColor("dark3")}`,
          textAlign: "center",
          fontFamily: "poppins",
          fontWeight: 600,
          color: getDynamicColor(error ? "redError" : "purple3"),
        }}
      >
        {error ?? "Change your plan"}
      </Typography>
      {renderModalContent()}
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        gap={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "100%",
          p: 4,
        }}
      >
        <Button
          variant="text"
          onClick={() => setDowngradeSelection(null)}
          sx={{
            width: { xs: "100%", sm: "auto" },
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={loadingCheckout || loadingProratedRate}
          onClick={handleCtaClick}
          sx={{
            width: { xs: "100%", sm: "auto" },
            backgroundColor: getDynamicColor("redError"),
            minWidth: 200,
            "&:hover": {
              backgroundColor: getDynamicColor("redErrorDark"),
            },
          }}
        >
          {multiStepDowngrade
            ? `${currIdx === 2 ? "Confirm" : "Next"} (${currIdx + 1}/3)`
            : "Downgrade"}
        </Button>
      </Stack>
    </Stack>
  );
};

export default DowngradeModalContent;
