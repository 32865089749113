/**
 * Login screen for toastmasters SSO. Allows login via a token, or via our
 * usual methods for 'admin' users.
 */
import React from "react";
import firebase from "firebase/app";
import _ from "lodash";
import moment from "moment-timezone";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

// Components
import { Box, Button, Checkbox, FormControlLabel, Link, Stack, Typography } from "@mui/material";
import AuthInput from "./components/AuthInput";

// Utils
import { useValidateEmail, useValidateDisplayString } from "auth/utils/validator";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { ssoSigninWithJWT } from "lib-frontend/modules/AxiosInstance";
import { LOGIN_SESSION_ACTIVE } from "../utils/Constants";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { determineHomePagePath } from "../utils/Utilities";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { YOODLI_REFERRER } from "lib-fullstack/utils/constants";

const envConfig = getClientEnvConfig();

const JWT_QPARAM_ID = "token";
const TMI_LOGIN_URLS = {
  local: "https://dynamics-dev.toastmasters.org/myhome/welcome-to-yoodli",
  development: "https://dynamics-dev.toastmasters.org/myhome/welcome-to-yoodli",
  staging: "https://preview.toastmasters.org/myhome/welcome-to-yoodli",
  production: "https://toastmasters.org/myhome/welcome-to-yoodli",
};

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery(): URLSearchParams {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function TmiSsoDialog(): JSX.Element {
  console.log("TMI SSO");
  if (getSiteId() !== "toastmasters") {
    throw new Error("Tried to do SSO login on ineligible site.");
  }
  // If appropriate query param exists, try to sign in with it.

  const query = useQuery();
  const jwt = query.get(JWT_QPARAM_ID);
  if (!jwt) {
    console.log("No JWT.");
  }

  // 'True' if waiting for sign-in reponse from server.
  const [signinInProgress, setSigninInProgress] = React.useState(!!jwt);
  // If true, JWT indicated new user; show name/email/TOS UI.
  const [mustConfirmNewUser, setMustConfirmNewUser] = React.useState(false);
  // State for UI controls where users input info (shown when mustConfirmNewUser is true)
  const [name, setName] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [privacyAccepted, setPrivacyAccepted] = React.useState(false);
  const [error, setError] = React.useState<string>();
  // any validation errors
  const errors = {
    name: useValidateDisplayString(name),
    email: useValidateEmail(email),
    privacyAccepted: !privacyAccepted,
  };

  // UI form controller fxns
  const handleChangeName = (name) => {
    setName(name);
    setError("");
  };

  const handleChangeEmail = (email) => {
    setEmail(email);
    setError("");
  };

  const handlePrivacyChecked = (checked) => {
    setPrivacyAccepted(checked);
    setError("");
  };

  // Sign in to firebase auth with a firebase custom token,
  //  provided by backend api.
  async function signinWithCustomToken(tok: string) {
    console.log("TMI SSO: Going to sign in with custom token.");
    await firebase.auth().signInWithCustomToken(tok);
    console.log("TMI SSO: Signed in with custom token. Redirecting.");
    localStorage.setItem(LOGIN_SESSION_ACTIVE, "true");
    window.location.href = determineHomePagePath();
    console.log("Redirected.");
  }

  // Attempt to sign in with the JWT token and/or user info from the form.
  async function attemptSignIn(): Promise<void> {
    const clientEnv = getClientEnvConfig();
    const referralSlug = Cookies.get(YOODLI_REFERRER, { domain: clientEnv.domain }) ?? "";
    const ret = await ssoSigninWithJWT({
      jwt: jwt ?? undefined,
      createNewUser: mustConfirmNewUser,
      fullname: name,
      homeTimezone: moment.tz.guess(true),
      email,
      referralSlug,
    });
    if (referralSlug) {
      Cookies.remove(YOODLI_REFERRER, { domain: clientEnv.domain });
    }
    if (!ret) {
      console.error("No response from JWT sign-in");
    } else if (ret.success === true) {
      await signinWithCustomToken(ret.customSigninToken);

      if (mustConfirmNewUser) {
        Instrumentation.logUserSignUpCheckpoint();
      } else {
        Instrumentation.logUserSignInCheckpoint();
      }
    } else if (ret.success === false) {
      setError(ret.failureReason);
      if (ret.mustConfirmNewUser === true) {
        setMustConfirmNewUser(true);
        setName(name ?? ret.defaultFullname);
        setEmail(email ?? ret.defaultEmail);
        setPrivacyAccepted(false);
      } else {
        console.log("JWT sign-in failure: " + ret.failureReason);
      }
    }
    setSigninInProgress(ret.success);
  }

  React.useEffect(() => {
    if (jwt) {
      attemptSignIn().catch((e) => {
        console.error(e);
        setSigninInProgress(false);
      });
    }
  }, [jwt]);

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (_.every(errors, (error) => !error) && (name?.length ?? 0 > 0)) {
      try {
        await attemptSignIn();
      } finally {
        setSigninInProgress(false);
      }
    } else if (errors.privacyAccepted) {
      setError("Please consent to the above in order to continue!");
    } else if (name?.length === 0 && !errors.name) {
      setError("Name field is required");
    }
  };

  if (signinInProgress) {
    return (
      <>
        <h3>Signing in with your Toastmasters account...</h3>
      </>
    );
  } else {
    if (mustConfirmNewUser) {
      return (
        <>
          <h2>Initial Sign In</h2>
          <p>
            You are using this site for the first time. To continue, please confirm your email and
            name, and accept the privacy policy and terms of service.
          </p>
          <form onSubmit={handleSignUp}>
            <Stack gap={2} alignItems="center">
              <AuthInput
                fullWidth
                value={name}
                autoComplete="given-name"
                placeholder="Name"
                label={name ? "Name" : undefined}
                errorText={errors.name}
                type="text"
                onChange={(e) => handleChangeName(e.target.value)}
              />

              <AuthInput
                fullWidth
                autoComplete="email"
                value={email}
                placeholder="Email"
                label={email ? "Email" : undefined}
                type="email"
                errorText={errors.email}
                onChange={(e) => handleChangeEmail(e.target.value)}
              />
              <Stack width="100%" gap={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(_, checked) => handlePrivacyChecked(checked)}
                      value={privacyAccepted}
                    />
                  }
                  label={
                    <Typography fontSize="14px" textAlign="start">
                      I agree to Yoodli's{" "}
                      <a href="/privacy" target="_blank">
                        Privacy Policy
                      </a>
                      ,{" "}
                      <a href="/tos" target="_blank">
                        Terms of Use
                      </a>
                      , and{" "}
                      <a href="/copyright" target="_blank">
                        Copyright Policy
                      </a>
                    </Typography>
                  }
                />
                {error && (
                  <Box
                    p={2}
                    sx={{ backgroundColor: "rgba(244, 67, 54, 0.08)" }}
                    borderRadius="12px"
                  >
                    <Typography color="error" fontWeight={600} fontSize="16px">
                      {error}
                    </Typography>
                  </Box>
                )}
                <Button variant="contained" onClick={handleSignUp}>
                  Sign up
                </Button>
                <input type="submit" style={{ display: "none" }} />
              </Stack>
            </Stack>
          </form>
        </>
      );
    }
    if (mustConfirmNewUser) {
      return (
        <>
          <h2>Sign In</h2>
          <p>
            Please confirm your email and name, and accept the privacy policy and terms of service.
          </p>
          <form onSubmit={handleSignUp}>
            <Stack gap={2} alignItems="center">
              <AuthInput
                fullWidth
                value={name}
                autoComplete="given-name"
                placeholder="Name"
                label={name ? "Name" : undefined}
                errorText={errors.name}
                type="text"
                onChange={(e) => handleChangeName(e.target.value)}
              />

              <AuthInput
                fullWidth
                autoComplete="email"
                value={email}
                placeholder="Email"
                label={email ? "Email" : undefined}
                type="email"
                errorText={errors.email}
                onChange={(e) => handleChangeEmail(e.target.value)}
              />
              <Stack width="100%" gap={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(_, checked) => handlePrivacyChecked(checked)}
                      value={privacyAccepted}
                    />
                  }
                  label={
                    <Typography fontSize="14px" textAlign="start">
                      I agree to Yoodli's{" "}
                      <a href="/privacy" target="_blank">
                        Privacy Policy
                      </a>
                      ,{" "}
                      <a href="/tos" target="_blank">
                        Terms of Use
                      </a>
                      , and{" "}
                      <a href="/copyright" target="_blank">
                        Copyright Policy
                      </a>
                    </Typography>
                  }
                />
                {error && (
                  <Box
                    p={2}
                    sx={{ backgroundColor: "rgba(244, 67, 54, 0.08)" }}
                    borderRadius="12px"
                  >
                    <Typography color="error" fontWeight={600} fontSize="16px">
                      {error}
                    </Typography>
                  </Box>
                )}
                <Button variant="contained" onClick={handleSignUp}>
                  Sign up
                </Button>
                <input type="submit" style={{ display: "none" }} />
              </Stack>
            </Stack>
          </form>
        </>
      );
    }
    return (
      <>
        <h2>Toastmasters Yoodli Sign In</h2>
        <p>This site can only be accessed using your Toastmasters International account.</p>
        <p>
          Click <a href={TMI_LOGIN_URLS[envConfig.envName]}>here</a> to sign in through
          Toastmasters.
        </p>
      </>
    );
  }
}

export default function TmiSsoScreen(props: {
  adminComponent: React.ReactElement;
}): React.ReactElement {
  const navigate = useNavigate();

  const [useTmiSso, setUseTmiSso] = React.useState(true);

  const user = firebase.auth().currentUser;
  const homePagePath = determineHomePagePath();

  const query = useQuery();
  const jwt = query.get(JWT_QPARAM_ID);

  React.useEffect(() => {
    if (!jwt) {
      // if no token provided, emulate usual redirection behavior.
      if (user && user["uid"]) {
        console.log(`User is logged in, routing them to ${homePagePath}`);
        navigate(homePagePath);
      } else {
        localStorage.removeItem(LOGIN_SESSION_ACTIVE);
      }
    }
  }, []);

  if (useTmiSso) {
    // Primary sign-in route for TMI is via SSO.
    return (
      <Stack alignItems={"center"} justifyContent={"center"} padding={15}>
        <div>
          <TmiSsoDialog />
          <p />
          <hr />
          <p />
          <small>
            <i>
              <Link
                component="button"
                sx={{ fontSize: ".7rem" }}
                onClick={() => {
                  setUseTmiSso(false);
                }}
              >
                Admin Access
              </Link>
            </i>
          </small>
        </div>
      </Stack>
    );
  } else {
    // 'admins'. Use the normal login.
    return props.adminComponent;
  }
}
