// Components
import { Avatar, SxProps } from "@mui/material";

// Utils
import { getInitialsForName } from "lib-frontend/utils/AccountUtils";
import { stringToColor } from "lib-frontend/utils/Utilities";

type YoodliAvatarProps = {
  name: string;
  sx?: SxProps;
};

export const YoodliAvatar = ({ name, sx }: YoodliAvatarProps): JSX.Element => {
  return (
    <Avatar
      sx={{
        fontSize: "16px",
        fontWeight: 800,
        fontFamily: "poppins",
        opacity: 0.5,
        backgroundColor: stringToColor(name),
        ...sx,
      }}
    >
      {getInitialsForName(name)}
    </Avatar>
  );
};
