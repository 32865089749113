import React from "react";

// Components
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ControlButton from "lib-frontend/components/ControlButton";
import RecordingDurationWarning from "lib-frontend/components/RecordingDurationWarning";

// Utils
import RecordingStatusNotifier from "./RecordingStatusNotifier";
import TimerDisplay from "./TimerDisplay";
import { currentUserFirstName } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ReliableTimer } from "lib-frontend/utils/ReliableTimer";

export type RecordingInProgressProps = {
  handleStop: () => void;
  speechTimer: ReliableTimer;
  secondsElapsed: number;
};
const RecordingInProgress = ({
  handleStop,
  speechTimer,
  secondsElapsed,
}: RecordingInProgressProps): JSX.Element => {
  const [recordingStopped, setRecordingStopped] = React.useState(false);

  const firstName = currentUserFirstName();
  const recordingName = firstName ? `${firstName}'s Recording` : "Untitled Zoom Recording";

  React.useEffect(() => {
    if (!speechTimer.running) {
      speechTimer.start();
    }
  }, [speechTimer]);

  const handleStopRecording = async () => {
    try {
      setRecordingStopped(true);
      await handleStop();
    } catch (er) {
      setRecordingStopped(false);
      throw new Error("Unable to stop recording. Error:" + er);
    }
  };

  return (
    <Stack
      gap={1}
      alignItems="center"
      sx={{
        backgroundColor: "white",
        borderRadius: "12px",
        position: "relative",
        color: getDynamicColor("purple3"),
        width: "100%",
      }}
    >
      <Stack
        gap={1.5}
        alignItems="center"
        sx={{
          width: "min(100%, 420px)",
          mb: 2,
        }}
      >
        <Stack gap={5} direction="row" alignItems="center" justifyContent="center" width="100%">
          {/* Either render the recording notifier or an empty div of the same height to avoid content layout shift */}
          {!recordingStopped ? (
            <RecordingStatusNotifier />
          ) : (
            <Box
              component="span"
              sx={{
                height: { xs: 31, md: 34 },
              }}
            />
          )}
        </Stack>
        {recordingStopped ? (
          <Stack direction="row" alignItems="center" gap={3} height={108} pb={4}>
            <CircularProgress size={24} />
            <Typography fontSize="24px" fontWeight={700}>
              Saving recording...
            </Typography>
          </Stack>
        ) : (
          <>
            <Typography fontSize="24px" fontWeight={700} pt={1}>
              {recordingName}
            </Typography>
            <TimerDisplay timeS={secondsElapsed} />
          </>
        )}

        <RecordingDurationWarning />
      </Stack>
      {recordingStopped ? (
        <span style={{ height: 46 }} />
      ) : (
        <ControlButton onClick={handleStopRecording} status="stop">
          Stop Recording
        </ControlButton>
      )}
    </Stack>
  );
};

export default RecordingInProgress;
