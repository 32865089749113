// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { Box, Dialog, IconButton, Stack, Typography } from "@mui/material";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { tipToContentMap } from "lib-frontend/utils/productTipsUtils";
import { ProductTip } from "lib-fullstack/db";

type TipGifDialogProps = {
  tip: ProductTip;
  isOpen: boolean;
  onClose: () => void;
};

export const TipGifDialog = ({ tip, isOpen, onClose }: TipGifDialogProps): JSX.Element => {
  const { modalStyles, navDrawerOffset } = useNavDrawerOffset();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        cursor: "default",
      }}
      PaperProps={{
        sx: {
          ...modalStyles,
          position: "relative",
          width: `min(720px, 100%)`,
          maxWidth: {
            xs: "calc(100% - 32px) !important",
            md: `calc(100% - ${navDrawerOffset + 32}px) !important`,
          },
          borderRadius: "16px",
          boxShadow: Y_SHADOWS.box_shadow_7,
          background: getDynamicColor("light1"),
          p: 2,
          m: 2,
          zIndex: 12,
          overflow: "hidden",
        },
      }}
      scroll="body"
    >
      <Stack gap={2} sx={{ textAlign: "center", px: { xs: 1, md: 4 } }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 6,
            right: 9,
          }}
        >
          <CloseIcon sx={{ width: "16px", height: "16px" }} />
        </IconButton>
        <Typography sx={{ fontFamily: "Poppins", fontWeight: 600, mt: 1, fontSize: 15 }}>
          {tipToContentMap[tip].header}
        </Typography>
        <Box
          sx={{ minHeight: { xs: "160px", md: "340px" }, display: "flex", alignItems: "center" }}
        >
          {isOpen && (
            <video
              width="100%"
              height="auto"
              style={{
                borderRadius: "4px",
              }}
              autoPlay
              loop
              muted
            >
              <source src={tipToContentMap[tip].gifUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Box>
        <Typography sx={{ fontFamily: "Poppins", fontSize: 13 }}>
          {tipToContentMap[tip].body}
        </Typography>
      </Stack>
    </Dialog>
  );
};
