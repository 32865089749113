import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";
import React from "react";

// Components
import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import CreateOrgSectionSelect from "components/Admin/CreateOrgSectionSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";

// Utils
import { OrgDetailCardItemLabel } from "./AdminConfigOrgDetails";
import { UpdateOrgDetailCardProps } from "./UpdateOrgModal";
import {
  useValidatePositiveInt,
  useValidateDate,
  useValidateNumberInRangeInclusive,
} from "auth/utils/validator";
import { adminUpdateOrgData } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  AdminUpdateOrgRequest,
  AdminUpdateOrgSubscriptionRequestType,
} from "lib-fullstack/api/adminApiTypes";
import { OrgSubscriptionType } from "lib-fullstack/utils/enums";
import { IOSSwitch } from "components/Settings/Settings";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import moment from "moment";
import { DEFAULT_DAYS_SEAT_INACTIVE } from "lib-fullstack/utils/constants";

export const UpdateSubscriptionForm = ({
  orgId,
  card,
  setRerenderKey,
  close,
}: UpdateOrgDetailCardProps): React.ReactElement => {
  const inactiveSeatDays = card.items.find(
    (item) => item.label === OrgDetailCardItemLabel.INACTIVE_SEAT_DAYS
  ).data;
  // Hooks
  const [requestType, setRequestType] = React.useState<AdminUpdateOrgSubscriptionRequestType>(
    AdminUpdateOrgSubscriptionRequestType.UPDATE_LICENSE
  );
  const [licensedSeats, setLicensedSeats] = React.useState<string>(
    card.items.find((item) => item.label === OrgDetailCardItemLabel.SEATS_LICENSED).data?.toString()
  );
  const [licenseEndDate, setLicenseEndDate] = React.useState<Moment | null>(
    moment(
      card.items.find((item) => item.label === OrgDetailCardItemLabel.LICENSE_END_DATE)
        .data as string
    )
  );
  const [seatInactiveEnabled, setSeatInactiveEnabled] = React.useState<boolean>(
    Number(inactiveSeatDays) > 0
  );
  const [numDaysSeatInactive, setNumDaysSeatInactive] = React.useState<number>(
    Number(inactiveSeatDays) > 0 ? Number(inactiveSeatDays) : DEFAULT_DAYS_SEAT_INACTIVE
  );
  const [perSeatDollars, setPerSeatDollars] = React.useState<string>(
    (
      (card.items.find((item) => item.label === OrgDetailCardItemLabel.PER_SEAT_DOLLARS).data ??
        15.0) as number
    ).toFixed(2)
  );
  const [typeChangeWarningModalOpen, setTypeChangeWarningModalOpen] =
    React.useState<boolean>(false);

  const subscriptionType = card.items.find(
    (item) => item.label === OrgDetailCardItemLabel.SUBSCRIPTION_TYPE
  ).data as OrgSubscriptionType;

  const requestTypeToString = {};
  requestTypeToString[AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_OR_RECREATE_PAYG_FREE_TRIAL] =
    "Switch to or recreate PAYG free trial";
  requestTypeToString[AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_PREPAID] =
    "Switch to Prepaid";
  requestTypeToString[AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_FLEXIBLE] =
    "Switch to Flexible";
  requestTypeToString[AdminUpdateOrgSubscriptionRequestType.UPDATE_LICENSE] =
    "Update Prepaid / Flexible license";

  const [loadingText, setLoadingText] = React.useState<string>("");
  const [errorText, setErrorText] = React.useState<string>("");

  const errors = {
    licensedSeats: useValidatePositiveInt(licensedSeats),
    licenseEndDate: useValidateDate(licenseEndDate),
    // prettier-ignore
    perSeatDollars: useValidateNumberInRangeInclusive(perSeatDollars, 0.01, 20.00),
  };

  const isFormValid = () => {
    let validRequest = false;

    if (
      subscriptionType === OrgSubscriptionType.PAYG &&
      [
        AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_PREPAID,
        AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_FLEXIBLE,
        AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_OR_RECREATE_PAYG_FREE_TRIAL,
      ].includes(requestType)
    ) {
      validRequest = true;
    } else if (
      subscriptionType === OrgSubscriptionType.FLEXIBLE &&
      [
        AdminUpdateOrgSubscriptionRequestType.UPDATE_LICENSE,
        AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_PREPAID,
        AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_OR_RECREATE_PAYG_FREE_TRIAL,
      ].includes(requestType)
    ) {
      validRequest = true;
    } else if (
      subscriptionType === OrgSubscriptionType.PREPAID &&
      [
        AdminUpdateOrgSubscriptionRequestType.UPDATE_LICENSE,
        AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_FLEXIBLE,
        AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_OR_RECREATE_PAYG_FREE_TRIAL,
      ].includes(requestType)
    ) {
      validRequest = true;
    }

    return validRequest && ![...Object.values(errors)].some((error) => !!error);
  };

  const handleUpdateOrg = async (e) => {
    e.preventDefault();

    if (
      requestType === AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_PREPAID ||
      requestType === AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_FLEXIBLE ||
      (requestType ===
        AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_OR_RECREATE_PAYG_FREE_TRIAL &&
        subscriptionType !== OrgSubscriptionType.PAYG)
    ) {
      setTypeChangeWarningModalOpen(true);
    } else {
      await executeUpdateOrg();
    }
  };

  const executeUpdateOrg = async (): Promise<void> => {
    setErrorText("");
    try {
      setLoadingText(`Updating ${card.label}`);

      const params: AdminUpdateOrgRequest = {
        subscription: {
          requestType,
          seatsLicensed: parseInt(licensedSeats),
          licenseEndDate: licenseEndDate?.toISOString(),
          perSeatDollars: parseFloat(perSeatDollars),
          ...(numDaysSeatInactive > 0 && {
            numSeatInactiveDays: seatInactiveEnabled ? numDaysSeatInactive : 0,
          }),
        },
      };

      await adminUpdateOrgData(orgId, params);
      setRerenderKey((prevKey: number) => prevKey + 1);
      close();
    } catch (e) {
      console.log(`error updating ${card.label}: ${e}`);
      setErrorText(`Error Updating ${card.label}: ${e}`);
    }
    setLoadingText("");
  };

  const showPerSeatDollars =
    requestType === AdminUpdateOrgSubscriptionRequestType.SWITCH_TO_FLEXIBLE ||
    (requestType === AdminUpdateOrgSubscriptionRequestType.UPDATE_LICENSE &&
      subscriptionType === OrgSubscriptionType.FLEXIBLE);

  return (
    <>
      <Stack width="100%" gap={2} p={3}>
        {errorText && (
          <Typography p={1} color="error" fontWeight={600} fontSize="12px">
            {errorText}
          </Typography>
        )}
        <form style={{ width: "100%" }} onSubmit={async (e) => await handleUpdateOrg(e)}>
          <Stack gap={3}>
            <Stack>
              <InputLabel
                htmlFor="request-type-select"
                sx={{ pb: 0.5, ml: 2, fontFamily: "poppins", fontSize: "16px", fontWeight: 500 }}
              >
                Subscription change request type
              </InputLabel>
              <CreateOrgSectionSelect
                value={requestType}
                onChange={(e) => {
                  setRequestType(e.target.value as AdminUpdateOrgSubscriptionRequestType);
                }}
              >
                {Object.entries(AdminUpdateOrgSubscriptionRequestType).map(([key, type]) => {
                  return (
                    <MenuItem
                      key={key}
                      value={AdminUpdateOrgSubscriptionRequestType[key]}
                      sx={{
                        px: 3,
                        color: getDynamicColor("purple3"),
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      {requestTypeToString[type]}
                    </MenuItem>
                  );
                })}
              </CreateOrgSectionSelect>
            </Stack>
            <Stack>
              <InputLabel
                htmlFor="seats-licensed-select"
                sx={{
                  pb: 0.5,
                  ml: 2,
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Number of seats to license
              </InputLabel>
              <YoodliTextfield
                id="seats-licensed-select"
                type="number"
                placeholder="Number of seats to license"
                value={licensedSeats ?? "N/A"}
                required={true}
                onChange={(e) => setLicensedSeats(e.target.value)}
                error={!!errors.licensedSeats}
                helperText={errors.licensedSeats}
                fullWidth
                InputProps={{
                  sx: {
                    fontSize: "16px",
                    fontWeight: 500,
                  },
                }}
              />
            </Stack>
            <Stack>
              <InputLabel
                htmlFor="license-end-date-select"
                sx={{
                  pb: 0.5,
                  ml: 2,
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Subscription license end date
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
                <DatePicker
                  disablePast
                  slotProps={{
                    textField: {
                      required: true,
                      helperText: errors.licenseEndDate,
                      error: !!errors.licenseEndDate,
                      InputProps: {
                        sx: {
                          color: getDynamicColor("purple3"),
                          fontFamily: "poppins",
                          fontSize: "16px",
                          fontWeight: 500,
                        },
                      },
                    },
                  }}
                  defaultValue={licenseEndDate}
                  onChange={setLicenseEndDate}
                />
              </LocalizationProvider>
            </Stack>
            {showPerSeatDollars && (
              <Stack gap={3}>
                <Stack>
                  <InputLabel
                    htmlFor="per-seat-dollars-select"
                    sx={{
                      pb: 0.5,
                      ml: 2,
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Price per an additional seat (USD)
                  </InputLabel>
                  <YoodliTextfield
                    id="per-seat-dollars-select"
                    type="number"
                    placeholder="Price for an additional seat"
                    value={perSeatDollars}
                    required={true}
                    onChange={(e) => setPerSeatDollars(e.target.value)}
                    error={!!errors.perSeatDollars}
                    helperText={errors.perSeatDollars}
                    fullWidth
                    InputProps={{
                      sx: {
                        fontSize: "16px",
                        fontWeight: 500,
                      },
                    }}
                  />
                </Stack>
              </Stack>
            )}
            <Stack>
              <Grid container>
                <Grid item xs={10} lg={8}>
                  <InputLabel
                    htmlFor="inactive-seat-toggle"
                    sx={{
                      pb: 0.5,
                      ml: 2,
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Allow seats to become inactive
                  </InputLabel>
                </Grid>
                <Grid item xs={1} lg={4}>
                  <IOSSwitch
                    id="inactive-seat-toggle"
                    checked={seatInactiveEnabled}
                    onChange={(e) => setSeatInactiveEnabled(e.target.checked)}
                  />
                </Grid>
              </Grid>
              {seatInactiveEnabled && (
                <Stack>
                  <Grid sx={{ ml: 2 }} container>
                    <YoodliLabeledInput
                      label="Days until a seat is marked as inactive"
                      labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
                      inputEl={
                        <YoodliTextfield
                          autoFocus
                          inputProps={{
                            className: "blockEnterToNavigate",
                          }}
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          value={numDaysSeatInactive}
                          onChange={(e) => setNumDaysSeatInactive(parseInt(e.target.value))}
                        />
                      }
                    />
                  </Grid>
                </Stack>
              )}
            </Stack>
          </Stack>

          <input type="submit" style={{ display: "none" }} />
        </form>
        <Button
          startIcon={
            loadingText.length > 0 ? (
              <CircularProgress
                size={20}
                sx={{
                  color: getDynamicColor("dark1"),
                }}
              />
            ) : (
              <></>
            )
          }
          onClick={async (e) => {
            await handleUpdateOrg(e);
          }}
          variant="gradient"
          disabled={!isFormValid() || !!loadingText}
          sx={{
            fontFamily: "poppins",
            fontSize: "16px",
            marginTop: 3,
            fontWeight: 700,
            "&:disabled": {
              color: getDynamicColor("light1"),
              background: getDynamicColor("dark4"),
            },
          }}
        >
          {loadingText.length == 0 ? `Update ${card.label}` : loadingText}
        </Button>
      </Stack>
      <YoodliCtaModal
        theme={YoodliCtaModalTheme.Danger}
        hideCloseButton
        open={typeChangeWarningModalOpen}
        close={() => setTypeChangeWarningModalOpen(false)}
        ctaBody={{
          title: "Prior contract is gone without refund",
          subtitle:
            "This operation changes the contract type and prior subscription is canceled immediately without any refund. Contact Engineering team if payment adjustment is needed.",
        }}
        buttons={
          {
            primary: {
              text: "Proceed changing the type",
              handler: () => executeUpdateOrg().catch((e) => console.log(`executeUpdateOrg: ${e}`)),
            },
            secondary: { text: "Cancel", handler: () => setTypeChangeWarningModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
};
