// Components
import { Box, Stack, Typography } from "@mui/material";
import { HoistedAnalytic } from "components/Dashboard/DashboardTypes";

// Assets
import { ReactComponent as AverageIcon } from "images/icons/icon-average.svg";

// Utils
import TrendChip from "../TrendChip";
import { DetailTitle } from "./AnalyticDetail";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { formatAggAnalyticValue } from "utils/DashboardUtils";

type AverageComparisonProps = {
  analytic: HoistedAnalytic;
  numDays: number;
};
const AverageComparison = ({ analytic, numDays }: AverageComparisonProps): JSX.Element => {
  const { currAvg, prevAvg } = analytic ?? {};
  const displayCurrAvg = formatAggAnalyticValue(currAvg, analytic);
  return (
    <Stack
      gap={2}
      sx={{
        mt: { xs: 2, md: 0 },
      }}
    >
      {!isNaN(prevAvg) && (prevAvg || prevAvg === 0) && (
        <TrendChip numDays={numDays} analytic={analytic} />
      )}
      <Stack direction="row" alignItems="center">
        <Box
          sx={{
            width: 30,
            svg: { verticalAlign: "middle" },
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              height: 18,
              width: 18,
              backgroundColor: getDynamicColor("purple3"),
              borderRadius: "50%",
              svg: { fill: getDynamicColor("light1"), height: 11, width: 11 },
            }}
          >
            <AverageIcon />
          </Stack>
        </Box>
        <DetailTitle title="Overall Average:" />

        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          {displayCurrAvg}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AverageComparison;
