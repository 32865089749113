import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// Components
import { Edit as EditIcon } from "@mui/icons-material";
import { Close as CloseIcon, DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import { Button, Collapse, Grow, IconButton, Stack, SxProps, Typography } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { usePrevious } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { reorder } from "utils/Utilities";

type WizardAddDraggableItemsProps = {
  items: string[];
  handleUpdateItems: (items: string[]) => void;
  inputPlaceholder: string;
  maxItems?: number;
  maxInputLength?: number;
  itemLabel: string;
  wrapperSx?: SxProps;
  itemListSx?: SxProps;
  controlRowSx?: SxProps;
  disableDrag?: boolean;
  currStepIndex?: number;
  scrollBehaviorOptions?: ScrollIntoViewOptions;
};

export const WizardAddDraggableItems = ({
  items,
  handleUpdateItems,
  inputPlaceholder,
  maxItems,
  maxInputLength,
  itemListSx,
  itemLabel,
  wrapperSx,
  controlRowSx,
  disableDrag,
  currStepIndex,
  scrollBehaviorOptions,
}: WizardAddDraggableItemsProps): JSX.Element => {
  const lastItemRef = React.useRef(null);
  const [error, setError] = React.useState<string | null>(null);
  const [newValue, setNewValue] = React.useState("");
  const [editingId, setEditingId] = React.useState(undefined);
  const [editingValue, setEditingValue] = React.useState(undefined);
  const prevItems = usePrevious(items);
  React.useEffect(() => {
    if (maxItems === undefined || items.length <= maxItems) {
      setError(null);
    }
    // if i've grown by an item, i.e. just added a enw one, scroll to the latest one
    if (
      (items?.length > prevItems?.length || (items?.length && !prevItems)) &&
      lastItemRef.current
    ) {
      lastItemRef.current.scrollIntoView({
        behavior: "smooth",
        ...scrollBehaviorOptions,
      });
    }
  }, [items]);
  React.useEffect(() => {
    setNewValue("");
  }, [currStepIndex]);
  const handleDrag = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    const reordered = reorder(items, result.source.index, result.destination.index);
    handleUpdateItems(reordered);
    setEditingId(undefined);
  };
  const handleEditSave = (idx: number) => {
    setEditingId(undefined);
    const newItems = items.map((item, index) => (index === idx ? editingValue : item));
    handleUpdateItems(newItems);
  };
  const handleDelete = (idx: number) => {
    setEditingId(undefined);
    const newItems = items.filter((_, index) => index !== idx);
    if (maxItems !== undefined && newItems.length <= maxItems) {
      setError(null);
    }
    handleUpdateItems(newItems);
  };
  const handleClearAll = () => {
    setEditingId(undefined);
    handleUpdateItems([]);
    setError(null);
  };
  const handleAddItem = (item: string) => {
    setEditingId(undefined);
    if (!newValue?.trim()) {
      return;
    }
    if (maxItems !== undefined && items.length >= maxItems) {
      setError(`You can only have a max of ${maxItems} ${itemLabel}`);
      return;
    }
    handleUpdateItems([...items, item]);
    setNewValue("");
  };
  const WrapperEl = disableDrag ? "div" : DragDropContext;
  const DraggableEl = disableDrag ? "div" : Draggable;
  const DroppableEl = disableDrag ? "div" : Droppable;
  const renderItemList = (provided) => {
    return (
      <Stack
        {...(disableDrag ? {} : provided.droppableProps)}
        ref={provided.innerRef}
        direction="column"
        sx={{
          maxHeight: 500,
          overflowY: "auto",
          ...itemListSx,
        }}
      >
        {items.map((item, index) => {
          if (disableDrag) {
            return renderItem(item, index, {});
          }
          return (
            <DraggableEl
              key={index}
              {...(disableDrag ? {} : { draggableId: index.toString(), index })}
            >
              {(provided) => renderItem(item, index, provided)}
            </DraggableEl>
          );
        })}
        {!disableDrag && provided.placeholder}
      </Stack>
    );
  };
  const renderItem = (item, idx, provided) => {
    return (
      <span ref={idx === items?.length - 1 ? lastItemRef : undefined} key={idx}>
        <Stack
          ref={provided.innerRef}
          direction="row"
          gap={1.5}
          alignItems="center"
          justifyContent="space-between"
          {...(disableDrag ? {} : provided.draggableProps)}
          {...(disableDrag ? {} : provided.dragHandleProps)}
          sx={{
            borderRadius: "6px",
            p: 1,
            fontFamily: "poppins",
            backgroundColor: getDynamicColor("dark1"),
            svg: {
              height: "16px",
              width: "16px",
              color: getDynamicColor("primary"),
            },
            // use mb not gap here so the placeholder while dragging renders correctly
            mb: 1,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {!disableDrag && provided.placeholder}
          {!disableDrag && <DragIndicatorIcon style={{ cursor: "grab" }} />}
          {editingId === idx ? (
            <YoodliTextfield
              value={editingValue}
              multiline
              inputProps={{
                className: "blockEnterToNavigate",
              }}
              fadeInCharCountPct={75}
              minRows={1}
              maxRows={3}
              maxChars={maxInputLength}
              onChange={(e) => setEditingValue(e.target.value)}
            />
          ) : (
            <Typography
              sx={{
                fontSize: 14,
                textAlign: "left",
                flexGrow: 1,
                lineHeight: 1.3,
                pl: disableDrag && 1,
              }}
            >
              {item}
            </Typography>
          )}
          {editingId === idx ? (
            <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
              <Button
                size="small"
                sx={{ fontSize: 14 }}
                onClick={() => handleEditSave(idx)}
                variant="text"
              >
                Save
              </Button>
              <Button
                size="small"
                sx={{ fontSize: 14 }}
                onClick={() => {
                  setEditingId(undefined);
                  setEditingValue(undefined);
                }}
                variant="text"
              >
                Cancel
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setEditingValue(item);
                  setEditingId(idx);
                }}
                size="medium"
              >
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(idx)} size="medium">
                <CloseIcon />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </span>
    );
  };
  return (
    <Stack
      direction="column"
      gap={4}
      sx={{
        pb: 4,
        ...wrapperSx,
      }}
    >
      <YoodliTextfield
        autoFocus
        multiline
        minRows={1}
        maxRows={3}
        fadeInCharCountPct={75}
        placeholder={inputPlaceholder}
        value={newValue}
        onChange={(e) => setNewValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            if (newValue?.trim()) {
              handleAddItem(newValue);
            }
          }
        }}
        maxChars={maxInputLength}
        inputProps={{
          className: "blockEnterToNavigate",
        }}
        InputProps={{
          endAdornment: (
            <Button
              disabled={!newValue?.trim()}
              variant="outlined"
              onClick={() => {
                handleAddItem(newValue);
              }}
              sx={{
                fontSize: 14,
                height: 32,
              }}
            >
              Add
            </Button>
          ),
        }}
        sx={{
          "*": {
            borderWidth: "1px !important",
          },
        }}
      />

      <Collapse in={items?.length > 0} unmountOnExit>
        <Stack
          justifyContent="flex-end"
          gap={0.5}
          sx={{
            ...controlRowSx,
          }}
        >
          <Grow in={!!error}>
            <span>
              <Typography
                sx={{
                  color: getDynamicColor("redError"),
                  fontWeight: 600,
                  mx: "auto",
                  textAlign: "center",
                  fontSize: 14,
                }}
              >
                {error}
              </Typography>
            </span>
          </Grow>
          {items?.length > 0 && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: 12,
                  fontWeight: 400,
                  color: getDynamicColor("dark5"),
                }}
              >
                {items?.length}/{maxItems} {itemLabel}
              </Typography>

              <Button
                onClick={handleClearAll}
                sx={{
                  ml: "auto",
                  fontSize: 12,
                }}
              >
                Clear all
              </Button>
            </Stack>
          )}
          <WrapperEl {...(disableDrag ? {} : { onDragEnd: handleDrag })}>
            <DroppableEl {...(disableDrag ? {} : { droppableId: "droppable" })}>
              {disableDrag ? renderItemList({}) : (provided) => renderItemList(provided)}
            </DroppableEl>
          </WrapperEl>
        </Stack>
      </Collapse>
    </Stack>
  );
};
