// Utils
import { getSiteId } from "./LiveSiteDocs";
import { SiteConfig } from "lib-fullstack/workspaces/SiteConfig";

export function isDefaultSite(config: SiteConfig): boolean {
  return config.subdomain === "app";
}

export function isToastmasters(config?: SiteConfig): boolean {
  if (!config) {
    return getSiteId() === "toastmasters";
  }
  return config.subdomain === "toastmasters";
}
