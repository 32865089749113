// Components
import { Circle as CircleIcon, Close as CloseIcon } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Stack, Switch, Typography } from "@mui/material";
import PricingCard from "components/Pricing/PricingCard";
import { Save40 } from "lib-frontend/components/Save40";
import UsageItem from "lib-frontend/components/UsageItem";
import { WIGGLE_ANIMATION } from "lib-frontend/ui/Theme";

// Assets
import { ReactComponent as Sparkle2 } from "lib-frontend/assets/sparkle2.svg";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getLiveUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import {
  PRICING_EXPERIMENT_CONFIG,
  PricingDurationOption,
  pdfFileURL,
} from "lib-frontend/utils/PricingData";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { PricingReimbursementLetterLinkLocation } from "lib-fullstack/utils/productAnalyticEvents";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";

type PricingOptionsModalContentProps = {
  onClose: () => void;
  setLoading: (loading: boolean) => void;
  shouldRenderPricingGate: boolean;
  handleOptionSelected: () => void;
  usedSpeeches: number;
  usagePlanType: UsagePlanType;
  setDowngradeSelection: (downgradeSelection: UsagePlanType) => void;
  setDurationChange: (durationChange: boolean) => void;
  loading: boolean;
  pricingDurationOption: PricingDurationOption;
  handleSetPricingDurationOption: () => void;
  quota: number;
};

const PricingOptionsModalContent = ({
  onClose,
  setLoading,
  shouldRenderPricingGate,
  handleOptionSelected,
  usedSpeeches,
  usagePlanType,
  setDowngradeSelection,
  setDurationChange,
  loading,
  pricingDurationOption,
  handleSetPricingDurationOption,
  quota,
}: PricingOptionsModalContentProps): JSX.Element => {
  const openReimbursementPDF = () => {
    Instrumentation.logPricingReimbursementLetterClicked(
      PricingReimbursementLetterLinkLocation.WEBCLIENT_PRICING_MODAL
    );
    window.open(pdfFileURL, "_blank");
  };

  return (
    <Stack gap={{ xs: 2, md: 3 }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: getDynamicColor("dark4", 0.75),
          pointerEvents: loading ? "all" : "none",
          opacity: loading ? 1 : 0,
          transition: "opacity 0.2s ease-out",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 2,
        }}
      >
        <CircularProgress sx={{ width: "24px", height: "24px" }} />
      </Box>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
      >
        <CloseIcon sx={{ width: "24px", height: "24px" }} />
      </IconButton>
      <Stack
        direction={{ xs: "column", md: "column" }}
        gap={2}
        sx={{
          justifyContent: "space-between",
          pr: { xs: 0, md: 4, lg: 8, xl: 12 },
          alignItems: { xs: "center", md: "flex-start" },
          width: { xs: "100%", md: "auto" },
        }}
      >
        <Stack
          gap={2.5}
          alignItems={{ xs: "center", md: "flex-start" }}
          justifyContent={{ xs: "center", md: "space-between" }}
          direction={{ xs: "column", md: "row" }}
          sx={{
            width: "100%",
          }}
        >
          <Stack gap={1}>
            <Typography
              sx={{
                width: { xs: "100%", md: "auto" },
                textAlign: "left",
                fontFamily: "poppins",
                fontSize: { xs: 20, sm: 24, md: 28 },
                fontWeight: 400,
                color: getDynamicColor("purple3"),
                letterSpacing: "-0.7px",
                pr: { xs: 5, sm: 2, md: 0 },
              }}
            >
              Choose a plan that best fits your speaking goals
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              sx={{
                display: { xs: "none", md: "initial" },
                width: "fit-content",
                "&:hover": {
                  svg: {
                    ...WIGGLE_ANIMATION,
                  },
                },
              }}
            >
              <Sparkle2
                style={{
                  height: 36,
                  width: 36,
                  minHeight: 36,
                  minWidth: 36,
                }}
              />
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  flexGrow: 1,
                  fontSize: { xs: 16, sm: 18, md: 20 },
                  fontWeight: 700,
                  whiteSpace: { xs: "auto", xl: "nowrap" },
                  textAlign: { xs: "auto", xl: "center" },
                }}
              >
                See details on{" "}
                <a
                  onClick={openReimbursementPDF}
                  style={{ color: getDynamicColor("primary"), cursor: "pointer", fontWeight: 700 }}
                >
                  {PRICING_EXPERIMENT_CONFIG[getLiveUserDocMain().pExpVersion].seeDetailsText}
                </a>
              </Typography>
            </Stack>
          </Stack>
          {shouldRenderPricingGate && (
            <UsageItem
              numUsed={usedSpeeches}
              quota={quota}
              usagePlanType={usagePlanType}
              icon={
                <CircleIcon
                  sx={{ width: "16px", height: "16px", color: getDynamicColor("primary") }}
                />
              }
              open
              showResetsInCopy={usagePlanType === UsagePlanType.PRO_V1}
              progressColor={getDynamicColor("primary")}
              progressSize={26}
              wrapperSx={{
                gap: 2,
              }}
              textSx={{
                fontSize: { xs: 16, xl: 18 },
                color: getDynamicColor("dark4"),
              }}
              resetsInCopySx={{
                fontSize: { xs: 14, xl: 16 },
              }}
            />
          )}
        </Stack>
        {/* Either render the monthly/annual toggle OR the crunch/growth mode savings of 50% */}
        {PRICING_EXPERIMENT_CONFIG[getLiveUserDocMain().pExpVersion].toggleAnnualMonthly ? (
          <Stack direction={{ xs: "column", md: "row" }} gap={{ xs: 1, md: 5 }} alignItems="center">
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography
                sx={{
                  fontSize: { xs: 14, md: 18 },
                  fontWeight: 500,
                  color: getDynamicColor(
                    pricingDurationOption === PricingDurationOption.MONTHLY ? "primary" : "purple3"
                  ),
                }}
              >
                Billed monthly
              </Typography>
              <Switch
                checked={pricingDurationOption === PricingDurationOption.ANNUALLY}
                onClick={handleSetPricingDurationOption}
              />

              <Typography
                sx={{
                  fontSize: { xs: 14, md: 18 },
                  fontWeight: 500,
                  color: getDynamicColor(
                    pricingDurationOption === PricingDurationOption.ANNUALLY ? "primary" : "purple3"
                  ),
                }}
              >
                Billed annually
              </Typography>
            </Stack>
            <Save40 isAnnual={pricingDurationOption === PricingDurationOption.ANNUALLY} />
          </Stack>
        ) : (
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 700,
              fontFamily: "poppins",
              p: 1,
              px: 1.5,
              height: "fit-content",
              width: "fit-content",
              mx: "auto",
              lineHeight: 1,
              borderRadius: "4px",
              backgroundColor: getDynamicColor("greenSuccessLight"),
              color: getDynamicColor("greenWarning"),
            }}
          >
            Save 50% with growth mode!
          </Typography>
        )}
      </Stack>
      <Stack
        sx={{
          width: "100%",
          flexDirection: { xs: "column", md: "row" },
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: { xs: "center", md: "center" },
          maxWidth: "unset",
          pb: { xs: 4, md: "unset" },
        }}
      >
        {PRICING_EXPERIMENT_CONFIG[getLiveUserDocMain().pExpVersion].cards.map((cardData) => (
          <Box
            key={cardData.id}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexBasis: { xs: "100%", sm: "50%", xl: "25%" },
              width: { xs: "100%", sm: "50%" },
              minWidth: { xs: 270, md: 280 },
              p: { xs: 1, md: 1.5 },
            }}
          >
            <PricingCard
              key={cardData.id}
              handleOptionSelected={handleOptionSelected}
              isAnnually={
                cardData.cost.annually && pricingDurationOption === PricingDurationOption.ANNUALLY
              }
              setDowngradeSelection={setDowngradeSelection}
              setDurationChange={setDurationChange}
              cardData={cardData}
              setLoading={setLoading}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default PricingOptionsModalContent;
