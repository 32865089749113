// Utils
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

/**
 * Enum indicating the type of the integration. Used for casting and type checking at runtime.
 */
export enum IntegrationTypeEnum {
  /**
   * Test integration type.
   */
  Test = "test",
  /**
   * LTI 1.1 integration.
   */
  LTI_1_1 = "lti_1_1",
  /**
   * LTI 1.3 integration.
   */
  LTI_1_3 = "lti_1_3",
  /**
   * SCORM 1.2 integration.
   */
  SCORM_1_2 = "scorm_1_2",
}

/**
 * Enum indicating the type of the integration. Used for casting and type checking at runtime.
 */
export const IntegrationType = RTStringEnum(IntegrationTypeEnum);
