import React from "react";

// Components
import { CloseRounded as CloseRoundedIcon } from "@mui/icons-material";
import { Box, Chip, Stack } from "@mui/material";
import { WizardTitle } from "components/Wizard/WizardTitles";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type EmailBankProps = {
  emails: string[];
  title: string;
  isError?: boolean;
  handleRemoveEmail?: (email: string) => void;
};

export const EmailBank = ({
  emails,
  title,
  isError,
  handleRemoveEmail,
}: EmailBankProps): JSX.Element => {
  const [hoveringIdx, setHoveringIdx] = React.useState<number | null>(null);
  return (
    <Stack gap={1}>
      <WizardTitle
        overrideSx={{
          fontSize: { xs: 13, md: 14 },
        }}
      >
        {title}
      </WizardTitle>
      <Box
        sx={{
          border: `1px solid ${getDynamicColor("dark4")}`,
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <Stack
          gap={1.5}
          direction="row"
          sx={{
            flexWrap: "wrap",
            p: 3,
            fontFamily: "poppins",
            maxHeight: 256,
            overflow: "auto",
          }}
        >
          {emails.map((email, index) => (
            <Chip
              key={index}
              label={email}
              sx={{
                width: "fit-content",
                fontSize: 14,
                borderRadius: "6px",
                fontWeight: 700,
                color: getDynamicColor(isError ? "redError" : "dark5"),
                backgroundColor: getDynamicColor("dark2"),
                transition: "all 0.2s",
                userSelect: "auto",
                ...(hoveringIdx === index && {
                  color: getDynamicColor("dark6"),
                  backgroundColor: getDynamicColor("dark3"),
                  svg: {
                    stroke: getDynamicColor("dark6"),
                  },
                }),
              }}
              onDelete={() => handleRemoveEmail(email)}
              deleteIcon={
                handleRemoveEmail ? (
                  <CloseRoundedIcon
                    onMouseEnter={() => setHoveringIdx(index)}
                    onMouseLeave={() => setHoveringIdx(null)}
                    sx={{
                      height: 24,
                      p: 0.75,
                      width: 24,
                      strokeWidth: 2,
                      stroke: !isError ? getDynamicColor("dark5") : getDynamicColor("redError"),
                      transition: "all 0.2s",
                    }}
                  />
                ) : (
                  <span />
                )
              }
            />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};
