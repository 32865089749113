import React from "react";
import Slider from "react-slick";

// Components
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { Box, IconButton, Stack } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
// React Slick Styles
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const DESKTOP_TIP_HEIGHT = 265;
export const MOBILE_TIP_HEIGHT = 480;
const NORMAL_DOT_COLOR = getDynamicColor("dark3");
const SELECTED_COLOR = getDynamicColor("purple3");
const ARROW_COLOR = getDynamicColor("dark3");

const CAROUSEL_STYLES = {
  ".slick-dots li": {
    margin: "0 2px 0 2px", // Spacing between dots
  },
  ".slick-dots li button:before": {
    position: "relative",
    fontSize: "13px", // Dots size
    opacity: "1",
    color: NORMAL_DOT_COLOR,
  },
  ".slick-dots li.slick-active button:before": {
    color: SELECTED_COLOR,
  },
  ".slick-dots li button:hover:before": {
    color: getDynamicColor("primary"),
  },
};

type TipsCarouselProps = {
  carouselElements: JSX.Element[];
  carouselControlsMarginTop: number;
  isSmallScreen: boolean;
};

export function TipsCarousel({
  carouselElements,
  carouselControlsMarginTop,
  isSmallScreen,
}: TipsCarouselProps): JSX.Element {
  const sliderRef = React.useRef<Slider>(null);
  const currIdx = React.useRef<number>(0);

  const settings = {
    afterChange: (index: number) => {
      // Check to make sure there is more than one element, because this can fire when the elements load from 0 to 1.
      if (carouselElements.length > 1) {
        currIdx.current = index;
      }
    },
    dots: carouselElements.length > 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000, // delay between auto-rotates
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleClickPrev = () => {
    if (sliderRef?.current) {
      sliderRef.current.slickPrev();
      Instrumentation.logUsageTipCarouselSlideManuallyChanged(
        currIdx.current - 1 < 0 ? carouselElements.length : currIdx.current
      );
    }
  };

  const handleClickNext = () => {
    if (sliderRef?.current) {
      sliderRef.current.slickNext();
      Instrumentation.logUsageTipCarouselSlideManuallyChanged(
        currIdx.current + 1 >= carouselElements.length ? 1 : currIdx.current + 2
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        ...CAROUSEL_STYLES,
        ".slick-list": {
          height: (isSmallScreen ? MOBILE_TIP_HEIGHT : DESKTOP_TIP_HEIGHT) + 17,
          paddingTop: "3px",
        },
        ".slick-dots": {
          bottom: -18 - carouselControlsMarginTop,
        },
      }}
    >
      <Slider ref={sliderRef} {...settings} className={"slickSlider"}>
        {carouselElements.map((item) => (
          <Box
            style={{
              width: "100%",
              height: "100%",
            }}
            key={item.key}
          >
            {item}
          </Box>
        ))}
      </Slider>
      {/* The carousel dots are part of the slickSlider class, but we add our own custom arrows.
          As a result, we need to dynamically calculate the gap between the arrows based on the number of dots. */}
      {carouselElements?.length > 1 && (
        <Stack
          direction={"row"}
          alignItems="center"
          display="flex"
          justifyContent="center"
          sx={{
            width: "100%",
            position: "absolute",
            bottom: -19 - carouselControlsMarginTop,
            zIndex: 1,
            pointerEvents: "none",
          }}
        >
          <IconButton
            sx={{
              p: 0,
              cursor: "pointer",
              borderRadius: "12px",
              color: ARROW_COLOR,
              "&:hover": {
                color: getDynamicColor("primary"),
              },
              transition: "margin 0.2s",
              mr: 2 * Math.max(carouselElements.length - 1, 0) + 3,
              mb: "2px",
              pointerEvents: "auto",
            }}
            onClick={handleClickPrev}
            disabled={carouselElements.length <= 1}
          >
            <ChevronLeft />
          </IconButton>
          {/* The slick carousel does not show any does if there is only one element, so we add add a disabled dot and set arrows to disabled in this case. */}
          <CircleIcon
            sx={{
              color: SELECTED_COLOR,
              height: "9px",
              width: "9px",
              visibility: carouselElements.length > 1 && "hidden",
              pointerEvents: carouselElements.length > 1 ? "none" : "auto",
            }}
          />
          <IconButton
            sx={{
              p: 0,
              cursor: "pointer",
              borderRadius: "12px",
              color: ARROW_COLOR,
              "&:hover": {
                color: getDynamicColor("primary"),
              },
              transition: "margin 0.2s",
              ml: 2 * Math.max(carouselElements.length - 1, 0) + 3,
              mb: "2px",
              pointerEvents: "auto",
            }}
            onClick={handleClickNext}
            disabled={carouselElements.length <= 1}
          >
            <ChevronRight />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
}
