import React from "react";

// Components
import { Stack, Typography } from "@mui/material";
import {
  CUSTOM_GOAL_MAX_SCORE,
  CUSTOM_GOAL_MIN_SCORE,
  CUSTOM_GOAL_NAME_MAX_CHARS,
  CUSTOM_GOAL_TEXTFIELD_MIN_ROWS,
  CUSTOM_GOAL_USER_DESC_MAX_CHARS,
  CreateConvoScenarioStepId,
  GoalHooks,
} from "components/ConvoScenarios/convoScenarioUtils";
import { WizardStep } from "components/Wizard/WizardTypes";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { CreateCompoundGoalSubGoalStep } from "./CreateCompoundGoalSubGoalStep";
import { MinMaxScoreSelector } from "./MinMaxScoreSelector";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  CreateCompoundGoalRequest,
  CreateCustomGoalRequest,
} from "lib-fullstack/api/scenarioApiTypes";
import { CompoundGoal } from "lib-fullstack/db";

export const CreateCompoundGoalSteps = (
  compoundGoal: CreateCompoundGoalRequest,
  setCompoundGoal: React.Dispatch<React.SetStateAction<CreateCompoundGoalRequest>>,
  createCustomGoal: (customGoal: CreateCustomGoalRequest) => Promise<string>,
  goalEditId: string,
  setDiscardGoalModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  goalHooks: GoalHooks,
  closeWizard: () => void,
  inScenarioCreation?: boolean
): WizardStep[] => {
  const handleUpdateCompoundGoal = (key: keyof CompoundGoal, val: string | number) => {
    setCompoundGoal((prev) => ({ ...prev, [key]: val }));
  };

  const descStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: inScenarioCreation ? "Customize" : "Start",
    component: (
      <ConvoScenarioStepWrapper
        title={goalEditId ? "Update Compound Goal" : "Create a Compound Goal"}
        subTitle="Name this compound goal, set up the scoring scale, and add a short description"
      >
        <Stack
          gap={3}
          sx={{
            position: "relative",
          }}
        >
          <YoodliLabeledInput
            label="Name"
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <YoodliTextfield
                autoFocus
                inputProps={{
                  className: "blockEnterToNavigate",
                }}
                maxChars={CUSTOM_GOAL_NAME_MAX_CHARS}
                InputLabelProps={{ shrink: true }}
                placeholder="e.g. STARRY approach"
                value={compoundGoal.name}
                onChange={(e) => handleUpdateCompoundGoal("name", e.target.value)}
              />
            }
          />
          <Stack>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: 600,
                color: getDynamicColor("purple3"),
              }}
            >
              Score
            </Typography>
            <Stack
              direction="row"
              gap={3}
              sx={{ justifyContent: "space-between", flexWrap: "wrap" }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 14,
                  fontWeight: 400,
                  color: getDynamicColor("dark5"),
                  maxWidth: { sm: "100%", md: "400px" },
                }}
              >
                Set the max score members can achieve for each subgoal (max 10). An aggregate score
                will be calculated based on the average of all subgoal scores.
              </Typography>
              <MinMaxScoreSelector
                min={{ value: CUSTOM_GOAL_MIN_SCORE, disabled: true }}
                max={{
                  value: compoundGoal.maxScore,
                  minValue: CUSTOM_GOAL_MIN_SCORE,
                  maxValue: CUSTOM_GOAL_MAX_SCORE,
                  onChange: (value: string) => {
                    handleUpdateCompoundGoal("maxScore", value ? Number(value) : undefined);
                  },
                }}
              />
            </Stack>
          </Stack>
          <YoodliLabeledInput
            label="Add a member-facing description (optional)"
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <YoodliTextfield
                multiline
                minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                maxChars={CUSTOM_GOAL_USER_DESC_MAX_CHARS}
                inputProps={{
                  className: "blockEnterToNavigate",
                }}
                placeholder="e.g. Use the STARRY approach to navigate a difficult conversation."
                InputLabelProps={{ shrink: true }}
                value={compoundGoal.userDescription ?? ""}
                onChange={(e) => handleUpdateCompoundGoal("userDescription", e.target.value)}
              />
            }
          />
        </Stack>
      </ConvoScenarioStepWrapper>
    ),
    validate: () => compoundGoal.name.length > 0,
    prev: () => {
      setDiscardGoalModalOpen(true);
    },
    prevCopy: "Discard",
    skipPrevIndexUpdate: inScenarioCreation,
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;

  const subgoalStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: "Customize",
    component: (
      <CreateCompoundGoalSubGoalStep
        compoundGoal={compoundGoal}
        setCompoundGoal={setCompoundGoal}
        goalEditId={goalEditId}
        goalHooks={goalHooks}
      />
    ),
    skipNextIndexUpdate: true,
    validate: () => compoundGoal.subGoals?.length > 1,
    next: async () => {
      await createCustomGoal(compoundGoal);
      if (!inScenarioCreation) {
        closeWizard();
      }
    },
    hidePrev: !compoundGoal.subGoals,
    nextCopy: "Save",
    enterToNavigate: true,
    showProgressTracker: true,
    nextHelperText: compoundGoal.subGoals?.length === 1 && "Add one more subgoal",
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;
  return [descStep, subgoalStep];
};
