import React, { useMemo } from "react";

// Components
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";

// Assets
// UI Utilities/Functions
import { ReactComponent as FilterIcon } from "../../../images/icons/Filter.svg";

// Utils
import { SpeechTableRow } from "./TableSortClient";
import { Column } from "@tanstack/react-table";
// Utilities/Functions
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";

type FilterItem = {
  label: string;
  value: string;
  isSelected: boolean;
};

export type LabelledValue = {
  label: string;
  value: string;
};

export const TableFilter = (props: {
  column?: Column<SpeechTableRow, unknown>;
  setFilterState?: (filterStr: string) => void;
  names: string[] | LabelledValue[];
  searchable: boolean;
  buttonSx?: SxProps;
  buttonText?: string;
  buttonProps?: ButtonProps;
}): JSX.Element => {
  const { column, setFilterState, names, searchable, buttonSx, buttonText, buttonProps } = props;
  const availableTypeFilters: FilterItem[] = names.map((name) => {
    return {
      label: typeof name === "string" ? name : name.label,
      value: typeof name === "string" ? name : name.value,
      isSelected: false,
    };
  });

  const [isOpen, setIsOpen] = React.useState(false);
  const [filterItems, setFilterItems] = React.useState<FilterItem[]>(availableTypeFilters);
  // We use a tempFilterItems to store the filter items that the user is currently selecting,
  // which are then only saved to the filterItems when the user clicks "Apply".
  const [tempFilterItems, setTempFilterItems] = React.useState([]);
  const dropDownRef = React.useRef();

  const [searchText, setSearchText] = React.useState("");

  const searchedFilterItems = useMemo(() => {
    if (searchText) {
      return tempFilterItems.filter((item) =>
        item.label.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return tempFilterItems;
  }, [searchText, tempFilterItems]);

  React.useEffect(() => {
    const newFilterList = filterItems.filter((item) => item.isSelected).map((item) => item.label);
    if (column) {
      column.setFilterValue(newFilterList);
    }
    if (setFilterState) {
      const filterValues = filterItems.filter((item) => item.isSelected).map((item) => item.value);
      setFilterState(filterValues.join(","));
    }
  }, [filterItems]);

  const handleTempFilterValueChanged = (name: string) => {
    const newFilterItems = tempFilterItems.map((item) => {
      if (item.label === name) {
        return {
          label: name,
          value: item.value,
          isSelected: !item.isSelected,
        };
      }
      return item;
    });

    setTempFilterItems(newFilterItems);
  };

  const getMenuItem = (checked: boolean, name: string) => (
    <MenuItem key={name} onClick={() => handleTempFilterValueChanged(name)} sx={{ px: 1 }}>
      <Stack direction="row" alignItems="center">
        <Checkbox checked={checked} color={"primary"} sx={{ pl: 0 }} />
        <Typography
          style={{
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: 600,
            textOverflow: "ellipsis",
            overflow: "hidden",
            minWidth: "12ch",
            maxWidth: "32ch",
          }}
        >
          {name[0].toUpperCase() + name.slice(1)}
        </Typography>
      </Stack>
    </MenuItem>
  );

  const menuList = searchedFilterItems.map((item) => getMenuItem(item.isSelected, item.label));

  const getSearchBar = () => (
    <Stack sx={{ mx: 1, mt: 1 }}>
      <FormControl
        sx={{ minWidth: "16ch", backgroundColor: "white" }}
        variant="outlined"
        size="small"
        key="search"
      >
        <InputLabel htmlFor="filter-input-search" margin="dense">
          Search
        </InputLabel>
        <OutlinedInput
          id="filter-input-search"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          // To prevent menu autofocus behavior
          onKeyDown={(event) => event.stopPropagation()}
          endAdornment={
            // search icon turns into 'X' for clearing text when
            // there is text to be cleared.
            // Search is instantaneous.
            <InputAdornment position="end">
              <IconButton aria-label="Search" onClick={() => setSearchText("")} edge="end">
                {searchText.length > 0 ? <ClearIcon /> : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          }
          label="Search"
        />
      </FormControl>
    </Stack>
  );

  const handleClick = (event) => {
    event.stopPropagation();
    setTempFilterItems(filterItems);
    setIsOpen(true);
    setSearchText("");
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClearAllFilters = () => {
    const newFilterItems = filterItems.map((item) => {
      return { ...item, isSelected: false };
    });
    setSearchText("");
    setTempFilterItems(newFilterItems);
  };

  const handleApplyClicked = () => {
    setFilterItems(tempFilterItems);
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        sx={{
          color: "inherit",
          maxWidth: buttonText ? "unset" : "3rem",
          minWidth: buttonText ? "unset" : "3rem",
          ...buttonSx,
        }}
        aria-expanded={isOpen ? "true" : undefined}
        onClick={handleClick}
        ref={dropDownRef}
        {...buttonProps}
      >
        {buttonText ? (
          buttonText
        ) : (
          <Box style={{ width: "14px", height: "14px", marginBottom: "5px" }}>
            <FilterIcon
              stroke={
                filterItems.some((elem) => elem.isSelected)
                  ? getDynamicColor("primary")
                  : getDynamicColor("dark3")
              }
            />
          </Box>
        )}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={dropDownRef.current}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          zIndex: 3001,
          borderRadius: "12px",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: "transparent",
              border: "hidden",
              boxShadow: "none",
              justifyContent: "center",
              zIndex: 1000,
            },
          },
        }}
      >
        <Stack
          minWidth="150px"
          py={2}
          pb={0}
          gap={1}
          borderRadius="12px"
          border={`1px solid ${getDynamicColor("dark3")}`}
          boxShadow={Y_SHADOWS.box_shadow_1}
          sx={{ background: getDynamicColor("light1") }}
        >
          {searchable && tempFilterItems.length >= 10 && getSearchBar()}
          <Stack sx={{ maxHeight: "min(400px, calc(100vh - 250px))", overflow: "auto" }}>
            {menuList}
          </Stack>
          {menuList.length == 0 && searchText.length > 0 && (
            <Typography sx={{ mt: 2, textAlign: "center" }}>No results found</Typography>
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ p: 1, borderTop: "1px solid lightgrey" }}
            gap={1}
          >
            <Button
              onClick={handleClearAllFilters}
              aria-label="Clear All"
              sx={{ color: getDynamicColor("primary"), fontWeight: 600 }}
            >
              Clear All
            </Button>
            <Button
              onClick={handleApplyClicked}
              aria-label="Apply"
              style={{
                backgroundColor: getDynamicColor("primary"),
                color: getDynamicColor("light1"),
                fontWeight: 600,
              }}
            >
              Apply
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};
