import React from "react";

export const usePreventSwipeClick = (
  onClick: (e) => void
): {
  handleMouseDown: () => void;
  handleMouseMove: () => void;
  handleMouseUp: () => void;
  handleClickIgnoreSwipe: (e) => void;
} => {
  const [mouseDown, setMouseDown] = React.useState(false);
  const [mouseMove, setMouseMove] = React.useState(false);

  const handleMouseDown = () => {
    setMouseDown(true);
    setMouseMove(false);
  };

  const handleMouseMove = () => {
    if (mouseDown) {
      setMouseMove(true);
    }
  };

  const handleMouseUp = () => {
    setMouseDown(false);
  };

  const handleClickIgnoreSwipe = (e) => {
    if (!mouseMove) {
      onClick(e);
    }
  };

  return { handleMouseDown, handleMouseMove, handleMouseUp, handleClickIgnoreSwipe };
};
