import React from "react";

// Components
import { Stack, IconButton, Link, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { SettingsTab } from "lib-frontend/utils/PricingData";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

type PendingPlanChangeModalContentProps = {
  handleCloseModal: () => void;
};

const PendingPlanChangeModalContent = ({
  handleCloseModal,
}: PendingPlanChangeModalContentProps): JSX.Element => {
  return (
    <Stack
      sx={{
        maxWidth: 600,
      }}
    >
      <IconButton
        onClick={handleCloseModal}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        sx={{
          width: "100%",
          p: 2.5,
          borderBottom: `1px solid ${getDynamicColor("dark3")}`,
          textAlign: "center",
          fontFamily: "poppins",
          fontWeight: 600,
          color: getDynamicColor("purple3"),
        }}
      >
        Change your plan
      </Typography>
      <Stack
        sx={{
          height: { xs: 200, md: 300 },
          width: "100%",
          p: 2,
          mb: 8,
          color: getDynamicColor("purple3"),
        }}
        alignItems="center"
        justifyContent="center"
        gap={2}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontFamily: "poppins",
            textAlign: "center",
          }}
        >
          You have a pending plan change
        </Typography>
        <Typography
          sx={{
            fontFamily: "poppins",
            fontWeight: 400,
            maxWidth: 350,
            textAlign: "center",
          }}
        >
          Please cancel your existing plan change request in the{" "}
          <Link
            sx={{
              fontWeight: 700,
              color: getDynamicColor("primary"),
            }}
            href={`${WebServerExternalPath.ACCOUNT}?tab=${SettingsTab.BILLING}`}
          >
            Plan & Billing
          </Link>{" "}
          tab before proceeding.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PendingPlanChangeModalContent;
