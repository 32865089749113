import { db } from "lib-fullstack";
import React from "react";

// Components
import { Button, Stack, Typography, Dialog } from "@mui/material";

// Utils
import LastRecording from "./LastRecording";
import OutOfRecordingsModal from "./OutOfRecordingsModal";
import { usePricingUsage } from "hooks";
import { useShouldRenderPricingGate } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { ZoodliAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";

type RecordingCompleteProps = {
  setRecordingComplete: (val: boolean) => void;
  lastDbBot: db.Doc<db.RecallZoomBot>;
  lastDbSpeech: db.Doc<db.Speech>;
  zoomUrl: string;
  setZoomUrl: (val: string) => void;
  inviteBotToMeeting: (val: string) => void;
  setOptimisticLoading: (val: boolean) => void;
  modalContainerRef: React.RefObject<HTMLDivElement>;
};

const BUTTON_STYLES = {
  borderRadius: "50px",
  py: 1,
  px: 3,
  fontWeight: 700,
};

const RecordingComplete = ({
  setRecordingComplete,
  lastDbBot,
  lastDbSpeech,
  zoomUrl,
  setZoomUrl,
  inviteBotToMeeting,
  setOptimisticLoading,
  modalContainerRef,
}: RecordingCompleteProps): JSX.Element => {
  const { thresholdHit } = usePricingUsage();
  const shouldRenderPricingGate = useShouldRenderPricingGate();

  const inviteZoodliBotAgain = async () => {
    try {
      setOptimisticLoading(true);
      Instrumentation.logAmplitudeEvent(ZoodliAnalyticsEvents.INVITE_AGAIN);
      await inviteBotToMeeting(zoomUrl);
      setOptimisticLoading(false);
    } catch (er) {
      throw new Error("Error inviting bot to meeting: " + er);
    }
  };

  const doneUsingZoodliBot = () => {
    setRecordingComplete(false);
    setZoomUrl(undefined);
  };
  return (
    <Stack
      gap={2}
      alignItems="center"
      sx={{
        color: getDynamicColor("purple3"),
        pb: !lastDbBot || !lastDbSpeech ? 1 : 0,
        width: "100%",
      }}
    >
      <Typography
        sx={{
          fontSize: 24,
          fontWeight: 700,
          py: 1,
        }}
      >
        Recording Saved!
      </Typography>
      <Button color="primary" variant="contained" onClick={inviteZoodliBotAgain} sx={BUTTON_STYLES}>
        Re-invite to current call
      </Button>
      <Button color="primary" variant="outlined" onClick={doneUsingZoodliBot} sx={BUTTON_STYLES}>
        Done for this call
      </Button>
      {lastDbBot && lastDbSpeech && <LastRecording dbBot={lastDbBot} dbSpeech={lastDbSpeech} />}
      <Dialog
        container={modalContainerRef.current}
        open={shouldRenderPricingGate && thresholdHit}
        slotProps={{ backdrop: { style: { position: "absolute" } } }}
        PaperComponent={OutOfRecordingsModal}
        sx={{ position: "absolute" }}
      />
    </Stack>
  );
};

export default RecordingComplete;
