// Utility functions to access and manipulate query parameters in the current URL

/**
 * Add a query parameter to the current window location.
 * @param key The key of the query parameter.
 * @param value The value of the query parameter. It cannot be null or empty.
 * @param overwrite If true, overwrite the value if the key already exists. Otherwise throws.
 */
export function addQueryParamToCurrentUrl(key: string, value: string, overwrite: boolean): void {
  if (!key || !value) {
    throw new Error("Unexpected: key and value are required");
  }
  const url = new URL(window.location.href);
  if (url.searchParams.has(key)) {
    if (!overwrite) {
      throw new Error(`The query parameter ${key} already exists`);
    }
  }
  url.searchParams.set(key, value);
  window.history.replaceState({}, "", url.toString());
}

/**
 * Get a query parameter value ino the current window location.
 * @param key The key of the query parameter.
 * @returns The value of the query parameter if it exists. Otherwise, null.
 * If 2+ entries exist for the key, only the first one is returned.
 */
export function getQueryParamFromCurrentUrl(key: string): string | null {
  if (!key) {
    throw new Error("Unexpected: key is required");
  }
  const url = new URL(window.location.href);

  // A string if the given search parameter is found; otherwise, null.
  const value = url.searchParams.get(key);

  return value;
}

/**
 * Remove a query parameter to the current window location and return the current value.
 * @param key The key of the query parameter.
 * @returns The value of the query parameter if it exists. Otherwise, null.
 * If 2+ entries exist for the key, all are removed, but only the first one is returned.
 */
export function removeQueryParamFromCurrentUrl(key: string): string | null {
  const value = getQueryParamFromCurrentUrl(key);

  if (value) {
    const url = new URL(window.location.href);
    url.searchParams.delete(key);
    window.history.replaceState({}, "", url.toString());
  }

  return value;
}
