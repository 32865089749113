/*eslint @typescript-eslint/no-explicit-any: ["error", { "ignoreRestArgs": true }]*/
import * as amplitude from "@amplitude/analytics-browser";

/**
 * We provide a special Amplitude logger that reduces error/warning logs
 * to standard console logs to avoid spamming sentry with errors
 */
export class AmplitudeYoodliLogger implements amplitude.Types.Logger {
  private enabled = true;
  private logLevel: amplitude.Types.LogLevel = amplitude.Types.LogLevel.None;

  public disable(): void {
    this.enabled = false;
  }

  public enable(logLevel: amplitude.Types.LogLevel): void {
    this.enabled = true;
    this.logLevel = logLevel;
  }

  public log(...args: any[]): void {
    if (this.enable && this.logLevel >= amplitude.Types.LogLevel.Verbose) {
      args.unshift("[AMPLITUDE]");
      console.log(...args);
    }
  }

  public warn(...args: any[]): void {
    if (this.enable && this.logLevel >= amplitude.Types.LogLevel.Warn) {
      args.unshift("[AMPLITUDE]");
      console.log(...args);
    }
  }

  public error(...args: any[]): void {
    if (this.enable && this.logLevel >= amplitude.Types.LogLevel.Error) {
      args.unshift("[AMPLITUDE]");
      console.log(...args);
    }
  }

  public debug(...args: any[]): void {
    if (this.enable && this.logLevel >= amplitude.Types.LogLevel.Debug) {
      args.unshift("[AMPLITUDE]");
      console.debug(...args);
    }
  }
}
