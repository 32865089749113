import React from "react";

// Components
import { Button, Stack, Typography } from "@mui/material";

// Utils
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { OrgSettingsTabs, isOrgOwnerAdmin } from "lib-frontend/utils/orgUtils";
import { AvailableContentQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { getAllPersonasMember, listGroupScenarios } from "lib-frontend/modules/AxiosInstance";
import firebase from "firebase/app";
import { ReadOnlyScenarioCard } from "./ReadOnlyScenarioCard";
import { OrgContentQueryParams } from "lib-fullstack/utils/queryParams";
import { WebServerInternalPath } from "utils/paths";
import { useNavigate } from "react-router-dom";
import { CustomizePracticeTabEnum } from "../ManageContent/CustomizePractice/CustomizePracticeTab";

export const ScenariosTab = ({ groupId }: { groupId: string }): JSX.Element => {
  const { defaultOrg, defaultOrgLoading } = React.useContext(UserOrgContext);
  const navigate = useNavigate();

  const scenariosQuery = useApiQuery({
    queryKey: [AvailableContentQueryKey.Scenarios, defaultOrg?.id, groupId],
    queryFn: async () => await listGroupScenarios(defaultOrg?.id, groupId),
    enabled: !!firebase.auth().currentUser?.uid && !!defaultOrg && !defaultOrgLoading,
    refetchOnWindowFocus: false,
  });

  const personasQuery = useApiQuery({
    queryKey: [AvailableContentQueryKey.Personas, defaultOrg?.id],
    queryFn: async () => await getAllPersonasMember(defaultOrg?.id),
    enabled: !!firebase.auth().currentUser?.uid && !!defaultOrg && !defaultOrgLoading,
    refetchOnWindowFocus: false,
  });

  const scenarios = scenariosQuery.data?.contentArray;

  return (
    <Stack gap={3} direction="column">
      <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "baseline" }}>
        <Stack
          direction="row"
          sx={{ flexWrap: "wrap", alignItems: "baseline", fontFamily: "poppins", fontWeight: 700 }}
        >
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              mr: 2,
            }}
          >
            Scenarios
          </Typography>
          {scenarios?.length && (
            <Typography
              sx={{
                fontSize: { xs: 14, md: 16 },
                color: getDynamicColor("dark4"),
              }}
            >
              {scenarios?.length} scenario{scenarios?.length > 1 && "s"}
            </Typography>
          )}
        </Stack>
        {isOrgOwnerAdmin(defaultOrg) && (
          <Button
            variant="text"
            onClick={() => {
              const qp = new URLSearchParams({
                [OrgContentQueryParams.TAB]: OrgSettingsTabs.CUSTOMIZE_PRACTICE,
                [OrgContentQueryParams.SUBTAB]: CustomizePracticeTabEnum.Scenarios,
              });
              navigate({ pathname: WebServerInternalPath.ORG_CONTENT, search: qp.toString() });
            }}
          >
            Manage
          </Button>
        )}
      </Stack>
      {scenarios?.map((scenario) => (
        <ReadOnlyScenarioCard
          key={scenario.id}
          selectedOrgId={defaultOrg.id}
          scenario={scenario}
          persona={personasQuery?.data?.personas?.find((p) => p.id === scenario.personaIds[0])}
          personaLoading={personasQuery?.status === "pending"}
        />
      ))}
    </Stack>
  );
};
