export enum GlobalQueryParams {
  TIMESTAMP = "t",
}

export enum SpeechSummaryQueryParams {
  FOCUS_ANALYTIC = "analytic",
  FOCUS_COMMENT = "comment",
  SHARE_EMAIL = "share-email",
}

export enum VideoLibraryQueryParams {
  TAB = "tab",
  PAGE = "page",
}

export enum PracticeRecorderQueryParams {
  PROMPT = "prompt",
  SETTINGS = "settings",
  CUSTOMIZE_QUESTIONS = "customize-questions",
  CUSTOMIZE_AUDIENCE = "customize-audience",
  TOPIC = "topic",
  SCENARIO = "scenario", // NOTE (2024-09-11): This is used as a query param for playwright/tests/programs.spec.ts
  PERSONA = "persona",
}

export enum SettingsQueryParams {
  TAB = "tab",
  PRICING_MODAL_OPEN = "pricingModalOpen",
}

export enum DashboardQueryParams {
  DAY_RANGE = "day-range",
  EDIT_FOCUS_ANALYTICS = "edit-focus-analytics",
}

export enum PoodliQueryParams {
  TAB = "tab",
  SETTINGS = "settings",
}
export enum PoodliToggleViews {
  COACHING = "Coaching",
  DISCOVER = "Discover",
  CALENDAR = "Calendar",
  TALKING_POINTS = "Talking Points",
}

export enum OrgSettingsQueryParams {
  TAB = "tab",
  ORG = "org",
}

export enum HubsQueryParams {
  ACCEPT_INVITE = "acceptInvite",
  HUB_ID = "hubId",
  INVITE_ID = "inviteId",
  HUB_NAME = "hubName",
  TAB = "tab",
}

/**
 * Query params required to accept a hub invite.
 */
export const HubsInviteRequiredQueryParams = [
  HubsQueryParams.ACCEPT_INVITE,
  HubsQueryParams.HUB_ID,
  HubsQueryParams.INVITE_ID,
];

export enum OrgInviteQueryParams {
  ACCEPT_INVITE = "acceptInvite",
  ORG_ID = "orgId",
  OVERRIDE_ORG_ID = "overrideOrgId",
  INVITE_ID = "inviteId",
  HUB_ID = "hubId",
}

export enum ReferralProgramQueryParams {
  INDEED_HUID = "indeedHuid",
}

export enum AuthQueryParams {
  FIREBASE_PROVIDER_TEST = "sso",
  HUB_ID = "hubId",
  IGNORE_SSO = "ignoreSso",
  INVITE_ID = "inviteId",
  ORG_ID = "orgId",
  OT_AUTH_CODE = "ot-auth-code",
  V2 = "v2",
  WHERE = "where",
}

export enum OrgMembersQueryParams {
  INVITE_MEMBERS = "inviteMembers",
}

export enum OrgHubsQueryParams {
  SECTION = "section",
  GROUP_ID = "groupId",
  TAB = "tab",
  TABLE = "table",
}

export enum OrgProgramsQueryParams {
  SECTION = "section",
  PROGRAM_ID = "programId",
  TAB = "tab",
  USER_ID = "userId",
  PROGRAM_TAB = "programTab",
  SCENARIO_ID = "scenarioId",
}

export enum MyLearningQueryParams {
  TAB = "tab",
  SECTION = "section",
  PROGRAM_ID = "programId", // NOTE (2024-08-19): This is used as a query param for CIO email template CTA (see sendReminder in programHandlers.ts)
}

export enum OrgContentQueryParams {
  TAB = "tab",
  PROGRAM_ID = "programId",
  CREATE_SCENARIO = "create_scenario",
  SUBTAB = "subtab",
}

export enum BuilderQueryParams {
  SECTION = "section",
  TAB = "tab",
  PRACTICE = "practice",
}

/**
 * Query params related to integration activities
 */
export enum ActivityQueryParams {
  ACTIVITY_ID = "activityId",
  ORG_ID = "orgId",
  SCENARIO_ID = "scenarioId",
  SPEECH_SLUG = "speech",
}

/**
 * List of all enums defined in this file.
 * Used by tests to verify that enum values are compatible across enums.
 */
export const ALL_ENUMS = [
  ActivityQueryParams,
  AuthQueryParams,
  BuilderQueryParams,
  DashboardQueryParams,
  HubsQueryParams,
  MyLearningQueryParams,
  OrgContentQueryParams,
  OrgHubsQueryParams,
  OrgInviteQueryParams,
  OrgMembersQueryParams,
  OrgProgramsQueryParams,
  OrgSettingsQueryParams,
  PoodliQueryParams,
  PoodliToggleViews,
  PracticeRecorderQueryParams,
  ReferralProgramQueryParams,
  SettingsQueryParams,
  SpeechSummaryQueryParams,
  VideoLibraryQueryParams,
];
