// Components
import { Chip } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ProgramState } from "lib-fullstack/utils/enums";

type ProgramStatusProps = {
  state: ProgramState;
};

export const ProgramStatus = ({ state }: ProgramStatusProps): JSX.Element => {
  const getStatusColor = () => {
    switch (state) {
      case ProgramState.Published:
        return getDynamicColor("greenSuccessLight");
      case ProgramState.Draft:
        return getDynamicColor("purple1");
      case ProgramState.Archived:
        return getDynamicColor("dark2");
    }
  };
  return (
    <Chip
      label={state.toUpperCase()}
      sx={{
        background: getStatusColor(),
        minWidth: 80,
        height: 20,
        fontSize: "10px",
        fontWeight: 400,
        letterSpacing: "1px",
      }}
    />
  );
};
