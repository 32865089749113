import React from "react";

// UI Utilities/Functions
import { styled } from "@mui/material";
import { Alert, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ConstructionIcon from "@mui/icons-material/Construction";

export const OrientationAlertWrapper = styled(Modal)({
  width: "100%",
});

function WindowOrientationAlert(props: {
  onClose?: (MouseEvent?) => void;
  open: boolean;
  message: string;
}): React.ReactElement {
  return (
    <OrientationAlertWrapper onClose={props.onClose} open={props.open}>
      <Alert
        icon={<ConstructionIcon />}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              props.onClose();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {props.message}
      </Alert>
    </OrientationAlertWrapper>
  );
}

export default WindowOrientationAlert;
