import React from "react";

// Components
import { Box, Stack } from "@mui/material";

// Utils
import { TipGifDialog } from "./TipGifDialog";
import { MOBILE_TIP_HEIGHT } from "./TipsCarousel";
import { TipText } from "./TipText";
import { usePreventSwipeClick } from "hooks/useSwipePrevention";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { markTipInteracted, tipToContentMap } from "lib-frontend/utils/productTipsUtils";
import { ProductTip } from "lib-fullstack/db";
import { ProductTipEventWheres } from "lib-fullstack/utils/productAnalyticEvents";

type MobileTipProps = {
  tip: ProductTip;
  isInteracted: boolean;
  onCtaClick: (tip: ProductTip) => void;
  idx: number;
};

export const MobileTip = ({ tip, isInteracted, onCtaClick, idx }: MobileTipProps): JSX.Element => {
  const [showGifDialog, setShowGifDialog] = React.useState(false);

  const previewImgClick = () => {
    setShowGifDialog(true);
    markTipInteracted(tip);
    Instrumentation.logUsageTipGifPlayed(
      tipToContentMap[tip].header,
      idx + 1,
      tip,
      ProductTipEventWheres.WEBCLIENT_HOME
    );
  };
  const { handleMouseDown, handleMouseMove, handleMouseUp, handleClickIgnoreSwipe } =
    usePreventSwipeClick(previewImgClick);

  return (
    <Stack
      direction="column"
      sx={{ boxShadow: Y_SHADOWS.box_shadow_9, mx: 1, height: MOBILE_TIP_HEIGHT }}
      gap={1}
    >
      <Stack
        sx={{
          backgroundColor: getDynamicColor("purple3"),
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          p: 2,
          mx: 2,
          mt: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            color: getDynamicColor("light1"),
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            py: 0.5,
          }}
        >
          <video
            width="100%"
            height="100%"
            style={{
              maxHeight: "240px",
              cursor: "pointer",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onClick={handleClickIgnoreSwipe}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={tipToContentMap[tip].gifUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
        <TipGifDialog tip={tip} isOpen={showGifDialog} onClose={() => setShowGifDialog(false)} />
      </Stack>
      <TipText
        tip={tip}
        isInteracted={isInteracted}
        width="100%"
        pl={3}
        pr={3}
        onCtaClick={onCtaClick}
        idx={idx}
      />
    </Stack>
  );
};
