import firebase from "firebase/app";
import React from "react";

// Components
import { Button, CircularProgress, Grid, InputLabel, Stack, Typography } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { OrgDetailCardItemLabel } from "./AdminConfigOrgDetails";
import { UpdateOrgDetailCardProps } from "./UpdateOrgModal";
import { useValidateUserEmailSync, useValidateUserEmail } from "auth/utils/validator";
import {
  createOrgUserV2,
  listOrgUsersV2,
  updateOrgUserV2,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgRole } from "lib-fullstack/utils/enums";

export const UpdateOwnerForm = ({
  orgId,
  card,
  setRerenderKey,
  close,
}: UpdateOrgDetailCardProps): React.ReactElement => {
  const initialOwnerEmail =
    (card.items.find((item) => item.label === OrgDetailCardItemLabel.OWNER).data as string) || "";
  const initialAdminEmails = card.items.find((item) => item.label === OrgDetailCardItemLabel.ADMINS)
    .data as string[];
  const currentUserEmail = firebase.auth().currentUser?.email || "";
  const initialIsOrgAdmin = initialAdminEmails.includes(currentUserEmail);

  // Hooks
  const [ownerEmail, setOwnerEmail] = React.useState<string>(initialOwnerEmail);
  const [isOrgAdmin, setIsOrgAdmin] = React.useState<boolean>(initialIsOrgAdmin);
  const [adminEmails, setAdminEmails] = React.useState<string[]>([...initialAdminEmails]);

  const [loadingText, setLoadingText] = React.useState<string>("");
  const [errorText, setErrorText] = React.useState<string>("");

  const errors = {
    ownerEmail: useValidateUserEmailSync(ownerEmail, true).error,
  };

  console.log("errors", errors);

  const isFormValid = () => {
    return ![...Object.values(errors)].some((error) => !!error);
  };

  const toggleSetIsOrgAdmin = () => {
    if (isOrgAdmin) {
      setAdminEmails(adminEmails.filter((email) => email !== currentUserEmail));
      setIsOrgAdmin(false);
    } else {
      setAdminEmails([...adminEmails, currentUserEmail]);
      setIsOrgAdmin(true);
    }
  };

  const handleUpdateOrg = async (e) => {
    e.preventDefault();

    setErrorText("");
    try {
      setLoadingText(`Updating ${card.label}`);

      // Get current owners and admins.
      setLoadingText(`Getting current organization owners and admins...`);
      const orgMembers = await listOrgUsersV2(orgId, {
        start: "0",
        limit: "100",
        effective_roles: [OrgRole.OWNER, OrgRole.ADMIN].join(","),
      });
      console.log("orgMembers", orgMembers);

      if (initialOwnerEmail !== ownerEmail) {
        // Demote initial owner to admin.
        const initialOwner = orgMembers.users.find((user) => user.email === initialOwnerEmail);
        if (initialOwner) {
          setLoadingText("Setting current owner as admin...");
          await updateOrgUserV2(orgId, initialOwner.user_id, { role: OrgRole.ADMIN });
        }

        // Promote new owner.
        setLoadingText("Promoting new owner...");
        const userResult = await useValidateUserEmail(ownerEmail, true);
        if (userResult.error) {
          throw new Error(userResult.error);
        }
        const newOwner = orgMembers.users.find((user) => user.email === userResult.user.email);
        if (!newOwner) {
          console.log("Creating user in org as admin...");
          await createOrgUserV2(orgId, {
            emails: [userResult.user.email],
            org_role: OrgRole.ADMIN,
            hub_role: null,
            hub_ids: null,
            send_invite_email: true,
          });
        }
        // Make them the owner.
        console.log("Seting user to owner role...");
        await updateOrgUserV2(orgId, userResult.user.id, { role: OrgRole.OWNER });
      }

      if (initialIsOrgAdmin !== isOrgAdmin) {
        const currentUser = orgMembers.users.find((user) => user.email === currentUserEmail);
        if (isOrgAdmin) {
          // Make the current user an org admin.
          if (currentUser) {
            console.log("Setting you to admin role...");
            await updateOrgUserV2(orgId, currentUser.user_id, { role: OrgRole.ADMIN });
          } else {
            console.log("Creating you in org as admin...");
            await createOrgUserV2(orgId, {
              emails: [currentUserEmail],
              org_role: OrgRole.ADMIN,
              hub_role: null,
              hub_ids: null,
              send_invite_email: true,
            });
          }
        } else {
          // Remove the current user from the org admins.
          console.log("Removing you from the org...");
          await updateOrgUserV2(orgId, currentUser.user_id, { role: null });
        }
      }

      setRerenderKey((prevKey: number) => prevKey + 1);
      close();
    } catch (e) {
      console.log(`error updating ${card.label}: ${e}`);
      setErrorText(`Error Updating ${card.label}: ${e}`);
    }
    setLoadingText("");
  };

  return (
    <Stack width="100%" gap={2} p={3}>
      {errorText && (
        <Typography p={1} color="error" fontWeight={600} fontSize="12px">
          {errorText}
        </Typography>
      )}
      <form style={{ width: "100%" }} onSubmit={async (e) => await handleUpdateOrg(e)}>
        <Stack gap={3}>
          <Stack>
            <Grid container>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="owner-item-add"
                  sx={{
                    pb: 0.5,
                    ml: 2,
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Owner email
                </InputLabel>
              </Grid>
              <Grid item xs={9}>
                <YoodliTextfield
                  id="owner-item-add"
                  type="text"
                  placeholder="Update owner email"
                  required={false}
                  error={!!errors.ownerEmail}
                  helperText={errors.ownerEmail}
                  fullWidth
                  multiline={false}
                  value={ownerEmail}
                  onChange={(e) => setOwnerEmail(e.target.value)}
                  InputProps={{
                    sx: {
                      fontSize: "16px",
                      fontWeight: 500,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
        <Stack>
          <Button
            onClick={toggleSetIsOrgAdmin}
            variant="gradient"
            disabled={false}
            sx={{
              fontFamily: "poppins",
              fontSize: "16px",
              marginTop: 3,
              fontWeight: 700,
              "&:disabled": {
                color: getDynamicColor("light1"),
                background: getDynamicColor("dark4"),
              },
            }}
          >
            {isOrgAdmin ? "Remove me from org admins" : "Add me to org admins"}
          </Button>
        </Stack>

        <input type="submit" style={{ display: "none" }} />
      </form>
      <Button
        startIcon={
          loadingText.length > 0 ? (
            <CircularProgress
              size={20}
              sx={{
                color: getDynamicColor("dark1"),
              }}
            />
          ) : (
            <></>
          )
        }
        onClick={async (e) => {
          await handleUpdateOrg(e);
        }}
        variant="gradient"
        disabled={
          !isFormValid() ||
          !!loadingText ||
          (initialOwnerEmail.toLowerCase() === ownerEmail.toLowerCase() &&
            initialIsOrgAdmin === isOrgAdmin)
        }
        sx={{
          fontFamily: "poppins",
          fontSize: "16px",
          marginTop: 3,
          fontWeight: 700,
          "&:disabled": {
            color: getDynamicColor("light1"),
            background: getDynamicColor("dark4"),
          },
        }}
      >
        {loadingText.length == 0 ? `Update ${card.label}` : loadingText}
      </Button>
    </Stack>
  );
};
