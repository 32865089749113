// Utils
import { NavigateFunction } from "react-router-dom";
import { updateUserDocsForPoodli } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { PracticeRecorderQueryParams } from "lib-fullstack/utils/queryParams";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { PoodliDownloadedWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { getPrivateYoodliDownloadPath } from "utils/Utilities";

const envConfig = getClientEnvConfig();
export const COACH_DEMO_SPEECH_PATH = `/share/${envConfig.coachDemoSlug}`;
export const USER_DEMO_SPEECH_PATH = `/share/${envConfig.demoSlug}`;
export const downloadPoodli = (where: PoodliDownloadedWheres): void => {
  updateUserDocsForPoodli();
  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- no catch
  getPrivateYoodliDownloadPath()
    .then((path) => {
      window.location.href = path;
    })
    .catch((err) => {
      console.warn(`Error getting Private Yoodli download path for onboarding`, err);
    });

  Instrumentation.logPoodliDownload(where);
};

export const Q2_DATA = {
  INTERVIEW: [
    {
      title:
        "Practice a mock interview with Yoodli-generated questions, or input your own practice questions",
      image:
        "https://storage.googleapis.com/yoodli-public/onboarding-assets/graphic-interview1.webp",
    },
    {
      title: "Get instant feedback and suggestions to improve next time!",
      image:
        "https://storage.googleapis.com/yoodli-public/onboarding-assets/graphic-interview2.webp",
    },
    {
      cta: {
        title: "Interview preparation can be fun and judgment free!",
        button: {
          text: "I’m ready to rock my interview!",
          onClick: (navigate?: NavigateFunction): void => {
            navigate?.(WebServerExternalPath.PRACTICE_INTERVIEW, { replace: true });
          },
        },
      },
    },
  ],
  MEETINGS: [
    {
      title: "Practice any crucial conversation with Yoodli-generated follow up questions",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/meetings1.webp",
    },
    {
      title: "With Yoodli Voice, our AI technology speaks just like a human",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/meetings2.webp",
    },
    {
      title:
        "After your practice, see areas you can make improvements so you can ace that conversation!",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/meetings3.webp",
    },
    {
      cta: {
        title: "Conversation prep can be fun and judgement free!",
        button: {
          text: "I’m ready to ace my meeting!",
          onClick: (navigate?: NavigateFunction): void => {
            navigate?.(WebServerExternalPath.PRACTICE_CONVERSATION, { replace: true });
          },
        },
      },
    },
  ],
  SPEECH: [
    {
      title:
        "Record yourself practicing your speech. We’ll provide judgement-free analytics on your speaking",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/graphic-speech1.webp",
    },
    {
      title:
        "View suggestions on how to improve your speech and anticipate questions that your audience might ask",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/graphic-speech2.webp",
    },
    {
      cta: {
        title: "Public speaking can be fun and judgment free!",
        button: {
          text: "I’m ready to wow my audience!",
          onClick: (navigate: NavigateFunction): void => {
            navigate(WebServerExternalPath.PRACTICE_SPEECH, { replace: true });
          },
        },
      },
    },
  ],
  COACH: [
    {
      title: "Provide a premium experience with AI-powered data on your clients’ speaking",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/graphic-coach1.webp",
    },
    {
      title: "Create personas and scenarios for your clients to practice with",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/graphic-coach2.webp",
    },
    {
      title: "Manage and track their progress in a single place with Yoodli organizations",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/obq1coach-3.webp",
    },
    {
      cta: {
        title: "See what Yoodli can do for your clients!",
        button: {
          text: "Practice a presentation with AI",
          onClick: (navigate: NavigateFunction): void => {
            navigate(WebServerExternalPath.PRACTICE_SPEECH, { replace: true });
          },
        },
      },
    },
  ],
  SALES: [
    {
      title: "Roleplay with an AI buyer to book meetings and close more sales deals",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/sales1.webp",
    },
    {
      title: "Be prepared for anything with AI follow-up objections and counterpoints",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/sales2.webp",
    },
    {
      title: "Tighten up your pitch and deliver with impact using AI feedback",
      image: "https://storage.googleapis.com/yoodli-public/onboarding-assets/sales3.webp",
    },
    {
      cta: {
        title: "Practice with AI and enter every sales call with confidence",
        button: {
          text: "I’m ready to practice",
          onClick: (navigate?: NavigateFunction): void => {
            navigate(
              {
                pathname: WebServerExternalPath.PRACTICE_CONVERSATION,
                search: new URLSearchParams({
                  [PracticeRecorderQueryParams.SCENARIO]: "outbound_customer_discovery",
                }).toString(),
              },
              { replace: true }
            );
          },
        },
      },
    },
  ],
};
