import React from "react";

// Components
import { Box, Stack, Typography } from "@mui/material";
import ZoomControlPanel from "components/ZoomControlPanel/ZoomControlPanel";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";

// Utils
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";

const ZoodliCard = (): JSX.Element => {
  const { userInCoachOrganization } = React.useContext(UserOrgContext);
  const modalContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Stack gap={2}>
      <Typography
        variant="poppins"
        component="h2"
        sx={{
          fontSize: { xs: 18, sm: 20 },
          fontWeight: 600,
        }}
      >
        {userInCoachOrganization ? "Record your video calls" : "Yoodli for video calls"}
      </Typography>
      <Box
        ref={modalContainerRef}
        sx={{
          position: "relative",
          border: `1px solid ${getDynamicColor("dark2")}`,
          boxShadow: Y_SHADOWS.box_shadow_1,
          borderRadius: "6px",
          p: 3,
        }}
      >
        <ZoomControlPanel modalContainerRef={modalContainerRef} />
      </Box>
    </Stack>
  );
};

export default ZoodliCard;
