// Utils
import { db } from "..";
import { GraphData } from "lib-fullstack/utils/aggregate";
// use runtypes if you want to do run-time validation
import {
  Optional,
  Record,
  Static,
  String,
  Number,
  Boolean,
  Array,
  Intersect,
  Unknown,
  Dictionary,
  Union,
  Null,
  Array as RTArray,
} from "runtypes";
import { NullableOptional } from "lib-fullstack/utils/runtypesHelpers";
import { SpeechPrompt, ResponseSection } from "lib-fullstack/db";
import {
  ApiErrorCodeType,
  RTPlanStepType,
  RTProgramRecordState,
  RTPlanStepState,
  RTHumanEvaluationState,
  RTAnalyticProcessingState,
  RecordingSupportedLanguage,
} from "lib-fullstack/utils/enums";
import { RTStringEnum, RTStringEnumKey } from "lib-fullstack/utils/runtypesHelpers";

export interface SiteIDRequest {
  siteId: string;
}

export const AddAllowedEmailsRequest = Record({
  siteId: String,
  triggerInviteEmails: Boolean,
  emails: Array(String),
  emailTemplate: Optional(String),
});
export type AddAllowedEmailsRequest = Static<typeof AddAllowedEmailsRequest>;

// Referrals
export const SignedUpUser = Record({ email: String, uid: String });
export type SignedUpUser = Static<typeof SignedUpUser>;

export interface CountReferralResponse {
  count: number;
}

export const RequestAccessRequest = Record({
  speechAuthorId: String,
  currentUserName: String,
  returnUrl: String,
  siteId: String,
});
export type RequestAccessRequest = Static<typeof RequestAccessRequest>;

export const RemoveAllowedEmailRequest = Record({
  siteId: String,
  email: String,
});
export type RemoveAllowedEmailRequest = Static<typeof RemoveAllowedEmailRequest>;

export const InterviewPromptId = Record({
  id: Optional(String),
});
export type InterviewPromptId = Static<typeof InterviewPromptId>;

export const PromptWithID = Intersect(db.InterviewQuestion, InterviewPromptId);
export type PromptWithID = Static<typeof PromptWithID>;

export const SetInterviewPromptRequest = Record({
  siteId: String,
  interviewPrompts: Array(PromptWithID),
});
export type SetInterviewPromptRequest = Static<typeof SetInterviewPromptRequest>;

export const DeleteInterviewPromptRequest = Record({
  siteId: String,
  ids: Array(String),
});
export type DeleteInterviewPromptRequest = Static<typeof DeleteInterviewPromptRequest>;

export interface AddSelfAsCollaboratorRequest extends SiteIDRequest {
  speechRefPath: string;
}

export interface AddRecallBotRequest {
  userRefPath: string;
  zoomUrl: string;
  joinMethod: db.RecallBotJoinMethod;
  language: RecordingSupportedLanguage;
}

export interface RemoveRecallBotRequest {
  botId: string;
}

export const AddCalendarRequest = Record({
  authCode: String,
  siteId: String,
  mode: RTStringEnum(db.CalendarMode),
  redirectUri: Optional(String),
});
export type AddCalendarRequest = Static<typeof AddCalendarRequest>;

export const SetCredentialsRequest = Record({
  authCode: String,
  redirectUri: Optional(String),
});
export type SetCredentialsRequest = Static<typeof SetCredentialsRequest>;

export const ZoomAppOAuthFinalizeRequest = Record({
  siteId: String,
  state: Optional(String),
  code: String,
  verifier: String,
});
export type ZoomAppOAuthFinalizeRequest = Static<typeof ZoomAppOAuthFinalizeRequest>;

export const ZoomAppOAuthFinalizeResponse = Record({
  deeplinkUrl: String,
});
export type ZoomAppOAuthFinalizeResponse = Static<typeof ZoomAppOAuthFinalizeResponse>;

export const ZoomAppOAuthInstallLinkRequest = Record({
  siteId: String,
});
export type ZoomAppOAuthInstallLinkRequest = Static<typeof ZoomAppOAuthFinalizeRequest>;

export const ZoomAppOAuthInstallLinkResponse = Record({
  state: String,
  verifier: String,
  url: String,
});
export type ZoomAppOAuthInstallLinkResponse = Static<typeof ZoomAppOAuthInstallLinkResponse>;

export const SyncCalendarEventsRequest = Record({
  siteId: String,
});
export type SyncCalendarEventsRequest = Static<typeof SyncCalendarEventsRequest>;

export const SyncCalEventToTaskRequest = Record({
  eventRefPath: String,
  defaultJoin: db.BotAutoJoinPreference,
  mode: RTStringEnum(db.CalendarMode),
});
export type SyncCalEventToTaskRequest = Static<typeof SyncCalEventToTaskRequest>;

export const DeleteCalendarRequest = Record({
  calendarRefPath: String,
});
export type DeleteCalendarRequest = Static<typeof DeleteCalendarRequest>;

export const ChangeDefaultJoinRequest = Record({
  calendarRefPath: String,
  defaultJoin: db.BotAutoJoinPreference,
});
export type ChangeDefaultJoinRequest = Static<typeof ChangeDefaultJoinRequest>;

export const ChangeCalendarModeRequest = Record({
  calendarRefPath: String,
  mode: RTStringEnum(db.CalendarMode),
});
export type ChangeCalendarModeRequest = Static<typeof ChangeCalendarModeRequest>;

export type SsoSigninWithJWTRequest = {
  siteId: string;
  source?: undefined | "kornferryadvance";
  jwt?: string;
};

export type ToastmastersSsoSigninWithJWTRequest = SsoSigninWithJWTRequest & {
  createNewUser: boolean;
  homeTimezone: string;
  fullname?: string;
  email?: string;
  referralSlug?: string;
};

export type KornFerryAdvanceSsoSigninWithJWTResponse = {
  redirectUrl: string;
};

export type ToastmastersSsoSigninWithJWTResponse =
  | {
      success: true;
      customSigninToken: string;
      mustConfirmNewUser: false | undefined;
    }
  | {
      success: false;
      failureReason?: string;
      mustConfirmNewUser?: false | undefined;
      defaultFullname: string;
      defaultEmail: string;
    }
  | {
      success: false;
      failureReason?: string;
      mustConfirmNewUser: true;
      defaultFullname: string;
      defaultEmail: string;
    };

export interface createAuthTokenRequest {
  code: string;
  idToken: string;
}

// From historical reason, this needs to be set as data field of an outer object
export const CreateSpeechRequestData = Record({
  recordedByVersion: String,
  name: String,
  type: db.SpeechSource,
  subType: Optional(db.SpeechLayout),
  audioOnly: Boolean,
  shouldDiarize: Optional(Boolean),
  mirrored: Optional(Boolean),
  isFTUX: Optional(Boolean),
  event: Optional(db.CalendarEvent),
  personaData: Optional(db.PublicPersonaData),
  scenarioPath: Optional(String),
  scenarioType: Optional(db.ScenarioTypeId),
  scenarioTemplateSubType: Optional(db.RTScenarioTemplateSubType),
  language: Optional(RecordingSupportedLanguage),

  // TODO: #10848: After Poodli 1.40.0 is widely adopted, remove these fields
  // After refactoring, backend fills those fields in Speech do by its own logic
  // and the values in API request are ignored.
  recordingStartDate: Optional(String),
  linkSharing: Optional(Boolean),
  commentsVisibility: Optional(Boolean),
  asyncTranscriptState: Optional(RTAnalyticProcessingState),

  /**
   * Activity ID associated with this speech, if any.
   */
  activityId: Optional(String),
});
export type CreateSpeechRequestData = Static<typeof CreateSpeechRequestData>;

export const CreateSpeechResponse = Record({
  id: String,
  slug: String,
  path: String,
});
export type CreateSpeechResponse = Static<typeof CreateSpeechResponse>;

/**
 * Speeches signed downloadable URL response
 */
export const DownloadSpeechResponse = Record({
  url: String,
});
export type DownloadSpeechResponse = Static<typeof DownloadSpeechResponse>;

export enum UploadFileType {
  VIDEO = "video",
  LOG = "log",
  TRANSCRIPT_HISTORY = "transcript_history",
  VOICE_DETECTION = "voice_detection",
  VOICE_DETECTION_VERBOSE = "voice_detection_verbose",
  SCREENSHOT_HISTORY = "screenshot_history",
}

export const UploadSpeechRequest = Record({
  type: RTStringEnum(UploadFileType),
  // Used only if type is video
  fileExtension: Optional(String),
});
export type UploadSpeechRequest = Static<typeof UploadSpeechRequest>;

export const UploadSpeechResponse = Record({
  url: String,
  gsUrl: String,
});
export type UploadSpeechResponse = Static<typeof UploadSpeechResponse>;

export const CreateCommentRequest = Record({
  // This field is a hack to workaround a situation where we don't have
  // index for speech collection group by ID.
  // REST API path does not include user ID and the comment may be
  // made to a speech that does not belong to the commenter (caller).
  speechOwnerId: String,
  data: db.SpeechComment,
});
export type CreateCommentRequest = Static<typeof CreateCommentRequest>;

export const CreateCommentResponse = Record({
  id: String,
  path: String,
});
export type CreateCommentResponse = Static<typeof CreateCommentResponse>;

export const SpeechStorageResponse = Record({
  uploaded: Union(String, Null),
  transcoded: Union(String, Null),
  audio: Union(String, Null),
});
export type SpeechStorageResponse = Static<typeof SpeechStorageResponse>;

export const SpeechIdTuple = Record({
  ownerId: String,
  speechId: String,
});
export type SpeechIdTuple = Static<typeof SpeechIdTuple>;

export const GetThumbnailsRequest = Record({
  speeches: Array(SpeechIdTuple),
});
export type GetThumbnailsRequest = Static<typeof GetThumbnailsRequest>;

export const GetThumbnailsResponse = Record({
  urls: Array(Union(String, Null)),
});
export type GetThumbnailsResponse = Static<typeof GetThumbnailsResponse>;

export const UpdateSpeechShareRequest = Record({
  link_sharing: Optional(Boolean),
  org_link_sharing: Optional(Boolean),
  add_emails: Optional(Array(String)),
  remove_emails: Optional(Array(String)),
  add_evaluators: Optional(Boolean),
  invite_message: Optional(String),
});
export type UpdateSpeechShareRequest = Static<typeof UpdateSpeechShareRequest>;

export type DashboardRequest = {
  timezone: string;
  startTime: string;
  endTime: string;
  analytics: Exclude<
    db.AggregateAnalyticTypes | db.UnitCountAnalyticEnum | string,
    "countAnalytics"
  >[];
  // #10484 Make this non-optional after Poodli 1.40.0 is widely adopted.
  targetUserId?: string | null;
  tagFilter?: { key: db.TagType; value: string | undefined }; // undefined is used to return all speeches without a tag for the given key
};

export type MostSentenceStarter = {
  word: string;
  count: number;
  totalSentences: number;
  pct: number;
};

export type PoodliAlertCounts = {
  [k in db.PoodliAlertStatuses]: number;
} & { total: number };

export type CorrelationResult = {
  categoryName: string;
  analytic: db.AggregateAnalyticTypes;
  difference: number;
  average: number;
  meetsThreshold: boolean;
  measure: string;
  categoryValue: string;
  differenceNStdDevs: number;
  score: number;
};

export type DashboardSuccess = {
  analytics: {
    [key: string]: {
      graphData: GraphData[];
      counted: string;
      goalName?: string;
      relevantSlugs?: string[];
    };
  };
  celeb: { speech: db.SampleSpeech; hadAbove: boolean } | undefined; // may be undefined if the speech doesn't have a name, which shouldn't happen but is allowed by the db types
  mostSentenceStarter: MostSentenceStarter | undefined;
  poodliAlertCounts: PoodliAlertCounts | undefined;
  topCorrelation: CorrelationResult | undefined;
  ok: boolean;
};

export enum DashboardErrorType {
  NO_DATA_FOR_RANGE = "no data for this range",
}
export type DashboardError = { error: DashboardErrorType; ok: boolean };

export type DashboardResponse = DashboardSuccess | DashboardError;

export const SendVerificationEmailRequest = Record({
  continueUrl: String,
  siteId: String,
});
export type SendVerificationEmailRequest = Static<typeof SendVerificationEmailRequest>;

export interface SendVerificationEmailResponse {
  success: boolean;
  error?: string;
}

export const SendPasswordResetEmailRequest = Record({
  email: String,
  siteId: String,
});
export type SendPasswordResetEmailRequest = Static<typeof SendPasswordResetEmailRequest>;

// TODO: #10848 Delete this after Poodli 1.40.0 is widely adopted.
export const SpeechStateRequest = Record({
  saved: Optional(Boolean),
  uploadState: Optional(RTStringEnum(db.UploadState)),
  gcsUploaded: Optional(String),
  totalTimeS: Optional(Number),
});
export type SpeechStateRequest = Static<typeof SpeechStateRequest>;

export const UpdateSpeechStatesRequest = Record({
  saved: Optional(Boolean),
  upload_state: Optional(RTStringEnum(db.UploadState)),
  gcs_uploaded: Optional(String),
  total_time_sec: Optional(Number),
  recorded_date: Optional(String),
  sync_rev_job_id: NullableOptional(String),
  rev_sync_was_interrupted: Optional(Boolean),
  prompts: Optional(RTArray(SpeechPrompt)),
  response_sections: Optional(RTArray(ResponseSection)),
});
export type UpdateSpeechStatesRequest = Static<typeof UpdateSpeechStatesRequest>;

export enum GetSpeechField {
  PROGRAM = "program",
}
export const GetSpeechFieldType = RTStringEnum(GetSpeechField);

export const GetSpeechQueryParams = Record({
  fields: Optional(String), // comma separated list of GetSpeechFieldType
});
export type GetSpeechQueryParams = Static<typeof GetSpeechQueryParams>;

export const SpeechProgramInfo = Record({
  pending_score: Boolean,
  human_evaluation_state: RTHumanEvaluationState,
  shared_to_evaluators: Boolean,
  id: Union(String, Null),
  state: Optional(RTProgramRecordState),
  completion_date: Optional(Union(String, Null)),
  completed_by_this_speech: Optional(Boolean),
  plan_step: Optional(
    Record({
      type: RTPlanStepType,
      state: RTPlanStepState,
      completion_date: Union(String, Null),
      completed_by_this_speech: Boolean,
      scenario_id: String,
      completion_score: Number,
      completion_attempts: Number,
      completion_by_email: Boolean,
      max_score: Number,
      count_attempts: Number,
      share_by_email: Boolean,
      has_custom_weights: Boolean,
    })
  ),
  next_plan_step: Optional(
    Record({
      type: RTPlanStepType,
      scenario_id: String,
    })
  ),
  evaluator_emails: Optional(Array(String)),
});
export type SpeechProgramInfo = Static<typeof SpeechProgramInfo>;

export const GetSpeechResponse = Record({
  id: String,
  slug: String,
  name: String,
  start_date: String,
  /** If duration is unavailable on database with some reason, total_time_sec is set as 0 */
  total_time_sec: Number,
  score: Optional(Union(Number, Null)),
  program: Optional(SpeechProgramInfo),
});
export type GetSpeechResponse = Static<typeof GetSpeechResponse>;

/**
 * Standardized error responses, used by apiErrorHandler.ts
 */
export const ErrorResponse = Record({
  error: String,
  status: Number, // http status code
  code: Optional(ApiErrorCodeType),
  extra: Optional(Unknown),
});
export type ErrorResponse = Static<typeof ErrorResponse>;

export enum Progress {
  IMPROVING = "improving",
  REGRESSING = "regressing",
  NO_CHANGE = "no-change",
}

export const ReportCardRequest = Record({
  startTime: String,
  endTime: String,
});
export type ReportCardRequest = Static<typeof ReportCardRequest>;

export const TopCorrelationResult = Record({
  categoryName: String,
  analytic: db.AggregateAnalyticTypes,
  difference: Number,
  average: Number,
  measure: String,
  categoryValue: String,
  differenceNStdDevs: Number,
  score: Number,
});
export type TopCorrelationResult = Static<typeof TopCorrelationResult>;

export const SkillSnapshotResult = Dictionary(
  Record({
    average: Number,
    measure: String,
    meetsThreshold: Boolean,
  }),
  RTStringEnumKey(db.AggregateAnalyticEnum)
);
export type SkillSnapshotResult = Static<typeof SkillSnapshotResult>;

export const OccurrenceCountResult = Dictionary(
  Record({
    countSpeeches: Number,
    countDays: Number,
  }),
  String
);
export type OccurrenceCountResult = Static<typeof OccurrenceCountResult>;

export const MostCommonWord = Dictionary(
  Record({
    word: String,
    count: Number,
  }),
  RTStringEnumKey(db.AggregateAnalyticEnum)
);
export type MostCommonWord = Static<typeof MostCommonWord>;

export const AnalyticBeforeAfterScore = Record({
  before: Number,
  after: Number,
  difference: Number,
  score: Number,
  measure: String,
  progress: RTStringEnum(Progress),
});
export type AnalyticBeforeAfterScore = Static<typeof AnalyticBeforeAfterScore>;

export const BeforeAfterResult = Dictionary(
  AnalyticBeforeAfterScore,
  RTStringEnumKey(db.AggregateAnalyticEnum)
);
export type BeforeAfterResult = Static<typeof BeforeAfterResult>;

export const ReportCardResponse = Record({
  metric_correlation: Optional(TopCorrelationResult),
  before_after: Optional(BeforeAfterResult),
  skill_snapshot: Optional(SkillSnapshotResult),
  occurrence_count: Optional(OccurrenceCountResult),
  most_common_word: MostCommonWord,
});
export type ReportCardResponse = Static<typeof ReportCardResponse>;

export enum GetUserFieldType {
  SHOW_ORG_ADMIN_UX = "show_org_admin_ux",
  PRESENTATION_FTUX = "presentation_ftux",
  FEATURE_FLAGS = "feature_flags",
  SIGN_UP_NOTICE = "sign_up_notice",
  DEFAULT_ORG_ID = "default_org_id",
  DEFAULT_ORG_LOGO_URL = "default_org_logo_url",
}

export const UserFeatureFlags = Record({
  presentation_enabled: Optional(Boolean),
  roleplay_enabled: Optional(Boolean),
  interview_enabled: Optional(Boolean),
  zoodli_for_users_enabled: Optional(Boolean),
  desktop_app_enabled: Optional(Boolean),
  member_builder_enabled: Optional(Boolean),
});

export type UserFeatureFlags = Static<typeof UserFeatureFlags>;

export const GetUserQueryParams = Record({
  fields: String, // comma separated list of GetUserFieldType
});
export type GetUserQueryParams = Static<typeof GetUserQueryParams>;

export const GetUserResponse = Record({
  id: String,
  show_org_admin_ux: Optional(Boolean),
  presentation_ftux: Optional(String),
  sign_up_notice: Optional(String),
  default_org_id: Optional(Union(String, Null)),
  default_org_logo_url: Optional(Union(String, Null)),
  default_org_secondary_logo_url: Optional(Union(String, Null)),
  feature_flags: Optional(UserFeatureFlags),
});
export type GetUserResponse = Static<typeof GetUserResponse>;

export const PatchUserRequest = Record({
  default_org_id: Optional(Union(String, Null)),
  date_last_activity: Optional(String),
});
export type PatchUserRequest = Static<typeof PatchUserRequest>;

export const CreateSupportMessageRequest = Record({
  name: String,
  email: String,
  subject: String,
  message: String,
});
export type CreateSupportMessageRequest = Static<typeof CreateSupportMessageRequest>;

export const CreateSupportMessageResponse = Record({
  id: String,
});
export type CreateSupportMessageResponse = Static<typeof CreateSupportMessageResponse>;

export const ScreenshotUploadResponse = Record({
  url: String,
  fileUrl: String,
});
export type ScreenshotUploadResponse = Static<typeof ScreenshotUploadResponse>;
