import React from "react";

// Components
import { Box, Stack } from "@mui/material";

// Utils
import { INTERVIEW_BANKS_QUERY_KEY } from "../ManageContent/OrgManageContent";
import { OrgLoading } from "../OrgLoading";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  getHubContentAdminViewV2,
  listHubInterviewBanks,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { AvailableContentTabEnum, AvailableContentTabLabels } from "lib-frontend/utils/orgUtils";
import {
  CustomizePracticeTabSelector,
  TabSelectorType,
} from "../ManageContent/CustomizePractice/CustomizePracticeTabSelector";
import { VideosAndCoursesTab } from "./VideosAndCoursesTab";
import { InterviewBanksTab } from "./InterviewBanksTab";
import { ScenariosTab } from "./ScenariosTab";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";

export const VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY = "videosAndCourses";

export default function AvailableContent({ hubId }: { hubId: string }): JSX.Element {
  const userOrgContext = React.useContext(UserOrgContext);
  const isSmallScreen = useIsSmallScreen();

  const tabs = Object.values(AvailableContentTabEnum);

  const [activeTab, setActiveTab] = React.useState<TabSelectorType>(
    AvailableContentTabEnum.Scenarios
  );

  const videosAndCoursesQueryResult = useApiQuery({
    queryKey: [VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY, userOrgContext.orgId, hubId],
    queryFn: async () => getHubContentAdminViewV2(userOrgContext.orgId, hubId),
    enabled: !userOrgContext.defaultOrgLoading,
  });

  const interviewBanksQueryResult = useApiQuery({
    queryKey: [INTERVIEW_BANKS_QUERY_KEY, hubId],
    queryFn: async () => listHubInterviewBanks(hubId),
    enabled: !userOrgContext.defaultOrgLoading,
  });

  const loading = videosAndCoursesQueryResult.isPending || interviewBanksQueryResult.isPending;

  if (loading) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: getDynamicColor("light1"),
        }}
      >
        <OrgLoading prompts={["Loading group content..."]} bgColor={getDynamicColor("light1")} />;
      </Stack>
    );
  }

  const renderCoursesAndVideosTab = () => (
    <VideosAndCoursesTab videosAndCourses={videosAndCoursesQueryResult.data} />
  );

  const renderScenariosTab = () => <ScenariosTab groupId={hubId} />;

  const renderInterviewBanksTab = () => (
    <InterviewBanksTab interviewBanks={interviewBanksQueryResult.data} />
  );

  const renderTabSelector = () => {
    if (isSmallScreen) {
      return (
        <YoodliSelect
          MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "right" } }}
          value={activeTab}
          onChange={(e) => {
            setActiveTab(e.target.value as TabSelectorType);
          }}
          options={Object.entries(AvailableContentTabEnum).map(([_, value]) => {
            return { value: value, label: AvailableContentTabLabels[value] };
          })}
          sx={{
            background: getDynamicColor("light1"),
            border: `1px solid ${getDynamicColor("primary")}`,
            minWidth: { xs: "fit-content", md: 200 },
          }}
          inputProps={{
            sx: {
              ".selected": {
                p: 0,
                fontWeight: 600,
                ".label": {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
              p: 1.5,
            },
          }}
        />
      );
    }
    return (
      <CustomizePracticeTabSelector tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
    );
  };

  return (
    <>
      <Stack
        gap={4}
        sx={{
          p: { xs: 3, md: 4, lg: 6 },
        }}
      >
        {renderTabSelector()}
        {tabs.map((tab) => {
          return (
            <Box
              key={tab}
              sx={{
                display: activeTab === tab ? "initial" : "none",
              }}
            >
              {tab === AvailableContentTabEnum.CoursesAndVideos && renderCoursesAndVideosTab()}
              {tab === AvailableContentTabEnum.Scenarios && renderScenariosTab()}
              {tab === AvailableContentTabEnum.InterviewBanks && renderInterviewBanksTab()}
            </Box>
          );
        })}
      </Stack>
    </>
  );
}
