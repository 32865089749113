// Utils
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

/**
 * Enum indicating the type of the activity. Used for casting and type checking at runtime.
 */
export enum ActivityTypeEnum {
  /**
   * The activity represents an Accenture 1.0 scenario role-playing learner activity.
   */
  Accenture_1_0_Scenario = "accenture_1_0_scenario",
  /**
   * The activity represents an Accenture 1.0 speech summary activity.
   */
  Accenture_1_0_SpeechSummary = "accenture_1_0_speech_summary",
  /**
   * The activity represents an LTI 1.1 scenario role-playing learner activity.
   */
  LTI_1_1_Scenario = "lti_1_1_scenario",
  /**
   * The activity represents an LTI 1.3 scenario role-playing learner activity.
   */
  LTI_1_3_Scenario = "lti_1_3_scenario",
  /**
   * The activity represents a SCORM 1.2 scenario role-playing learner activity.
   */
  SCORM_1_2_Scenario = "scorm_1_2_scenario",
  /**
   * Test activity type.
   */
  Test = "test",
}

/**
 * Enum indicating the type of the activity. Used for casting and type checking
 * at runtime.
 */
export const ActivityType = RTStringEnum(ActivityTypeEnum);
