import { db } from "lib-fullstack";
import { DateTime } from "luxon";
import React from "react";
// Components
import { Stack, Box, Typography } from "@mui/material";

// Utils
import CalendarDate from "./CalendarDate";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { animatedEllipsisStyles } from "lib-frontend/utils/StyleUtils";
// Utils
import { isToday, isTomorrow } from "lib-fullstack/utils/dateUtils";

export default function CalendarEvents({
  scrollableClass,
  dbEvents,
  eventsLoading,
  defaultJoin,
  currentCalendarMode,
  setScrolled,
  setLoading,
  isPoodli,
  className,
}: {
  scrollableClass?: string;
  dbEvents: db.Doc<db.CalendarEvent>[];
  eventsLoading?: boolean;
  defaultJoin: db.BotAutoJoinPreference;
  currentCalendarMode: db.CalendarMode;
  setScrolled?: (val: boolean) => void;
  setLoading: (val: boolean) => void;
  isPoodli?: boolean;
  className?: string;
}): JSX.Element {
  const calendarScrollableRef = React.useRef(null);
  React.useEffect(() => {
    const onCalendarScroll = (e) => {
      setScrolled(e.target.scrollTop > 0);
    };
    if (calendarScrollableRef.current && setScrolled) {
      calendarScrollableRef?.current?.addEventListener("scroll", onCalendarScroll);
      return () => {
        calendarScrollableRef?.current?.removeEventListener("scroll", onCalendarScroll);
      };
    }
  }, [calendarScrollableRef]);
  const events = dbEvents
    ? dbEvents
        .filter((event) => {
          return Date.parse(event?.data.endTime) > new Date().getTime();
        })
        .sort((a, b) => Date.parse(a?.data.startTime) - Date.parse(b?.data.startTime))
    : [];
  // Group calendar events by day
  const eventMap = {};
  if (dbEvents) {
    for (const event of events) {
      const options = {
        month: "long",
        day: "numeric",
        weekday: "long",
        timeZone: DateTime.local().zoneName,
      } as const;
      let key = new Date(event?.data.startTime).toLocaleDateString(undefined, options);
      if (isToday(new Date(event?.data.startTime))) {
        key = `Today, ${key.split(", ")[1]}`;
      } else if (isTomorrow(new Date(event?.data.startTime))) {
        key = `Tomorrow, ${key.split(", ")[1]}`;
      }
      if (!Object.keys(eventMap).includes(key)) {
        eventMap[key] = [];
      }

      eventMap[key].push(event);
    }
  }

  if (eventsLoading) {
    return (
      <Box textAlign="center" pt={2}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            ...animatedEllipsisStyles,
          }}
        >
          Loading Events
        </Typography>
      </Box>
    );
  }

  return Object.keys(eventMap).length ? (
    <Stack
      className={`${className ?? ""} ${scrollableClass}`}
      ref={calendarScrollableRef}
      gap={4}
      position="relative"
      pt={1}
      pb={8}
      width="100%"
      alignItems="center"
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {isPoodli && (
        <Typography
          sx={{
            fontSize: 12,
            textAlign: "center",
            width: "100%",
            mt: 1,
            mb: -2,
          }}
        >
          Choose which meetings you want live coaching on 🎉
        </Typography>
      )}
      {Object.keys(eventMap).map((key: string) => (
        <CalendarDate
          key={key}
          isPoodli={isPoodli}
          isToday={key.includes("Today")}
          date={key}
          events={eventMap[key]}
          defaultJoin={defaultJoin}
          mode={currentCalendarMode}
          setLoading={setLoading}
        />
      ))}
    </Stack>
  ) : (
    <Box textAlign="center" pt={2}>
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          ...animatedEllipsisStyles,
        }}
      >
        No upcoming events
      </Typography>
    </Box>
  );
}
