import React from "react";

// Components
import { Stack, Typography, Divider, Button, CircularProgress } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { DeleteModalType } from "utils/Enums";

const CONFIRMATION_WORD = "DELETE";

type DeleteModalProps = {
  type: DeleteModalType;
  currentName: string;
  closeModal: () => void;
  handleDelete: UseMutateAsyncFunction<void, Error, void>;
};

export default function DeleteModal({
  type,
  currentName,
  closeModal,
  handleDelete,
}: DeleteModalProps): JSX.Element {
  const [confirmation, setConfirmation] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>(undefined);

  const doDelete = async () => {
    try {
      setError(undefined);
      setLoading(true);
      await handleDelete();
      closeModal();
    } catch (err) {
      console.log("Error in delete operation", err);
      setError("An unexpected error occurred!");
    } finally {
      setLoading(false);
    }
  };

  const capitalizeEveryWord = (inputString: string): string => {
    return inputString
      .split(" ") // Split the input string into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back into a single string
  };

  return (
    <>
      <Stack width="100%">
        <Typography
          color={getDynamicColor("redError")}
          fontFamily="poppins"
          fontSize="16px"
          fontWeight={600}
        >
          Delete {capitalizeEveryWord(type)}
        </Typography>
        <Typography
          sx={{
            color: getDynamicColor("redError"),
            fontFamily: "poppins",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          {currentName}
        </Typography>
        <Divider sx={{ width: "100%", backgroundColor: getDynamicColor("dark4"), mt: 2 }} />
      </Stack>
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack gap={5} alignItems="center">
          {error && (
            <Typography color={getDynamicColor("redError")} fontSize="14px" fontWeight={700}>
              {error}
            </Typography>
          )}
          <Stack
            color={getDynamicColor("redError")}
            fontFamily="poppins"
            fontSize="16px"
            fontWeight={500}
            width="min(350px, 100%)"
            px={2}
            gap={3}
          >
            <Typography>Type “DELETE” to confirm deletion of this {type}.</Typography>
            <Typography>This action is not reversible.</Typography>
          </Stack>
          <Stack
            gap={2}
            direction={{ xs: "column", md: "row" }}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <YoodliTextfield
              value={confirmation}
              onChange={(e) => setConfirmation(e.target.value)}
              sx={{
                width: "min(278px, 100%)",
                color: getDynamicColor("redError"),
                border: `1px solid ${getDynamicColor("redError")}`,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              inputProps={{
                sx: {
                  color: getDynamicColor("redError"),
                },
              }}
            />
            <Button
              onClick={doDelete}
              disabled={confirmation !== CONFIRMATION_WORD}
              sx={{
                borderRadius: "4px",
                background: getDynamicColor("redError"),
                color: getDynamicColor("light1"),
                fontSize: "16px",
                fontFamily: "poppins",
                fontWeight: 700,
                px: 5.5,
                py: 1.5,
                "&:hover": {
                  background: getDynamicColor("redErrorDark"),
                },
                "&:disabled": {
                  background: getDynamicColor("redErrorDark", 0.5),
                  color: getDynamicColor("light1"),
                },
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
}
