import { useEffect } from "react";

type ExternalScriptProps = {
  id: string;
  type: string;
  src: string;
};

export default function useExternalScript({
  id,
  type = "text/javascript",
  src,
}: ExternalScriptProps): void {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", src);
    script.setAttribute("id", id);
    script.setAttribute("type", type);
    if (!head.querySelector(`#${id}`)) {
      head.appendChild(script);
    }
  }, [src]);
}
