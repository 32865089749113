// Components
import { Box, Stack } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type AuthImageProps = {
  lightBackground: boolean;
  image: JSX.Element;
};
export default function AuthImage({ lightBackground, image }: AuthImageProps): JSX.Element {
  return (
    <Box
      width="100%"
      height="100%"
      minHeight="100vh"
      sx={{ backgroundColor: getDynamicColor(lightBackground ? "light2" : "purple3") }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        gap={4}
        sx={{
          textAlign: "center",
          alignItems: "center",
          p: 2.5,

          maxWidth: "500px",
        }}
      >
        {image}
      </Stack>
    </Box>
  );
}
