import React from "react";
import { db } from "lib-fullstack";

// Components
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { ReactComponent as AdmitGraphic } from "images/graphics/graphic-admit.svg";
import RecordingDurationWarning from "lib-frontend/components/RecordingDurationWarning";

const JoiningMeeting = (props: {
  handleStop: () => void;
  setRecordingComplete: (val: boolean) => void;
  status: db.RecallBotStatus;
}): JSX.Element => {
  const quitYoodliBot = async () => {
    await props.handleStop();
    props.setRecordingComplete(false);
  };

  const botStatusMessage = {
    ready: "Spinning up a fresh bot...",
    joining_call: "Attempting to join your call...",
    in_waiting_room: "We're in the host's Zoom waiting room, please ask them to click Admit!",
  };
  return (
    <Stack p={1} pt={2} gap={2} alignItems="center">
      <Typography fontSize="24px" fontWeight={700}>
        Joining your call
      </Typography>
      {botStatusMessage[props.status] && (
        <Typography fontWeight={400} textAlign="center">
          {botStatusMessage[props.status]}
        </Typography>
      )}
      <Box pt={1}>
        {props.status === "in_waiting_room" ? (
          <AdmitGraphic />
        ) : (
          <CircularProgress size="2em" color="primary" />
        )}
      </Box>
      <RecordingDurationWarning />
      <Stack direction="row" width="100%" justifyContent="center" alignItems="center" mt={1}>
        <Button
          color="primary"
          variant="outlined"
          type="submit"
          sx={{
            borderRadius: "50px",
            py: 1,
            px: 3,
            fontWeight: 700,
          }}
          onClick={quitYoodliBot}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export default JoiningMeeting;
