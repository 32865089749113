import { db } from "lib-fullstack";

// Components
import { Box, Typography, Stack, Link } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// Utils
import { videoIsDisplayable } from "../VideoJournal/VideoJournalUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { formatDate } from "lib-fullstack/utils/dateUtils";
import { WebServerInternalPath } from "utils/paths";

export default function LastRecording(props: {
  dbBot: db.Doc<db.RecallZoomBot>;
  dbSpeech: db.Doc<db.Speech>;
}): JSX.Element {
  const { meetingId, createdAt } = props.dbBot.data;
  const { slug, name } = props.dbSpeech.data;
  const ready = videoIsDisplayable(props.dbSpeech.data);

  return (
    <Box
      pt={1}
      sx={{
        width: "min(100%, 800px)",
      }}
    >
      <Typography pl={1} variant="h6" fontWeight={600} mb={1}>
        View last recording
      </Typography>
      <Link
        href={ready ? WebServerInternalPath.SHARE_SLUG.replace(":slug", slug) : ""}
        target="_blank"
        sx={{
          textDecoration: "none",
          pointerEvents: ready ? "pointer" : "none",
          "&:hover": {
            "#last-recording-name": {
              textDecoration: "underline",
              textUnderlineOffset: 4,
            },
          },
        }}
      >
        <Box
          p={2}
          mb={1}
          mt={1}
          sx={{
            backgroundColor: getDynamicColor("light2"),
            borderRadius: 3,
            width: "100%",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {!ready && (
              <Box mr={2}>
                <CircularProgress size="1.5em" color="primary" />
              </Box>
            )}
            <Stack
              width="100%"
              direction="row"
              flexWrap="wrap"
              gap={0.5}
              mr={!ready ? 1 : 0}
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Stack
                justifyContent="space-between"
                alignItems="flex-start"
                flexWrap="wrap"
                gap={0.5}
              >
                <Typography
                  variant="h6"
                  id="last-recording-name"
                  fontWeight={600}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {name}
                </Typography>

                <Typography
                  variant="h6"
                  fontSize="14px"
                  fontWeight={400}
                  color={getDynamicColor("dark4")}
                >
                  {formatDate(createdAt)}
                </Typography>
              </Stack>
              <Typography
                fontSize="14px"
                variant="h6"
                fontWeight={500}
                sx={{
                  color: getDynamicColor("purple3"),
                }}
              >
                Meeting ID: {meetingId}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}
