/**
 * Sanitize a string for logging to avoid log injection attacks
 */
export function sanitizeForLogging(input: string): string {
  return input?.replace(/\n|\r/g, "");
}

export function sanitizeSubdomain(subdomain: string): string {
  return subdomain.replace(/[^a-zA-Z0-9-]/g, "");
}
