import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { db } from "lib-fullstack";

const AUTH_URL = "/api/outlook";

export const GOOGLE_SCOPE = "https://www.googleapis.com/auth/calendar.events.readonly";

export const initAuthFlow = (redirectPathname?: string, mode?: db.CalendarMode): void => {
  let authUrl = `${AUTH_URL}?siteId=${getSiteId()}`;

  if (redirectPathname) {
    authUrl += `&redirectPathname=${redirectPathname}`;
  }

  if (mode) {
    authUrl += `&mode=${mode}`;
  }

  window.location.href = authUrl;
};
