import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/analytics";

// Utilities/Functions
import { LOGIN_SESSION_ACTIVE } from "../utils/Constants";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

/**
 * Gets current user ID (if any) from Firebase. Returns `undefined`
 * if none.
 */
export function getCurrentUserId(): string | undefined {
  return firebase.auth().currentUser?.uid;
}

/**
 * Sign out from Firebase
 */
export const doLogout = async (): Promise<void> => {
  const clientEnv = getClientEnvConfig();
  return firebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.removeItem(LOGIN_SESSION_ACTIVE);
      window.location.href = isToastmasters()
        ? `${clientEnv.url.WEB_SERVER.replace("app", "toastmasters")}${
            WebServerExternalPath.SIGN_IN
          }`
        : clientEnv.url.LANDING_PAGE;
      console.log("User logged out.");
    });
};

export const doLogoutWithoutRedirect = async (): Promise<void> => {
  return firebase
    .auth()
    .signOut()
    .then(() => {
      console.log("User logged out.");
    });
};
