/* Convenience functions to read and update UserDoc's.
 * GET: All clients should use these functions because we allow physically missing
 * mandatory fields in a Firestore doc and these functions merge the defaults.
 * UPSET: It is strongly recommended to use these functions to make sure
 * "type" field is set correctly.
 **/
// can't do our usual `db` import b/c it could cause cycles
import * as dbr from "./db_runtypes";
import * as dbts from "./db_typesaurus";
import * as ts from "typesaurus";
import * as _ from "lodash";
import { UpdateModel } from "typesaurus/update";
import { UpsetModel } from "typesaurus/upset";
import { UsagePlanDuration, UsagePlanType } from "lib-fullstack/utils/pricingTypes";

const epochString = new Date(0).toISOString();

// Define all default values of mandatory fields.
// user_docs.test.ts ensures all mandatory fields are defined here.
// This function is exported for another unit test, but it's not
// meant to be used directly by the production code.
export const userDocDefaults = {
  [dbr.UserDocTypes.PUBLIC]: {
    type: dbr.UserDocTypes.PUBLIC as dbr.UserDocTypes.PUBLIC,
    dailyStreak: 0,
  },
  [dbr.UserDocTypes.MAIN]: {
    type: dbr.UserDocTypes.MAIN as dbr.UserDocTypes.MAIN,
    contacts: {},
    notifPrefsNewCommentEmail: true,
    notifPrefsNewShareEmail: true,
    notifPrefsSpeechMilestonesEmail: true,
    notifPrefsSendCommentEmail: true,

    speechRecorderVideoOn: true,
    poseHelperOn: false,
    videoPlayerOnMobile: {
      speech: false,
    },
    videoPlayerOnDesktop: {
      speech: true,
    },
    fpr: {
      ttsEnabled: true,
      showCaptions: false,
    },
    mirrorVideo: true,
    countdownOn: true,
    backgroundBlurOn: false,
    realTimeAlertsOn: true,
    defaultLinkSharingPublic: false,
    navigationBarDefaultExpanded: true,

    defaultTargetTimeS: 60,
    visitedDemoTourpoints: [],
    visitedRecorderTourpoints: [],
    visitedSummaryTourpoints: [],
    visitedPracticeRecorderTourpoints: [],
    visitedTtsTourpoints: [],

    nka_record: 0, // emitted when user is about to view speech summary (on Speech Recorder, it's when user clicks "View Analysis")
    nka_poodli_record: 0, // emitted when, from Poodli app, user records a Poodli and the upload completes
    nka_zoodli_record: 0, // emitted when, from Zoodli page, user clicks on "View last recording" to view Speech Summary right after Zoodli recording
    nka_upload: 0, // upload is initiated (regardless of outcome)
    nka_game: 0, // game is successfully played
    nka_comment: 0, // a human comment, comment reply, etc on speech summary
    nka_autoremark: 0, // an autoremark click, interaction, expand, read
    nka_share_game: 0, // share a game
    nka_share_rec: 0, // share a speech/video
    nka_invite: 0, // invite a user to the platform or to view a speech, by email
    nka_engage_passive: 0, // play video on speech summary or engaging with Courses
    nka_tour_click: 0, // click on a tour point on speech summmary

    highScoreYarn: 0,
    highScoreNoFiller: 0,

    dataCollectionConsent: true,
    dateLastActivity: epochString,

    personas: {},
  },
  [dbr.UserDocTypes.READONLY]: {
    type: dbr.UserDocTypes.READONLY as dbr.UserDocTypes.READONLY,

    usagePlanType: UsagePlanType.FREE,
    usagePlanDuration: UsagePlanDuration.NONE,
    usagePlanRenewalDate: null,
    nextUsagePlanStart: null,
    nextUsagePlanType: null,
    nextUsagePlanDuration: null,
    usageWindowStart: null,
    usageWindowEnd: null,
    usedSpeeches: 0,
    usageQuota: 5,
    freeUsageBonusForPoodli: false,
    dataRedactionDays: 0,
  },
  [dbr.UserDocTypes.PLAN]: {
    type: dbr.UserDocTypes.PLAN as dbr.UserDocTypes.PLAN,
  },
};

/**
 * Get a user doc with default attributes even if they do not physically exist in DB.
 */
async function getUserDoc(
  siteId: string,
  userId: string,
  docType: dbr.UserDocTypes
): Promise<dbr.UserDoc> {
  const doc = await ts.get(dbts.userDocs([siteId, userId]), docType);
  return mergeDefault(doc?.data as Partial<dbr.UserDoc>, docType);
}

/**
 * This defaults op lets us provide defaults that pass validation even
 * in the case of an empty doc. Better to provide defaults here than
 * to auto-write them to DB because these are changeable in src code.
 */
export function mergeDefault<T extends dbr.UserDoc>(
  rawDocData: Partial<T>,
  docType: dbr.UserDocTypes
): T {
  if (rawDocData) {
    _.defaults(rawDocData, userDocDefaults[docType]);
    return rawDocData as T;
  } else {
    return userDocDefaults[docType as string];
  }
}

export async function getUserDocMain(siteId: string, userId: string): Promise<dbr.UserDocMain> {
  const doc = await getUserDoc(siteId, userId, dbr.UserDocTypes.MAIN);
  try {
    return dbr.UserDocMain.check(doc);
  } catch (err) {
    const details = err.details ? JSON.stringify(err.details) : "(details unavailable)";
    console.error(`Malformed ${userId}/docs/main ${details}`);
    return doc as dbr.UserDocMain;
  }
}

export async function getUserDocReadonly(
  siteId: string,
  userId: string
): Promise<dbr.UserDocReadonly> {
  const doc = await getUserDoc(siteId, userId, dbr.UserDocTypes.READONLY);
  try {
    return dbr.UserDocReadonly.check(doc);
  } catch (err) {
    const details = err.details ? JSON.stringify(err.details) : "(details unavailable)";
    console.error(`Malformed ${userId}/docs/readonly ${details}`);
    return doc as dbr.UserDocReadonly;
  }
}

export async function getUserDocPublic(siteId: string, userId: string): Promise<dbr.UserDocPublic> {
  const doc = await getUserDoc(siteId, userId, dbr.UserDocTypes.PUBLIC);
  try {
    return dbr.UserDocPublic.check(doc);
  } catch (err) {
    const details = err.details ? JSON.stringify(err.details) : "(details unavailable)";
    console.error(`Malformed ${userId}/docs/public ${details}`);
    return doc as dbr.UserDocPublic;
  }
}

export async function updateUserDocMain(
  siteId: string,
  userId: string,
  updates: UpdateModel<dbr.UserDocMain>
): Promise<void> {
  // adds the required `type` param in case doc doesn't exist
  const updatesForUpset = {
    ...updates,
    type: dbr.UserDocTypes.MAIN,
  } as UpsetModel<dbr.UserDoc>;
  return ts.upset(dbts.userDocs([siteId, userId]), dbr.UserDocTypes.MAIN, updatesForUpset);
}

export async function updateUserDocReadonly(
  siteId: string,
  userId: string,
  updates: UpdateModel<dbr.UserDocReadonly>
): Promise<void> {
  // adds the required `type` param in case doc doesn't exist
  const updatesForUpset = {
    ...updates,
    type: dbr.UserDocTypes.READONLY,
  } as UpsetModel<dbr.UserDoc>;
  return ts.upset(dbts.userDocs([siteId, userId]), dbr.UserDocTypes.READONLY, updatesForUpset);
}

export async function updateUserDocPublic(
  siteId: string,
  userId: string,
  updates: UpdateModel<dbr.UserDocPublic>
): Promise<void> {
  // adds the required `type` param in case doc doesn't exist
  const updatesForUpset = {
    ...updates,
    type: dbr.UserDocTypes.PUBLIC,
  } as UpsetModel<dbr.UserDoc>;
  return ts.upset(dbts.userDocs([siteId, userId]), dbr.UserDocTypes.PUBLIC, updatesForUpset);
}

export async function updateUserDocPlan(
  siteId: string,
  userId: string,
  updates: UpdateModel<dbr.UserDocPlan>
): Promise<void> {
  // adds the required `type` param in case doc doesn't exist
  const updatesForUpset = {
    ...updates,
    type: dbr.UserDocTypes.PLAN,
  } as UpsetModel<dbr.UserDoc>;
  return ts.upset(dbts.userDocs([siteId, userId]), dbr.UserDocTypes.PLAN, updatesForUpset);
}
