import React from "react";

// Components
import { Info as InfoIcon } from "@mui/icons-material";
import { Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import {
  YoodliCtaModal,
  CtaButtonHandlers,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliSelect,
  YoodliSelectVariant,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { OrgProgramsQueryKeys } from "../Programs/OrgPrograms";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HttpStatusCode } from "axios";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { deleteOrgUserV2, patchUser } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";

export const OrganizationSettings = (): JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  const {
    defaultOrg,
    defaultOrgLoading,
    invalidateOrgListQuery,
    invalidateUserOrgQuery,
    fullOrgList,
    invalidateDefaultOrgQuery,
    orgListLoading,
  } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  let options = [];
  if (fullOrgList && defaultOrg) {
    options = [
      { label: defaultOrg?.name, value: defaultOrg?.id },
      ...fullOrgList
        .filter((org) => org.id !== defaultOrg?.id)
        .map((org) => ({ label: org.name, value: org.id })),
    ];
  }

  const updateDefaultOrgMutation = useMutation({
    mutationFn: (orgId: string) => {
      setError("");
      return patchUser({ default_org_id: orgId }).then((result) => result.toString());
    },
    onSuccess: async (orgId: string) => {
      Instrumentation.logDefaultOrgChanged(defaultOrg?.id, orgId);
      await invalidateUserOrgQuery();
      return invalidateDefaultOrgQuery();
    },
    onError: () => {
      setError("An error occured when attempting to switch organizations.");
    },
  });

  const handleLeaveOrg = async () => {
    setLoading(true);
    setError("");
    try {
      await deleteOrgUserV2(defaultOrg?.id, "me");
      const newUserOrgs = fullOrgList?.filter((org) => org.id !== defaultOrg?.id);
      if (newUserOrgs.length > 0) {
        await updateDefaultOrgMutation.mutate(newUserOrgs[0].id);
      }
      await invalidateUserOrgQuery();
      await invalidateOrgListQuery();
      await invalidateDefaultOrgQuery();
      if (newUserOrgs.length === 0) {
        await queryClient.invalidateQueries({
          queryKey: [OrgProgramsQueryKeys.MemberView],
        });
      }
    } catch (e) {
      console.log("error leaving org: ", e);
      if (e?.message?.includes(HttpStatusCode.Forbidden)) {
        setError("You are not allowed to leave this organization.");
      } else {
        setError("An error occured when attempting to leave this organization.");
      }
    }
    setLoading(false);
  };

  const renderDefaultOrgSelector = () => {
    if (fullOrgList.length <= 1) {
      return null;
    }
    return (
      <>
        <Grid item flexWrap={"wrap"} xs={12} md={6} lg={6}>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "160%",
              }}
            >
              Switch organization
            </Typography>
            <Tooltip
              title="You are associated with more than one organization. Use the dropdown to the left to switch a different organization’s branded experience."
              placement="right"
            >
              <InfoIcon sx={{ color: getDynamicColor("dark3") }} />
            </Tooltip>
          </Stack>
        </Grid>

        {defaultOrg?.id && (
          <Grid item sx={{ mx: { xs: "auto", md: 0 }, flexGrow: 1 }}>
            <YoodliSelect
              value={defaultOrg?.id}
              onChange={(e) => updateDefaultOrgMutation.mutate(e.target.value as string)}
              loading={
                updateDefaultOrgMutation.isPending || defaultOrgLoading || orgListLoading || loading
              }
              yoodliVariant={YoodliSelectVariant.PRIMARY}
              options={options}
            />
          </Grid>
        )}
      </>
    );
  };

  const renderOrgSettingsContent = () => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between" zIndex={2} spacing={3}>
          <Grid item flexWrap={"wrap"} xs={12} md={6} lg={6}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                lineHeight: 2,
              }}
            >
              You are part of{" "}
              <strong
                color={
                  updateDefaultOrgMutation.isPending ||
                  loading ||
                  defaultOrgLoading ||
                  orgListLoading
                    ? getDynamicColor("dark4")
                    : "initial"
                }
              >
                {defaultOrg?.name}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={6} sx={{ mx: { xs: "auto" } }}>
            <Button
              variant="outlined"
              sx={{
                alignContent: "left",
                whiteSpace: "nowrap",
                width: "fit-content",
                px: 2,
              }}
              onClick={() => setOpenDeleteModal(true)}
            >
              Leave this organization
            </Button>
          </Grid>
          {renderDefaultOrgSelector()}
        </Grid>
        {error && (
          <Typography color="error" fontWeight={600} fontSize="12px">
            {error}
          </Typography>
        )}
      </>
    );
  };
  return (
    <>
      <Stack gap={3} sx={{ zIndex: 3, position: "relative" }}>
        <Typography component="h2" sx={{ fontWeight: 700, fontFamily: "Poppins" }}>
          Organization Settings
        </Typography>
        {renderOrgSettingsContent()}
      </Stack>
      <YoodliCtaModal
        hideCloseButton={true}
        ctaBody={{
          title: "Are you sure you want to leave this organization?",
          subtitle:
            "If you leave, you will lose access to all content associated with this organization.",
        }}
        loading={loading}
        open={openDeleteModal}
        theme={YoodliCtaModalTheme.Danger}
        close={() => setOpenDeleteModal(false)}
        buttons={
          {
            primary: { text: "Leave Organization", handler: handleLeaveOrg },
            secondary: { text: "Cancel", handler: () => setOpenDeleteModal(false) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
};
