// Components
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Stack, Box, IconButton, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { MAX_ORG_SEATS } from "lib-fullstack/utils/constants";

type UpdateOrgSeatsControlsProps = {
  updatedOrgSeats: number;
  setUpdatedOrgSeats: React.Dispatch<React.SetStateAction<number>>;
  org: OrgV2Response;
  size: number;
  fontSize?: number;
  disabled?: boolean;
};

const BUTTON_SIZE = 20;

export const UpdateOrgSeatsControls = ({
  updatedOrgSeats,
  setUpdatedOrgSeats,
  org,
  size,
  fontSize,
  disabled,
}: UpdateOrgSeatsControlsProps): JSX.Element => {
  const getSeatCountColor = () => {
    if (updatedOrgSeats === org.license_count) {
      return getDynamicColor("purple3");
    } else if (updatedOrgSeats > org.license_count) {
      return getDynamicColor("greenSuccess");
    } else {
      return getDynamicColor("redError");
    }
  };

  return (
    <Stack gap={1} sx={{ alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: size,
          height: size,
          borderRadius: "8px",
          border: `1px solid ${getSeatCountColor()}`,
          position: "relative",
        }}
      >
        {updatedOrgSeats > org.license_used && (
          <IconButton
            onClick={() => setUpdatedOrgSeats((prev) => prev - 1)}
            disabled={disabled}
            sx={{
              position: "absolute",
              top: `calc(50% - ${BUTTON_SIZE / 2}px)`,
              left: -(BUTTON_SIZE / 2),
              width: BUTTON_SIZE,
              height: BUTTON_SIZE,
              background: getDynamicColor("light1"),
              color: getDynamicColor("purple3"),
              "&:hover": {
                background: getDynamicColor("light1"),
              },
            }}
          >
            <RemoveCircleIcon sx={{ width: BUTTON_SIZE, height: BUTTON_SIZE }} />
          </IconButton>
        )}
        <Typography
          sx={{
            color: getSeatCountColor(),
            fontFamily: "poppins",
            fontSize: fontSize ?? "20px",
            fontWeight: 700,
            letterSpacing: "0.2px",
          }}
        >
          {updatedOrgSeats}
        </Typography>
        {updatedOrgSeats < MAX_ORG_SEATS && (
          <IconButton
            onClick={() => setUpdatedOrgSeats((prev) => prev + 1)}
            disabled={disabled}
            sx={{
              position: "absolute",
              top: `calc(50% - ${BUTTON_SIZE / 2}px)`,
              right: -(BUTTON_SIZE / 2),
              width: BUTTON_SIZE,
              height: BUTTON_SIZE,
              background: getDynamicColor("light1"),
              color: getDynamicColor("purple3"),
              "&:hover": {
                background: getDynamicColor("light1"),
              },
            }}
          >
            <AddCircleIcon sx={{ width: BUTTON_SIZE, height: BUTTON_SIZE }} />
          </IconButton>
        )}
      </Box>
      <Typography
        sx={{
          color: getDynamicColor("dark5"),
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 700,
          whiteSpace: "nowrap",
        }}
      >
        total seats
      </Typography>
    </Stack>
  );
};
