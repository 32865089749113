import React from "react";

// Components
import { Button, LinearProgress, Stack, Typography } from "@mui/material";

// Utils
import { CoachBotContentUploadStatus } from "../../CoachBotTypes";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

type FileUploadProgressProps = {
  progress: number;
  status: CoachBotContentUploadStatus;
  fileId: string;
  hideEdit?: boolean;
  hideRemove?: boolean;

  editingFileId: string;
  handleSetEditingFileId: (fileId: string) => void;
  handleRemoveFile: (fileId: string) => Promise<void>;
};

export const FileUploadProgress = ({
  progress,
  fileId,
  status,
  hideEdit,
  hideRemove,
  editingFileId,
  handleSetEditingFileId,
  handleRemoveFile,
}: FileUploadProgressProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const renderLinearProgressCtas = () => {
    const buttonStyles = {
      py: 0.25,
      px: 0.75,
      fontSize: 10,
      whiteSpace: "nowrap",
    };
    const buttons = status === "done" && (
      <>
        {!hideEdit && (
          <Button
            onClick={() => {
              handleSetEditingFileId(fileId);
            }}
            key="edit"
            variant="text"
            sx={{ ...buttonStyles }}
          >
            {editingFileId ? "Save" : "Edit"}
          </Button>
        )}
        {!hideRemove && !editingFileId && (
          <Button
            onClick={() => handleRemoveFile(fileId)}
            key="remove"
            variant="text"
            sx={{ ...buttonStyles }}
          >
            Remove
          </Button>
        )}
      </>
    );

    // TODO @dwiegand: add this back once the functionality has been completed
    // if (["readyToUpload", "uploading"].includes(status) && progress < 100) {
    //   buttonsEl = (
    //     <Button
    //       key="cancel"
    //       variant="text"
    //       sx={{ ...buttonStyles }}
    //       onClick={() => {
    //         console.log("handle cancel upload");
    //       }}
    //     >
    //       Cancel upload
    //     </Button>
    //   );
    // }
    return (
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        {buttons}
      </Stack>
    );
  };
  // always start at a random percentage between 5 and 15 so it looks like the first chunk has already begun uploading right away
  const progPct = React.useMemo(() => {
    return Math.max(Math.floor(Math.random() * 10) + 5, progress);
  }, [progress]);
  return (
    <Stack
      direction="column"
      gap={0.5}
      sx={{
        color: getDynamicColor("primary"),
        ".MuiLinearProgress-root": {
          borderRadius: 100,
        },
      }}
    >
      <LinearProgress color={"inherit"} value={progPct} variant="determinate" />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            fontSize: 10,
            fontFamily: "poppins",
            fontWeight: 400,
            color: getDynamicColor("dark4"),
          }}
        >
          {progPct}% {!isSmallScreen && "uploaded"}
        </Typography>
        {renderLinearProgressCtas()}
      </Stack>
    </Stack>
  );
};
