import { db } from "lib-fullstack";

// Utils
import {
  getLocalDayAndHourFromIso,
  inBusinessHours,
  nowInIsoFormat,
} from "lib-fullstack/utils/dateUtils";

export enum CalendarAction {
  HIDDEN = "HIDDEN",
  SKIPPED = "SKIPPED",
  CONNECTED = "CONNECTED",
  FAILED = "FAILED",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}

export function shouldDefaultJoin(
  defaultJoin: db.BotAutoJoinPreference,
  isOrganizer: boolean,
  attendees: db.CalendarAttendee[],
  startTimeIso: string
): boolean {
  if (!attendees?.length) {
    return false;
  }

  if (defaultJoin === "all") {
    return true;
  } else if (defaultJoin === "organizer_only") {
    return isOrganizer;
  } else if (defaultJoin === "smart") {
    const [day, hour] = getLocalDayAndHourFromIso(startTimeIso);
    return isOrganizer && inBusinessHours(day, hour);
  } else {
    return false;
  }
}

export function isJoinableEvent(dbEvent: db.Doc<db.CalendarEvent>): boolean {
  return !dbEvent.data.joined && !dbEvent.data.skipped;
}

export function formatEventMeetingPlatform(meetingPlatform: db.MeetingPlatform): string {
  switch (meetingPlatform) {
    case db.MeetingPlatform.GOOGLE_MEET:
      return "Google Meet";
    case db.MeetingPlatform.ZOOM:
      return "Zoom";
    case db.MeetingPlatform.MICROSOFT_TEAMS:
      return "Microsoft Teams";
  }
}

export async function handleTransactionalCalendarFxn(
  dbCalendar: db.Doc<db.Calendar>,
  fxn: () => Promise<void>
): Promise<void> {
  let isLocked = false;
  await db.ts.transaction<db.Doc<db.Calendar>, void>(
    ({ get }) => get(dbCalendar.ref),
    async ({ data, update }) => {
      const lockAcqUntil = (data?.data as db.Calendar)?.lockAcqUntil;
      if (lockAcqUntil && lockAcqUntil > new Date().toISOString()) {
        isLocked = true;
        console.warn(
          `Could not acquire calendar transaction lock, aborting! Calendar id: ${dbCalendar.ref.id}`
        );
        return;
      }
      return update(dbCalendar.ref, {
        lockAcqUntil: nowInIsoFormat(5 * 60), // lock for 5 minutes.
      });
    }
  );

  if (isLocked) {
    return;
  }
  console.log(`Calendar transaction lock acquired for ${dbCalendar.ref.id}`);

  try {
    await fxn();
  } finally {
    try {
      await db.update(dbCalendar.ref, { lockAcqUntil: db.ts.value("remove") });
      console.log(`Calendar transaction lock released for ${dbCalendar.ref.id}`);
    } catch {
      console.log(`Unable to release lock, calendar ${dbCalendar.ref.id} was likely deleted`);
    }
  }
  console.log(`Calendar transaction completed for ${dbCalendar.ref.id}`);
}
