import firebase from "firebase/app";
import { db } from "lib-fullstack";
import { useOnAll } from "@typesaurus/react";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";

const useCalendars = (
  dbCalendars?: db.Doc<db.Calendar>[],
  dbCalendarsMeta?: { loading: boolean; error: unknown }
): {
  dbCalendars: db.Doc<db.Calendar>[];
  dbCalendarsMeta: { loading: boolean; error: unknown };
} => {
  const [_dbCalendars, _dbCalendarsMeta] = useOnAll(
    db.userCalendars([getSiteId(), firebase.auth()?.currentUser?.uid])
  );

  if (_dbCalendarsMeta.error && firebase.auth()?.currentUser) {
    console.error(_dbCalendarsMeta.error);
    throw new Error(_dbCalendarsMeta.error.toString());
  }

  return {
    dbCalendars: dbCalendars ?? _dbCalendars,
    dbCalendarsMeta: dbCalendarsMeta ?? _dbCalendarsMeta,
  };
};

export default useCalendars;
