import React from "react";

// Components
import { Box, Stack, Tooltip, Typography } from "@mui/material";

// Assets
import { ReactComponent as ArrowDownIcon } from "images/icons/icon-arrow-down.svg";

// Utils
import { HoistedAnalytic } from "../DashboardTypes";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { formatAggAnalyticValue } from "utils/DashboardUtils";

type TrendChipProps = {
  analytic: HoistedAnalytic;
  numDays: number;
};

const TrendChip = ({ analytic, numDays }: TrendChipProps): JSX.Element => {
  const { currAvg, prevAvg } = analytic ?? {};

  let change = currAvg - prevAvg;
  const displayPrevAvg = formatAggAnalyticValue(prevAvg, analytic);
  let displayChange = formatAggAnalyticValue(change, analytic);

  if (analytic.changeOverTime) {
    const customChangeOverTime = analytic.changeOverTime(prevAvg, currAvg);
    change = customChangeOverTime.change;
    displayChange = customChangeOverTime.displayChange;
  }

  const noChange = isNaN(change) || change === 0;
  const isPercentage = displayChange.includes("%");
  let trendWord = isPercentage ? "higher" : "more";
  if (change < 0) {
    trendWord = isPercentage ? " " : "less";
  }

  return (
    <Tooltip title={`Previous Average: ${displayPrevAvg}`}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: getDynamicColor("light2"),
          color: getDynamicColor("purple3"),
          width: "fit-content",
          fontSize: 14,
          whiteSpace: "nowrap",
          py: 0.5,
          fontWeight: 500,
          borderRadius: "16px",
          px: 2,
        }}
      >
        {!noChange && (
          <Box
            sx={{
              display: "inline-block",
              height: 12,
              width: 12,
              mr: 0.5,
              svg: {
                fill: getDynamicColor("purple3"),
              },
            }}
          >
            <ArrowDownIcon
              style={{
                transform: change > 0 ? "rotate(180deg)" : "rotate(0deg)",
                verticalAlign: "top",
              }}
            />
          </Box>
        )}
        <Typography
          sx={{
            display: "inline-block",
          }}
        >
          {noChange
            ? `Same as last ${numDays === 7 ? "week" : "month"}`
            : `${displayChange} ${trendWord} than the last ${numDays} days`}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default TrendChip;
