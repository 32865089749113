import React, { useRef, useState } from "react";

// Components
import PauseIcon from "@mui/icons-material/Pause";
import PlayIcon from "@mui/icons-material/PlayArrow";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Collapse, IconButton, Typography, Box } from "@mui/material";
import Slider from "@mui/material/Slider";
import { ContainedLoadingAnimation } from "lib-frontend/components/Animations/LoadingAnimation";
import { VolumeSlider } from "lib-frontend/components/VideoPlayer/VideoControls";
import { PlaybackSpeedMenu } from "lib-frontend/components/VideoPlayer/VideoControls";

// Utils
import styled from "@emotion/styled";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { SUPPORT_EMAIL } from "lib-fullstack/utils/constants";
import { getMMSS } from "lib-fullstack/utils/dateUtils";
import { KeyActionAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";

type ReactPlayerWrapperProps = {
  isAudio: boolean;
  className?: string;
};

const ReactPlayerWrapper = styled.div<ReactPlayerWrapperProps>`
  position: relative;
  width: 100%;
  background-color: ${({ isAudio }: ReactPlayerWrapperProps) =>
    !isAudio ? getDynamicColor("purple3") : "transparent"};
  overflow: hidden;

  .MuiIconButton-root {
    border-radius: 8px;
    color: inherit;
  }

  .video {
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    .react-player {
      position: absolute;
    }
  }

  .audio {
    height: 64px;
  }

  .video-loading {
    background-color: var(--white);
    height: 100%;
    width: 100%;
    position: absolute;
    padding: 2rem;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .overlay {
    position: absolute;
    color: white;
    fill: white;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    transition: visibility 2s easeInOut;
  }

  .big-play-button {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .play-circle {
      fill: rgba(255, 255, 255, 0.8);
      font-size: 4rem;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    .play-click-zone {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 80%;
    }
  }

  .controls {
    display: flex;

    ${({ isAudio }: ReactPlayerWrapperProps) =>
      !isAudio
        ? "background: linear-gradient(0deg, #1d1616 0%, rgba(29, 22, 22, 0) 100%)"
        : `background: ${getDynamicColor("dark6")}`};

    ${({ isAudio }: ReactPlayerWrapperProps) => isAudio && "border-radius: 6px"};
    fill: white;
    padding: 0.5rem 0.75rem;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;

    .timestamp {
      margin-left: 8px;
      color: ${getDynamicColor("light1")};
    }

    .duration {
      margin-right: 8px;
      color: ${getDynamicColor("light1")};
    }
  }
`;

type SimpleVideoPlayerProps = {
  videoURL: string;
  speechId?: string;
  onPlay?: (number) => void;
  onPause?: (number) => void;
  onSeek?: (number) => void;
  onPlaybackSpeedChange?: (number) => void;
  width?: number;
  height?: number;
  className?: string;
  isAudio?: boolean;
  noMirror?: boolean;
  skipToFrame?: number;
  mirrored?: boolean;
  isSmall?: boolean;
  hidePlaybackButton?: boolean;
  hideControls?: boolean;
  autoplay?: boolean;
  currentTime?: number;
  setParentVideoTime?: (number) => void;
  playbackSpeed?: number;
  isMuted?: boolean;
};

export const SimpleVideoPlayer = ({
  videoURL,
  onPlay,
  onPause,
  onSeek,
  onPlaybackSpeedChange,
  className,
  isAudio,
  mirrored,
  isSmall,
  hidePlaybackButton,
  hideControls,
  autoplay,
  currentTime,
  setParentVideoTime,
  playbackSpeed,
  isMuted,
}: SimpleVideoPlayerProps): JSX.Element => {
  const [videoURLState, setVideoURLState] = useState(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(!!autoplay);
  const [isSeeking, setIsSeeking] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [played, setPlayed] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1.0);
  const [controls, toggleControls] = useState<boolean>(false);
  const [muted, toggleMuted] = useState<boolean>(isMuted ?? false);
  const [error, setError] = useState<string>("");
  const [playbackRate, setPlaybackRate] = useState<number>(playbackSpeed ?? 1.0);
  const videoRef = useRef(undefined);

  // Only set it if there was an issue with setting seekable blob
  React.useEffect(() => {
    if (videoURLState === undefined) {
      setVideoURLState(videoURL);
    }
  }, [videoURL]);

  React.useEffect(() => {
    isPlaying ? videoRef.current.play() : videoRef.current.pause();
  }, [isPlaying, videoRef]);

  React.useEffect(() => {
    if (currentTime) {
      videoRef.current.currentTime = currentTime;
      setPlayed(currentTime);
    }
  }, []);

  React.useEffect(() => {
    setParentVideoTime?.(videoRef?.current?.currentTime);
  }, [videoRef?.current?.currentTime]);

  const handlePlay = () => {
    if (!isPlaying) {
      if (onPlay) {
        onPlay(played);
      }
      Instrumentation.logKeyAction(KeyActionAnalyticsEvents.ENGAGE_PASSIVE, {
        isYoodliVideoPlayer: false,
        videoURL: videoURLState,
      });
    }
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
    if (isPlaying && onPause) {
      onPause(played);
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  const handleSeekMouseDown = () => {
    setIsSeeking(true);
  };

  const handleSeekChange = (e: Event) => {
    if (videoRef.current) {
      const value = (e.target as HTMLInputElement).value;
      setPlayed(parseFloat(value as string) ?? 0);
      videoRef.current.currentTime = parseFloat(value as string);
      if (onSeek && !isSeeking) {
        onSeek(parseInt(value));
      }
    }
  };

  const handleSeekMouseUp = () => {
    setIsSeeking(false);
  };

  const handleProgress = () => {
    setPlayed(videoRef.current?.currentTime ?? 0);
  };

  const handleVolumeChange = (e) => {
    toggleMuted(false);
    setVolume(parseFloat(e.target.value));
    videoRef.current.volume = e.target.value;
  };

  const handleMute = () => {
    toggleMuted(!muted);
  };

  const handleError = (error) => {
    if (videoURLState) {
      console.error(error.target.error);
      const defaultError = "Oops! The video failed to load. Please try refreshing the page!";

      if (error.target.error.message.includes("MEDIA_ERR_SRC_NOT_SUPPORTED")) {
        setError(
          `Oops! Your browser doesn't support this video. Please try a different browser or contact our support at ${SUPPORT_EMAIL} for more information!`
        );
      } else {
        setError(defaultError);
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration ?? 0);
      setIsLoading(false);
      setError("");
    }
  };

  const handlePlaybackRateChange = (speed: number) => {
    if (onPlaybackSpeedChange) {
      onPlaybackSpeedChange(speed);
    }
    setPlaybackRate(speed);
    videoRef.current.playbackRate = speed;
  };

  return (
    <ReactPlayerWrapper
      onMouseEnter={() => toggleControls(true)}
      onMouseLeave={() => toggleControls(false)}
      isAudio={isAudio}
      className={className}
      style={{ borderRadius: "4px" }}
    >
      {isLoading && !isAudio && (
        <div className="video-loading">
          <ContainedLoadingAnimation showPrompt={false} />
        </div>
      )}
      {error !== "" && (
        <div className="video-loading">
          <h2>{error}</h2>
        </div>
      )}
      {!isAudio && !hidePlaybackButton && (
        <div className="big-play-button">
          <div onClick={togglePlay} className="play-click-zone" />
          {!isPlaying && <PlayCircleIcon className="play-circle" />}
        </div>
      )}
      <div tabIndex={0} className={!isAudio ? "video" : "audio"}>
        <video
          className="react-player"
          ref={videoRef}
          style={mirrored ? { transform: "scaleX(-1)" } : {}}
          width="100%"
          height="100%"
          src={videoURL}
          controls={false}
          muted={muted}
          onPlay={handlePlay}
          onPause={handlePause}
          onTimeUpdate={handleProgress}
          onError={handleError}
          onLoadedMetadata={handleLoadedMetadata}
        />
      </div>
      {!hideControls && (
        <Collapse in={controls || isAudio} className="overlay">
          <Box
            className="controls"
            sx={{
              borderRadius: { xs: 0, md: "6px" },
            }}
          >
            {!hidePlaybackButton &&
              (isPlaying ? (
                <IconButton onClick={handlePause} size={isSmall ? "small" : "large"}>
                  <PauseIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => handlePlay()} size={isSmall ? "small" : "large"}>
                  <PlayIcon />
                </IconButton>
              ))}
            <VolumeSlider
              muted={muted}
              controlsHovered={controls}
              toggleMuted={handleMute}
              volume={volume}
              setVolume={handleVolumeChange}
              isAudio={isAudio}
            />
            <Typography className="timestamp">{getMMSS(played)}</Typography>
            <Slider
              size="small"
              sx={{
                padding: "0 0",
                height: "6px",
                color: "rgba(255, 255, 255)",
                marginX: "10px",
                "&:hover": {
                  height: "9px",
                },
                ".MuiSlider-thumb": {
                  visibility: "hidden",
                },
                ".MuiSlider-track": {
                  backgroundColor: getDynamicColor("primary"),
                },
                ".MuiSlider-mark": {
                  height: "calc(100% + 10px)",
                  borderRadius: "2px",
                  width: "4px",
                  backgroundColor: "white",
                  "&:hover": {
                    height: "20px",
                  },
                },
              }}
              min={0}
              step={1}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              valueLabelDisplay="auto"
              onMouseUp={handleSeekMouseUp}
              value={played}
              max={duration}
            />
            <Typography className="duration">{getMMSS(duration)}</Typography>
            <PlaybackSpeedMenu
              value={playbackRate}
              onClick={handlePlaybackRateChange}
              isSmall={isSmall}
            />
          </Box>
        </Collapse>
      )}
    </ReactPlayerWrapper>
  );
};

export default SimpleVideoPlayer;
