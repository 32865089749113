import { db } from "lib-fullstack";

// Utils
import { OnboardingChecklist, OnboardingChecklistTask } from "lib-fullstack/db";

export const onboardingChecklists = {
  [OnboardingChecklist.INTERVIEW_CHECKLIST_V1]: [
    OnboardingChecklistTask.DEMO_VIDEO,
    OnboardingChecklistTask.FPR_INTERVIEW,
    OnboardingChecklistTask.VIEW_SPEECH_SUMMARY,
  ],
  [OnboardingChecklist.SPEECH_CHECKLIST_V1]: [
    OnboardingChecklistTask.DEMO_VIDEO,
    OnboardingChecklistTask.FPR_SPEECH,
    OnboardingChecklistTask.VIEW_SPEECH_SUMMARY,
  ],
  [OnboardingChecklist.MEETINGS_CHECKLIST_V1]: [
    OnboardingChecklistTask.DEMO_VIDEO,
    OnboardingChecklistTask.FPR_CONVERSATION,
    OnboardingChecklistTask.VIEW_SPEECH_SUMMARY,
  ],
  [OnboardingChecklist.COACH_CHECKLIST_V1]: [
    OnboardingChecklistTask.DEMO_VIDEO,
    OnboardingChecklistTask.FPR_SPEECH,
    OnboardingChecklistTask.UPLOAD_SPEECH,
    OnboardingChecklistTask.CREATE_ORG,
    OnboardingChecklistTask.INVITE_CLIENT_TO_HUB,
  ],
};

export const markOnboardingTaskComplete = async (
  completedTask: OnboardingChecklistTask,
  siteId: string,
  userId: string
): Promise<boolean> => {
  try {
    const newCompletedTask = {
      task: completedTask,
      completedTimestamp: new Date().toISOString(),
    };

    const userDocMainRef: db.Ref<db.UserDocMain> = db.ref(
      db.userDocs([siteId, userId]),
      db.UserDocTypes.MAIN
    );

    let isChecklistCompletedWithThisTask = false;
    await db.transaction<db.Doc<db.UserDocMain>, boolean>(
      ({ get }) => get(userDocMainRef),
      async ({ data, update }) => {
        const doc = data?.data as db.UserDocMain;
        if (!doc?.onboardingChecklist) {
          return false;
        }

        const userOnboardingChecklist = doc.onboardingChecklist;
        // If the user already completed task, do nothing:
        if (
          userOnboardingChecklist.completedTasks?.find(
            (alreadyCompletedTask) => alreadyCompletedTask.task === completedTask
          )
        ) {
          return false;
        }

        // Else, add the task to the completed tasks list:
        userOnboardingChecklist.completedTasks?.push(newCompletedTask);

        // Check if we can mark the whole checklist as completed:
        const currentChecklist = onboardingChecklists[userOnboardingChecklist.checklistId];
        const allTasksCompleted = currentChecklist.every((availableTask: OnboardingChecklistTask) =>
          userOnboardingChecklist.completedTasks?.find(
            (completedTask) => completedTask.task === availableTask
          )
        );
        if (allTasksCompleted) {
          userOnboardingChecklist.completedTimestamp = new Date().toISOString();
          isChecklistCompletedWithThisTask = true;
        }

        update(userDocMainRef, { onboardingChecklist: userOnboardingChecklist });
        return true;
      }
    );
    return isChecklistCompletedWithThisTask;
  } catch (er) {
    throw new Error(
      `onboardingChecklistUtils.markOnboardingTaskComplete: Error marking onboarding task ${completedTask} complete: ${er}`
    );
  }
};
