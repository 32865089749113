export default function YoodliLaptop(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30.2756" cy="30.4519" r="30" fill="#040348" />
      <path
        d="M49.2618 38.6777H11.2858C11.0841 38.7832 6.11509 42.643 6.11509 42.643C4.99518 43.5464 4.73286 44.8225 5.4492 45.9052C5.98393 46.8227 7.25013 47.4308 8.66767 47.4519H51.88C53.2975 47.4308 54.5637 46.8227 55.0985 45.9052C55.8199 44.819 55.5575 43.5394 54.4326 42.636L49.2618 38.6777Z"
        fill="white"
      />
      <path
        d="M46.2779 13.4519H14.3025C12.2926 13.4519 10.6633 15.0812 10.6633 17.0911V36.5792C10.6633 38.589 12.2926 40.2183 14.3025 40.2183H46.2779C48.2878 40.2183 49.9171 38.589 49.9171 36.5792V17.0911C49.9171 15.0812 48.2878 13.4519 46.2779 13.4519Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M12.4342 38.9706C12.6218 38.7831 12.8761 38.6777 13.1413 38.6777H47.4097C47.6749 38.6777 47.9293 38.7831 48.1168 38.9706L52.6974 43.5513C53.3274 44.1812 52.8812 45.2584 51.9903 45.2584H8.5607C7.6698 45.2584 7.22363 44.1812 7.85359 43.5513L12.4342 38.9706Z"
        fill="#A3B5C8"
      />
      <rect
        x="12.7273"
        y="15.6454"
        width="35.0968"
        height="21.9355"
        fill="url(#paint0_linear_5163_1900)"
      />
      <g clipPath="url(#clip0_5163_1900)">
        <path
          d="M8.48877 24.0114L7.03469 27.7869L3.25635 29.241L7.13106 30.7915L8.48877 34.7144L9.94002 30.6923L13.7666 29.3629L9.84365 27.7387L8.48877 24.0114Z"
          fill="#6966FF"
        />
        <path
          d="M15.2633 23.4162L14.7077 24.8561L13.2678 25.4088L14.7446 25.9984L15.2633 27.495L15.816 25.9616L17.2758 25.4542L15.7792 24.8363L15.2633 23.4162Z"
          fill="#CDCCFF"
        />
        <path
          d="M15.7564 32.58C16.298 32.58 16.7371 32.141 16.7371 31.5993C16.7371 31.0577 16.298 30.6186 15.7564 30.6186C15.2147 30.6186 14.7756 31.0577 14.7756 31.5993C14.7756 32.141 15.2147 32.58 15.7564 32.58Z"
          fill="#FFD15A"
        />
      </g>
      <path
        d="M35.4451 23.9415C35.2433 23.3338 34.5707 23.0033 33.9317 23.1952C33.573 23.3018 33.2927 23.5577 33.1694 23.8882C33.8533 24.2933 34.5819 24.6132 35.333 24.8691C35.5123 24.5919 35.546 24.2507 35.4451 23.9415Z"
        fill="white"
      />
      <path
        d="M33.0799 24.7944C33.0911 24.8371 33.1136 24.8797 33.136 24.9224L33.0015 25.2315L33.8983 25.6793C34.0328 25.7433 34.1561 25.8073 34.2794 25.8712L34.4924 25.5621C34.526 25.5514 34.5597 25.5514 34.5821 25.5407C34.7951 25.4768 34.9744 25.3595 35.1202 25.2102C34.3915 24.9543 33.6965 24.6345 33.0351 24.2507C33.0015 24.4213 33.0127 24.6132 33.0799 24.7944Z"
        fill="white"
      />
      <path
        d="M32.7996 25.5514L32.2054 26.9481V26.9587L29.4926 32.1509C29.4141 32.3855 29.1563 32.5134 28.9097 32.4495L28.8872 32.4388C28.6742 32.3855 28.5509 32.1829 28.607 31.991C28.6182 31.9697 28.6182 31.9484 28.6294 31.9271L33.73 21.9158C34.0775 21.2334 33.7748 20.4125 33.0574 20.082C33.0126 20.0606 32.9565 20.0393 32.9117 20.0287L32.878 20.018C32.183 19.7834 31.4207 20.082 31.0956 20.711L28.3379 26.0845C28.1362 26.479 27.6429 26.6389 27.2281 26.447C26.847 26.2764 26.6676 25.8499 26.8134 25.4768C26.8246 25.4448 26.8358 25.4235 26.847 25.3915L29.4253 20.4231C29.7728 19.7408 29.4814 18.9199 28.7639 18.5893C28.0689 18.2695 27.2281 18.5254 26.8582 19.1757L25.1094 22.3209C24.9749 22.5661 25.0758 22.8647 25.3336 22.9926C25.5915 23.1205 25.9053 23.0246 26.0399 22.79L27.7886 19.6448C27.8895 19.4636 28.125 19.3996 28.3155 19.4956C28.4949 19.5809 28.5734 19.7834 28.4949 19.954L28.4837 19.986L25.9053 24.9543C25.4345 25.8286 25.7932 26.9161 26.7237 27.3639C27.6429 27.8117 28.7863 27.4705 29.2572 26.5856C29.2684 26.5642 29.2796 26.5429 29.2908 26.5216L32.0485 21.1375C32.1382 20.9669 32.34 20.8816 32.5305 20.9456L32.5642 20.9562C32.7659 21.0202 32.878 21.2334 32.7996 21.4253C32.7996 21.436 32.7884 21.4467 32.7771 21.4573L27.6765 31.4579C27.486 31.8311 27.486 32.2576 27.6653 32.6307C27.8335 32.9932 28.1698 33.2704 28.5734 33.377C28.7191 33.4197 28.876 33.4517 29.0442 33.4517C29.6607 33.4623 30.21 33.1105 30.4454 32.5668L33.1471 27.3959L33.999 26.1484C33.5955 25.9672 33.2031 25.7646 32.7996 25.5514Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5163_1900"
          x1="12.7273"
          y1="37.5809"
          x2="49.6689"
          y2="19.3923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1DA9E6" />
          <stop offset="0.822917" stopColor="#6966FE" />
        </linearGradient>
        <clipPath id="clip0_5163_1900">
          <rect width="15" height="13.2625" fill="white" transform="translate(2.27563 21.4519)" />
        </clipPath>
      </defs>
    </svg>
  );
}
