import React from "react";

// Components
import { Box, Fade, Stack, Typography } from "@mui/material";
import {
  MAX_SCENARIO_DESCRIPTION_LENGTH,
  TEMPLATE_SUB_TYPE_DATA,
} from "components/ConvoScenarios/convoScenarioUtils";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";

// Utils
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { ScenarioTemplateSubType } from "lib-fullstack/db";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import { useEditor, EditorContent, Extensions } from "@tiptap/react";
import { Link } from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { WYSIWYGMenuBar } from "components/WYSIWYGMenuBar";
import CharacterCount from "@tiptap/extension-character-count";
import { isValidUrl } from "utils/navUtils";

export type ScenarioDescriptors = {
  title: string;
  description: string;
};

type ScenarioPreviewProps = {
  templateSubType: ScenarioTemplateSubType;
  title: string;
  titleLabel?: string;
  description: string;
  descriptionLabel?: string;
  decriptionPlaceholder?: string;
  handleUpdateScenarioDetails: (key: keyof ScenarioDescriptors, value: string) => void;
};

// TODO: rename this to better represent the details that a user can change (title/desc) instead of just a purely preview page
export const ScenarioPreview = ({
  templateSubType,
  title,
  titleLabel,
  description,
  descriptionLabel,
  decriptionPlaceholder,
  handleUpdateScenarioDetails,
}: ScenarioPreviewProps): JSX.Element => {
  const extensions: Extensions = [
    StarterKit,
    CharacterCount.configure({
      limit: MAX_SCENARIO_DESCRIPTION_LENGTH,
    }),
    TextStyle,
    Underline,
    Placeholder.configure({ placeholder: decriptionPlaceholder }),
    Link.configure({
      openOnClick: false,
      linkOnPaste: true,
      validate: (href) => isValidUrl(href),
    }),
  ];
  const editor = useEditor({
    content: description,
    extensions: extensions,
    onBlur: async ({ editor }) => {
      if (!editor) {
        return;
      }
      const html = editor.getHTML();
      handleUpdateScenarioDetails("description", `${html}`);
    },
  });
  React.useEffect(() => {
    if (description !== editor.getHTML()) {
      editor.commands.setContent(description);
    }
  }, [description]);
  return (
    <Stack
      direction="column"
      gap={{ xs: 2, md: 3 }}
      sx={{
        background: getDynamicColor("gradient.slantedLight"),
        borderRadius: "12px",
        boxShadow: Y_SHADOWS.box_shadow_1,
        py: 3,
        px: 2.5,
      }}
    >
      <YoodliLabeledInput
        label={titleLabel || "Scenario name"}
        inputEl={
          <YoodliTextfield
            autoFocus
            inputProps={{
              className: "blockEnterToNavigate",
              sx: {
                fontWeight: 700,
              },
            }}
            maxChars={200}
            InputLabelProps={{ shrink: true }}
            placeholder={TEMPLATE_SUB_TYPE_DATA[templateSubType]?.label}
            value={title}
            onChange={(e) => handleUpdateScenarioDetails("title", e.target.value)}
            sx={{
              backgroundColor: getDynamicColor("light1"),
            }}
          />
        }
      />
      <YoodliLabeledInput
        label={descriptionLabel || "Member-facing Description"}
        inputEl={
          <Stack
            gap={2}
            sx={{
              mt: 1,
            }}
          >
            <WYSIWYGMenuBar editor={editor} excludeHeaderStyles />
            <Box
              sx={{
                border: "none !important",
                borderRadius: "4px",
                backgroundColor: getDynamicColor("light1"),
                height: "100%",
                outline: "none !important",
                flexGrow: 1,
                overflow: "auto",
                ".tiptap": {
                  maxHeight: 300,
                  padding: "8px 16px",
                  border: `1px solid ${getDynamicColor("purple1")}`,
                  borderRadius: "4px",
                  "&:focus": {
                    outline: "none !important",
                    border: `1px solid ${getDynamicColor("primary")}`,
                  },
                },
              }}
            >
              <EditorContent editor={editor} style={{ height: "100%", fontSize: 14 }} />
            </Box>
            <Fade in={editor.isFocused}>
              <Typography
                sx={{
                  fontSize: 12,
                  color: getDynamicColor(
                    editor.storage.characterCount.characters() >= MAX_SCENARIO_DESCRIPTION_LENGTH
                      ? "redError"
                      : "dark4"
                  ),
                  position: "relative",
                  top: -8,
                  right: -8,
                }}
              >
                {editor.storage.characterCount.characters()} / {MAX_SCENARIO_DESCRIPTION_LENGTH}
              </Typography>
            </Fade>
          </Stack>
        }
      />
    </Stack>
  );
};
