// Components
import { Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "../utils/Colors";
import * as Constants from "lib-fullstack/utils/constants";

// Warning text for recording duration
export default function RecordingDurationWarning(props: { isPoodli?: boolean }): JSX.Element {
  return (
    <Stack direction="row" textAlign="center" gap={1}>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "1em",
          color: getDynamicColor("dark4"),
        }}
      >
        {props.isPoodli ? (
          <>
            Record up to <strong>up to {Constants.RecordingMaxLengthString}</strong> of audio
          </>
        ) : (
          <>
            The Yoodlibot can record <strong>up to {Constants.RecordingMaxLengthString}</strong> of
            an online meeting
          </>
        )}
      </Typography>
    </Stack>
  );
}
